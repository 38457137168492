import {api} from "./index";
import qs from "querystringify";

export let
    postExportDataFromModelToCsv,
    postExportDataWithF32FromModelToCsv,
    postExportsTemplates,
    postExportsTablesTemplates;

postExportDataFromModelToCsv = (userhash, payload,shared) => api.post('v4models/exportcsvs.json?userhash=' + userhash + '&shared=' + shared, payload);
postExportDataWithF32FromModelToCsv = (userhash, payload,shared) => api.post('v5models/exportcsvs.json?userhash=' + userhash + '&shared=' + shared, payload);
postExportsTemplates = (userhash, payload,shared) => api.post('import-export-plugnotes/v2exports/templates.json' + qs.stringify({ userhash, shared }, '?'), payload);
postExportsTablesTemplates = (userhash, payload,shared) => api.post('import-export-plugnotes/tabs/exports/templates' + qs.stringify({ userhash, shared }, '?'), payload);
