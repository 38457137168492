import React, {useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import {postExtDataGenerateTableRequest, } from "../../actions/extdata";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getToken} from "../../utils/Common";
import {FormControl, InputAdornment, InputLabel, TextField, Tooltip} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ExtDataSideMenu } from "../custom/ExtDataTable";
import colors from "../../assets/themes/Colors";
import { confirmAlert } from "react-confirm-alert";
import {ColorCheckbox} from "../custom/CustomComponents";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },    field : {
        width : '100%',
        display: 'flex',
    },
    subfield : {
        width : '95%',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        margin: 20,
        marginTop: 0,
        width : '100%',
        display : 'grid'
    },
    alignedInput: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
    },
    mainComponent:{
        marginLeft:  -50,
        marginRight: 10,
        alignSelf: "center",
        zIndex: 10,
    },
    tableRoot: {
        width: "100%", //it was 100%
        marginTop: theme.spacing(3),
        overflowX: "auto",
        //marginRight: 'auto',
    },
    inputLabel: {
        marginRight: -70,
    },
    inputMargin:{
        margin : '10px',
        minWidth: 200,
        alignSelf: 'flex-end',
        '& .MuiInputLabel-formControl': {
            width: '130%'
        }
    },
    selectMargin:{
        margin : '10px',
        minWidth: 200,
        height: 42,
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
    },
    inputLarge:{
        margin : '10px',
        minWidth: 300,
        alignSelf: 'flex-end'
    },
    tooltipStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '16px',
        borderRadius: 5
    },
    alertContainer: {
        padding: 20,
        position: 'relative',
        width: '60vw',
        height: '60vh',
        borderRadius: 20,
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        color: colors.darkSilver,
        fontWeight: 800,
        fontSize: 20,
        marginBottom: 10,
    },
    alertClose: {
        position: 'absolute',
        top: 15,
        right: 15,
        color: colors.frost,
    },
    alertCloseBtn: {
        position: 'absolute',
        bottom: 15,
        right: 15,
    },
    alertSubtitle: {
        color: colors.darkSilver,
        fontWeight: 600,
        fontSize: 17,
        marginLeft: 20,
        marginTop: 20,
    },
}));


const FormatIntDataMajConstructorComponent = (props) => {
    const classes = useStyles();

    const { t, } = useTranslation(['common', 'models']);
    const [newDataPlugform, setNewDataPlugform] = useState(props.field.options.plugform !== ""  ? props.field.options.plugform  : "");
    const [newDataDest, setNewDataDest] = useState(props.field.options.dest !== ""  ? props.field.options.dest  : "");
    const [newDataDestInfo, setNewDataDestInfo] = useState(props.field.options.dest_infos !== ""  ? props.field.options.dest_infos  : "");
    const [newComment, setNewComment] = useState(props.field.options.comment !== ""  ? props.field.options.comment  : "");
    const [displaySearch, setDisplaySearch] = useState(props.field.options?.display_search || false)
    const [hiddenComponent, setHiddenComponent] = useState(props.field?.hidden || false)


    const handleDataPlugform  = (clef) => {
        setNewDataPlugform(clef);

        props.field.options.plugform = clef;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.plugform = clef;

            props.setNewModel(model);
        }else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.plugform = clef;
            props.setUpdateModel(model);
        }
    }


    const handleDataDestination = (field) => {
        setNewDataDest(field);
        props.field.options.dest = field;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.dest = field;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.dest = field;
            props.setUpdateModel(model);
        }
    }

    const handleDataDestinationInfo = (field) => {
        setNewDataDestInfo(field);
        props.field.options.dest_infos = field;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.dest_infos = field;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.dest_infos = field;
            props.setUpdateModel(model);
        }
    }

    const handleDataComment = (field) => {
        setNewComment(field);
        props.field.options.comment = field;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.comment = field;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.comment = field;
            props.setUpdateModel(model);
        }
    }

    const handleToggleDisplaySearch = () => {
        let newVal = !displaySearch
        setDisplaySearch(!displaySearch)
        props.field.options.display_search = newVal
        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.display_search = newVal
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.display_search = newVal
            props.setUpdateModel(model);
        }
    }


    return (
    <div className={classes.field}>
        <div className={classes.mainInputField}>
            <div className={classes.alignedInput}>
                <FormControl variant="standard" className={classes.inputMargin}>
                    <InputLabel htmlFor={props.field.format.toLowerCase()+'-plugform'} className={classes.inputLabel} shrink={true}>{t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-plugform')}</InputLabel>
                    <Input
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:intdatamaj.info-s03')} placement="bottom">
                                    <span className="material-icons" style={{fontSize: 20, color: colors.mainButton}}>
                                        info
                                    </span>
                                </Tooltip>

                            </InputAdornment>}
                        id={props.field.format.toLowerCase()+'-plugform'}
                        label="Label"
                        //placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-plugform')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{shrink: true}}
                        inputProps={
                            {
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 40
                            }
                        }
                        onChange={(event) => handleDataPlugform(event.target.value)}
                        defaultValue={newDataPlugform}
                    />
                </FormControl>

                {
                    !hiddenComponent &&

                    <div className={classes.inputMargin} style={{display: 'inline-flex', cursor: 'pointer',}}
                         onClick={handleToggleDisplaySearch}>
                        <ColorCheckbox checked={displaySearch}/>
                        <div>{t('common:intdatamaj.display-search')}</div>
                        <Tooltip classes={{tooltip: classes.tooltipStyle}} title={t('common:intdatamaj.display-search')}
                                 placement="bottom">
                            <span className="material-icons" style={{fontSize: 20, color: colors.mainButton}}>
                                info
                            </span>
                        </Tooltip>
                    </div>
                }




            </div>

            <div className={classes.alignedInput}>

                <FormControl variant="standard" className={classes.inputLarge}>
                    <InputLabel htmlFor={props.field.format.toLowerCase()+'-dest'} className={classes.inputLabel} shrink={true}>{t('intdatamaj.autocomplete-rules', {"id": props.field.id})}</InputLabel>
                    <Input
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip
                                    classes={{ tooltip: classes.tooltipStyle }}
                                    placement="bottom"
                                    title={t('common:intdatamaj.info-complete').split('\n').map((c, index) => {
                                        return (<p key={'1' + index + '-' + c[0]}> {c} </p>)})}
                                >
                                    <span className="material-icons" style={{fontSize: 20, color: colors.mainButton}}>
                                        info
                                    </span>
                                </Tooltip>
                            </InputAdornment>}
                        id={props.field.format.toLowerCase()+'-dest'}
                        label="Label"
                        fullWidth={true}
                        multiline={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            fieldkey: props.field.key,
                            className: classes.inputField,
                        }}
                        onChange={(event) => handleDataDestination(event.target.value)}
                        defaultValue={newDataDest}
                    />
                </FormControl>

                <FormControl variant="standard" className={classes.inputLarge}>
                    <InputLabel htmlFor={props.field.format.toLowerCase()+'-dest-info'} className={classes.inputLabel} shrink={true}>{t('intdatamaj.autocomplete-info-rules', {"id": props.field.id})}</InputLabel>
                    <Input
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip
                                    classes={{ tooltip: classes.tooltipStyle }}
                                    placement="bottom"
                                    title={t('common:intdatamaj.dest-info-complete').split('\n').map((c, index) => {
                                        return (<p key={'1' + index + '-' + c[0]}> {c} </p>)})}
                                >
                                    <span className="material-icons" style={{fontSize: 20, color: colors.mainButton}}>
                                        info
                                    </span>
                                </Tooltip>
                            </InputAdornment>}
                        id={props.field.format.toLowerCase()+'-dest-info'}
                        label="Label"
                        fullWidth={true}
                        multiline={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            fieldkey: props.field.key,
                            className: classes.inputField,
                        }}
                        onChange={(event) => handleDataDestinationInfo(event.target.value)}
                        defaultValue={newDataDestInfo}
                    />
                </FormControl>


            </div>

            {
                !hiddenComponent &&
                <div className={classes.alignedInput}>

                    <FormControl variant="standard" className={classes.inputLarge}>
                        <InputLabel htmlFor={props.field.format.toLowerCase()+'-comment'} className={classes.inputLabel} shrink={true}>{t('common:intdatamaj.comment', {"id": props.field.id})}</InputLabel>
                        <Input
                            id={props.field.format.toLowerCase()+'-comment'}
                            label="Label"
                            fullWidth={true}
                            multiline={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={{
                                fieldkey: props.field.key,
                                className: classes.inputField,
                            }}
                            onChange={(event) => handleDataComment(event.target.value)}
                            defaultValue={newComment}
                        />
                    </FormControl>

                </div>
            }

        </div>
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        user : state.user,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGenerateTableByClient: (payload) => dispatch(postExtDataGenerateTableRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatIntDataMajConstructorComponent);





