import React, {Suspense, useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import colors from "../../assets/themes/Colors";
import { TextField } from "../../../node_modules/@material-ui/core/index";
import {getPlugformViaCodeRequest, getPlugnoteViaCodeRequest} from "../../actions/plugcode";
import ModalViewPlugcodeComponent from "../modals/qrcode/ModalViewPlugcodeComponent";
import { PlugTooltip } from "../custom/CustomComponents";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input_error:{
        borderColor: colors.bloodOrange,
        borderBottom: '1px solid',
        borderRadius: '4px',
        textAlignLast: 'end',
        fontWeight: '900',
        marginRight: '5px'
    },
    editButton:{
        display: 'inline-block',
        paddingRight: "10px",
        borderRadius: "6px",
        fontSize: '22px!important',
        color: "#0000008a",
        cursor: "pointer",
        alignSelf: 'center'
    },
    rightButton: {
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    rightButtonMargin: {
        marginRight : '5px',
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    previewContainer: {
        width: '100%',
        // display: 'flex',
        // flexDirection: 'row',
        marginTop: '-32px',
    },
    commentContainer: {
        transform: 'translateY(-6px)',
    },
    codeContainer: {
        marginLeft: 'auto',
        width: 'fit-content',
        cursor: 'pointer',
        display: 'flex',
        position: 'relative',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    mainContainer: {
        // width: '100%',
        // cursor: 'pointer',
    },
    collapsedView: {
        // width: '100%',
        justifyContent: 'space-between',
        marginRight: '15px'
    },
    commentPreview: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'pointer!important'
    },
    codeInput: {
        textAlignLast: 'center',
        fontWeight: '900',
        marginRight: '5px',
        borderColor: colors.selectedMain,
        borderBottom: '1px solid',
    },
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const FormatPlugcodeComponent = (props) => {
    // console.log('Plugcode F19 got props', props)
    const classes = useStyles();
    let plugcode = typeof props.field.value !== 'undefined' ? props.field.value.split(';') : [];
    let initCode = "";
    let initComment = ""
    const isLocked = props.field.options.setup?.editable === false || props.disabled

    if (plugcode.length === 2) {
        initCode = (plugcode[0] !== 'undefined' ? plugcode[0] : "");
        initComment = (typeof plugcode[1] !== 'undefined' ? plugcode[1] : "");
    } else if (plugcode.length === 1) {
        let containsCode = plugcode[0].startsWith('PN') || plugcode[0].startsWith('PP') || plugcode[0].startsWith('PF') || plugcode[0].startsWith('PE');
        if (containsCode) {
            initCode = (plugcode[0]);
        } else {
            initComment = (plugcode[0]);
        }
    }

    const [code, setCode] = useState(initCode);
    const [comment, setComment] = useState(initComment);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(false) // champ pour ajouter un commentaire
    const [viewFiche, setViewFiche] = useState(false)

    //open PlugCode:
    // const environment = getCurrentEnvironnement();
    // const [hash, setHash] = useState(!!environment ? environment.userhash : null);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    const validateCode = (code) => {
        let isValid = false
        if(code.startsWith("PF-") || code.startsWith("PN-") || code.startsWith("PP-")) isValid = true
        if (code.trim().length === 15) return isValid;
        else return false
    }

    const handleOpenFiche = () => {
        if(validateCode(code)){
            // if (code.startsWith("PN-")) props.attemptGetPlugnoteViaCodeRequest(code, props.userhash, false)
            // else if (code.startsWith("PF-")) props.attemptGetPlugformViaCodeRequest(code, props.userhash, false)
        }
        setViewFiche(true);
    }

    const handleOpenComment = () => {
        setOpen(true);
        if(open){
            setTimeout(() => {document.getElementById('comment-field').focus()}, 100);
        }
    }
    
    // console.log("F19 props", props)
    return (
    <div className={classes.mainContainer}>
        <div className={classes.codeContainer} style={open? null : {backgroundColor: colors.white}}>
            {!open && comment.length > 30? <span onClick={()=> setOpen(true)}> ...</span> : null}
            <Input
                disabled={disabled || isLocked}
                autoComplete='off'
                id={'plugcode-input-' + props.field.id}
                placeholder="code"
                fullWidth={true}
                margin="none"
                className={classes.codeInput}
                disableUnderline={true}
                onChange={(event) => {
                    setCode(event.target.value);
                    props.handleChangePlugcode(event.target.value,comment, props.field, props.index)}
                }
                value={code}
            />

            {open?
            <PlugTooltip title="Validate comment" placement="top">
            <span id="icon-listForm" className={`material-icons md-25 ${classes.rightButton}`} style={{marginLeft: 'auto'}} onClick={(event) => setOpen(false)}>
                check
            </span></PlugTooltip>
            :
            validateCode(code) && <PlugTooltip title="See Plugnote" placement="top">
            <span id="icon-listForm" className={`material-icons md-25  ${classes.rightButton}`} style={{marginLeft: 'auto'}} onClick={(event) => handleOpenFiche()}>
                {code.startsWith("PF-")? 'edit' : 'visibility'}
            </span></PlugTooltip>}
        </div>

        {!open?
            <div className={classes.previewContainer} onClick={()=> handleOpenComment() }>
            {comment !== ""?
            <PlugTooltip title="Edit comment" placement="top">
            <TextField
                className={classes.commentPreview}
                InputProps={{ disableUnderline: true, maxLength: 20}}
                inputProps={{ maxLength: 12 }}
                fullWidth={true}
                value={comment}/></PlugTooltip>
                :
            <PlugTooltip title="Add comment" placement="top">
                <span
                    onClick={()=> setOpen(true)}
                    className={`material-icons md-small ${classes.editButton}`}>message</span>
            </PlugTooltip>}
            </div>
        : null}

        {open? //commentaire de plugform
            <Input
                id='comment-field'
                autoFocus
                disableUnderline
                style={{ margin: 0 }}
                disabled={disabled}
                autoComplete='off'
                placeholder="comment"
                fullWidth={true}
                multiline={true}
                margin="none"
                className={classes.commentContainer}
                onChange={(event) => {
                    setComment(event.target.value);
                    props.handleChangePlugcode(code,event.target.value, props.field, props.index);
                    }
                }
                defaultValue={comment}
                onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                    e.currentTarget.value.length,
                    e.currentTarget.value.length
                )}
            />
        : null
        }
        { viewFiche &&
            <Suspense fallback={null}>
                <ModalViewPlugcodeComponent code={code} open={viewFiche} close={() => setViewFiche(false)} useSuspense={false}/>
            </Suspense>
            }
    </div>);
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        userhash: state?.user?.user?.userhash || null
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetPlugnoteViaCodeRequest: (plugcode, userhash, exist) => dispatch(getPlugnoteViaCodeRequest(plugcode, userhash, exist)),
    attemptGetPlugformViaCodeRequest: (plugcode, userhash, exist) => dispatch(getPlugformViaCodeRequest(plugcode, userhash, exist))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugcodeComponent);





