import React, {useEffect, useRef, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {getCurrentEnvironnement, getPlugnote, getToken} from "../../utils/Common";
import {getDataByClefUserIndexRequest, reduxGetDataByClefUserIndexReset} from "../../actions/data";
import {confirmAlert} from "react-confirm-alert";
import {Tooltip} from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import ApplicationConfig from '../../config/ApplicationConfig';
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import ReactDataGrid from "react-data-grid";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Input from "@material-ui/core/Input";
import {Close, Search} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import {getTemplateFormatText} from "../../utils/Compatibility";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {isNull} from "lodash";
import {getBuildDate} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldTop:{
        width : '100%',
        // border: '1px solid #E3E3E3',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'baseline'
    },
    fieldValueCtn:{
        width : '100%',
        border: '1px solid #E3E3E3',
        borderRadius: '6px',
        marginTop: '5px',
        padding: '3px 10px',
        display: 'flex',
        flexDirection: 'row',
    },
    fieldBottom: {
        '& .MuiInputBase-inputMultiline': {
            alignSelf: 'flex-start',
        }
    },
    mainContainer:{
        minHeight: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rightBtns: {
        cursor: 'pointer',
        padding: '3px 6px',
        marginLeft: 5,
        borderRadius: "3px",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: colors.unselectedAlpha,
    },
    topRightBtns: {
        flexDirection: 'row',
        display: 'flex',
        maxHeight: 'none',
        height: 'auto',
        justifyContent: 'flex-end',
    },
    inputAdornmentCtn: {
        maxHeight: 'none',
        height: 'auto',
    },
    addFormBtn: {
        fontSize: '17px',
        minHeight: '40px',
        padding: '3px 6px',
        color: 'white!important',
        alignSelf: 'center',
        backgroundColor: colors.unselectedAlpha,
        borderRadius: '6px',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces',
    },
    folderText: {
        fontSize: 16,
        color: colors.unselectedMain
    },
    formBtnText: {
        verticalAlign: '-webkit-baseline-middle',
        fontSize : 14,
        display: 'inline-flex',
        alignItems: 'center',
    },
    searchFieldCtn: {
        flexDirection: 'row',
        gap: '20px',
        display: 'flex'
    },
    searchCtnDiv: {
        flexDirection: 'column',
        gap: '20px',
        display: 'flex'
    },
    fieldCtn: {
        border: '1px solid',
        borderColor: colors.greyLight,
        borderRadius: 4,
        padding: '3px 10px',
    },
    fieldCtnTable : {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        gap: 3,
        flexWrap: 'nowrap',
        minWidth: 60,
        textAlign: 'center',
    },
    searchField: {
        minWidth: 150,
        width: '50%',
        transition: 'margin 0.5s'
    },
    searchFieldfull: {
        minWidth: 150,
        width: '100%',
        transition: 'margin 0.5s'
    },
    searchCtn: {
        display: 'inline-flex',
        flexDirection: 'row',
        width: '-webkit-fill-available',
        gap: 8,
        justifyContent: 'space-between',
    },
    searchCtnTop: {
        // marginTop :'10px',
        // display: 'inline-flex',
        // flexDirection: 'row',
        // width: '-webkit-fill-available',
        // gap: 8,
        // justifyContent: 'space-between',
        width  : '65%'
    },
    searchCtnTopDiv:{
        marginTop :'10px',
        display: 'inline-flex',
        flexDirection: 'row',
        width: '-webkit-fill-available',
        gap: 8,
        justifyContent: 'space-between',
    },
    searchCtnWithout: {
        // display: 'inline-flex',
        // flexDirection: 'row',
        // gap: 8,
        // justifyContent: 'space-between',
        width : '35%'
    },
    searchBtnText: {
        '@media(max-width: 500px)': {
            display: 'none'
        }
    }
}));

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const modalStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        maxHeight : '95vh',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    smallTextField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '30px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    gridCtn: {
        margin: '0px -40px',
        maxHeight: 'calc(95vh - 180px)'
    },
    legendCtn: {
        padding: '0px 20px',
        height: 25,
        backgroundColor: colors.plugnotesLightAlpha,
        marginRight: 5,
        border: '1px solid',
        borderRadius: 4,
        borderColor: '#dadada'
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
            justifyContent:"center",
            display:'flex'
        },
        '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
                background: '#58c3c1',
                color: 'white',
                // borderRadius: '50%',
            },
        }
    }
}));

const ViewPlugnotes = ({envIndex, plugnotes, limitPage, startPage, model, handleSelectPlugnote, activeSearch, search}) => {

    const classes = modalStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const gridRef = useRef(null);

    const environment = getCurrentEnvironnement();

    const initialRows = [];
    let initColumns = [];

    const onRowClick = (object) => {
        handleSelectPlugnote(object.row.plugnote);
    }

    const headerTitleView = (object) => {
        let importedStyle = {
            backgroundColor: colors.plugnotesLightAlpha,
            color: colors.unselectedMain,
            fontWeight: "bold",
            height: 70,
            margin: '0px -8px',
            padding: '0px 8px',
            width: 'inherit',
        }
        let item = object.column.item;
        let template = getTemplateFormatText(model['field'+item+'format'],typeof model['field'+item+'formatoptions'] !== "undefined" ? model['field'+item+'formatoptions'] : null);
        let span =  ' ( ' + item + ' - ' + template +' )';

        return (
            <div className="flex_display_direction_column" style={null}>
                <span>{object.column.name}</span>
                <span style={{fontSize : '10px'}}>{span}</span>
            </div>
        );
    }

    const specialAction = (object) => {
        let importedStyle = {
            backgroundColor: colors.plugnotesLightAlpha,
            color: colors.unselectedMain,
            height: 'inherit',
            margin: 0,
            padding: '0px 8px',
            width: 'inherit',
        }
        let normalStyle = {
            // backgroundColor: colors.plugnotesLightAlpha,
            color: colors.unselectedMain,
            height: 'inherit',
            margin: 0,
            padding: '0px 8px',
            width: 'inherit',
        }
        let column = object.column.key;
        let value = object.row[column];
        switch (object.column.format) {
            case 'phone':
                return (
                    <div style={normalStyle}>
                        <span>
                            <a href={`tel:${value}`}>{value}</a>
                        </span>
                    </div>
                );

            case 'mail':
                return (
                    <div style={normalStyle}>
                        <span>
                            <a href={`mailto:${value}`}>{value}</a>
                        </span>
                    </div>
                );

            case 'url':
                return (
                    <div>
                        <span>
                            <a href={value} rel='noreferrer' target="_blank">{value}</a>
                        </span>
                    </div>
                );


            default :
                return (
                    <div onClick={() => {onRowClick(object)}} style={normalStyle}>
                        <span>
                            {value}
                        </span>
                    </div>
                )
        }
    };

    const headerIdView = (object) => {
        return (
            <div
                style={{
                    color: '#58C3C1'
                }}
                className="flex_display_direction_column"
            >
                <span>
                    {object.column.name}
                </span>
            </div>
        );
    }

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [activeSearch, plugnotes]);


    if(plugnotes.length > 0 && model){
        // columns.push({ key: 'view', name:  '', resizable: true, sortable : false , width: 25, formatter : viewAction });

        initColumns.push({ key: 'userindex', name: "N°",
            resizable: true,
            sortable : true,
            item : 0,
            headerRenderer: headerIdView,
            formatter : specialAction,
            special : true
        });

        for (let item = 1; item <= 15 ; item++){

            if(model['field'+item+'label'] !== null && typeof model['field'+item+'label'] !== "undefined"){

                initColumns.push({ key: 'field' + item, name:  model['field'+item+'label'],
                    resizable: true,
                    sortable : true,
                    item : item,
                    headerRenderer: headerTitleView,
                    formatter : specialAction,
                    format : model['field'+item+'format'],
                    special : false
                });
            }
        }

        plugnotes.map((plugnote, index) => {
            let finalObject = {};
            let founded = false;
            finalObject['include'] = true;
            finalObject.userindex = '[' + envIndex + '-' + plugnote.userindex + ']';

            for (let item = 1; item <= 15 ; item++){
                if(model['field'+item+'label'] !== null){
                    let value = plugnote['field'+item];
                    if(plugnote['field'+item+'format'] === 'select'){
                        try {
                            let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                let num1 = parseInt(str1);
                                let num2 = parseInt(str2);

                                if (isNaN(num1) && isNaN(num2)) {
                                    if (str1 > str2)
                                        return 1;
                                    else if (str1 < str2)
                                        return -1;
                                    else
                                        return 0;
                                }

                                else if (isNaN(num1))
                                    return 1;
                                else if (isNaN(num2))
                                    return -1;
                                else if (num1 > num2)
                                    return 1;
                                else if (num1 < num2)
                                    return -1;
                                else
                                    return 0;
                            });
                            value = values.join(', ');
                        } catch (error) {
                            value = plugnote['field'+item];
                        }
                    }
                    else if(plugnote['field'+item+'format'] === 'list'){
                        let plugValue = parseJson(plugnote['field' + item]);
                        if (typeof plugValue === 'string') {
                            if (plugValue) {
                                value = [value];
                            }
                        } else if (plugValue == null) {
                        } else {
                            value = plugValue.reduce((res, obj) => {
                                return res.concat(obj.text || '');
                            }, []).join(', ');
                        }
                    }
                    else if(plugnote['field'+item+'format'] === 'table'){
                        let plugValue = parseJson(plugnote['field' + item]);
                        if (typeof plugValue === 'string') {
                            if (plugValue) {
                                value = [value];
                            }
                        } else if (plugValue == null) {
                        } else {
                            value = plugValue.reduce((res, obj) => {
                                return res.concat(obj.text + ' : ' + obj.value || '');
                            }, []).join(', ');
                        }
                    }
                    else if(plugnote['field'+item+'format'] === 'plugform'){
                        let plugValue = parseJson(plugnote['field' + item]);
                        value = typeof plugValue !== "undefined" ? plugValue.value : "";
                    }
                    else if(plugnote['field'+item+'format'] === 'qrcode'){
                        if(typeof value !== 'undefined' && value.length > 0){
                            let qrCode = "";
                            let comment = "";
                            if(value.startsWith(';')){
                                let fieldValue  = value.substring(1);
                                qrCode = '';
                                comment = fieldValue;
                            }else{
                                let fieldValue = value.split(/([^,]*);(.*)/);
                                let fieldFilteredValue =  fieldValue.filter( a => a);
                                qrCode = fieldFilteredValue[0];
                                comment = fieldFilteredValue[1];
                            }

                            if(qrCode !== undefined && qrCode.length > 0){
                                if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                    value = '(' + qrCode.toUpperCase() + ')';
                                }
                            }

                            if(comment !== undefined && comment.length > 0){
                                value = comment;
                            }

                            if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                value = '(' + qrCode.toUpperCase() + ') ' + comment;
                            }
                        }

                    }
                    else if(plugnote['field'+item+'format'] === 'password'){
                        value = '*'.repeat(6);

                    }
                    else if(plugnote['field'+item+'format'] === 'glocalis'){
                        let plugValue = parseJson(value);
                        let tmp = "";
                        if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                            tmp = tmp.concat(plugValue.address);
                        }

                        if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                            if(tmp.length > 0){
                                tmp = tmp.concat(' ~ ');
                            }
                            tmp = tmp.concat('lat : ' + plugValue.latitude + ',' + 'lng :' + plugValue.longitude);
                        }

                        value = tmp;
                    }
                    else if(plugnote['field'+item+'format'] === 'qrcodeint'){
                        try {
                            let tmpValue = parseJson(value);
                            value = tmpValue?.comment || "";
                        }catch (e) {
                            value = "";
                        }
                    }
                    else if(plugnote['field'+item+'format'] === 'tablemulti'){
                        try {
                            let tmpValue = parseJson(value);
                            value = tmpValue.length;
                        }catch (e) {
                            value = 0;
                        }

                    }
                    else if(plugnote['field'+item+'format'] === 'tablemultin'){
                        try {
                            let tmpValue = parseJson(value);
                            value = tmpValue.list.length;
                        }catch (e) {
                            value = 0;

                        }

                    }
                    else if(plugnote['field'+item+'format'] === 'smailvalidmulti'){
                        try {
                            let tmpValue = parseJson(value);
                            value = tmpValue.list.length;
                        }catch (e) {
                            value = 0;
                        }
                    }
                    else if(plugnote['field'+item+'format'] === 'document'){
                        try {
                            let tmpValue = parseJson(value);
                            value = tmpValue.list.length;
                        }catch (e) {
                            value = 0;
                        }
                    }
                    finalObject['field' + item] = value;

                    if(activeSearch && founded === false && typeof value !== "undefined"){
                        founded = value.includes(search)
                    }
                }
            }

            finalObject['include'] = !activeSearch ? true : founded;


            finalObject['plugnote'] =  plugnote;
            finalObject['hash'] =  plugnote.hash;

            if(!activeSearch){
                initialRows.push(finalObject);
            }
            else if(finalObject['include'] === true){
                initialRows.push(finalObject);
            }
        });
    }

    const [changeColumns, setChangeColumns] = useState(initColumns);
    const [rows, setRows] = useState([initialRows]);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("userindex");
    const [rowCount, setRowCount] = useState(rows.length);

    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [plugnotes]);


    return (
        <div key={-1}>
            <DndProvider backend={HTML5Backend}>
                <ReactDataGrid
                    rowKeyGetter={(row) => row.hash || ''}
                    defaultColumnOptions={{
                        sortable: true,
                        resizable: true
                    }}
                    style={{height : ((rowCount * 35) + 100), maxHeight: 'calc(95vh - 200px)'}}
                    columns={initColumns}
                    rowGetter={i => rows[i]}
                    rowsCount={rowCount}
                    sortDirection={sortDirection}
                    sortColumn={sortColumn}
                    onSort={(sortColumn, sortDirection) =>{
                        setSortDirection(sortDirection);
                        setSortColumn(sortColumn);
                        setRows(sortRows(initialRows, sortColumn, sortDirection));
                    }}
                    rows={rows || []}
                    headerRowHeight={70}
                    //selectedRows={selectedRows}
                    //onSelectedRowsChange={setSelectedRows}
                />
            </DndProvider>
        </div>
    );
};

const ModalListFicheIntDataMajComponent = (props) => {
    const classes = modalStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const environment = getCurrentEnvironnement();

    const [key, setKey] = useState(0);
    const [plugnotes, setPlugnotes] = useState([]);
    const [model, setModel] = useState([]);
    const [page, setPage] = useState(1);
    const [end, setEnd] = useState(1);
    const [hash, setHash] = useState(props.owner !== null ? props.owner : environment.userhash);

    const [search, setSearch] = useState(props.filter);
    const [activeSearch, setActiveSearch] = useState(props.filter.length > 0);
    const [reload, setReload] = useState(false);
    const [envIndex, setEnvIndex] = useState(1);

    const handleCloseModal = () => {
        props.close();
    };

    useEffect(() => {
        fetchLoadDatas();
    }, [props.open]);


    const fetchLoadDatas = () => {
        trackPromise(
            axios.get(
                ApplicationConfig.apiUrl + `v5/datas/clefs.json?clef=${props.clef}&uh=${hash}&internal=${hash}&page=${page}&filter=${search}`,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                })
                .then((response) => {
                    let datas = response.data.datas || [];
                    let datasSorted = [];

                    if(datas.length > 0){
                        datasSorted = datas.sort((a,b) => {
                            return a.userindex - b.userindex;
                        })
                    }
                    setEnd(response.data.end);
                    setModel(response.data.model);
                    setPlugnotes(datasSorted);
                    setEnvIndex(response.data.envindex);
                })
                .catch((e) => console.log('error', e)
                )
        );
    };

    const handleSeeMore = (event, value) => {
        setPage(value);
    }

    useEffect(() => {
        fetchLoadDatas();
    }, [page])


    const handleSelectPlugnote = (plugnote) => {
        copyId(plugnote);
    }

    const copyId = (plugnote) => {
        props.handleSelectPlugnote(envIndex+'-'+plugnote.userindex);
    }

    const handleViewInputSearch = () => {
        setActiveSearch(false);
        setSearch("");
        setReload(true);
    }

    const handleSearch = () => {
        setActiveSearch(true);
    };

    useEffect(() => {
        if(reload){
            fetchLoadDatas();
            setReload(false);
        }
    }, [reload])

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div style={{marginRight: 'auto', flex: 1}}>
                            </div>
                            <div className="text_align">
                                <span className="titres">
                                    {t('common:modal.select-plugnotes-from-folder')}
                                    <br/>
                                    { !!props.clef &&
                                        <span>[{props.clef}]</span>
                                    }
                                    <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal} style={{marginLeft: 'auto', flex: 1}}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">

                                <div className="grey flex_display_list_annexe" style={{justifyContent: 'space-between',}}>
                                    <div style={{width : '30%', minWidth: 120}}>
                                        <Input
                                            id="standard-full-width"
                                            label="Label"
                                            style={{ marginBottom: 13 }}
                                            //placeholder='titre formulaire'
                                            helpertext="Full width!"
                                            fullWidth={true}
                                            margin="none"
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            // disableUnderline={true}
                                            className={classes.textField}
                                            value={search}
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                                if(activeSearch){
                                                    setActiveSearch(false)
                                                }
                                                setReload(true);
                                            }}
                                            endAdornment={ search.length > 0 && activeSearch ?
                                                <InputAdornment position="end" onClick={handleViewInputSearch} style={{cursor : 'pointer'}}>
                                                    <Close />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position="end" onClick={handleSearch} style={{cursor : 'pointer'}}>
                                                    <Search />
                                                </InputAdornment>
                                            }
                                            onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch()}}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.gridCtn}>
                            {
                                plugnotes.length > 0 &&
                                <ViewPlugnotes
                                    clef={props.clef}
                                    handleSelectPlugnote={handleSelectPlugnote}
                                    envIndex={envIndex}
                                    hash={hash}
                                    plugnotes={plugnotes}
                                    limitPage={props.end}
                                    startPage={props.start}
                                    model={model}
                                    value={props.value}
                                />
                            }
                            { plugnotes.length > 0 && model !== null && end > 1 &&
                                <div>
                                    <Pagination page={page}  className={classes.pagination} count={end} variant="outlined" shape="rounded" onChange={handleSeeMore} />
                                </div>
                            }
                            { plugnotes.length > 0 && model !== null && end > 1 &&
                                <div>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Input
                                            id="standard-full-width-page"
                                            label="Label"
                                            style={{ margin: 8 }}
                                            //placeholder='titre formulaire'
                                            helpertext="Full width!"
                                            fullWidth={false}
                                            margin="none"
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            disableUnderline={true}
                                            className={classes.smallTextField}
                                            value={page}
                                            onChange={(event) => {
                                                setPage(event.target.value);
                                            }}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { handleSeeMore(e, page)}}}
                                        />
                                    </Box>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

const FormatIntDataMajComponent = (props) => {


    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(props?.field?.options?.display_search || false);
    const [clef, setClef] = useState(props?.field?.options?.plugform || "");
    const [comment, setComment] = useState(props?.field?.options?.comment || "");
    const [field, setField] = useState(props?.field?.options?.field || "");
    const [viewIconSave, setViewIconSave] = useState(typeof props.dataHash !== "undefined" && !isNull(props.dataHash))
    const [hiddenComponent, setHiddenComponent] = useState(props?.hidden || false)
    const [openPromised, setOpenPromised] = useState(false);
    const { promiseInProgress } = usePromiseTracker();

    let tmpValue = "";

    if(!!props?.field?.value?.value){
        if(props?.field?.value?.value !== ""){
            tmpValue = props.field.value.value;
        }
    }

    let tmpUpdatedValue = "";

    if(!!props?.field?.value?.updatedDate){
        if(props?.field?.value?.updatedDate !== ""){
            tmpUpdatedValue = props.field.value.updatedDate;
        }
    }

    const [value, setValue] = useState(tmpValue);
    const [updatedDate, setUpdatedDate] = useState(tmpUpdatedValue);
    const [model, setModel] = useState(null);
    const [filter, setFilter] = useState('')

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        let tmpValue = "";

        if(!!props?.field?.value?.value){
            if(props?.field?.value?.value !== ""){
                tmpValue = props.field.value.value;
            }
        }
        setValue(tmpValue);

        if(!!props.field.value.updatedDate){
            if(props.field.value.updatedDate !== ""){
                setUpdatedDate(props.field.value.updatedDate);
            }else {
                setUpdatedDate("");
            }
        }else{
            setUpdatedDate("");
        }
    },[props.field.value])

    useEffect(() => {
        setViewIconSave(typeof props.dataHash !== "undefined" && !isNull(props.dataHash));
    },[props.dataHash])

    const handleOpenSearchModel = () => {
        setOpen(true);
    }

    const handleSelectPlugnote = (userindex) => {
        setValue(userindex);
        if(typeof props.handleChangeIntDataMaj !== "undefined"){
            props.handleChangeIntDataMaj({"value": userindex, "updatedDate" : ""}, props.field, props.index);
        }else if(typeof props.handleChangeIntDataMajRow !== "undefined"){
            props.handleChangeIntDataMajRow({"value": userindex, "updatedDate" : ""});
        }
    }

    const handleChangeIntDataMaj = (value, field, index) => {

        if(typeof props.handleChangeIntDataMaj !== "undefined"){
            props.handleChangeIntDataMaj(value, field, index);
        }else if(typeof props.handleChangeIntDataMajRow !== "undefined"){
            props.handleChangeIntDataMajRow(value);
        }
    }



    const handleSelectPlugnoteFromModal = (userindex) => {
        handleSelectPlugnote(userindex);
        setOpen(false);
    }

    useEffect(() => {
        if (openPromised){
            if (!promiseInProgress && getPlugnote() !== null){
                setOpenPromised(false);
                handleUpdateInternalPlugnote();
            }
            else {
                setOpenPromised(true);
            }
        }
    }, [promiseInProgress, openPromised, getPlugnote()])

    const controlBeforeUpdateInternal = () => {

        if (props.mode !== "modal-shared-create"){
            if (typeof props.control !== "undefined" && props.control){
                switch (props.mode) {
                    case 'table':
                    case 'create':
                    case 'shared-create':
                        setOpenPromised(true);
                        if (getPlugnote() === null){
                            props.controlCreate();
                        }
                        else {
                            props.controlUpdate();
                        }
                        break;
                    case 'update':
                    case 'shared-update':
                    case 'modal-shared-update':
                        setOpenPromised(true);
                        props.controlUpdate();
                        break;
                    default:
                        break;
                }
            }
        }
        else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:global.send-before'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }


    const handleUpdateInternalPlugnote = () => {

        if(typeof props.dataHash !== "undefined"&& !isNull(props.dataHash)){
            let payload = {
                "plugnote_origin_hash" : props.dataHash,
                "plugnote_update_userindex" : value,
                "owner" : owner,
                "destination" : props.field.options.dest,
                "destination_infos" : props.field.options.dest_infos,
                "plugform" : props.field.options.plugform,

            }
            trackPromise(
                axios.post(ApplicationConfig.apiUrl + 'ints/datas/updates.json?uh=' + environment.userhash , payload,
                    {
                        crossdomain: true,
                        'headers': {
                            'X-AUTH-TOKEN': getToken(),
                        }}
                ).then(async (response) => {
                    if (response.data.result === true) {

                        let dateUpdated = await getBuildDate();
                        setUpdatedDate(dateUpdated);

                        handleChangeIntDataMaj({
                            "value": value,
                            "updatedDate": dateUpdated
                        }, props.field, props.index);


                        confirmAlert({
                            title: i18n.t('common:global.plugnotes'),
                            message: i18n.t('common:global.saved'),
                            buttons: [
                                {
                                    label: i18n.t('common:global.ok'),
                                }
                            ]
                        });
                    }else{
                        confirmAlert({
                            title: i18n.t('common:global.plugnotes'),
                            message: i18n.t('common:global.administrator-error'),
                            buttons: [
                                {
                                    label: i18n.t('common:global.ok'),
                                }
                            ]
                        });
                    }
                }).catch((response) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:global.administrator-error'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                            }
                            ]
                    });
                })
            )
        }
    }

    function handleSearchValue (value) {
        setFilter(value)
    }


    let mainContainer = props.mode === 'table' ? '' : classes.mainContainer;
    let fieldCtn = props.mode === 'table' ? classes.fieldCtnTable : classes.fieldCtn;
    let searchCtnWithout = props.mode === 'table' ? '' : classes.searchCtnWithout;
    let searchField = props.mode === 'table' ? classes.searchFieldfull : classes.searchField;

    console.log('props', props);
    console.log('clef', clef);
    console.log('value', value);
    console.log('disabled', disabled);
    console.log('typeof clef !== "undefined" && typeof value !== "undefined" &&', typeof clef !== "undefined" && typeof value !== "undefined");

    return (
        <div className={mainContainer}>
            <div className={fieldCtn}>


                <div className={classes.searchFieldCtn}>
                    {
                        !hiddenComponent &&
                        <div className={classes.fieldTop}>
                            {
                                typeof clef !== "undefined" ?
                                    <div className={classes.folderCtn}>
                                        <span className={classes.folderText}>{comment}</span>
                                    </div>
                                    :
                                    <span>not configured</span>
                            }
                        </div>
                    }

                        {

                            <div className={searchCtnWithout}>
                                <TextField
                                    InputProps={{
                                        disableUnderline: false,
                                        endAdornment:
                                            viewIconSave ?
                                                <InputAdornment
                                                    classes={{root: classes.inputAdornmentCtn}}
                                                    position="end"
                                                >
                                                    { typeof clef !== "undefined" && typeof value !== "undefined" &&
                                                        <div className={classes.rightBtns}>
                                                            {
                                                                !disabled &&
                                                                <Tooltip title={t('common:intdatamaj.update-s03')} placement='top'>
                                                                    <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => controlBeforeUpdateInternal()} style={{alignSelf: 'flex-end',}}>
                                                                        cloud_sync
                                                                    </span>
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    }
                                                </InputAdornment>
                                                : null

                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.searchFieldfull}
                                    helperText=""
                                    id="standard-full-width"
                                    margin="none"
                                    disabled={disabled}
                                    multiline={false}
                                    onChange={(event) => handleSelectPlugnote(event.target.value)}
                                    value={value}
                                />
                                {
                                    updatedDate.length > 0 &&
                                    <TextField
                                        disabled={true}
                                        className={searchField}
                                        helperText=""
                                        id="standard-full-width"
                                        margin="none"
                                        multiline={false}
                                        value={updatedDate}
                                    />
                                }
                            </div>
                        }
                    </div>

                {
                    !disabled && view && !hiddenComponent &&
                    <div className={classes.searchCtnTopDiv}>
                        <TextField
                            className={classes.searchField}
                            helperText=""
                            id="standard-full-width"
                            margin="none"
                            multiline={true}
                            placeholder={t('plugform.filtered-search')}
                            onChange={(event) => handleSearchValue(event.target.value)}
                            onKeyDown={(e) => {if (e.key === 'Enter') handleOpenSearchModel()}}
                            value={filter}
                        />
                        <Tooltip title={t('plugform.select-plugnote')} placement='top'>
                            <div className={classes.addFormBtn} onClick={(event) => handleOpenSearchModel()}>
                                <span className={classes.formBtnText}>
                                    <span className={classes.searchBtnText}>{t('plugnote.select-plugnote')}</span>
                                    <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleOpenSearchModel()}>
                                        search
                                    </span>
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                }

            </div>

            {
                open &&
                <ModalListFicheIntDataMajComponent
                    clef={clef}
                    close={() => setOpen(false)}
                    handleSelectPlugnote={handleSelectPlugnoteFromModal}
                    label=""
                    filter={filter}
                    mode={props.mode}
                    type="id"
                    open={open}
                    owner={owner}
                    value={value}
                />
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {

    return {

    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetPlugnoteByClefAndUserHashAndUserIndex: (clef,userhash,userindex, fieldId) => dispatch(getDataByClefUserIndexRequest(clef,userhash,userindex, fieldId)),
    attemptGetPlugnoteByClefAndUserHashAndUserIndexReset: () => dispatch(reduxGetDataByClefUserIndexReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatIntDataMajComponent);
