import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import {Input, IconButton, Tooltip} from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import { SetupSelects } from "../custom/SetupSelects";
import { Settings } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {ReactComponent as MultiSelect} from '../../assets/images/format-icon/svg/multi-select.svg'
import {ReactComponent as MultiSelectAdd} from '../../assets/images/format-icon/svg/free-multi-select.svg'
import {ReactComponent as SingleSelect} from '../../assets/images/format-icon/svg/single-select.svg'
import {ReactComponent as SingleSelectAdd} from '../../assets/images/format-icon/svg/free-single-select.svg'
import {SetupIndice} from "../custom/SetupIndice";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        width : '100%',
        display: 'flex',
        alignItems: 'center'
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column',
        paddingBottom: 5
    },
    selectType:{
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    inputType:{
        marginTop: '15px',
    },
    inputListType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    visibleBox:{
        height:'40px',
        margin:'5px 0',
        justifyContent: 'left',
        textAlign: 'start',
        alignItems: 'center',
        display: 'flex',
    },
    visibleText:{
        verticalAlign: 'text-bottom',
        color: 'black',
        fontStyle: 'italic',
        fontSize: 'small',
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        color: 'white'
    },
    fieldIcon: {
        // stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 22,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer'
    },
    preselConfig: {
            height: 'fit-content',
        color: colors.white,
        cursor: 'pointer',
        overflow: 'visible',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        backgroundColor: colors.mainButton,
        padding: 4,
        marginTop: -10,
        zIndex: 1,
        '&:hover': {
            backgroundColor: colors.main,
            boxShadow: '0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 20px rgb(0 0 0 / 20%)'
        }
    },
}));


const FormatIndiceConstructorComponent = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const [racine, setRacine] = useState(props.field.options?.racine || "");
    const [indice, setIndice] = useState(props.field.options?.indice || "");
    const [editable, setEditable] = useState(props.field.options?.editable || false);

    const [open, setOpen] = useState(false);

    const handleChangeRacine = (value, index) => {
        let field ={...props.field}

        setRacine(value);
        field.options.racine = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.racine = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.racine = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeIndice = (value, index) => {
        let field ={...props.field}

        setIndice(value);
        field.options.indice = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.indice = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.indice = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeEditable = (value, index) => {
        let field ={...props.field}

        setEditable(value);
        field.options.editable = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.editable = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.editable = value;
            props.setUpdateModel(model);
        }
    }


    return (
        <div className={classes.mainField}>
            <div className={classes.field}>
                <Input
                    id="standard-full-width"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={{
                        fieldkey: props.field.key,
                        className: classes.inputField,
                        maxLength: 40
                    }}
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
                <div className={classes.preselConfig}>
                    <IconButton aria-label="expand row" size="small" style={{alignItems: 'flex-start'}} onClick={() => {setOpen(!open)}}
                    >
                        {open ? 
                        <div className={classes.subOpenClose}><Settings/><KeyboardArrowUpIcon/></div> : <div className={classes.subOpenClose}><Settings/><KeyboardArrowDownIcon/></div>}
                    </IconButton>
                </div>
            </div>

          {open &&
            <SetupIndice
                field={props.field}
                format={"indice"}
                handleClose={() => setOpen(false)}
                index={props.index}
                racine={racine}
                indice={indice}
                editable={editable}
                handleChangeRacine={handleChangeRacine}
                handleChangeIndice={handleChangeIndice}
                handleChangeEditable={handleChangeEditable}
            />
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatIndiceConstructorComponent);





