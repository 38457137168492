import React from "react";
import { Text, View, StyleSheet, Link, Font } from "@react-pdf/renderer";
import {isDefined} from "../../../utils/utils";
import colors from "../../../assets/themes/Colors";

const hyphenationCallback = (word) => {
    if (word.length > 0) {
        return word.split(/(?=.)/g); // splits the string without removing splitting char
    }
    else return ['']
}

Font.registerHyphenationCallback(hyphenationCallback);

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        // flexGrow: 1,
        flexDirection: "row",
        // flexWrap: 'nowrap',
        // alignItems: "center",
        // width: '100%',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        minHeight: 15,
    },
    cell: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',
        alignContent: 'center',
        borderLeftWidth: 1,
        borderRightWidth: 0,
        borderColor : "#e0e0e0",
        // backgroundColor: 'yellow', // pour test
        height: '100%',
        minHeight: 15,
        // wordWrap: 'normal',
        // textOverflow: 'ellipsis',
    },
    flexCell: {
        // flex: 1,
    },
    emptyCell: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',
        alignContent: 'center',
        height: 'auto',
        minHeight: 15,
    },
    rowNb: {
        fontFamily: 'Poppins',
        fontSize: 8,
    },
    value: {
        // flexGrow: 1,
        flexWrap: 'wrap',
        fontFamily: 'Poppins',
        fontWeight: 'light',
        minHeight: 15,
    },
    ellipsisCell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    }
});

const RenderTextOrObject = (props) => {
    let value = isDefined(props.row) ? props.row : "";
    if(typeof props.row === "object" && props.row.hasOwnProperty('value') && props.row.hasOwnProperty('updatedDate')){
        if(props.row.value !== "" && props.row.updatedDate !== ""){
            value = "TO : " + props.row.value + " AT : " + props.row.updatedDate;
        }else if(props.row.value !== "" && props.row.updatedDate === ""){
            value = "TO : " + props.row.value;
        }else {
            value = ""
        };
    }

    return (
        <Text style={[styles.value, { fontSize: props.fontSize, }]} > {value} </Text>
    )
}

const PDFRow = (props) => {
    const borderColor = props.borderColor !== undefined ? props.borderColor: "#c5c5c5"

    return ( props.isEmpty === true ?
            (
                <View style={[styles.row, { borderColor: borderColor, borderLeftWidth: 1, borderRightWidth: 1 }]}>
                    <View style={[styles.emptyCell, { flex: 1 }]} key={props.id}>
                        <Text style={props.textStyle}>{props.row[0]}</Text>
                    </View>
                </View>
            ) : (
                <View style={[styles.row, { borderColor: borderColor }, props.widthStyle]}
                      wrap={false}
                    // debug
                >
                    <View style={[styles.cell, { width: 20, borderColor: borderColor }]}>
                        <Text style={styles.rowNb} key="row-number" wrap={false}>{props.rowNb + 1}</Text>
                    </View>
                    {
                        typeof props.row !== "undefined" && (props.row.length > 0 || props.row !== null) &&
                        props.header.map((col, index) => {
                            let colId = col.id.toString()
                            let fontSize = isDefined(col.fontSize) ? col.fontSize * 0.7 : 8
                            let widthStyle
                            if (props.flex) {
                                widthStyle = { flex: props.widths[index] }
                            } else {
                                widthStyle = col.colWidth > 0
                                    ? { maxWidth: col.colWidth * 10, minWidth: col.colWidth * 10 }
                                    : { flex: 1 }
                            }

                            // Test if the label and row should be shown
                            if (props.hiddenOpt?.pdf || col.showOnPdf || !isDefined(col.showOnPdf)) {
                                let isLastColumn = false
                                let isUrlOrMail = false
                                if (typeof props.row[colId] === "string" && props.row[colId].length > 0 && (['@', 'http', 'www', 'ftp'].some(v => props.row[colId].includes(v)))) { isUrlOrMail = true; }
                                if (!props.header.some(entry => { return props.header.indexOf(entry) > index && (entry.showOnPdf === true || !isDefined(entry.showOnPdf)|| props.hiddenOpt?.pdf) })) { isLastColumn = true }
                                return (
                                    <View style={[styles.cell, styles.flexCell, widthStyle, { borderColor: borderColor, borderRightWidth: isLastColumn ? 1 : 0, fontSize: fontSize }]} key={index}>
                                        {
                                            isUrlOrMail ?
                                                <View style={styles.ellipsisCell} >
                                                    <Text style={[styles.value, { textOverflow: 'ellipsis', fontSize: fontSize }]}>
                                                        <Link
                                                            src={props.row[colId]} style={{ textOverflow: 'ellipsis', }}
                                                        >
                                                            {
                                                                isDefined(props.row[colId]) ? props.row[colId] : ''
                                                            }
                                                        </Link>
                                                    </Text>
                                                </View>
                                                :
                                                <RenderTextOrObject row={props.row[colId]} fontSize={fontSize} />
                                        }
                                    </View>
                                )
                            } else { return null; }
                        })
                    }
                </View>
            )
    );
};

export default PDFRow;
