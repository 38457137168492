import {apiV2} from "./indexV2";
import * as qs from "querystringify";

export let
    getCheckToken,
    postLogin,
    postLogout,
    postLoginForce,
    getValues,
    getListModelsSharedInWaiting,
    getProfils,
    getUserConfig,
    postUserOksignConfig;

getCheckToken = (uid,token) => apiV2.get( `User/TestToken.php?uid=${uid}&token=${encodeURIComponent(token)}`);
postLogin = (username, password, source) => apiV2.post('User/Login.php', {username, password, source});
postLoginForce = (username, password, source, force) => apiV2.post('User/Login.php', {username, password, source, force});
postLogout = (userHash) =>   apiV2.post('User/Logout.php', {userhash : userHash});
getProfils = (userhash) =>  apiV2.get('Profils/GetProfils.php' + qs.stringify({ userhash }, '?'));
getValues = (userhash) =>  apiV2.get('User/GetValues.php' + qs.stringify({ userhash }, '?'));
getListModelsSharedInWaiting = (userhash) =>  apiV2.get('User/GetListModelsSharedInWaiting.php' + qs.stringify({ userhash }, '?'));
getUserConfig = (userhash) =>  apiV2.get('User/GetConfigs.php' + qs.stringify({ userhash }, '?'));
postUserOksignConfig = (payload) =>  apiV2.post('User/SaveOkSignConfig.php', payload);
