import React, {useEffect, useState} from "react"
import { makeStyles } from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import {postExtDataReset} from "../../actions/extdata";
import { isDefined } from "../../utils/utils";
import { HyperFormula } from "hyperformula";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import { delTableView, getTableView } from "../../utils/Common";
import { getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV } from "../../utils/Fields";
import { PlugTooltip } from "../custom/CustomComponents";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&: : -youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },
    },
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    mainCtn: {
		width: '100%',
        marginTop: -30,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    tableRoot: {
        width: "99%", //it was 100%
        overflowX: "auto",
    },
    topBtnCtn: {
        right: 0,
        gap: 3,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        // maxHeight: 45,
    },
    topBtn: {
        width: 60,
        height: 30,
        padding: 5,
        borderRadius: 5,
        boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.mainButton,
        color: 'white',
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.mainButton,
        },
    },
    disabledBtn: {
        cursor: 'not-allowed',
        backgroundColor: colors.iconGrey,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.iconGrey,
        },
    },
    appBar: {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '10%',
    },
    margin_5: {
        margin : '5px',
        width : '100%'
    },
    margin_left_5: {
        marginLeft : '5px',
    },
    input_add_item: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        // padding: '0px 5px',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&: : -webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '5px',
            background: '#E3E3E3',
            width: '5px',
        },
        "&: : -webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&: : -webkit-scrollbar-button: single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none',
        '&.MuiTableCell-root': {
            padding: 21
        },
        borderLeft: '2px solid',
        borderColor: colors.greyLight
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rowNum: {
        color: colors.grey,
    },
    tableHeader: {
        padding: '3px 10px',
    },
    tableMultButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.unselectedMain
        // fontSize: 22,
    },
    tableMultAddButton: {
        display : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableMultExportButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonBar: {
        marginLeft: 'auto',
        backgroundColor: colors.greyLighter,
        display: 'flex',
        justifyContent: 'space-between',
        height: 30,
        alignItems: 'center'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        marginLeft: 3,
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    stickyCol: {
        position: "sticky",
        zIndex: 100,
        width : '100%',
        wordBreak: 'break-word',
        borderBottom: 'none'
    },
    stickyColRight: {
        position: "sticky",
        left: 0,
        textAlign: 'center',
        zIndex: 50,
        borderBottom: 'none'
    },
    paginationSpacer: {
        flex: 'none',
    },
    width: {
      width : '99%'
    },
    paginationStyle: {
        border: '1px solid blue',
        position: "sticky",
        left: '-10px',
        background: colors.white,
        borderWidth: 0,
    },
    tableMultPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tableMultPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        height: '100%',
        fontSize: 15,
        padding: '0 5px',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        '@media(max-width: 900px)': {
            fontSize: 11
        }
    },
    paginationStyleRow: {
        //border: '3px solid blue',
    },
    paginationStyleFooter: {
        //border: '3px solid blue',
    },

    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
        minHeight: 28,
    },
    renderSingleFormulaContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        minHeight: 28,
    },
    renderSingleSubTotalContainer: {
        padding : 0,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        minHeight: 28,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
        minHeight: 28,
    },
    durationInputContainer: {
        display: 'flex',
        width: '100%',
        background: colors.greenLightest,
        marginBottom: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: '1px solid black',
        position: 'relative'
    },
    renderFirstInputContainer: {
        display: 'flex',
        width: '40px'
    },
    renderSecondInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 'auto'
    },
    renderCentralInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 17
    },
    renderThirdInputContainer: {
        width: '70%'
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    stylizedValue: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontChange: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    fontFirst: {fontSize: 12, fontWeight: 'bold'},
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    deleteBtn: {
        fontSize: 12,
        color: colors.mainButton,
        fontWeight: 'bold',
        position: 'absolute',
        right: 0
    },
    textFont: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 0,
    },
    fontChangeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'middle',
        width: 16,
        height: 16,
    },
    fontTotalNumber: {
        fontSize: 8,
        marginRight: 2,
        border: '1px solid',
        padding: 2,
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        order: 2,
        width : '60%'
    },
    fieldValues: {
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginRight: 'auto',
        textAlign: 'left',
    },
    rowCellBtn: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none',
        // '&.MuiTableCell-root': {
        //     padding: 21
        // },
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: colors.greyLighter
    },
    totalFieldLabel: {
        backgroundColor: `${colors.hoverBgnd} !important`
    },
    timeNowButton: {
        height:'20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        fontSize: "20px",
        margin: '0px 5px',
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
    tableMultVisibilityBtn: {
        width: 50,
        color: colors.main,
    },
    openRow: {
        backgroundColor: colors.greyLighter
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: `1px solid ${colors.greyLight}`
    },
    pageCell: {
        textAlign: 'center',
        borderBottom: 'none',
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30,
        display: 'flex',
        flexDirection: 'row',
    },
    valuesCell: {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    totalValues: {
        color: colors.bloodOrange,
        padding: 0,
        paddingLeft: 5,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
    },
    //New design
    summaryCtn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 5
    },
    summary: {
        paddingRight: 4
    },
    rowCtn: {
        justifyContent: 'flex-end',
		zIndex: 200,
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
        alignSelf: 'flex-end',
    },
    //!Preview
	tooltipCtn: {
		maxWidth: 'none',
		backgroundColor: colors.greyLighter,
		boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
		borderRadius: 8,
	},
	noTooltip: {
		backgroundColor: colors.transparent,
	},
	previewCtn: {
		backgroundColor: colors.transparent,
		margin: 10,
		maxWidth: 'none',
        minWidth: 160,
        padding: 20,
	},
    warnToast: {
        backgroundColor: colors.unselectedMain,
    },
}));

function getCellFormat (cellId, columns) {
    if (cellId) {
        let column = columns.find(obj => {
            return obj.id.toString() === cellId.toString()
        })
        return column
    }
}

const FormatTableMultipleComponent = (props) => {
    // console.log('TM got props', props);
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined"){
        tmpDisabled = props.disabled;
    }
    else if (typeof props.field.disabled !== "undefined"){
        tmpDisabled = props.field.disabled;
    }
    let _table = getTableView() || null
    const classes = useStyles();
    const { t, } = useTranslation(['common']);
    const [owner, ] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [ownerId, ] = useState(props.ownerId);

    let colonnesOptions = props.field.options.length > 0 ? props.field.options : []

    if(props.field.options?.lines){
        colonnesOptions = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    }

    const [colonnes, ] = useState(colonnesOptions);
    const [rows , setRows] = useState(props.field.value.length > 0 ? props.field.value : []);
    const [table, ] = useState(_table?.hash === props.hash ? _table : null)
    const [disabled , setDisabled] = useState(tmpDisabled);
    const [openTable, setOpenTable] = useState(false)
    const [openRow, setOpenRow] = useState(null);
    const [openDownload, setOpenDownload] = useState(false);
    const [datasToDownload, setDatasToDownload] = useState([]);
    const [optionFormule, ] = useState({licenseKey: 'gpl-v3'});
    const [recap, setRecap] = useState(false)
    const [tableLength, setTableLength] = useState(recap ? rows.length - 1 : rows.length)
    const plugNb = props?.plugNb
    const [newRow, setNewRow] = useState(null)
    const setup = props.field.options?.setup

    //function rowIsEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows (rows) {
        let newRows = []
        if (rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if (valeur !== '' && valeur !== undefined) value = valeur
                })
                if (value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    useEffect(() => {
        let hasRecap = false
        if(props.field.options?.lines){
            props.field.options.lines.forEach((col) => {if(col.recap === true) hasRecap = true})
        }else{
            props.field.options.forEach((col) => {if(col.recap === true) hasRecap = true})
        }
        setRecap(hasRecap)
        let newRows = deleteEmptyRows(rows)
        setRows(newRows)
        if (table !== null && table !== undefined && table.field.split('field')[1] === props.field.id.toString()) setOpenTable(true)
    }, [])

    const handleClickAddRow = () => {
        handleAddTableMulti()
        if(!openTable) setOpenTable(true)
    }

    const handleAddTableMulti = async () => {
        if (rows.length > 0) {
            let isEmpty = true
            let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        let _newRow = createData()
                        if (recap) setRows([..._rows, _newRow, createRecapData()]);
                        else setRows([..._rows, _newRow]);
                        setNewRow(_newRow)
                    }
                    else {
                        setOpenRow(_rows.length - 1)
                    }
                }
                else {
                    let _newRow = createData()
                    if (recap) setRows([_newRow, createRecapData()])
                    else setRows([_newRow]);
                    setNewRow(_newRow)
                }
            } else {
                console.log('Toast', toast)
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        }
        else {
            let _newRow = createData()
            if (recap) setRows([...rows, _newRow, createRecapData()])
			else setRows([...rows, _newRow]);
            setNewRow(_newRow)
        }
    }

    const handleAddRecap = () => {
        if (rows.length > 0 && !rows[rows.length - 1].hasOwnProperty('recap')) setRows([...rows, createRecapData()]);
    }

    const handleExportTableMulti = () => {
        let dataRows = [];
        if (colonnes.length > 0){
            let line1 = [];
            line1.push(t('export.export-date'))
            line1.push(t('export.export-key'))
            line1.push(t('export.export-number'))
            line1.push(t('export.export-field-number'))
            line1.push(t('export.export-field'))
            colonnes.forEach(col => {
                if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
            });

            dataRows.push(line1);
            rows.forEach((row, index) => {
                let dataRow = [];
                // date et heure de l'export
                // ajouter Clé du plugform
                // Numero de plugnote
                // Numero du champ
                // Libellé du champ
                let now = new Date()
                dataRow.push(now.toLocaleString())
                dataRow.push(props.plugKey)
                dataRow.push(plugNb)
                dataRow.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
                dataRow.push(props.title)

                colonnes.forEach(col => {
                    if (isVisibleInCSV(col, props.mode)) {
                        let someText = typeof row[col.id] === 'string'
                            ? row[col.id].replace(/\r?\n?/g, '').trim()
                            : row[col.id]
                        if (someText === undefined) {
                            someText = "";
                        }
                        dataRow.push(someText);
                }})
                dataRows.push(dataRow);
            });
        }

        if (dataRows.length > 0) {
            setDatasToDownload(dataRows);
            setOpenDownload(true);
        }
    }

    useEffect(() => {
        if (openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        setOpenRow(null)
        let items
        if (Array.isArray(value)) {
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
            items = rows.filter((row, index) => index !== value);
        }
        if (recap) {
            let row = items.find(row => row?.recap === true)
            if (row && items.length > 1) {
                let rindex = items.indexOf(row)
                colonnes.forEach( (col, index) => {
                    if (col?.recap){
                        row["recap"] = true;
                        row[col.id] = getAllValueFromRowsToSpecificIndex(items, col.id)
                    }
                })
                items[rindex] = row;
                setRows([...items]);
            }
            else setRows([])
        }
        else setRows(items);
    };

    function createData() {
        let data = {};
        colonnes.forEach((col, index) => {
            data[col.id] = '';
        })
        return data;
    }

    function createRecapData() {
        let data = {};
        colonnes.forEach((col, index) => {
            if (col?.recap){
                data["recap"] = true;
                data[col.id] = 0
            }
        })
        return data;
    }

    function refreshRecapData () {
        let _rows = [...rows]
        let row = _rows.find(row => row?.recap === true)
        if (row) {
            let rindex = _rows.indexOf(row)
            colonnes.forEach( (col, index) => {
                if (col?.recap){
                    row["recap"] = true;
                    row[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
                }
            })
            rows[rindex] = row;
            setRows([...rows]);
        }
    }

    const getAllValueFromRowsToSpecificIndex = (rows, id) => {
        let total
		let format = getCellFormat(id, colonnes)
        if (format.format === "time" || format.format === "duration" || (typeof rows[0] !== "undefined" && isNaN(Number(rows[0][id])))) {
            total = ''
        }
        else {
            total = 0;
            rows.forEach((row, index) => {
                if (row[id] && row?.recap !== true) {
                    // console.log('row', row, format);
                    total += Number(row[id]);
                }
            });
        }

        return total;
    }

    const handleChangeTableValue =  () => {
        props.handleChangeTableValue(rows, props.field, props.index);
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        colonnes.forEach((elm, index) => {
            let tmpValue = row[elm.id];
            if (Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }
            if (elm.format === 'numeric' && row[elm.id] !== undefined && typeof row[elm.id] !== 'number'){
                if (tmpValue !== null) tmpValue = row[elm.id].replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });

        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            let newFormula =  formula;

            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);

            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')){
                    answer =  mySum;
                }else{
                    answer =  "";
                }
            }catch (e){
                //setResult(e.message)
                console.log(e.message);
                answer =  "";
            }

            hfInstance.destroy();

        }
        return answer;
    }

    const handleChangeChildrenValue =  async (value, col, row, rindex) => {
        if (!props.disabled) {
            let copyColonnes = [...colonnes];
            let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);
            //console.log('children value inputs:', value, col, row, rindex,obj);
            switch (col.format) {
                case 'presel':
                    let multiselect = col.mask.multiselect;
                    let editable = col.mask.editable;
                    if (multiselect === false && editable === false) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === false && editable === true) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === false) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === true) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                default:
                    row[col.id] = value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                             if (tmpValue !== null){
                                 row[list.id] = tmpValue
                             }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                    break;
            }
            refreshRecapData();
        }
    }

    const handleChangeChildrenRowValue =  async (value, col, row, rindex) => {
        if (!props.disabled) {
            switch (col.format) {
                case 'intdata':
                case 'extdata':
                    rows[rindex] = value;
                    setRows([...rows]);
                    break;
                default:
                    break;
            }
            // refreshRecapData();
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    const handleClickPreview = (index, id) => {
        if (!disabled) {
            if (isDefined(id)) { setOpenRow({row: rows[index], id: id}); }
        }
        handleViewTable();
    }

    function isLimitReached() {
        return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }

    useEffect(() => {
        handleChangeTableValue();
        setTableLength(rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap')? rows.length - 1 : rows.length)
    }, [rows]);

    useEffect(() => {
        let tmpDisabled = false;
        if (typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if (typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props.disabled, props.field.disabled]);

    // console.log('F32 [props]', props);

    return (
    colonnes.length > 0 &&
    <div className={classes.mainCtn}>
        <div className={classes.rowCtn}>
            <div className={classes.topBtnCtn}>
                {
                    rows.length > 0 &&
                    <PlugTooltip title={t('tablemulti.table-view').concat(` - ${tableLength} `).concat(t('tablemulti.rows'))} placement="top">
                        <div className={classes.topBtn} onClick={handleViewTable}>
                            <span id="icon-add-playlist" className="material-icons">
                                visibility
                            </span>
                            <span style={{fontSize: 10, fontWeight: 'bolder',}}>
                                {`(${tableLength})`}
                            </span>
                        </div>
                    </PlugTooltip>
                }

                {
                    !disabled && props.field.options?.setup?.rowLimit !== 1 &&
                    <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                        <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
                            <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                add_circle_outline
                            </span>
                        </div>
                    </PlugTooltip>
                }
            </div>
        </div>
        <ModalTablemultiTableViewComponent
            {...props}
            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
            colonnes={colonnes}
            datasToDownload={datasToDownload}
            disabled={disabled}
            field={props.field}
            handleAddRecap={handleAddRecap}
            handleAddTableMulti={handleAddTableMulti}
            handleChangeChildrenRowValue={handleChangeChildrenRowValue}
            handleChangeChildrenValue={handleChangeChildrenValue}
            handleClose={() => setOpenTable(false)}
            handleClickPreview={handleClickPreview}
            handleDeleteRow={handleRemoveSpecificRow}
            handleExportTableMulti={handleExportTableMulti}
            handleSafeExitPlugnote={props.handleSafeExitPlugnote}
            hasDb={false}
            isPreview={!openTable}
            mode={props.mode}
            model={props.model}
            newRow={newRow}
            open={openTable}
            openDownload={openDownload}
            openRow={openRow}
            owner={owner}
            ownerId={ownerId}
            plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
            recap={recap}
            rows={rows}
            setNewRow={setNewRow}
            setOpenRow={setOpenRow}
            title={props.title}
        />
    </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        "plugKey": state?.model?.model?.clef || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultipleComponent);
