import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {Content, Header} from "rsuite";
import {getCurrentEnvironnement, setLanguage, setLoadModel} from "../../utils/Common";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    getUserConfigRequest,
    getUserQuotaRequest,
    getUserRequest, postUserConfigRequest,
    putUserConfigRequest,
    putUserRequest
} from "../../actions/user";
import Input from "@material-ui/core/Input";
import {KeyboardDatePicker} from "@material-ui/pickers";
import PhoneInput from "react-phone-number-input";
import MenuItem from "@material-ui/core/MenuItem";
import * as moment from "moment";
import {useTranslation} from "react-i18next";
import {isArray} from "lodash";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '72px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        position: 'absolute',
        width : '100%'
    },
    openCloseRelative: {
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'10px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    content: {
        //width : `calc(100% - ${drawerWidth}px)`,
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 20px',
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        //display: 'flex',
        //backgroundColor : 'white',
        //flexWrap : 'wrap',
        //justifyContent: 'space-evenly',
        //columnGap: '20px',
        maxHeight : 'calc(80vh - 100px)',
    },
    contentModelList : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight : 'calc(80vh - 100px)',
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    MuiFormControl : {
        marginTop : 0,
        marginBottom : 0
}
}));


function Params(props) {

    const [t, i18n] = useTranslation('common');

    const classes = useStyles();
    const theme = useTheme();
    const environment = getCurrentEnvironnement();

    const [listView, setListView] = useState(false);
    const [open, setOpen] = React.useState(true);
    const [fetching, setFetching] = React.useState(true);
    const [fetchingConfig, setFetchingConfig] = React.useState(false);
    const [mainUser, setMainUser] = React.useState(null);

    const [username, setUsername] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [pseudo, setPseudo] = React.useState("");
    const [sexe, setSexe] = React.useState("");
    const [birthday, setBirthday] = React.useState('');
    const [langue, setLangue] = React.useState("");
    const [mobile, setMobile] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [devise, setDevise] = React.useState("");
    const [ancre, setAncre] = React.useState("mce");


    const [abonnementInfos, setAbonnementInfos] = React.useState(null);
    const [C1, setC1] = React.useState(null);
    const [C2, setC2] = React.useState(null);
    const [C3, setC3] = React.useState(null);



    const [oksign_account_nb, setOksign_account_nb] = React.useState({id: "", user_id: "", clef: 'oksign_account_nb', valeur: ''});
    const [oksign_authorisation_token, setOksign_authorisation_token] = React.useState({id: "", user_id: "", clef: 'oksign_authorisation_token', valeur: ''});
    const [oksign_organizational_token, setOksign_organizational_token] = React.useState({id: "", user_id: "", clef: 'oksign_organizational_token', valeur: ''});


    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSaveUser = () => {

        let payload = {
            "mobile": mainUser.mobile,
            "firstname": mainUser.firstname,
            "lastname": mainUser.lastname,
            "email": mainUser.email,
            "username": mainUser.username,
            "company": mainUser.company,
            "pseudo": mainUser.pseudo,
            "gender": mainUser.sexe.toString(),
            "language": mainUser.langue.toString(),
            "birthday":  !!mainUser.birthday && mainUser.birthday !== '' && mainUser.birthday !== null ? moment(mainUser.birthday, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : "",
            "devise": mainUser.aboPackagesDevise.toString()
        };

        props.attemptSetUserInfo(payload, props.profil.hash);
    };

    const handleSaveConfigUser = () => {
        let payload = {
            "oksign_account_nb": oksign_account_nb,
            "oksign_authorisation_token": oksign_authorisation_token,
            "oksign_organizational_token": oksign_organizational_token,
        };

        props.attemptSetUserConfig(payload);
    }

    const handleExitUser = () => {
        props.history.push('/models');
    };

    const handleEditUser = (value, property,date) => {

        switch (property) {
            case 'sexe':
                setSexe(value);
                break;
            case 'langue':
                setLangue(value);
                i18n.changeLanguage(value.toLowerCase());
                setLanguage(value.toLowerCase());
                setLoadModel(true);
                break;
            case 'birthday':
                setBirthday(date);
                break;
            case 'mobile':
                setMobile(value);
                break;
            case 'aboPackagesDevise':
                setDevise(value);
                break;
            case 'pseudo':
                setPseudo(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'company':
                setCompany(value);
                break;
        }

        mainUser[property] = value;
        setMainUser(mainUser);

    };

    const handleEditUserconfig = (value, property) => {

        switch (property) {
            case 'oksign_account_nb':
                oksign_account_nb.valeur = value;
                setOksign_account_nb({...oksign_account_nb});
                break;
            case 'oksign_authorisation_token':
                oksign_authorisation_token.valeur = value;
                setOksign_authorisation_token({...oksign_authorisation_token});
                break;
            case 'oksign_organizational_token':
                oksign_organizational_token.valeur = value;
                setOksign_organizational_token({...oksign_organizational_token});
                break;
        }
    };


    useEffect(() => {
        if(fetching){
            props.attemptGetUserInfo();
            setFetching(false);
        }


    },[fetching]);

    useEffect(() => {
        if(fetchingConfig){
            props.attemptGetUserConfig();
            setFetchingConfig(false);
        }
    },[fetchingConfig]);

    useEffect(() => {
        if(props.main !== null){
            setUsername(props.main.username);
            setCompany(props.main.company);
            setPseudo(props.main.pseudo);
            setSexe(props.main.sexe);
            setBirthday(!!props.main.birthday && props.main.birthday !== '' && props.main.birthday !== null ? moment(props.main.birthday,'DD/MM/YYYY').format('YYYY-MM-DD') : '');
            setEmail(props.main.email);
            setLangue(props.main.langue);
            setMobile(props.main.mobile);
            setDevise(props.main.aboPackagesDevise);
            setMainUser(props.main);
        }
    },[props.main]);

    useEffect(() => {
        if(props.config !== null){
            if(isArray(props.config)){
                props.config.map((cfg, index) => {
                    if(typeof cfg.clef !== "undefined"){
                        if(cfg.clef === 'oksign_account_nb'){
                            setOksign_account_nb(cfg)
                        }

                        if(cfg.clef ===  'oksign_authorisation_token'){
                            setOksign_authorisation_token(cfg)
                        }

                        if(cfg.clef ===  'oksign_organizational_token'){
                            setOksign_organizational_token(cfg)
                        }
                    }

                });
            }
        }
    },[props.config]);

    useEffect(() => {
        if(!fetchingConfig && ancre === "cfg"){
            setFetchingConfig(true);
        }

    }, [ancre])

    useEffect(() => {
        props.attemptGetUserQuota(environment.userhash);
    },[]);

    useEffect(() => {
        if(props.package !== null){
            setAbonnementInfos(props.package);
            if(props.packages.length > 0){
                props.packages.map((pack, index) => {
                    if(pack.critera === 'C1'){
                        setC1(pack);
                    }

                    if(pack.critera === 'C2'){
                        setC2(pack);
                    }

                    if(pack.critera === 'C3'){
                        setC3(pack);
                    }
                })
            }
        }
    },[props.package, props.packages]);

  return (
      <div className={classes.root}>
          <CssBaseline />

          <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
              })}
              classes={{
                  paper: clsx(classes.paperAnchorDockedLeft,{
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                  }),
              }}
          >
              <div className={classes.toolbar}/>

              <div className={classes.openCloseRelative}>
                  <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                      {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
              </div>

              <List>
                  <div className="left-menu_contents">
                      <div className="left-menu_contents_wrapper">
                          <ul className="left-menu_contents_actions">
                              <li className="cursor" onClick={(event) => setAncre("mce")}>
                                    <span className="material-icons md-25 ">
                                        people_alt
                                    </span>
                                  <div className={ancre === 'mce' ?'selected-leftmenu' : ''}>{t('common:params.my-account')}</div>
                              </li>
                              <li className="cursor" onClick={(event) => setAncre("mat")}>
                                    <span className="material-icons md-25 ">
                                        card_membership
                                    </span>
                                  <div className={ancre === 'mat' ?'selected-leftmenu' : ''}>{t('common:params.my-subscription')}</div>
                              </li>
                              <li className="cursor" onClick={(event) => setAncre("cfg")}>
                                    <span className="material-icons md-25 ">
                                        settings
                                    </span>
                                  <div className={ancre === 'cfg' ?'selected-leftmenu' : ''}>{t('common:params.my-configs')}</div>
                              </li>
{/*                              <li>
                                    <span className="material-icons md-25 ">
                                        settings
                                    </span>
                                  <div>Paramètres</div>
                              </li>
                              <li>
                                    <span className="material-icons md-25 ">
                                        lock
                                    </span>
                                  <div>Confidentialité</div>
                              </li>*/}

                          </ul>
                      </div>
                  </div>
              </List>

              <div className={classes.openClose}>
                  <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                      {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
              </div>
          </Drawer>




          {
              ancre === 'mce' &&
              <main className={classes.content}>
                  <Header>
                      <div className="flexbox-container">

                          <div className="sous-header p1 m2 grey">

                              <div className="sous-header_content">
                                  <div>

                                  </div>
                                  <div className="entête">
                                      <span className="titres black">{t('common:params.my-account')} <br/></span>
                                  </div>

                                  <div>
                                      <button className="button" onClick={handleSaveUser}>{t('common:params.subscription-save')}</button>
                                      <span id="icon-close" className="material-icons md-30" onClick={handleExitUser}>
                                      close
                                  </span>
                                  </div>
                              </div>

                              <div className="sous-header_content black">
                                  <div className="line flex_style">
                                  </div>
                              </div>

                              <div className="sous-header_content lightgrey md-30">

                              </div>

                          </div>
                      </div>
                  </Header>
                  <Content className={!listView ? classes.contentModelGrid : classes.contentModelList}>
                      <div className="moncompte-container">
                          <div className="moncompte-avatar">
                                  <span className="material-icons green font_size_100"> account_circle </span>
                          </div>
                          <ul className="mon-compte_profil">
                              <li>
                                  <span className="green">{t('common:params.login')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  onChange={(event) => handleEditUser(event.target.value, 'username')}
                                  value={username}
                              />
                              </span>
                              </li>
                              <li>
                                  <span className="green">{t('common:params.pseudo')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  onChange={(event) => handleEditUser(event.target.value, 'pseudo')}
                                  value={pseudo}
                              />
                              </span>
                              </li>
{/*
                              <li>
                                  <span className="green">{t('common:params.sexe')}</span>
                                  <span className="black">
                                  <Select
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={sexe}
                                      disableUnderline={true}
                                      onChange={(event) => handleEditUser(event.target.value, 'sexe')}
                                  >
                                        <MenuItem value={0}>{t('common:params.man')}</MenuItem>
                                        <MenuItem value={1}>{t('common:params.woman')}</MenuItem>
                                        <MenuItem value={2}>{'NC'}</MenuItem>
                                    </Select>
                              </span>
                              </li>
*/}
                              <li>
                                  <span className="green">{t('common:params.language')}</span>
                                  <span className="black">
                                  <Select
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={langue}
                                      disableUnderline={true}

                                      onChange={(event) => handleEditUser(event.target.value, 'langue')}
                                  >
                                        <MenuItem value='fr'>{'FR'}</MenuItem>
                                        <MenuItem value='en'>{'EN'}</MenuItem>
                                    </Select>
                              </span>
                              </li>
{/*
                              <li>
                                  <span className="green">{t('common:params.birthday')}</span>
                                  <span className="black">
                                          <KeyboardDatePicker
                                              placeholder="yyyy-MM-dd"
                                              className={classes.MuiFormControl}
                                              variant="inline"
                                              format="yyyy-MM-dd"
                                              margin="normal"
                                              fullWidth={true}
                                              InputProps={{
                                                  disableUnderline: true,
                                              }}
                                              id={'date-picker-inline'}
                                              //label={props.field.title}
                                              KeyboardButtonProps={{
                                                  'aria-label': 'change date',
                                              }}
                                              value={birthday ? birthday : null}
                                              onChange={(date,value) => handleEditUser(value, 'birthday', date)}
                                              //onChange={handleDateChange}
                                              // onChange={(date, value) => {
                                              //     handleDateChange(date);
                                              //     props.handleEditDateTime(date, value, props.field, props.index);
                                              // }}
                                              //value={field.value.length > 0 ? field.value : null}
                                              // onChange={(date, value) => {
                                              //     handleEditDateTime(date,value, field, index);
                                              // }}
                                          />
                              </span>
                              </li>
*/}
                              <li>
                                  <span className="green">{t('common:params.email')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  disabled={true}
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  //onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                                  value={email}
                              />
                              </span>
                              </li>
                              <li>
                                  <span className="green">{t('common:params.mobile')}</span>
                                  <span className="black">
                                  <PhoneInput
                                      defaultCountry="BE"
                                      value={mobile}
                                      onChange={(value) => handleEditUser(value, 'mobile')}
                                      //onChange={(value) => handleEditPhoneValue(value, field, index)}
                                  />
                              </span>
                              </li>
                              <li>
                                  <span className="green">{t('common:params.company')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  disabled={false}
                                  onChange={(event) => handleEditUser(event.target.value, 'company')}
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  //onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                                  value={company}
                              />
                              </span>
                              </li>
                              <li>
                                  <span className="green">{t('common:params.devise')}</span>
                                  <span className="black">
                                  <Select
                                      disableUnderline={true}
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={devise}
                                      onChange={(event) => handleEditUser(event.target.value, 'aboPackagesDevise')}
                                      //onChange={(event) => setTemplate(event.target.value)}
                                  >
                                        <MenuItem value='EUR'>{'EUR'}</MenuItem>
                                        <MenuItem value='USD'>{'USD'}</MenuItem>
                                    </Select>
                              </span>
                              </li>
                          </ul>


                      </div>

                  </Content>
              </main>
          }

          {
              ancre === 'mat' &&
                  <main className={classes.content}>

                      <div className="flexbox-container">
                          <div className="sous-header p1 m2 grey">

                              <div className="sous-header_content">
                                  <div>
                                  </div>
                                  <div className="entête">
                                      <span className="titres black">{t('common:params.my-subscription')} <br/></span>
                                  </div>

                                  <div>
                                      {/*<button className="button">SAUVER</button>*/}
                                      <span id="icon-close" className="material-icons md-30" onClick={handleExitUser}>
                                        close
                                      </span>
                                  </div>
                              </div>

                              <div className="sous-header_content black">
                                  <div className="line flex_style">
                                  </div>
                              </div>

                              <div className="sous-header_content lightgrey md-30">

                              </div>

                          </div>
                      </div>

                      <div className="moncompte-container">
                          <div className="moncompte-avatar">
                                  <span className="material-icons md-30 green"
                                        style={{fontSize : '100px'}}> account_circle </span>
                          </div>
                          <ul className="mon-abonnement">
                              <li>
                                  <span className="black">{t('common:params.your-subscription')}&nbsp;:</span>
                                  <span className="green">&nbsp;{abonnementInfos['abonnement_'+currentLanguageLower]}
                            <span id="icon-blockForm" className="material-icons md-small lightgrey">
                            help_outline
                            </span>
                        </span>
                              </li>
                              <li>
                                  <span className="black">{t('common:params.end-subscription')}&nbsp;:</span>
                                  <span className="green">&nbsp;{abonnementInfos.abo_package_validity}</span>
                              </li>
                              {/*<li>*/}
                              {/*    <span className="black">Informations de payement&nbsp;:</span>*/}
                              {/*    <span className="green">&nbsp;visa 4242 - 2/ 2023 - US</span>*/}
                              {/*</li>*/}

                          </ul>
                      </div>

                      <div className="meter-container m1 flexSpacebetween">
                          <div>
                              <div className="meter">
                                  <span style={{width : (C1.quota / C1.seuil_personal_limit) * 100 }} className={((C1.quota / C1.seuil_personal_limit) * 100) >= 90 ? 'back-red' : 'back-green' }></span>
                              </div>
                              <span> {t('common:params.subscription-plugnote-number')}&nbsp;:
                        <span className={((C1.quota / C1.seuil_personal_limit) * 100) >= 90 ? 'red' : 'green' }>&nbsp;{C1.quota}</span>
                        <span className="lightgrey">&nbsp;/ {C1.seuil_personal_limit}</span>
                    </span>
                          </div>
                          <div>
                              <div className="meter">
                                  <span style={{width : (C2.quota / C2.seuil_personal_limit) * 100 }}  className={((C2.quota / C2.seuil_personal_limit) * 100) >= 90 ? 'back-red' : 'back-green' }></span>
                              </div>
                              <span> {t('common:params.subscription-plugnote-volume')}&nbsp;:
                        <span className={((C2.quota / C2.seuil_personal_limit) * 100) >= 90 ? 'red' : 'green' }>&nbsp;{C2.quota.toFixed(2)} Gb</span>
                        <span className="lightgrey">&nbsp;/ {C2.seuil_personal_limit} GB</span>
                    </span>
                          </div>
                          <div>
                              <div className="meter">
                                  <span style={{width : (C3.quota / C3.seuil_personal_limit) * 100 }} className={((C3.quota / C3.seuil_personal_limit) * 100) >= 90 ? 'back-red' : 'back-green' }></span>
                              </div>
                              <span> {t('common:params.subscription-plugnote-univers')}&nbsp;:
                        <span className={((C3.quota / C3.seuil_personal_limit) * 100) >= 90 ? 'red' : 'green' }>&nbsp;{C3.quota}</span>
                        <span className="lightgrey">&nbsp;/ {C3.seuil_personal_limit}</span>
                    </span>
                          </div>

{/*                          <div class="meter">
                              <span></span>
                              <span> Volume stocké&nbsp;:
                                  <span class="green">&nbsp;0.01 GB</span>
                                  <span class="grey">&nbsp;/ 2.5 Gb</span>
                              </span>
                          </div>
                          <div class="meter">
                              <span></span>
                              <span> Nombre d'univers&nbsp;:
                                  <span class="green">&nbsp;20</span>
                                  <span class="grey">&nbsp;/ 50</span>
                              </span>
                          </div>*/}
                      </div>


                  </main>
          }

          {
              ancre === 'cfg' &&
              <main className={classes.content}>
                  <Header>
                      <div className="flexbox-container">

                          <div className="sous-header p1 m2 grey">

                              <div className="sous-header_content">
                                  <div>

                                  </div>
                                  <div className="entête">
                                      <span className="titres black">{t('common:params.my-configs')} <br/></span>
                                  </div>

                                  <div>
                                      <button className="button" onClick={handleSaveConfigUser}>{t('common:params.subscription-save')}</button>
                                      <span id="icon-close" className="material-icons md-30" onClick={handleExitUser}>
                                      close
                                  </span>
                                  </div>
                              </div>

                              <div className="sous-header_content black">
                                  <div className="line flex_style">
                                  </div>
                              </div>

                              <div className="sous-header_content lightgrey md-30">

                              </div>

                          </div>
                      </div>
                  </Header>
                  <Content className={!listView ? classes.contentModelGrid : classes.contentModelList}>
                      <div className="moncompte-container">
                          <div className="entête">
                              <span className="titres black">{t('common:params.oksign')} <br/></span>
                          </div>
                          <ul className="mon-compte_profil">
                              <li>
                                  <span className="green">{t('common:params.oksign_account_nb')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  onChange={(event) => handleEditUserconfig(event.target.value, 'oksign_account_nb')}
                                  value={oksign_account_nb.valeur}
                              />
                              </span>
                              </li>
                              <li>
                                  <span className="green">{t('common:params.oksign_authorisation_token')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  onChange={(event) => handleEditUserconfig(event.target.value, 'oksign_authorisation_token')}
                                  value={oksign_authorisation_token.valeur}
                              />
                              </span>
                              </li>
                              <li>
                                  <span className="green">{t('common:params.oksign_organizational_token')}</span>
                                  <span className="black">
                              <Input
                                  id="standard-full-width"
                                  disableUnderline={true}
                                  label="Label"
                                  fullWidth
                                  margin="none"
                                  inputlabelprops={{
                                      shrink: true,
                                  }}
                                  inputProps={
                                      {
                                          className: classes.inputField
                                      }
                                  }
                                  className="black"
                                  onChange={(event) => handleEditUserconfig(event.target.value, 'oksign_organizational_token')}
                                  value={oksign_organizational_token.valeur}
                              />
                              </span>
                              </li>
                          </ul>


                      </div>

                  </Content>
              </main>
          }

      </div>
  );
}

const mapStateToProps = (state) => {

    const listEnvironment = state?.user?.profil?.filter((value, index) => {
        if(!value.modelsshared){
            return value;
        }
    }) || [];

    const sortedListEnvironnement = listEnvironment.sort((left, right) => {
        if(left.profilid === ''){
            return -1
        }else{
            return left.profilid < right.profilid ? -1 : 1;
        }
    });

    return {
        sortedListEnvironnement : sortedListEnvironnement,
        listEnvironment : listEnvironment,
        user : state.user,
        profil : state.user.profil,
        main : state.user.main,
        config : state.user.config,
        token : state.user.token,
        fetching : state.user.fetching,
        package : state.user.package,
        packages: state.user.packages,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetUserInfo: (userhash) => dispatch(getUserRequest(userhash)),
        attemptGetUserConfig: (userhash) => dispatch(getUserConfigRequest(userhash)),
        attemptSetUserInfo: (payload,userhash) => dispatch(putUserRequest(payload,userhash)),
        attemptSetUserConfig: (payload) => dispatch(postUserConfigRequest(payload)),
        attemptGetUserQuota: (userHash) => dispatch(getUserQuotaRequest(userHash)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Params)
