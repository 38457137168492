import {api} from "./index";

export let
    postExtData,
    postExtDataPreview,
    postExtDataGenerateTable;


postExtData = (payload) => api.post('extdata/v3s/retrieves/datas.json', payload);
postExtDataPreview = (payload) => api.post('extdata/uniques/datas.json', payload);
postExtDataGenerateTable = (payload) => api.post('extdata/generates/datas.json', payload);

