import React, {useState} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import ApplicationConfig from "../../config/ApplicationConfig";

/**
 * @return {null}
 */
function Intercom(props) {

    const [t, i18n] = useTranslation('common');
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase());
    const location = useLocation();

    if(ApplicationConfig.intercom){
        if(!location.pathname.includes('/plugcodes-shared')){
            if(props.profil === null || typeof props.profil === 'undefined'){
                window.Intercom('shutdown');
                window.Intercom('boot', {
                    app_id: 'm63djv8b',
                    hide_default_launcher : false,
                });
            }
            else{
                let name = !!props.profil.lastname && props.profil.lastname !== '' && !!props.profil.firstname && props.profil.firstname !== '' ? props.profil.lastname+ '-' + props.profil.firstname : props.profil.pseudo
                let nombre_plugforms = 0;
                let nombre_plugnotes = 0;
                let nombre_annexes = 0;
                let nombre_univers = 0;
                let nombre_univers_partages = 0;
                let nombre_plugforms_shared = 0;
                let nombre_plugnotes_shared = 0;

                props.listenvironnement.forEach((profile) => {
                    if(props.profil.type === 'user' || props.profil.type === 'admin'){
                        if(profile.modelsshared !== 1){
                            nombre_plugforms += parseInt(profile.count_pf);
                            nombre_plugnotes += parseInt(profile.count_pn);
                            nombre_annexes += parseInt(profile.count_annexes);
                            nombre_plugforms_shared += parseInt(profile.count_pf_shared);
                            nombre_plugnotes_shared += parseInt(profile.count_pn_shared);
                            nombre_univers += 1;
                        }else if(profile.modelsshared === 1){
                            nombre_univers_partages += 1;
                        }
                    }
                });


                window.Intercom('boot', {
                    app_id: 'm63djv8b',
                    email: props.profil.email,
                    phone: props.profil.mobile,
                    user_id: props.profil.id,
                    name: name,
                    browser_language : currentLanguage,
                    language_override : currentLanguage,
                    horizontal_padding: 20,
                    vertical_padding: 20,
                    //created_at: 1234567890,
                    custom_launcher_selector: '#menu_open_intercom_button',
                    hide_default_launcher : true,
                    nombre_univers: nombre_univers,
                    nombre_univers_partages: nombre_univers_partages,
                    nombre_plugforms: nombre_plugforms,
                    nombre_plugnotes: nombre_plugnotes,
                    nombre_annexes: nombre_annexes,
                    nombre_plugforms_shared : nombre_plugforms_shared,
                    nombre_plugnotes_shared : nombre_plugnotes_shared,
                });

                window.Intercom("update", {
                    app_id: 'm63djv8b',
                    email: props.profil.email,
                    phone: props.profil.mobile,
                    user_id: props.profil.id,
                    name: name,
                    browser_language : currentLanguage,
                    language_override : currentLanguage,
                    horizontal_padding: 20,
                    vertical_padding: 20,
                    custom_launcher_selector: '#menu_open_intercom_button',
                    hide_default_launcher : true,
                    nombre_univers: nombre_univers,
                    nombre_univers_partages: nombre_univers_partages,
                    nombre_plugforms: nombre_plugforms,
                    nombre_plugnotes: nombre_plugnotes,
                    nombre_annexes: nombre_annexes,
                    nombre_plugforms_shared : nombre_plugforms_shared,
                    nombre_plugnotes_shared : nombre_plugnotes_shared,
                })

                window.Intercom('onUnreadCountChange', function(unreadCount) {
                    // Do stuff...
                    window.onUnreadCountChange = unreadCount;
                });

            }
        }
    }


    return null;
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        listenvironnement : !!state.user.user ? state.user.user.profil : [] ,
        profil : state.user.profil
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Intercom)
