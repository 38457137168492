import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getConnected, getCurrentEnvironnement, getToken} from '../utils/Common';

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {

    const isAuthenticated = () => {
        return getConnected() === 'true' && getToken() !== 'null' && getCurrentEnvironnement() !== 'null';
    }

    return (
        <Route
            {...rest}
            render={(props) => !isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: '/models' }}/> }
        />
    )
}
export default PublicRoute
