import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {isEmpty, sortBy} from "lodash";
import {getFieldsRequest} from "../../actions/field";
import {postModelRequest} from "../../actions/model";
import {
    getTemplateCodeCategorieRequest,
    getTemplateCodeGroupRequest,
    getTemplateCodeModelRequest,
    resetTemplateHistoryRequest, 
    resetTemplateOpenListCategorieRequest,
    resetTemplateOpenListGroupRequest,
    resetTemplateOpenListModelsRequest
} from "../../actions/template";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken, getModel} from "../../utils/Common";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Content, Header} from "rsuite";
import Input from "@material-ui/core/Input";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {
    deleteDeleteTranslationByModelRequest,
    getAllTranslationsByModelRequest,
    getOneTranslationByModelRequest,
    postCreateTranslationByModelRequest, putUpdateTranslationByModelRequest
} from "../../actions/translate";
import Select from "react-select";
import {formatTypes} from "../../utils/Compatibility";
import {FormControl, TextField} from "@material-ui/core";
import Images from "../../assets/themes/Images";
import colors from '../../assets/themes/Colors';
import { isDefined } from '../../utils/utils';


const drawerWidth = 320;

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    })
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mandatory:{
        color : '#E54F62',
    },
    notMandatory:{
        color : '#7F7F7F',
    },
    isTrue:{
        color : '#58C3C1',
    },
    isFalse:{
        color : '#E54F62',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '40px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
        padding: '20px 20px',
    },
    contentModel : {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow : 'scroll',
        maxHeight : 'calc(80vh - 100px)'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'blue'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldIconSvg : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    field : {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldLeft : {
        alignContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
    },
    fieldRightLeft : {
        alignContent: 'flex-end'
    },
    fieldRight:{
        textAlign : 'center'
    },
    fullWidth :{
        width : '100%'
    },
    paper : {
        display: 'block !important',
        overflow : 'hidden !important'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    listHeight:{
        height: '100%'
    },
    buttonCtn: {
        height: 52,
        marginLeft: 30
    },
    createBtn: {
        width: 'max-content'
    },
    subtitleCtn: {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldCtn: {
        marginBottom: 8,
    },
    // Select
    selectsCtn: {
        minWidth: 100,
        minHeight: 40,
        marginRight: 30,
    },
    // Sub-Fields
    subfieldsCtn: {
        marginLeft: 25,
        // marginRight: 15,
    },
    subfieldCtn: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    subfield: {
        // marginLeft: 25,
        flex: 1,
    },
    subfieldNb: {
		fontSize: 20,
        fontWeight: 'bold',
		color: colors.main,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '2px solid',
		borderRadius: '50%',
		verticalAlign: 'middle',
		width: 30,
		height: 30,
	},
    // Sub-Presels
    subPreselsCtn: {
        paddingLeft: 40,  
    },
    subPreselNb: {
		fontSize: 12,
        fontWeight: 'bold',
		color: colors.main,
		display: 'inline-flex',
        width: 30,
        height: 30,
		border: '1px solid',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
    },
    translationSelect: {
        lineHeight: 0.6,
        fontSize: 9,
        display: 'flex',
        flexDirection: 'column',
    },
    fieldWithSubfieldsCtn: {
        display: 'flex',
        flexDirection: 'row',
    },
    openSubfieldsBtn: {
        flex: 0.2,
        margin: '3px 15px',
        borderRadius: 4,
        backgroundColor: colors.mainButton,
        color: 'white',
        cursor: 'pointer',
        minWidth: 180,
        alignSelf: 'center',
        padding: '8px 15px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
    }
}));


function ModelTranslate(props) {
    const classes = useStyles();
    const hasSubfields = ["tablemulti", "smailvalidmulti", "tablemultin", "document", "select", ] // ajouter table
    const { t, i18n } = useTranslation(['common', 'models']);
    const [myModel, setMyModel] = useState(null)
    const [langue, setLangue] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase() === 'fr' ? '' : 'en');
    const [updateModelLangue, setUpdateModelLangue] = useState([]);
    const [updated, setUpdated] = useState(false);
    const environment = getCurrentEnvironnement();

    const model = getModel();

    function getFieldOptions (field) {
        if (typeof field !== "undefined" && hasSubfields.includes(field.format)) {
            let array = []
            if (field.format === 'select') {
                field.formatoptions.presel.forEach((presel, pindex) => array.push(''))
                return array.length > 0 ? array : null
            }
            else {
                let options = isDefined(field.options.lines) ? field.options.lines : field.options;
                options.forEach((row) => {
                    let presels = {};
                    if ((row.format === 'presel' || row.format === 'select') && row.presel?.length > 0) {
                        row.presel.forEach((entry, index) => presels[index] = '');
                    }
                    array.push((row.format === 'presel' || row.format === 'select') ? { id: row.id, label: '', presels: presels } : { id: row.id, label: '' });
                });
                console.log("add array: ", array);
                return array.length > 0 ? array : null
            }
        }
        else return null
    }
    useEffect(() => {
        axios.get(ApplicationConfig.apiUrl + 'v2/modelunique?mh=' + model.hash + '&uh=' + environment.userhash,
        {crossdomain: true,
            'headers': {
                'X-AUTH-TOKEN': getToken(),
            }
        }).then((response) => {
            // console.log('response', response);
            setMyModel(response.data.data);
        }).catch((response) => {
            setMyModel(null);
        })
    }, [])

    useEffect(() => {
        if (myModel !== null) {
            const labels = Object.keys(myModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key) && myModel[key] != null;
            });

            let fields = labels.map((label, index) => {
                let formatoptions = myModel['field' + (index + 1) + 'formatoptions'] || {};
                if (typeof formatoptions !== 'object') {
                    formatoptions = JSON.parse(formatoptions);
                }

                if(myModel['field' + (index + 1) + 'format'] === "select"){
                    if(typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0){
                        formatoptions.presel = formatoptions.presel.toString().split(";")
                    }else{
                        formatoptions.presel = [];
                    }
                }

                if(myModel['field' + (index + 1) + 'format'] === "table"){
                    if(typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0){
                        formatoptions.presel = formatoptions.presel.toString().split(";")
                    }else{
                        formatoptions.presel = [];
                    }
                }

                let fieldFormat = myModel['field' + (index + 1) + 'format'].toUpperCase();
                // Vérifier les présélections
                if ( fieldFormat === 'SELECT') {

                    if( myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "MULTI_SELECT";
                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "SINGLE_SELECT";

                    }else if(myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_MULTI_SELECT";

                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_SINGLE_SELECT";
                    }

                }

                if (fieldFormat === 'TABLE') {

                    if(myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "MULTI_SELECT_TABLE";
                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "SINGLE_SELECT_TABLE";

                    }else if(myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_MULTI_SELECT_TABLE";

                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_SINGLE_SELECT_TABLE";
                    }

                }
                let obj = {
                    newField: false,
                    dragid : 'item-' + index,
                    key: index + 1,
                    id: index + 1,
                    position : formatTypes[fieldFormat],
                    isEncodable : !!myModel['field' + (index + 1) + 'encodable'] && myModel['field' + (index + 1) + 'encodable'] === 1,
                    isVisible : !!myModel['field' + (index + 1) + 'visible'] && myModel['field' + (index + 1) + 'visible'] === 1,
                    label: myModel['field' + (index + 1) + 'label'],
                    format: myModel['field' + (index + 1) + 'format'],
                    options: {
                        mandatory: !!myModel['field' + (index + 1) + 'mandatory'],
                        ...formatoptions
                    },
                    formatoptions: formatoptions
                }
                return obj
            });

            setUpdateModelLangue(fields)
        }
    }, [myModel])

    const [nameModel, ] = useState(model.label);
    const [hash, ] = useState(!!environment ? environment.userhash : null);
    const [generateOptions, setGenerateOptions] = useState([]);
    const [generateFlagOptions, setGenerateFlagOptions] = useState([]);
    const [selectedFlagOption, setSelectedFlagOption] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedTranslate, setSelectedTranslate] = useState(null);
    const [createdTranslate, setCreatedTranslate] = useState(null);
    const [creation, setCreation] = useState(false);
    const [openPresel, setOpenPresel] = useState(null);
    const [openSubPresel, setOpenSubPresel] = useState(null);

    const handleExitModel = () => {
        props.history.push("/models");
    };

    const handleCreateExitModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.exit-saving-model-translation'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleSaveLanguage();
                    }
                },
                {
                    label: t('global.cancel'),
                    onClick: () => {
                        props.history.push("/models");
                    }
                },
            ]
        });
    };

    const handleDeleteTranslationModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleDeleteLanguage();
                    }
                },
                {
                    label: t('global.cancel'),
                },
            ]
        });
    }

    const handleUpdateExitModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.exit-saving-model-translation'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleUpdateLanguage();
                    }
                },
                {
                    label: t('global.cancel'),
                    onClick: () => {
                        props.history.push("/models");
                    }
                },
            ]
        });
    };

    const handleSaveLanguage = () => {
        let payload = createdTranslate;
        payload.modelhash = model.hash;
        payload.userhash = hash;
        props.attemptCreateTranslation(payload);
        setSelectedFlagOption('');
        setCreation(false)
        setCreatedTranslate(null);
        setSelectedOption('');
        setUpdated(false);
    }

    const handleUpdateLanguage = () => {
        let payload = selectedTranslate;
        payload.modelhash = model.hash;
        payload.userhash = hash;
        props.attemptUpdateTranslation(payload);

        setSelectedFlagOption('');
        setCreation(false);
        setSelectedTranslate(null);
        setSelectedOption('');
    }

    const handleDeleteLanguage = () => {
        props.attemptDeleteTranslation(model.hash,hash,selectedTranslate.id);
        setSelectedFlagOption('');
        setCreation(false);
        setSelectedTranslate(null);
        setSelectedOption('');
    }

    const handleChangeOption = async (newValue) => {
        setCreation(false);
        setSelectedOption(newValue);
        let choice = props.list[newValue.indice];

        for (let i = 1; i <= 15 ; i++) {
            if (choice.hasOwnProperty('field' + i + 'formatoptions')) {
                choice['field' + i + 'formatoptions'] = choice['field' + i + 'formatoptions'] !== null ? JSON.parse(choice['field'+i+'formatoptions']) : null
            }
            let format = myModel['field' + i + 'format']
            if (hasSubfields.includes(format) && !choice.hasOwnProperty('field' + i + 'formatoptions')) {
                choice['field' + i + 'formatoptions'] = getFieldOptions(updateModelLangue[i - 1])
            }
        }
        // console.log('choice | after', choice);
        await setSelectedTranslate(choice);
        let image = props.translations.find((item) => {
            return item.id === newValue.translation
        })

        setSelectedFlagOption({
            value : image.id ,
            label : <div><img alt={"flag-" + image.name} src={require('../../assets/images/flags/'+image.name+'.svg').default} height="30px" width="30px"/></div>,
        });
    }

    const handleChangeFlagOptionCreate = (newValue) => {
        // console.log('newValue', newValue);
        createdTranslate.translationId = newValue.value;
        setCreatedTranslate(createdTranslate);
        setSelectedFlagOption(newValue);
    }

    const handleChangeFlagOption = (newValue) => {
        selectedTranslate.translationId = newValue.value;
        selectedTranslate.translation = newValue;
        setSelectedTranslate(selectedTranslate);
        setSelectedFlagOption(newValue);
    }

    const handleCreateTranslation = async () => {
        setSelectedTranslate(null);
        setSelectedOption('');
        setCreation(true);
        setSelectedFlagOption({flag: 'fr', value : 1 , label : <div><img alt='' src={Images.fr} height="30px" width="30px"/></div>});
        await setCreatedTranslate({
            "translation" : {value : 1 , label : <div><img alt='' src={Images.fr} height="30px" width="30px"/></div>},
            "translationId" : 1,
            "description" : "",
            "label" : nameModel,
            "field1" : "",
            "field2" : "",
            "field3" : "",
            "field4" : "",
            "field5" : "",
            "field6" : "",
            "field7" : "",
            "field8" : "",
            "field9" : "",
            "field10" : "",
            "field11" : "",
            "field12" : "",
            "field13" : "",
            "field14" : "",
            "field15" : "",
            "field1formatoptions": getFieldOptions(updateModelLangue[0]),
            "field2formatoptions": getFieldOptions(updateModelLangue[1]) ,
            "field3formatoptions": getFieldOptions(updateModelLangue[2]) ,
            "field4formatoptions": getFieldOptions(updateModelLangue[3]) ,
            "field5formatoptions": getFieldOptions(updateModelLangue[4]) ,
            "field6formatoptions": getFieldOptions(updateModelLangue[5]) ,
            "field7formatoptions": getFieldOptions(updateModelLangue[6]) ,
            "field8formatoptions": getFieldOptions(updateModelLangue[7]) ,
            "field9formatoptions": getFieldOptions(updateModelLangue[8]) ,
            "field10formatoptions": getFieldOptions(updateModelLangue[9]) ,
            "field11formatoptions": getFieldOptions(updateModelLangue[10]) ,
            "field12formatoptions": getFieldOptions(updateModelLangue[11]) ,
            "field13formatoptions": getFieldOptions(updateModelLangue[12]) ,
            "field14formatoptions": getFieldOptions(updateModelLangue[13]) ,
            "field15formatoptions": getFieldOptions(updateModelLangue[14]) ,
        });
        console.log(createdTranslate);
    }

    const handleUpdateTranslation = async () => {
        // console.log('handleUpdateTranslation  selectedTranslate BEFORE', selectedTranslate);
        // console.log('handleUpdateTranslation updateModelLng', updateModelLangue);
        let _selectedTranslate = {...selectedTranslate}
        updateModelLangue.forEach((field, findex) => {
            console.log(`base model field ${findex}`, field);
            if (field.format === 'select' || field.format === 'presel') {
                let formatOptions = selectedTranslate['field' + (findex + 1) + 'formatoptions'];
                // console.log('existing field', formatOptions);
                if (formatOptions.length !== field.formatoptions.presel.length) {
                    let updatedField = field.formatoptions.presel.map((presel, id) => formatOptions[id] || '');
                    _selectedTranslate['field' + (findex + 1) + 'formatoptions'] = updatedField;
                }
            } else {
                let options = field.formatoptions
                if (isDefined(options)) {
                    let subfields = isDefined(options.lines) ? options.lines : options
                    // console.log('subfields', subfields);
                    if (_selectedTranslate.hasOwnProperty('field' + (findex + 1) + 'formatoptions')) {
                        let updatedSubfields = []
                        if (subfields?.length > 0) {
                            subfields.forEach((subfield) => {
                                let existingField = selectedTranslate['field' + (findex + 1) + 'formatoptions'].find(field => field.id === subfield.id);
                                // if (subfield.format === 'presel' || subfield.format === 'select') {console.log('existing field', existingField);}
                                if ((subfield.format === 'presel' || subfield.format === 'select') && !existingField.hasOwnProperty('presels')) {
                                    let presels = {};
                                    subfield.presel.forEach((presel, id) => presels[id] = '');
                                    existingField['presels'] = presels;
                                }
                                updatedSubfields.push((subfield.format === 'presel' || subfield.format === 'select')
                                    ? { id: subfield.id, label: existingField['label'] || '', presels: existingField.presels }
                                    : { id: subfield.id, label: existingField['label'] || '' })
                            });
                        }
                        _selectedTranslate['field' + (findex + 1) + 'formatoptions'] = updatedSubfields
                    }
                }
            }
        })
        // console.log('handleUpdateTranslation selectedTranslate AFTER', _selectedTranslate);
        setSelectedTranslate(_selectedTranslate)
        setUpdated(true)
    }

    function handleChangeSubfield (e, key, index) {
        if (selectedTranslate['field' + key + 'formatoptions'] === undefined) {
            selectedTranslate['field' + key + 'formatoptions'] = getFieldOptions(updateModelLangue[key - 1])
        }
        selectedTranslate['field' + key + 'formatoptions'][index]["label"] = e.target.value
        setSelectedTranslate({ ...selectedTranslate });
    }

    function handleChangeSubfieldPresel (e, index, subindex, pindex) {
        const baseTranslation = creation === true ? createdTranslate : selectedTranslate;
        if (!isDefined(baseTranslation['field' + (index + 1) + 'formatoptions'])) {
            baseTranslation['field' + (index + 1) + 'formatoptions'] = getFieldOptions(updateModelLangue[index])
        }
        // let formatOptions = selectedTranslate['field' + (index + 1) + 'formatoptions'][subindex]
        
        baseTranslation['field' + (index + 1) + 'formatoptions'][subindex]["presels"][pindex] = e.target.value
        if (creation === true) setCreatedTranslate({ ...baseTranslation }); 
        else setSelectedTranslate({ ...baseTranslation });
    }

    function handleCreatePresel (e, key, index) {
        if (createdTranslate['field' + key + 'formatoptions'] === undefined) {
            createdTranslate['field' + key + 'formatoptions'] = getFieldOptions(updateModelLangue[key - 1])
        }
        createdTranslate['field' + key + 'formatoptions'][index] = e.target.value
        setCreatedTranslate({ ...createdTranslate });
    }

    function handleChangePresel(e, key, index) {
        const baseTranslation = creation === true ? createdTranslate : selectedTranslate;
        if (baseTranslation['field' + key + 'formatoptions'] === undefined) {
            baseTranslation['field' + key + 'formatoptions'] = getFieldOptions(updateModelLangue[key - 1])
        }
        baseTranslation['field' + key + 'formatoptions'][index] = e.target.value
        if (creation === true) setCreatedTranslate({ ...baseTranslation }); 
        else setSelectedTranslate({ ...selectedTranslate });
    }

    function handleTogglePresel(id) {
        setOpenPresel(id === openPresel ? null : id)
    }

    function handleToggleSubPresel(id) {
        setOpenSubPresel(id === openSubPresel ? null : id);
    }

    const ErrorToastMessage = ({ title, message }) => (
        <div>
            <h6> {title}</h6>
            <p> {message} </p>
        </div>
    )
    
    // console.log('ModTranslate  selectedTranslate', selectedTranslate);
    // console.log('ModTranslate  createdTranslate', createdTranslate);

    // console.log('ModelTranslate props', props);
    // console.log('ModelTranslate updateModelLangue', updateModelLangue);
    // console.log('ModelTranslate myModel', myModel);

    useEffect(() => {
        let flagOptions = [];
        props.translations
            .sort((a, b) => a.description.localeCompare(b.description))
            .forEach((i, index) => {
            flagOptions.push({
                value : i.id,
                flag: i.name,
                label : <div className={classes.translationSelect}><img alt="" src={require('../../assets/images/flags/'+ i.name +'.svg').default} height="30px" width="30px"/> {i.description}</div>
            });
        });
        setGenerateFlagOptions(flagOptions);

        let options = [];
        props.list.forEach((i, index) => {
            let image = props.translations.find((item) => {
                return item.id === i.translationId
            })
            options.push({
                value : i.id ,
                flag: i.name,
                label : <div><img alt="" src={require('../../assets/images/flags/'+image.name+'.svg').default} height="30px" width="30px"/> {i.description}</div>,
                indice: index,
                translation : i.translationId
            });
        });
        setGenerateOptions(options)

    }, [props.translations, props.list]);

    useEffect(() => {
        props.attemptGetAllTranslations(model.hash, hash)
    }, [hash]);

    useEffect(() => {
        console.log('ue selectedTranslate', selectedTranslate);
        if (selectedTranslate !== null && !updated) handleUpdateTranslation()
    }, [selectedTranslate])

    const renderField = (field, index) => {
        let subfields = []
        if (field.options?.lines !== null && field.options?.lines !== undefined) subfields = field.options.lines;
        else if (field.format === "tablemulti") subfields = field.formatoptions;
        else if (field.format === "select") subfields = field.formatoptions.presel;

        const baseTranslation = creation === true ? createdTranslate : selectedTranslate;
        // console.log('base translation', baseTranslation);
        return (
            <div className={classes.fieldCtn} key={index}>
                <div className={classes.fieldWithSubfieldsCtn}>
                    <TextField
                        fullWidth={true}
                        id="filled-helperText"
                        label={(index + 1) + ' - ' + field.label}
                        value={baseTranslation['field' + field.key]}
                        onChange={(event) => {
                            baseTranslation['field' + field.key] = event.target.value;
                            if (creation === true) setCreatedTranslate({ ...baseTranslation });
                            else setSelectedTranslate({ ...baseTranslation });
                        }}
                        variant="filled"
                    />
                    {
                        subfields.length > 0 &&
                        <div onClick={() => handleTogglePresel(index)} className={classes.openSubfieldsBtn}>
                            Translate sub fields
                        </div>
                    }
                </div>
                {
                    openPresel === index && subfields.length > 0 &&
                    <div className={classes.subfieldsCtn}>
                        {
                            field.format === "select" ?
                                subfields.map((subfield, subindex) => {
                                    return (
                                        <div className={classes.subfieldCtn} key={'sub' + index + '-' + subindex}>
                                            <span className={classes.subfieldNb}>{subindex + 1}</span>
                                            <TextField
                                                className={classes.subfield}
                                                fullWidth={true}
                                                id="filled-helperText"
                                                label={subfield}
                                                value={baseTranslation['field' + field.key + 'formatoptions'][subindex]}
                                                onChange={(e) => handleChangePresel(e, field.key, subindex)}
                                                variant="filled"
                                            />
                                        </div>)
                                })
                                :
                                subfields.map((subfield, subindex) => {
                                    // console.log('subfield', subfield);
                                    let value = baseTranslation['field' + field.key + 'formatoptions'][subindex] !== undefined ? baseTranslation['field' + field.key + 'formatoptions'][subindex]["label"] : ""
                                    return (
                                        <div>
                                            <div className={classes.subfieldCtn} key={'sub' + index + '-' + subindex}>
                                                <span className={classes.subfieldNb}>{subindex + 1}</span>
                                                <TextField
                                                    className={classes.subfield}
                                                    fullWidth={true}
                                                    id="filled-helperText"
                                                    label={subfield.label}
                                                    value={value}
                                                    onChange={(e) => handleChangeSubfield(e, field.key, subindex)}
                                                    variant="filled"
                                                />
                                                {
                                                    (subfield.format === 'select' || subfield.format === 'presel') &&
                                                    <div onClick={() => handleToggleSubPresel(subindex)} className={classes.openSubfieldsBtn}>
                                                        Translate presels
                                                    </div>
                                                }
                                            </div>
                                            {
                                                openSubPresel === subindex &&
                                                renderSubPresels(subfield, index, subindex)
                                            }
                                        </div>
                                    )
                                })}
                    </div>
                }
            </div>
        );
    }

    const renderSubPresels = (field, index, subindex) => {
        console.log('renderSubPresels params', field, index, subindex);
        const baseTranslation = creation === true ? createdTranslate : selectedTranslate;
        console.log('base translation', baseTranslation);
        return (
        <div className={classes.subPreselsCtn} >
            {
                field.presel
                    .map((presel, pindex) => {
                        // let formatOptions = baseTranslation['field' + (index + 1) + 'formatoptions'][subindex]
                        // let value = formatOptions.presels[pindex];
                        // console.log(`field ${index + 1} formatOptions`, formatOptions)
                        // console.log(`field ${index + 1} value`, value)
                        return (
                            <div className={classes.subfieldCtn} key={'field-' + index + '-subfield-' + subindex + '-presel-' + pindex}>
                                <span className={classes.subPreselNb}>{subindex + 1}-{pindex + 1}</span>
                                <TextField
                                    className={classes.subfield}
                                    fullWidth={true}
                                    id="filled-helperText"
                                    label={presel}
                                    value={baseTranslation['field' + (index + 1) + 'formatoptions'][subindex.toString()]["presels"][pindex]}
                                    onChange={(e) => handleChangeSubfieldPresel(e, index, subindex, pindex)}
                                    variant="filled"
                                />
                            </div>
                        )
                    })}
        </div>);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Header>
                    <div className="sous-header-without-height p1 grey">
                        <div className="text_align">
                            <h3 className="">{t('common:models.form-translation')}</h3>
                        </div>
                    </div>
                    <div className="sous-header_no_restrict m2 grey">
                        <div className="sous-header_content">
                            <div>
                                {
                                    selectedOption && selectedOption.value !== '' && creation === false &&
                                    <span id="icon-close" className="material-icons md-30" onClick={handleUpdateExitModel}>
                                        keyboard_arrow_left
                                    </span>
                                }

                                {
                                    creation &&
                                    <span id="icon-close" className="material-icons md-30" onClick={handleCreateExitModel}>
                                        keyboard_arrow_left
                                    </span>
                                }

                                {selectedOption === '' && creation === false &&
                                    <span id="icon-close" className="material-icons md-30" onClick={handleExitModel}>
                                        keyboard_arrow_left
                                    </span>
                                }
                            </div>

                            <div className="div_width_50">
                                <Input
                                    id="standard-full-width"
                                    label="Label"
                                    disabled={true}
                                    style={{ margin: 8 }}
                                    placeholder={t('common:models.form-title')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.fieldRight
                                    }}
                                    value={nameModel}
                                />
                            </div>
                            <div>

                                {
                                    selectedOption && selectedOption.value !== '' && creation === false &&
                                    <button className="button" onClick={handleDeleteTranslationModel}>{t('common:global.delete')}</button>
                                }

                                {
                                    selectedOption && selectedOption.value !== '' && creation === false &&
                                    <button className="button" onClick={handleUpdateExitModel}>{t('common:multiple.save')}</button>
                                }

                                {
                                    creation === true && createdTranslate !== null &&
                                    <button className="button" onClick={handleCreateExitModel}>{t('common:multiple.save')}</button>
                                }
                            </div>
                        </div>

                        <div className="sous-header_content grey">
                            <div className="line flex_style">
                            </div>
                        </div>

                        <div className="popup_wrapper_content">
                            <div className="flex_display_direction_column_centered">
                                <div className="fullWidth">
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={t('common:models.update-translation')}
                                        options={generateOptions}
                                        value={selectedOption}
                                        onChange={handleChangeOption}
                                        styles={selectStyles}
                                    />
                                </div>
                                {
                                    !selectedOption && creation === false &&
                                    <div className={classes.buttonCtn}>
                                        <button className={`button ${classes.createBtn}`} onClick={handleCreateTranslation}>{t('common:models.create-translation')}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Header>
                <Content className={`${classes.contentModel} scrollbar-hidden`}>
                    <div className={classes.subtitleCtn}>
                        {
                            selectedOption && selectedOption.value !== '' && creation === false &&
                            <FormControl className={classes.selectsCtn}>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    placeholder={t('common:models.list-translation')}
                                    options={generateFlagOptions}
                                    value={selectedFlagOption}
                                    onChange={handleChangeFlagOption}
                                    styles={selectStyles}
                                />
                            </FormControl>
                        }
                        {
                            selectedOption && selectedOption.value !== '' && creation === false && selectedTranslate !== null &&
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:global.description')}
                                value={selectedTranslate.description}
                                onChange={(event) => {
                                    selectedTranslate.description = event.target.value;
                                    setSelectedTranslate({ ...selectedTranslate });
                                }}
                                variant="filled"
                            />
                        }

                        {
                            creation === true &&
                            <FormControl className={classes.selectsCtn}>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    placeholder={t('common:models.list-translation')}
                                    options={generateFlagOptions}
                                    value={selectedFlagOption}
                                    onChange={handleChangeFlagOptionCreate}
                                    styles={selectStyles}
                                    />
                            </FormControl>
                        }

                        {
                            creation === true && createdTranslate !== null &&
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:global.description')}
                                value={createdTranslate.description}
                                onChange={(event) => {
                                    createdTranslate.description = event.target.value;
                                    setCreatedTranslate({ ...createdTranslate });
                                }}
                                variant="filled"
                                />
                        }
                    </div>

                    <br />
                    {
                        selectedOption && selectedOption.value !== '' && creation === false && selectedTranslate !== null &&
                        <div className={classes.fieldCtn}>
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:models.form-title')}
                                value={selectedTranslate.label}
                                onChange={(event) => {
                                    selectedTranslate.label = event.target.value;
                                    setSelectedTranslate({ ...selectedTranslate });
                                }}
                                variant="filled"
                                />
                        </div>
                    }

                    {
                        creation === true && createdTranslate !== null &&
                        <div className={classes.fieldCtn}>
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:models.form-title')}
                                value={createdTranslate.label}
                                onChange={(event) => {
                                    createdTranslate.label = event.target.value;
                                    setCreatedTranslate({ ...createdTranslate });
                                }}
                                variant="filled"
                            />
                        </div>
                    }

                    <div>
                        {
                            selectedOption && selectedOption.value !== '' && creation === false &&
                            updateModelLangue
                                .map((field, index) => renderField(field, index))
                        }

                        {
                            creation === true && createdTranslate !== null &&
                            updateModelLangue
                                .map((field, index) => renderField(field, index))
                            //{
                               // let subfields = []
                               // if (field.options?.lines !== null && field.options?.lines !== undefined) subfields = field.options.lines;
                               // else if (field.format === "tablemulti") subfields = field.formatoptions
                               // else if (field.format === "select") subfields = field.formatoptions.presel
                               // // console.log('subfields', subfields);
                               // return (
                                    //<div className={classes.fieldCtn} key={index}>
                                    //    <div className={classes.fieldWithSubfieldsCtn}>
                                    //    <TextField
                                    //        fullWidth={true}
                                    //        id="filled-helperText"
                                    //        label={(index + 1) + ' - ' + field.label}
                                    //        value={createdTranslate['field' + field.key]}
                                    //        onChange={(event) => {
                                    //            createdTranslate['field' + field.key] = event.target.value;
                                    //            setCreatedTranslate({ ...createdTranslate });
                                    //        }}
                                    //        variant="filled"
                                    //    />
                                    //    {
                                    //        subfields.length > 0 &&
                                    //        <div onClick={() => handleTogglePresel(index)} className={classes.openSubfieldsBtn}>
                                    //            Translate sub fields
                                    //        </div>
                                    //    }
                                    //    </div>
                                    //    {
                                    //        openPresel === index && subfields.length > 0 &&
                                    //        (field.format === "select" ?
                                    //        subfields.map((subfield, subindex) => {
                                    //            return (
                                    //                <div className={classes.subfieldCtn} key={'sub' + index + '-' + subindex}>
                                    //                    <span className={classes.subfieldNb}>{subindex + 1}</span>
                                    //                    <TextField
                                    //                        className={classes.subfield}
                                    //                        fullWidth={true}
                                    //                        id="filled-helperText"
                                    //                        label={subfield}
                                    //                        value={createdTranslate['field' + field.key + 'formatoptions'][subindex]}
                                    //                        onChange={(e) => handleCreatePresel(e, field.key, subindex)}
                                    //                        variant="filled"
                                    //                    />
                                    //                </div>)
                                    //        })
                                    //        :
                                    //        <div className={classes.subfieldsCtn}>
                                    //            {
                                    //                subfields.map((subfield, subindex) => {
                                    //                    // console.log('subfield', subfield)
                                    //                return(
                                    //                    <div className={classes.subfieldCtn} key={'sub' + index + '-' + subindex}>
                                    //                        <span className={classes.subfieldNb}>{subindex + 1}</span>
                                    //                        <TextField
                                    //                            className={classes.subfield}
                                    //                            fullWidth={true}
                                    //                            id="filled-helperText"
                                    //                            label={subfield.label}
                                    //                            value={createdTranslate['field' + field.key + 'formatoptions'][subindex]["label"]}
                                    //                            onChange={(event) => {
                                    //                                createdTranslate['field' + field.key + 'formatoptions'][subindex]["label"] = event.target.value
                                    //                                setCreatedTranslate({ ...createdTranslate });
                                    //                            }}
                                    //                            variant="filled"
                                    //                        />
                                    //                        {
                                    //                            subfield.format === 'select' &&
                                    //                            <div onClick={() => handleTogglePresel(index)} className={classes.openSubfieldsBtn}>
                                    //                                Translate presels
                                    //                            </div>
                                    //                        }
                                    //                    </div>)
                                    //            })}
                                    //        </div>
                                    //    )}
                                    //</div>
                               // )
                            //})
                        }
                    </div>
                </Content>
            </main>
        </div>
  );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        user : state.user,
        token : state.user.token,
        list : !! state.translate ? state.translate.list : [],
        translations : !! state.translate ? state.translate.translations : []
        // translations : [
        //     {
        //         "description": "French",
        //         "name": "fr",
        //         "id": 1
        //     },
        //     {
        //         "description": "English",
        //         "name": "gb",
        //         "id": 2
        //     },
        //     {
        //         "description": "Dutch",
        //         "name": "nl",
        //         "id": 3
        //     },
        //     {
        //         "description": "Italian",
        //         "name": "it",
        //         "id": 4
        //     },
        //     {
        //         "description": "German",
        //         "name": "de",
        //         "id": 5,
        //     },
        //     {
        //         "description": "Spanish",
        //         "name": "es",
        //         "id": 6
        //     },
        //     {
        //         "description": "Portuguese",
        //         "name": "pt",
        //         "id": 7
        //     },
        //     {
        //         "description": "Polish",
        //         "name": "pl",
        //         "id": 8
        //     },
        //     {
        //         "description": "Romanian",
        //         "name": "ro",
        //         "id": 9
        //     },
        //     {
        //         "description": "Russian",
        //         "name": "ru",
        //         "id": 10
        //     },
        //     {
        //         "description": "Luxembourgish",
        //         "name": "lu",
        //         "id": 11
        //     }
        // ]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptCreateTranslation: (payload) => dispatch(postCreateTranslationByModelRequest(payload)),
        attemptUpdateTranslation: (payload) => dispatch(putUpdateTranslationByModelRequest(payload)),
        attemptDeleteTranslation: (modelhash,userhash, id) => dispatch(deleteDeleteTranslationByModelRequest(modelhash,userhash, id)),
        attemptGetAllTranslations: (modelhash, userhash) => dispatch(getAllTranslationsByModelRequest(modelhash, userhash)),
        attemptGetOneTranslations: (modelhash, userhash) => dispatch(getOneTranslationByModelRequest(modelhash, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelTranslate)
