import React, {useState} from 'react';
import {connect} from 'react-redux';

import {getloginRealUserRequest} from '../../actions/user';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import meta from "./../../meta.json";

import logo from '../../assets/images/logos/plugnotes_logo_color.png';
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import {getBuildDate} from "../../utils/utils";
import colors from "../../assets/themes/Colors";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getToken} from "../../utils/Common";
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    homeVersion: {
        fontSize: 10,
        color: colors.grey,
        marginBottom: 30,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    displayFlex:{
        display : 'flex',
        flexDirection : 'row'
    },
    displayFlexFull:{
        width: '100%',
        display : 'flex',
        flexDirection : 'row'
    },
    displayFlexFullCenter:{
        width: '100%',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center'
    },
    marginLeftAuto:{
        marginLeft : 'auto'
    },
    alertContainer: {
        padding: 20,
        position: 'relative',
        width: '60vw',
        height: '30vh',
        borderRadius: 20,
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        color: colors.darkSilver,
        fontWeight: 800,
        fontSize: 20,
        marginBottom: 10,
    },
    alertClose: {
        position: 'absolute',
        top: 15,
        right: 15,
        color: colors.frost,
    },
    alertCacheBtn: {
        position: 'absolute',
        bottom: 15,
        left: 15,
    },
    alertCloseBtn: {
        position: 'absolute',
        bottom: 15,
        right: 15,
    },
    alertSubtitle: {
        color: colors.darkSilver,
        fontWeight: 600,
        fontSize: 17,
        marginLeft: 20,
        marginTop: 20,
    },
}));

function Login(props) {
    const username = useFormInput('');
    const password = useFormInput('');
    const [view, setView] = useState(false);
    const [cache, setCache] = useState(true);

    // handle button click of login form
    const handleLogin = () => {
        props.attemptRealLogin(username.value, password.value);
    };

    const handleLostPassword = () => {
        props.history.push('/lost-password');
    };

    const handleCreateAccount = () => {
        props.history.push('/register?lg='+i18n.language.toLowerCase());
    };

    // Function to clear complete cache data
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        alert(t('common:login.clearcacheend'));
        window.location.reload(false);
    };

    const checkVersion= () => {
        axios.get(ApplicationConfig.apiUrl + 'utils/check/version.json?version=V' + getBuildDate(meta.buildDate),
            {crossdomain: true}
        ).then((response) => {
            if(response.data.result){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className={classes.alertContainer}>
                            <span className={classes.alertTitle}>{t('common:login.version-out-of-date')}</span>
                            <span className={classes.alertClose}>
                                <span id="icon-close" class="material-icons md-30" onClick={() => onClose()}>
                                    close
                                </span>
                            </span>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style"/>
                            </div>
                            <span className={classes.alertBody}>
                                <br/>
                            {t('common:login.cache-advice')}
                            </span>
                            <span className={classes.alertCacheBtn}>
                                <button
                                    class="button"
                                    onClick={() => clearCacheData()}>
                                    {t('common:login.clearcachestart')}
                                </button>
                            </span>
                            <span className={classes.alertCloseBtn}>
                                <button
                                    class="button"
                                    onClick={() => onClose()}>
                                    {t('common:global.ok')}
                                </button>
                            </span>
                        </div>)}})
            }
            else {
                // alert(t('common:login.version-up-to-date'));
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className={classes.alertContainer}>
                            <span className={classes.alertTitle}>{t('common:login.version-up-to-date')}</span>
                            <span className={classes.alertClose}>
                                <span id="icon-close" class="material-icons md-30" onClick={() => onClose()}>
                                    close
                                </span>
                            </span>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style"/>
                            </div>
                            <span className={classes.alertBody}>
                                <br/>
                            {t('common:login.version-advice')}
                            </span>
                            <span className={classes.alertCloseBtn}>
                                <button
                                    class="button"
                                    onClick={() => onClose()}>
                                    {t('common:global.ok')}
                                </button>
                            </span>
                        </div>)}})
            }
        }).catch((response) => {
        })
    };

    const handleCreateDemoAccount = () => {
        props.history.push('/demo');
    };

    const handleConfirmAccount = () => {
        props.history.push('/finalize-account-password');
    };

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    return (
    <Grid>
        <div className="container_signin">
            <div className="login_wrap_content">
                <div className="logoSignin">
                    <img className="login_image" src={logo} alt="Plugnotes" />
                </div>
                <div className="popup signin">
                    <div className="popup_wrapper flexSpacebetween">
                        <div className="popup_wrapper_content_login">
                            <div className="sous-header_content">
                                <div>
                                    <span className="titres">
                                        {t('login.connection')}
                                    </span>
                                </div>
                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                        </div>

                        <div className="popup_wrapper_content_login">

                            <ul className="inputWrapper">
                                <li>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="username"
                                        label={t('login.email')}
                                        name="username"
                                        {...username}
                                        autoComplete="username"
                                    />
                                </li>
                                <li>
                                    <TextField
                                        autoComplete="current-password"
                                        fullWidth
                                        id="password"
                                        label={t('login.password')}
                                        name="password"
                                        onKeyDown={(e)=> {if(e.key === "Enter") handleLogin()}}
                                        required
                                        variant="outlined"
                                        type={view ? "text" : "password"}
                                        {...password}
                                        InputProps={{
                                            endAdornment:  <InputAdornment position="end">
                                                <div onClick={(event) => setView(!view)}>
                                                    {
                                                    view ?
                                                        <span className="material-icons" onMouseLeave={(event) => setView(false)}>
                                                            visibility_off
                                                        </span>
                                                        :
                                                        <span className="material-icons" >
                                                            visibility
                                                        </span>
                                                    }
                                                </div>
                                            </InputAdornment>,
                                        }}

                                    />
                                </li>
                                <li onClick={handleLostPassword}><span className="cliquables grey margin-left-auto">{t('login.lost-password')}</span></li>
                            </ul>

                        </div>

                        <div className="popup_wrapper_content_login align-items-center">

                            <div className="confirmation_pop-up">
                                <button className="button_signin"
                                        onClick={handleLogin}
                                        disabled={props.fetching}
                                >{props.fetching ? t('global.loading') : t('login.connection')}</button>
                            </div>
                            <div className={classes.displayFlex}>
                                <div className="confirmation_pop-up grey" onClick={handleCreateAccount}>
                                    <span className="cliquables grey">{t('login.create-account')}</span>
                                </div>
                                <div className={"confirmation_pop-up grey " + classes.marginLeftAuto } onClick={handleConfirmAccount}>
                                    <span className="cliquables grey">{t('login.confirm-account')}</span>
                                </div>
                            </div>
                            {/*<div className={classes.displayFlexFull}>*/}
                            {/*    <div className="confirmation_pop-up grey fullWidth" onClick={handleCreateDemoAccount}>*/}
                            {/*        <span className="cliquables grey">{t('login.create-demo-account')}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {
                            cache &&
                            // !ApplicationConfig.development && ApplicationConfig.production &&
                            <div className="align-items-center">
                                <div className={classes.displayFlexFullCenter}>
                                    <div className="confirmation_pop-up grey" onClick={checkVersion}>
                                        <span className="cliquables grey">{ t('common:login.version') }</span>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                </div>
            </div>
            <span className={classes.homeVersion}>V{getBuildDate(meta.buildDate)}</span>
        </div>
    </Grid>
    );

}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        fetching : !!state.user ? state.user.fetching : false,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptRealLogin: (username, password) => dispatch(getloginRealUserRequest(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login)
