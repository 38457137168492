import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import {confirmAlert} from "react-confirm-alert";
import {Close, Search} from "@material-ui/icons";
import colors from "../../assets/themes/Colors";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '30%',
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: "5px 15px",
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        // padding: '0 10px',
        backgroundColor: colors.greyLighter,
        color: colors.selectedMain,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
        marginLeft: 10,
    },
    singTabMainContainer: {
        width : '100%',
        display: 'flex',
        minHeight: '42px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    singTableAddBox: {
        width : '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    singTabItemContainer: {
        flexDirection: 'row',
        width: 'inherit',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: "4px",
        // padding: "10px 15px",
        flexWrap: 'wrap',
        cursor: 'pointer',
        gap: 5,
    },
    singleTabItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        minHeight: '45px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "4px",
        backgroundColor: colors.unselectedAlpha,
        padding: "0 10px 1px",
        gap: 5,
    },
    singleTabSelected: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        minHeight: '45px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "4px",
        padding: "0 10px 1px",
        gap: 5,
        backgroundColor: colors.selectedAlpha,
        color: colors.white
    },
    unselectedTabItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        borderRadius: "4px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "0 10px 1px",
        gap: 5,
        minWidth: '70px',
        minHeight: '45px'
    },
    singleLabel: {
        textAlign: 'center',
        margin: 0,
        // color: colors.white,
        // fontWeight: 'bold'
    },
    endButtonContainer:{
        // width: '52px',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "3px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    singTableDelButton: {
        // width : 18,
        // height : 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // color: colors.white,
        fontSize: 25,
        // fontWeight: 'bold',
    },
    singTableOutlinedButton: {
        width : 23,
        height : 23,
        display: 'flex',
        border: '2px solid',
        // borderColor: 'white',
        borderRadius: '22px',
        justifyContent: 'center',
        alignItems: 'center',
        // color: colors.white,
        fontSize: 15,
        // fontWeight: 'bold',
    },
    singTableItemSearchContainer: {
        width: '100%',
        display: 'flex',
        borderRadius: "4px",
        padding: "10px 15px",
        marginTop: 10,
        gap: 5,
        alignItems: 'center',
    },
    singTableItemSearchContainerOpen: {
        width: '100%',
        display: 'flex',
        borderRadius: "4px",
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    singTablePresetText: {
        color: colors.grey,
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    singTableItemPresetOpen: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        width: '100%',
    },
    singTableSearchButton: {
        color: colors.main,
    },
    singTableText: {
        flexGrow: 1,
        textAlign: 'right',
        border: 'none',
        fontStyle: 'italic',
    },
    singTableItemText: {
        // fontWeight: 'bold',
        color: colors.white,
    },
    inputField: {
        width: '80px',
    },


}));

const FormatSingleSelectTableInputComponent = (props) => {
    const [disabled, ] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    return(
        <TextField
            disabled={disabled}
            autoFocus={true}
            autoComplete='off'
            style={{ margin: 0, marginLeft: '5px'}}
            id="standard-full-width"
            placeholder=""
            className={props.className}
            onChange={(event) => {
                props.setNewValueDigit(event.target.value);
                props.onHandleChange(event.target.value);
            }}
            value={props.newValueDigit}
            onKeyPress={(e) => {props.onKeyPress(e)}}
        />
    )
}

const FormatSingleSelectTableComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    const includesInJson = () => {
        return props.field.options.presel.findIndex((item, index) => {
            if(typeof selected.text !== "undefined"){
                return item.trim() === selected.text.trim()
            }
            return false;
        })
    }

    const [selected, setSelected] = useState(props.field.value.length > 0 && props.field.value[0].text !== undefined ? props.field.value[0] : "");
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(props.field.visualDefault ? props.field.visualDefault : false);
    const [choice, setChoice] = useState(selected?.text || null);
    const [newValueDigit, setNewValueDigit] = useState(props.field.value[0] !== [] ?? props.field.value[0]?.text !== undefined ? selected?.value : "");
    const [value, setValue] = useState('') // filter text (bad name, I know...)

    const presels = [...props.field.options.presel]
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
            if(props.field.value.length > 0){
                let tmp = props.field.value[0];
                setNewValueDigit(tmp.value);
                setChoice(tmp.text);
            }else{
                setChoice("");
                setNewValueDigit("");
                setSelected("");
            }
    },[props.field.value]);

    const handleHitEnter = (e) => {
        if (e.key === 'Enter') setOpen(false)
    }

    const handleAddValue = (value) => {
        if (value.trim().length !== 0 && !presels.includes(value)) {
            props.field.options.presel.push(value);
            presels.push(value)
            setNewValueDigit("");
            setNewValue('');
            setChoice(value)
        }
    }

    const handleOnChange = (value) => {

        if(value.trim !== ""){
            props.handleChangeInputTable({"text" : choice, "value" : value}, includesInJson(), props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setSelected({"text": choice, "value": value})
        }
        if(value === '') {
            props.handleDeleteInputTable(value, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setSelected('')
        }
    }

    const handleRemoveSelect = (item) => {
        setChoice("");
        setSelected("");
        setNewValue("");
        setNewValueDigit("");
        props.handleDeleteInputTable(item, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
    }

    const handleDeletePresel = (value) => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-sur'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        setSelected("");
                        props.field.options.presel = props.field.options.presel.filter(item => item !== value);
                        props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });
    }

    const isInPresel = (value) => {
        return props.field.originalPresel.includes(value)
    }

    useEffect(() => {
    }, [choice])

    return (
        <div className={classes.singTabMainContainer} onClick={(e) => {e.stopPropagation(); e.preventDefault(); setOpen(!open)}}>
            <div className={classes.singTabItemContainer}>

            {/** presel filter **/
                open && presels.length > 10 &&
                <div className={classes.singTableItemPresetOpen}>
                    <Input
                        id={`standard-full-width-search-${props.field.id}`}
                        label=""
                        //style={{ margin: 8 }}
                        //placeholder={props.placeholder}
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search className={classes.singTableSearchButton}/>
                            </InputAdornment>}
                        endAdornment={ value.length > 0 &&
                        <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                            <Close />
                        </InputAdornment>
                        }
                        className={classes.textField}
                        onChange={(event) => setValue(event.target.value)}
                        value={value}
                    />
                </div>}

            { open && typeof props.field.options.presel !== 'undefined' &&
            /** box d'ajout **/
                <div className={classes.singTableItemSearchContainerOpen}>
                    {props.field.options.editable && !disabled &&
                        <div className={classes.input_add_item} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
                            <Input
                                disabled={disabled}
                                autoComplete='off'
                                id="standard-full-width"
                                label="Label"
                                style={{ margin: 0 }}
                                placeholder={t('common:multiple.add-item')}
                                helpertext="Full width!"
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                className={classes.fieldRight}
                                onBlur={() => {handleAddValue(newValue.trim(), props.field, props.index)}}
                                onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValue(newValue, props.field, props.index)}}}
                                onChange={(event) => setNewValue(event.target.value)}
                                value={newValue}
                            />
                        </div>
                    }
                    {
                        props.field.format.includes('table') && props.field.format.includes('single') &&    /**** liste des items */
                        presels.filter(item => {
                            if (value.trim() === '') return true
                            else if (item.toLowerCase().includes(value.toLowerCase())) {
                                return true
                            }
                        })
                            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            .sort((a, b) => {
                                let aN = selected.text === a? 1 : 0;
                                let bN = selected.text === b? 1 : 0;
                                return bN - aN
                            })
                            .map((item,indexation) => {
                            return (
                            <div
                                key={'divsingleitem' + props.field.id + '-' + indexation}
                                className={(selected.text === item) ? classes.singleTabSelected : classes.unselectedTabItem}
                                onClick={(event) => {
                                    event.preventDefault(); event.stopPropagation();
                                    if(!disabled) {
                                        if(choice !== item) {
                                            if(selected.text !== undefined) {handleRemoveSelect(selected.text)}
                                        }
                                        setTimeout(() => setChoice(item), 100);
                                }}}>
                                <span className={classes.singleLabel}>{item}</span>
                                {choice === item ?
                                <span className={classes.singleLabel}>:
                                    <FormatSingleSelectTableInputComponent
                                        className={classes.inputField}
                                        disabled={disabled}
                                        onHandleChange={handleOnChange}
                                        onKeyPress={handleHitEnter}
                                        setNewValueDigit={setNewValueDigit}
                                        newValueDigit={newValueDigit}
                                    />
                                </span>
                                : null}
                                {!disabled && selected.text===item && typeof selected.text !== "undefined" &&
                                <span className="cursor" onClick={(event) => {setOpen(true); event.preventDefault(); event.stopPropagation(); if(!disabled){!isInPresel(selected.text)?  handleDeletePresel(selected.text):  handleRemoveSelect(selected.text)}}}>
                                    {isInPresel(item)?
                                    <span className={`${classes.singTableDelButton} material-icons`}>highlight_off</span>
                                        :
                                    <span className={`${classes.singTableOutlinedButton} material-icons`}>delete</span>
                                    }
                                </span>
                                }
                            </div>
                            )
                        })
                    }
                </div>
            }
            {!open && typeof selected.text !== "undefined" &&
            <div className={classes.singleTabSelected} onClick={(event) => {event.preventDefault(); event.stopPropagation(); setOpen(true);}}>
                <span className={classes.singTableItemText}>{selected.text}: </span>
                <span>{selected.value}</span>
                {/*<span className="cursor" onClick={() => {isInPresel(selected.text)? handleChangeSelect("") : handleDeletePresel(selected.text)}}>*/}
                {
                    !disabled &&
                        <span className="cursor" onClick={(event) => {setOpen(true); event.preventDefault(); event.stopPropagation(); if(!disabled){!isInPresel(selected.text)?  handleDeletePresel(selected.text):  handleRemoveSelect(selected.text)}}}>
                            {isInPresel(selected.text)?
                            <span className={`${classes.singTableDelButton} material-icons`}>highlight_off</span>
                                :
                            <span className={`${classes.singTableOutlinedButton} material-icons`}>delete</span>}
                        </span>
                }
            </div>
            }
            </div>

            {typeof props.field.options.presel !== 'undefined' &&
            <div className={classes.endButtonContainer}>
                <span id="icon-delete-playlist" className={`${classes.singleItemPresetOpen} material-icons md-small`} onClick={(event) => setOpen(!open)}>
                    {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                </span>
            </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSingleSelectTableComponent);
