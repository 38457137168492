import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    acceptShareModelShareRequest,
    delModelUniqueRequest,
    delShareModelShareRequest,
    getModelsSharedPaginateRequest,
    getModelsSharedRequest
} from '../../actions/model'
import {confirmAlert} from 'react-confirm-alert'; // Import
import {Content, Header} from "rsuite";
import {
    deleteHistoryAction,
    delModel,
    getCurrentEnvironnement,
    getFolderTopRow,
    getLoadSharedModel,
    getModel,
    getModelView,
    setCurrentEnvironnement,
    setFolderTopRow,
    setLoadSharedModel,
    setModel,
    setModelView
} from "../../utils/Common";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getFieldsRequest} from "../../actions/field";
import ModalModelPlugcodeComponent from "../../components/modals/ModalModelPlugcodeComponent";
import ModalModelShareComponent from "../../components/modals/ModalModelShareComponent";
import {reduxGeneratePlugformcodeReset} from "../../actions/plugcode";
import {usePromiseTracker} from "react-promise-tracker";
import {reduxGenerateShareReset} from "../../actions/share";
import {delAllSearchParamsRequest} from "../../actions/search";
import FlipModelSharedComponent from "../../components/specials/flip/FlipModelSharedComponent";
import {reduxDelAllDataFilterRequest} from "../../actions/data"
import * as moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Tooltip from "@material-ui/core/Tooltip";
import i18n from "i18next";
import { GridOverlay, useGridApiRef } from '@material-ui/x-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DesignedGrid } from '../../components/custom/CustomComponents';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    iconRefresh:{
        float : 'right'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '57px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        position: 'absolute',
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        //width : `calc(100% - ${drawerWidth}px)`,
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 20px',
        position : 'fixed',
        height : '100%'
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight : 'calc(90vh - 100px)',
    },
    contentModelList : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        height : '100%',
        maxHeight : 'calc(90vh - 100px)',
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconBlack:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#000000'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    pointer:{
        cursor: 'pointer'
    },
}));

const getCodePFCode = (model) => {
    return model.codepfcode != null ? 'PF-' + moment(model.creationdate.date).format('YYMMDD') + '-' + model.codepfcode : ''
}

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

// ms_accepted: 1
// ms_active: 1
// ms_add_annexe: 0
// ms_add_fiche: 0
// ms_delete_annexe: 0
// ms_delete_fiche: 0
// ms_edit_annexe: 0
// ms_edit_fiche: 0
// ms_list_model_shared: null
// ms_read_annexe: 1
// ms_read_fiche: 1
// ms_share: 1

const ViewModels = ({models, listView, handleSelectModel,handleCreateModel,handleDeleteModel,handleModalModelShareOpen,handleModalModelShareClose,handleModalModelPlugcodeOpen,handleModalModelPlugcodeClose,handleFlip, flipped, history,
                        attemptGetModelsPaginate,hash,profilHash,limitPage,startPage,language,refresh, setRefresh,attemptRemoveSharedForModel
                    }) => {
    const classes = useStyles();
    const apiRef = useGridApiRef();

    const initialRows = [];
    let columns = [];

    const qrcodeAction = (object) => {
        return (
            <div className="float_left" onClick={(event) => {
                setModel(object.row.model);
                handleModalModelPlugcodeOpen();
            }}>
                <span className="material-icons md-small">
                    qr_code
                </span>
            </div>
        )
    };

    const viewAction = (object) => {
        return (
            <div onClick={(event) => {
                handleSelectModel(object.row.model);
            }}>
                <span className="material-icons md-small">
                    visibility
                </span>
            </div>
        )
    };

    const viewValueAction = (object) => {
        return (
            <div onClick={(event) => {
                handleSelectModel(object.row.model);
            }} style={{ cursor: "pointer", width : "100%" }}>
                        <span>
                            {object.value}
                        </span>
            </div>
        )
    }

    const folderValueAction = (object) => {

        return (
            <div onClick={(event) => {
                handleSelectModel(object.row.model);
            }} style={{ cursor: "pointer", width : "100%" }}>
                <span className="material-icons md-30 green">
                    folder_open
                </span>
                <span>&nbsp;&nbsp;</span>
                <span>
                    {object.value}
                </span>
            </div>
        )
    }

    const partageAction = (object) => {
        return (
            <div onClick={(event) => {
                setModel(object.row.model);
                handleModalModelShareOpen();
            }}>
                <span className="material-icons md-small">
                    group_add
                </span>
            </div>
        )
    };

    const allActions = (object) => {

        return (
            <div className="d-flex justify-content-between align-items-center grey" style={{ cursor: "pointer" }}>
                {
                    object.row.model.ms_add_fiche > 0 &&

                    <div>
                        <span className="material-icons md-medium"  onClick={(event) => {
                            setModel(object.row.model);
                            history.push('/create-plugnote-shared');
                        }}>
                            add
                        </span>
                    </div>
                }
            </div>
        )
    }

    const _handleSubmitModelShare = (object) => {

        // Alert.alert(I18n.t('confirm'), I18n.t('model-share-confirm-delete'), [
        //     {text: I18n.t('cancel'), style: 'cancel'},
        //     {text: I18n.t('ok'), onPress: () => {
        //             this.props.attemptRemoveSharedForModel(this.props.modelHash,this.props.modelShareForUser.hashModelShared)
        //         }}
        // ])

        confirmAlert({
            title: i18n.t('common:global.plugnotes'),
            message: i18n.t('common:models.model-share-confirm-delete'),
            buttons: [
                {
                    label: i18n.t('common:global.ok'),
                    onClick: () => attemptRemoveSharedForModel(object.row.model.hashModelShared,object.row.model.hash)
                },
                {
                    label: i18n.t('common:global.cancel')
                },
            ]
        });
    }

    const delActions = (object) => {

        return (
            <div className="d-flex justify-content-between align-items-center grey" style={{ cursor: "pointer" }}>
                <div>
                    <Tooltip title={i18n.t('common:actions.delete-shared')} aria-label="add" placement="top-start">

                        <span className="material-icons md-medium" onClick={(event) => { _handleSubmitModelShare(object)
                        }}>
                            delete
                        </span>
                    </Tooltip>
                </div>
            </div>
        )
    }

/*
    if(models.length > 0){
        columns.push(
            // { key: 'view', name:  '', resizable: true, sortable : false , width: '10%', formatter : viewAction  },
            { key: 'username', name:  'Propriétaire', resizable: true, sortable : true, width : '20%', formatter : viewValueAction},
            { key: 'label', name:  'Dossier partagés', resizable: true, sortable : true, width : '30%', formatter : viewValueAction},
            { key: 'plugnote', name:  'Plugnote(s)', resizable: true, sortable : true , width : '10%'},
            { key: 'actions', name:  '', resizable: true, sortable : false , formatter : allActions , width : '5%'},
            { key: 'clef', name: "N°",
                resizable: true,
                sortable : true,
                width : '5%', formatter : viewValueAction
            },
            { key: 'plugcode', name:  'Plugcode', resizable: true, sortable : true, width : '20%'},
            { key: 'delete', name:  '', resizable: true, sortable : false , formatter : delActions , width : '5%'},
            // { key: 'partage', name:  'Partage', resizable: true, sortable : false, formatter : partageAction},
            // { key: 'qrcode', name:  'Qrcode', resizable: true, sortable : false, formatter : qrcodeAction},
        );

        models.map((model, index) => {

            if(model.ms_read_fiche > 0) {

                let finalObject = {};

                finalObject['id'] = model['id'];
                finalObject['username'] = model['username'];
                finalObject['clef'] = model['clef'];
                finalObject['label'] = model['label'];
                finalObject['plugcode'] = getCodePFCode(model);
                finalObject['plugnote'] = model.nbFichesNotArchived + model.nbFichesArchived;
                finalObject['model'] = model;

                initialRows.push(finalObject);

            }

        });
    }
*/

    if(models.length > 0){
        columns.push(
            // { key: 'view', name:  '', resizable: true, sortable : false , width: '10%', formatter : viewAction  },
            { field: 'username', key: 'username', headerName:  'Propriétaire', resizable: true, sortable : true,
                disableClickEventBubbling: true,
                renderCell: viewValueAction,
                width: 250, headerAlign: 'center'
            },
            { field: 'label', key: 'label', headerName:  'Dossier partagés', resizable: true, sortable : true,
                disableClickEventBubbling: true,
                renderCell: folderValueAction,
                width: 450, headerAlign: 'center'
            },
            { field: 'plugnote', key: 'plugnote', headerName:  'Nbr (PN)', resizable: true, sortable : true,
                disableClickEventBubbling: true,
                renderCell: viewValueAction,
                width: 175, headerAlign: 'center', align : "center"
            },
            { field: 'actions', key: 'actions', headerName:  'Actions', resizable: true, sortable : false,
                disableClickEventBubbling: true,
                renderCell: allActions,
                width: 175, headerAlign: 'center', align : "center"
            },
            { field: 'clef', key: 'clef', headerName: "Key", resizable: true, sortable : true,
                disableClickEventBubbling: true,
                renderCell: viewValueAction,
                width: 150, headerAlign: 'center', align : "center"
            },
            { field: 'plugcode', key: 'plugcode', headerName:  'PF- Code', resizable: true, sortable : true,
                disableClickEventBubbling: true,
                width: 200, headerAlign: 'center', align : "center"
            },
            { field: 'delete', key: 'delete' , headerName:  ' Delete', resizable: true, sortable : false,
                disableClickEventBubbling: true,
                renderCell: delActions,
                width: 175, headerAlign: 'center', align : "center"
            },
            // { key: 'partage', name:  'Partage', resizable: true, sortable : false, formatter : partageAction},
            // { key: 'qrcode', name:  'Qrcode', resizable: true, sortable : false, formatter : qrcodeAction},
        );

        models.map((model, index) => {
            if(model.ms_read_fiche > 0) {
                let finalObject = {};
                finalObject['id'] = model['id'];
                finalObject['username'] = model['username'];
                finalObject['label'] = model['label'];
                finalObject['plugnote'] = model.nbFichesNotArchived + model.nbFichesArchived;
                finalObject['clef'] = model['clef'];
                finalObject['plugcode'] = getCodePFCode(model);
                finalObject['model'] = model;
                initialRows.push(finalObject);
            }
        });
    }

    const [rows, setRows] = useState(initialRows);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("clef");
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);

    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    const [rowCount, setRowCount] = useState(rows.length);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const mounted = React.useRef(true);

    useEffect(() => {
        if(refresh){
            setPage(1);
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
        setLoading(false);
    }, [models]);

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const fetchDatas = () => {
        setLoading(true)
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }
        else{
            setHasMore(false);
        }
        attemptGetModelsPaginate(hash, profilHash,language, nextPage);
    };

    const loadServerRows = () => {
        setLoading(true);
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }
        else{
            setHasMore(false);
        }
        attemptGetModelsPaginate(hash,language, nextPage);
    };

    const storeClickedRow = (params, e) => {
        let position = apiRef.current.getScrollPosition()
        let row = {
            position: position,
            folder: hash
        }
        // console.log('stored position', row);
        setFolderTopRow(row)
    }
    const scrollGrid = () => {
        let topRow = getFolderTopRow()
        if(topRow !== null && apiRef?.current !== null) {
            if(topRow.folder === hash) {
                apiRef.current.scroll(topRow.position)
            }
        }
    }

    useEffect(() => {
    //restore previous position
        if(apiRef?.current !== null) setTimeout(() => {
            scrollGrid()
        }, 1200)
    }, [])

    if(listView){
        return (
            <div className="root-container">
                <div className="grid-container">
                    <DesignedGrid
                        {...rows}
                        apiRef={apiRef}
                        autoHeight={true}
                        autoPageSize={true}
                        className={classes.root}
                        columns={columns}
                        columnWidth={250}
                        disableExtendRowFullWidth={false}
                        editRowsModel={editRowsModel}
                        hideFooter={true}
                        hideFooterPagination={true}
                        loading={false}
                        onCellClick={(params, e)=> storeClickedRow(params, e)}
                        onRowsScrollEnd={hasMore ? loadServerRows : null}
                        rows={rows}
                        rowHeight={50}
                        showCellRightBorder={true}
                    />
                </div>
            </div>
        );
    }
    else{
        return (
            <InfiniteScroll
                className="scrollbar-hidden"
                dataLength={models.length} //This is important field to render the next data
                next={fetchDatas}
                hasMore={hasMore}
                scrollThreshold={0.7}
                loader={<div/>}
            >
                <div className={!listView ? classes.contentModelGrid : classes.contentModelList}>
                    {models.map((value, index) => {
                        if(value.ms_read_fiche > 0){
                            return (
                                <div className="flexbox-container" key={index} onClick={handleSelectModel.bind(this,value)}>
                                    <FlipModelSharedComponent
                                        index={index}
                                        value={value}
                                        history={history}
                                        handleDeleteModel={handleDeleteModel}
                                        handleSubmitModelShare={_handleSubmitModelShare}
                                        handleModalModelShareOpen={handleModalModelShareOpen}
                                        handleModalModelShareClose={handleModalModelShareClose}
                                        handleModalModelPlugcodeOpen={handleModalModelPlugcodeOpen}
                                        handleModalModelPlugcodeClose={handleModalModelPlugcodeClose}/>
                                </div>
                            )
                        }
                    })}
                </div>
            </InfiniteScroll>
        )
    }
}

function ModelSharedList(props) {
    const classes = useStyles();
    const theme = useTheme();

    const { promiseInProgress } = usePromiseTracker();

    const [open, setOpen] = React.useState(true);
    const [isOpenModalModelPlugcode, setIsOpenModalModelPlugcode] = React.useState(false);
    const [isOpenModalModelShare, setIsOpenModalModelShare] = React.useState(false);

    const user = props.user;
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = React.useState(!!props.profil.hash ? props.profil.hash : null);
    const sortedListEnvironnement = props.sortedListEnvironnement;

    const token = props.token;

    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(getModelView() !== 'false');
    const [activeReset, setActiveReset] = useState(false);
    const [flipped, setFlipped] = useState(-1);
    const [refresh , setRefresh] = useState(false);

    useEffect(() => {
        deleteHistoryAction();
        if(getLoadSharedModel() !== 'false'){
            let language = i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase();
            props.attemptGetModels(hash, props.profil.hash, language);
            props.attemptGetFields();
            props.attemptDelAllDataFilter();
            setLoadSharedModel(false);
        }

    }, [hash]);

    const handleRefreshView = () => {
        let language = i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase();
        props.attemptGetModels(hash, props.profil.hash, language);
        props.attemptGetFields();
        props.attemptDelAllDataFilter();
        setLoadSharedModel(false);
        setRefresh(true);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleModalModelPlugcodeOpen = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(true);
    };

    const handleModalModelPlugcodeClose = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(false);

    };

    const handleModalModelShareOpen = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(true);
    };

    const handleModalModelShareClose = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(false);
    };

    const handleDeleteModel = (model) => {
        const isDeletable = !model.nbFichesArchived && !model.nbFichesNotArchived;

        if(isDeletable){
            confirmAlert({
                title: t('global.confirm'),
                message: t('models.model-confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => props.attemptRemoveModel(model.hash, hash)
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }
    }

    const handleSelectModel = (model) => {
        delModel();
        setModel(model);
        props.history.push('/pluglist-shared');
    }

    const handleCreateModel = () => {
        props.history.push('/create-model');
    }

    const handleChangeView = () => {
        setListView(!listView);
        setModelView(!listView);
    }

    const handleFlip = (value, event) => {
        event.preventDefault();
        event.stopPropagation();
        if(flipped === value){
            setFlipped(-1);
        }else{
            setFlipped(value);
        }
    }

    const handleChangeEnvironnement = (event) => {

        const value = event.target.value;
        let newEnvironnement = sortedListEnvironnement.find((element, index) => {
            return element.userhash === value;
        })

        setCurrentEnvironnement(newEnvironnement);
        setHash(newEnvironnement.userhash);
        setModels([]);
        setLoading(true);
    }

    const handleResetAllSearch = (event) => {
        props.attemptResetSearch();
        setActiveReset(true);
        setTimeout(() => {
            setActiveReset(false);
        },10)
    };

    const _handleAcceptSharedModel = (element, answer) => {

        let language = i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase();

        if(answer !== null){
            props.attemptAcceptSharedForModel(element.modelsshared_hash, answer ? "1" : "2",hash,props.profil.hash,language);
        }
    }

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    let index = 0;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Header>
                    <div id="btnContainer">
                        <div className="flex_display_direction_column_centered">

                            <span id="icon-listForm" className={`material-icons md-25 ${!listView ? classes.iconActive : classes.icon}`} onClick={handleChangeView}>
                                apps
                            </span>
                            <span id="icon-blockForm" className={`material-icons md-25 ${listView ? classes.iconActive : classes.icon}`} onClick={handleChangeView}>
                                menu
                            </span>
                            <span onClick={handleRefreshView} className={`material-icons md-25 ${classes.iconBlack}`}>
                                cached
                            </span>

                        </div>
                    </div>
                </Header>
                <Content className={!listView ? classes.contentModelGrid : classes.contentModelList}>
                    {
                        props.listToAccept.length > 0 && props.listToAccept.map((element, index) => {
                            const modelShared = element;
                            const modelSharedName = !!modelShared.firstname && !!modelShared.lastname ? modelShared.firstname + ' ' + modelShared.lastname : modelShared.username ;
                            const modelSharedEnv = !!modelShared.profilename ? modelShared.profilename : t('common:global.new') ;

                            return(
                                <div className="flexSpacebetween margin-bottom" key={index}>
                                    <div className="quaranteWidth">
                                        <span>{t('common:share.alert-model-shared-confirm-title', {name : modelSharedName})}</span>
                                    </div>
                                    <div className="quaranteWidth">
                                        <span>{t('common:share.alert-model-shared-confirm-message', { modelName: modelShared.label, profileName : modelSharedEnv })}</span>
                                    </div>
                                    <div className="dixWidth float_right">
                                        <span onClick={(event) => _handleAcceptSharedModel(element, true)}>{t('common:global.yes')}</span>
                                    </div>
                                    <div className="dixWidth float_right">
                                        <span onClick={(event) => _handleAcceptSharedModel(element, false)}>{t('common:global.no')}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        props.models.length > 0 &&
                        <ViewModels models={props.models}
                                    listView={listView}
                                    handleSelectModel={handleSelectModel}
                                    handleCreateModel={handleCreateModel}
                                    handleDeleteModel={handleDeleteModel}
                                    handleModalModelShareOpen={handleModalModelShareOpen}
                                    handleModalModelShareClose={handleModalModelShareClose}
                                    handleModalModelPlugcodeOpen={handleModalModelPlugcodeOpen}
                                    handleModalModelPlugcodeClose={handleModalModelPlugcodeClose}
                                    handleFlip={handleFlip}
                                    flipped={flipped}
                                    history={props.history}
                                    attemptGetModelsPaginate={props.attemptGetModelsPaginate}
                                    hash={hash}
                                    profilHash={props.profil.hash}
                                    limitPage={props.end}
                                    startPage={props.start}
                                    language={i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase()}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    attemptRemoveSharedForModel={props.attemptRemoveSharedForModel}
                        />
                    }
                </Content>
            </main>
            { getModel() && isOpenModalModelPlugcode ?
                <ModalModelPlugcodeComponent open={isOpenModalModelPlugcode} handleModalModelPlugcodeClose={handleModalModelPlugcodeClose} close={handleModalModelPlugcodeClose}/>
                :
                null
            }
            { getModel()  && isOpenModalModelShare ?
                <ModalModelShareComponent open={isOpenModalModelShare} handleModalModelShareClose={handleModalModelShareClose} close={handleModalModelShareClose}/>
                :
                null
            }
        </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    const listEnvironment = state?.user?.profil?.filter((value, index) => {
        if(!value.modelsshared){
            return value;
        }
    }) || [];

    const sortedListEnvironnement = listEnvironment.sort((left, right) => {
        if(left.profilid === ''){
            return -1
        }else{
            return left.profilid < right.profilid ? -1 : 1;
        }
    });

    let models = state.model.shareds;

    return {
        end : state.model.end,
        start : state.model.start,
        sortedListEnvironnement : sortedListEnvironnement,
        listEnvironment : listEnvironment,
        user : state.user,
        profil : state.user.profil,
        token : state.user.token,
        data: state.data,
        models,
        fetching : state.model.fetching,
        listToAccept : !!state.user && !!state.user.user ?  state.user.listToAccept : []
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptDelAllDataFilter: () => dispatch(reduxDelAllDataFilterRequest()),
        attemptGetModels: (userhash, usermainhash , language) => dispatch(getModelsSharedRequest(userhash, usermainhash,language)),
        attemptGetModelsPaginate: (userhash, usermainhash , language, page) => dispatch(getModelsSharedPaginateRequest(userhash, usermainhash,language, page)),
        attemptGetFields : () =>  dispatch(getFieldsRequest()),
        attemptResetModelPlugcode: () => dispatch(reduxGeneratePlugformcodeReset()),
        attemptResetModelShare: () => dispatch(reduxGenerateShareReset()),
        attemptRemoveModel :(modelhash,userhash) => dispatch(delModelUniqueRequest(modelhash, userhash)),
        attemptResetSearch : () => dispatch(delAllSearchParamsRequest()),
        attemptRemoveSharedForModel: (modelsharedhash,model) => dispatch(delShareModelShareRequest(modelsharedhash,model)),
        attemptAcceptSharedForModel : (modelsharedhash, accept,userhash,usermainhash,language) => dispatch(acceptShareModelShareRequest(modelsharedhash, accept,userhash,usermainhash,language))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelSharedList)
