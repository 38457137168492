import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/images/logos/plugnotes_logo_color.png";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    deleteHistoryAction,
    getDemoHash,
    getLoadModel,
    removeDemoHash,
    setLanguage,
    setLoadModel
} from "../../utils/Common";
import {getDemoRequest, getloginRealUserRequest, postDemoRequest} from "../../actions/user";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import qs from "querystringify";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    displayFlex:{
        display : 'flex',
        flexDirection : 'row'
    },
    marginLeftAuto:{
        marginLeft : 'auto'
    }
}));


function Demo(props) {


    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    const [demoHash, setDemoHash] = useState(getDemoHash() === null ?  null : getDemoHash());
    const [email, setEmail] = useState("");

    const [lg, setLg] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.lg || 'fr');


    const handleCreateDemoAccount = () => {
        if(email !== ""){
            let payload = {
                email : email,
                hubspotLanguage : i18n.language.toUpperCase()
            }
            props.attemptCreateDemoAccount(payload);
        }
    }

    const handleRettrieveDemoAccount = () => {
        if(demoHash !== null){
            props.attemptRetrieveDemoAccount(demoHash);
        }
    }

    const handleCheckValidEmail = () => {
        if(email !== "" && email.length > 0){
            //return email.match(new RegExp(/^.*@.*\..*$/)) != null;
            return email.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) != null;
        }

        return false;

    }

    useEffect(() => {
        i18n.changeLanguage(lg.toLowerCase());
        setLanguage(lg.toLowerCase());
    }, [lg])

    return (
    <Grid>
        <div className="container_signin">
            <div className="logoSignin">
                <img className="" src={logo} alt="Plugnotes" />
            </div>

            <div>

                <div className="popup signin">
                    <div className="popup_wrapper flexSpacebetween">

                        <div className="popup_wrapper_content_login">
                            <div className="sous-header_content">
                                <div>
                                        <span className="titres">
                                            {t('demo.title')}
                                        </span>
                                </div>
                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <div className="popup_wrapper_content_login">
                                {
                                    demoHash !== null ?
                                        <article>
                                            <div>
                                                <span>{t('common:demo.session')}</span>
                                                <br/>
                                                <br/>
                                            </div>
                                            <div className="align-items-center">
                                                <button className="button_signin"
                                                        onClick={(event) => handleRettrieveDemoAccount()}
                                                >{t('global.yes')}</button>

                                                <button className="button_signin_red"
                                                        onClick={(event) => {setDemoHash(null); removeDemoHash(demoHash);}}
                                                >{t('global.no')}</button>
                                            </div>
                                        </article>
                                        :
                                        <article>
                                            <Input
                                                id="standard-full-width"
                                                label="Label"
                                                type="email"
                                                onChange={(event) => setEmail(event.target.value)}
                                                style={{ margin: 8 }}
                                                placeholder={t('common:demo.mail')}
                                                helpertext="Full width!"
                                                fullWidth={true}
                                                margin="none"
                                                inputlabelprops={{
                                                    shrink: true,
                                                }}
                                                className={classes.fieldRight}
                                                value={email}
                                            />
                                            <br/>
                                            <button className={email !== "" && email.length > 0 && handleCheckValidEmail() ? "button_signin" : "button_signin_grey"}
                                                    disabled={email !== "" && email.length > 0 && !handleCheckValidEmail()}
                                                    onClick={(event) => (email !== "" && email.length > 0 && handleCheckValidEmail() ) ?  handleCreateDemoAccount() : null }
                                            >{t('common:demo.create')}</button>
                                        </article>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </Grid>
);
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        fetching : !!state.user ? state.user.fetching : false,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptCreateDemoAccount: (payload) => dispatch(postDemoRequest(payload)),
    attemptRetrieveDemoAccount : (hash) => dispatch(getDemoRequest(hash))
});

export default connect(mapStateToProps, mapDispatchToProps)(Demo)
