// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action

import {toast} from "react-toastify";
import React from "react";
import {Translation} from "react-i18next";


export const Types = {

    RESET_ALL_DATA: 'reset_all_data',


    GET_MODELS_REQUEST: 'get_models_request',
    GET_MODELS_SUCCESS: 'get_models_success',
    GET_MODELS_FAILURE: 'get_models_failure',
    GET_MODELS_ERROR: 'get_models_error',

    GET_MODELS_LIGHT_REQUEST: 'get_models_light_request',
    GET_MODELS_LIGHT_SUCCESS: 'get_models_light_success',
    GET_MODELS_LIGHT_FAILURE: 'get_models_light_failure',
    GET_MODELS_LIGHT_ERROR: 'get_models_light_error',

    GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_REQUEST: 'get_models_light_countable_plugnote_request',
    GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_SUCCESS: 'get_models_light_countable_plugnote_success',
    GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_FAILURE: 'get_models_light_countable_plugnote_failure',
    GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_ERROR: 'get_models_light_countable_plugnote_error',

    GET_MODELS_END_REQUEST: 'get_models_end_request',
    GET_MODELS_END_SUCCESS: 'get_models_end_success',
    GET_MODELS_END_FAILURE: 'get_models_end_failure',
    GET_MODELS_END_ERROR: 'get_models_end_error',

    GET_MODELS_PAGINATE_REQUEST: 'get_models_paginate_request',
    GET_MODELS_PAGINATE_SUCCESS: 'get_models_paginate_success',
    GET_MODELS_PAGINATE_FAILURE: 'get_models_paginate_failure',
    GET_MODELS_PAGINATE_ERROR: 'get_models_paginate_error',

    GET_MODELS_SHARED_REQUEST: 'get_models_shared_request',
    GET_MODELS_SHARED_SUCCESS: 'get_models_shared_success',
    GET_MODELS_SHARED_FAILURE: 'get_models_shared_failure',
    GET_MODELS_SHARED_ERROR: 'get_models_shared_error',

    GET_MODELS_SHARED_END_REQUEST: 'get_models_shared_end_request',
    GET_MODELS_SHARED_END_SUCCESS: 'get_models_shared_end_success',
    GET_MODELS_SHARED_END_FAILURE: 'get_models_shared_end_failure',
    GET_MODELS_SHARED_END_ERROR: 'get_models_shared_end_error',

    GET_MODELS_SHARED_PAGINATE_REQUEST: 'get_models_shared_paginate_request',
    GET_MODELS_SHARED_PAGINATE_SUCCESS: 'get_models_shared_paginate_success',
    GET_MODELS_SHARED_PAGINATE_FAILURE: 'get_models_shared_paginate_failure',
    GET_MODELS_SHARED_PAGINATE_ERROR: 'get_models_shared_paginate_error',

    GET_MODEL_UNIQUE_REQUEST: 'get_model_unique_request',
    GET_MODEL_UNIQUE_SUCCESS: 'get_model_unique_success',
    GET_MODEL_UNIQUE_FAILURE: 'get_model_unique_failure',
    GET_MODEL_UNIQUE_ERROR: 'get_models_unique_error',
    GET_MODEL_UNIQUE_RESET: 'get_models_unique_reset',

    GET_MODEL_SHARED_UNIQUE_REQUEST: 'get_model_shared_unique_request',
    GET_MODEL_SHARED_UNIQUE_SUCCESS: 'get_model_shared_unique_success',
    GET_MODEL_SHARED_UNIQUE_FAILURE: 'get_model_shared_unique_failure',
    GET_MODEL_SHARED_UNIQUE_ERROR: 'get_model_shared_unique_error',
    GET_MODEL_SHARED_UNIQUE_RESET: 'get_model_shared_unique_reset',

    GET_MODEL_UNIQUE_BY_CLEF_RESET: 'get_model_unique_by_clef_reset',
    GET_MODEL_UNIQUE_BY_CLEF_REQUEST: 'get_model_unique_by_clef_request',
    GET_MODEL_UNIQUE_BY_CLEF_SUCCESS: 'get_model_unique_by_clef_success',
    GET_MODEL_UNIQUE_BY_CLEF_FAILURE: 'get_model_unique_by_clef_failure',
    GET_MODEL_UNIQUE_BY_CLEF_ERROR: 'get_model_unique_by_clef_error',

    GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_RESET: 'get_model_unique_by_clef_and_owner_reset',
    GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_REQUEST: 'get_model_unique_by_clef_and_owner_request',
    GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_SUCCESS: 'get_model_unique_by_clef_and_owner_success',
    GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_FAILURE: 'get_model_unique_by_clef_and_owner_failure',
    GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_ERROR: 'get_model_unique_by_clef_and_owner_error',

    POST_MODEL_REQUEST: 'post_model_request',
    POST_MODEL_SUCCESS: 'post_model_success',
    POST_MODEL_FAILURE: 'post_model_failure',
    POST_MODEL_ERROR: 'post_model_error',

    POST_MODEL_ACCESS_REQUEST: 'post_model_access_request',
    POST_MODEL_ACCESS_SUCCESS: 'post_model_access_success',
    POST_MODEL_ACCESS_FAILURE: 'post_model_access_failure',
    POST_MODEL_ACCESS_ERROR: 'post_model_access_error',

    POST_MODEL_ZAPIER_REQUEST: 'post_model_zapier_request',
    POST_MODEL_ZAPIER_SUCCESS: 'post_model_zapier_success',
    POST_MODEL_ZAPIER_FAILURE: 'post_model_zapier_failure',
    POST_MODEL_ZAPIER_ERROR: 'post_model_zapier_error',

    POST_MODEL_DUPLICATE_REQUEST: 'post_model_duplicate_request',
    POST_MODEL_DUPLICATE_SUCCESS: 'post_model_duplicate_success',
    POST_MODEL_DUPLICATE_FAILURE: 'post_model_duplicate_failure',
    POST_MODEL_DUPLICATE_ERROR: 'post_model_duplicate_error',

    PUT_MODEL_REQUEST: 'put_model_request',
    PUT_MODEL_SUCCESS: 'put_model_success',
    PUT_MODEL_FAILURE: 'put_model_failure',
    PUT_MODEL_ERROR: 'put_model_error',

    PUT_MODEL_DELETE_SHARE_REQUEST: 'put_model_delete_share_request',
    PUT_MODEL_DELETE_SHARE_SUCCESS: 'put_model_delete_share_success',
    PUT_MODEL_DELETE_SHARE_FAILURE: 'put_model_delete_share_failure',
    PUT_MODEL_DELETE_SHARE_ERROR: 'put_model_delete_share_error',

    PUT_MODEL_POST_SHARE_REQUEST: 'put_model_post_share_request',
    PUT_MODEL_POST_SHARE_SUCCESS: 'put_model_post_share_success',
    PUT_MODEL_POST_SHARE_FAILURE: 'put_model_post_share_failure',
    PUT_MODEL_POST_SHARE_ERROR: 'put_model_post_share_error',

    PUT_REPLACE_MODEL_REQUEST: 'put_replace_model_request',
    PUT_REPLACE_MODEL_SUCCESS: 'put_replace_model_success',
    PUT_REPLACE_MODEL_FAILURE: 'put_replace_model_failure',
    PUT_REPLACE_MODEL_ERROR: 'put_replace_model_error',

    PUT_MODEL_UNIQUE_REQUEST: 'put_model_unique_request',
    PUT_MODEL_UNIQUE_SUCCESS: 'put_model_unique_success',
    PUT_MODEL_UNIQUE_FAILURE: 'put_model_unique_failure',
    PUT_MODEL_UNIQUE_ERROR: 'put_model_unique_error',

    DEL_MODEL_UNIQUE_REQUEST: 'del_model_unique_request',
    DEL_MODEL_UNIQUE_SUCCESS: 'del_model_unique_success',
    DEL_MODEL_UNIQUE_FAILURE: 'del_model_unique_failure',
    DEL_MODEL_UNIQUE_ERROR: 'del_model_unique_error',

    POST_MODEL_FROM_TEMPLATE_GROUP_REQUEST: 'post_model_from_template_group_request',
    POST_MODEL_FROM_TEMPLATE_GROUP_SUCCESS: 'post_model_from_template_group_success',
    POST_MODEL_FROM_TEMPLATE_GROUP_FAILURE: 'post_model_from_template_group_failure',
    POST_MODEL_FROM_TEMPLATE_GROUP_ERROR: 'post_model_from_template_group_error',

    POST_CREATE_MODEL_BIBLIO_REQUEST: 'post_create_model_biblio_request',
    POST_CREATE_MODEL_BIBLIO_SUCCESS: 'post_create_model_biblio_success',
    POST_CREATE_MODEL_BIBLIO_FAILURE: 'post_create_model_biblio_failure',
    POST_CREATE_MODEL_BIBLIO_ERROR: 'post_create_model_biblio_error',

    POST_CREATE_MODEL_BIBLIO_V2_REQUEST: 'post_create_model_biblio_v2_request',
    POST_CREATE_MODEL_BIBLIO_V2_SUCCESS: 'post_create_model_biblio_v2_success',
    POST_CREATE_MODEL_BIBLIO_V2_FAILURE: 'post_create_model_biblio_v2_failure',
    POST_CREATE_MODEL_BIBLIO_V2_ERROR: 'post_create_model_biblio_v2_error',

    POST_EXPORT_FULL_FILE_DATAS_REQUEST: 'post_export_full_file_datas_request',
    POST_EXPORT_FULL_FILE_DATAS_SUCCESS: 'post_export_full_file_datas_success',
    POST_EXPORT_FULL_FILE_DATAS_FAILURE: 'post_export_full_file_datas_failure',
    POST_EXPORT_FULL_FILE_DATAS_ERROR: 'post_export_full_file_datas_error',


    DEL_SHARE_MODEL_SHARE_REQUEST: 'del_share_model_share_request',
    DEL_SHARE_MODEL_SHARE_SUCCESS: 'del_share_model_share_success',
    DEL_SHARE_MODEL_SHARE_FAILURE: 'del_share_model_share_failure',
    DEL_SHARE_MODEL_SHARE_ERROR: 'del_share_model_share_failure',

    ACCEPT_SHARE_MODEL_SHARE_REQUEST: 'accept_share_model_share_request',
    ACCEPT_SHARE_MODEL_SHARE_SUCCESS: 'accept_share_model_share_success',
    ACCEPT_SHARE_MODEL_SHARE_FAILURE: 'accept_share_model_share_failure',
    ACCEPT_SHARE_MODEL_SHARE_ERROR: 'accept_share_model_share_error',

    GET_MODELS_CATEGORIES_REQUEST: 'get_models_categories_request',
    GET_MODELS_CATEGORIES_SUCCESS: 'get_models_categories_success',
    GET_MODELS_CATEGORIES_FAILURE: 'get_models_categories_failure',
    GET_MODELS_CATEGORIES_ERROR: 'get_models_categories_error',

};

const ErrorToastMessage = ({ title, message }) => (
    <div>
        <Translation ns="common">
            {
                (t, { i18n }) => <h6>{t(title)}</h6>
            }
        </Translation>
        <Translation ns="common">
            {
                (t, { i18n }) => <p>{t(message)}</p>
            }
        </Translation>
    </div>

)

/*
Token
 */
// function that returns an object literal
export const getModelsRequest = (userhash, language) => ({
    type: Types.GET_MODELS_REQUEST,
    userhash : userhash,
    language : language
});

// function that returns an object literal
export const getModelsSuccess = (data) => ({
    type: Types.GET_MODELS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsFailure = () => ({
    type: Types.GET_MODELS_FAILURE,
});

// function that returns an object literal
export const getModelsError = () => ({
    type: Types.GET_MODELS_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsLightRequest = (userhash) => ({
    type: Types.GET_MODELS_LIGHT_REQUEST,
    userhash : userhash
});

// function that returns an object literal
export const getModelsLightSuccess = (data) => ({
    type: Types.GET_MODELS_LIGHT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsLightFailure = () => ({
    type: Types.GET_MODELS_LIGHT_FAILURE,
});

// function that returns an object literal
export const getModelsLightError = () => ({
    type: Types.GET_MODELS_LIGHT_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsLightCountablePlugnoteRequest = (userhash) => ({
    type: Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_REQUEST,
    userhash : userhash
});

// function that returns an object literal
export const getModelsLightCountablePlugnoteSuccess = (data) => ({
    type: Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsLightCountablePlugnoteFailure = () => ({
    type: Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_FAILURE,
});

// function that returns an object literal
export const getModelsLightCountablePlugnoteError = () => ({
    type: Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsEndRequest = () => ({
    type: Types.GET_MODELS_END_REQUEST,
});

// function that returns an object literal
export const getModelsEndSuccess = (start, end) => ({
    type: Types.GET_MODELS_END_SUCCESS,
    payload: { start },
    payload2: { end }
});

// function that returns an object literal
export const getModelsEndFailure = () => ({
    type: Types.GET_MODELS_END_FAILURE,
});

// function that returns an object literal
export const getModelsEndError = () => ({
    type: Types.GET_MODELS_END_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsPaginateRequest = (userhash, language, page) => ({
    type: Types.GET_MODELS_PAGINATE_REQUEST,
    userhash : userhash,
    language : language,
    page : page
});

// function that returns an object literal
export const getModelsPaginateSuccess = (data) => ({
    type: Types.GET_MODELS_PAGINATE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsPaginateFailure = () => ({
    type: Types.GET_MODELS_PAGINATE_FAILURE,
});

// function that returns an object literal
export const getModelsPaginateError = () => ({
    type: Types.GET_MODELS_PAGINATE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsSharedRequest = (userhash, usermainhash, language) => ({
    type: Types.GET_MODELS_SHARED_REQUEST,
    userhash : userhash,
    usermainhash : usermainhash,
    language : language
});

// function that returns an object literal
export const getModelsSharedSuccess = (data) => ({
    type: Types.GET_MODELS_SHARED_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsSharedFailure = () => ({
    type: Types.GET_MODELS_SHARED_FAILURE,
});

// function that returns an object literal
export const getModelsSharedError = () => ({
    type: Types.GET_MODELS_SHARED_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsSharedEndRequest = () => ({
    type: Types.GET_MODELS_SHARED_END_REQUEST,
});

// function that returns an object literal
export const getModelsSharedEndSuccess = (start, end) => ({
    type: Types.GET_MODELS_SHARED_END_SUCCESS,
    payload: { start },
    payload2: { end }
});

// function that returns an object literal
export const getModelsSharedEndFailure = () => ({
    type: Types.GET_MODELS_SHARED_END_FAILURE,
});

// function that returns an object literal
export const getModelsSharedEndError = () => ({
    type: Types.GET_MODELS_SHARED_END_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelsSharedPaginateRequest = (userhash, language, page) => ({
    type: Types.GET_MODELS_SHARED_PAGINATE_REQUEST,
    userhash : userhash,
    language : language,
    page : page
});

// function that returns an object literal
export const getModelsSharedPaginateSuccess = (data) => ({
    type: Types.GET_MODELS_SHARED_PAGINATE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsSharedPaginateFailure = () => ({
    type: Types.GET_MODELS_SHARED_PAGINATE_FAILURE,
});

// function that returns an object literal
export const getModelsSharedPaginateError = () => ({
    type: Types.GET_MODELS_SHARED_PAGINATE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getModelUniqueRequest = (modelhash,userhash,usermainhash) => ({
    type: Types.GET_MODEL_UNIQUE_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    usermainhash : usermainhash,
});

// function that returns an object literal
export const getModelUniqueSuccess = (data) => ({
    type: Types.GET_MODEL_UNIQUE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getModelUniqueFailure = () => ({
    type: Types.GET_MODEL_UNIQUE_FAILURE,
});

// function that returns an object literal
export const getModelUniqueError = () => ({
    type: Types.GET_MODEL_UNIQUE_ERROR,
});

// function that returns an object literal
export const getModelUniqueReset = () => ({
    type: Types.GET_MODEL_UNIQUE_RESET,
});

// function that returns an object literal
export const getModelSharedUniqueRequest = (modelhash,userhash,usermainhash) => ({
    type: Types.GET_MODEL_SHARED_UNIQUE_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    usermainhash : usermainhash,
});

// function that returns an object literal
export const getModelSharedUniqueSuccess = (data) => ({
    type: Types.GET_MODEL_SHARED_UNIQUE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getModelSharedUniqueFailure = () => ({
    type: Types.GET_MODEL_SHARED_UNIQUE_FAILURE,
});

// function that returns an object literal
export const getModelSharedUniqueError = () => ({
    type: Types.GET_MODEL_SHARED_UNIQUE_ERROR,
});

// function that returns an object literal
export const getModelSharedUniqueReset = () => ({
    type: Types.GET_MODEL_UNIQUE_RESET,
});

/*
Token
 */
// function that returns an object literal
export const getModelUniqueByClefRequest = (code,userhash) => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_REQUEST,
    code : code,
    userhash : userhash
});

// function that returns an object literal
export const getModelUniqueByClefSuccess = (data) => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getModelUniqueByClefFailure = () => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_FAILURE,
});

// function that returns an object literal
export const getModelUniqueByClefError = () => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_ERROR,
});

// function that returns an object literal
export const getModelUniqueByClefReset = () => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_RESET,
});

/*
Token
 */
// function that returns an object literal
export const getModelUniqueByClefAndOwnerRequest = (clef,owner) => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_REQUEST,
    clef : owner,
    owner : owner
});

// function that returns an object literal
export const getModelUniqueByClefAndOwnerSuccess = (data) => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getModelUniqueByClefAndOwnerFailure = () => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_FAILURE,
});

// function that returns an object literal
export const getModelUniqueByClefAndOwnerError = () => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_ERROR,
});

// function that returns an object literal
export const getModelUniqueByClefAndOwnerReset = () => ({
    type: Types.GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_RESET,
});

/*
Token
 */
// function that returns an object literal
export const postModelRequest = (data, userhash) => ({
    type: Types.POST_MODEL_REQUEST,
    payload : data,
    userhash : userhash
});

// function that returns an object literal
export const postModelSuccess = (data) => ({
    type: Types.POST_MODEL_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postModelFailure = () => ({
    type: Types.POST_MODEL_FAILURE,
});

// function that returns an object literal
export const postModelError = () => ({
    type: Types.POST_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postModelAccessRequest = (data, userhash) => ({
    type: Types.POST_MODEL_ACCESS_REQUEST,
    payload : data,
    userhash : userhash
});

// function that returns an object literal
export const postModelAccessSuccess = ({data}) => ({
    type: Types.POST_MODEL_ACCESS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postModelAccessFailure = () => ({
    type: Types.POST_MODEL_ACCESS_FAILURE,
});

// function that returns an object literal
export const postModelAccessError = () => ({
    type: Types.POST_MODEL_ACCESS_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postModelZapierRequest = (modelhash,userhash,payload) => ({
    type: Types.POST_MODEL_ZAPIER_REQUEST,
    modelhash : modelhash,
    payload : payload,
    userhash : userhash
});

// function that returns an object literal
export const postModelZapierSuccess = (data) => ({
    type: Types.POST_MODEL_ZAPIER_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postModelZapierFailure = () => ({
    type: Types.POST_MODEL_ZAPIER_FAILURE,
});

// function that returns an object literal
export const postModelZapierError = () => ({
    type: Types.POST_MODEL_ZAPIER_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const postModelDuplicateRequest = (model,userhash) => ({
    type: Types.POST_MODEL_DUPLICATE_REQUEST,
    model : model,
    userhash : userhash
});

// function that returns an object literal
export const postModelDuplicateSuccess = (data) => ({
    type: Types.POST_MODEL_DUPLICATE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postModelDuplicateFailure = () => ({
    type: Types.POST_MODEL_DUPLICATE_FAILURE,
});

// function that returns an object literal
export const postModelDuplicateError = () => ({
    type: Types.POST_MODEL_DUPLICATE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const putModelRequest = (data, userhash, back) => {
    // console.log('putModel data', data)
    return {
    type: Types.PUT_MODEL_REQUEST,
    payload : data,
    userhash : userhash,
    back : back
}}

// function that returns an object literal
export const putModelSuccess = (data) => ({
    type: Types.PUT_MODEL_SUCCESS,
    payload : data
});

// function that returns an object literal
export const putModelFailure = () => ({
    type: Types.PUT_MODEL_FAILURE,
});

// function that returns an object literal
export const putModelError = () => ({
    type: Types.PUT_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const putModelDeleteShareRequest = (data) => ({
    type: Types.PUT_MODEL_DELETE_SHARE_REQUEST,
    payload : data,
});

// function that returns an object literal
export const putModelDeleteShareSuccess = (data) => ({
    type: Types.PUT_MODEL_DELETE_SHARE_SUCCESS,
    payload : data
});

// function that returns an object literal
export const putModelDeleteShareFailure = () => ({
    type: Types.PUT_MODEL_DELETE_SHARE_FAILURE,
});

// function that returns an object literal
export const putModelDeleteShareError = () => ({
    type: Types.PUT_MODEL_DELETE_SHARE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const putModelPostShareRequest = (data) => ({
    type: Types.PUT_MODEL_POST_SHARE_REQUEST,
    payload : data,
});

// function that returns an object literal
export const putModelPostShareSuccess = (data) => ({
    type: Types.PUT_MODEL_POST_SHARE_SUCCESS,
    payload : data
});

// function that returns an object literal
export const putModelPostShareFailure = () => ({
    type: Types.PUT_MODEL_POST_SHARE_FAILURE,
});

// function that returns an object literal
export const putModelPostShareError = () => ({
    type: Types.PUT_MODEL_POST_SHARE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const putReplaceModelRequest = (data, userhash,back) => ({
    type: Types.PUT_REPLACE_MODEL_REQUEST,
    payload : data,
    userhash : userhash,
    back : back
});

// function that returns an object literal
export const putReplaceModelSuccess = (data) => ({
    type: Types.PUT_REPLACE_MODEL_SUCCESS,
    payload : data
});

// function that returns an object literal
export const putReplaceModelFailure = () => ({
    type: Types.PUT_REPLACE_MODEL_FAILURE,
});

// function that returns an object literal
export const putReplaceModelError = () => ({
    type: Types.PUT_REPLACE_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const putModelUniqueRequest = (data, userhash) => ({
    type: Types.PUT_MODEL_UNIQUE_REQUEST,
    payload : data,
    userhash : userhash
});

export const putModelUniqueSuccess = (data) => ({
    type: Types.PUT_MODEL_UNIQUE_SUCCESS,
    payload : data
});

/*export function putModelUniqueSuccess(data) {
    return function(dispatch) {
        dispatch({
            type: Types.PUT_MODEL_UNIQUE_SUCCESS,
            payload : data
        });
        toast.success("MY SUCCESS");
    };
}*/

// function that returns an object literal
export const putModelUniqueFailure = () => ({
    type: Types.PUT_MODEL_UNIQUE_FAILURE,
});

// function that returns an object literal
export const putModelUniqueError = () => ({
    type: Types.PUT_MODEL_UNIQUE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const delModelUniqueRequest = (modelhash, userhash, back) => ({
    type: Types.DEL_MODEL_UNIQUE_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    back : back
});

// function that returns an object literal
export const delModelUniqueSuccess = (data) => ({
    type: Types.DEL_MODEL_UNIQUE_SUCCESS,
    payload : data
});

// function that returns an object literal
export const delModelUniqueFailure = () => ({
    type: Types.DEL_MODEL_UNIQUE_FAILURE,
});

// function that returns an object literal
export const delModelUniqueError = () => ({
    type: Types.DEL_MODEL_UNIQUE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postModelFromTemplateGroupRequest = (model, models, unique, userhash) => ({
    type: Types.POST_MODEL_FROM_TEMPLATE_GROUP_REQUEST,
    model : model,
    models : models,
    unique : unique,
    userhash : userhash
});

// function that returns an object literal
export const postModelFromTemplateGroupSuccess = ({data}) => ({
    type: Types.POST_MODEL_FROM_TEMPLATE_GROUP_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postModelFromTemplateGroupFailure = () => ({
    type: Types.POST_MODEL_FROM_TEMPLATE_GROUP_FAILURE,
});

// function that returns an object literal
export const postModelFromTemplateGroupError = () => ({
    type: Types.POST_MODEL_FROM_TEMPLATE_GROUP_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const postCreateModelBiblioRequest = (payload, userhash, language) => ({
    type: Types.POST_CREATE_MODEL_BIBLIO_REQUEST,
    payload : payload,
    userhash : userhash,
    language : language
});

export const postCreateModelBiblioSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_CREATE_MODEL_BIBLIO_SUCCESS,
            payload: data,
        });
        toast.success(<ErrorToastMessage title='global.plugnotes' message='common:models.create-selected-models-confirm' />)
    }
};

// function that returns an object literal
export const postCreateModelBiblioFailure = () => ({
    type: Types.POST_CREATE_MODEL_BIBLIO_FAILURE,
});

// function that returns an object literal
export const postCreateModelBiblioError = () => ({
    type: Types.POST_CREATE_MODEL_BIBLIO_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postCreateModelBiblioV2Request = (payload, userhash, language) => ({
    type: Types.POST_CREATE_MODEL_BIBLIO_V2_REQUEST,
    payload : payload,
    userhash : userhash,
    language : language
});

export const postCreateModelBiblioV2Success = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_CREATE_MODEL_BIBLIO_V2_SUCCESS,
            payload: data,
        });
        toast.success(<ErrorToastMessage title='global.plugnotes' message='common:models.create-selected-models-confirm' />)
    }
};

// function that returns an object literal
export const postCreateModelBiblioV2Failure = () => ({
    type: Types.POST_CREATE_MODEL_BIBLIO_V2_FAILURE,
});

// function that returns an object literal
export const postCreateModelBiblioV2Error = () => ({
    type: Types.POST_CREATE_MODEL_BIBLIO_V2_ERROR,
});


/*
Token post_export_full_file_datas_request
 */
// function that returns an object literal
export const postExportFullFileDatasRequest = (modelhash, usermainhash, payloadhash) => ({
    type: Types.POST_EXPORT_FULL_FILE_DATAS_REQUEST,
    modelhash : modelhash,
    usermainhash : usermainhash,
    payloadhash : payloadhash
});

// function that returns an object literal
export const postExportFullFileDatasSuccess = () => ({
    type: Types.POST_EXPORT_FULL_FILE_DATAS_SUCCESS,
});

// function that returns an object literal
export const postExportFullFileDatasFailure = () => ({
    type: Types.POST_EXPORT_FULL_FILE_DATAS_FAILURE,
});

// function that returns an object literal
export const postExportFullFileDatasError = () => ({
    type: Types.POST_EXPORT_FULL_FILE_DATAS_ERROR,
});


/*
Token
*/
// function that returns an object literal
export const delShareModelShareRequest = (modelsharedhash,model) => ({
    type: Types.DEL_SHARE_MODEL_SHARE_REQUEST,
    modelsharedhash: modelsharedhash,
    model: model
});

// function that returns an object literal
export const delShareModelShareSuccess = (data) => ({
    type: Types.DEL_SHARE_MODEL_SHARE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const delShareModelShareFailure = () => ({
    type: Types.DEL_SHARE_MODEL_SHARE_FAILURE,
});

// function that returns an object literal
export const delShareModelShareError = () => ({
    type: Types.DEL_SHARE_MODEL_SHARE_ERROR,
});

/*
Token
*/
// function that returns an object literal
export const acceptShareModelShareRequest = (modelsharedhash,accept, userhash,  usermainhash, language) => ({
    type: Types.ACCEPT_SHARE_MODEL_SHARE_REQUEST,
    modelsharedhash: modelsharedhash,
    accept: accept,
    userhash : userhash,
    usermainhash : usermainhash,
    language : language
});

// function that returns an object literal
export const acceptShareModelShareSuccess = (data) => ({
    type: Types.ACCEPT_SHARE_MODEL_SHARE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const acceptShareModelShareFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.ACCEPT_SHARE_MODEL_SHARE_FAILURE,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='' />)
    }
};

// function that returns an object literal
export const acceptShareModelShareError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.ACCEPT_SHARE_MODEL_SHARE_ERROR,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='' />)
    }
};


// function that returns an object literal
export const getModelsCategoriesRequest = (language) => ({
    type: Types.GET_MODELS_CATEGORIES_REQUEST,
    language : language
});

// function that returns an object literal
export const getModelsCategoriesSuccess = (data) => ({
    type: Types.GET_MODELS_CATEGORIES_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelsCategoriesFailure = () => ({
    type: Types.GET_MODELS_CATEGORIES_FAILURE,
});

// function that returns an object literal
export const getModelsCategoriesError = () => ({
    type: Types.GET_MODELS_CATEGORIES_ERROR,
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

