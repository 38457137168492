import qs from "querystringify";
import {api} from "./index";

export let
    getDatas,
    getDatasLight,
    getDatasSharedLight,
    getDatasByClef,
    getDataByClefAndUserIndex,
    getFullDatas,
    postData,
    putData,
    putDataField,
    deleteData,
    archiveAndLockData,
    deleteDatas,
    archiveDatas,
    unarchiveDatas,
    getDataUnique,
    getDataUniqueByUserindex,
    getDatasWithCodeAndFolder,
    decryptPassword;
getFullDatas = (modelhash, userhash) => api.get(`v2/datas/${modelhash}.json?internal=${userhash}`);
getDatas = (modelhash, userhash, page, filter) => api.get(`v2/datas.json?mh=${modelhash}&internal=${userhash}&page=${page}&filter=${filter}`);
getDatasLight = (modelhash, userhash, page, filter,mode) => api.get(`light/datas.json?mh=${modelhash}&internal=${userhash}&page=${page}&filter=${filter}&mode=${mode}`);
getDatasSharedLight = (modelhash, userhash, page, filter,mode) => api.get(`light/shared/datas.json?mh=${modelhash}&uh=${userhash}&internal=${userhash}&page=${page}&filter=${filter}&mode=${mode}`);
getDatasByClef = (clef, userhash, page, filter, view) => api.get(`v3/datas/clefs.json?clef=${clef}&uh=${userhash}&internal=${userhash}&page=${page}&filter=${filter}&view=${view}`);
getDataByClefAndUserIndex = (clef, userhash, userindex) => api.get(`v2/data/clef/userindex.json?clef=${clef}&uh=${userhash}&internal=${userhash}&userindex=${userindex}`);
postData = (modelHash, userHash, formData) => api.post('datas.json' + qs.stringify({userhash: userHash, modelhash: modelHash}, '?') +`&internal=${userHash}`, formData);
putData = (modelHash, userHash, formData) => api.put('datas.json' + qs.stringify({userhash: userHash, modelhash: modelHash}, '?'), formData);
putDataField = (modelhash, userhash, datahash, formData) => api.put('datafields.json' + qs.stringify({userhash: userhash, modelhash: modelhash, datahash: datahash}, '?'), formData);
decryptPassword = (datahash, field) => api.get('datadecryptpassword.json' + qs.stringify({datahash, field}, '?'));
archiveAndLockData = (formData) => api.put('datas/archiveandlock.json', formData);
deleteData = (dataHash) => api.delete('datas/' + dataHash + '.json');
deleteDatas = (payload) => api.post('deletes/datas.json', payload);
archiveDatas = (payload) => api.post('archives/datas.json', payload);
unarchiveDatas = (payload) => api.post('unarchives/datas.json', payload);
getDataUnique = (datahash) => api.get('datauniques/' + datahash + '.json');
getDataUniqueByUserindex = (userindex, userhash) => api.get(`dataindexunique.json?ux=${userindex}&uh=${userhash}`);
getDatasWithCodeAndFolder = (plugcode, folder, plug) => api.get('pluglink/datas.json' + qs.stringify({
    code: encodeURIComponent(plugcode),
    folder : encodeURIComponent(folder),
    plug : encodeURIComponent(plug),
}, '?'));

