import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import Input from "@material-ui/core/Input";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormatTMExtDataConstructorComponent from "./FormatTMExtDataConstructorComponent";
import FormatTMFormulaConstructorComponent from "./FormatTMFormulaConstructorComponent";
import {ArrowDropDown, ArrowDropUp, Help, Settings} from "@material-ui/icons";
import colors from "../../assets/themes/Colors";
import {ReactComponent as MultiSelect} from '../../assets/images/format-icon/svg/multi-select.svg'
import {ReactComponent as MultiSelectAdd} from '../../assets/images/format-icon/svg/free-multi-select.svg'
import {ReactComponent as SingleSelect} from '../../assets/images/format-icon/svg/single-select.svg'
import {ReactComponent as SingleSelectAdd} from '../../assets/images/format-icon/svg/free-single-select.svg'
import {Checkbox, FormControl, FormLabel, Switch } from "@material-ui/core";
import {RenderPreselList} from "../custom/TableRenderPresel";
import FormatTMReferenceConstructorComponent from "./FormatTMReferenceConstructorComponent";
import { ColorCheckbox, PlugTooltip } from "../custom/CustomComponents";
import { isDefined } from "../../utils/utils";
import { useTooltipStyle } from "../../assets/styles/TooltipStyles";
import { toast } from "react-toastify";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import FormatIntDataMajConstructorComponent from "./FormatIntDataMajConstructorComponent";
import * as XLSX from "xlsx";
import { VisibilityIndicator } from "../custom/icons/CustomIcons";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getToken} from "../../utils/Common";
import ModalHelpComponent from "../modals/ModalHelpComponent";

const hasConfigurator = ['extdata', 'formula', 'intdata', 'numeric-ref', 'presel', 's33data', 'intdatamaj'];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 650,
    },
    tableRoot: {
        marginRight: '20px',
    },
    headers: { '& .MuiTableCell-root': {
        padding: '4px !important',
        textAlign: 'center',
        position: 'sticky',
    }},
    flatHeader: {
        display: 'none',
    },
    appBar: {
        backgroundColor: 'white',
        borderBottom: '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '10%',
    },
    field: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    inputField: {
        padding: '0px 0px 7px',
    },
    headerInputCtn: { fontSize: 13, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    inputCenter: { textAlign: 'center' },
    mainField: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    selectType: {
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    inputType: {
        marginTop: '30px',
        marginLeft: '0px',
        marginRight: '20px',
    },
    inputListType: {
        marginTop: '15px',
        marginLeft: '10px',
    },
    configCell: {
        height: 60,
    },
    formatSelectCtn: {
        width: 100,
        paddingLeft: '12px !important',
        // paddingRight:'4px !important',
    },
    nbColumn: {
        width: '30px !important',
        paddingLeft: 5,
        paddingRight: 5
    },
    preselConfig: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5
    },
    switchCtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    configRow: {
        // verticalAlign: 'bottom',
        height: '60px !important',
    },
    openClose: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        scrollMarginTop: '30px',
    },
    tableRow: {
        borderRadius: 4,
        color: colors.main,
        padding: '0px 5px',
        gap: 5,
        // display: 'flex',
        // alignItems: 'center'
    },
    rowNb: {
        fontSize: 14,
        marginRight: 4,
        border: '1px solid',
        padding: '0px 4px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    rowContainer: {
        '& .MuiTableCell-root': {
            padding: '0px!important'
        },
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
        paddingLeft: 12,
        paddingTop: 4,
        paddingBottom: 4,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            borderRadius: 50,
            background: colors.frost
        }
    },
    fieldIcon: {
        // stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 20,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer'
    },
    switch: {
        '& .MuiSwitch-root': { height: '43px !important' }, height: '43px !important',
        '& .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1, },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1 }
    },
    switchIcon: { fontSize: 24, color: 'white', backgroundColor: colors.main, borderRadius: 12, padding: 2, },
    clickableText: {
        cursor: 'pointer',
    },
    warnToast: {
        backgroundColor: colors.unselectedMain,
    },
}));


const RenderColumnWidthEditor = (props) => {
    const { t } = useTranslation('common')
    const classes = useStyles()
    let isCustom = isDefined(props.colWidths[props.index]) && props.colWidths[props.index] !== 0
    const [width, setWidth] = useState(isCustom ? props.colWidths[props.index] : '-')
    const ref = useRef()

    const handleInputChange = (e) => {
        // console.log('input' , e);
        setWidth(e);
        props.handleUpdateHeaderLength(props.index, e);
    }
    const handleOnFocus = () => {
        setWidth(props.colWidths[props.index])
        if (width <= 0 || width === '-') {
            setTimeout(() => { ref?.current?.setSelectionRange(1, 1) }, 100)
        }
    };

    const handleOnBlur = () => {
        if (width === 0) setWidth('-')
    }

    const handleResetValue = () => {
        setWidth(0)
        props.handleUpdateHeaderLength(props.index, 0)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
            <Input
                autoComplete="off"
                classes={{ input: classes.inputCenter }}
                id="standard-full-width"
                inputRef={ref}
                police="police"
                style={{ width: '50%', minWidth: 30, maxWidth: 70, paddingLeft: 4 }}
                inputlabelprops={{ shrink: true }}
                inputMode="numeric"
                value={width}
                onBlur={handleOnBlur}
                onChange={(e) => handleInputChange(Number(e.target.value))}
                onFocus={handleOnFocus}
            />
            {
                isCustom &&
                <PlugTooltip title={t('tablemulti.reset-auto')} placement="right" alignment="center" >
                    <span className="material-icons" style={{ color: colors.main, marginRight: -28, fontSize: 20 }} onClick={handleResetValue}>
                        highlight_off
                    </span>
                </PlugTooltip>
            }
        </div>
    )
}

const RenderInputLabelEdit = (props) => {
    const classes = useStyles();
    const [newData, setNewData] = useState(props.row.label);
    const missingLabel = props.missingLabel === props.index
    const handleInputChange = (event) => {
        setNewData(event.target.value);
        props.handleUpdateLabel(props.index, event.target.value);
    }

    useEffect(() => {
        setNewData(props.row.label);
    }, [props.row]);

    useEffect(() => {
      if (missingLabel) {
          document.getElementById("subfield-label-input-" + props.index).scrollIntoView({ behavior: "smooth" })
      }
    }, [missingLabel]);

    return (
        <Input
            autoComplete="off"
            autoFocus={missingLabel}
            id={"subfield-label-input-" + props.index}
            label="Label"
            error={missingLabel}
            placeholder={missingLabel ? 'Missing Label' : 'Label'}
            classes={{ input: classes.inputCenter }}
            fullWidth={false}
            margin="none"
            inputlabelprops={{ shrink: true }}
            style={{ width: '95%' }}
            value={newData}
            onChange={(event) => handleInputChange(event)}
        />
    )
}

const RenderInputHeaderFontsizeEdit = (props) => {
    const classes = useStyles()
    const { t } = useTranslation('common')
    const [headerFontSize, setHeaderFontSize] = useState(props.row?.headerFontSize === props.default ? -1 : props.row?.headerFontSize)
    const ref = useRef()

    const handleInputChange = (event) => {
        let _fontSize = event.target.value
        if (_fontSize.length >= 2 && _fontSize.startsWith('0')) { _fontSize = _fontSize.slice(1) }
        setHeaderFontSize(_fontSize)
        props.handleUpdatePolice(props.index, _fontSize)
    }

    const handleResetValue = () => {
        setHeaderFontSize(-1)
        props.handleUpdatePolice(props.index, -1)
    }

    const handleOnFocus = () => {
        if (headerFontSize <= 0) {
            setHeaderFontSize(0)
            setTimeout(() => { ref?.current?.setSelectionRange(1, 1) }, 100)
        }
    };

    const handleOnBlur = () => {
        if (headerFontSize === '' ) setHeaderFontSize(-1)
    }

    useEffect(() => {
        setHeaderFontSize(props.row?.headerFontSize || -1);
    }, [props.row]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Input
                autoComplete="off"
                classes={{ input: classes.inputCenter }}
                id="standard-full-width"
                inputMode="numeric"
                inputRef={ref}
                police="police"
                inputlabelprops={{
                    shrink: true,

                }}
                onBlur={handleOnBlur}
                onChange={(event) => handleInputChange(event)}
                onFocus={handleOnFocus}
                style={{ width: '30%', minWidth: 70 }}
                value={(headerFontSize < 0 || headerFontSize === props.default) ? '-' : headerFontSize}
            />
            {
                (headerFontSize !== props.default && headerFontSize >= 0 ) &&
                <PlugTooltip title={t('tablemulti.reset-default')} placement="right" alignment="center" >
                    <span className="material-icons" style={{ color: colors.main, marginRight: -28, fontSize: 20 }} onClick={handleResetValue}>
                        highlight_off
                    </span>
                </PlugTooltip>
            }
        </div>
    )
}

const RenderInputRowFontsizeEdit = (props) => {
    const classes = useStyles()
    const { t } = useTranslation('common')
    const [fontSize, setFontSize] = useState(isDefined(props.row?.fontSize) ? props.row.fontSize : -1)
    const ref = useRef()

    const handleInputChange = (event) => {
        let _fontSize = event.target.value
        if (_fontSize.length >= 2 && _fontSize.startsWith('0')) { _fontSize = _fontSize.slice(1) }
        setFontSize(_fontSize)
        props.handleUpdatePolice(props.index, _fontSize)
    }

    const handleResetValue = () => {
        setFontSize(-1)
        props.handleUpdatePolice(props.index, -1)
    }

    const handleOnFocus = () => {
        if (fontSize <= 0) {
            setFontSize(0)
            setTimeout(() => { ref?.current?.setSelectionRange(1, 1) }, 100)
        }
    };

    const handleOnBlur = () => {
        if (fontSize === '' ) setFontSize(-1)
    }

    useEffect(() => {
        setFontSize(isDefined(props.row?.fontSize) ? props.row.fontSize : -1);
    }, [props.row]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Input
                autoComplete="off"
                inputRef={ref}
                classes={{ input: classes.inputCenter }}
                id="standard-full-width"
                inputMode="numeric"
                police="police"
                inputlabelprops={{ shrink: true }}
                onBlur={handleOnBlur}
                onChange={(event) => handleInputChange(event)}
                onFocus={handleOnFocus}
                style={{ width: '30%', minWidth: 70 }}
                value={(fontSize < 0 || fontSize === props.default) ? '-' : fontSize}
            />
            {
                (fontSize !== props.default && fontSize >= 0 ) &&
                <PlugTooltip title={t('tablemulti.reset-default')} placement="right" alignment="center">
                    <span className="material-icons" style={{ color: colors.main, marginRight: -28, fontSize: 20 }} onClick={handleResetValue}>
                        highlight_off
                    </span>
                </PlugTooltip>
            }
        </div>
    )
}

const RenderMaskList = (props) => {
    const [masks, setMasks] = useState(props.rows.findIndex((row => row.format === props.row.format)) !== -1 ? [props.rows[props.rows.findIndex((row => row.format === props.row.format))].mask] : []);
    const [mask, setMask] = useState(props.row.mask !== null && !Array.isArray(props.row.mask) ? props.row.mask : '');
    const classes = useStyles()

    useEffect(() => {
        setMask(props.row.mask !== null && !Array.isArray(props.row.mask) ? props.row.mask : '');
        setMasks(props.rows[props.rows.findIndex((row => row.format === props.row.format))].mask);
    }, [props.row.format]);

    const handleMaskChange = (value) => {
        let regex = null;
        let _value
        if (typeof value === 'string') _value = value;
        else if (Array.isArray(value) && value.length > 0) _value = value[0]
        else _value = value.toString()
        setMask(_value);
        switch (props.row.format) {
            case "duration":
            case "time":
            case "numeric":
            case "date":
            case "tel":
                let indexRow = props.rows.findIndex((row => row.mask.includes(_value)));
                if (indexRow !== -1) {
                    let row = props.rows[indexRow];
                    let indexMask = row.mask.findIndex((mask => mask === _value));
                    regex = row.regex[indexMask];
                }
                break;
            default:
                break;
        }
        props.handleUpdateMask(props.index, _value, regex);
    }

    useEffect(() => {
        if (masks.length > 0 && mask === '' && masks[0] !== undefined) {
            handleMaskChange(masks[0])
        }
    }, [masks])

    if (masks.length === 0) {
        return null;
    }

    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {
                masks.map((row, index) => {
                    return (
                        <div className={row === mask ? classes.selectedMask : classes.unselectedMask}
                             key={index + "-mask"} onClick={() => handleMaskChange(row)}>{row}</div>
                    )
                })
            }
        </div>
    );
}

const getTypeValue = (value) => {
    let multiselect;
    let editable;
    if (value === null || value === '') {
        return 'T01';
    } else {
        multiselect = value.multiselect
        editable = value.editable
        if (multiselect === false && editable === false) {
            return 'T01';
        } else if (multiselect === false && editable === true) {
            return 'T02';
        } else if (multiselect === true && editable === false) {
            return 'T03';
        } else if (multiselect === true && editable === true) {
            return 'T04';
        }
    }
}

// Finds special chars in column label
const getColumnLabel = (label) => {
    const textStyle = { }
    let template;
    let hasTooltip = false;
    if (label.includes('~')) {
        const parts = label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<span style={textStyle} key={'label-section-' + section}>{section}<br /></span>);
            }
            return (<span style={textStyle} key={'label-section-' + section}>{section}</span>);
        });
    } else if (label.includes('#')) {
        const index = label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span style={textStyle} key={'label-slice-' + label}>
                {label.slice(0, index)}
                <span style={textStyle}>...</span>
            </span>]
        );
    } else {
        template = (
            [<div style={textStyle} key={'label-slice-' + label}>{label}</div>]
        );
    }
    let _label = label.replaceAll('#', '');
    return (hasTooltip ?
        <PlugTooltip title={_label.replaceAll('~', '\n')}>
            <div>
                {template.map(part => part)}
            </div>
        </PlugTooltip>
        :
        <>
            {template.map(part => part)}
        </>
    );
}

const RenderTypeList = (props) => {
    const { t } = useTranslation(['common', 'models']);
    const selectTypes = ['T01', 'T02', 'T03', 'T04']
    const classes = useStyles()
    const [newData, setNewData] = useState(getTypeValue(props.row.mask));

    useEffect(() => {
        setNewData(getTypeValue(props.row.mask));
    }, [props.row]);

    const handleTypeChange = (value) => {
        setNewData(value.toString());
        props.handleUpdateType(props.index, value);
    }

    function rowRender(value) {
        let icon = ""
        let tooltip = ""
        let label = ""
        switch (value) {
            case 'T01':
                icon = <SingleSelect alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
                                     fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-two")
                label = t("selects.single")
                break;
            case'T02':
                icon = <SingleSelectAdd alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
                                        fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-and-free-two")
                label = t("selects.single-add")
                break;
            case'T03':
                icon = <MultiSelect alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
                                    fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-two")
                label = t("selects.multiple")
                break;
            case'T04':
                icon = <MultiSelectAdd alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
                                       fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-and-free-two")
                label = t("selects.multiple-add")
                break;
            default:
                break;
        }
        let result = {icon: icon, label: label, tooltip: tooltip}
        // console.log('result', result);
        return result
    }

    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {selectTypes.map((row, index) => {
                return (
                    <PlugTooltip title={rowRender(row).tooltip} key={"presel-" + index}>
                        <div className={row === newData ? classes.selectedMask : classes.unselectedMask}
                             key={index + "-select"} onClick={() => handleTypeChange(row)}>
                            <div className={classes.fieldIcon} style={{width: '20px', fill: 'white !important'}}>
                                {rowRender(row).icon}
                            </div>
                            {rowRender(row).label}
                        </div>
                    </PlugTooltip>
                )
            })}
        </div>
    );
}

const RenderFormatList = (props) => {
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const [newData, setNewData] = useState(props.rows.findIndex((row => row.format === props.row.format)).toString());
    const handleFormatChange = (event) => {
        setNewData(event.target.value.toString());
        props.handleUpdateFormat(props.index, event.target.value);
    }

    useEffect(() => {
        setNewData(props.rows.findIndex((row => row.format === props.row.format)).toString());
    }, [props.row]);

    return (
        <Select
            fullWidth
            displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newData}
            onChange={(event) => {
                handleFormatChange(event)
            }}
        >
            <PlugTooltip title={i18n.t('common:global.help-manuels')}>
                <div className="cursor text_align" onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    props.handleModalHelpOpen(event)
                }}>
                    <span id="icon-more" className="font_size_15 text-span-round" >
                            ?
                    </span>
                    <span>{i18n.t('common:global.help-manuels')}</span>
                </div>
            </PlugTooltip>

            {props.rows.map((row, index) =>
                (<MenuItem key={index} value={index}>{row.formatLabel}</MenuItem>)
            )}
        </Select>
    )
}

const RenderFormulaComponent = (props) => {
    // const classes = useStyles();
    // const { t, i18n } = useTranslation(['common', 'models']);

    return (
        <div>
            <FormatTMFormulaConstructorComponent options={props.row.configuration}/>
        </div>
    )
}

const RenderExtdataComponent = (props) => {
    // const classes = useStyles();
    // const { t, i18n } = useTranslation(['common', 'models']);
    const field = {
        label: props.row.label || '',
        key: props.field.key,
        index: props.index,
        format: 'table',
        type : props.row.format || '',
    }
    return <FormatTMExtDataConstructorComponent options={props.row.configuration} field={field}/>
}

const RenderIntDataMajComponent = (props) => {
    const field = {
        options: props.row.configuration,
        format: 'intdatamaj',
        hidden:true
    }
    return <FormatIntDataMajConstructorComponent field={field}  />
}

const RenderReferenceComponent = (props) => {
    const field = {
        label: props.row.label || '',
        key: props.field.key,
        index: props.index,
        format: 'table',
        type : props.row.format || '',
        complex : props.complex,
    }
    return <FormatTMReferenceConstructorComponent options={props.row.configuration} field={field}/>
}

const FormatTableMultipleConstructorComponent = (props) => {
    console.log('TMulti constructor', props)
    const classes = useStyles();
    const tooltipStyles = useTooltipStyle(props);
    const { t } = useTranslation(['common', 'models']);
    const [open, setOpen] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    let valueRows = props.field.formatoptions.length > 0 ? props.field.formatoptions : []
    if (props.field.format === 'tablemulti' && props.field.formatoptions.hasOwnProperty('lines')) {
        valueRows = props.field.formatoptions.lines.length > 0 ? props.field.formatoptions.lines : []
    }

    if (props.field.format === 'tablemultin'|| props.field.format === 'smailvalidmulti') {
        valueRows = props.field.formatoptions.lines.length > 0 ? props.field.formatoptions.lines : []
    }

    let _widths = valueRows.map(row => {
        if (isDefined(row.colWidth)) { return row.colWidth; } else { return 0 }
    })

    let _fontSizes = valueRows.map(row => {
        if (isDefined(row.fontSize)) { return row.fontSize; } else { return -1 }
    })

    let _labelSizes = valueRows.map(row => {
        if (isDefined(row.labelSize)) { return row.labelSize } else { return -1 }
    })
    let valueRows2 = valueRows.map((entry, cindex) => { return { ...entry, dragId: isDefined(entry.dragId) ? entry.dragId : 'col-' + cindex }})
    let _defaultLabelSize = isDefined(props.field.formatoptions.setup?.layout?.defaultLabelSize) ? props.field.formatoptions.setup?.layout?.defaultLabelSize : 14

    const [dataRows, setDataRows] = useState(valueRows2);
    const [missingLabel, setMissingLabel] = useState(null)
    const [rowLimit, setRowLimit] = useState(isDefined(props.field.formatoptions.setup?.rowLimit) ? props.field.formatoptions.setup?.rowLimit : 0)
    const [colWidths, setColWidths] = useState(_widths);
    const [colFontSizes, setColFontSizes] = useState(_fontSizes);
    const [labelFontSizes, setLabelFontSizes] = useState(_labelSizes);
    const [configPdf, setConfigPdf] = useState(false);
    const [alignment, setAlignment] = useState(props.field.formatoptions.setup?.layout?.alignment || 'full')
    const [defaultFontSize, setDefaultFontSize] = useState(props.field.formatoptions.setup?.layout?.defaultFontSize || 14)
    const [defaultLabelSize, setDefaultLabelSize] = useState(_defaultLabelSize)
    const [editColWidths, setEditColWidths] = useState(dataRows.some(row => row?.colWidth == null || row?.colWidth === 0 ))

    const [openModalColonneHelp, setOpenModalColonneHelp] = useState(false);
    const [openModalTableHelp, setOpenModalTableHelp] = useState(false);

    const handleModalColonneHelpClose = () => {
        setOpenModalColonneHelp(false);
    }

    const handleModalColonneHelpOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenModalColonneHelp(true);
    }

    const handleModalTableHelpClose = () => {
        setOpenModalTableHelp(false);
    }

    const handleModalTableHelpOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenModalTableHelp(true);
    }


    function createData(label, formatLabel, format, mask, presel, id, regex, configuration, recap, colWidth, fontSize, showOnPdf) {
        return {label, formatLabel, format, mask, presel, id, regex, configuration, recap, colWidth, fontSize, showOnPdf, dragId: 'col-' + dataRows.length };
    }

    let rows = [
        createData('', '', '', [], [], 0, []),
        createData('Taches', 'Présélection', 'presel', [{ multiselect: false, editable: false }, { multiselect: false, editable: true }, { multiselect: true, editable: false }, { multiselect: true, editable: true }], [], 1, [], null, null, null, null, true),
        createData('Suivi des dates', 'Date', 'date', ["DD/MM", "DD/MM/YY", "DD/MM/YYYY", "MM/YYYY", 'Free Text'], null, 2, ['99/99', '99/99/99', '99/99/9999', '99/9999', '99'], null, null, null, null, true),
        createData('Quantité', 'Num', 'numeric', ['XX', 'XX,XXXXXX', 'X.XXX', 'X.XXX,XXXXX'], null, 3, ['99', '99,999999', '9.999', '9.999,99999'], null, false, null, null, true),
        createData('Debut', 'Heure', 'time', ['HH:MM (24H)', 'HH:MM:SS', 'MM:SS (60min)', 'Free Text', 'HH H MM'], null, 4, ['99:99', '99:99:99', '99:99', '99', '99 H 99'], null, null, null, null, true),
        createData(t('constructor.duration'), t('constructor.duration'), 'duration', ["HH:MM", "HH:MM:SS", "MM:SS"], null, 12, ['99:99', '99:99:99', '99:99'], null, null, null, null, true),
        createData('Commentaires', 'Texte', 'text', ['10', '40', '256', '512'], null, 5, [], null, null, null, null, true),
        createData('Valider', 'Radio', 'radio', ['Y/N', 'Y/N/NA'], null, 6, [], null, null, null, null, true),
        createData('E mail', 'Mail', 'mail', ['@'], null, 7, [], null, null, null, null, true),
        createData('GSM', 'Téléphone', 'tel', ['xxxx/xxxxxxxx', '+xxxxxxxxxxxxxx'], null, 8, ['9999/99999999', '+99999999999999'], null, null, null, null, true),
        createData('Statut', 'Select', 'select', [], ['1-ouvert', '2-fermé'], 9, [], null, null, null, null, true),
        createData('Formula', 'Formula', 'formula', [], null, 10, null, { formula: "" }, false, null, null, true),
        createData('S01', 'S01', 'extdata', [], null, 11, null, {
            ref: "",
            query: "LIKE",
            columns: "",
            column: "",
            dest: ""
        }, null, null, null, true),
        createData('S03', 'S03', 'intdatamaj', [], null, 15, null, {
            display_search: false,
            plugform: null,
            dest: null,
            dest_infos: null
        }, null, null, null, true),
        createData('I01', 'I01', 'intdata', [], null, 11, null, {
            ref: "",
            query: "LIKE",
            columns: "",
            column: "",
            dest: ""
        }, null, null, null, true),
        createData('URL', 'Url', 'url', [], null, 13, [], null, null, null, null, true),
        createData('Quantité référencée', 'Num ref', 'numeric-ref', [], null, 14, [], { target: null }, null, null, null, true)
    ];

    if (props.field.format === 'tablemultin') {
    //     rows.push(createData('Quantité référencée', 'Num ref', 'numeric-ref', [], null, 14, [], { target: null }, null, null, null, true));
        rows.push(createData('S33', 'S33', 's33data', [], null, 14, [], { target: null, targettable: null }, null, null, null, true));
    }

    const handleSetDataRows = (rows) => { // Nécessaire au SMailValidMultiConstructor pour disposer de la liste complète des colonnes
        setDataRows(rows)
        if (isDefined(props.setDataRows)) {
            props.setDataRows(rows)
        }
    }

    const handleAddRow = () => {
        // if (dataRows.some((row, rindex) => {
        //     if (row.label === '') {
        //         setMissingLabel(rindex)
        //         toast.info(t('tablemulti.missing-label'),
        //         {
        //             autoClose: 3000,
        //             hideProgressBar: true,
        //             bodyClassName: classes.warnToast,
        //             className: classes.warnToast,
        //             toastClassName: classes.warnToast,
        //         })
        //         return true
        //     } else { return false }
        // })) {
        //     console.log('', );
        // } else {
            setMissingLabel(null)
            handleAddTableMulti()
        // }
    }

    const handleAddTableMulti = () => {
        handleSetDataRows([...dataRows, createData('', 'Présélection', 'presel', {
            multiselect: false,
            editable: false
        }, [], new Date().getTime(), null, null, null, null, null, true)]);
        setColWidths([...colWidths, 0]);
        setColFontSizes([...colFontSizes, -1]);
        setLabelFontSizes([...labelFontSizes, -1])
    }

    const handleUpdateLabel = (index, value) => {
        dataRows[index].label = value;
        handleSetDataRows(dataRows);
    }

    const handleUpdateHeaderPolice = (index, value) => {
        labelFontSizes[index] = Number(value);
        dataRows[index].headerFontSize = Number(value);
        handleSetDataRows([...dataRows]);
    }

    const handleUpdatePolice = (index, value) => {
        colFontSizes[index] = Number(value);
        dataRows[index].fontSize = Number(value);
        handleSetDataRows([...dataRows]);
    }

    const handleUpdateHeaderLength = (index, value) => {
        colWidths[index] = Number(value);
        setColWidths([...colWidths]);
        dataRows[index].colWidth = Number(value);
        handleSetDataRows([...dataRows]);
    }

    const handleUpdateFormat = (index, value) => {
        dataRows[index].format = rows[value].format;
        dataRows[index].formatLabel = rows[value].formatLabel;
        dataRows[index].configuration = rows[value].configuration;
        dataRows[index].mask = rows[value].format === 'presel' ? {multiselect: false, editable: false} : null;
        dataRows[index].presel = rows[value].format === 'select' ? ['1-ouvert', '2-fermé'] : [];
        if (rows[value].format !== 'formula' && rows[value].format !== 'numeric') {
            dataRows[index].recap = null;
        }
        if (rows[value].regex?.length > 0) {
            dataRows[index].mask = rows[value].mask[0]
            dataRows[index].regex = rows[value].regex[0]
        }
        handleSetDataRows([...dataRows]);
    }

    const handleUpdateMask = (index, value, regex) => {
        dataRows[index].mask = value;
        dataRows[index].regex = regex;
        handleSetDataRows(dataRows);
    }

    const handleUpdateType = (index, value) => {
        let multiselect;
        let editable;

        switch (value) {
            case 'T01':
                multiselect = false;
                editable = false;
                break;
            case 'T02':
                multiselect = false;
                editable = true;
                break;
            case 'T03':
                multiselect = true;
                editable = false;
                break;
            case 'T04':
                multiselect = true;
                editable = true;
                break;
            default:
                break;
        }

        dataRows[index].mask = {multiselect: multiselect, editable: editable};
        handleSetDataRows(dataRows);
    }

    const handleUpdatePresel = (index, value) => {
        dataRows[index].presel = value;
        handleSetDataRows([...dataRows])
    }

    const handleRemoveSpecificRow = (value) => {
        let items = dataRows.filter((row, index) => index !== value);
        handleSetDataRows(items);
    };

    const handleToggleEditColWidths = () => {
        setEditColWidths(previous => !previous);
        if (!editColWidths) {
            dataRows.forEach((row, index) => {
                row.colWidth = 0
            });
        } else {
            dataRows.forEach((row, index) => {
                row.colWidth = colWidths[index];
            });
        }
    }

    const handleChangeHeadersFontSize = (e) => {
        let _defaultSize = Number(e.target.value)
        setDefaultLabelSize(_defaultSize)
        let model
        if (props.update === false) {
            model = [...props.newModel];
        } else if (props.update === true) {
            model = [...props.updateModel]
        }
        if (isDefined(model[props.index].options.setup.layout)) {
            model[props.index].options.setup.layout.defaultLabelSize = _defaultSize;
        } else {
            let layout = {
                defaultLabelSize: _defaultSize
            }
            model[props.index].options.setup = {
                ...model[props.index].options.setup,
                layout: layout,
            }
        }
        if (props.update === false) {  props.setNewModel(model) } else if (props.update === true) { props.setUpdateModel(model)}
    }

    const handleChangeRowsFontSize = (e) => {
        let _defaultSize = Number(e.target.value)
        setDefaultFontSize(_defaultSize)
        let model
        if (props.update === false) { model = [...props.newModel] } else if (props.update === true) { model = [...props.updateModel] }
        if (isDefined(model[props.index].options.setup.layout)) {
            model[props.index].options.setup.layout.defaultFontSize = _defaultSize;
        } else {
            let layout = {
                defaultFontSize: _defaultSize
            }
            model[props.index].options.setup = {
                ...model[props.index].options.setup,
                layout: layout,
            }
        }
        if (props.update === false) {  props.setNewModel(model) } else if (props.update === true) { props.setUpdateModel(model)}
    }

    const handleChangeRowLimit = (e) => {
        let _rowLimit = Number(e.target.value)
        setRowLimit(_rowLimit)
        if (props.update === false) {
            let model = [...props.newModel];
            model[props.index].options.setup.rowLimit = _rowLimit;
            props.setNewModel(model)
        } else if (props.update === true) {
            let model = [...props.updateModel];
            model[props.index].options.setup.rowLimit = _rowLimit;
            props.setUpdateModel(model)
        }
    }

    function handleTogglePDFVisibility(row, index) {
        let _row = {...row};
        if (isDefined(row.showOnPdf)) {
            _row.showOnPdf = !row.showOnPdf;
        } else { _row.showOnPdf = false; }
        let _rows = [...dataRows];
        _rows[index] = _row;
        handleSetDataRows(_rows);
    }

    function handleTogglePFVisibility(row, index) {
        let _row = {...row};
        if (isDefined(row.encodable)) {
            _row.encodable = !row.encodable;
        } else { _row.encodable = false; }
        let _rows = [...dataRows];
        _rows[index] = _row;
        handleSetDataRows(_rows);
    }

    function handleTogglePNVisibility(row, index) {
        let _row = {...row};
        if (isDefined(row.visible)) {
            _row.visible = !row.visible;
        } else { _row.visible = false; }
        let _rows = [...dataRows];
        _rows[index] = _row;
        handleSetDataRows(_rows);
    }

    function handleToggleConfig() {
        setConfigPdf(previous => !previous);
    }

    function handleToggleAlignement(e) {
        let align = e.target.value;
        if (isDefined(align) && align !== 0) setAlignment(align);
        else {
            align = isDefined(alignment) ? alignment : 'full'
            setAlignment(align)
        }
        if (props.update === false) {
            let model = [...props.newModel];
            model[props.index].options.setup.layout.alignment = align;
            props.setNewModel(model);
        } else if (props.update === true) {
            let model = [...props.updateModel];
            model[props.index].options.setup.layout.alignment = align;
            props.setUpdateModel(model);
        }
    }

    const handleEnableRecapSpecificRow = (index, value) => {
        //setDataRows(items);
        dataRows[index].recap = value;
        handleSetDataRows([...dataRows]);
    }

    const handleDropPresel = (acceptedFiles, _, index) => {
        let files = acceptedFiles, f = files[0];
        let reader = new FileReader()

        reader.onload = function(e) {
            let bstr = new Uint8Array(e.target.result)
            let workbook = XLSX.read(bstr, {type: 'array'})
            /* DO SOMETHING WITH workbook HERE */
            /* Get first worksheet */
            const wsName = workbook.SheetNames[0]
            const ws = workbook.Sheets[wsName]
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
            handleAddPresels(data, index)
        }
        reader.readAsArrayBuffer(f)
    }

    const handleAddPresels = (list, index) => {
        let presels = dataRows[index].presel
        let _list = list.split(/\r\n|\n/)
        for (const element of _list) {
            const row = element.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            for (let j = 0; j < 1; j++) {
                let item = row[j]?.trim()

                if (item?.length > 0 && !presels.includes(item)) {
                    presels.push(item)
                }
            }
        }
        handleUpdatePresel(index, presels)
    }

    const renderFieldConfigurator = (row, index) => {
        let template;
        switch (row.format) {
            case 'presel':
                template = (
                    <RenderPreselList
                        {...props}
                        getTypeValue={getTypeValue}
                        handleClose={() => { setOpen(false); setOpenRow(null) }}
                        handleUpdatePresel={handleUpdatePresel}
                        index={index}
                        onChangeHandlerDrop={handleDropPresel}
                        // onChangeHandlerDrop={props.onChangeHandlerDrop}
                        row={dataRows[index]}
                        rows={rows}
                    />
                );
                break;
            case 'formula':
                template = (
                    <RenderFormulaComponent
                        {...props}
                        row={dataRows[index]}
                        rows={rows}
                        index={index}
                    />
                );
                break;
            case 'extdata':
                template = (
                    <RenderExtdataComponent
                        {...props}
                        row={dataRows[index]}
                        rows={rows}
                        index={index}
                    />
                );
                break;
            case 'intdatamaj':
                template = (
                    <RenderIntDataMajComponent
                        {...props}
                        row={dataRows[index]}
                        rows={rows}
                        index={index}
                    />
                )
                break;
            case 'intdata':
                template = (
                    <RenderExtdataComponent
                        {...props}
                        row={dataRows[index]}
                        rows={rows}
                        index={index}
                    />
                );
                break;
            case 'numeric-ref':
                template = (
                    <RenderReferenceComponent
                        {...props}
                        row={dataRows[index]}
                        rows={rows}
                        index={index}
                        complex={false}
                    />
                );
                break;
            case 's33data':
                template = (
                    <RenderReferenceComponent
                        {...props}
                        row={dataRows[index]}
                        rows={rows}
                        index={index}
                        complex={true}
                    />
                );
                break;
            default:
                break;
        }
        return template;
    }

    // DragNDrop
    const onDragRow = (result) => {
        if (props.plugnoteCount > 0) {
            toast.info(t('tablemulti.folder-not-empty'),
                {
                    autoClose: 5000,
                    hideProgressBar: true,
                    bodyClassName: classes.warnToast,
                    className: classes.warnToast,
                    toastClassName: classes.warnToast,
                })
        } else {
            // dropped outside the list
            if (!result.destination) {
                return;
            }
            if (result.destination.index === result.source.index) {
                return;
            }
            const sourceIndex = result.source.index
            const destinationIndex = result.destination.index

            setDataRows((prev) => {
                const temp = [...prev];
                const source = prev[sourceIndex]
                const destination = prev[destinationIndex]
                const sourceId = source.id
                const destinationId = destination.id
                destination.id = sourceId
                source.id = destinationId

                temp[destinationIndex] = source
                temp[sourceIndex] = destination
                return temp;
            })
        }
    }

    const getListStyle = dragging => ({
        background: dragging ? "lightblue" : "white",
        //padding: grid,
        //width: 250
    });

    useEffect(() => {
        if (props.update === false) {
            let model = [...props.newModel];
            model[props.index].formatoptions.lines = dataRows;
            props.setNewModel(model);
        } else if (props.update === true) {
            let model = [...props.updateModel]
            model[props.index].formatoptions.lines = dataRows
            props.setUpdateModel(model)
        }
    }, [dataRows])


    return (
        <div className={classes.mainField}>
            {
                props.field.format === 'tablemulti' &&
                <span style={{marginLeft : '5px'}}>
                  <PlugTooltip title={t('common:global.help-manuels')}>
                      <span id="icon-more" className="cursor font_size_15 text-span-round" onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          handleModalTableHelpOpen(event)
                      }}>
                          ?
                      </span>
                  </PlugTooltip>
                </span>
            }
            <div className={classes.field}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow className={classes.headers}>
                            <TableCell align="center" colSpan={2}>
                                <div className={classes.field}>
                                    <Typography className={classes.clickableText} onClick={() => setConfigPdf(false)}>Configuration</Typography>
                                    <div className={classes.switchCtn}>
                                        <Typography className={classes.clickableText} onClick={() => setConfigPdf(false)}>{t('constructor.table')}</Typography>
                                        <Switch
                                            onClick={handleToggleConfig}
                                            checkedIcon={
                                                <span className={`material-icons ${classes.switchIcon}`} style={{ marginRight: -12 }}>
                                                    picture_as_pdf
                                                </span>}
                                            icon={
                                                <span className={`material-icons ${classes.switchIcon}`} style={{ marginLeft: -8 }}>
                                                    backup_table
                                                </span>}
                                            checked={configPdf}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                            className={classes.switch}
                                        />
                                        <Typography className={classes.clickableText} onClick={() => setConfigPdf(true)}>{t('constructor.layout')}</Typography>
                                    </div>
                                </div>
                            </TableCell>
                            {/* <TableCell align="center" colSpan={3}/>
                                <TableCell align="center" colSpan={3}/> */}
                            <TableCell colSpan={10} style={{ display: 'table-cell', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: configPdf ? 'space-between' : 'space-between' }}>
                                    {
                                        configPdf ?
                                            <FormControl>
                                                <FormLabel id="select-group-label">{t('common:print.alignment')}</FormLabel>
                                                <Select
                                                    name="select-group"
                                                    onChange={handleToggleAlignement}
                                                    value={alignment}
                                                >
                                                    <MenuItem value="full">{t('common:print.align-full')}</MenuItem>
                                                    <MenuItem value="left">{t('common:print.align-left')}</MenuItem>
                                                    <MenuItem value="center">{t('common:print.align-center')}</MenuItem>
                                                    <MenuItem value="right">{t('common:print.align-right')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            :
                                            <PlugTooltip title={t('tablemulti.row-limit-info')}>
                                                <FormControl>
                                                    <FormLabel id="row-limit-label">{t('tablemulti.row-limit')}</FormLabel>
                                                    <div className={classes.headerInputCtn}>
                                                        <div>
                                                            <span className="material-icons" style={{ fontSize: 20, marginRight: -8 }} >
                                                                height
                                                            </span>
                                                            <span className="material-icons" style={{ fontSize: 20 }} >
                                                                table_rows
                                                            </span>
                                                        </div>
                                                        <Input
                                                            autoComplete="off"
                                                            classes={{ input: classes.inputCenter }}
                                                            id="standard-full-width"
                                                            inputMode="numeric"
                                                            police="police"
                                                            inputlabelprops={{ shrink: true }}
                                                            onChange={handleChangeRowLimit}
                                                            style={{ width: '30%' }}
                                                            type="number"
                                                            value={rowLimit === 0 ? ' - ' : rowLimit}
                                                        />
                                                    </div>
                                                </FormControl>
                                            </PlugTooltip>
                                    }
                                    <span
                                        style={{
                                            flexWrap: 'nowrap',
                                            padding: 10,
                                            borderRadius: 5,
                                            backgroundColor: colors.main,
                                            color: colors.white,
                                            cursor: "pointer",
                                            textAlign: "center",
                                            alignSelf: 'flex-end',
                                        }}
                                        onClick={handleAddRow}>
                                        {t('tablemulti.add-column')}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.headers}>
                            <TableCell align="center" className={classes.nbColumn}>
                                <span style={{ display: "inline-block" }}>
                                    Col.
                                </span>
                            </TableCell>
                            <PlugTooltip title={<span style={{ fontSize: 15, whiteSpace: 'pre-line' }}>{t('tablemulti.infos-chars')}</span>} >
                                <TableCell >
                                    <span>Libellé</span>
                                    <span className="material-icons" style={{ fontSize: 20, color: colors.main }}>
                                        info
                                    </span>
                                </TableCell>
                            </PlugTooltip>

                            <TableCell align="center">
                                <span>Format</span>
                                {
                                    configPdf &&
                                    <PlugTooltip title={<span style={{ fontSize: 15, whiteSpace: 'pre-line' }}>{t('constructor.info-format')}</span>} >
                                        <span className="material-icons" style={{ fontSize: 20, color: colors.main }}>
                                            info
                                        </span>
                                    </PlugTooltip>
                                }
                            </TableCell>
                            {
                                !configPdf &&
                                <TableCell align="center">Configuration</TableCell>
                            }

                            {
                                !configPdf &&
                                <TableCell align="right">
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                        <div style={{ fontSize: 15 }}>Total</div>
                                    </div>
                                </TableCell>
                            }

                            {
                                configPdf &&
                                <PlugTooltip title={<span style={{ fontSize: 15 }}>{t('tablemulti.info-col-width')}</span>} >
                                    <TableCell align="center" style={{ maxWidth: 120, }}>
                                        <div style={{ fontSize: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                            {t('tablemulti.col-width')}
                                            <div style={{ fontSize: 10, alignItems: 'center' }}>
                                                {t('tablemulti.auto-values')}
                                                <input
                                                    type="checkbox" className="checkbox" style={{ color: colors.main, minWidth: '30px' }}
                                                    onChange={handleToggleEditColWidths}
                                                    checked={!editColWidths}
                                                />
                                            </div>
                                        </div>
                                    </TableCell>
                                </PlugTooltip>
                            }
                            {
                                configPdf &&
                                <TableCell align="center" style={{ maxWidth: 120, }}>
                                    <div style={{ fontSize: 16 }}>{t('tablemulti.header-fontsize')}</div>
                                        <PlugTooltip title={t('tablemulti.default-header')}>
                                            <div className={classes.headerInputCtn}>
                                                <span className="material-icons" style={{ fontSize: 20 }} >
                                                    format_size
                                                </span>
                                                <Input
                                                    autoComplete="off"
                                                    classes={{ input: classes.inputCenter }}
                                                    id="standard-full-width"
                                                    inputMode="numeric"
                                                    police="police"
                                                    inputlabelprops={{ shrink: true }}
                                                    onChange={handleChangeHeadersFontSize}
                                                    style={{ width: '30%' }}
                                                    type="number"
                                                    value={defaultLabelSize}
                                                />
                                            </div>
                                        </PlugTooltip>
                                </TableCell>
                            }
                            {
                                configPdf &&
                                <TableCell align="center" style={{ maxWidth: 120, }}>
                                    <div style={{ fontSize: 16 }}>{t('tablemulti.rows-fontsize')}</div>
                                    <PlugTooltip title={t('tablemulti.default-row')}>
                                        <div className={classes.headerInputCtn} >
                                            <span className="material-icons" style={{ fontSize: 20 }} >
                                                format_size
                                            </span>
                                            <Input
                                                autoComplete="off"
                                                classes={{ input: classes.inputCenter }}
                                                id="standard-full-width"
                                                inputMode="numeric"
                                                police="police"
                                                inputlabelprops={{ shrink: true }}
                                                onChange={handleChangeRowsFontSize}
                                                style={{ width: '30%' }}
                                                type="number"
                                                value={defaultFontSize}
                                            />
                                        </div>
                                    </PlugTooltip>
                                </TableCell>
                            }
                            <TableCell align="center" colSpan={3}>{t('common:helper.visibility')}</TableCell>
                            <TableCell align="center" />
                        </TableRow>
                    </TableHead>
                    {
                        !configPdf &&
                        <DragDropContext onDragEnd={onDragRow}>
                            <Droppable droppableId="droppable" direction="vertical">
                                {(droppableProvided, snapshot) => (
                                    <TableBody
                                        ref={droppableProvided.innerRef}
                                        {...droppableProvided.droppableProps}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {
                                            dataRows.map((row, index) => (
                                                <Draggable
                                                    key={row.dragId.toString()}
                                                    draggableId={row.dragId.toString()}
                                                    index={index}
                                                >
                                                    {(draggableProvided, snapshot) => {
                                                        return (
                                                            <React.Fragment>
                                                            <TableRow
                                                                ref={draggableProvided.innerRef}
                                                                {...draggableProvided.draggableProps}
                                                                style={{
                                                                    ...draggableProvided.draggableProps.style,
                                                                    background: snapshot.isDragging
                                                                        ? "rgba(245,245,245, 0.75)"
                                                                        : "none"
                                                                }}
                                                            >
                                                                <TableCell align="center" className={classes.configCell} >
                                                                    {index + 1}
                                                                    <div
                                                                        {...draggableProvided.dragHandleProps}>
                                                                        <span className="material-icons" style={{ cursor: 'grab' }}>drag_handle</span>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell align="center" className={classes.configCell} style={{ width: 200 }}>
                                                                    <RenderInputLabelEdit {...props} row={dataRows[index]} index={index} handleUpdateLabel={handleUpdateLabel} missingLabel={missingLabel} />
                                                                </TableCell>

                                                                <TableCell align="center" className={`${classes.configCell} `}>
                                                                    <div className={classes.formatSelectCtn}>
                                                                        <RenderFormatList {...props} handleModalHelpOpen={handleModalColonneHelpOpen}  row={dataRows[index]} index={index} rows={rows} handleUpdateFormat={handleUpdateFormat} />
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell className={classes.configCell}>
                                                                    {
                                                                        row.format === 'presel' ?
                                                                            <div className={classes.preselConfig}>
                                                                                <RenderTypeList {...props}
                                                                                    row={dataRows[index]}
                                                                                    index={index} rows={rows}
                                                                                    handleUpdateType={handleUpdateType}
                                                                                />
                                                                                <IconButton aria-label="expand row" size="small"
                                                                                    style={{ alignItems: 'flex-start' }}
                                                                                    onClick={() => {
                                                                                        if (openRow === index) {
                                                                                            setOpen(false);
                                                                                            setOpenRow(null)
                                                                                        } else {
                                                                                            setOpen(true);
                                                                                            setOpenRow(index)
                                                                                        }
                                                                                    }}>
                                                                                    {open && openRow === index ?
                                                                                        <div className={classes.subOpenClose}>
                                                                                            <Settings /><KeyboardArrowUpIcon /></div> :
                                                                                        <div className={classes.subOpenClose}>
                                                                                            <Settings /><KeyboardArrowDownIcon /></div>}
                                                                                    ({row.presel.length})
                                                                                </IconButton>
                                                                            </div>
                                                                            :
                                                                            <RenderMaskList {...props} row={dataRows[index]}
                                                                                index={index} rows={rows}
                                                                                handleUpdateMask={handleUpdateMask} />
                                                                    }
                                                                    {(row.format === 'formula' || row.format === "extdata" || row.format === "intdata" || row.format === "numeric-ref" || row.format === "s33data" || row.format === "intdatamaj") &&
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            onClick={() => {
                                                                                if (openRow === index) {
                                                                                    setOpen(false);
                                                                                    setOpenRow(null)
                                                                                } else {
                                                                                    setOpen(true);
                                                                                    setOpenRow(index)
                                                                                }
                                                                            }}
                                                                            size="small"
                                                                        >
                                                                            {!open && openRow !== index ?
                                                                                <div className={classes.openClose}>
                                                                                    <SettingsIcon /><ArrowDropDown /></div>
                                                                                :
                                                                                <div className={classes.openClose}>
                                                                                    <SettingsIcon /><ArrowDropUp /></div>}
                                                                        </IconButton>
                                                                    }
                                                                </TableCell>

                                                                <TableCell align="center">
                                                                    {
                                                                        (row.format === "numeric" || row.format === "formula") &&
                                                                        <PlugTooltip title="Total" key={"recap-" + index} placement="top">
                                                                            <Checkbox
                                                                                style={{
                                                                                    color: colors.main,
                                                                                    width: '20px',
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                                checked={row?.recap === true || false}
                                                                                onChange={() => {handleEnableRecapSpecificRow(index, !(row?.recap === true))}}
                                                                            />
                                                                        </PlugTooltip>
                                                                    }
                                                                </TableCell>

                                                                <TableCell style={{ textAlign: "center" }} colSpan={3}>
                                                                    <PlugTooltip title={t('helper.toggle-visibility')} placement="top">
                                                                        <div style={{ justifyContent: 'center', display: 'flex', gap: 5 }}>
                                                                            <span onClick={() => handleTogglePDFVisibility(row, index)} >
                                                                                <VisibilityIndicator text="PDF" visible={!isDefined(row.showOnPdf) || row.showOnPdf} />
                                                                            </span>
                                                                            <span onClick={() => handleTogglePNVisibility(row, index)} >
                                                                                <VisibilityIndicator text="PN-" visible={!isDefined(row.visible) || row.visible} />
                                                                            </span>
                                                                            <span onClick={() => handleTogglePFVisibility(row, index)} >
                                                                                <VisibilityIndicator text="PF-" visible={!isDefined(row.encodable) || row.encodable} />
                                                                            </span>
                                                                        </div>
                                                                    </PlugTooltip>
                                                                </TableCell>

                                                                {/* <TableCell style={{ height: 60, width: 50, textAlign: "center" }} onClick={() => handleTogglePDFVisibility(row, index)}>
                                                                    <PlugTooltip title={t('tablemulti.visibility-pdf')} placement="top" classes={{ tooltip: tooltipStyles.tooltip }}>
                                                                        <div>
                                                                            <ColorCheckbox
                                                                                color={colors.main}
                                                                                checked={!isDefined(row.showOnPdf) || row.showOnPdf}
                                                                            />
                                                                        </div>
                                                                    </PlugTooltip>
                                                                </TableCell>
                                                                <TableCell align="center" style={{ height: 60, width: 30, textAlign: "center" }} onClick={() => handleTogglePNVisibility(row, index)}>
                                                                    <PlugTooltip title={t('tablemulti.visibility-pn')} placement="top" classes={{ tooltip: tooltipStyles.tooltip }}>
                                                                        <div>
                                                                            <ColorCheckbox
                                                                                color={colors.main}
                                                                                checked={!isDefined(row.visible) || row.visible}
                                                                            />
                                                                        </div>
                                                                    </PlugTooltip>
                                                                </TableCell>
                                                                <TableCell align="center" style={{ height: 60, width: 30, textAlign: "center" }} onClick={() => handleTogglePFVisibility(row, index)}>
                                                                    <PlugTooltip title={t('tablemulti.visibility-pf')} placement="top" classes={{ tooltip: tooltipStyles.tooltip }}>
                                                                        <div>
                                                                            <ColorCheckbox
                                                                                color={colors.main}
                                                                                checked={!isDefined(row.encodable) || row.encodable}
                                                                            />
                                                                        </div>
                                                                    </PlugTooltip>
                                                                </TableCell> */}
                                                                <TableCell>
                                                                    <span
                                                                        id="icon-add-playlist" className="material-icons md-small"
                                                                        onClick={() => handleRemoveSpecificRow(index)}
                                                                    >
                                                                        delete
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                hasConfigurator.includes(row.format) &&
                                                                <TableRow key={"row-" + index}>
                                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}
                                                                        colSpan={6}>
                                                                        <Collapse
                                                                            in={open && openRow === index}
                                                                            timeout="auto"
                                                                            unmountOnExit
                                                                        >
                                                                            <Box margin={1}>
                                                                                <Typography variant="h6" gutterBottom
                                                                                    component="div">
                                                                                    Configuration
                                                                                </Typography>
                                                                                <section>
                                                                                    {renderFieldConfigurator(row, index)}
                                                                                </section>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))
                                        }
                                        {droppableProvided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                    {
                        dataRows.length > 0 && configPdf &&
                        <TableBody >
                            {
                                dataRows.map((row, index) => (
                                    <TableRow
                                        key={'table-' + props.field.id + '-row-' + row.id}
                                        className={`${classes.configRow} ${classes.rowContainer}`}>
                                        <TableCell
                                            align="center"
                                            className={classes.configCell}
                                        >
                                            {index + 1}
                                        </TableCell>

                                        {/* Libellé */}
                                        <TableCell align="center" className={classes.configCell} style={{ width: 200 }}>
                                            <RenderInputLabelEdit {...props} row={dataRows[index]} index={index} handleUpdateLabel={handleUpdateLabel} missingLabel={missingLabel} />
                                        </TableCell>

                                        {/* Format */}
                                        <TableCell align="center" className={`${classes.configCell} `}>
                                            <div style={{ textAlign: 'center', }}>{(rows.find((entry => entry.format === dataRows[index].format)).formatLabel).toString()}</div>
                                        </TableCell>

                                        {/* Largeur Colonne Manuelle */}
                                        <TableCell align="center" className={classes.configCell} style={{ minWidth: 70, maxWidth: 120 }}>
                                            {
                                                editColWidths ?
                                                    <RenderColumnWidthEditor
                                                        colWidths={colWidths}
                                                        index={index}
                                                        handleUpdateHeaderLength={handleUpdateHeaderLength}
                                                    />
                                                    :
                                                    <>
                                                        <div>-</div>
                                                    </>
                                            }
                                        </TableCell>

                                        {/* Police Header  */}
                                        <TableCell align="center" className={classes.configCell} style={{ minWidth: 70, maxWidth: 120 }}>
                                            <RenderInputHeaderFontsizeEdit
                                                row={dataRows[index]} fontSizes={labelFontSizes}
                                                default={defaultLabelSize} index={index}
                                                handleUpdatePolice={handleUpdateHeaderPolice}
                                            />
                                        </TableCell>

                                        {/* Police Colonne  */}
                                        <TableCell align="center" className={classes.configCell} style={{ minWidth: 70, maxWidth: 120 }}>
                                            <RenderInputRowFontsizeEdit
                                                row={dataRows[index]} fontSizes={colFontSizes}
                                                default={defaultFontSize} index={index}
                                                handleUpdatePolice={handleUpdatePolice} />
                                        </TableCell>

                                        {/* Visible on Cols */}
                                        <TableCell style={{ height: 60, width: 50, textAlign: "center" }} onClick={() => handleTogglePDFVisibility(row, index)}>
                                            <PlugTooltip title={t('tablemulti.visibility-pdf')} placement="top" classes={{ tooltip: tooltipStyles.tooltip }}>
                                                <div>
                                                    <ColorCheckbox
                                                        color={colors.main}
                                                        checked={!isDefined(row.showOnPdf) || row.showOnPdf}
                                                    />
                                                </div>
                                            </PlugTooltip>
                                        </TableCell>

                                        <TableCell align="center" style={{ height: 60, width: 30, textAlign: "center" }} onClick={() => handleTogglePNVisibility(row, index)}>
                                            <PlugTooltip title={t('tablemulti.visibility-pn')} placement="top" classes={{ tooltip: tooltipStyles.tooltip }}>
                                                <div>
                                                    <ColorCheckbox
                                                        color={colors.main}
                                                        checked={!isDefined(row.visible) || row.visible}
                                                    />
                                                </div>
                                            </PlugTooltip>
                                        </TableCell>

                                        <TableCell align="center" style={{ height: 60, width: 30, textAlign: "center" }} onClick={() => handleTogglePFVisibility(row, index)}>
                                            <PlugTooltip title={t('tablemulti.visibility-pf')} placement="top" classes={{ tooltip: tooltipStyles.tooltip }}>
                                                <div>
                                                    <ColorCheckbox
                                                        color={colors.main}
                                                        checked={!isDefined(row.encodable) || row.encodable}
                                                    />
                                                </div>
                                            </PlugTooltip>
                                        </TableCell>

                                        <TableCell>
                                            <span
                                                id="icon-add-playlist" className="material-icons md-small"
                                                onClick={() => handleRemoveSpecificRow(index)}
                                            >
                                                delete
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    }
                </Table>
            </div>
            {
                openModalColonneHelp  ?
                    <ModalHelpComponent open={openModalColonneHelp} close={handleModalColonneHelpClose} from="colonne"/>
                    :
                    null
            }

            {
                openModalTableHelp  ?
                    <ModalHelpComponent open={openModalTableHelp} close={handleModalTableHelpClose} from="table"/>
                    :
                    null
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultipleConstructorComponent);





