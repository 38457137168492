import React, {useEffect, useState} from 'react';
import {makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Content, Header,} from "rsuite";
import {
    delOpenFolder,
    getCurrentEnvironnement,
    getOpenFolder,
    getPlugcode,
    removePlugcode,
    setCurrentEnvironnement,
    setOpenFolder,
    setPlugcode,
} from "../../utils/Common";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import moment from "moment";
import { Search, } from "@material-ui/icons";
import {confirmAlert} from "react-confirm-alert";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {
    delPlugcodeGalleryRequest,
    getPlugcodeGalleryRequest,
    postPlugcodeGalleryRequest,
    putPlugcodeGalleryRequest,
    savingGalleryPlugcodeReset,
    ToastMessage
} from "../../actions/gallery";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getPlugformViaCodeRequest, getPlugnoteViaCodeRequest, postClassifyInItsPlugformRequest} from "../../actions/plugcode";
import SubPlugcodePortalComponent from "../../components/specials/portal/SubPlugcodePortalComponent";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Checkbox, Tooltip } from '@material-ui/core';
import colors from '../../assets/themes/Colors';
import useElementSize from '../../utils/hooks/useElementSize';
import { toast } from 'react-toastify';
import ApplicationConfig from '../../config/ApplicationConfig';
import axios from "axios";
import qs from "querystringify";

const drawerWidth = 320;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '80px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openCloseRelative: {
        marginTop : 10,
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'15px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    openClose: {
        bottom: 0,
        position: 'absolute',
        width : '100%'
    },
    content: {
        //width : `calc(100% - ${drawerWidth}px)`,
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 20px',
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight: 'calc(90vh - 200px)',
        overflow: "scroll",
    },
    contentModelList : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        backgroundColor: 'white',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight: 'calc(90vh - 100px)',
        overflow: "scroll",
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    plugField: {
        border: '2px solid',
        borderColor: colors.mainButton,
        borderRadius: 8,
        backgroundColor: '#F1F1F1',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '8px',
        height: '38px',
        width: 200,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '17px',
        MozBorderRadius : '8px',
        WebkitBorderRadius: '8px',
    },
    plugcodeInputField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '8px',
        height: 50,
        width: 250,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: 20,
        MozBorderRadius : '8px',
        WebkitBorderRadius: '8px',
        '@media (max-width: 760px)': {
            width: 300,
        }
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    tabsRoot: {
        '& .MuiTab-labelIcon': {
            minHeight: '50px !important',
            maxHeight: '50px !important',
        },
        '& div.MuiTab-textColorInherit': {
            opacity: '1 !important',
        },
        '& div.MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'hidden !important'
        },
        '& div.MuiTab-root': {
            flexShrink: '1 !important',
            minWidth: '10vw !important',
            '@media (min-width: 600px)': {
                minWidth: '0px !important'
            }
        },
        '& div.MuiTabs-scroller': {
            flex: '0 1 auto',
        },
        '& div.MuiTabs-fixed': {
            // width: 'auto !important'
        },
        '& .MuiTabs-root': {
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        justifyContent: 'flex-end',
        width: 'fit-content',
        alignSelf: 'flex-end',
        maxWidth: 'calc(100vw - 40px)',
    },
    indicator:{
        backgroundColor: 'transparent'
    },
    selected:{
        background: colors.mainButton,
        color: "white",
        position: 'relative',
        height: 50,
        '& .MuiTab-wrapper':{
            flexDirection: 'row-reverse !important'
        }
    },
    tabRoot: {
        height: 50,
        '& .MuiTab-wrapper':{
            flexDirection: 'row-reverse !important'
        },
        '& .MuiTab-labelIcon': {
            minHeight: '50px !important',
            maxHeight: '50px !important',
        },
        opacity: '1 !important',
        '@media (max-width: 950px)': {
            maxWidth: 'calc((100vw - 50px) / 4) !important'
        }
    },
    selectedLeft:{
        background: colors.mainButton,
        color: "white",
        position: 'relative',
        height: 50,
        marginLeft: 'auto',
    },
    unselectedTabLeft: {
        '& .MuiTab-wrapper':{
            flexDirection: 'row-reverse !important'
        },
        '& .MuiTab-labelIcon': {
            minHeight: '50px !important',
            maxHeight: '50px !important',
        },
        marginLeft: 'auto',
        opacity: '1 !important',
        '@media (min-width: 600px)': {
            minWidth: '0px !important',
            flexShrink: '1 !important',
        },
        '@media (min-width: 950px)': {
            minWidth: '0px !important',
        }
    },
    selectedRight:{
        background: colors.mainButton,
        color: "white",
        position: 'relative',
        height: 50,
        '& .MuiTab-wrapper':{
            flexDirection: 'row-reverse !important'
        },
        marginRight: 50,
    },
    unselectedTabRight: {
        height: 50,
        '& .MuiTab-wrapper':{
            flexDirection: 'row-reverse !important'
        },
        '& .MuiTab-labelIcon': {
            minHeight: '50px !important',
            maxHeight: '50px !important',
        },
        marginRight: 50,
        opacity: '1 !important'
    },
    accordionCtn: {
       '& .MuiAccordionSummary-content': {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
       }
    },
    tripleInlineSpace: {
        minWidth: 460,
        '@media (max-width: 950px)': {
            minWidth: 120
        }
    },
    inlineBtn: {
        minWidth: 200,
        color: colors.mainButton,
        padding: 5,
        fontWeight: 'bold',
        textAlignLast: 'center',
        '@media (max-width: 950px)': {
            minWidth: 40
        }
    },
    inlineBtnLeft: {
        minWidth: 130,
        '@media (max-width: 950px)': {
            minWidth: 40
        }
    },
    inlineBtnRight: {
        minWidth: 80,
        marginLeft: 30,
        '@media (max-width: 950px)': {
            minWidth: 40,
            marginLeft: 0,
        }
    },
    centeredBtn: {
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    activeBtn: {
        color: colors.white,
        backgroundColor: colors.mainButton,
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        '&:hover': {
            boxShadow: '0px 0px 10px 1px',
            scale: 1.2
        },
    },
    inactiveBtn: {
        color: colors.mainButton,
        backgroundColor: colors.white,
        border: '2px solid',
        borderRadius: '50%',
        borderColor: colors.mainButton,
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            boxShadow: '0px 0px 10px 1px',
            scale: 1.2
        },
    },
    mainCtn: {
        '& .MuiAccordion-root': {
            padding: '0px !important',
            margin: '0px !important',
            borderRadius: 8,
            minHeight: '50px !important',
            '&:hover': {
                background: colors.mainPale,
                // color: `${colors.unselectedAlpha} !important`
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '0px !important'
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '50px !important',
        }
    },
    plugcodesCtn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8,
    },
    plugcodeCtn: {
        maxWidth: 320,
        minWidth: 200,
    },
    newPlugcodeCtn: {
        border: '2px solid',
        borderColor: colors.bloodOrange
    },
    plugcodeHeader: {
        padding:'10px 10px 0px',
        '&:hover': {
            background: colors.mainPale,
            color: `${colors.unselectedAlpha} !important`
        }
    },
    plugcodeCode: {
        // alignSelf: 'flex-end',
        fontSize: 10,
        display: 'flex',
        justifyContent: 'space-between'
    },
    plugcodeLbl: {
        whiteSpace: 'nowrap',
        fontSize: 15,
        fontWeight: 'bold'
    },
    actionCtn: {
        display :  'flex',
        justifyContent : 'end',
        paddingBottom: 8,
    },
    countBadge: {
        background: colors.unselectedMain,
        color: colors.white,
        marginTop: -16,
        width: '20px !important',
        height: '20px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        fontSize: 12,
        fontWeight: 'bold',
        border: '1px solid white',
    },
    titleCtn: {
        position: 'relative',
        marginTop: 5,
        '@media (max-width: 600px)': {
            display: 'flex',
            flexDirection: 'column-reverse',
            marginTop: 0
        },
    },
    folderCtn: {
        color: colors.mainButton,
        alignSelf: 'flex-end',
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        height: 30
    },
    plugInputCtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20,
        '@media (max-width: 760px)': {
            width: 420,
            flexDirection: 'row',
            justifyContent: 'center',
            maxWidth: '90vw',
            flexWrap: 'wrap',
            marginBottom: 8
        }
    },
    searchInputCtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        position: 'absolute',
        right: 0,
        top: 0,
        '@media (max-width: 1024px)': {
            flexDirection: 'column-reverse',
            gap: 0,
            top: -17,
        },
        '@media (max-width: 600px)': {
            position: 'inherit',
            alignSelf: 'flex-end',
            top: 'auto'
        }
    },
    searchFieldsCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '20px 10px',
        '@media (max-width: 760px)': {
            flexDirection: 'column'
        }
    },
    button: {
        width: 150,
        height: 50,
        backgroundColor: colors.mainButton,
        borderRadius: 4,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        padding: 6,
        cursor: 'pointer',
    },
    inactiveButton: {
        width: 150,
        height: 50,
        backgroundColor: colors.frost,
        borderRadius: 4,
        color: colors.darkSilver,
        display: 'flex',
        justifyContent: 'center',
        padding: 6,
        cursor: 'not-allowed'
    },
    subTitleCtn: {
        position: 'absolute',
        left: 14,
        top: 10,
        '@media (max-width: 950px)': {
            position: 'inherit',
            marginBottom: 12,
        },
        '@media (max-width: 760px)': {
           textAlignLast: 'center',
        }
    },
    userInfoCtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    userInfo: {},
    userCompany: {
        fontStyle: 'italic',
        color: colors.darkSilver
    },
    ownerComment: {
        fontSize: 14,
        color: colors.main,
        border: '1px solid',
        borderColor: colors.main,
        borderRadius: 4,
        padding : '0px 10px',
        width: '100%',
    },
    saveCtn: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    saveBtn: {
        alignSelf: 'center',
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 10,
        width: 540,
        margin: '15px 0px',
        '@media (max-width: 600px)': {
            width: '100%'
        },
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className="fullWidth"
            {...other}
        >
            {value === index && (
                <div className="margin-10">
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ActionButton (props) {
    const classes = useStyles()
    const { t, } = useTranslation(['common', 'models', 'global']);
    return(
    <div className={classes.actionCtn}>
        {props.item.isNew &&
        <span className="cursor" onClick={(event) => props.handleReject(props.item)}>
            <Tooltip title={t('common:global.reject')} aria-label="add" placement="top">
                <button className="button-exit">
                    {t('common:global.reject')}
                </button>
            </Tooltip>
        </span>}

        {props.item.isNew &&
        <span className="cursor" onClick={(e) => props.handleAccept(e, props.item)}>
            <Tooltip title={t('common:global.accept')} aria-label="add" placement="top">
                <button className="button">
                    {t('common:global.accept')}
                </button>
            </Tooltip>
        </span>
        }

        {props.isNew !== true &&
        <span className="cursor" onClick={(event) => props.handleFavorite(event, props.item)}>
            <Tooltip title={props.item.favorite ? t('common:models.favorite-remove') : t('common:models.favorite-add')} aria-label="add" placement="top">
                <span className={"material-icons md-25 unselected"}>
                    {props.item.favorite ? 'star' : 'star_outline'}
                </span>
            </Tooltip>
        </span>}
        <span className="cursor" onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleDelete(props.item)}}>
            <Tooltip title={t('common:plugcode.delete')} aria-label="add" placement="top">
                <span id="icon-close" className="material-icons md-25" style={{color: colors.unselectedMain}}>
                    delete
                </span>
            </Tooltip>
        </span>
    </div>
    )
}

function FolderButton (props) {
    const classes = useStyles()
    const { t, } = useTranslation(['common']);
    const favorites = props.favorites
    const archived = props.archived

    const handleOnFavorites = () => {
        props.setFavorites(!favorites)
    }
    const handleOnArchived = () => {
        props.setArchived(!archived)
    }
    return(
            <div
                id='arch-cb'
                onClick={() => {handleOnFavorites()}}
                style={{cursor: 'pointer', color: favorites? colors.mainButton : colors.unselectedMain}}
                >
                <Checkbox
                    checked={favorites}
                    onChange={() => {handleOnFavorites()}}
                    style={{ width: '30px', marginRight: '5px'}}
                />
                {t('plugcode.consult-plugcode-favorite')}
                <span className={"material-icons md-25 main"}>
                    {props.favorites ? 'star' : 'star_outline'}
                </span>

            {/* <div
                id='fav-cb'
                onClick={() => {handleOnArchived()}}
                style={{cursor: 'pointer', color: archived? colors.mainButton : colors.unselectedMain}}
                >
                <Checkbox
                    checked={archived}
                    onChange={() => {handleOnArchived()}}
                    style={{width: '30px', marginRight: '5px'}}
                />
                {t('plugcode.consult-plugcode-error')}
            </div> */}
            </div>
    )
}

function PlugcodeList(props) {
    const classes = useStyles();
    const [t, i18n] = useTranslation(["common", "models"]);
    const [headerRef, { width, height }] = useElementSize()
    const [headerW, setHeaderW] = useState(width - 50)
    const { promiseInProgress } = usePromiseTracker();

    const [favorites, setFavorites] = useState(false)
    const [archived, setArchived] = useState(false)
    const [open, setOpen] = useState(true);

    const user = props.user;
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(!!environment ? environment.userhash : null);
    const sortedListEnvironnement = props.sortedListEnvironnement;

    const token = props.token;

    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(false);
    // const [activeReset, setActiveReset] = useState(false);
    // const [viewInputSearchCode, setViewInputSearchCode] = useState(false);
    const [searchPlugcode, setSearchPlugcode] = useState('');
    const [response, setResponse] = useState(null)
    const [pcode, setPcode] = useState(null)
    const [saving, setSaving] = useState(false)

    const [codeType, setCodeType] = useState(null)
    const [searchActive, setSearchActive] = useState(props.isSearchActive && typeof props.searchParams.content !== "undefined");
    const [activeSearch, setActiveSearch] = useState(false);
    const [value, setValue] = useState(3);
    const [filter, setFilter] = useState(null)
    const [expanded, setExpanded] = useState("")

    const [search, setSearch] = useState("");
    const [pnGalleries, setPNGalleries] = useState([]);
    const [pfGalleries, setPFGalleries] = useState([]);
    const [pnPfFavorites, setPnPfFavorites] = useState([]);
    const [pnPfAccepted, setPnPfAccepted] = useState([]);
    const [pnPfCodeError, setPnPfCodeError] = useState([]);
    const [codeList, setCodeList] = useState([])

    const [favPn, setFavPn] = useState([])
    const [favPf, setFavPf] = useState([])
    const [allFav, setAllFav] = useState(favPf.concat(favPn))

    const [openSave, setOpenSave] = useState(false)
    const [mainListPNPPCount, setMainListPNPPCount] = useState(0);
    const [mainListPFCount, setMainListPFCount] = useState(0);
    const [mainListNEWCount, setMainListNEWCount] = useState(0);
    const [mainListErrorCount, setMainListErrorCount] = useState(0);

    //styles des pastilles numériques
    const styles = {
        newActive: {
            backgroundColor: colors.bloodOrange
        },
        newNb: {
            borderColor: colors.bloodOrange,
            color: colors.bloodOrange
        },
        newCtn: {
            display: 'flex',
            paddingLeft: 70,
            width: headerW,
        },
        newCtnNarrow: {
            width: 35,
            display: 'flex',
            paddingLeft: 0,
        },
        pfActive: {backgroundColor: colors.plugformBlue},
        pfNb: {
            borderColor: colors.plugformBlue,
            color: colors.plugformBlue
        },
        pfCtn: {
            display: 'flex',
            paddingLeft: 230,
            width: headerW,
        },
        pfCtnNarrow: {
            width: 35,
            display: 'flex',
            paddingLeft: 0,
        },
        pnActive: {backgroundColor: colors.unselectedMain},
        pnNb: {
            borderColor: colors.unselectedMain,
            color: colors.unselectedMain
        },
        pnCtn: {
            display: 'flex',
            paddingLeft: 410,
            width: headerW,
        },
        pnCtnNarrow: {
            width: 35,
            display: 'flex',
            paddingLeft: 0,
        },
    }

    useEffect(() => {
        props.attemptGetGalleries(props.user.profil.hash);
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleChangeEnvironnement = (event) => {
        const value = event.target.value;
        let newEnvironnement = sortedListEnvironnement.find((element, index) => {
            return element.userhash === value;
        })
        setCurrentEnvironnement(newEnvironnement);
        setHash(newEnvironnement.userhash);
        setModels([]);
        setLoading(true);
    }

    const handleClearSearchCode = () => {
        setSearchPlugcode('');
        setActiveSearch(false);
        setOpenSave(false)
        setResponse(null)
    }

    const handleSearchPlugCode = () => {
        let code = ""
        setActiveSearch(true);
        if(searchPlugcode.length > 0){
            code = searchPlugcode.toLocaleUpperCase()
            if(code.startsWith('PF')){
                props.attemptGetPlugformViaCodeRequest(code, environment.userhash, false)
            }
            else {
                props.attemptGetPlugnoteViaCodeRequest(code, environment.userhash, false)
            }
        }
    };

    const handleOpenPlugform = (plugform) => {
        setPlugcode(plugform)
        props.attemptGetPlugformViaCodeRequest(plugform.refcode, environment.userhash, true)
    };

    const handleOpenPlugnote = (plugnote) => {
        setPlugcode(plugnote)
        props.attemptGetPlugnoteViaCodeRequest(plugnote.refcode, environment.userhash, true)
    };

    const handleOpenPlugcode = (item) => {
        if (item.refcode.startsWith('PF-')) handleOpenPlugform(item);
        else handleOpenPlugnote(item)
    }

    const handleDeletePlugform = (plugform) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('global.delete'),
            buttons: [{
                    label: t('global.yes'),
                    onClick: () => props.attemptDeleteGalleryRequest(plugform.hash, plugform.mainuserhash)
                },
                {label: t('global.cancel')}]
        });
    };

    const handleDeletePlugnote = (plugnote) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('global.delete'),
            buttons: [{
                label: t('global.yes'),
                onClick: () => props.attemptDeleteGalleryRequest(plugnote.hash, plugnote.mainuserhash)
                },
                {label: t('global.cancel')}]
        });
    };

    function handleDeletePlugcode (plug) {
        if(plug.refcode.startsWith('PF')) props.attemptDeleteGalleryRequest(plug.hash, plug.mainuserhash);
        else props.attemptDeleteGalleryRequest(plug.hash, plug.mainuserhash)
    }

    const handleFavorite = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        let gallery = {
            plugcode : item.refcode,
            galleryhash : item.hash,
            userhash : props.uh,
            mainuserhash : props.umh,
            favorite : !item.favorite,
        }
        props.attemptUpdateGalleryRequest(gallery, item.mainuserhash);
        // confirmAlert({
        //     title: t('global.confirm'),
        //     message: t('global.save'),
        //     buttons: [
        //         {
        //             label: t('global.yes'),
        //             onClick: () => props.attemptUpdateGalleryRequest(gallery, item.mainuserhash)
        //             },
        //         {
        //             label: t('global.cancel'),
        //         }
        //     ]
        // });
    }

    const handleAccept = (e, item) => {
        e.preventDefault(); e.stopPropagation();
        let gallery = {
            plugcode : item.refcode,
            galleryhash : item.hash,
            userhash : props.uh,
            mainuserhash : props.umh,
            accepted : true,
        }
        if(!codeList.includes(item.refcode)) confirmAlert({
            title: t('global.confirm'),
            message: t('global.save'),
            buttons: [{
                label: t('global.yes'),
                onClick: () =>
                {props.attemptUpdateGalleryRequest(gallery, item.mainuserhash)}
                },
                {label: t('global.cancel')}]
        });
        else confirmAlert({
            title: t('global.confirm'),
            message: t('plugcode.code-exists'),
            buttons: [{
                label: t('global.yes'),
                onClick: () => {props.attemptUpdateGalleryRequest(gallery, item.mainuserhash)}
                },
                {label: t('global.cancel')}]
        });
    }

    const handleReject = (item) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('global.save'),
            buttons: [{
                label: t('global.yes'),
                onClick: () => props.attemptDeleteGalleryRequest(item.hash, item.mainuserhash)
                },
                {label: t('global.cancel')}]
        });
    }

    function handleRestorePlugcode () {
        let plug = getPlugcode()
        if(plug !== null) {
            setPcode(plug)
        }
    }

    const handleChangeTextInput = (event, value) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        if(search.length > 0){
            setSearchActive(true);
            let mainListTmpPNPP = [];
            props.galleriesPN.map((item, index) => {
                let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }
                let isInArray = mainListTmpPNPP.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;
                if(!isInArray){
                    mainListTmpPNPP.push(object)
                }
            });

            let listPNPP = props.galleriesPN.filter(data => {
                let commentPerso = '';
                let commentProprio = '';
                let firstName = '';
                let lastName = '';
                let refCode = '';

                if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                    commentPerso = data.commentperso;
                }

                if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                    commentProprio = data.commentproprio;
                }

                if(typeof  data.owner !== "undefined"){
                    firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                    lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
                }

                if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                    refCode = data.refcode;
                }

                if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
                {
                    return data;
                }
            })

            let mainListPNPP = mainListTmpPNPP.map((item,index) => {
                item.list = listPNPP.filter((listItem, listIndex) => {
                    return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
                });

                item.list = [...item.list].sort(
                    function(a, b) {
                        if(a.favorite === true){
                            return -1;
                        }else return 1;
                    }
                );
                return item;
            })

            let mainListTmpPF = [];
            props.galleriesPF.map((item, index) => {
                let object = {
                    company: item.owner.company,
                    email: item.owner.email,
                    id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                    list : []
                }

                let isInArray = mainListTmpPF.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

                if(!isInArray){
                    mainListTmpPF.push(object)
                }
            });

            let listPF = props.galleriesPF.filter(data => {
                let commentPerso = '';
                let commentProprio = '';
                let firstName = '';
                let lastName = '';
                let refCode = '';

                if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                    commentPerso = data.commentperso;
                }

                if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                    commentProprio = data.commentproprio;
                }

                if(typeof  data.owner !== "undefined"){
                    firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                    lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
                }

                if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                    refCode = data.refcode;
                }

                if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
                {
                    return data;
                }
            })

            let mainListPF = mainListTmpPF.map((item,index) => {
                item.list = listPF.filter((listItem, listIndex) => {
                    return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
                });

                item.list = [...item.list].sort(
                    function(a, b) {
                        if(a.favorite === true){
                            return -1;
                        }else return 1;
                    }
                );
                return item;
            })

            let mainListTmpNEW = [];

            props.galleriesPNPFNew.map((item, index) => {
                let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

                let isInArray = mainListTmpNEW.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;
                if(!isInArray){
                    mainListTmpNEW.push(object)
                }


            });

            let listNEW = props.galleriesPNPFNew.filter(data => {
                let commentPerso = '';
                let commentProprio = '';
                let firstName = '';
                let lastName = '';
                let refCode = '';

                if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                    commentPerso = data.commentperso;
                }

                if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                    commentProprio = data.commentproprio;
                }

                if(typeof  data.owner !== "undefined"){
                    firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                    lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
                }

                if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                    refCode = data.refcode;
                }

                if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
                {
                    return data;
                }
            })

            let mainListNew = mainListTmpNEW.map((item,index) => {
                item.list = listNEW.filter((listItem, listIndex) => {
                    return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
                });

                item.list = [...item.list].sort(
                    function(a, b) {
                        if(a.favorite === true){
                            return -1;
                        }else return 1;
                    }
                );
                return item;
            })
            let mainListTmpError = [];
            props.galleriesPNPFError.map((item, index) => {
                let object = {
                    company: item.owner.company,
                    email: item.owner.email,
                    id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                    list : []
                }

                let isInArray = mainListTmpError.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

                if(!isInArray){
                    mainListTmpError.push(object)
                }
            });

            let listError = props.galleriesPNPFError.filter(data => {
                let commentPerso = '';
                let commentProprio = '';
                let firstName = '';
                let lastName = '';
                let refCode = '';

                if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                    commentPerso = data.commentperso;
                }

                if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                    commentProprio = data.commentproprio;
                }

                if(typeof  data.owner !== "undefined"){
                    firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                    lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
                }

                if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                    refCode = data.refcode;
                }

                if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
                {
                    return data;
                }
            })

            let mainListError = mainListTmpError.map((item,index) => {
                item.list = listError.filter((listItem, listIndex) => {
                    return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
                });

                item.list = [...item.list].sort(
                    function(a, b) {
                        if(a.favorite === true){
                            return -1;
                        }else return 1;
                    }
                );
                return item;
            })

            setMainListNEWCount(listNEW.length)
            setMainListPNPPCount(listPNPP.length)
            setMainListPFCount(listPF.length)
            setMainListErrorCount(listError.length)
            setPNGalleries(mainListPNPP);
            setPFGalleries(mainListPF);
            setPnPfAccepted(mainListNew);
            setPnPfCodeError(mainListError);
        }
    };

    const handleResetSearch = () => {
        setSearch("");
        setSearchActive(false);
        let mainListTmpPNPP = [];
        props.galleriesPN.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

            let isInArray = mainListTmpPNPP.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

            if(!isInArray){
                mainListTmpPNPP.push(object)
            }
        });

        let listPNPP = props.galleriesPN;

        let mainListPNPP = mainListTmpPNPP.map((item,index) => {
            item.list = listPNPP.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })

        let mainListTmpPF = [];

        props.galleriesPF.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

            let isInArray = mainListTmpPF.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

            if(!isInArray){
                mainListTmpPF.push(object)
            }
        });

        let listPF = props.galleriesPF;

        let mainListPF = mainListTmpPF.map((item,index) => {
            item.list = listPF.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })

        let mainListTmpNEW = [];
        props.galleriesPNPFNew.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

            let isInArray = mainListTmpNEW.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

            if(!isInArray){
                mainListTmpNEW.push(object)
            }
        });

        let listNEW = props.galleriesPNPFNew;

        let mainListNew = mainListTmpNEW.map((item,index) => {
            item.list = listNEW.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })

        let mainListTmpError = [];
        props.galleriesPNPFError.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

            let isInArray = mainListTmpError.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

            if(!isInArray){
                mainListTmpError.push(object)
            }
        });

        let listError = props.galleriesPNPFError;
        let mainListError = mainListTmpError.map((item,index) => {
            item.list = listError.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })

        setMainListNEWCount(props.galleriesPNPFNew.length)
        setMainListPNPPCount(props.galleriesPN.length)
        setMainListPFCount(props.galleriesPF.length)
        setMainListErrorCount(props.galleriesPNPFError.length)
        setPNGalleries(mainListPNPP);
        setPFGalleries(mainListPF);
        setPnPfAccepted(mainListNew);
        setPnPfCodeError(mainListError);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue === value ? 3 : newValue);
        setOpenFolder({"filter": null, "id": "", "tab": newValue})
    };

    const handleOnFilter = (valeur, id) => {
        if(filter !== null && filter === id) {
            setFilter(null);
        }
        else if (valeur === filter) {
            setFilter(null);
            setExpanded(id)
        }
        else {
            setFilter(valeur)
            setExpanded(id)
        }
        setOpenFolder({"filter": valeur, "id": id, "tab": value})
    }

    const handleOnExpand = (e, id) => {
        e.preventDefault(); e.stopPropagation();
        if(expanded === id) {
            delOpenFolder()
            setExpanded('')
        }
        else {
            setExpanded(id)
            if(id === null) setFilter(null)
        }
        setOpenFolder({"filter": null, "id": id, "tab": value})
    }

    const handleSearchPlugcode = () => {
        if (searchPlugcode.length > 0) {
            if (searchPlugcode.startsWith("PF")) {
                //props.attemptGetPlugform(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pfcode/v2/modelcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(searchPlugcode),
                    }, '?'))
                        .then((response) => {
                        if(response.data.result == true || response.data.result === 'ok'){
                            setResponse(response.data)
                        }
                        else if(response.data.result == false || response.data.result === 'onk'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                        else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        } else {
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                    })
                        .catch((response) => {
                        //je lance une notification (toast error)
                        console.log("error", response);
                        // toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
                )
            }
            else {
                //props.attemptGetPlugnote(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(searchPlugcode),
                    }, '?'))
                        .then((response) => {
                        console.log(response);
                        if(response.data.result == true){
                            setResponse(response.data)
                        }else if(response.data.result == false){
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        } else {
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                    })
                        .catch((response) => {
                        //je lance une notification (toast error)
                        console.log("error", response);
                        // toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
                )
            }
        }
    }

    function handleOpenSaveModal () {
        if (searchPlugcode !== "") {
            setOpenSave(true)
            handleSearchPlugcode()
        }
    }

    function findInGallery (code) {
        if(code.startsWith('PF')) {
            let plugform = null
            pfGalleries.forEach((entry) => {
                let index = -1
                entry.list.map((obj, ind) => {
                    if(obj.refcode === code) index = ind
                })
                if(index !== -1) plugform = entry.list[index]
            })
            return plugform
        }
        else {
            let plugnote = null
            pnGalleries.forEach((entry) => {
                let index = -1
                entry.list.map((obj, ind) => {
                    if(obj.refcode === code) index = ind
                })
                if(index !== -1) plugnote = entry.list[index]
            })
            return plugnote
        }
    }

    function handleOnSaveCode () {
        let gallery = {
            plugcode : searchPlugcode.trim(),
            comment : "",
        };

        if(!codeList.includes(searchPlugcode.trim())) {
            setSaving(true)
            props.attemptCreateGalleryRequest(gallery, props.user.profil.hash, false)
        }
        else confirmAlert({
            title: t('global.confirm'),
            message: t('plugcode.code-exists'),
            buttons: [{
                label: t('plugcode.replace'),
                onClick: () => {
                    setSaving(true)
                    handleDeletePlugcode(findInGallery(searchPlugcode.trim()))
                    props.attemptCreateGalleryRequest(gallery, props.user.profil.hash, false)
                }},
                {label: t('global.cancel')}]
        });

    }

    function handleOnSaveForm () {}

    function handleOnSaveNote () {
        let plugnote = response.data
        props.attemptClassifyInItsPlugform(plugnote, plugnote.hash, environment.userhash);
        setResponse(null)
        setOpenSave(false)
    }

    useEffect(() => {
        if(props.saving) {
            //get new object from sesssionStorage
            props.attemptGetGalleries(props.user.profil.hash)
            let plug = getPlugcode().data
            if(plug !== null) {
                setPcode(plug)
                setSaving(false)
                setOpenSave(false)
                setResponse(null)
                let mail = plug.owner.email !== "" && plug.owner.email !== undefined ? plug.owner.email.split('@')[0] : null
                let id = plug.owner.company ? plug.owner.company : plug.owner.firstname + " " + plug.owner.lastname
                toast.success(
                <ToastMessage
                    folder={mail + '(' + id + ')'}
                    message={mail !== null ? 'plugcode.plugcode-added-to': 'plugcode.plugcode-added'}
                    title='plugcode.create-success'
                    />, {autoClose: 5000})
                setExpanded(id)
                setFilter(plug.refcode.startsWith('PF')? 'PF' : 'PN')
            }
            props.attemptResetSavingGalleryPlugcode()
        }
        updateCodeList()
    }, [props.saving])

    useEffect(() => {
        if(response !== null) {
            if(response.model === undefined) setCodeType("PF");
            else setCodeType("PN");
            }
        else setCodeType(null)
    }, [response])

    useEffect(() => {
        let mainListTmpPNPP = [];
        props.galleriesPN.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }
            let isInArray = mainListTmpPNPP.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;
            if(!isInArray){
                mainListTmpPNPP.push(object)
            }
        });
        let listPNPP = props.galleriesPN.filter(data => {
            let commentPerso = '';
            let commentProprio = '';
            let firstName = '';
            let lastName = '';
            let refCode = '';

            if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                commentPerso = data.commentperso;
            }

            if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                commentProprio = data.commentproprio;
            }

            if(typeof  data.owner !== "undefined"){
                firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
            }

            if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                refCode = data.refcode;
            }

            if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
            {
                return data;
            }
        })
        let mainListPNPP = mainListTmpPNPP.map((item,index) => {
            item.list = listPNPP.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })
        setPNGalleries(mainListPNPP);
        setMainListPNPPCount(props.galleriesPN.length)
    }, [props.galleriesPN]);

    useEffect(() => {
        let mainListTmpPF = [];
        props.galleriesPF.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

            let isInArray = mainListTmpPF.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

            if(!isInArray){
                mainListTmpPF.push(object)
            }


        });
        let listPF = props.galleriesPF.filter(data => {
            let commentPerso = '';
            let commentProprio = '';
            let firstName = '';
            let lastName = '';
            let refCode = '';

            if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                commentPerso = data.commentperso;
            }

            if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                commentProprio = data.commentproprio;
            }

            if(typeof  data.owner !== "undefined"){
                firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
            }

            if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                refCode = data.refcode;
            }

            if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
            {
                return data;
            }
        })
        let mainListPF = mainListTmpPF.map((item,index) => {
            item.list = listPF.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });
            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })
        setMainListPFCount(props.galleriesPF.length)
        setPFGalleries(mainListPF);
    }, [props.galleriesPF]);

    useEffect(() => {
        let favs = filterFav(pfGalleries)
        setFavPf(favs)
        updateCodeList()
    }, [pfGalleries, pnPfFavorites])

    useEffect(() => {
        let favs = filterFav(pnGalleries)
        setFavPn(favs)
        updateCodeList()
    }, [pnGalleries, pnPfFavorites])

    useEffect(() => {
        setPnPfFavorites(props.galleriesPNPFFavorites);
        setAllFav(sortFavorites(props.galleriesPNPFFavorites))
    }, [props.galleriesPNPFFavorites]);

    useEffect(() => {
        let mainListTmpNEW = [];
        props.galleriesPNPFNew.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }
            let isInArray = mainListTmpNEW.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;
            if(!isInArray){
                mainListTmpNEW.push(object)
            }
        });

        let listNEW = props.galleriesPNPFNew.filter(data => {
            let commentPerso = '';
            let commentProprio = '';
            let firstName = '';
            let lastName = '';
            let refCode = '';

            if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                commentPerso = data.commentperso;
            }

            if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                commentProprio = data.commentproprio;
            }

            if(typeof  data.owner !== "undefined"){
                firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
            }

            if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                refCode = data.refcode;
            }

            if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
            {
                return data;
            }
        })

        let mainListNew = mainListTmpNEW.map((item,index) => {
            item.list = listNEW.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    } else return 1;
                }
            );
            return item;
        })
        setMainListNEWCount(props.galleriesPNPFNew.length)
        setPnPfAccepted(mainListNew);
    }, [props.galleriesPNPFNew]);

    useEffect(() => {
        let mainListTmpError = [];

        props.galleriesPNPFError.map((item, index) => {
            let object = {
                company: item.owner.company,
                email: item.owner.email,
                id : item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname,
                list : []
            }

            let isInArray = mainListTmpError.find(function(el){ return el.id === (item.owner.company ? item.owner.company : item.owner.firstname + " " + item.owner.lastname) }) !== undefined;

            if(!isInArray){
                mainListTmpError.push(object)
            }


        });

        let listError = props.galleriesPNPFError.filter(data => {
            let commentPerso = '';
            let commentProprio = '';
            let firstName = '';
            let lastName = '';
            let refCode = '';

            if(typeof  data.commentperso !== "undefined" && data.commentperso.length > 0){
                commentPerso = data.commentperso;
            }

            if(typeof  data.commentproprio !== "undefined" && data.commentproprio.length > 0){
                commentProprio = data.commentproprio;
            }

            if(typeof  data.owner !== "undefined"){
                firstName = typeof  data.owner.firstname !== "undefined" && data.owner.firstname.length > 0 ? data.owner.firstname : '';
                lastName = typeof  data.owner.lastname !== "undefined" && data.owner.lastname.length > 0 ? data.owner.lastname : '';
            }

            if(typeof  data.refcode !== "undefined" && data.refcode.length > 0){
                refCode = data.refcode;
            }

            if ((commentPerso || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (commentProprio || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (refCode || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (firstName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (lastName || '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
            {
                return data;
            }
        })

        let mainListError = mainListTmpError.map((item,index) => {
            item.list = listError.filter((listItem, listIndex) => {
                return item.id === (listItem.owner.company ? listItem.owner.company : listItem.owner.firstname + " " + listItem.owner.lastname);
            });

            item.list = [...item.list].sort(
                function(a, b) {
                    if(a.favorite === true){
                        return -1;
                    }else return 1;
                }
            );
            return item;
        })
        setMainListErrorCount(props.galleriesPNPFError.length)
        setPnPfCodeError(mainListError);
    }, [props.galleriesPNPFError]);

    useEffect(() => {
        setTimeout(() => {
            handleRestorePlugcode()
            let folder = getOpenFolder()
            if (folder !== null){
                setExpanded(folder?.id)
                setValue(folder?.tab)
                if (folder.filter !== null && folder.filter !== undefined) setFilter(folder.filter)
            }
        }, 1000)
    }, [])

    useEffect(() => {
        if(isPlausibleCode(searchPlugcode)) setOpenSave(true);
        else {
            setOpenSave(false)
            setResponse(null)
        }
    }, [searchPlugcode])

    const mainGallery = pnPfAccepted.concat(pnGalleries).concat(pfGalleries)
        .sort((a, b) => a.id.localeCompare(b.id))

    function groupGallery (gall) {
        if(gall.length > 0){
            let finObj = []
            let keys = []
            Object.values(gall).forEach((value, index) => {
                if(!keys.includes(value.id)) keys.push(value.id)
            })
            let lists = Array.from({length: keys.length}, (v, i) => [])
            Object.values(gall).forEach((value, index) => {
                let atIndex = keys.indexOf(value.id)
                lists[atIndex] = lists[atIndex].concat(value.list)
            })
            for (let index = 0; index < keys.length; index++) {
                if(lists[index].length > 0) {
                    const newVal = {
                        company: lists[index][0].owner.company,
                        email: lists[index][0].owner.email,
                        id: keys[index],
                        list: lists[index]}
                    finObj.push(newVal)
                }
            }
            return finObj
        }
        return []
    }

    function sortFavorites (array) {
        let favs = []
        array.forEach((entry) => {
            let nom = entry.owner.lastname === undefined ? "" : entry.owner.lastname
            let prenom = entry.owner.firstname === undefined ? "" : entry.owner.firstname
            let id = entry.owner.company === "" ? (prenom + ' ' + nom).trim() : entry.owner.company

            let index = -1
            if(favs.length > 0) {
                index = favs.find(v => v.id === id)
                index = favs.indexOf(index)
            }
            if(index === -1) {
                let list = []
                list.push(entry)
                favs.push({
                    company: entry.owner.company,
                    email: entry.owner.email,
                    id: id,
                    list: list});
            }
            else {
                let value = favs[index]
                value.list.push(entry)
                favs[index] = value
            }
        })
        return favs
    }

    function isInFav (item) {
        let inFav = false
        pnPfFavorites.forEach(entry => {
            if(item.hash === entry.hash) inFav = true
            if(inFav) return true
        })
        return inFav
    }

    function filterFav (obj) {
        let _obj = []
        obj.forEach(entry => {
            let list = entry.list.filter((item) => {
                return isInFav(item)
            })
            if(list.length > 0) _obj.push({company: entry.company,
                    email: entry.email,id: entry.id, list: list})
        })
        return _obj
    }

    function updateCodeList () {
        let fullList = []
        let fullGallery = pnGalleries.concat(pfGalleries)
        if(fullGallery.length > 0) {
            fullGallery.map((entry) => {
                entry.list.map((code) => fullList.push(code.refcode))
            })
        }
        setCodeList(fullList)
    }

    function isPlausibleCode (code) {
        return code.trim().length === 15 && (code.startsWith('PP-') || code.startsWith('PF-') || code.startsWith('PN-') || code.startsWith('PW-'))
    }

    useEffect(() => {
        setHeaderW(width - 50)
    },[width, height])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Header>
                    <div id="btnCtn">
                        <div className="sous-header-without-height grey">
                            <div className={classes.titleCtn}>
                                <span className="titres black text_align_centered">Plugcodes</span>
                                <div className={classes.searchInputCtn}>
                                    <FolderButton
                                        archived={archived}
                                        favorites={favorites}
                                        setArchived={setArchived}
                                        setFavorites={setFavorites}
                                        />
                                    <Input
                                        id="standard-full-width"
                                        label=""
                                        //style={{ margin: 8 }}
                                        placeholder={t('common:global.search')}
                                        margin="none"
                                        onKeyPress={(e) => { if (e.key === 'Enter' && !searchActive) { handleSearch()}}}
                                        disableUnderline={true}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>}
                                        endAdornment={ searchActive &&
                                        <InputAdornment position="end" onClick={handleResetSearch} style={{cursor : 'pointer'}}>
                                            <span className={`material-icons md-25 unselected`}>highlight_off</span>
                                        </InputAdornment>
                                        }
                                        inputlabelprops={{
                                            className: classes.input,
                                        }}
                                        className={classes.textField}
                                        onChange={handleChangeTextInput}
                                        value={search}
                                    />
                                </div>
                            </div>

                            <br/>

                            <div className="sous-header_content black" style={{padding: '0px 10px'}}>
                                <div className="line flex_style">
                                </div>
                            </div>
                            <div className={classes.searchFieldsCtn}>
                                <div className={classes.plugInputCtn}>
                                    <div onClick={() => {handleSearchPlugCode()}} style={isPlausibleCode(searchPlugcode)? {cursor : 'pointer'} : null}>
                                        {/* <span id="icon-qrcode" className="material-icons md-24">
                                            qr_code
                                        </span> */}
                                        <span className="titres black">{t('plugcode.enter-plugcode')}</span>
                                    </div>
                                    <Input
                                        autoComplete='new-password'
                                        id="standard"
                                        className={`${classes.plugcodeInputField} ${classes.plugField}`}
                                        disableUnderline={true}
                                        endAdornment={searchPlugcode.length > 0  &&
                                            <div style={{display: 'inline-flex' , alignSelf: 'center'}}>
                                                <div onClick={handleClearSearchCode} style={{cursor : 'pointer'}}>
                                                    <span id="icon-qrcode" className="material-icons md-24">
                                                        clear
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        inputlabelprops={{shrink: true}}
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        label="Label"
                                        margin="none"
                                        onChange={(event) => setSearchPlugcode(event.target.value.toLocaleUpperCase())}
                                        onKeyPress={(e) => { if (e.key === 'Enter') handleSearchPlugCode()}}
                                        placeholder="P..."
                                        style={{ margin: 8 }}
                                        value={searchPlugcode}
                                    />
                                </div>
                                <div className={classes.plugInputCtn}>
                                    <div className={isPlausibleCode(searchPlugcode) ? classes.button : classes.inactiveButton} onClick={() => handleSearchPlugCode()}>
                                        <div style={{display: 'inline-flex' , alignSelf: 'center', gap: 5}}>
                                            <span >{t('plugcode.access-form')}</span>
                                        </div>
                                    </div>
                                    <div className={isPlausibleCode(searchPlugcode) ? classes.button : classes.inactiveButton} onClick={() => handleOpenSaveModal(!openSave)}>
                                        <div style={{display: 'inline-flex' , alignSelf: 'center', gap: 5}}>
                                            <span >{t('global.save')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {openSave && response !== null ?
                            <div className={classes.saveCtn}>
                                <div style={{display: 'inline-flex' , alignSelf: 'center', gap: 5}}>
                                    <span >{codeType === 'PN' ? response.model.label : response.data.label}</span>
                                </div>
                                <span className={classes.userCompany}>{response.proprio}</span>
                                <div className={classes.ownerComment} style={{whiteSpace: "pre-wrap"}}>{codeType === 'PN' ? response.data.pncomment : response.data.pfcomment}</div>
                                <div className={classes.saveBtn}>
                                    <div className={isPlausibleCode(searchPlugcode) ? classes.button : classes.inactiveButton} onClick={() => handleOnSaveCode()}>
                                        <div style={{display: 'inline-flex' , alignSelf: 'center', gap: 5, textAlign: 'center'}}>
                                            <span >{t('plugcode.choice-save-plugcode-title')}</span>
                                        </div>
                                    </div>
                                    {/* sauvegarde du plugform seul à implémenter
                                    <div className={isPlausibleCode(searchPlugcode) ? classes.button : classes.inactiveButton} onClick={() => handleOpenSaveModal(false)}>
                                        <div style={{display: 'inline-flex' , alignSelf: 'center', gap: 5, textAlign: 'center'}}>
                                            <span >{t('plugcode.choice-copy-plugnote-plugform')}</span>
                                        </div>
                                    </div> */}
                                    {codeType === 'PN' ?
                                    <div className={isPlausibleCode(searchPlugcode) ? classes.button : classes.inactiveButton} onClick={() => handleOnSaveNote()}>
                                        <div style={{display: 'inline-flex' , alignSelf: 'center', gap: 5, textAlign: 'center'}}>
                                            <span >{t('plugcode.choice-save-plugnote')}</span>
                                        </div>
                                    </div>
                                    : null}
                                </div>
                            </div>
                            : null}
                        </div>
                    </div>

                    <div className="sous-header_content black" style={{padding: '0px 10px'}}>
                        <div className="line flex_style">
                        </div>
                    </div>

                    <AppBar position="static" className="inheritHeaderClass margin-top-5" color="transparent"style={{position: 'relative'}}>
                        {searchActive ?
                        <span style={{position: 'absolute', left: 0, top: 2, display: 'flex', height: '80%', alignItems: 'center', padding: 10, backgroundColor: colors.frost, borderRadius: 4}} className="unselected">
                            {t('plugcode.active-filter')}
                            <span className={`material-icons md-25 unselected`} onClick={handleResetSearch}>highlight_off</span>
                        </span>
                        :
                        <span className={`titres black ${classes.subTitleCtn}`}>{t('common:plugcode.plugcodes-saved')}</span>
                        }

                        <Tabs
                            aria-label="simple tabs example"
                            classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
                            // initialSelectedIndex={value}
                            onChange={handleChange}
                            ref={headerRef}
                            scrollButtons="auto"
                            value={value}
                            >
                            <Tab
                                {...a11yProps(1)}
                                className="textTransformNone"
                                classes={{
                                    selected: classes.selectedLeft,
                                    root: `${classes.tabRoot} ${classes.unselectedTabLeft}`
                                    }}
                                icon={<span className={classes.countBadge} style={{backgroundColor: colors.bloodOrange}}>{mainListNEWCount}</span>}
                                label={t('common:plugcode.consult-plugcode-new')}
                                />

                            <Tab label={t('common:plugcode.consult-plugcode-form')} {...a11yProps(2)}
                                className="textTransformNone"
                                classes={{
                                    selected: classes.selected,
                                    root: `${classes.tabRoot}`
                                }}
                                icon={mainListPFCount > 0 && <span className={classes.countBadge} style={{backgroundColor: colors.plugformBlue}}>{favorites? '' : mainListPFCount}</span>}
                                />

                            <Tab label= {t('common:plugcode.consult-plugcode')} {...a11yProps(3)}
                                className="textTransformNone"
                                classes={{
                                    selected: classes.selected,
                                    root: `${classes.tabRoot}`
                                }}
                                icon={mainListPNPPCount > 0 && <span className={classes.countBadge}>{favorites? '' : mainListPNPPCount}</span>}
                            />

                            <Tab
                                {...a11yProps(4)}
                                className="textTransformNone"
                                classes={{
                                    selected: classes.selected,
                                    root: `${classes.tabRoot}`
                                    }}
                                icon={
                                <span className={classes.countBadge} style={{backgroundColor: value === 3 ? colors.white : colors.mainButton, color: value === 3 ? colors.mainButton : colors.white, fontWeight: 'bold'}}>
                                    {favorites? '' : (mainListNEWCount + mainListPNPPCount + mainListPFCount)}
                                </span>}
                                label={t('common:plugcode.consult-all')}
                                />
                        </Tabs>
                        <div className="sous-header_content black margin-top-5">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </AppBar>
                </Header>

                <Content className={` ${!listView ? classes.contentModelGrid : classes.contentModelList} scrollbar-hidden`} id='gallery-content'>
                    {/* NEW */}
                    {mainListNEWCount > 0 &&
                    <TabPanel value={value} index={0}>
                        <div className={classes.mainCtn}>
                            {pnPfAccepted
                                .sort((a, b) => a.email.localeCompare(b.email))
                                .map((object, indexObject) => {
                                let email = object.email.split('@')[0]
                                return(
                                <Accordion
                                    defaultExpanded={true}
                                    expanded={expanded === object.id}
                                    key={'pnPfAccepted-' + indexObject}
                                    TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary
                                        className={classes.accordionCtn}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        onClick={(e) => {handleOnExpand(e, object.id)}}
                                        >
                                        <div className={classes.userInfoCtn}>
                                            <Typography className={classes.userInfo}>{email} ({object.list.length})</Typography>
                                            <Typography className={classes.userCompany}>{object.company}</Typography>
                                        </div>
                                        <span id="count-new-ctn"
                                            style={headerW < 590 || window.innerWidth < 950 ? styles.newCtnNarrow : styles.newCtn}
                                            >
                                            <span
                                                className={classes.activeBtn}
                                                style={styles.newActive}
                                                >
                                                {object.list.length}
                                            </span>
                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                            className={classes.plugcodesCtn}
                                            onClick={(e) => {handleOnExpand(e, object.id)}}
                                            >
                                            {object.list
                                                .sort((a, b) => {return a.model.toLowerCase().localeCompare(b.model.toLowerCase())})
                                                .map((item, index) => {
                                                if(item.visuel === "PN" || item.visuel === 'PP'){
                                                    return (
                                                    <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn}`}>
                                                        <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                            <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                    {typeof item.owner !== "undefined" &&
                                                                    <div className={` ${classes.plugcodeLbl}`}>
                                                                        {typeof item.model !== 'undefined' &&
                                                                        <span className={classes.plugcodeLbl} style={{color: colors.bloodOrange}}>
                                                                            {item.model}
                                                                        </span>}
                                                                    </div>}
                                                                </div>
                                                                <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                    <ActionButton
                                                                        item={item}
                                                                        isNew={true}
                                                                        handleOpenPlugform={handleOpenPlugnote}
                                                                        handleDelete={handleDeletePlugnote}
                                                                        handleFavorite={handleFavorite}
                                                                        handleAccept={handleAccept}
                                                                        handleReject={handleReject}
                                                                        />
                                                                </span>
                                                            </div>

                                                            {/* <div style={{height:'12px' , marginLeft : '10px' , marginRight : '10px'}}>
                                                                <div className="line lightgrey flex_style">
                                                                </div>
                                                            </div> */}

                                                            <SubPlugcodePortalComponent
                                                                item={item}
                                                                handleOpenPlugform={handleOpenPlugnote}
                                                                handleDelete={handleDeletePlugnote}
                                                                handleFavorite={handleFavorite}
                                                                handleAccept={handleAccept}
                                                                handleReject={handleReject}
                                                            />
                                                        </div>
                                                    </div>)
                                                }
                                                else if(item.visuel === "PF") {
                                                    return(
                                                    <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn} ${pcode !== null && item.refcode === pcode.refcode && classes.newPlugcodeCtn}`}>
                                                        <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                            <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                <div
                                                                    className="cursor"
                                                                    onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugform(item)}}}
                                                                    >
                                                                    {typeof item.owner !== "undefined" &&
                                                                    <div className={` ${classes.plugcodeLbl}`}>
                                                                        {typeof item.model !== 'undefined' &&
                                                                        <span className={classes.plugcodeLbl} style={{color: colors.bloodOrange}}>
                                                                            {item.model}
                                                                        </span>}
                                                                    </div>}
                                                                </div>
                                                                <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugform(item)}}}>
                                                                    <ActionButton
                                                                        item={item}
                                                                        isNew={true}
                                                                        handleOpenPlugform={handleOpenPlugform}
                                                                        handleDelete={handleDeletePlugnote}
                                                                        handleFavorite={handleFavorite}
                                                                        handleAccept={handleAccept}
                                                                        handleReject={handleReject}
                                                                        />
                                                                </span>
                                                            </div>

                                                            <SubPlugcodePortalComponent
                                                                item={item}
                                                                handleOpenPlugform={handleOpenPlugform}
                                                                handleDelete={handleDeletePlugform}
                                                                handleFavorite={handleFavorite}
                                                                handleAccept={handleAccept}
                                                                handleReject={handleReject}
                                                            />
                                                        </div>
                                                    </div>)
                                                }
                                                else return null;
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>)})
                            }
                        </div>
                    </TabPanel>}

                    {/* PF */}
                    <TabPanel value={value} index={1}>
                        <div className={classes.mainCtn}>
                            {(favorites? favPf : pfGalleries)
                                .sort((a, b) => a.email.localeCompare(b.email))
                                .map((object, indexObject) => {
                                let email = object.email.split('@')[0]
                                return (
                                <Accordion
                                    defaultExpanded={false}
                                    expanded={expanded === object.id}
                                    key={'pfGalleries-' + indexObject}
                                    TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary
                                        className={classes.accordionCtn}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        onClick={(e) => {handleOnExpand(e, object.id)}}
                                    >
                                        <div className={classes.userInfoCtn}>
                                            <Typography className={classes.userInfo}>{email} ({object.list.length})</Typography>
                                            <Typography className={classes.userCompany}>{object.company}</Typography>
                                        </div>
                                        <span id="count-pf-ctn"
                                            style={headerW < 590 || window.innerWidth < 950 ? styles.pfCtnNarrow : styles.pfCtn}
                                            >
                                            <span
                                                className={classes.activeBtn}
                                                style={styles.pfActive}
                                                >
                                                {object.list.length}
                                            </span>
                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                            className={classes.plugcodesCtn}
                                            onClick={(e) => { handleOnExpand(e, object.id)}}
                                            >
                                            {object.list
                                            .sort((a, b) => {return a.model.toLowerCase().localeCompare(b.model.toLowerCase())})
                                            .map((item, index) => {
                                                return (
                                                <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn} ${pcode !== null && pcode !== null && item.refcode === pcode.refcode && classes.newPlugcodeCtn}`}>
                                                    <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                        <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                            <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugform(item)}}}>
                                                                {typeof item.owner !== "undefined" &&
                                                                <div className={` ${classes.plugcodeLbl}`}>
                                                                    {typeof item.model !== 'undefined' &&
                                                                    <span className={classes.plugcodeLbl}  style={{color: colors.plugformBlue}}>
                                                                        {item.model}
                                                                    </span>}
                                                                </div>}
                                                            </div>
                                                            <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugform(item)}}}>
                                                                {item.refcode}
                                                                <ActionButton
                                                                    item={item}
                                                                    isNew={false}
                                                                    handleOpenPlugform={handleOpenPlugform}
                                                                    handleDelete={handleDeletePlugnote}
                                                                    handleFavorite={handleFavorite}
                                                                    handleAccept={handleAccept}
                                                                    handleReject={handleReject}
                                                                    />
                                                            </span>
                                                        </div>

                                                        {/* <div style={{height:'12px' , marginLeft : '10px' , marginRight : '10px'}}>
                                                            <div className="line lightgrey flex_style">
                                                            </div>
                                                        </div> */}

                                                        <SubPlugcodePortalComponent
                                                            item={item}
                                                            handleOpenPlugform={handleOpenPlugform}
                                                            handleDelete={handleDeletePlugform}
                                                            handleFavorite={handleFavorite}
                                                            handleAccept={handleAccept}
                                                            handleReject={handleReject}
                                                        />
                                                    </div>

                                                </div>)
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>)})
                            }
                        </div>
                    </TabPanel>

                    {/* PN */}
                    <TabPanel value={value} index={2}>
                        <div className={classes.mainCtn}>
                            {(favorites? favPn : pnGalleries)
                                .sort((a, b) => a.email.localeCompare(b.email))
                                .map((object, indexObject) => {
                                let email = object.email.split('@')[0]
                                return (
                                <Accordion
                                    defaultExpanded={false}
                                    expanded={expanded === object.id}
                                    key={'pnGalleries-' + indexObject}
                                    onClick={(e) => handleOnExpand(e, object.id)}
                                    TransitionProps={{ unmountOnExit: true }}
                                    >
                                    <AccordionSummary
                                        className={classes.accordionCtn}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        >
                                        <div className={classes.userInfoCtn}>
                                            <Typography className={classes.userInfo}>{email} ({object.list.length})</Typography>
                                            <Typography className={classes.userCompany}>{object.company}</Typography>
                                        </div>
                                        <span id="count-pn-ctn"
                                            style={headerW < 590 || window.innerWidth < 950 ? styles.pnCtnNarrow : styles.pnCtn}
                                            >
                                            <span
                                                className={classes.activeBtn}
                                                style={styles.pnActive}
                                                >
                                                {object.list.length}
                                            </span>
                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className={classes.plugcodesCtn} onClick={(e) => handleOnExpand(e, object.id)}>
                                            {object.list
                                                .sort((a, b) => {return a.model.toLowerCase().localeCompare(b.model.toLowerCase())})
                                                .map((item, index) => {
                                                return(
                                                <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn} ${pcode !== null && item.refcode === pcode.refcode && classes.newPlugcodeCtn}`}>
                                                    <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                        <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                            <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                {typeof item.owner !== "undefined" &&
                                                                <div className={`${classes.plugcodeLbl}`}>
                                                                    {typeof item.model !== 'undefined' &&
                                                                    <span className={classes.plugcodeLbl}>
                                                                        {item.model}
                                                                    </span>}
                                                                </div>}
                                                            </div>
                                                            <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                {item.refcode}
                                                                <ActionButton
                                                                    item={item}
                                                                    isNew={false}
                                                                    handleOpenPlugform={handleOpenPlugnote}
                                                                    handleDelete={handleDeletePlugnote}
                                                                    handleFavorite={handleFavorite}
                                                                    handleAccept={handleAccept}
                                                                    handleReject={handleReject}
                                                                    />
                                                            </span>
                                                        </div>

                                                        <SubPlugcodePortalComponent
                                                            item={item}
                                                            handleOpenPlugform={handleOpenPlugnote}
                                                            handleDelete={handleDeletePlugnote}
                                                            handleFavorite={handleFavorite}
                                                            handleAccept={handleAccept}
                                                            handleReject={handleReject}
                                                        />
                                                    </div>
                                                </div>)})
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>)})
                            }
                        </div>
                    </TabPanel>

                    {/* ALL */}
                    <TabPanel value={value} index={3}>
                        <div className={classes.mainCtn}>
                            {(favorites? allFav : groupGallery(mainGallery))
                                .sort((a, b) => a.email.localeCompare(b.email))
                                .map((object, indexObject) => {
                                let pfObj =  (favorites? favPf : pfGalleries ).find((entry, index) => object.id === entry.id)
                                let pnObj =  (favorites? favPn : pnGalleries ).find((entry, index) => object.id === entry.id)
                                let newObj = (pnPfAccepted).find((entry, index) => object.id === entry.id)

                                let pfNb = pfObj !== undefined ? pfObj.list : []
                                let pnNb = pnObj !== undefined ? pnObj.list : []
                                let newNb = newObj !== undefined ? newObj.list : []

                                let email = object.email? object.email.split('@')[0] : ""

                                return(
                                <Accordion
                                    defaultExpanded={false}
                                    expanded={expanded === object.id}
                                    key={'pnPfAccepted-' + indexObject}
                                    TransitionProps={{ unmountOnExit: true }}
                                    >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        onClick={(e) => {handleOnExpand(e, object.id)}}
                                        >
                                        <div className={classes.userInfoCtn}>
                                            <Typography className={classes.userInfo}>{email} ({object.list.length})</Typography>
                                            <Typography className={classes.userCompany}>{object.company}</Typography>
                                        </div>
                                        <span id="nb-new" style={{zIndex: newNb.length<1? -10:1, marginLeft: 'auto'}} className={`${classes.inlineBtn} ${classes.inlineBtnLeft}`}
                                            >
                                            <span
                                                className={`${filter !== null && filter === "new" ? classes.activeBtn : classes.inactiveBtn} ${classes.centeredBtn}`}
                                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleOnFilter("new", object.id)}}
                                                style={filter !== null && filter === "new" ? {backgroundColor: colors.bloodOrange} : {borderColor: colors.bloodOrange, color: colors.bloodOrange}}
                                                >
                                                {newNb.length}
                                            </span>
                                        </span>
                                        <span id="nb-pf"
                                            className={`${classes.inlineBtn}`}
                                            style={{zIndex: pfNb.length<1? -10: 1}}
                                            >
                                            <span
                                                className={`${filter !== null && filter === "PF" ? classes.activeBtn : classes.inactiveBtn} ${classes.centeredBtn}`}
                                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleOnFilter("PF", object.id)}}
                                                style={filter !== null && filter === "PF" ? {backgroundColor: colors.plugformBlue} : {borderColor: colors.plugformBlue, color: colors.plugformBlue}}

                                                >
                                                {pfNb.length}
                                            </span>
                                        </span>
                                        <span id="nb-pn"
                                            style={pnNb.length < 1? {zIndex: -10} : null}
                                            className={`${classes.inlineBtn}`}
                                            >
                                            <span
                                                className={`${filter !== null && filter === "PN" ? classes.activeBtn : classes.inactiveBtn} ${classes.centeredBtn}`}
                                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleOnFilter("PN", object.id)}}
                                                style={filter !== null && filter === "PN" ? {backgroundColor: colors.unselectedMain} : {borderColor: colors.unselectedMain, color: colors.unselectedMain}}
                                                >
                                                {pnNb.length}
                                            </span>
                                        </span>
                                        <span id="nb-all"
                                            style={object.list.length < 1? {zIndex: -10} : null}
                                            className={`${classes.inlineBtnRight} ${classes.inlineBtn} ${filter !== null && filter === null && classes.activeBtn}`}
                                            >
                                            <span
                                                className={`${filter === null? classes.activeBtn : classes.inactiveBtn} ${classes.centeredBtn}`}
                                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleOnFilter(null, object.id)}}
                                                >
                                                {object.list.length}
                                            </span>
                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                            className={classes.plugcodesCtn}
                                            onClick={(e) => {handleOnExpand(e, object.id)}}
                                            >
                                            {object.list
                                                .sort((a, b) => {return a.model.toLowerCase().localeCompare(b.model.toLowerCase())})
                                                .sort((a, b) => {
                                                    // tri des plugcodes par catégorie
                                                    const equi = (item) => {
                                                        if(item.isNew) return 2;
                                                        else if(item.refcode.startsWith('PF-')) return 1;
                                                        else return 0
                                                    }
                                                    return equi(b) - equi(a)
                                                })
                                                .filter((object, index) => {
                                                    if(filter === null) return true;
                                                    else switch(filter) {
                                                        case "new":
                                                            return object.isNew
                                                        case "PN":
                                                            return (object.refcode.startsWith("PN-") || object.refcode.startsWith("PW-") || object.refcode.startsWith("PP-")) && !object.isNew
                                                        default:
                                                            return  object.refcode.startsWith("PF-") && !object.isNew
                                                    }
                                                })
                                                .map((item, index) => {
                                                    let itemColor = item.isNew? colors.bloodOrange : item.refcode.startsWith("PF")? colors.plugformBlue : item.refcode.startsWith("PW")? colors.darkSilver : colors.unselectedMain
                                                    if(item.visuel === "PN" || item.visuel === 'PP'){
                                                        return (
                                                        <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn} ${pcode !== null && item.refcode === pcode.refcode && classes.newPlugcodeCtn}`}>
                                                            <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                                <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                    <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                        {typeof item.owner !== "undefined" &&
                                                                        <div className={` ${classes.plugcodeLbl}`}>
                                                                            {typeof item.model !== 'undefined' &&
                                                                            <span className={classes.plugcodeLbl} style={{color: itemColor}}>
                                                                                {item.model}
                                                                            </span>}
                                                                        </div>}
                                                                    </div>
                                                                    <span
                                                                        className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`}
                                                                        onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}
                                                                        style={{color: colors.unselectedMain}}
                                                                        >
                                                                        {!item.isNew && item.refcode}
                                                                        <ActionButton
                                                                            isNew={item.isNew}
                                                                            item={item}
                                                                            handleOpenPlugform={handleOpenPlugnote}
                                                                            handleDelete={handleDeletePlugnote}
                                                                            handleFavorite={handleFavorite}
                                                                            handleAccept={handleAccept}
                                                                            handleReject={handleReject}
                                                                            />
                                                                    </span>
                                                                </div>

                                                                <SubPlugcodePortalComponent
                                                                    item={item}
                                                                    handleOpenPlugform={handleOpenPlugnote}
                                                                    handleDelete={handleDeletePlugnote}
                                                                    handleFavorite={handleFavorite}
                                                                    handleAccept={handleAccept}
                                                                    handleReject={handleReject}
                                                                />
                                                            </div>
                                                        </div>)
                                                    }
                                                    else if(item.visuel === "PF") {
                                                        return(
                                                        <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn} ${pcode !== null && item.refcode === pcode.refcode && classes.newPlugcodeCtn}`}>
                                                            <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                                <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                    <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugform(item)}}}>
                                                                        {typeof item.owner !== "undefined" &&
                                                                        <span className="references green">
                                                                            {typeof item.model !== 'undefined' &&
                                                                            <div className={` ${classes.plugcodeLbl}`}>
                                                                                {typeof item.model !== 'undefined' &&
                                                                                <span className={classes.plugcodeLbl} style={{color: itemColor}}>
                                                                                    {item.model}
                                                                                </span>}
                                                                            </div>}

                                                                        </span>
                                                                        }
                                                                    </div>
                                                                    <span
                                                                        className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`}
                                                                        onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugform(item)}}}
                                                                        >
                                                                        {!item.isNew && item.refcode}
                                                                        <ActionButton
                                                                            isNew={item.isNew}
                                                                            item={item}
                                                                            handleOpenPlugform={handleOpenPlugform}
                                                                            handleDelete={handleDeletePlugnote}
                                                                            handleFavorite={handleFavorite}
                                                                            handleAccept={handleAccept}
                                                                            handleReject={handleReject}
                                                                            />
                                                                    </span>
                                                                </div>

                                                                <SubPlugcodePortalComponent
                                                                    item={item}
                                                                    handleOpenPlugform={handleOpenPlugform}
                                                                    handleDelete={handleDeletePlugform}
                                                                    handleFavorite={handleFavorite}
                                                                    handleAccept={handleAccept}
                                                                    handleReject={handleReject}
                                                                />
                                                            </div>
                                                        </div>)
                                                    }
                                                    else {
                                                    return (
                                                    <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn} ${pcode !== null && item.refcode === pcode.refcode && classes.newPlugcodeCtn}`}>
                                                        <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                            <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugcode(item)}}}>
                                                                    {typeof item.owner !== "undefined" &&
                                                                    <div className={`${classes.plugcodeLbl}`}>
                                                                        {typeof item.model !== 'undefined' &&
                                                                        <span className={classes.plugcodeLbl} style={{color: itemColor}}>{item.model}
                                                                            {/* <span className="references_10 grey">{t('common:global.by')} </span> */}
                                                                        </span>}
                                                                        {/* <span className="references_10 grey">{item.owner.lastname} {item.owner.firstname}</span> */}
                                                                    </div>}
                                                                </div>
                                                                <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugcode(item)}}}>
                                                                    {!item.isNew && item.refcode}
                                                                    <ActionButton
                                                                        isNew={item.isNew}
                                                                        item={item}
                                                                        handleOpenPlugform={handleOpenPlugcode}
                                                                        handleDelete={handleDeletePlugnote}
                                                                        handleFavorite={handleFavorite}
                                                                        handleAccept={handleAccept}
                                                                        handleReject={handleReject}
                                                                        />
                                                                </span>
                                                            </div>

                                                            <SubPlugcodePortalComponent
                                                                item={item}
                                                                handleOpenPlugform={handleOpenPlugcode}
                                                                handleDelete={handleDeletePlugnote}
                                                                handleFavorite={handleFavorite}
                                                                handleAccept={handleAccept}
                                                                handleReject={handleReject}
                                                            />
                                                        </div>
                                                    </div>)}
                                                })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>)})
                            }
                        </div>
                    </TabPanel>
                    {/*
                    <TabPanel value={value} index={4}>
                        <div className={classes.mainCtn}>
                            {pnPfCodeError.map((object, indexObject) => {
                                return(
                                <Accordion key={'pnPfCodeError-' + indexObject} defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <div className={classes.userInfoCtn}>
                                            <Typography className={classes.userInfo}>{email} ({object.list.length})</Typography>
                                            <Typography className={classes.userCompany}>{object.company}</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className={classes.plugcodesCtn}>
                                            {object.list
                                                .sort((a, b) => {return a.model.toLowerCase().localeCompare(b.model.toLowerCase())})
                                                .map((item, index) => {
                                                if(item.visuel === "PN" || item.visuel === 'PP'){
                                                    return (
                                                    <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn}`}>
                                                        <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                            <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                    {typeof item.owner !== "undefined" &&
                                                                    <div className={`${classes.plugcodeLbl}`}>
                                                                        {typeof item.model !== 'undefined' &&
                                                                        <span className={classes.plugcodeLbl}>{item.model}
                                                                        <span className="references_10 grey">{t('common:global.by')}</span>
                                                                        </span>}
                                                                        <span className="references_10 grey">{item.owner.lastname} {item.owner.firstname} </span>
                                                                    </div>}
                                                                </div>
                                                                <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                    {item.refcode}
                                                                    <ActionButton
                                                                        item={item}
                                                                        handleOpenPlugform={handleOpenPlugnote}
                                                                        handleDelete={handleDeletePlugnote}
                                                                        handleFavorite={handleFavorite}
                                                                        handleAccept={handleAccept}
                                                                        handleReject={handleReject}
                                                                        />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {/* <div style={{height:'12px' , marginLeft : '10px' , marginRight : '10px'}}>
                                                            <div className="line lightgrey flex_style">
                                                            </div>
                                                        </div>

                                                        <SubPlugcodePortalComponent
                                                            item={item}
                                                            handleOpenPlugform={handleOpenPlugnote}
                                                            handleDelete={handleDeletePlugnote}
                                                            handleFavorite={handleFavorite}
                                                            handleAccept={handleAccept}
                                                            handleReject={handleReject}
                                                        />
                                                    </div>
                                                    )
                                                }
                                                else if(item.visuel === "PF") {
                                                    return(
                                                    <div key={index} className={`champs_apercu flexbox-item-plugcode ${classes.plugcodeCtn}`}>
                                                        <div className="flexbox-item-plugcode_texte_without_margin Plugcode">
                                                            <div>
                                                                <div className={`infos-supplementaires-without-margin grey ${classes.plugcodeHeader}`}>
                                                                    <div className="cursor" onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                        {typeof item.owner !== "undefined" &&
                                                                        <span className="references green">
                                                                            {typeof item.model !== 'undefined' &&
                                                                            <span className={"references green"}>
                                                                            {/* <span className="references_10 grey">{t('common:global.by')}</span>
                                                                            </span>} 
                                                                            {/* <span className="references_10 grey">{item.owner.lastname} {item.owner.firstname} </span>
                                                                        </span>}
                                                                    </div>
                                                                    <span className={`cursor references ${item.error ? 'red' : ''} ${classes.plugcodeCode}`} onClick={(event) => {if(!item.error) {event.preventDefault(); event.stopPropagation();  handleOpenPlugnote(item)}}}>
                                                                        {item.refcode}
                                                                        <ActionButton
                                                                            item={item}
                                                                            handleOpenPlugform={handleOpenPlugform}
                                                                            handleDelete={handleDeletePlugnote}
                                                                            handleFavorite={handleFavorite}
                                                                            handleAccept={handleAccept}
                                                                            handleReject={handleReject}
                                                                            />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            {/* <div style={{height:'12px' , marginLeft : '10px' , marginRight : '10px'}}>
                                                                <div className="line lightgrey flex_style">
                                                                </div>
                                                            </div>

                                                            <SubPlugcodePortalComponent
                                                                item={item}
                                                                handleOpenPlugform={handleOpenPlugform}
                                                                handleDelete={handleDeletePlugform}
                                                                handleFavorite={handleFavorite}
                                                                handleAccept={handleAccept}
                                                                handleReject={handleReject}
                                                            />
                                                        </div>
                                                    </div>)
                                                }
                                                else return null;
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>)})
                            }
                        </div>
                        <div className="flexbox-container-hidden"/>

                    </TabPanel> */}
                </Content>
            </main>
        </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    const listEnvironment = state?.user?.profil?.filter((value, index) => {
        if(!value.modelsshared){
            return value;
        }
    }) || [];

    const sortedListEnvironnement = listEnvironment.sort((left, right) => {
        if(left.profilid === ''){
            return -1
        }else{
            return left.profilid < right.profilid ? -1 : 1;
        }
    });
    const environment = getCurrentEnvironnement();

    const galleriesPN = state.gallery.list.length > 0 ?
        state.gallery.list.filter((item,index) => {
            //&& item.accepted  === true
            if(!item.refcode.startsWith('PF') && item.accepted  === true && item.error === false){
                item.isNew  = false;
                return {...item};
            }
        }).sort(
            function(a, b) {
                if(a.favorite === true){
                    return -1;
                }else return 1;
            }
        ) : [];

    const galleriesPF = state.gallery.list.length > 0 ?
        state.gallery.list.filter((item,index) => {
            if(item.refcode.startsWith('PF') && item.accepted  === true && item.error === false){
                item.isNew  = false;
                return {...item};
            }
        }).sort(
            function(a, b) {
                if(a.favorite === true){
                    return -1;
                }else return 1;
            }
        ) : [];

    const galleriesPNPFFavorites = state.gallery.list.length > 0 ?
        state.gallery.list.filter((item,index) => {
            if(item.refcode.startsWith('PF') && item.favorite && item.error === false){
                item.visuel  = 'PF';
                item.isNew  = false;
                return {...item};
            }
            else if(item.refcode.startsWith('PN') && item.favorite && item.error === false){
                item.visuel  = 'PN';
                item.isNew  = false;
                return {...item};
            }
            else if(item.refcode.startsWith('PW') && item.favorite && item.error === false){
                item.visuel  = 'PN';
                item.isNew  = false;
                return {...item};
            }
            else if(item.favorite && item.error === false) {
                item.visuel  = 'PN';
                item.isNew  = false;
                return {...item};
            }
        }) : [];

    const galleriesPNPFNew = state.gallery.list.length > 0 ?
        state.gallery.list.filter((item,index) => {
            if(item.refcode.startsWith('PF') && item.accepted  === false){
                item.visuel  = 'PF';
                item.isNew  = true;
                return {...item};
            }
            else if(((item.refcode.startsWith('PN') && item.accepted  === false) || (item.refcode.startsWith('PP') && item.accepted  === false) || (item.refcode.startsWith('PW') && item.accepted  === false))){
                item.isNew  = true;
                item.visuel  = 'PN';
                return {...item};
            }
        }) : [];

    const galleriesPNPFError = state.gallery.list.length > 0 ?
        state.gallery.list.filter((item,index) => {
            if(item.refcode.startsWith('PF') &&  item.error  === true){
                item.isNew  = false;
                item.visuel  = 'PF';
                return {...item};
            }else if(!item.refcode.startsWith('PF') &&  item.error  === true){
                item.isNew  = false;
                item.visuel  = 'PN';
                return {...item};
            }
        }) : [];

    return {
        sortedListEnvironnement : sortedListEnvironnement,
        listEnvironment : listEnvironment,
        user : state.user,
        token : state.user.token,
        galleriesPN,
        galleriesPF,
        galleriesPNPFFavorites,
        galleriesPNPFNew,
        galleriesPNPFError,
        saving: state?.gallery?.saving || false,
        uh : environment.userhash,
        umh : state.user.profil.hash,
        modelUniqueRefresh : state.model.modelUniqueRefresh || null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptClassifyInItsPlugform: (plugnote, datahash, userhash) => dispatch(postClassifyInItsPlugformRequest(plugnote, datahash, userhash)),
        attemptCreateGalleryRequest: (gallery, userhash, redirect) => dispatch(postPlugcodeGalleryRequest(gallery, userhash, redirect)),
        attemptDeleteGalleryRequest: (galleryhash,userhash) => dispatch(delPlugcodeGalleryRequest(galleryhash,userhash)),
        attemptGetGalleries: (userhash) => dispatch(getPlugcodeGalleryRequest(userhash)),
        attemptGetPlugnoteViaCodeRequest: (plugcode, userhash, exist) => dispatch(getPlugnoteViaCodeRequest(plugcode, userhash, exist)),
        attemptGetPlugformViaCodeRequest: (plugcode, userhash, exist) => dispatch(getPlugformViaCodeRequest(plugcode, userhash, exist)),
        attemptResetSavingGalleryPlugcode: () => dispatch(savingGalleryPlugcodeReset()),
        attemptUpdateGalleryRequest: (gallery, userhash) => dispatch(putPlugcodeGalleryRequest(gallery, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlugcodeList)
