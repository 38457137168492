import React from "react";
import PropTypes from "prop-types"
import { View, StyleSheet } from "@react-pdf/renderer";
import PDFRow from "./PDFRow";
import PDFHeaderRow from "./PDFHeaderRow";
import i18n from "i18next";
import { isDefined } from "../../../utils/utils";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "column",
        flexWrap: "nowrap",
        // minHeight: 62,
    },
    noData: {
        fontFamily: 'Poppins',
        fontSize: 10,
        fontWeight: 'light',
        fontStyle: 'italic',
        padding: 4,
    },
});

const PDFTablemulti = (props) => {
    // console.log('PDFTablemulti got props', props)
    let noDataMsg = i18n.t('common:global.no-data')

    let align = isDefined(props.setup?.layout?.alignment) ? props.setup.layout.alignment : 'full'
    let tableWidth = 20;
    let flex = false
    let flexWidths = []
    if (align !== 'full' && props.header.every(col => col.colWidth !== 0)) {
        // console.log('No 0-width', )
        props.header.forEach(col => {
            if (col.showOnPdf !== false || !isDefined(col.showOnPdf) || props.hiddenOpt?.pdf) {
                let colWidth = col.colWidth * 10;
                flexWidths.push(colWidth)
                tableWidth += colWidth
            } else { flexWidths.push(0) }
        });
        if (tableWidth >= 514) {
            flexWidths = flexWidths.map(entry => entry / tableWidth)
            tableWidth = 514
            flex = true
        }
    } else if (props.header.some(col => col.colWidth === 0)) {
        // console.log('Some 0-width', )
        tableWidth = 514
        props.header.forEach(col => {
            if (col.showOnPdf !== false || !isDefined(col.showOnPdf) || props.hiddenOpt?.pdf) {
                flexWidths.push(1)
            } else {flexWidths.push(0)}
        })
    } else { 
        tableWidth = 514
        props.header.forEach(col => {
            if (col.showOnPdf !== false || !isDefined(col.showOnPdf) || props.hiddenOpt?.pdf) {
                flexWidths.push(1)
            } else {flexWidths.push(0)}
        })
    }
    if (tableWidth === 514) { flex = true }
    
    const widthStyle = (align === 'full') ? { flexGrow: 1, width: 'auto', minWidth: 200 } : { maxWidth: tableWidth, width: tableWidth, minWidth: 120 }
    if (align === 'right') { widthStyle.marginLeft = '10px'; } else if (align === 'center') { widthStyle.marginLeft = 'auto';  widthStyle.marginRight = 'auto'; }

    return (
        <View style={[styles.tableContainer, widthStyle]}
            wrap={props.rows?.length > 0 ? true : false}
            // debug
        >
            {
                props.header.length > 0 &&
                <PDFHeaderRow
                    borderColor={props.borderColor}
                    flex={flex}
                    hiddenOpt={props.hiddenOpt}
                    items={props.header}
                    key={"pdf-table-header-" + props.fieldId + '-' + props.fieldLabel}
                    style={props.headerStyle}
                    widths={flexWidths}
                    widthStyle={widthStyle}
                />
            }
            {
                props.rows?.length > 0 ?
                    props.rows
                        .map((row, rindex) => {
                            let rowNb = rindex + 1
                            return (
                                <PDFRow
                                    borderColor={props.borderColor}
                                    flex={flex}
                                    header={props.header}
                                    hiddenOpt={props.hiddenOpt}
                                    row={row}
                                    key={"pdf-table-row-" + props.fieldId + '-row-' + rowNb}
                                    rowNb={rindex}
                                    widths={flexWidths}
                                    widthStyle={widthStyle}
                                />
                            );
                        })
                    :
                    <PDFRow
                        borderColor={props.borderColor}
                        isEmpty={true}
                        header={props.header}
                        id={"pdf-empty-table"}
                        row={[noDataMsg]}
                        rowNb={0}
                        textStyle={styles.noData}
                        widths={flexWidths}
                    />
            }
        </View>
    );
}


PDFTablemulti.propTypes = {
    borderColor: PropTypes.string,
    header: PropTypes.array,
    rows: PropTypes.array.isRequired,
    widths: PropTypes.array,
}

export default PDFTablemulti;