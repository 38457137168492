import { BsLink45Deg, BsUiChecksGrid } from "react-icons/bs"
import { IconContext } from "react-icons"
import { FaAsterisk, FaInfoCircle } from "react-icons/fa"
import { GiResize } from "react-icons/gi"
import { MdEdit, MdSettings, MdVisibilityOff } from "react-icons/md"
import { RiAddFill, RiEdit2Fill } from "react-icons/ri"
import { RxInput } from "react-icons/rx"
import { TbFileText } from "react-icons/tb"
import { TfiLayoutListThumbAlt } from "react-icons/tfi"
import colors from "../../../assets/themes/Colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles } from "@material-ui/core/styles"
import { faSlash } from "@fortawesome/free-solid-svg-icons"
import { isDefined } from "../../../utils/utils"

const mainIcon = {
  position: 'relative'
}
const useStyles = makeStyles(() => ({
  fontStyle: {
      fontSize: 12,
      fontWeight: 'bold',
  },
  fontError: {
      fontSize: 12,
      color: colors.red,
      fontWeight: 'bold',
      paddingLeft: '5px!important',
      width: '-webkit-fill-available',
      display: 'flex',
      justifyContent: 'flex-end',
  },
  delButton: {
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.main,
      position: 'absolute',
      right: 0
  },
  indicatorCtn: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      gap: 4,
      height: 15,
      cursor: 'default',
      justifyContent: 'center',
  },
  indicator: {
      position: 'relative',
      fontSize: 8,
      width: 10,
      height: 10,
  },
  indicatorFalse: {
      fontWeight: "bolder",
      color: colors.bloodOrange,
      fontSize: 7,
      position: 'absolute',
      top: -2,
      left: 0,
      right: 0,
  },
  slashIcon: {
      fontSize: 8,
      color: colors.bloodOrange,
      position: 'absolute',
      top: 6,
      left: 0,
      right: 0,
  },
  indicatorBtn: {
    fontSize: 12,
    color: colors.main,
    position: 'relative',
    fontWeight: 'bold',
    cursor: 'pointer',
  }
}))
const secondIcon = (size) => {
  return {
    position: 'absolute',
    right: -10,
    bottom: size / (-2),
    transform: 'scale(0.7)'
  }
}
export const BackupTable = () => {
  return (
    <span className="material-icons" >
      backup_table
    </span>)
}

export const InputIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <RxInput />
    </IconContext.Provider>
  )
}
export const AddInfoIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <FaInfoCircle style={isDefined(props.style) ? props.style : undefined}/>
        <div style={secondIcon(props.size || 24)}>
          <RiAddFill color={isDefined(props.secondaryColor) ? props.secondaryColor : props.color || "red" }/>
        </div>
      </div>
    </IconContext.Provider>
  )
}
export const EditInfoIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <FaInfoCircle style={isDefined(props.style) ? props.style : undefined}/>
        <div style={secondIcon(props.size || 24)}>
          <MdEdit color={isDefined(props.secondaryColor) ? props.secondaryColor : props.color || "red" } />
        </div>
      </div>
    </IconContext.Provider>
  )
}
export const InfoIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <FaInfoCircle style={isDefined(props.style) ? props.style : undefined} />
    </IconContext.Provider>
  )
}
export const EditValueIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <RiEdit2Fill style={isDefined(props.style) ? props.style : undefined} />
    </IconContext.Provider>
  )
}

export const SettingsIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <MdSettings />
    </IconContext.Provider>
  )
}

export const GallerySelect = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <BsUiChecksGrid />
    </IconContext.Provider>
  )
}

export const Asterisk = (props) => {
  return (
    <FaAsterisk
      color={isDefined(props.color) ? props.color : colors.main}
      size={isDefined(props.size) ? props.size : 24}
      stroke={isDefined(props.color) ? props.color : colors.main}
      strokeWidth={8}
    />
  )
}
export const AsteriskOutline = (props) => {
  return (
    <FaAsterisk
      stroke={isDefined(props.color) ? props.color : colors.main}
      strokeWidth={8}
      size={isDefined(props.size) ? props.size : 24} color="white"/>
  )
}

export const AttLayout = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <GiResize />
    </IconContext.Provider>
  )
}

export const FieldVisibility = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <TfiLayoutListThumbAlt />
        <div style={secondIcon(props.size || 24)}>
          <MdVisibilityOff color="red"/>
        </div>
      </div>
    </IconContext.Provider>
  )
}

export const RelatedPlugnote = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <TbFileText />
        <div style={secondIcon(props.size || 24)}>
          <BsLink45Deg color="red"/>
        </div>
      </div>
    </IconContext.Provider>
  )
}

const indicatorStyle = (props) => {
  // console.log('style props', props);
  return {
    fontSize: isDefined(props.fontSize) ? `${props.fontSize}px` : '7px',
    position: props.relative ? "relative" : "absolute",
    top: props.relative ? "auto" : "-2px",
  }
}

const slashStyle = (props) => {
  return {
    top: props.relative ? "-2px" : 6,
    fontSize: isDefined(props.fontSize) ? `${props.fontSize}px` : '7px',
  }
}

export const InvisiblePDF = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicator} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle(props)} />
      }
      <span className={classes.indicatorFalse} style={{ ...indicatorStyle(props) }}>PDF </span>
    </span>
  )
}
export const VisibilityIndicator = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicatorBtn} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={{ fontSize: 12, top: 0, left: 3 }} />
      }
      <span className={classes.indicatorBtn} style={ !props.visible ? { color: colors.bloodOrange } : undefined }>{props.text}</span>
    </span>
  )
}

export const InvisiblePF = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicator} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle(props)} />
      }
      <span className={classes.indicatorFalse} style={indicatorStyle(props)}>PF- </span>
    </span>
  )
}

export const InvisiblePN = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicator} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle(props)} />
      }
      <span className={classes.indicatorFalse} style={indicatorStyle(props)}>PN- </span>
    </span>
  )
}

export const LockedIcon = (props) => {
  const lockStyle = {
    color: props.locked ? props.color || colors.main : colors.coal,
  }
  return (
    <span className="material-icons" style={lockStyle}>
      {
        props.locked ? 'lock' : 'lock_open'
      }
    </span>
  )
}
