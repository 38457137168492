import React, {useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import ModalFormatPluglinkConstructorComponent from "./modal/ModalFormatPluglinkConstructorComponent";
import {getCurrentEnvironnement} from "../../utils/Common";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        display: 'flex',
    },
    mainInputFieldTools:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom : '20px',
        cursor : "pointer"
    }
}));


const FormatPluglinkConstructorComponent = (props) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [newDataClef, setDataClef] = useState(typeof props.field.options.code !== "undefined" && props.field.options.code !== ""  ? props.field.options.code  : "");
    const [codePf, setCodePf] = useState(typeof props.field.options.pfcode !== "undefined" && props.field.options.pfcode !== ""  ? props.field.options.pfcode  : "");
    const [newDataField, setDataField] = useState(typeof props.field.options.fields !== "undefined" && props.field.options.fields !== ""  ? JSON.stringify(props.field.options.fields)  : "");
    const [newDataFieldCount, setDataFieldCount] = useState(typeof props.field.options.fields !== "undefined" && props.field.options.fields !== ""  ? props.field.options.fields.length.toString()  : "0");

    const [openModal, setOpenModal] = useState(false);

    const environment = getCurrentEnvironnement();


    const handleDataCode = (code) => {

        props.field.options.code = code;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.code = code;

            props.setNewModel(model);
        }else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.code = code;
            props.setUpdateModel(model);
        }
        setDataClef(code);
    }

    const handleDataPfCode = (code) => {
        props.field.options.pfcode = code;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.pfcode = code;

            props.setNewModel(model);
        }else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.pfcode = code;
            props.setUpdateModel(model);
        }

        setCodePf(code)
    }

    const handleDataFields = (fields) => {

        setDataField(...fields);

        props.field.options.fields = fields;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.fields = fields;

            props.setNewModel(model);
        }else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.fields = fields;
            props.setUpdateModel(model);
        }

        setDataField(JSON.stringify(fields));
        setDataFieldCount(fields.length);
    }

    const handleConfigurator = (field) => {
        handleModalOpen();
    }

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const handleModalOpen = () => {
        setOpenModal(true);
    }

    return (
        <div className={classes.mainField}>
            <div className={classes.field}>
                <Input
                    id="standard-full-width-plugform"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth={true}
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div>
            <div className={classes.inputType}>
                <div className={classes.mainInputFieldTools} onClick={(event) => handleConfigurator(props.field)}>
                    <Tooltip  title={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-configurator')} aria-label="add" placement="top-start">
                        <span id="icon-more" className={`material-icons md-30`}>
                            settings
                        </span>
                    </Tooltip>
                    <span>{t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-configurator')}</span>
                </div>
                <div className={classes.mainInputField}>

                    { newDataClef !== "" && codePf !== "" &&
                        <Input
                            id="standard-full-width-clef"
                            label="Label"
                            placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-code')}
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={
                                {
                                    fieldkey: 'pluglinkcode-'+props.field.id,
                                    className: classes.inputField,
                                    maxLength: 40
                                }
                            }
                            disabled={true}
                            className="text-line"
                            value={newDataClef  || ''}
                        />

                    }

                    {newDataClef !== "" && codePf !== "" &&
                        <Input
                            id="standard-full-width-clef"
                            label="Label"
                            placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-code')}
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={
                                {
                                    fieldkey: 'pluglinkcode-'+props.field.id,
                                    className: classes.inputField,
                                    maxLength: 40
                                }
                            }
                            disabled={true}
                            className="text-line"
                            value={codePf  || ''}
                        />
                    }

                    {newDataClef !== "" && codePf !== "" &&
                    <Input
                        id="standard-full-width-field"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-fields')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: 'pluglinkfields-'+props.field.id,
                                className: classes.inputField,
                                maxLength: 40
                            }
                        }
                        disabled={true}
                        className="text-line"
                        value={newDataFieldCount + " " + t('common:fields.format-choice-pluglink')  || '0' + " " + t('common:fields.format-choice-pluglink') }
                    />
                    }
                </div>
            </div>
            {
                openModal &&
                <ModalFormatPluglinkConstructorComponent
                    folderName={props.folderName}
                    field={props.field}
                    open={openModal}
                    fields={props.update ? props.updateModel : props.newModel}
                    close={handleModalClose}
                    handleDataCode={handleDataCode}
                    handleDataPfCode={handleDataPfCode}
                    handleDataFields={handleDataFields}
                />
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPluglinkConstructorComponent);





