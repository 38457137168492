import axios from 'axios';
import ApplicationConfig from "../config/ApplicationConfig";
import {getToken} from "../utils/Common";

export const apiV2 = axios.create({
    baseURL: ApplicationConfig.apiv2Url
});

//Set default headers to common_axios ( as Instance )
if(getToken() !== null){
    apiV2.defaults.headers.common['X-AUTH-TOKEN'] = getToken();
}

apiV2.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    config.headers.common['X-AUTH-TOKEN'] =  token ? `${token}` : '';
    return config;
});

apiV2.interceptors.response.use(response => {
    if(typeof response.data !== 'undefined'){
        if(typeof response.data.message !== 'undefined'){
            if(response.data.message === "Invalid credentials"){
                //fetchForceLogoutUser();
            }
        }
    }

    return response;
});
