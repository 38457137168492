import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {postRegisterRequest} from '../../actions/user';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import logo from '../../assets/images/logos/plugnotes_logo_color.png';
import MuiPhoneNumber from 'material-ui-phone-number';
import {Checkbox, TextField} from '@material-ui/core';
import qs from "querystringify";
import {setLanguage} from "../../utils/Common";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fullWidth : {
      width : '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    margin_left_5 : {
        marginLeft : '5px',
        cursor : 'pointer',
    },
    accept:{
        display: 'flex',
        alignItems: 'center'
    }
}));

const styles = theme => ({
    field: {
        margin: '10px 0',
    },
    countryList: {
        ...theme.typography.body1,
    },
});

function Register(props) {
    const firstname = useFormInput('');
    const lastname = useFormInput('');
    const email = useFormInput('');
    const company = useFormInput('');
    const [telephone, setTelephone] = useState('');
    const [accept1, setAccept1] = useState(false);
    const [accept2, setAccept2] = useState(false);


    const [errorFirstname, setErrrFirstName] = React.useState(false);
    const [errorLastname, setErrorLastName] = React.useState(false);
    const [errorEmail, setErrorMail] = React.useState(false);
    const [errorCompany, setErrorCompany] = React.useState(false);
    const [errorTelephone, setErrorTelephone] = React.useState(false);
    const [errorAccept1, setErrorAccept1] = React.useState(false);
    const [errorAccept2, setErrorAccept2] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [send, setSend] = React.useState(false);
    const [control, setControl] = React.useState(false);

    const [lg, setLg] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.lg || 'fr');

    /**
     * Ajout d'une vérif pour le type 'email'
     */
    const isEmail = (value) => {
        return /(.)+@(.)+/.test(value)
    }

    const isPhoneNumber = (value) => {
        return true
    }

    // handle button click of login form
    const handleLogin = () => {
        //props.attemptRealLogin(username.value,  password.value);
        props.history.push('/');
    };

    const _handlePressLink = (link) => {
        window.open("https://plugnotes.com/conditions/","_blank");
/*        if (link === 'conditions') {
            window.open("http://api.plugnotes.com/assets/Conditions_d_utilisation.docx", "_blank");
        } else {
            window.open("http://api.plugnotes.com/assets/Politique_de_confidentialite.docx", "_blank");
        }*/
    }

    const handleControlAccount = () => {
        if(firstname.value.length === 0) setErrrFirstName(true); else setErrrFirstName(false);
        if(lastname.value.length === 0) setErrorLastName(true); else setErrorLastName(false);
        if(company.value.length === 0) setErrorCompany(true); else setErrorCompany(false);
        if(email.value.length === 0 || isEmail(email.value) === false) setErrorMail(true); else setErrorMail(false);
        if(telephone.length === 0 || !isPhoneNumber(telephone)) setErrorTelephone(true); else setErrorTelephone(false);
        if(accept1 === false) setErrorAccept1(true); else setErrorAccept1(false);
        if(accept2 === false) setErrorAccept2(true); else setErrorAccept2(false);

        setControl(true);
    }

    useEffect(() => {
        if(control){
            setControl(false);
            if(errorFirstname || errorLastname || errorEmail || errorTelephone || errorAccept1 || errorAccept2){
                setError(true);
                setSend(false);
            }else{
                setError(false);
                setSend(true);
            }
        }

    }, [control])

    useEffect(() => {


        if(!error && send){
            setSend(false);
            let payload =   {
                email : email.value,
                mobile : telephone.replace(/\s/g,''),
                username : email.value,
                firstname : firstname.value,
                lastname : lastname.value,
                company : company.value,
                language : i18n.language.substr(0, 2).toLowerCase() === 'fr' ? 'fr' : 'en',
                hubspotLanguage : i18n.language.toUpperCase(),
                accept: "1",
                accept_2: "1"
            }

            props.attemptRegister(payload)
        }

    }, [send])

    useEffect(() => {
        i18n.changeLanguage(lg.toLowerCase());
        setLanguage(lg.toLowerCase());
    }, [lg])

    const onChangePhoneInput = (value) => {
        setTelephone(value);
    }

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    return (
        <Grid>
            <div className="container_signin">
                <div className="logoSignin">
                    <img className="" src={logo} alt="Plugnotes" />
                </div>

                <div>

                    <div className="popup signin">
                        <div className="popup_wrapper flexSpacebetween">

                            <div className="popup_wrapper_content">
                                <div className="sous-header_content">
                                    <div>
                                        <span className="titres">
                                            {t('login.create-account')}
                                        </span>
                                    </div>
                                </div>
                                <div className="sous-header_content lightgrey">
                                    <div className="line flex_style">
                                    </div>
                                </div>
                            </div>

                            <div className="popup_wrapper_content">

                                <ul className="inputWrapper">
                                    <li>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="firstname"
                                            label={t('login.firstname')}
                                            type="text"
                                            id="firstname"
                                            {...firstname}
                                            autoComplete="off"
                                            error={errorFirstname}
                                            helperText={errorFirstname ? <span>{t('login.error-firstname-required')}</span> : ''}
                                        />
                                    </li>
                                    <li>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="lastname"
                                            label={t('login.lastname')}
                                            type="text"
                                            id="lastname"
                                            {...lastname}
                                            autoComplete="off"
                                            error={errorLastname}
                                            helperText={errorLastname ? <span>{t('login.error-lastname-required')}</span> : ''}
                                        />
                                    </li>
                                    <li>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="email"
                                            label={t('login.email')}
                                            type="text"
                                            id="email"
                                            {...email}
                                            autoComplete="off"
                                            error={errorEmail}
                                            helperText={errorEmail ? <span>{t('login.error-mail-required')}</span> : ''}
                                        />
                                    </li>
                                    <li>
                                        <MuiPhoneNumber
                                            defaultCountry={'be'}
                                            onChange={onChangePhoneInput}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="telephone"
                                            label={t('login.telephone')}
                                            type="text"
                                            id="telephone"
                                            error={errorTelephone}
                                            helperText={errorTelephone ? <span>{t('login.error-mobile-required')}</span> : ''}
                                        />
                                    </li>
                                    <li>
                                        <TextField
                                            variant="outlined"
                                            required={true}
                                            fullWidth
                                            name="company"
                                            label={t('login.company')}
                                            type="text"
                                            id="company"
                                            {...company}
                                            autoComplete="off"
                                            error={errorCompany}
                                            helperText={errorCompany ? <span>{t('login.error-company-required')}</span> : ''}
                                        />
                                    </li>
                                    <li className={classes.accept}>
                                        <Checkbox id="accept1" name="accept1" color="default" className="checkbox" type="checkbox" disabled={false} required={true} value={accept1} onClick={(event) => setAccept1(!accept1)} />
                                        <label className={`blue selected-leftmenu ${classes.margin_left_5}`} htmlFor="accept1" onClick={_handlePressLink.bind(this,"conditions")}>{t('login.accept_one')}</label>
                                    </li>
                                    {errorAccept1 ?
                                    <li className={classes.accept}>
                                         <div><p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"><span style={{color : 'red'}}>{t('login.error-accept-one')}</span></p></div>
                                    </li>
                                        : ''
                                    }
                                    <li className={classes.accept}>
                                        <Checkbox id="accept2" name="accept2" color="default" className="checkbox" type="checkbox" required={true} disabled={false}  value={accept2} onClick={(event) => setAccept2(!accept2)}/>
                                        <label className={`blue selected-leftmenu ${classes.margin_left_5}`} htmlFor="accept2" onClick={_handlePressLink.bind(this,"contrats")}>{t('login.accept_two')}</label>
                                    </li>
                                    {errorAccept2 ?
                                        <li className={classes.accept}>
                                            <div><p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"><span style={{color : 'red'}}>{t('login.error-accept-two')}</span></p></div>
                                        </li>
                                        : ''
                                    }
                                </ul>

                            </div>

                            <div className="popup_wrapper_content align-items-center">

                                <div className="confirmation_pop-up">
                                    <button className="button_signin"
                                            onClick={handleControlAccount}
                                            disabled={props.fetching}
                                    >{props.fetching ? t('global.loading') : t('login.create-my-account')}</button>
                                </div>
                                <div className="confirmation_pop-up greya" onClick={handleLogin}>
                                    <span className="cliquables grey">{t('login.already-account')}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </Grid>

    );

}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptRegister: (payload) => dispatch(postRegisterRequest(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Register)
