import * as moment from "moment";
import i18n from './../translations/i18n';
import ApplicationConfig from "../config/ApplicationConfig";
import { isDefined } from "./utils";

export const  getContentValueForField = (field) => {
    let fields
    if(field.value !== "" ){
        switch (field.format) {
            case 'extdata':
            case 'extdatan':
            case 'text':
            case 'agenda':
            case 'textarea':
            case 'date':
            case 'birthday':
            case 'phone':
            case 'sms':
            case 'time':
            case 'smail':
            case 'mail':
            case 'formula':
            case 'sign':
            case 'url':
            case 'qrcode':
            case 'now':
            case 'localis':
            case 'indice':
                return field.value;
            case 'password':
                return "******"
            case 'list':
            case 'single-select':
            case 'free-single-select':
            case 'multi-select':
            case 'free-multi-select':
                // console.log('field value - ', field.format, ': ', field.value);
                // if (field.value?.length > 0) return field.value.join(' , ');
                // else return ''
                return field.value.join(' , ');
            case 'single-table':
            case 'free-single-table':
            case 'multi-table':
            case 'free-multi-table':
                return field.value.map(item => item.text + ' : '+ item.value).join(' , ');
            // case 'tablemulti':
            //     fields = getTableMultiFieldsLabels(field)
            //     console.log('rendering... tablemulti fields', fields);
            //     if(Array.isArray(field.value)){
            //         return fields + '\n' + field.value.map(item => Object.values(item) + '\n').join('');
            //     }
            //     else {
            //         let tmp = JSON.parse(field.value);
            //         return fields + '\n' + tmp.map(item => Object.values(item) + '\n').join('');
            //     }
            // case 'tablemultin':
                // fields = getTableMultiFieldsLabels(field)
                // if(Array.isArray(field.value.list)){
                //     return fields + '\n' + field.value.value + '\n' + field.value.list.map(item => Object.values(item) + '\n').join('');
                // }
                // else{
                //     let tmpArray = JSON.parse(field.value.list);
                //     return fields + '\n' + field.value.value + '\n' + tmpArray.map(item => Object.values(item) + '\n').join('');
                // }
            case 'tablemulti':
            case 'tablemultin':
            case 'smailvalidmulti':
            case 'document':
                fields = getTableMultiFieldsLabels(field)
                let values = ''
                let _value = Object.prototype.toString.call(field.value) === '[object Array]'? field.value : field.value.list
                // console.log('_value', _value, '_fields', fields)
                if (isDefined(_value)) {
                    _value.map((item, row) => {
                        if (Object.values(item).length > 0) {
                            Object.values(item).map((val, id) => {
                                if (id === 0) values += '\n' + ` ${row + 1}) `
                                if (val !== '') {
                                    let _val
                                    if (typeof val === "object") _val = val.join(', ');
                                    else _val = val
                                    let nb = id + 1
                                    if (_val !== '') values += `<sup>(${nb})</sup> ` + _val + ' | '
                                }
                                else return ''
                            })
                        }
                    })
                }
                if (values !== '') return '\n' + fields + values;
                else return ''
            case 'boolean':
                return field.value == "1" ? "Y" : "N";
            case 'numeric':
                if(!isNaN(field.value)){
                    return field.value;
                }else return "";
            case 'plugform':
                return field?.value?.value
            case 'glocalis':
                try {
                    let tmp = JSON.parse(field?.value);
                    return '\nAdr: ' + tmp.address + '\nLat: ' + tmp.latitude + '\nLong: ' + tmp.longitude;
                }catch (e) {
                    return "";
                }
            case 'qrcodeint':
                try {
                    let value = field?.value;
                    let qrcodeintComment = typeof value.comment !==  "undefined" ? value.comment : "";
                    let qrcodeintCode = typeof value.code !==  "undefined" ? value.code : "";
                    if(qrcodeintComment != "" && qrcodeintCode != ""){
                        let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                        return '\n' + qrcodeintComment + '\n' + url;
                    }else if(qrcodeintComment != ""){
                        return '\n' + qrcodeintComment;
                    }else if( qrcodeintCode != ""){
                        let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                        return '\n' + url;
                    }else return "";

                }catch (e){
                    return "";
                }

            default : return  "";
        }
    }else return "";
};

const getTableMultiFieldsLabels = (fields) => {
    let modelFields = fields.options.lines === undefined ? fields.options : fields.options.lines
    if(modelFields !== null && modelFields !== undefined){
        let fieldStr = ''
        modelFields.map((value, index) => {
            fieldStr += (`<span><sup>(${index + 1})</sup> ${value.label} - </span>`)
        })
        return '&nbsp;&nbsp;&nbsp;&nbsp;' + fieldStr
        }
    else return null
}

export const strReplaceContentField = (content, fields, model, plugnote, user, pwCode) => {
    let _fields = fields.options?.lines !== undefined ? fields.options.lines : fields
    let fieldsCopy = [..._fields]
    let copy = content;
    copy = copy.replaceAll('***', '{{')
    copy = copy.replaceAll('**', '}}')

    if(fieldsCopy.length > 0 && fieldsCopy[0].hasOwnProperty('hash')){
        fieldsCopy = fieldsCopy.filter((value, index )=> index !== 0).filter(Boolean);
    }

    fieldsCopy.map((field, index) => {

        try {
            let fieldInsert = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}'
            let fieldId = "{{field" + field.id.toString()+"}}" // legacy version
            if(copy.includes(fieldId)) {
                copy = copy.replaceAll(fieldId, getContentValueForField(field))
            }
            if(copy.includes(fieldInsert)) {
                copy = copy.replaceAll(fieldInsert, getContentValueForField(field))
            }

            // let searchRegex = "{{field" + field.id.toString()+"}}"
            // let searchRegexLabelled = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}';

            // let regex = new RegExp( "{{field" + field.id.toString()+"}}",'g');
            // if(copy.search(searchRegex)){
            //     copy = copy.replaceAll(regex, getContentValueForField(field, model));
            // }

            //@gabriel l'erreur vient surement quand tu mets un titre + (ex : quelqueschose).
            // try {
            //     let regexLabelled = new RegExp(searchRegexLabelled,'g');
            //     if(copy.search(regexLabelled)){
            //         copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            //     }
            // }catch (e){
            // console.log(e);
            //     // copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            // }        let fieldInsert = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}'
            //         let fieldId = "{{field" + field.id.toString()+"}}" // legacy version
            //         if(copy.includes(fieldId)) {
            //             copy = copy.replaceAll(fieldId, getContentValueForField(field))
            //         }
            //         if(copy.includes(fieldInsert)) {
            //             copy = copy.replaceAll(fieldInsert, getContentValueForField(field))
            //         }
            //
            //         // let searchRegex = "{{field" + field.id.toString()+"}}"
            //         // let searchRegexLabelled = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}';
            //
            //         // let regex = new RegExp( "{{field" + field.id.toString()+"}}",'g');
            //         // if(copy.search(searchRegex)){
            //         //     copy = copy.replaceAll(regex, getContentValueForField(field, model));
            //         // }
            //
            //         //@gabriel l'erreur vient surement quand tu mets un titre + (ex : quelqueschose).
            //         // try {
            //         //     let regexLabelled = new RegExp(searchRegexLabelled,'g');
            //         //     if(copy.search(regexLabelled)){
            //         //         copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            //         //     }
            //         // }catch (e){
            //             // console.log(e);
            //         //     // copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            //         // }
        }
        catch (e) {
            //console.log(e);
        }

    });
    //! champs modifiés dans le setup après la composition du mail
    // remplacé selon le label ou selon le numéro du champs
    //Si les deux valeurs changent, impossible de garantir la correction
    if (copy.includes("__field")) {
        let inserts = []
        let copyArray = copy.split('{{')
        copyArray.forEach(entry => {
            let insert  = entry.split('}}')[0]
            let id = insert.split('__field')[1]
            let label = insert.split('__field')[0]
            if(id !== undefined) inserts.push({id: id, insert: insert, label: label})
        })
        // console.log('inserts', inserts)
        inserts.forEach((value) => {
            let field = fieldsCopy.find(insert => insert.title.includes(value.label))
            // console.log('original', field)
            if (field !== undefined) {
                copy = copy.replaceAll('{{' + value.insert + '}}', getContentValueForField(field))
            }
            else {
                let index = parseInt(value.id)
                // console.log('wrong number', fields[index]);
                copy = copy.replaceAll('{{' + value.insert + '}}', getContentValueForField(fieldsCopy.find(field => field.id === index)))
        }})
    }

    //  {{PF Key}} {{PF}} {{User}} {{Date Modif}} {{PN}} {{PN-}} {{User Gsm}}

    let searchRegexPFKey = "{{PF Key}}";
    let regexPFKey = /{{PF Key}}/g;
    if(copy.search(searchRegexPFKey)){
        copy = copy.replaceAll(regexPFKey, model?.clef || "");
    }

    let searchRegexPF = "{{PF}}"
    let regexPF = /{{PF}}/g
    if(copy.search(searchRegexPF)){
        copy = copy.replaceAll(regexPF, model?.label || "");
    }

    let searchRegexUser = "{{User}}"
    let regexUser = /{{User}}/g
    if(copy.search(searchRegexUser)){
        copy = copy.replaceAll(regexUser, user?.pseudo || "");
    }

    let searchRegexDateModif = "{{Date Modif}}"
    let regexDateModif = /{{Date Modif}}/g
    if(copy.search(searchRegexDateModif)){
        if(plugnote !== null){
            let date = typeof plugnote.moddate !== "undefined" ?  moment(plugnote.moddate).format('YY-MM-DD') : ""
            copy = copy.replaceAll(regexDateModif, date );
        }else copy = copy.replaceAll(regexDateModif, "" );
    }

    let searchRegexPn = "{{PN}}"
    let regexPn = /{{PN}}/g
    if(copy.search(searchRegexPn)){
        if(plugnote !== null){
            copy = copy.replaceAll(regexPn, "1-" + plugnote?.userindex || "");
        }else copy = copy.replaceAll(regexPn, "" );
    }

    let searchRegexPw = "{{PW}}"
    let regexPw = /{{PW}}/g
    if(copy.search(searchRegexPw)){
        if(pwCode !== null && pwCode !== ""){
            copy = copy.replaceAll(regexPw, i18n.t('common:smail.pwcode-info',{pw:pwCode}));
        }else copy = copy.replaceAll(regexPw, "" );
    }

    let searchRegexPwUrl = "{{PW Url}}"
    let regexPwUrl = /{{PW Url}}/g
    if(copy.search(searchRegexPwUrl)){
        if(pwCode !== null && pwCode !== ""){
            copy = copy.replaceAll(regexPwUrl, ApplicationConfig.templateUrl + '?code=' + pwCode);
        }else copy = copy.replaceAll(regexPwUrl, "" );
    }

    let searchRegexPn_ = "{{PN-}}"
    let regexPn_ = /{{PN-}}/g
    if(copy.search(searchRegexPn_)){
        copy = copy.replaceAll(regexPn_, _getCodePnPpCodeTemplate(plugnote));
    }

    let sarchRegexUserGsm = "{{User Gsm}}"
    let regexUserGsm = /{{User Gsm}}/g
    if(copy.search(sarchRegexUserGsm)){
        copy = copy.replaceAll(regexUserGsm, user?.mobile || "");
    }
    return copy;
}

export const _getCodePnPpCodeTemplate = (plug) => {
    if(plug === undefined || plug === null) return ""
    const {publicpncode} = plug

    let codePn= 'PN';

    switch (publicpncode) {
        case 0:
            codePn = 'PP';
            break;
        case 1:
            codePn = 'PN';
            break;
        case 2:
            codePn = 'PE';
            break;
        case 3:
            codePn = 'PS';
            break;
        default:
            break;
    }

    let pncode = plug.codepncode ? `${codePn}-${moment(plug.creationdate).format('YYMMDD')}-${plug.codepncode}` : null

    if(pncode !== null){
        pncode = pncode
    }else{
        pncode = "";
    }

    return pncode
};

export const _getCodePFCode = (plugform) => {
    return plugform !== null && plugform.codepfcode != null ? 'PF-' + moment(plugform.creationdate).format('YYMMDD') + '-' + plugform.codepfcode : '';
};

export const parseModel = (model) => {
    let _model = {}
    let _fields = (model)
    Object.keys(_fields).forEach((key) => {
        if(key.includes('label')) {
            let nb = key.charAt(5)
            if (!isNaN(parseInt(key.charAt(6)))) nb = nb + key.charAt(6)
            _model['field' + nb] = _fields[key]
        }
    })
    return _model
};

const parseOperator = (operator) => {
    switch (operator) {
        case 'contains':
            return " .x.. ";
        case 'isNotEmpty':
            return " ≠ Ø ";
        case 'isEmpty':
            return " = Ø ";
        case 'is':
        case 'equals':
        case '=':
        case 'on':
            return " = ";
        case '!=':
            return " ≠ ";
        case '<=':
            return " ≤ ";
        case '<':
        case 'before':
        case "onOrBefore":
            return " < ";
        case '>':
        case 'after':
            return " > ";
        case '>=':
        case 'onOrAfter':
            return " ≥ ";
        case 'startsWith':
            return " x.. ";
        case 'endsWith':
            return " ..x ";
        default:
            return " ? ";
    }
};

export const parseFilterParameters = (fields, options, model) => {
    let entries = []
    if(options !== null && fields !== null && Array.isArray(options)) {
        options.forEach((index) => {
            let parameters = ""
            let numero = 0
            numero = numero + index.columnField.charAt(5)
            if (index.columnField.length >= 5) {
                if (!isNaN(parseInt(index.columnField.charAt(6)))) numero = index.columnField.charAt(5) + index.columnField.charAt(6)
            }
            if(fields[index.columnField] === undefined) {
                parameters = parameters + index.columnField;
                parameters = parameters + parseOperator(index.operatorValue)
            }
            else if(index.columnField !== 'view' ) {
                parameters = parameters  + numero + ':'
                parameters = parameters + parseOperator(index.operatorValue)
            }
            if(index?.value !== undefined) {
                parameters = parameters + index.value
            }
            entries.push(parameters)
            if (options.indexOf(index) !== options.length -1) {
                parameters = parameters + '; '
            }
        })
    }
    let stringOptions = ''
    entries.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .forEach(option => {
        stringOptions = stringOptions + option
        if (entries.indexOf(option) !== entries.length -1) {
        stringOptions = stringOptions + '; '
        }
    })
    return stringOptions
}

export const fieldIsVisible = (field, props) => {
    // console.log('empty?', field);
    // console.log('visible?', props);
    let visible = false

    //? permet d'afficher tous les champs si Display empty fields est coché
    //? (dans le setup du plugform ouvert dans la modale)
    if (props.visibleAll && !props.mode.includes('plugcode-consult')) return true
    // if (props.model.fieldEmptyVisible === 1) {
    //     return true
    // }

    switch(field.format) {
        case "agenda":
        case "birthday":
        case "boolean":
        case "date":
        case "extdata":
        case "extdatan":
        case "glocalis":
        case "localis":
        case "mail":
        case "now":
        case "password":
        case "phone":
        case "qrcode":
        case "qrcodeint":
        case "sign":
        case "smail":
        case "textarea":
        case "text":
        case "time":
        case "url":
            return field.value !== "" && field.value !== undefined;
        case "formula":
        case "numeric":
            return field.value !== "" && field.value !== undefined && !isNaN(field.value);
        case "free-multi-select":
        case "free-multi-table":
        case "free-single-select":
        case "free-single-table":
        case "list":
        case "multi-table":
        case "multi-select":
        case "single-select":
        case "single-table":
        case "tablemulti":
            return field.value !== undefined && field.value.length > 0
        case "document":
        case "tablemultin":
            let empty = true
            Object.keys(field.value).forEach(key => {
                if(key === 'value' && field.value.value !== '') empty = false
                if(key === 'list' && field.value.list.length > 0) empty = false
            })
            return !empty
        case "smailvalidmulti":
            let vide = true
            if(field.value === "") return false
            else {
                let smailValue = typeof field.value === 'string' ? JSON.parse(field.value) : field.value
                if (smailValue.hasOwnProperty("value")) vide = smailValue.value.length === 0
                if (smailValue.hasOwnProperty("list") && vide) vide = smailValue.list.length === 0
            }
            return !vide
        default:
            let tmpValue = typeof field.value !== "undefined" && field.value !== null ? field.value.toString() : "";
            let length = field.format === "numeric" && tmpValue === "NaN" ? 0 : tmpValue.length;
            if (typeof tmpValue === "string" && Object(tmpValue) && tmpValue !== null && field.format === "plugform") {
                let tmp = field.value;
                if (tmp.hasOwnProperty("value")) length = tmp.value.length;
                else length = 0;
            }
            visible = ((props.visibleAll === false && length > 0) || props.visibleAll === true)
            return visible
    }
}

export const getNonEmptyRows = (rows) => {
    let isArray = Array.isArray(rows)
    let _rows = isArray ? rows : rows.list
    // console.log('got rows', rows, _rows);
    let nonEmptyRows = []
    if (_rows.length > 0) {
        _rows.forEach((row, rindex) => {
            let valArray = Object.values(row)
            // console.log('valArray', valArray);
            if (Object.keys(row).includes('recap')) { nonEmptyRows.push(row) }
            else if (valArray.some(value => (isDefined(value) && value !== '0' && value !== 0 && value !== '0:00' && value?.length !== 0))) { nonEmptyRows.push(row) }
            // else console.log(`row #${rindex} was empty`);
        })
    }
    if (nonEmptyRows.length > 0) return isArray ? nonEmptyRows : { list: nonEmptyRows, value: rows.value };
    else return isArray ? '' : { list: '', value: rows.value }
}

export const isPrintable = (field, mode) => {
    let print = true
    if (field.showOnPdf === false) { return false }
    if ((field.encodable === false || field.editable === false) && mode.includes('plugcode')) { print = false }
    if (field.visible === false && mode.includes('plugcode')) { print = false }
    return print
}

export const isVisibleInCSV = (field, mode) => {
    let visible = true
    if ((field.encodable === false || field.editable === false) && mode.includes('plugcode-complete')) { visible = false }
    if (field.visible === false && mode.includes('plugcode-consult')) { visible = false }
    return visible
}

export const getLabelWithSpecialChar = (label) => {
    let str = label
    if (label.includes('~')) {
        let array = label.split('~').map(entry => entry?.trim())
        str = array.join(' ')
    }
    if (str.includes('#')) {
        let array = label.split('#').map(entry => entry?.trim())
        str = array.join(' ')
    }
    return str
}

export const isEmptyField = (value) => {
    if (!isDefined(value)) {
        return true;
    }
    if (Array.isArray(value) || typeof value === 'string') {
        // console.log('Array.isArray(value) || typeof value === string', value.length === 0);
        return value.length === 0;
    } else if (typeof value === 'number') {
        return false;
    } else {
        let valArray = Object.values(value);
        // console.log('Object', valArray.some(row => !isEmptyField(row)));
        return !valArray.some(row => !isEmptyField(row));
    }
}

export const getFieldDefaultValue = (format, options, value) => {
    console.log('get default val params', format, options, value)

    if(isDefined(options.setup)){
        if (isDefined(options.setup.defaultValue)) {
            let _default = options.setup.defaultValue
            switch (format) {
                case 'text':
                case 'textarea':
                case 'url':
                    return _default
                case 'qrcode':
                    return _default + ';'
                // case '':
                // case '':
                // break;
                default:
                    return value
            }
        }
        else {
            return value;
        }
    }else {
        return value;
    }


}

export const getFormatDefaultValue = (format) => {
    switch (format) {
        case "select":
            return []
        case "date":
        case "duration":
        case "mail":
        case "numeric":
        case "time":
        case "text":
        default:
            return ""
    }
}

export const isRowLimitReached = (rowLimit, rows) => {
    if (!isDefined(rowLimit) || rowLimit === 0) {
        return false
    }
    if (rows.length > 0) {
        let lastRow = rows[rows.length - 1]
        let tableLength = lastRow.hasOwnProperty('recap') ? rows.length - 1 : rows.length
        return tableLength === rowLimit
    } else { return false }
}

export const fieldsWithDefaultValue = ['qrcode', 'smail', 'text', 'textarea', 'url', ]

export const fieldsWithOptions = ['document', 'extdata', 'extdatan', 'intdatamaj', 'smail', 'smailvalidmulti', 'tablemulti', 'tablemultin'];

