import React, {useRef, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {
    postImportTemplateCsvRequest,
    postImportTemplateDataCsvRequest,
    postImportTemplateDataTableCsvRequest
} from "../../actions/eximport";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {toast} from "react-toastify";
import InputLabel from "@material-ui/core/InputLabel";
import {Tooltip} from "@material-ui/core";
import colors from "../../assets/themes/Colors";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    div:{
        /* border: '1px red solid', */
        display: 'flex',
        justifyContent: 'flex-start',
        padding: "10px",
        width : '100%'
    },    button:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "#58c3c1",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
        width : '35px',
        height : '35px',
    },
    selectedButton:{
        margin : '5px',
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
        height : '35px',
        width : '35px',
    },
    sous_header_button: {
        width: "150px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    button_save: {
        color: "white",
        backgroundColor: colors.mainButton,
        width : '70px',
        border: `2px solid transparent`,
        alignSelf : 'flex-end'
    },
    button_cancel: {
        color: colors.mainDark,
        backgroundColor: "transparent",
        border: `2px solid ${colors.mainDark}`,
        fontWeight: "600",
        width : '70px',
        marginLeft : '5px',
        marginRight : '5px',
        alignSelf : 'flex-end'
    },
}));

const getCurrentDate=()=>{

    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    //Alert.alert(date + '-' + month + '-' + year);
    // You can turn it in to your desired format
    return date + '/' + month + '/' + year;//format: dd-mm-yyyy;
}

const checkDoublon = (list, text) => {
    return list.findIndex((item) => item === text) > -1
};

const exists = (model) => {
    return Object.keys(model).some(function(k) {
        return model[k] === "tablemulti" || model[k] === 'tablemultin' || model[k] === 'smailvalidmulti' ;
    })
};

const ModalImportExportCsvComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    let languageCode = i18n.language.substr(0, 2).toLowerCase();
    // getModalStyle is not a pure function, we roll the style only on the first render

    const model = getModel();
    const environment = getCurrentEnvironnement();

    const [modalStyle] = React.useState(getModalStyle);
    const [template, setTemplate] = React.useState("");
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedFile, setSelectedFile] = useState(false);
    const [buttonLabel, setButtonLabel] = React.useState(template === 'edit' ? t('common:global.edit') : t('common:global.import'));
    const [exist,setExist] = React.useState(exists(props.model));


    const titleModelName = '[' + model.clef + '] ' +  model.label;

    const inputFile = useRef(null)

    const handleCloseModal = () => {
        props.close();
    };

    const onError = (e) => {
        //console.log(e, 'error in file-viewer');
    };

    const checkMimeType=(event)=>{
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        // const types = ['image/png', 'image/jpeg', 'image/gif']
        // // loop access array
        // for(var x = 0; x<files.length; x++) {
        //     // compare file type find doesn't matach
        //     if (types.every(type => files[x].type !== type)) {
        //         // create error message and assign to container
        //         err[x] = files[x].type+' is not a supported format\n';
        //     }
        // };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    };

    const maxSelectFile=(event)=>{
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    };

    const checkFileSize=(event)=>{
        let files = event.target.files
        let size = 20000000
        let err = [];
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+'is too large, please pick a smaller file\n';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    };

    const onChangeHandler=event=>{
        const files = event.target.files;
        if(maxSelectFile(event) && checkMimeType(event) &&  checkFileSize(event)){
            setSelectedFiles(files);
            setSelectedFile(true);
        }
    };

    const handlePressImport = (model) => {

        props.close();

        const data = new FormData();
        for(let x = 0; x < selectedFiles.length; x++) {
            data.append('file', selectedFiles[x])
        }

        props.attemptImportTemplateCsv(data,model.hash, model.clef, model.label,model.isModelShared, environment.userhash);
    };

    const handlePressImportWithData = (model) => {

        props.close();

        const data = new FormData();
        for(let x = 0; x < selectedFiles.length; x++) {
            data.append('file', selectedFiles[x])
        }

        props.attemptImportTemplateWithDataCsv(data, model.hash, model.clef, model.label,model.isModelShared, environment.userhash);
    };

    const handlePressImportWithDataTable = (model) => {

        props.close();

        const data = new FormData();
        for(let x = 0; x < selectedFiles.length; x++) {
            data.append('file', selectedFiles[x])
        }

        props.attemptImportTemplateWithDataTableCsv(data, model.hash, model.clef, model.label,model.isModelShared, environment.userhash);
    };

    const handlePressValidate = () => {
        if(template === 'new'){
            handlePressImport(model);
        }else if(template === 'edit'){
            handlePressImportWithData(model);
        }else if(template === 'edit-table'){
            handlePressImportWithDataTable(model);
        }
    };

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <span>Importer et mise à jour via excel {titleModelName} </span>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">

{/*                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={template}
                            onChange={(event) => setTemplate(event.target.value)}
                        >
                            <MenuItem value={'new'}>{t("common:export.template-import-file-new-csv")}</MenuItem>
                            <MenuItem value={'edit'}>{t("common:export.template-import-file-edit-csv")}</MenuItem>
                        </Select>*/}

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>{ t('common:export.template-import-export-title') }</InputLabel>
                        </span>

                        <div className={classes.div}>
                            <Tooltip title={t('common:export.template-import-new-csv')}>
                                <button className={`${classes.sous_header_button} ${template === 'new' ? classes.button_save : classes.button_cancel}`} onClick={(event) => setTemplate('new')}>
                                        <span className="material-icons" style={{ color: template === 'new' ? colors.hoverBgnd : colors.main}}>
                                            table_chart
                                        </span>{" "}
                                </button>
                            </Tooltip>

                            {
                                exist &&
                                <Tooltip title={t('common:export.template-import-edit-table-csv')}>
                                    <button className={`${classes.sous_header_button} ${template === 'edit-table' ? classes.button_save : classes.button_cancel}`} onClick={() => setTemplate('edit-table')}>
                                        <span className="material-icons" style={{ color: template === 'edit-table' ? colors.hoverBgnd : colors.main}} >
                                            table_rows
                                        </span>
                                    </button>
                                </Tooltip>
                            }

                        </div>

                        {
                            template !== "" && <input type="file" className="form-control" multiple={false} onChange={onChangeHandler}/>
                        }


                    </div>
                    <button className="button" onClick={handlePressValidate} disabled={!selectedFile}>{buttonLabel}</button>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainProfil : state.user.profil
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptImportTemplateCsv: (annexe, modelhash, modelclef, modellabel,shared, userhash) => dispatch(postImportTemplateCsvRequest(annexe, modelhash, modelclef, modellabel,shared, userhash)),
        attemptImportTemplateWithDataCsv: (annexe, modelhash, modelclef, modellabel,shared, userhash) => dispatch(postImportTemplateDataCsvRequest(annexe, modelhash, modelclef, modellabel,shared, userhash)),
        attemptImportTemplateWithDataTableCsv: (annexe, modelhash, modelclef, modellabel,shared, userhash) => dispatch(postImportTemplateDataTableCsvRequest(annexe, modelhash, modelclef, modellabel,shared, userhash)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalImportExportCsvComponent)
