import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import _ from "lodash";
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel} from "../../utils/Common";
import {
    deleteGeneratePlugformcodeRequest,
    postGeneratePlugformcodeRequest,
    putGeneratePlugformcodeRequest
} from "../../actions/plugcode";
import Images from "../../assets/themes/Images";
import QRCode from "qrcode.react"
import ApplicationConfig from "../../config/ApplicationConfig";
import * as moment from "moment";
import html2canvas from 'html2canvas';
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {isFirefox, isSafari} from 'react-device-detect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TagsInputObjectMails from "../specials/input/TagsInputObjectMails";
import ModalSimpleSendMailV2PlugformCodeComponent from "./ModalSimpleSendMailV2PlugformCodeComponent";
import {dateFormat} from "../../utils/utils";
import colors from "../../assets/themes/Colors";


function getModalStyle() {
    const top = 10;
    return {
        top: 'auto',
        margin:'auto'
        //left: `${left}%`,
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        minHeight: '300px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    divSharedUser:{
        marginTop : 10,
        height  : '10vh',
        maxHeight : '20vh',
        overflowY :'scroll'
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    paddingMargin5:{
        padding : '5px',
        backgroundColor:"#92FCEF",
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
    },
    paddingMargin5v2:{
        padding : '5px',
        backgroundColor:"#70AFB4",
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
    },
    divRadius:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        backgroundColor: '#92fcef',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    divRadiusShare:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        backgroundColor: '#ACDAD9',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    divRadiusNotif:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        backgroundColor: '#ACDAD9',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    textFieldChips : {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const switchStyles = makeStyles((theme) => ({
    switchTrack: {
        backgroundColor: "#4417f7"
    },
    switchBase: {
        color: "#8873DF",
        "&.Mui-checked": {
            color: "#ffffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#8873DF"
        }
    },
}));

const checkDoublon = (list, text) => {
    return list.findIndex((item) => item === text) > -1
};

const checkDoublonObject = (list, text) => {
    return list.findIndex((item) => item.email === text) > -1
};

const getPlugcodeLink = (model) => {
    const production = ApplicationConfig.production;
    let link = null;
    const pfCode = _.get(model, 'pfcode', null);
    const hash = _.get(model, 'hash', null);
    const baseURL = production ? 'https://www.plugcodes.com/' : 'https://plug.plugnotes.com/'

    if (hash && (pfCode)) {
        link = `${baseURL}?fh=${hash}&pfc=${pfCode}`
    }

    return link || baseURL
}

const getCodePFCode = (model) => {
    return model.codepfcode != null ? 'PF-' + moment(model.creationdate.date).format('YYMMDD') + '-' + model.codepfcode : ''
}

const checkComment = (model) => {
    if(_.get(model, 'pfcomment', '') !== null){
        return _.get(model, 'pfcomment', '');
    }

    return "";
}

const isEmail = (value) => {
    return value.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) != null;
    //return /(.)+@(.)+/.test(value)
}

const ModalModelPlugcodeComponent = (props) => {
    const classes = useStyles();
    const classesSwitch = switchStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');
    const environment = getCurrentEnvironnement();
    const currentChooseModel = getModel();
    const model = props.model === null ? currentChooseModel : props.model;
    const link = model.pfcode !== null ?  getPlugcodeLink(model) : null;
    const [comment, setComment] = useState(checkComment(model));
    const [mail, setMail] = useState("");
    const [dataHasChanged, setDataHasChanged] = useState(false);
    const [openSendMail, setOpenSendMail] = useState(false);

    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());


    let mailNotification = []
    if (model && model.mailNotification && model.mailNotification.length > 0) {
        mailNotification = model.mailNotification.split(/\s*;\s*/).filter(item => item !== '');
    }

/*
    if (mailNotification && (mailNotification.length === 0 || (mailNotification.length > 0 && !mailNotification.find((mail) => mail === props.email))) ){
        mailNotification.push(props.email)
    }    */

    if (mailNotification && mailNotification.length === 0){
        mailNotification.push(props.email)
    }

    let mailNotificationRules = [];

    if (model && model.mailNotificationRules && model.mailNotificationRules.length > 0) {
        try {
            let textMailNotificationRulesToJson = JSON.parse(model.mailNotificationRules);
            mailNotificationRules = textMailNotificationRulesToJson.map(notification => {
                return {
                    email: notification.email,
                    read : notification.read,
                    readedit: notification.readedit,
                    addannexes: notification.addannexes,
                    main: notification.main
                }
            });
        }catch(err){
            let textMailNotificationRulesToJson = JSON.stringify(model.mailNotificationRules);
            let textMailNotificationRuleFromJson = JSON.parse(textMailNotificationRulesToJson);
            mailNotificationRules = textMailNotificationRuleFromJson.map(notification => {
                return {
                    email: notification.email,
                    read : notification.read,
                    readedit: notification.readedit,
                    addannexes: notification.addannexes,
                    main: notification.main
                }
            });
        }
    }

/*    if (mailNotificationRules && (mailNotificationRules.length === 0 || (mailNotificationRules.length > 0 && !mailNotificationRules.find((mail) => mail.email === props.email))) ){
        mailNotificationRules.push({"email" : props.email, "read" : null, "readedit": null, "addannexes" : null ,  "main" : true})
    }   */

    if (mailNotificationRules && (mailNotificationRules.length === 0) ){
        mailNotificationRules.push({"email" : props.email, "read" : null, "readedit": null, "addannexes" : null ,  "main" : true})
    }

    if(mailNotificationRules.length === 0 || (mailNotificationRules.length < mailNotification.length)){
/*        if(!mailNotificationRules.find((mail) => mail.email === props.email)){
            mailNotificationRules.push({"email" : props.email, "read" : null, "readedit": null, "addannexes" : null ,  "main" : true})
        }*/
        let addMailNotificationRulesEmpty = mailNotification.filter(item => item.email !== props.email);
        addMailNotificationRulesEmpty.map(notification => {
            if(!mailNotificationRules.find((mail) => mail.email === notification)) {
                mailNotificationRules.push({
                    email: notification,
                    read : true,
                    readedit: false,
                    addannexes: false,
                    main: false
                });
            }
        });
    }

    const [modelMailNotification, setModelMailNotification] = useState(mailNotification);
    const [modelMailNotificationDefault, setModelMailNotificationDefault] = useState(mailNotification);
    const [modelMailNotificationRules, setModelMailNotificationRules] = useState(mailNotificationRules);
    const [modelMailNotificationRulesDefault, setModelMailNotificationRulesDefault] = useState(mailNotificationRules);


    let mailInviteUsers = [];

    if (model && model.mailInviteUsers && model.mailInviteUsers.length > 0){
        //mailInviteUsers.push({"email" : props.email, "read" : null, "date": null, "addannexes" : null ,  "main" : true})
        try {
            let textMailInviteUsersToJson = JSON.parse(model.mailInviteUsers);
            mailInviteUsers = textMailInviteUsersToJson.map(notification => {
                return {
                    email: notification.email,
                    date : notification.date,
                }
            });
        }catch(err){
            let textMailInviteUsersToJson = JSON.stringify(model.mailInviteUsers);
            let textMailInviteUsersFromJson = JSON.parse(textMailInviteUsersToJson);
            mailInviteUsers = textMailInviteUsersFromJson.map(notification => {
                return {
                    email: notification.email,
                    date : notification.date,
                }
            });
        }
    }

    const [modelMailInviteUsers, setModelMailInviteUsers] = useState(mailInviteUsers);
    const [modelMailNewInviteUsers, setModelMailNewInviteUsers] = useState([]);
    const [modelMailDeleteInviteUsers, setModelMailDeleteInviteUsers] = useState([]);
    const [modelMailRefreshInviteUsers, setModelMailRefreshInviteUsers] = useState([]);



    const [authAnnexeInPublicMode, setAuthAnnexeInPublicMode] = useState(model.authAnnexeInPublicMode === 1);
    const [isCreated, setIsCreated] = useState(model ? !!model.isCreated : false);
    const [isLinked, setIsLinked] = useState(model ? !!model.isLinked : false);
    const [isNotificationLinked, setIsNotificationLinked] = useState(model ? !!model.isNotificationLinked : false);
    const [isEditable, setIsEditable] = useState(model ? !!model.isEditable : false);
    const [errorMail, setErrorEmail] = useState(false);
    const [errorMailInvite, setErrorEmailInvite] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const handleAddMailNotification = (mail) => {
        if(isEmail(mail)){
            setErrorEmail(false);
            let mailNotification = modelMailNotification;
            let mailNotificationRules = modelMailNotificationRules;

            if (!checkDoublon(mailNotification, mail)) {
                mailNotification = mailNotification.concat(mail);
            }

            if (!checkDoublonObject(mailNotificationRules, mail)) {
                if(props.email === mail){
                    mailNotificationRules = mailNotificationRules.concat({"email" : mail, "read" : null, "readedit": null, "addannexes" : null ,  "main" : true})
                }else{
                    mailNotificationRules = mailNotificationRules.concat({"email" : mail, "read" : true, "readedit" : false, "addannexes" : false, "main" :false});
                }
            }

            setMail("");
            setModelMailNotification(mailNotification);
            setModelMailNotificationRules(mailNotificationRules);
            setDataHasChanged(true);
        }else{
            setErrorEmail(true);
        }
    };

    const handleDelMailNotification = (mail, index) => {
        let mailNotification = modelMailNotification.filter(item => item !== mail);
        let mailNotificationRules = modelMailNotificationRules.filter(item => item.email !== mail);

        setModelMailNotification(mailNotification);
        setModelMailNotificationRules(mailNotificationRules);
        setDataHasChanged(true);
    }

    const handleAddMailInviteUser = (mail) => {
        if(isEmail(mail)){
            setErrorEmailInvite(false);
            let mailNewInviteUsers = modelMailNewInviteUsers;
            let mailInviteUsers = modelMailInviteUsers;

            if (!checkDoublonObject(modelMailInviteUsers, mail)) {
                const formatYmd = dateFormat (new Date (), "%Y-%m-%d %H:%M:%S", true)
                mailNewInviteUsers = mailNewInviteUsers.concat({"email" : mail, "date" : formatYmd});
                mailInviteUsers = mailInviteUsers.concat({"email" : mail, "date" : formatYmd});
            }

            setModelMailNewInviteUsers(mailNewInviteUsers);
            setModelMailInviteUsers(mailInviteUsers);
            setDataHasChanged(true);
        }else{
            setErrorEmailInvite(true);
        }
    };

    const handleDelMailNewInviteUser = (mail, index) => {
        let mailInviteUsers = modelMailNewInviteUsers.filter(item => item.email !== mail);
        setModelMailNewInviteUsers(mailInviteUsers);
        setDataHasChanged(true);
    }

    const handleDelMailInviteUser = (mail, index) => {
        let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
        let mailDeleteInviteUsers = modelMailDeleteInviteUsers;
        if (!checkDoublonObject(modelMailDeleteInviteUsers, mail)) {
            mailDeleteInviteUsers = mailDeleteInviteUsers.concat(modelMailInviteUsers[index]);
            setModelMailDeleteInviteUsers(mailDeleteInviteUsers)
        }

        setModelMailInviteUsers(mailInviteUsers);
        setDataHasChanged(true);
    }

    const handleRefreshMailInviteUser = (mail, index) => {
        //let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
        //setModelMailInviteUsers(mailInviteUsers);
        //setDataHasChanged(true);

        const formatYmd = dateFormat (new Date (), "%Y-%m-%d %H:%M:%S", true)

        let mailRefreshInviteUsers = modelMailRefreshInviteUsers;

        if (!checkDoublonObject(modelMailRefreshInviteUsers, mail)) {
            mailRefreshInviteUsers = mailRefreshInviteUsers.concat({"email" : mail, "date" : formatYmd});
        }

        if(checkDoublonObject(modelMailInviteUsers, mail)){
            let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
            mailInviteUsers = mailInviteUsers.concat({"email" : mail, "date" : formatYmd});
            setModelMailInviteUsers(mailInviteUsers);
        }

        setModelMailRefreshInviteUsers(mailRefreshInviteUsers);
        setDataHasChanged(true);

    }

    /**
     * Verifie si les options d'utilisation ont changé
     * @private
     */
    const accessibilitiesHasChanged = () => {

        return isLinked != !!model.isLinked || isEditable != !!model.isEditable ||
            authAnnexeInPublicMode != !!model.authAnnexeInPublicMode ||
            isCreated != !!model.isCreated || !_.isEqual(mailNotification, modelMailNotificationDefault) || !_.isEqual(modelMailNotificationRules, modelMailNotificationRulesDefault) ||
            isNotificationLinked != !!model.isNotificationLinked
    };

    const handleGeneratePfCode = () => {
        props.generatePlugcodePlugform('', currentChooseModel.hash, environment.userhash, currentChooseModel.isPublic, currentChooseModel)
    }

    const handleDeletePfCode = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.exit-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        props.deletePlugcodePlugform(model.hash, model.pfcode, environment.userhash)
                    }
                },
                {
                    label: t('global.cancel'),
                },
            ]
        });
    }

    const handleUpdatePfCode = () => {

        setDataHasChanged(false);

        const payload = {
            mh: model.hash,
            comment: comment,
            pfc: model.pfcode,
            uh: environment.userhash
        }

        let accessPayload = {
            auth_annexe_in_public_mode: authAnnexeInPublicMode,
            is_created: isCreated,
            mail_notification: modelMailNotification.join(';'),
            mail_notification_rules: modelMailNotificationRules,
            mail_invite_users : modelMailInviteUsers.length > 0 ? modelMailInviteUsers : null,
            mail_invite_new_users : modelMailNewInviteUsers.length > 0 ? modelMailNewInviteUsers : null,
            mail_invite_refresh_users : modelMailRefreshInviteUsers.length > 0 ? modelMailRefreshInviteUsers : null,
            mail_invite_delete_users : modelMailDeleteInviteUsers.length > 0 ? modelMailDeleteInviteUsers : null,
        };


        props.updatePlugcodePlugform(payload,accessPayload,model.hash,environment.userhash,model);

        setModelMailNewInviteUsers([]);
        setModelMailRefreshInviteUsers([]);
        setModelMailDeleteInviteUsers([]);

    }

    const copyPfCodeToClipboard = () => {


        if(isFirefox || isSafari){
            let pfCode = getCodePFCode(model);
            navigator.clipboard.writeText(pfCode);
            document.execCommand('copy');
            toast.success(i18n.t('common:global.plugcodes-to-clipboard'));
        } else{
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    let pfCode = getCodePFCode(model);
                    navigator.clipboard.writeText(pfCode);
                    toast.success(i18n.t('common:global.plugcodes-to-clipboard'));
                }
            });
        }
    };

    const copyLnkPfCodeToClipboard = () => {

        if(isFirefox || isSafari){
            navigator.clipboard.writeText(link);
            document.execCommand('copy');
            toast.success(i18n.t('common:global.link-to-clipboard'));
        }else{
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    navigator.clipboard.writeText(link);
                    toast.success(i18n.t('common:global.link-to-clipboard'));
                }
            });
        }

    };

    const sendMail = () => {
        setOpenSendMail(true);
    }

    const closeSendMail = () => {
        setOpenSendMail(false);
    }

    const downloadPfQrCode = () => {
        let tmpId = getCodePFCode(model);
        html2canvas(document.querySelector( `#plugcode-model-${tmpId}`)).then(canvas => {
            //document.body.appendChild(canvas)
             const pngUrl = canvas
                 .toDataURL("image/png")
                 .replace("image/png", "image/octet-stream");
             let downloadLink = document.createElement("a");
             downloadLink.href = pngUrl;
             downloadLink.download = id + ".png";
             document.body.appendChild(downloadLink);
             downloadLink.click();
             document.body.removeChild(downloadLink);
        });
    }

    const id = getCodePFCode(model);

    const _handlePressAccessibilityIsCreated = () => {

        setIsCreated(!isCreated);
        setDataHasChanged(true);

        // this.setState(prevState => {
        //     prevState[option] = !prevState[option];
        //
        //     if (option === 'isLinked' && !prevState[option]) {
        //         prevState['isEditable'] = false
        //     }
        //
        //     if (option === 'isNotificationLinked' && !prevState[option]) {
        //         prevState['isNotificationLinked'] = false;
        //
        //         let mailNotificationRules = prevState.mailNotification;
        //         prevState.mailNotificationRules = mailNotificationRules.map(notification => {
        //             return {
        //                 email: notification.email,
        //                 read : prevState['isNotificationLinked'],
        //                 readedit: false,
        //                 addannexes: false,
        //                 main: notification.main
        //             }
        //         });
        //
        //     }
        //
        //     return prevState
        // })
    };

    const _handlePressAccessibilityAuthAnnexeInPublicMode = () => {
        setAuthAnnexeInPublicMode(!authAnnexeInPublicMode);
        setDataHasChanged(true);
        // this.setState(prevState => {
        //     prevState[option] = !prevState[option];
        //
        //     if (option === 'isLinked' && !prevState[option]) {
        //         prevState['isEditable'] = false
        //     }
        //
        //     if (option === 'isNotificationLinked' && !prevState[option]) {
        //         prevState['isNotificationLinked'] = false;
        //
        //         let mailNotificationRules = prevState.mailNotification;
        //         prevState.mailNotificationRules = mailNotificationRules.map(notification => {
        //             return {
        //                 email: notification.email,
        //                 read : prevState['isNotificationLinked'],
        //                 readedit: false,
        //                 addannexes: false,
        //                 main: notification.main
        //             }
        //         });
        //
        //     }
        //
        //     return prevState
        // })
    };

    const _handleChangeComment = (event) => {
        setDataHasChanged(true);
        setComment(event.target.value);
    }

    const handleClosePopup = () => {

        if(dataHasChanged){
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.exit-without-saving'),
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            props.close();
                        }
                    },
                    {
                        label: t('global.cancel'),
                    },
                ]
            });
        }else{
            props.close();
        }
    }

    function handleSelecetedTags(items) {
    }

    useEffect(() => {
        setComment(checkComment(model));
        setAuthAnnexeInPublicMode(model.authAnnexeInPublicMode === 1);

        let mailInviteUsers = [];

        if (model && model.mailInviteUsers && model.mailInviteUsers.length > 0){
            //mailInviteUsers.push({"email" : props.email, "read" : null, "date": null, "addannexes" : null ,  "main" : true})
            try {
                let textMailInviteUsersToJson = JSON.parse(model.mailInviteUsers);
                mailInviteUsers = textMailInviteUsersToJson.map(notification => {
                    return {
                        email: notification.email,
                        date : notification.date,
                    }
                });
                setModelMailInviteUsers(mailInviteUsers);
            }catch(err){
                let textMailInviteUsersToJson = JSON.stringify(model.mailInviteUsers);
                let textMailInviteUsersFromJson = JSON.parse(textMailInviteUsersToJson);
                mailInviteUsers = textMailInviteUsersFromJson.map(notification => {
                    return {
                        email: notification.email,
                        date : notification.date,
                    }
                });
                setModelMailInviteUsers(mailInviteUsers);
            }
        }

    }, [props.model]);

    useEffect(() => {
        if(model !== null){
            if(currentLanguageLower === 'fr'){
                setTitle("Complétez le formulaire "+ model.clef + " " +  model.label);
                setContent(
                    "Bonjour,\n\n"+
                    "Je vous invite à completer le formulaire "+ model.clef + " " +  model.label + " via Plugnotes.\n\n" +
                    "Accédez au formulaire en cliquant sur le lien ci-dessous ou en encodant le Plugcode "+ getCodePFCode(model) + " dans l'application ou sur www.plugcodes.com.\n\n" +
                    getPlugcodeLink(model)+"\n\n\n"+
                    "Cordialement,\n\n\n"+
                    props.pseudo
                )
            }
            else{
                setTitle("Complete Form "+ model.clef + " " +  model.label);
                setContent(
                    "Dear,\n\n"+
                    "I invite you to complete the "+ model.clef + " " +  model.label + " form via Plugnotes.\n\n" +
                    "Access the form by clicking on the link below or by encoding the Plugcode "+ getCodePFCode(model) + " in the app or on www.plugcodes.com.\n\n" +
                    getPlugcodeLink(model)+"\n\n\n"+
                    "Kind regards,\n\n\n"+
                    props.pseudo
                )
            }
        }
    }, [props.model]);

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.handleModalModelPlugcodeClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >

            <div style={modalStyle} className={classes.popup}>
                { model.pfcode == null ?
                    < div className = "scrollbar-hidden popup_wrapper_plugcode flexSpacebetween" >
                        < div className="popup_wrapper_content">
                            <div className="sous-header_modal_content">
                                <div>
                                    <span className="titres">
                                        {t('common:plugcode.send-form')} <br />
                                    </span>
                                    <span className="grey">
                                        [{model.clef}] {model.label}
                                    </span>
                                </div>
                                <div onClick={props.close}>
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                        close
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="popup_wrapper_content">
                            <div>
                                <button className="button button_full_width" onClick={handleGeneratePfCode}>{t('common:models.generate-plugcode')}</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="scrollbar-hidden popup_wrapper_plugcode flexSpacebetween">
                        <div className="popup_wrapper_content">
                            <div className="sous-header_modal_content">
                                <div>
                                    <span className="titres">
                                        {t('common:plugcode.send-form-title')}
                                    </span>
                                    <span className="grey margin-left-20">
                                    [{model.clef}] {model.label}
                                    </span>
                                </div>
                                <div onClick={copyPfCodeToClipboard} className="cursor grey">
                                        <span className=" md-25 ">
                                            {
                                                getCodePFCode(model)
                                            }
                                        </span>
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                        content_copy
                                    </span>
                                </div>
                                <div onClick={handleUpdatePfCode} className="cursor">
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                        save
                                    </span>
                                </div>
                                <div onClick={handleClosePopup} className="cursor">
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                        close
                                    </span>
                                </div>

                            </div>
                            <div>
                                <span className="grey">{ t('common:modal-plugcode.invite-users') }</span>
                            </div>
                            <div className={classes.textFieldChips}>
                                <TagsInputObjectMails
                                    handleRemove={handleDelMailNewInviteUser}
                                    handleAdd={handleAddMailInviteUser}
                                    selectedTags={handleSelecetedTags}
                                    fullWidth
                                    tags={modelMailNewInviteUsers}
                                    controls={modelMailInviteUsers}
                                    id="tags"
                                    name="tags-shared"
                                    placeholder=""
                                    label=""
                                />
                            </div>
                            <div className={classes.divRadiusShare}>
                                <div className={classes.paddingMargin5v2}>
                                    <span className="white margin-5">{t('common:plugcode.list-shared-user')}</span>
                                </div>
                                <div className="line-white flex_style">
                                </div>
                                <div className="scrollbar-hidden inputWrapper retrait-popup-margin">
                                    <div className={`scrollbar-hidden  ${classes.divSharedUser}`}>
                                        {
                                        modelMailInviteUsers.map((mail, index) => {
                                            return (
                                                <div key={index} className="flex_display_list_email">
                                                    {mail.email}
                                                    {/* <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="default"
                                                                classes={{
                                                                    track: classesSwitch.switchTrack,
                                                                    switchBase: classesSwitch.switchBase,
                                                                }}
                                                                checked={sharePlugnotes}
                                                                onChange={handleToggleSharePlugnotes}
                                                                name={t('common:plugcode.auth-public-annexe')}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        }
                                                        label={t('common:plugcode.auth-public-annexe')}
                                                    /> */}
                                                    <span className="margin-left-auto cursor" onClick={(event) => handleRefreshMailInviteUser(mail.email, index)}>
                                                        {mail.date}
                                                        <span id="icon-close" className="material-icons md-25" style={{color: colors.unselectedMain}}>
                                                            refresh
                                                        </span>
                                                    </span>
                                                    <span id="icon-close" className="material-icons md-25 margin-left-auto cursor" style={{color: colors.unselectedMain}} onClick={(event) => handleDelMailInviteUser(mail.email, index)}>
                                                        delete
                                                    </span>
                                                </div>)
                                        })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={classes.divRadius}>
                                <div className={classes.paddingMargin5}>
                                    <span className="black margin-5">{t('common:modal-plugcode.params')}</span>
                                </div>
                                <div className="line-white flex_style">
                                </div>
                                <div className="inputWrapper retrait-popup-margin">
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="default"
                                                    classes={{
                                                        track: classesSwitch.switchTrack,
                                                        switchBase: classesSwitch.switchBase,
                                                    }}
                                                    checked={authAnnexeInPublicMode}
                                                    onChange={_handlePressAccessibilityAuthAnnexeInPublicMode}
                                                    name={t('common:plugcode.auth-public-annexe')}
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            }
                                            label={t('common:plugcode.auth-public-annexe')}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="default"
                                                    classes={{
                                                        track: classesSwitch.switchTrack,
                                                        switchBase: classesSwitch.switchBase,
                                                    }}
                                                    checked={isCreated}
                                                    onChange={_handlePressAccessibilityIsCreated}
                                                    name={t('common:plugcode.receive-notification')}
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            }
                                            label={t('common:plugcode.receive-notification')}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                isCreated &&
                                <div>
                                    <span className="grey">{ t('common:modal-plugcode.notifications') }</span>
                                </div>
                            }

                            { isCreated &&
                                <div className={classes.textFieldChips}>
                                    <TagsInputObjectMails
                                        handleRemove={handleDelMailNotification}
                                        handleAdd={handleAddMailNotification}
                                        selectedTags={handleSelecetedTags}
                                        tags={modelMailNotificationRules}
                                        fullWidth
                                        id="tags"
                                        name="tags-shared"
                                        placeholder=""
                                        label=""
                                    />
                                    <div>
                                        { errorMail &&
                                        <span className="red">{t('common:plugcode.error-mail')}</span>
                                        }
                                    </div>

                                </div>
                            }

                            <div>
                                <span className="grey">{ t('common:modal-plugcode.comment-plugform') }</span>
                            </div>

                            <div className="inputWrapper popup-TextInput">
                                <div>
                                    <textarea className="input_area_small" maxLength="255" name="description" rows="2" value={comment} onChange={_handleChangeComment}/>
                                    <span style={{float : "right"}}>{comment.length +  ' / ' + '256'}</span>
                                </div>
                            </div>
                            <div className="margin-top flex_display_direction_row_center">
                                <div className="flex_display_direction_row_center">
                                    <div className="confirmation_pop-up cursor" onClick={handleDeletePfCode}>
                                        <button className="grey-button"><span className="greyFine">{t('common:models.delete-plugcode')}</span></button>
                                    </div>
                                </div>
                                <div className="order-2 flex_display_direction_row_center">
                                    <div onClick={downloadPfQrCode} className="cursor margin-5">
                                        <button className="grey-button margin-5">
                                        <span className="material-icons md-25 black">
                                            qr_code
                                        </span>
                                            <span className="greyFine">{t('common:plugcode.qr-code')}</span>
                                        </button>
                                    </div>
                                    <div onClick={copyLnkPfCodeToClipboard} className="cursor">
                                        <button className="green-button margin-5">
                                        <span className="material-icons md-25 ">
                                            link
                                        </span>
                                            <span className="white">{t('common:plugcode.copy-link')}</span>
                                        </button>
                                    </div>
                                    <div  onClick={sendMail} className="cursor">
                                        <button className="base-button">
                                           <span className="material-icons md-25 ">
                                                mail_outline
                                            </span>
                                            <span className="white">{ t('common:modal-plugcode.invite') }</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="popup_wrapper_content_secret">
                            <div id={`plugcode-model-${id}`}>
                                <div style={{
                                    flexDirection: 'column',
                                    alignItems : 'center',
                                    display : 'flex',
                                }}>
                                    <div className="subQrCode-media-div">
                                        <span> {comment} </span>
                                    </div>
                                    <div className='qrCode-media'>
                                        <div className='subQrCode-media-color-1'>
                                            <div className='subQrCode-media-color-2'>
                                                <div>
                                                    <QRCode
                                                        id={id}
                                                        value={link}
                                                        size={180}
                                                        bgColor={"#ffffff"}
                                                        fgColor={"#20959D"}
                                                        level={"L"}
                                                        includeMargin={false}
                                                        renderAs={"png"}
                                                        imageSettings={{
                                                            src: Images.iconQrCode,
                                                            x: null,
                                                            y: null,
                                                            height: 40,
                                                            width: 40,
                                                            excavate: false,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div><span className="white">{ApplicationConfig.url}</span></div>
                                        <div><span className="white">{getCodePFCode(model)}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalSimpleSendMailV2PlugformCodeComponent
                            open={openSendMail}
                            close={closeSendMail}
                            code={getCodePFCode(model)}
                            link={getPlugcodeLink(model)}
                            title={title}
                            content={content}
                            model={model}
                        />
                    </div>
                }
            </div>

        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        model : state.plugcode.model,
        email: state.user.profil.email,
        pseudo : state.user.profil.pseudo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        generatePlugcodePlugform:(comment,modelhash,userhash,isPublic,model) => dispatch(postGeneratePlugformcodeRequest(comment,modelhash,userhash,isPublic,model)),
        deletePlugcodePlugform:(modelhash, modelpfc, userhash) => dispatch(deleteGeneratePlugformcodeRequest(modelhash, modelpfc, userhash)),
        updatePlugcodePlugform: (payload,accessPayload,modelhash,userhash,model) => dispatch(putGeneratePlugformcodeRequest(payload,accessPayload,modelhash,userhash,model)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalModelPlugcodeComponent)
