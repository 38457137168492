import { makeStyles } from "@material-ui/core"
import images from "../../assets/themes/Images"


export const AttachmentThumbnail = (props) => {
  let att = props.attachment
  const classes = useStyles()
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={att.path} alt={att.name}/>
        </div>
      )
    case 'gif':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.gifPng} alt={att.name}/>
        </div>
      )
    case 'html':
    case 'xhtml':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.htmlPng} alt={att.name}/>
        </div>
      )
    case 'pdf':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.pdfPng} alt={att.name}/>
        </div>
      )
    case 'txt':
    case 'doc':
    case 'docx':
    case 'pages':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.docPng} alt={att.name}/>
        </div>
      )
    case 'note':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.notePng} alt={att.name}/>
        </div>
      )
    case 'pptx':
    case 'ppt':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.pptPng} alt={att.name}/>
        </div>
      )
    case 'csv':
    case 'number':
    case 'sheet':
    case 'xls':
    case 'xlsx':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.xlsPng} alt={att.name}/>
        </div>
      )
    case '7z':
    case 'rar':
    case 'zip':
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.zipPng} alt={att.name}/>
        </div>
      )
    default:
      return (
        <div className={classes.thumbnailCtn} >
          <img src={images.genericPng} alt={att.name}/>
        </div>
      )
  }
}

const useStyles = makeStyles(() => ({
  thumbnailCtn: {
    width: 50,
    maxHeight: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  }
}))
