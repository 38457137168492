import React, {useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import {
    postExtDataGenerateTableRequest,
    postExtDataPreviewRequest,
    postExtDataPreviewReset,
    postExtDataRequest
} from "../../actions/extdata";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import * as api from "../../api/extdataApi";
import {trackPromise} from "react-promise-tracker";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {convertersNumberToAlpha} from "../../utils/utils";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {FormControl, InputAdornment, InputLabel, Tooltip} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ExtDataSideMenu } from "../custom/ExtDataTable";
import colors from "../../assets/themes/Colors";
import { confirmAlert } from "react-confirm-alert";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        width : '100%',
        display: 'flex',
    },
    subfield : {
        width : '95%',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        margin: 20,
        marginTop: 0,
        width : '100%',
        display : 'grid'
    },
    alignedInput: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
    },
    mainComponent:{
        marginLeft:  -50,
        marginRight: 40,
        alignSelf: "center",
        zIndex: 5,
    },
    tableRoot: {
        width: "100%", //it was 100%
        marginTop: theme.spacing(3),
        overflowX: "auto",
        //marginRight: 'auto',
    },
    inputLabel: {
        marginRight: -70,
    },
    inputMargin:{
        margin : '10px',
        minWidth: 200,
        alignSelf: 'flex-end'
    },
    selectMargin:{
        margin : '10px',
        minWidth: 200,
        height: 42,
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
    },
    inputLarge:{
        margin : '10px',
        minWidth: 300,
        alignSelf: 'flex-end'
    },
    tooltipStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '16px',
        borderRadius: 5
    },
    alertContainer: {
        padding: 20,
        position: 'relative',
        width: '60vw',
        height: '60vh',
        borderRadius: 20,
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        color: colors.darkSilver,
        fontWeight: 800,
        fontSize: 20,
        marginBottom: 10,
    },
    alertClose: {
        position: 'absolute',
        top: 15,
        right: 15,
        color: colors.frost,
    },
    alertCloseBtn: {
        position: 'absolute',
        bottom: 15,
        right: 15,
    },
    alertSubtitle: {
        color: colors.darkSilver,
        fontWeight: 600,
        fontSize: 17,
        marginLeft: 20,
        marginTop: 20,
    },
}));


const FormatTMReferenceConstructorComponent = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const [newDataTarget, setNewDataTarget] = useState(typeof props.options.target !== "undefined" && props.options.target !== ""  ? props.options.target  : "");
    const [newDataTargetTable, setNewDataTargetTable] = useState(typeof props.options.targettable !== "undefined" && props.options.targettable !== ""  ? props.options.targettable  : "");

    const handleDataReference = (field) => {
        setNewDataTarget(field);

        props.options.target = field;
    }
    const handleDataReferenceTable = (field) => {
        setNewDataTargetTable(field);

        props.options.targettable = field;
    }

    return (
        <div className={classes.field}>
            <div className={classes.mainInputField}>
                <div className={classes.alignedInput}>
                    <FormControl variant="standard" className={classes.inputMargin}>
                        <InputLabel htmlFor={props.field.format.toLowerCase()+'-file'} className={classes.inputLabel} shrink={true}>{t('plugnote.copy-from-pn')}</InputLabel>
                        <InputLabel className={classes.sideInstructions}>
                        </InputLabel>
                        <Input
                            id={props.field.format.toLowerCase()+'-file'}
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={{
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 40
                            }}
                            // className="text-line-margin"
                            onChange={(event) => handleDataReference(event.target.value)}
                            defaultValue={newDataTarget}
                        />
                    </FormControl>
                    {
                        props.field.complex &&
                        <FormControl variant="standard" className={classes.inputMargin}>
                            <InputLabel htmlFor={props.field.format.toLowerCase()+'-file'} className={classes.inputLabel} shrink={true}>{t('plugnote.copy-from-table')}</InputLabel>
                            <InputLabel className={classes.sideInstructions}>
                            </InputLabel>
                            <Input
                                id={props.field.format.toLowerCase()+'-file'}
                                label="Label"
                                fullWidth={true}
                                margin="none"
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    fieldkey: props.field.key,
                                    className: classes.inputField,
                                    maxLength: 40
                                }}
                                // className="text-line-margin"
                                onChange={(event) => handleDataReferenceTable(event.target.value)}
                                defaultValue={newDataTargetTable}
                            />
                        </FormControl>
                    }
                </div>
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        user : state.user,
        profil : state.user.profil,
    }
}

const mapDispatchToProps = (dispatch) => ({


});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTMReferenceConstructorComponent);
