import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import { KeyboardArrowDown, KeyboardArrowUp, Settings } from "@material-ui/icons";
import colors from "../../assets/themes/Colors";
import { PlugTooltip } from "../custom/CustomComponents";
import ModalHelpComponent from "../modals/ModalHelpComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    fieldContainer: {
        width : '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    field : {
        width : '100%',
        display: 'flex',
        scrollMarginTop: '300px',
        maxHeight: 35
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    paramsBtn: {
        height: 'fit-content',
        color: colors.white,
        cursor: 'pointer',
        overflow: 'visible',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        backgroundColor: colors.mainButton,
        padding: 4,
        margin: '-11px auto 0',
        zIndex: 1,
        '&:hover': {
            backgroundColor: colors.main,
            boxShadow: '0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 20px rgb(0 0 0 / 20%)'
        },
        width: 40,
    },
    openClose: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tableOverviewRow: {
        display: 'flex',
        gap: 5,
        flexWrap: 'wrap',
        paddingTop: '10px',
    },
    tableRow: {
        borderRadius: 4,
        color: colors.main,
        padding: '0px 5px',
        gap: 5,
        // display: 'flex',
        // alignItems: 'center'
    },
    rowNb: {
        fontSize: 14,
        marginRight: 4,
        border: '1px solid',
        padding: '0px 4px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
}));

const formatWithExtraMenu = ['extdata', 'extdatan', 'smail', 'smailvalidmulti', 'tablemulti', 'tablemultin', 'document','intdatamaj']
const formatWithFields = ['extdatan', 'tablemulti', 'tablemultin'] // S02, F32, S32


const FormatFieldConstructorComponent = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const [includes, ] = useState(['date','time','agenda','birthday','email','mail','smail','smailvalidmulti','url','tel','localis','now']);
    const [open, setOpen] = useState(props.openSMail === props.field.id);

    const [fields, setFields] = useState(null)
    const bottomRef = useRef()
    useEffect(() => {
        if(formatWithExtraMenu.includes(props.field.format)){
            setOpen(props.openSMail === props.field.id);
        }
    }, [props.openSMail])

    useEffect(() => {
        if(formatWithFields.includes(props.field.format)) {
            if (typeof props.field.formatoptions !== 'undefined') {
                if (typeof props.field.formatoptions.lines === 'undefined') setFields(props.field.formatoptions);
                else if (typeof props.field.formatoptions.lines !== 'undefined') setFields(props.field.formatoptions.lines)}
        }
    }, [props.field.format, props.field.formatoptions])

    // console.log('format field const', props);

    return (
    <div className={classes.fieldContainer}>
        <div className={classes.field} ref={bottomRef}>
            <Input
                id="standard-full-width"
                label="Label"
                placeholder={includes.includes(props.field.format.toLowerCase()) ? t('fields.format-choice-placeholder-'+ props.field.format.toLowerCase()) : ""}
                fullWidth={true}
                margin="none"
                inputlabelprops={{ shrink: true }}
                inputProps={{
                    className: classes.inputField,
                    fieldkey: props.field.key,
                    maxLength: 40,
                }}
                style={{ width: !formatWithExtraMenu.includes(props.field.format) ? '100%' : '116%' }}
                className="text-line"
                onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                defaultValue={props.field.label}
            />
            {
                formatWithExtraMenu.includes(props.field.format) &&
                <span style={{ width: props.isCreate === true ? '35%' : '40%' , display: 'flex'}}>
                    <PlugTooltip title={t('helper.more-options')} placement="top">
                        <div
                            className={classes.paramsBtn}
                            onClick={() => {props.handleOpenCloseSmail(props.field.id)}}
                            >
                            {!open ?
                            <div className={classes.openClose}><Settings /><KeyboardArrowDown /></div>
                            :
                            <div className={classes.openClose}><Settings /><KeyboardArrowUp /></div>}
                        </div>
                    </PlugTooltip>
                </span>
            }
        </div>
        {fields !== null && fields.length > 0 &&
        <div className={classes.tableOverviewRow}>
            {
            fields.map((row, index) => {
                return (
                <div className={classes.tableRow} key={"header-row-" + index}>
                    <span className={classes.rowNb}>{index + 1}</span>
                    <span className={classes.rowFormat}> {row.label}</span>
                </div>)})
            }
        </div>
        }
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatFieldConstructorComponent);





