import { styled, TextField, Tooltip } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { XGrid } from "@material-ui/x-grid";
import React, { useRef } from "react";
import colors from "../../assets/themes/Colors";
import InputMask from 'react-input-mask';
import { isDefined } from "../../utils/utils";
import { InvisiblePDF, InvisiblePF, InvisiblePN } from "./icons/CustomIcons";

const useStyles = makeStyles((_) => ({
    durationInputContainer: {
        display: 'flex',
        width: '100%',
        background: colors.greenLightest,
        marginBottom: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: '1px solid black',
        position: 'relative'
    },
    renderFirstInputContainer: {
        display: 'flex',
        width: '40px'
    },
    renderSecondInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 'auto'
    },
    renderCentralInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 17
    },
    renderThirdInputContainer: {
        width: '70%'
    },
    stylizedInput: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontStyle: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    delButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        position: 'absolute',
        right: 0
    },
    indicatorCtn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        gap: 4,
        height: 15,
        cursor: 'default',
        justifyContent: 'center',
    },
    indicator: {
        position: 'relative',
        fontSize: 8,
        width: 10,
        height: 10,
    },
    indicatorFalse: {
        fontWeight: "bolder",
        color: colors.bloodOrange,
        fontSize: 7,
        position: 'absolute',
        top: -2,
        left: 0,
        right: 0,
    },
    slashIcon: {
        fontSize: 8,
        color: colors.bloodOrange,
        position: 'absolute',
        top: 6,
        left: 0,
        right: 0,
    },
}))

const StyledXGrid = styled(XGrid)((_) => ({
    '& .MuiDataGrid-root, .MuiDataGrid-row.Mui-selected': {
        backgroundColor: colors.selectedRow,
    },
    // ...other style rules
}));

export function DesignedGrid (props) {
    return <StyledXGrid {...props} />
}

export function DurationInput (props) {
    let classes = useStyles();
    const { t, } = useTranslation(['common']);
    const firstInput = useRef()
    const secondInput = useRef()
    const thirdInput = useRef()
    const navigateThrough = (e, inputNb, hasThird) => {
        switch(e.key){
            case 'ArrowRight':
                if(inputNb === 2 && hasThird && props.duration[1].trim().length === 2 && e.target.selectionStart === 2) {
                    thirdInput.current.setSelectionRange(0, 0);
                    thirdInput.current.focus()
                }
                if(inputNb === 1 && props.duration[0].trim().length >= 1 && e.target.selectionStart >= 1) {
                    secondInput.current.setSelectionRange(0, 0);
                    secondInput.current.focus()
                }
                break;
            case 'ArrowLeft':
                if(inputNb === 2 && e.target.selectionStart === 0) {
                    firstInput.current.setSelectionRange(props.duration[0].length, props.duration[0].length);
                    firstInput.current.focus()
                }
                if(inputNb === 3 && e.target.selectionStart === 0) {
                    secondInput.current.setSelectionRange(2, 2);
                    secondInput.current.focus()
                }
                break;
            case ':':
                if(inputNb === 1 && props.duration[0].trim().length >= 1){
                    secondInput.current.setSelectionRange(0, 0);
                    secondInput.current.focus()
                }
                if(inputNb === 2 && hasThird && props.duration[1].trim().length === 2) {
                    thirdInput.current.setSelectionRange(0, 0);
                    thirdInput.current.focus()
                }
                break;
            case 'Backspace':
                if(inputNb === 3 && props.duration[2].trim().length === 0){
                    secondInput.current.setSelectionRange(2, 2);
                    secondInput.current.focus()
                }
                if(inputNb === 2 && props.duration[1].trim().length === 0){
                    firstInput.current.setSelectionRange(props.duration[0].length, props.duration[0].length);
                    firstInput.current.focus()
                }
                break;
            default: if(inputNb === 2 && props.duration[1].trim().length === 2 && hasThird && e.target.selectionStart === 2){
                    thirdInput.current.setSelectionRange(0, 0);
                    thirdInput.current.focus()
                }
                break;
        }
    }
    const allowedInput = ['0','1','2','3','4','5','6','7','8','9','Backspace', null, ':']
    const handleChangeFirst = (e, hasThird) => {
        // console.log("first input", e);
        if(!allowedInput.includes(e.nativeEvent.data)) return null;
        props.setDuration([e.target.value.trim(), props.duration[1], props.duration[2]])
        let durString = ''
        if(hasThird) durString = e.target.value.trim() + ':' + props.duration[1] + ':' + props.duration[2];
        else durString = e.target.value.trim() + ':' + props.duration[1]
        if(durString.length > 2) props.handleChangeTableValue(durString);
        else props.handleChangeTableValue('')
    }
    const handleChangeSecond = (e, hasThird) => {
        // console.log('2nd input', e.target.value);
        props.setDuration([props.duration[0], e.target.value.trim(), props.duration[2]])
        let durString = ''
        if(hasThird) durString = props.duration[0] + ':' + e.target.value.trim() + ':' + props.duration[2]
        else durString = props.duration[0] + ':' + e.target.value.trim()
        if(durString.length > 2) props.handleChangeTableValue(durString)
    }
    const handleChangeThird = (e) => {
        // console.log('3rd input', e.target.value);
        props.setDuration([props.duration[0], props.duration[1], e.target.value.trim()])
        let durString = props.duration[0] + ':' + props.duration[1] + ':' + e.target.value.trim()
        if(durString.length > 2) props.handleChangeTableValue(durString);
        else props.handleChangeTableValue('')
    }
    const handleDeleteValue = () => {
        props.handleChangeTableValue('');
        props.setDuration(['', '',''])
        props.setAccept(false)
    }

    let durationInvalid = false
    let hasThird = false
    switch (props.mask) {
        case '99:99:99':
            if(!props.value.includes('_')) durationInvalid = props.duration[1] >= 60 || props.duration[2] >= 60
            // props.mask = '99:99:99'
            hasThird = true
            break;
        case '99:99':
            if(!props.value.includes('_')) durationInvalid = props.duration[1] >= 60
            // props.mask = "99:99"
            break;
        default:
            break;
    }
    return(
        <div
            className={classes.durationInputContainer}
            onClick={() => {if(props.value.trim().length === 0)
                {firstInput.current.setSelectionRange(0, 0);
                firstInput.current.focus()}
            }}
        >
            <TextField
                autoFocus={props.edit === "cell"}
                id={props.cindex + 'first'}
                inputRef={firstInput}
                inputProps={{ style: {textAlign: 'right', paddingRight: 2} }}
                name={props.col.format + '-' + props.cindex}
                type="tel"
                disabled={props.disabled}
                InputProps={{
                    classes: {
                        input: classes.fontStyle,
                        root: classes.renderFirstInputContainer,
                    },
                    disableUnderline: true,
                }}
                onBlur={() => {if(props.duration[0].trim().length === 0) props.setDuration(['', props.duration[1], props.duration[2]])}}
                onChange={(e) => {if (!props.disabled) {props.setAccept(false); handleChangeFirst(e, hasThird)}}}
                onKeyUp={(e)=> navigateThrough(e, 1)}
                value={props.duration[0]}
            />
            {props.duration[0] !== '' && ':'}
            <InputMask
                disabled={props.disabled}
                maskChar=" "
                mask="99"
                value={props.duration[1]}
                onChange={(e) => {if (!props.disabled)
                    {props.setAccept(false)
                    handleChangeSecond(e, hasThird)}
                }}
                onBlur={() => {if(props.duration[1].trim().length === 0 && !props.disabled) props.setDuration([props.duration[0], '', props.duration[2]])}}
                >
                {(inputProps) =>
                <TextField
                    id={props.cindex + 'second'}
                    inputRef={secondInput}
                    inputProps={{ style: hasThird?{ textAlign:'center', flexGrow: 1} : {flexGrow: 1 , paddingLeft: 0} }}
                    name={props.col.format + '-' + props.cindex}
                    onKeyUp={(e)=> navigateThrough(e, 2, hasThird)}
                    InputProps={{
                        classes: {
                            input: classes.fontStyle,
                            root: hasThird? classes.renderCentralInputContainer : classes.renderSecondInputContainer,
                        },
                        disableUnderline: true,
                        endAdornment:
                        (props.value.length > 0 && !hasThird && durationInvalid && !props.accept && !props.disabled ?
                        <React.Fragment>
                            <Tooltip title={t('common:multiple.validate')} placement="right">
                                <span className={classes.fontError} onClick={() => props.setAccept(true)} style={{marginLeft: 23}}>
                                    {t('common:multiple.invalid-value')}
                                    <span id="icon-add-playlist" className={`${classes.tableMultiButton} material-icons`}>
                                        check
                                    </span>
                                </span>
                            </Tooltip>
                        </React.Fragment> : null),
                    }}
                />}
            </InputMask>
            {props.mask === '99:99:99' && props.duration[1].length === 2 && ':'}
            {props.mask === '99:99:99' &&
            <InputMask
                disabled={props.disabled}
                maskChar=" "
                mask="99"
                value={props.duration[2]}
                onChange={(e) => {if (!props.disabled)
                    {props.setAccept(false)
                    handleChangeThird(e)}
                }}
                onBlur={() => {if(props.duration[2].trim().length === 0 && !props.disabled) props.setDuration([props.duration[0], props.duration[1], ''])}}
                >
                {(inputProps) =>
                <TextField
                    id={props.cindex + 'third'}
                    inputRef={thirdInput}
                    inputProps={{ style: {textAlign: 'left', flexGrow: 1, paddingLeft: 2} }}
                    name={props.col.format + '-' + props.cindex}
                    onKeyUp={(e)=> navigateThrough(e, 3, hasThird)}
                    InputProps={{
                        classes: {
                            input: classes.fontStyle,
                        },
                        disableUnderline: true,
                        endAdornment:
                        (props.value.length > 0 && hasThird && durationInvalid && !props.accept && !props.disabled ?
                        <React.Fragment>
                            <Tooltip title={t('common:multiple.validate')} placement="right">
                                <span className={classes.fontError} onClick={() => props.setAccept(true)} style={{marginRight: 25}}>
                                    {t('common:multiple.invalid-value')}
                                    <span id="icon-add-playlist" className={`${classes.tableMultiButton} material-icons`}>
                                        check
                                    </span>
                                </span>
                            </Tooltip>
                        </React.Fragment> : null),
                    }}
                />}
            </InputMask>}
            {props.value.length > 0 && !props.disabled ?
            <Tooltip title={t('common:global.delete')} placement="right">
                <span className={classes.deleteBtn} onClick={() => handleDeleteValue()}>
                    <span id="icon-add-playlist" className={`${classes.delButton} material-icons`}>
                        highlight_off
                    </span>
                </span>
            </Tooltip>
            : null}
        </div>
    )
}

export const RestyledNumericInput = (props) => {
    let classes = useStyles()

    const handlePeriod = (e) => {
        let cursorA = e.target.selectionStart
        let cursorB = e.target.selectionEnd
        if(e.key === '.' || e.key === ',') {
            let _e = {...e}
            let text = props.value
            text = text.slice(0, cursorA) + '.' + text.slice(cursorB)
            _e.target.value = text
            props.onChange(_e)
            e.target.setSelectionRange(cursorA + 1, cursorA + 1)
        }
    }

    return(
        <TextField
            autoComplete="none"
            autoFocus={props.edit === "cell"}
            disabled={props.disabled}
            fullWidth={true}
            inputProps={{ style: {textAlign: 'center'} }}
            InputProps={{
                classes: {
                    input: classes.fontStyle,
                    root: classes.stylizedInput,
                },
            }}
            name={props.col.format + '-' + props.cindex}
            onChange={props.onChange}
            onKeyDown={(e) => handlePeriod(e)}
            placeholder={props.col.mask}
            value={props.value}
        />)
}

export const ColorCheckbox = (props) => {
    let color = props.color ? props.color : colors.selectedMain
    if (props.disabled) { color = colors.iconGrey }
    const size  = props.size? props.size : 20
    const checked = props.checked
    const propStyle = props.style || null
    if (checked) return (
        <span
            id="icon-more"
            className={`material-icons md ${props.disabled ? 'material-icons-disabled' : undefined}`}
            style={{ margin: 0, width: size, height: size, marginRight: '5px', color: color, fontSize: size, ...propStyle }}
        >
            check_box
        </span>
    )
    else return (
        <span
            id="icon-more"
            className={`material-icons md  ${props.disabled ? 'material-icons-disabled' : undefined}`}
            style={{ margin: 0, width: size, height: size, marginRight: '5px', color: color, fontSize: size, ...propStyle }}
        >
            check_box_outline_blank
        </span>
    )
}

export function PlugTooltip (props) {
    if (props.debug) console.log('Plug TT props', props)
    const textStyle = {
        fontSize: isDefined(props.size) ? props.size : 15,
        whiteSpace: 'break-spaces',
        textAlign: isDefined(props.alignment) ? props.alignment : "left",
        display: 'flex',
    }
    
    return <Tooltip
                {...props}
                open={props.debug} //? for tooltip inspection
                title={
                    <span style={textStyle}>{props.title}</span>
                }
            />
}

export function FieldVisibilityIndicator(props) {
    let field = props.field
    let mode = props.mode
    // console.log('FieldVisibility got props', props);
    const classes = useStyles();
    let printable = field.showOnPdf === true || !isDefined(field.showOnPdf)
    let visible = field.visible === true || !isDefined(field.visible)
    let editable
    if (field.hasOwnProperty('editable') && !props.isTableColumn) { editable = field.editable === true || !isDefined(field.editable) }
    else if (field.hasOwnProperty('encodable')) { editable = field.encodable === true || !isDefined(field.encodable) }
    else { editable = true }
    let shown = !visible || !editable || !printable
    let infobulle = ''
    if (!visible) infobulle += "Not visible via PN-code";
    if (!editable) {
        if (infobulle.length > 0) {infobulle += '\n'}
        infobulle += "Not visible via PF-code"
    }
    if (!printable) {
        if (infobulle.length > 0) {infobulle += '\n'}
        infobulle += "Not printed in PDF"
    }

    if ((mode === 'create' || mode === 'update') && shown) return (
        <PlugTooltip title={infobulle} placement="bottom" size={13} >
            <span className={classes.indicatorCtn}>
                {
                    !editable &&
                    <InvisiblePF />
                }
                {
                    !visible &&
                    <InvisiblePN />
                }
                {
                    !printable &&
                    <InvisiblePDF />
                }
            </span>
        </PlugTooltip>
    );
    else return null
}



