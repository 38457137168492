import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import FormatTableMultipleConstructorComponent from './FormatTableMultipleConstructorComponent';
import { Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@material-ui/core";
import colors from '../../assets/themes/Colors';
import { useTranslation } from 'react-i18next';
import FormatExtDataConstructorComponent from './FormatExtDataConstructorComponent';
import { ReactComponent as Extdata } from '../../assets/images/format-icon/svg/extdata.svg';
import { getColumnLabel, RenderMaskList } from './FormatSMailValidMultiConstructorComponent';
import { isDefined } from '../../utils/utils';
import { BackupTable, EditValueIcon } from '../custom/icons/CustomIcons';
import FormatTableMultipleDefaultValuesEditorComponent from './tablemulti/FormatTableMultipleDefaultValuesEditorComponent';
import {PlugTooltip} from "../custom/CustomComponents";
import ModalHelpComponent from "../modals/ModalHelpComponent";

const useStyles = makeStyles(() => ({
  mainField : {
      minWidth: '100%',
      display: 'flex',
      flexDirection : 'column'
  },
  subfield : {
      scrollMarginTop: '100px',
      width : '100%',
  },
  //Table
    tableCtn: {
      marginTop: -24,
  },
  table: {
      '@media(max-width: 1024px)':{
          '& .MuiTableCell-root': {
              padding: 8
          }
      }
  },
  tableHeader: {
      '& .MuiTableCell-root': {
          padding: '8px 30px !important',
      },
  },
  headerTxt: {
      fontSize: 18,
  },
  secondColCell: {
      '& .MuiTableCell-root': {
          borderLeft: '1px solid #cccccc',
      },
      borderLeft: '1px solid #cccccc',
  },
  // Tabs
  tabsWrapper: {
      display: 'flex',
      flexDirection: 'row',
  },
  tabsCtn: {
      '& .MuiTab-root': {
          textTransform: 'none !important',
      },
      '& .MuiTabs-indicator': {
          top: 0,
          backgroundColor: colors.main,
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
          color: colors.main,
          fontWeight: 700,
      },
      '& div.MuiTabs-scroller.MuiTabs-fixed': {
          overflow: 'auto !important',
      }
  },
  tab: {
      // paddingTop: -20,
  },
  tabIcon: {
    height: 24,
    width: 24,
  },
  unselectedTab: {
      backgroundColor: colors.greyLight,
  },
  voidSpace: {
      flex: 1,
      background: `linear-gradient(90deg, ${colors.greyLight}, transparent)`,
      opacity: 0.7,
  },
}))

const FormatTableMultiplenConstructorComponent = (props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [tabNb, setTabNb] = useState(0);
  const [dataRows, setDataRows] = useState(props.field.formatoptions.lines);
  const [openModalHelp, setOpenModalHelp] = useState(false);


    const handleChangeTab = (_, nb) => {
    setTabNb(nb);
    if (nb === 0) { // ouvrir le Drawer
      props.setOpenDrawer(true)
    }
  }

    const handleOpenHelp = () => {
        setOpenModalHelp(true);
    }

    const handleModalHelpClose = () => {
        setOpenModalHelp(false);
    }

  const voidAction = () => { }

  return (
    <div className={classes.mainField}>
      <div className={classes.tabsWrapper}>
        <div className={classes.tabsCtn} >
          <Tabs
            onChange={handleChangeTab}
            textColor="inherit"
            value={tabNb}
          >
            <Tab icon={<Extdata fill={tabNb === 0 ? colors.main : colors.coal} height={24} width={24} />}
              disableRipple
              label={'1. ' + t('smailvalidmulti.config-extdata')} className={`${classes.tab} ${tabNb !== 0 && classes.unselectedTab}`} />
            <Tab
              disableRipple
              icon={<BackupTable />} label={'2. ' + t('smailvalidmulti.config-table')} className={`${classes.tab} ${tabNb !== 1 && classes.unselectedTab}`} />
          {/* <Tab
              disableRipple
              icon={<EditValueIcon color={tabNb === 2 ? colors.main : colors.coal}/>} label={'3. ' + t('helper.default-values')} className={`${classes.tab} ${tabNb !== 2 && classes.unselectedTab}`} /> */}
          </Tabs>
        </div>
          <span className={classes.voidSpace + " " + "flex_display_align_items_justify_content_center"}>
              <PlugTooltip title={t('common:global.help-manuels')}>
                  <span id="icon-more" className="cursor font_size_15 text-span-round" onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleOpenHelp(event)
                  }}>
                      ?
                  </span>
              </PlugTooltip>
          </span>
      </div>
      {
        tabNb === 0 &&
        <div className={classes.tabCtn} >
          <div className={classes.subfield}>
            <FormatExtDataConstructorComponent
              field={props.field}
              handleEditLabel={props.handleEditLabel}
              handleOpenCloseSmail={props.handleOpenCloseSmail}
              index={props.index}
              openSMail={props.openSMail}
              setUpdateModel={props.setUpdateModel}
              updateModel={props.updateModel}
              update={props.update}
            />
            <TableContainer component={Paper} className={classes.tableCtn} style={{ width: '100%' }}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHeader}  >
                  <TableRow>
                    <TableCell align="left" className={classes.headerTxt} colSpan={dataRows.length > 5 ? 9 : 4}>Field List</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    dataRows.length > 0 &&
                    <React.Fragment>
                      {
                        dataRows
                        .slice(...(dataRows.length > 5 ? [0, Math.ceil(dataRows.length / 2)] : []))
                        .map((row, index) => {
                          let index2 = Math.ceil(index + (dataRows.length / 2))
                          return (
                            <React.Fragment key={index}>
                              <TableRow >
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">
                                  <span>{row.label}</span>
                                </TableCell>
                                <TableCell align="center">
                                  <span>{row.formatLabel}</span>
                                </TableCell>
                                <TableCell className={classes.configCell}>
                                  <RenderMaskList {...props}
                                    open={null}
                                    openRow={null}
                                    row={dataRows[index]}
                                    index={index}
                                    rows={[]}
                                    isPreview
                                    handleUpdateMask={voidAction}
                                    handleUpdateType={voidAction}
                                    handleToggleSubConfig={voidAction}
                                  />
                                </TableCell>
                                {
                                  dataRows.length > 5 &&
                                  <TableCell colSpan={1} />
                                }
                                {dataRows.length > 5 &&
                                (isDefined(dataRows[index2]) ?
                                  <React.Fragment>
                                    <TableCell align="center" className={classes.secondColCell} >{index2 + 1}</TableCell>
                                    <TableCell align="center">
                                      <span>{getColumnLabel(dataRows[index2].label)}</span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span>{dataRows[index2].formatLabel}</span>
                                    </TableCell>
                                    <TableCell className={classes.configCell}>
                                      <RenderMaskList {...props}
                                        open={null}
                                        openRow={null}
                                        row={dataRows[index2]}
                                        index={index2}
                                        rows={[]}
                                        isPreview
                                        handleUpdateMask={voidAction}
                                        handleUpdateType={voidAction}
                                        handleToggleSubConfig={voidAction}
                                      />
                                    </TableCell>
                                  </React.Fragment>
                                  :
                                  <TableCell colSpan={5} className={classes.secondColCell} />)
                                }
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  }
                </TableBody >

              </Table>
            </TableContainer>
          </div>
        </div>
      }

      {
        tabNb === 1 &&
        <div className={classes.tabCtn} >
          <FormatTableMultipleConstructorComponent
            field={props.field}
            folderName={props.folderName}
            handleEditLabel={props.handleEditLabel}
            index={props.index}
            newModel={props.newModel}
            onChangeHandlerDrop={props.onChangeHandlerDrop}
            plugnoteCount={props.plugnoteCount}
            setNewModel={props.setNewModel}
            setDataRows={setDataRows}
            setUpdateModel={props.setUpdateModel}
            updateModel={props.updateModel}
            update={props.update}
          />
        </div>
      }
      {
        tabNb === 2 &&
        <div className={classes.tabCtn} >
          <FormatTableMultipleDefaultValuesEditorComponent
            field={props.field}
            model={isDefined(props.newModel) ? props.newModel : props.updateModel}
            setRows={setDataRows}
            setModel={isDefined(props.setNewModel) ? props.setNewModel : props.setUpdateModel}
          />
        </div>
      }
        {
            openModalHelp  ?
                <ModalHelpComponent open={openModalHelp} close={handleModalHelpClose} from="table"/>
                :
                null
        }
    </div>
  );
};

export default FormatTableMultiplenConstructorComponent;
