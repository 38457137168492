import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import {
  delTableView,
  getAnnexes,
  getCurrentEnvironnement,
  getModel,
  getPlugnote,
  getToken,
  getUser,
  setAnnexes,
  setPlugnote,
} from "../../utils/Common";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileImage,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faStickyNote,
} from "@fortawesome/free-regular-svg-icons";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  delDeleteDataRequest, getDataUniqueRequest,
  putUpdateDataArchiveRequest,
  putUpdateDataFavorisRequest,
  putUpdateDataLockRequest,
  putUpdateSharedDataRequest,
  putUpdateSharedDataWithoutRedirectRequest,
} from "../../actions/data";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import UserIcon from "@material-ui/icons/PeopleAlt";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import { Content, Header } from "rsuite";
import Grid from "@material-ui/core/Grid";
import {
  delDeleteAnnexeRequest,
  getAnnexesVersionRequest,
  postAddNoteRequest,
  postAnnexesSharedRequest,
  postAnnexesVersionRequest,
  postAnnexesVersionSharedRequest,
  putEditNoteRequest,
} from "../../actions/annexe";
import ApplicationConfig from "../../config/ApplicationConfig";
import { trackPromise } from "react-promise-tracker";
import _, { camelCase, isString } from "lodash";
import { useTranslation } from "react-i18next";
import Immutable from "seamless-immutable";
import moment from "moment";
import Images from "../../assets/themes/Images";
import PhoneInput from "react-phone-number-input";
import FormatDateComponent from "../../components/formats/FormatDateComponent";
import FormatAgendaComponent from "../../components/formats/FormatAgendaComponent";
import FormatNowComponent from "../../components/formats/FormatNowComponent";
import FormatBirthdayComponent from "../../components/formats/FormatBirthdayComponent";
import FormatTimeComponent from "../../components/formats/FormatTimeComponent";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import FormatMultipleSelectComponent from "../../components/formats/FormatMultipleSelectComponent";
import FormatSingleSelectComponent from "../../components/formats/FormatSingleSelectComponent";
import FormatKeywordComponent from "../../components/formats/FormatKeywordComponent";
import FormatBooleanComponent from "../../components/formats/FormatBooleanComponent";
import FormatPlugcodeComponent from "../../components/formats/FormatPlugcodeComponent";
import FormatPasswordComponent from "../../components/formats/FormatPasswordComponent";
import InputLabel from "@material-ui/core/InputLabel";
import FormatLocalisationComponent from "../../components/formats/FormatLocalisationComponent";
import ModalAddAnnexeCommentComponent from "../../components/modals/ModalAddAnnexeCommentComponent";
import Tooltip from "@material-ui/core/Tooltip";
import ModalNoteComponent from "../../components/modals/ModalNoteComponent";
import {
  ChildButton,
  FloatingMenu,
  MainButton,
} from "react-floating-button-menu";
import { MdAdd, MdAttachFile, MdClose, MdNoteAdd } from "react-icons/md";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormatMemoComponent from "../../components/formats/FormatMemoComponent";
import Lightbox from "react-image-lightbox";
import ModalPlugnotePlugcodeComponent from "../../components/modals/ModalPlugnotePlugcodeComponent";
import { reduxGeneratePlugnotecodeReset } from "../../actions/plugcode";
import { confirmAlert } from "react-confirm-alert";
import ModalListAnnexesVersionComponent from "../../components/modals/ModalListAnnexesVersionComponent";
import Dropzone from "react-dropzone";
import FormatPhoneComponent from "../../components/formats/FormatPhoneComponent";
import FormatMailComponent from "../../components/formats/FormatMailComponent";
import FormatUrlComponent from "../../components/formats/FormatUrlComponent";
import HeaderSearch from "../../components/searchs/HeaderSearch";
import ModalListAnnexesComponent from "../../components/modals/ModalListAnnexesComponent";
import FormatMultipleSelectTableComponent from "../../components/formats/FormatMultipleSelectTableComponent";
import FormatSingleSelectTableComponent from "../../components/formats/FormatSingleSelectTableComponent";
import FormatPlugformComponent from "../../components/formats/FormatPlugformComponent";
import { isJsonString } from "../../utils/utils";
import FormatPlugLinkComponent from "../../components/formats/FormatPlugLinkComponent";
import ModalPrintComponent from "../../components/modals/ModalPrintComponent";
import FormatFormulaComponent from "../../components/formats/FormatFormulaComponent";
import FormatGeoLocalisationComponent from "../../components/formats/FormatGeoLocalisationComponent";
import { usePromiseTracker } from "react-promise-tracker";


import Plugforms from "../../components/plugforms/Plugforms";
import parse from "html-react-parser";
import ModalHelpComponent from "../../components/modals/ModalHelpComponent";
import {Help} from "@material-ui/icons";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "70px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  openClose: {
    bottom: 0,
    // position: 'absolute',
    width: "100%",
  },
  openCloseRelative: {
    marginTop: 5,
    bottom: 0,
    position: "relative",
    width: "100%",
  },
  openCloseButton: {
    float: "right",
    marginRight: "10px",
  },
  openedCloseButton: {
    float: "right",
    marginRight: "10px",
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  contentModel: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: "scroll",
    maxHeight: "calc(100vh - 200px)",
  },
  contentModelMain: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icon: {
    width: 56,
    height: 56,
    lineHeight: "56px",
    textAlign: "center",
    color: "black",
  },
  iconActive: {
    width: 56,
    height: 56,
    lineHeight: "56px",
    textAlign: "center",
    color: "blue",
  },
  gridHeader: {
    padding: "10px 20px !important",
    width: "100%",
  },
  selectEnvironnement: {},
  fieldImage: {
    height: 10,
    width: 10,
  },
  fieldIcon: {
    width: 20,
    height: 20,
    backgroundColor: "#57bbb8",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px",
  },
  fieldIconMandatory: {
    width: 20,
    height: 20,
    backgroundColor: "#57bbb8",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid red",
    marginRight: "5px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  field: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  fieldLeft: {
    alignContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },
  fieldIconLeft: {
    alignContent: "flex-start",
    display: "flex",
    alignItems: "center",
    width: "40px",
  },
  fieldRight: {
    width: "100%",
  },
  fieldRightLeft: {
    alignContent: "flex-end",
  },
  fullWidth: {
    width: "100%",
  },
  card: {
    maxWidth: 345,
  },
  fieldImageSvg: {
    width: 25,
    height: 25,
  },
  media: {
    height: 140,
  },
  paperAnchorDockedLeft: {
    boxShadow: "0px 0px 6px 4px rgba(136, 136, 136, 0.12)",
    border: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  listHeight: {
    height: "100%",
  },
  faIconSize: {
    fontSize: 20,
    color: "#51C3C1",
  },
  faIconView: {
    fontSize: 200,
    color: "#51C3C1",
  },
  modalFile: {
    border: "none",
    width: "100%",
    height: "100%",
    minWidth: '400px',
    "@media (min-width:1025px)":{
        minWidth: '60vw',
    },
  },
  confirmContainer: {
    border: "1px solid #F9F9F9",
    backgroundColor: "white",
    borderRadius: 15,
    padding: "15px",
  },
  confirmTitle: {
    color: "black",
    fontSize: "30px",
  },
  confirmText: {
    color: "#88949C",
  },
  confirmButtons: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  confirmButtonStyle: {
    width: "100px",
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      /* width: "100%", */
    },
  },
  confirmAffirmative: {
    color: "white",
    backgroundColor: "#58c3c1",
    border: "none",
  },
  confirmNegative: {
    color: "#E3E3E3",
    backgroundColor: "transparent",
    border: "2px solid #E3E3E3",
    fontWeight: "600",
  },
  notRender: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
  },
  notRenderText: {
    fontWeight: 'bold',
    color: '#88949C',
  },
}));
const modalStyles = { overlay: { zIndex: 10000 } };
const modalStylesClosed = { overlay: { zIndex: 0 } };

const authorisedToEdit = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "pages",
  "csv",
  "odt",
  "ods",
  "pptx",
  "ppt",
];
const zeroPad = (num, places) => String(num).padStart(places, "0");

const specialFields = [
  "plugform",
  "textarea",
  "list",
  "single-select",
  "free-single-select",
  "multi-select",
  "free-multi-select",
  "single-table",
  "free-single-table",
  "multi-table",
  "free-multi-table",
  "qrcode",
  "localis",
  "glocalis",
];

function PlugnoteSharedUpdateV2(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["common", "models"]);

  const plugnote = getPlugnote();

  const { promiseInProgress } = usePromiseTracker();
  const [updateExternalDb, setUpdateExternalDb] = React.useState(false);
  const [deleteExternalDb, setDeleteExternalDb] = React.useState(false);


  const inputFile = useRef(null);
  const inputUpdateFile = useRef(null);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [cancel, setCancel] = React.useState(false);

  const [isOpenModalAnnexe, setIsOpenModalAnnexe] = React.useState(false);
  const [selectedAnnexe, setSelectedAnnexe] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [openNote, setOpenNote] = React.useState(false);
  const [isOpenFloatingMenu, setIsOpenFloatingMenu] = React.useState(false);
  const [isOpenModalListAnnexesVersion, setIsOpenModalListAnnexesVersion] =
      React.useState(false);
  const [isOpenModalListAnnexes, setIsOpenModalListAnnexes] =
      React.useState(false);
  const [isOpenModalPrint, setIsOpenModalPrint] = React.useState(false);

  const [openModalPreview, setOpenModalPreview] = React.useState(false);
  const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
  const [images, setImages] = useState([null]);

  const [openModalHelp, setOpenModalHelp] = useState(false);


  const [dataHasChanged, setDataHasChanged] = React.useState(false);

  const environment = getCurrentEnvironnement();

  const model = getModel();

  const modelForHash = getModel();

  const dataForHash = getPlugnote();

  const [fields, setFields] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedUpdateFiles, setSelectedUpdateFiles] = useState(null);

  const user = getUser();
  const token = getToken();

  const plugIndex =
      "Plugnote " + environment.envIndex + "-" + plugnote.userindex;
  const [fullNamePlugnote, setFullNamePlugnote] = useState(
      modelForHash !== null ? plugIndex : null
  );

  const [plugnoteAnnexes, setPlugnotesAnnexes] = useState(
      typeof props.annexes !== "undefined" && props.annexes.length > 0
          ? props.annexes
          : []
  );
  //const [plugnoteAnnexes, setPlugnotesAnnexes] = useState( getAnnexes());
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(0);
  const [locked, setLocked] = useState(plugnote.locked);
  const [favorite, setFavorite] = useState(plugnote.favorite);
  const [archived, setArchived] = useState(plugnote.archived);

  const [isOpenModalPlugnotePlugcode, setIsOpenModalPlugnotePlugcode] = React.useState(false);
  const [visibleAll, setVisibleAll] = useState(
      typeof model.fieldEmptyVisible !== "undefined"
          ? model.fieldEmptyVisible === 1
          : false
  );

  const [nameModel, setNameModel] = useState(modelForHash !== null ? modelForHash.label : ""
  );
  const [hashOwnerModel, setHashOwnerModel] = useState(modelForHash !== null ? modelForHash.mainOwner : ""
  );

  const [fileInputKey, setFileInputKey] = useState(Math.random().toString(36));
  const [fileUpdateInputKey, setFileUpdateInputKey] = useState(
      Math.random().toString(36)
  );


  /**
   * Quand l'utilisateur veut éditer un champ
   * @param value
   * @param field
   * @param index
   * @private
   */

  const handleModalAnnexeClose = () => {
    setSelectedAnnexe(null);
    setIsOpenModalAnnexe(false);
  };

  const handleModalAnnexeOpen = (event, annexe) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedAnnexe(annexe);
    setIsOpenModalAnnexe(true);
  };

  const handleEditValue = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleEditPhoneValue = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleEditDateTime = (date, value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;

    listFields[index].visuelValue = date;

        setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleClickCancelModal = () => {
    props.history.push("/pluglist-shared");
    setOpenModal(false);
  };

  const handleClickContinueModal = () => {
    setOpenModal(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleModalPrintOpen = () => {
    setIsOpenModalPrint(true);
  };

  const _handleCloseModalPrint = () => {
    setIsOpenModalPrint(false);
  };

  const handleExitUpdatePlugnote = () => {
    props.history.push("/pluglist-shared");

    /*        if(model.ms_edit_fiche === 0 || plugnote.locked){
            props.history.push("/pluglist-shared");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('plugnote.confirm-save'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () =>  handleSaveUpdatePlugnote()
                    },
                    {
                        label: t('global.no'),
                        onClick: () =>  props.history.push("/pluglist-shared")
                    }
                ]
            });
        }*/
  };

  const checkData = (fields) => {
    return fields.some((field) => {
      if (field.format !== null && typeof field.format !== "undefined") {
        if (!!field.mandatory && field.mandatory === 1) {
          if (field.format.includes("select")) {
            return field.value.length === 0;
          } else if (field.format === "list") {
            return (
                (!field.value ||
                    field.value === "[]" ||
                    field.value.length === 0) &&
                field.format != "indice"
            );
          } else if (field.format === "boolean") {
            return field.value === "";
          } else {
            return !field.value && field.format != "indice";
          }
        }
      }
    });
  };

  /**
   * Construction de la nouvelle fiche à partir des champs
   * @param fields
   */
  const buildUpdateAnnexe = (fields) => {
    let newData = getPlugnote();

    const modelForHash = getModel();

    fields.map((field) => {
      if (
          field.format === "multi-select" ||
          field.format === "free-multi-select" ||
          field.format === "single-select" ||
          field.format === "free-single-select"
      ) {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          newData["field" + field.id] = JSON.stringify(field.value);
        } else {
          newData["field" + field.id] = JSON.stringify([field.value]);
        }
      } else if (
          field.format === "multi-table" ||
          field.format === "free-multi-table" ||
          field.format === "single-table" ||
          field.format === "free-single-table"
      ) {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          newData["field" + field.id] = JSON.stringify(field.value);
        } else {
          newData["field" + field.id] = JSON.stringify([field.value]);
        }
      } else if (field.format === "list") {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          let correctValue = [];
          field.value.map((item, index) => {
            correctValue.push({ text: item });
          });
          newData["field" + field.id] = JSON.stringify(correctValue);
        } else {
          let transformValue = [field.value];
          let correctValue = [];
          transformValue.map((item, index) => {
            correctValue.push({ text: item });
          });
          newData["field" + field.id] = correctValue;
        }
      } else if (field.format === "plugform") {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if (field.format === "qrcodeint") {
        if (field.value.length === 0) {
          newData["field" + field.id] = JSON.stringify({"code" : "", "model" : "", "data" : "", "comment" : ""});
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }else if (field.format === "intdatamaj") {
        if (field.value.length === 0) {
          newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if (field.format === "intdatamaj") {
        if (field.value.length === 0) {
          newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if(field.format === "tablemulti") {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if(field.format === "tablemultin") {
        if (field.value.length === 0) {
          newData["field" + field.id] = {"value" : "", "list" : []};
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if(field.format === "document") {
        if (field.value.length === 0) {
          newData["field" + field.id] = {"value" : "", "list" : []};
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if(field.format === "smailvalidmulti") {
        if (field.value.length === 0) {
          newData["field" + field.id] = {"value" : "", "list" : []};
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else {
        if (Array.isArray(field.value) && field.value.length === 0) {
          newData["field" + field.id] = "";
        } else {
          newData["field" + field.id] = field.value;
        }
      }

      if (
          field.format === "multi-select" ||
          field.format === "free-multi-select" ||
          field.format === "single-select" ||
          field.format === "free-single-select"
      ) {
        newData["field" + field.id + "format"] = "select";
      } else if (
          field.format === "multi-table" ||
          field.format === "free-multi-table" ||
          field.format === "single-table" ||
          field.format === "free-single-table"
      ) {
        newData["field" + field.id + "format"] = "table";
      } else {
        newData["field" + field.id + "format"] = field.format;
      }

      if (modelForHash && field.format === "indice") {
        let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
        if(!editable && field.value === "" || (editable && field.value === "")){
          newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
        }
      }

      if (
          (field.format && field.format === "agenda") ||
          (field.format && field.format === "birthday")
      ) {
        newData["field" + field.id + "options"] = field.event;
      }

      newData["field" + field.id + "label"] = field.title;
    });
    newData.Annexes = [];
    newData.lastversion_model = modelForHash ? modelForHash.version : null;
    newData.modid = modelForHash.id;
    newData.offline = modelForHash.offline;

    return newData;
  };

  const renderAnnexeIconPreview = (annexeToPreview) => {
    switch (annexeToPreview.type.toLowerCase()) {
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "PNG":
      case "gif":
        /* return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>; */
        return (
            <FontAwesomeIcon icon={faFileImage} className={classes.faIconSize} />
        );
        break;

      case "mp4":
      case "mkv":
      case "avi":
      case "mov":
        return (
            <FontAwesomeIcon icon={faFileVideo} className={classes.faIconSize} />
        );
        break;

      case "pdf":
        return (
            <FontAwesomeIcon
                icon={faFilePdf}
                className={classes.faIconSize}
                onClick={(event) =>
                    handlePreviewPdfAnnexe(
                        event,
                        getUrlPreviewAnnexe(annexeToPreview)
                    )
                }
            />
        );
        break;

      case "zip":
      case "rar":
        return (
            <FontAwesomeIcon
                icon={faFileArchive}
                className={classes.faIconSize}
            />
        );
        break;

      case "mp3":
      case "aac":
        return (
            <FontAwesomeIcon icon={faFileAudio} className={classes.faIconSize} />
        );
        break;

      case "html":
        return (
            <FontAwesomeIcon icon={faFileCode} className={classes.faIconSize} />
        );
        break;

      case "txt":
      case "sheet":
      case "doc":
      case "docx":
        return (
            <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
        );
        break;

      case "xls":
      case "xlsx":
      case "csv":
        return (
            <FontAwesomeIcon icon={faFileExcel} className={classes.faIconSize} />
        );
        break;

      case "note":
        return (
            <FontAwesomeIcon icon={faStickyNote} className={classes.faIconSize} />
        );
        break;

      case "pages":
        return (
            <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
        );
        break;

      case "pptx":
      case "ppt":
        return (
            <FontAwesomeIcon
                icon={faFilePowerpoint}
                className={classes.faIconSize}
            />
        );
        break;
      default:
        return <FontAwesomeIcon icon={faFile} className={classes.faIconSize} />;
        break;
    }
  };

  const renderAnnexeImagePreview = (annexeToPreview) => {
    switch (annexeToPreview.type.toLowerCase()) {
      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'png':
      case 'PNG':
      case 'gif':
        /* case 'jfif': */
        return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} /* className={classes.modalFile} *//>;
        break;

      case 'mp4':
      case 'mkv':
      case 'avi':
      case 'mov':
        return <video className={classes.modalFile} controls><source  src={getUrlPreviewAnnexe(annexeToPreview)} type={`video/${annexeToPreview.type.toLowerCase()}`} /></video>;
        break;

      case 'pdf':
        return <iframe src={getUrlPreviewAnnexe(annexeToPreview)} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
        break;

      case 'zip':
      case 'rar':
        return <div className={classes.notRender}><FontAwesomeIcon icon={faFileArchive} className={classes.faIconView}  /><p class={classes.notRenderText}>{t("multiple.file-not-previewed")}</p></div>;
        break;

      case 'mp3':
      case 'aac':
        return <audio className={classes.modalFile} controls><source  src={getUrlPreviewAnnexe(annexeToPreview)} type={`audio/${annexeToPreview.type.toLowerCase()}`} /></audio>;
        break;

      case 'html':
        return <iframe src={getUrlPreviewAnnexe(annexeToPreview)} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
        break;

      case 'txt':
        return <iframe src={getUrlPreviewAnnexe(annexeToPreview)} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
        break;

      case 'sheet':
      case 'doc':
      case 'docx':
        /* return <iframe src={`https://docs.google.com/gview?url=${getUrlPreviewAnnexe(annexeToPreview)}&embedded=true`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />; */
        return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${getUrlPreviewAnnexe(annexeToPreview)}`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
        break;

      case 'xls':
      case 'xlsx':
      case 'csv':
        return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${getUrlPreviewAnnexe(annexeToPreview)}`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
        break;

      case 'note':
        return <div className={classes.noteRenderText}>
          {/* {t("multiple.file-not-previewed")} */}
          {parse(annexeToPreview.Webdoc.content)}
          <span
              id="icon-file_copy"
              className={`material-icons md-25 ${classes.noteIcon}`}
              onClick={(event) =>
                  handleNoteOpen(event, annexeToPreview)
              }>
                    create
                    </span>
        </div>;
        break;

      case 'pages':
        return <div className={classes.notRender}><FontAwesomeIcon icon={faFileWord} className={classes.faIconSize}  /><p class={classes.notRenderText}>{t("multiple.file-not-previewed")}</p></div>;
        break;

      case 'pptx':
      case 'ppt':
        return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${getUrlPreviewAnnexe(annexeToPreview)}`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
        break;
      default :
        return <div className={classes.notRender}><FontAwesomeIcon icon={faFile} className={classes.faIconView}/><p class={classes.notRenderText}>{t("multiple.file-not-previewed")}</p></div>;
        break;
    }
  };

  const handleClickAlertWithCancel = () => {
    setCancel(true);
    setOpenModal(true);
  };

  const handleClickAlertWithoutCancel = () => {
    setCancel(false);
    setOpenModal(true);
  };

  const handleSaveUpdatePlugnote = () => {
    delTableView()
    if (dataHasChanged) {
      if (checkData(fields)) {
        if (selectedFiles !== null) {
          handleClickAlertWithCancel();
        } else {
          handleClickAlertWithoutCancel();
        }
      } else {
        const modelForHash = getModel();

        //this._handleSaveAgendaToCalendar();

        let dataForHash = buildUpdateAnnexe(fields);

        props.attemptUpdateData(
            modelForHash.hash,
            environment.userhash,
            dataForHash
        );

        // if (isNewdata) {
        //
        // }
      }
    } else {
      if (checkData(fields)) {
        if (selectedFiles !== null) {
          handleClickAlertWithCancel();
        } else {
          handleClickAlertWithoutCancel();
        }
      } else {
        props.history.push("/pluglist-shared");
      }
    }
  };

  const handleSavePlugnote = () => {
    if (dataHasChanged) {
      if (checkData(fields)) {
        if(selectedFiles !== null){
          handleClickAlertWithCancel();
        }else{
          handleClickAlertWithoutCancel();
        }
      }
      else{
        setDataHasChanged(false);
        const modelForHash = getModel();
        let dataForHash = buildUpdateAnnexe(fields);

        props.attemptUpdateDataWithoutRedirect(
            modelForHash.hash,
            environment.userhash,
            dataForHash);

      }
    }
  }

  const handleUpdateExportedPlugnote = () => {
    setDataHasChanged(false);
    const modelForHash = getModel();
    let dataForHash = buildUpdateAnnexe(fields);

    props.attemptUpdateDataWithoutRedirect(
        modelForHash.hash,
        environment.userhash,
        dataForHash);

    setUpdateExternalDb(true);
  }


  const handleDeleteExportedPlugnote = () => {
    setDataHasChanged(false);
    const modelForHash = getModel();
    let dataForHash = buildUpdateAnnexe(fields);

    dataForHash.isexported = false;

    props.attemptUpdateDataWithoutRedirect(modelForHash.hash, environment.userhash, dataForHash);

    setDeleteExternalDb(true);

  }

  useEffect(() => {

    if(promiseInProgress === false && updateExternalDb){
      setUpdateExternalDb(false);
      if(props.userid !== null){

        let bodyFormData = new FormData();
        bodyFormData.append('uid', props.userid);
        bodyFormData.append('hash',  getToken());
        bodyFormData.append('dh',  props.plugnote.hash);
        bodyFormData.append('mh',  modelForHash.hash);
        bodyFormData.append('owner',  modelForHash.owner);
        bodyFormData.append('shared', true);

        confirmAlert({
          title: i18n.t('common:global.plugnotes'),
          message: i18n.t('common:tables.row-automatisation'),
          buttons: [
            {
              label: i18n.t('common:global.ok'),
              onClick: () => {
                axios({
                  method: "post",
                  url: ApplicationConfig.backop + 'process_exported_update_customer_row_table.php',
                  data: bodyFormData,
                  headers: {
                    "Content-Type": "multipart/form-data" ,
                    "Access-Control-Allow-Origin": "*"
                  },
                })
                    .then(function (response) {
                      //handle success
                      //console.log(response);
                    })
                    .catch(function (response) {
                      //handle error
                      //console.log(response);
                    });
              }
            },
            {
              label: i18n.t('common:global.cancel')
            },
          ]
        });
      }
      else{
        confirmAlert({
          title: i18n.t('common:global.plugnotes'),
          message: i18n.t('common:global.administrator-error'),
          buttons: [
            {
              label: i18n.t('common:global.ok'),
            }
          ]
        });
      }
    }else if(promiseInProgress === false && deleteExternalDb){
      setDeleteExternalDb(false);
      if(props.userid !== null){

        let bodyFormData = new FormData();
        bodyFormData.append('uid', props.userid);
        bodyFormData.append('hash',  getToken());
        bodyFormData.append('dh',  props.plugnote.hash);
        bodyFormData.append('mh',  modelForHash.hash);
        bodyFormData.append('owner',  modelForHash.owner);
        bodyFormData.append('shared', true);

        confirmAlert({
          title: i18n.t('common:global.plugnotes'),
          message: i18n.t('common:tables.row-automatisation'),
          buttons: [
            {
              label: i18n.t('common:global.ok'),
              onClick: () => {
                axios({
                  method: "post",
                  url: ApplicationConfig.backop + 'process_exported_delete_customer_row_table.php',
                  data: bodyFormData,
                  headers: {
                    "Content-Type": "multipart/form-data" ,
                    "Access-Control-Allow-Origin": "*"
                  },
                })
                    .then(function (response) {
                      //handle success
                      //console.log(response);
                    })
                    .catch(function (response) {
                      //handle error
                      //console.log(response);
                    });
              }
            },
            {
              label: i18n.t('common:global.cancel')
            },
          ]
        });
      }
      else{
        confirmAlert({
          title: i18n.t('common:global.plugnotes'),
          message: i18n.t('common:global.administrator-error'),
          buttons: [
            {
              label: i18n.t('common:global.ok'),
            }
          ]
        });
      }
    }

  },[promiseInProgress , updateExternalDb, deleteExternalDb])




  const handleChangeTextField = () => {};

  const handleChangeCheckBox = (event, field, index, multiselect) => {};

  const handleChangeRadio = (value, indexation, field, index, multiselect, mandatory) => {
    let listFields = fields;

    if (mandatory) {
      if (listFields[index].value.includes(value)) {
        listFields[index].value = listFields[index].value.filter(
            (item) => item !== value
        );
      } else {
        if (!multiselect) {
          listFields[index].value = [];
          listFields[index].value.push(value);
        } else {
          listFields[index].value.push(value);
        }
      }
    } else {
      if (listFields[index].value.includes(value)) {
        listFields[index].value = listFields[index].value.filter(
            (item) => item !== value
        );
      } else {
        if (!multiselect) {
          listFields[index].value = [];
          listFields[index].value.push(value);
        } else {
          listFields[index].value.push(value);
        }
      }
    }

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const includesInJson = (search, value) => {
    return search.findIndex((item, index) => {
      return item.text === value;
    });
  };

  const handleChangeRadioTable = (
      value,
      indexation,
      field,
      index,
      multiselect,
      mandatory
  ) => {
    let listFields = fields;

    if (mandatory) {
      if (!multiselect) {
        listFields[index].value = [];
        listFields[index].value.push({ text: value, value: 1 });
      } else {
        listFields[index].value.push({ text: value, value: 1 });
      }
    } else {
      if (includesInJson(listFields[index].value, value) !== -1) {
        listFields[index].value = listFields[index].value.filter(
            (item) => item.text !== value
        );
      } else {
        if (!multiselect) {
          listFields[index].value = [];
          listFields[index].value.push({ text: value, value: 1 });
        } else {
          listFields[index].value.push({ text: value, value: 1 });
        }
      }
    }

    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleChangeInputTable = (value, indexation, field, index, multiselect, mandatory) => {
    let listFields = fields;

    if (mandatory) {
      if (!multiselect) {
        listFields[index].value = [];
        listFields[index].value.push({ text: value.text, value: value.value });
      } else {
        if (includesInJson(listFields[index].value, value.text) === -1) {
          listFields[index].value.push({
            text: value.text,
            value: value.value,
          });
        } else {
          listFields[index].value[
              includesInJson(listFields[index].value, value.text)
              ].value = value.value;
        }
      }
    } else {
      if (!multiselect) {
        listFields[index].value = [];
        listFields[index].value.push({ text: value.text, value: value.value });
      } else {
        if (includesInJson(listFields[index].value, value.text) === -1) {
          listFields[index].value.push({
            text: value.text,
            value: value.value,
          });
        } else {
          listFields[index].value[
              includesInJson(listFields[index].value, value.text)
              ].value = value.value;
        }
      }
    }

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleUpdateInputTable = (
      value,
      indexation,
      field,
      index,
      multiselect,
      mandatory
  ) => {
    let listFields = fields;
    let tmpValue = listFields[index].value;

    listFields[index].value = tmpValue.filter((item) =>
        value.includes(item.text)
    );

    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleDeleteInputTable = (value, indexation, field, index, multiselect, mandatory) => {
    let listFields = fields;
    let tmpValue = listFields[index].value;

    listFields[index].value = tmpValue.filter((item) => {
      return !item.text.includes(value)
    });

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangeKeyword = (value, indexation, field, index) => {
    let listFields = fields;
    if (listFields[index].value.includes(value)) {
      listFields[index].value = listFields[index].value.filter(
          (item) => item !== value
      );
    } else {
      listFields[index].value.push(value);
    }

    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleChangeBoolean = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleChangePlugcode = (plugcode, comment, field, index) => {
    let listFields = fields;
    listFields[index].value = plugcode + ";" + comment;
    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleChangePlugcodeInternal = (plugcode, comment, field, index, destinations) => {
    let listFields = [...fields];

    listFields[index].value = plugcode;

    if (destinations?.length > 0) {
      let updatedListFields =  listFields.map((field, index) => {
        if(!!field.id){
          if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
            let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
            switch (field.format) {
              case 'numeric':
                let tmpValue = destination.value.replaceAll(",", ".");
                return {...field, value : tmpValue, visuelValue :  tmpValue}
              default:
                return {...field, value : destination.value, visuelValue :  destination.value}
            }
          }
        }
        return field;
      });

      setFields(updatedListFields);
      setDataHasChanged(true);
    }else{
      setFields(listFields);
      setDataHasChanged(true);
    }
  }

  const handleChangePassword = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields(listFields);
    setDataHasChanged(true);
  };

  const handlePreviewPdfAnnexe = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, "_blank");
    //setOpenPdfPreview(true);
    //setSelectedPreviewPdfAnnexe(url);
  };

  const handleChangePlugform = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleChangeIntDataMaj = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields(listFields);
    setDataHasChanged(true);
  };

  const handleChangeExtData = async (destinations) => {
    let listFields = [...fields];

    if (destinations.length > 0) {
      let updatedListFields =  listFields.map((field, index) => {
        if(!!field.id){
          if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
            let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
            switch (field.format) {
              case 'numeric':
                let tmpValue = destination.value.replaceAll(",", ".");
                return {...field, value : tmpValue, visuelValue :  tmpValue}
              default:
                return {...field, value : destination.value, visuelValue :  destination.value}
            }
          }
        }
        return field;
      });

      setFields(updatedListFields);
      setDataHasChanged(true);
    }

  }


  const handleChangeLocalisation = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
        setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleUploadClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const resetFileUpdateInput = () => {
    let randomString = Math.random().toString(36);
    setFileUpdateInputKey(randomString);
  };

  const handleDeleteAnnexe = (event, annexe, previousFile) => {
    event.preventDefault();
    event.stopPropagation();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className={classes.confirmContainer}>
              <h1 className={classes.confirmTitle}>{t("global.confirm")}</h1>
              <p className={classes.confirmText}>
                {t("plugnote.confirm-delete-annexe")}
              </p>
              <div className={classes.confirmButtons}>
                <button
                    className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`}
                    onClick={onClose}
                >
                  {t("global.cancel")}
                </button>
                <button
                    className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                    onClick={() => {
                      let dataForHash = buildUpdateAnnexe(fields);
                      props.attemptDeleteAnnexe(annexe.hash, environment.userhash, modelForHash.hash,dataForHash);
                      if(typeof previousFile !== "undefined" && previousFile !== null){
                        previousFile();
                      }
                      onClose();
                    }}
                >
                  {t("global.yes")}
                </button>
              </div>
            </div>
        );
      },
    });
  };

  const _handleOpenModalListAnnexes = () => {
    setIsOpenModalListAnnexes(true);
  };

  const _handleCloseModalListAnnexes = () => {
    setIsOpenModalListAnnexes(false);
  };

  const handleDownloadFile = (annexe) => {
    saveAs(
        ApplicationConfig.downloadUrl +
        environment.userhash +
        "/" +
        plugnote.hash +
        "/" +
        annexe.name +
        "." +
        annexe.type,
        annexe.name + "." + annexe.type
    );
  };

  const handlePreviewAnnexeClose = () => {
    setOpenModalPreview(false);
    setSelectedPreviewAnnexe(null);
    setImages([]);
  };

  const handlePreviewAnnexe = (event, hash) => {
    event.preventDefault();
    event.stopPropagation();
    const images = [];
    plugnoteAnnexes.map((annexeToPreview, index) => {
      switch (annexeToPreview.type.toLowerCase()) {
        case "jpg":
        case "JPG":
        case "jpeg":
        case "png":
        case "PNG":
        case "gif":
          let url = getUrlPreviewAnnexe(annexeToPreview);
          images.push(url);
          if (hash === annexeToPreview.hash) {
            setSelectedPreviewAnnexe(images.length - 1);
          }
          break;
      }
    });

    setImages(images);
    setOpenModalPreview(true);
  };

  //      .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
  const downloadEmployeeData = (event, annexe) => {
    event.preventDefault();
    event.stopPropagation();
    // Fetch the dynamically generated excel document from the server.
    trackPromise(
        axios
            .get(
                ApplicationConfig.apiUrl + "annexe.json?annexehash=" + annexe.hash,
                {
                  crossdomain: true,
                  headers: {
                    "X-AUTH-TOKEN": getToken(),
                  },
                }
            )
            .then((response) => {
              if (response.data.result) {
                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader];
                const userindex = zeroPad(plugnote.userindex, 5); // "000005"
                const effectiveFileName =
                    suggestedFileName === undefined
                        ? "PNS-" +
                        environment.envIndex +
                        "-" +
                        userindex +
                        "-" +
                        annexe.name +
                        "-" +
                        moment().format("YYMMDDHHmmss") +
                        "." +
                        annexe.type
                        : suggestedFileName;

                var a = document.createElement("a"); //Create <a>
                a.href = response.data.base64; //Image Base64 Goes here
                a.download = effectiveFileName; //File name Here
                a.click(); //Downloaded file
              }
            })
            .catch((response) => {
              console.error(
                  "Could not Download the Excel report from the backend.",
                  response
              );
            })
    );
  };

  const getUrlPreviewAnnexe = (annexe) => {
    return (
        ApplicationConfig.downloadUrl +
        model.mainOwner +
        "/" +
        plugnote.hash +
        "/" +
        encodeURIComponent(annexe.name) +
        "." +
        annexe.type
    );
  };

  const renderAnnexePreview = (annexeToPreview) => {
    switch (annexeToPreview.type.toLowerCase()) {
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "PNG":
      case "gif":
        return (
            <img
                src={getUrlPreviewAnnexe(annexeToPreview)}
                alt={annexeToPreview.name}
                onClick={(event) =>
                    handlePreviewAnnexe(event, annexeToPreview.hash)
                }
                className={classes.pointer}
            />
        );
        break;

      case "mp4":
      case "mkv":
      case "avi":
      case "mov":
        return (
            <FontAwesomeIcon
                icon={faFileVideo}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "pdf":
        return (
            <FontAwesomeIcon
                icon={faFilePdf}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
                onClick={(event) =>
                    handlePreviewPdfAnnexe(
                        event,
                        getUrlPreviewAnnexe(annexeToPreview)
                    )
                }
                className={classes.pointer}
            />
        );
        break;

      case "zip":
      case "rar":
        return (
            <FontAwesomeIcon
                icon={faFileArchive}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "mp3":
      case "aac":
        return (
            <FontAwesomeIcon
                icon={faFileAudio}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "html":
        return (
            <FontAwesomeIcon
                icon={faFileCode}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "txt":
      case "sheet":
      case "doc":
      case "docx":
        return (
            <FontAwesomeIcon
                icon={faFileWord}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "xls":
      case "xlsx":
      case "csv":
        return (
            <FontAwesomeIcon
                icon={faFileExcel}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "note":
        return (
            <FontAwesomeIcon
                icon={faStickyNote}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "pages":
        return (
            <FontAwesomeIcon
                icon={faFileWord}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;

      case "pptx":
      case "ppt":
        return (
            <FontAwesomeIcon
                icon={faFilePowerpoint}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;
      default:
        return (
            <FontAwesomeIcon
                icon={faFile}
                size="lg"
                style={{ fontSize: "50", marginRight: "10" }}
            />
        );
        break;
    }
  };

  const handleSaveNote = (title, content) => {
    props.attemptAddNoteAnnexe(
        model.hash,
        plugnote.hash,
        environment.userhash,
        title,
        content,
        false,
        null
    );
    setSelectedNote(null);
  };

  const handleUpdateNote = (title, content, annexehash, notehash) => {
    props.attemptEditNoteAnnexe(annexehash, notehash, title, content);
    setSelectedNote(null);
  };

  const handleNoteOpen = (event, annexe) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof annexe !== "undefined") {
      setSelectedNote(annexe);
    } else {
      setSelectedNote(null);
    }

    setOpenNote(true);
  };

  const handleNoteClose = () => {
    setOpenNote(false);
    setSelectedNote(null);
  };

  const handleCreateNote = (event) => {
    handleNoteOpen(event);
  };

  const handleClickFavoris = () => {
    props.attemptUpdateFavorisPlugnote(
        plugnote.hash,
        !plugnote.favorite,
        environment.userhash
    );
    plugnote.favorite = !plugnote.favorite;
    setFavorite(plugnote.favorite);
    setPlugnote(plugnote);
  };

  const handleClickLock = () => {
    props.attemptUpdateLockPlugnote(
        plugnote.hash,
        plugnote.isarchived,
        !plugnote.locked,
        environment.userhash
    );
    plugnote.locked = !plugnote.locked;
    setLocked(plugnote.locked);
    setPlugnote(plugnote);
  };

  const handleClickArchive = () => {
    props.attemptUpdateArchivePlugnote(
        plugnote.hash,
        !plugnote.isarchived,
        plugnote.locked,
        environment.userhash
    );
    plugnote.isarchived = !plugnote.isarchived;
    setArchived(plugnote.isarchived)
    setPlugnote(plugnote);
  };

  const handleDeletePlugnote = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className={classes.confirmContainer}>
              <h1 className={classes.confirmTitle}>{t("global.plugnotes")}</h1>
              <p className={classes.confirmText}>
                {t("plugnote.confirm-delete")}
              </p>
              <div className={classes.confirmButtons}>
                <button
                    className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`}
                    onClick={onClose}
                >
                  {t("global.cancel")}
                </button>
                <button
                    className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                    onClick={() => {
                      props.attemptDeletePlugote(
                          plugnote.hash,
                          environment.userhash,
                          "/pluglist-shared"
                      );
                      onClose();
                    }}
                >
                  {t("global.yes")}
                </button>
              </div>
            </div>
        );
      },
    });
  };

  const handleModalPlugnotePlugcodeOpen = () => {
    props.attemptResetPlugnotePlugcode();

    if (dataHasChanged) {
      if (checkData(fields)) {
        if (selectedFiles !== null) {
          handleClickAlertWithCancel();
        } else {
          handleClickAlertWithoutCancel();
        }
      } else {
        const modelForHash = getModel();

        //this._handleSaveAgendaToCalendar();

        let dataForHash = buildUpdateAnnexe(fields);

        props.attemptUpdateDataWithoutRedirect(
            modelForHash.hash,
            environment.userhash,
            dataForHash
        );

        // if (isNewdata) {
        //
        // }
        setIsOpenModalPlugnotePlugcode(true);
      }
    } else {
      setIsOpenModalPlugnotePlugcode(true);
    }
  };

  const handleModalPlugnotePlugcodeClose = () => {
    props.attemptResetPlugnotePlugcode();
    setIsOpenModalPlugnotePlugcode(false);
  };

  const onError = (e) => {
    //console.log(e, 'error in file-viewer');
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // list allow mime type
    // const types = ['image/png', 'image/jpeg', 'image/gif']
    // // loop access array
    // for(var x = 0; x<files.length; x++) {
    //     // compare file type find doesn't matach
    //     if (types.every(type => files[x].type !== type)) {
    //         // create error message and assign to container
    //         err[x] = files[x].type+' is not a supported format\n';
    //     }
    // };
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };
  const maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 1) {
      const msg = "Only 1 images can be uploaded at a time";
      event.target.value = null;
      toast.warn(msg);
      return false;
    }
    return true;
  };
  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 1000000000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file\n";
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  const maxSelectFileDrop = (files) => {
    if (files.length > 1) {
      const msg = "Only 1 images can be uploaded at a time";
      toast.warn(msg);
      return false;
    }
    return true;
  };

  const checkFileSizeDrop = (files) => {
    let size = 100000000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file\n";
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
    }
    return true;
  };

  const onChangeHandlerDrop = (acceptedFiles) => {
    const files = acceptedFiles;

    if (maxSelectFileDrop(files) && checkFileSizeDrop(files)) {
      setLoaded(0);
      setSelectedFiles(files);
    }
  };

  const onChangeHandler = (event) => {
    const files = event.target.files;

    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      // if return true allow to setState
      setLoaded(0);
      setSelectedFiles(files);
    }
  };

  const onChangeUpdateHandler = (event) => {
    const files = event.target.files;

    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      // if return true allow to setState
      setLoaded(0);
      setSelectedUpdateFiles(files);
    }
  };

  const onClickHandler = () => {
    const data = new FormData();
    for (let x = 0; x < selectedFiles.length; x++) {
      data.append("file", selectedFiles[x]);
    }
    props.attemptPostAnnexes(
        model.hash,
        environment.userhash,
        plugnote.hash,
        true,
        false,
        null,
        data
    );
    resetFileInput();
  };

  const onClickUpdateHandler = () => {
    const data = new FormData();
    for (let x = 0; x < selectedUpdateFiles.length; x++) {
      data.append("file", selectedUpdateFiles[x]);
    }

    props.attemptPostUpdateAnnexes(
        model.hash,
        environment.userhash,
        plugnote.hash,
        selectedAnnexe.hash,
        true,
        false,
        null,
        data
    );
    setSelectedAnnexe(null);
    setSelectedUpdateFiles(null);
    resetFileUpdateInput();
  };

  const isAuthorisedToEdit = (type) => {
    return authorisedToEdit.includes(type.toLowerCase());
  };

  const handleUpdateAnnexe = (event, annexe) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedAnnexe(annexe);
    inputUpdateFile.current.click();
  };

  const handleModalListAnnexesVersionOpen = (event, annexehash) => {
    event.preventDefault();
    event.stopPropagation();
    props.attemptGetAnnexesVersion(annexehash);
    setIsOpenModalListAnnexesVersion(true);
  };

  const handleModalListAnnexesVersionClose = () => {
    setIsOpenModalListAnnexesVersion(false);
  };

  const handleChangeTableValue = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  }

  const handleChangeTableRowsValue = (value, rows, field, index) => {
    let listFields = fields;
    listFields[index].value.value = value;
    listFields[index].value.list = rows;
    setFields([...listFields]);
    setDataHasChanged(true);
  }

  const handleSavePassword = () => {
    if (dataHasChanged) {
      if (checkData(fields)) {
        if(selectedFiles !== null){
          handleClickAlertWithCancel();
        }else{
          handleClickAlertWithoutCancel();
        }
      }
      else{
        setDataHasChanged(false);
        const modelForHash = getModel();
        let dataForHash = buildUpdateAnnexe(fields);

        props.attemptUpdateDataWithoutRedirect(
            modelForHash.hash,
            environment.userhash,
            dataForHash);

      }
    }
  }

  const habilityToUseDelete = () => {
    let obj =  props.params.find((elm) => elm.clef === 'process_exported_delete_customer_row_table');
    if(obj){
      let values = obj.valeur.split(';')
      let valueObj = values.find((val) => val ===  environment.envIndexOwner +'-'+ model.clef);
      return !!valueObj
    }

    return false;

  }

  const habilityToUseUpdate = () => {
    let obj =  props.params.find((elm) => elm.clef === 'process_exported_update_customer_row_table');
    if(obj){
      let values = obj.valeur.split(';')
      let valueObj = values.find((val) => val ===  model.envIndexOwner +'-'+ model.clef);
      return !!valueObj
    }

    return false;

  }

  const handleOpenHelp = () => {
    setOpenModalHelp(true);
  }

  const handleModalHelpClose = () => {
    setOpenModalHelp(false);
  }


  useEffect(() => {
    if (getPlugnote() !== null) {
      props.attemptGetDataUnique(getPlugnote().hash , environment.userhash);
    }
  }, []);

  useEffect(() => {
    const labels = Object.keys(model).filter((key) => {
      const regex = /field[0-9]+label/gi;
      return regex.test(key);
    });

    let champs = labels.map((item) => {
      return model[item];
    });

    let listFields = [];

    champs.forEach((field, index) => {
      if (field) {
        const fieldIndex = index + 1;
        let value = "";
        let format = {};
        let visuelFormat = {};
        let show = false;
        let encodable = false;
        let event = "";
        let visualDefault = false;

        let options = [];

        let sharedPlugform = false;

        let originalOptions = [];

        if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
          options =
              typeof modelForHash[`field${fieldIndex}formatoptions`] !== "string"
                  ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])
                  : Object.create(
                  JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                  );
          originalOptions =
              typeof modelForHash[`field${fieldIndex}formatoptions`] !== "string"
                  ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])
                  : Object.create(
                  JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                  );
        }

        // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
        if (!_.isEmpty(modelForHash)) {
          format = modelForHash[`field${fieldIndex}format`];
          encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
          visuelFormat = modelForHash[`field${fieldIndex}format`];
          sharedPlugform = modelForHash[`plugformModelIsSharedField${fieldIndex}`];
        }

        // Si c'est une préselection on initialise la valeur à un tableau vide
        if (options != null && (options.presel || format === "list")) {
          value = [];
        }

        if (!_.isEmpty(dataForHash)) {
          if (
              format === "numeric" &&
              typeof dataForHash[`field${fieldIndex}`] !== "undefined"
          ) {
            value = Number.isInteger(dataForHash[`field${fieldIndex}`])
                ? parseInt(dataForHash[`field${fieldIndex}`])
                : parseFloat(dataForHash[`field${fieldIndex}`].replace(",", "."));
          } else if (format === "list") {
            if (typeof dataForHash[`field${fieldIndex}`] === "undefined") {
              value = [];
            } else if (dataForHash[`field${fieldIndex}`].length > 0) {
              let keywordList = JSON.parse(dataForHash[`field${fieldIndex}`]);

              value = [];
              keywordList.map((item, index) => {
                value.push(item.text);
              });
            }
          } else if (format === "plugform") {
            if (typeof dataForHash[`field${fieldIndex}`] === "undefined") {
              value = { value: "", index: "" };
            } else if (dataForHash[`field${fieldIndex}`].length > 0) {
              value = JSON.parse(dataForHash[`field${fieldIndex}`]);
            }
          }
          else if (format === "qrcodeint") {
            if (typeof dataForHash[`field${fieldIndex}`] === "undefined" || dataForHash[`field${fieldIndex}`] === "") {
              value = {"code" : "", "model" : "", "data" : "", "comment" : ""};
            } else if (dataForHash[`field${fieldIndex}`].length > 0) {
              value = JSON.parse(dataForHash[`field${fieldIndex}`]);
            }
          }
          else if (format === "pluglink") {
            value = {
              code: JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                  .pfcode,
              clef: modelForHash[`clef`],
              userindex: dataForHash[`userindex`],
            };
          }
          else if(format === 'tablemulti'){
            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
              value = {"value" : ""};
            }else if( dataForHash[`field${fieldIndex}`].length > 0){
              value = JSON.parse(dataForHash[`field${fieldIndex}`]);
            }
          }
          else if(format === 'tablemultin'){
            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
              value = {"value" : "", "list" : []};
            }else if( dataForHash[`field${fieldIndex}`].length > 0){
              value = JSON.parse(dataForHash[`field${fieldIndex}`]);
            }
          }
          else if(format === 'document'){
            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
              value = {"value" : "", "list" : []};
            }else if( dataForHash[`field${fieldIndex}`].length > 0){
              value = JSON.parse(dataForHash[`field${fieldIndex}`]);
            }
          }
          else if(format === 'smailvalidmulti'){
            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
              value = {"value" : "", "list" : []};
            }else if( dataForHash[`field${fieldIndex}`].length > 0){
              value = JSON.parse(dataForHash[`field${fieldIndex}`]);
            }
          }
          else {
            value = dataForHash[`field${fieldIndex}`];
          }
        }

        // On vérifie si le champ est une préselection avec les options
        if (options != null && options.presel && format === "select") {
          if (Array.isArray(value) && Immutable.isImmutable(value)) {
            value = Immutable.asMutable(value);
          }

          if(options?.visibledefault){
            visualDefault = options?.visibledefault;
          }

          // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
          if (!Array.isArray(value)) {
            if (
                value &&
                value != null &&
                value.length &&
                /^[\],:{}\s]*$/.test(
                    value
                        .replace(/\\["\\\/bfnrtu]/g, "@")
                        .replace(
                            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                            "]"
                        )
                        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                )
            ) {
              value = JSON.parse(value);
            } else {
              if (value && value != null) {
                value = [value];
              } else {
                value = [];
              }
            }
          }

          switch (options.multiselect) {
            case true:
              if (options.editable) {
                format = "free-multi-select";
                visuelFormat = "free-multi-select";
              } else {
                format = "multi-select";
                visuelFormat = "multi-select";
              }
              break;
            default:
              if (options.editable) {
                format = "free-single-select";
                visuelFormat = "free-single-select";
              } else {
                format = "single-select";
                visuelFormat = "single-select";
              }
          }

          options.presel =
              options && options.presel && isString(options.presel)
                  ? options.presel.split(";")
                  : options.presel;
        } else if (options != null && options.presel && format === "table") {
          if (Array.isArray(value) && Immutable.isImmutable(value)) {
            value = Immutable.asMutable(value);
          }
          if(options?.visibledefault){
            visualDefault = options?.visibledefault;
          }

          // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
          if (!Array.isArray(value)) {
            if (
                value &&
                value != null &&
                value.length &&
                /^[\],:{}\s]*$/.test(
                    value
                        .replace(/\\["\\\/bfnrtu]/g, "@")
                        .replace(
                            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                            "]"
                        )
                        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                )
            ) {
              value = JSON.parse(value);
            } else {
              if (value && value != null) {
                value = [value];
              } else {
                value = [];
              }
            }
          }

          switch (options.multiselect) {
            case true:
              if (options.editable) {
                format = "free-multi-table";
                visuelFormat = "free-multi-table";
              } else {
                format = "multi-table";
                visuelFormat = "multi-table";
              }
              break;
            default:
              if (options.editable) {
                format = "free-single-table";
                visuelFormat = "free-single-table";
              } else {
                format = "single-table";
                visuelFormat = "single-table";
              }
          }
          options.presel =
              options && options.presel && isString(options.presel)
                  ? options.presel.split(";")
                  : options.presel;
        } else {
          if (format === "select") {
            format = "multi-select";
            options = [];

            if (
                value &&
                value != null &&
                value.length &&
                /^[\],:{}\s]*$/.test(
                    value
                        .replace(/\\["\\\/bfnrtu]/g, "@")
                        .replace(
                            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                            "]"
                        )
                        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                )
            ) {
              value = JSON.parse(value);
            } else {
              if (value && value != null) {
                value = [value];
              } else {
                value = [];
              }
            }
          }

          if (format === "table") {
            format = "multi-table";
            options = [];

            if (
                value &&
                value != null &&
                value.length &&
                /^[\],:{}\s]*$/.test(
                    value
                        .replace(/\\["\\\/bfnrtu]/g, "@")
                        .replace(
                            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                            "]"
                        )
                        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                )
            ) {
              value = JSON.parse(value);
            } else {
              if (value && value != null) {
                value = [value];
              } else {
                value = [];
              }
            }
          }
        }

        let originalPresel = undefined;
        if (
            typeof originalOptions.presel !== "undefined" &&
            originalOptions.presel.length > 0
        ) {
          if (Array.isArray(originalOptions.presel)) {
            originalPresel = originalOptions.presel;
          } else {
            originalPresel = originalOptions.presel.split(";");
          }
        }

        if (format === "now" && !value) {
          value = moment().format("YYYY-MM-DD HH:mm:ss");
          setDataHasChanged(true);
        }

        if (
            (format === "free-multi-select" || format === "free-single-select") &&
            value.length > 0
        ) {
          value.map((item, index) => {
            if (!options.presel.includes(item)) {
              options.presel.push(item);
            }
          });
        }

        if (
            (format === "free-multi-table" || format === "free-single-table") &&
            value.length > 0
        ) {
          value.map((item, index) => {
            if (!options.presel.includes(item.text)) {
              options.presel.push(item.text);
            }
          });
        }

        listFields.push({
          id: fieldIndex,
          title: modelForHash[`field${fieldIndex}label`],
          titleTranslate: modelForHash[`field${fieldIndex}label`],
          value: typeof value === "undefined" ? "" : value,
          visuelValue: value,
          format,
          visuelFormat,
          encodable,
          sharedPlugform,
          options,
          originalPresel,
          mandatory: modelForHash[`field${fieldIndex}mandatory`],
          show,
          event: event,
          visualDefault
        });
      }
    });

    setFields(listFields)
  }, []);

  useEffect(() => {
    setPlugnotesAnnexes(getAnnexes());
  }, [props.annexes]);

  useEffect(() => {
    if (selectedFiles !== null) {
      onClickHandler();
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedUpdateFiles !== null) {
      onClickUpdateHandler();
    }
  }, [selectedUpdateFiles]);

  useEffect(() => {
    setPlugnote(props.plugnote);
  }, [props.plugnote]);

  useEffect(() => {
    if(props.plugnoteRefresh !== null){
      setPlugnote(props.plugnoteRefresh);
    }
  }, [props.plugnoteRefresh]);

  return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes.paperAnchorDockedLeft, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
        >
          <div className={classes.toolbar} />
          <div className={classes.openCloseRelative}>
            <IconButton
                className={
                  !open ? classes.openCloseButton : classes.openedCloseButton
                }
                onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <List className={classes.listHeight}>
            <div className="left-menu_contents">
              {props.type !== "demo" && (
                  <ListItem button disableGutters style={{ marginLeft: 4 }}>
                    <ListItemIcon>
                      <UserIcon />
                    </ListItemIcon>
                    <ListItemText primary={environment.name} />
                  </ListItem>
              )}
              <div className="left-menu_contents_wrapper">
                <ul className="left-menu_contents_actions">
                  {!locked && model.ms_add_annexe > 0 && (
                      <li className={classes.pointer} onClick={handleUploadClick}>
                        <span className="material-icons md-25 ">add</span>
                        <div className={classes.pointer}>
                          {t("common:plugnote.add-attachment")}
                        </div>
                      </li>
                  )}
                  <li
                      className={classes.pointer}
                      onClick={(event) => setVisibleAll(!visibleAll)}
                  >
                  <span className="material-icons md-25 ">
                    {visibleAll === true ? "visibility" : "visibility_off"}
                  </span>
                    <div className={classes.pointer}>
                      {visibleAll === true
                          ? t("common:plugnote.hide-all-fields")
                          : t("common:plugnote.view-all-fields")}
                    </div>
                  </li>

                  <li className={classes.pointer} onClick={handleModalPrintOpen}>
                    <span className="material-icons md-25">picture_as_pdf</span>
                    <div className={classes.pointer}>
                      {t("common:global.print")}
                    </div>
                  </li>

                  {model.ms_edit_fiche > 0 && (
                      <li
                          className={classes.pointer}
                          onClick={handleModalPlugnotePlugcodeOpen}
                      >
                        <span className="material-icons md-25">qr_code</span>
                        <div className={classes.pointer}>
                          {t("common:plugnote.transfert-plugnote")}
                        </div>
                      </li>
                  )}
                  {/*<li className={classes.pointer} >*/}
                  {/*<span className="material-icons md-25 ">*/}
                  {/*    edit*/}
                  {/*</span>*/}
                  {/*    <div className={classes.pointer}>Editer les labels</div>*/}
                  {/*</li>*/}
                  {model.ms_edit_fiche > 0 && (
                      <li className={classes.pointer} onClick={handleClickFavoris}>
                    <span
                        className={
                          "material-icons md-25 " +
                          (plugnote.favorite ? "green" : "")
                        }
                    >
                      star
                    </span>
                        <div className={classes.pointer}>
                          {t("common:plugnote.favorite")}
                        </div>
                      </li>
                  )}

                  {model.ms_edit_fiche > 0 && (
                      <li className={classes.pointer} onClick={handleClickLock}>
                        {locked === true ? (
                            <span
                                id="icon-verouiller"
                                className="material-icons md-25"
                            >
                        lock
                      </span>
                        ) : (
                            <span
                                id="icon-verouiller"
                                className="material-icons md-25"
                            >
                        lock_open
                      </span>
                        )}
                        <div className={classes.pointer}>
                          {" "}
                          {locked === true
                              ? t("common:plugnote.unlock")
                              : t("common:plugnote.lock")}
                        </div>
                      </li>
                  )}

                  {model.ms_edit_fiche > 0 && (
                      <li className={classes.pointer} onClick={handleClickArchive}>
                        {plugnote.isarchived ? (
                            <span id="icon-archive" className="material-icons md-25">
                        archive
                      </span>
                        ) : (
                            <span id="icon-archive" className="material-icons md-25">
                        unarchive
                      </span>
                        )}
                        <div className={classes.pointer}>
                          {plugnote.isarchived
                              ? t("common:plugnote.unarchive")
                              : t("common:plugnote.archive")}
                        </div>
                      </li>
                  )}

                  {model.ms_delete_fiche > 0 && (
                      <li
                          className={classes.pointer}
                          onClick={handleDeletePlugnote}
                      >
                        <span className="material-icons md-25 ">delete</span>
                        <div className={classes.pointer}>
                          {t("common:plugnote.delete")}
                        </div>
                      </li>
                  )}

                  {model.ms_edit_fiche > 0 && plugnote !== null && plugnote.isexported && habilityToUseUpdate() && (
                      <li
                          className={classes.pointer}
                          onClick={handleUpdateExportedPlugnote}
                      >
                        <span className="material-icons md-25 ">upgrade</span>
                        <div className={classes.pointer}>
                          {t("common:multiple.save-exported")}
                        </div>
                      </li>
                  )}

                  {model.ms_delete_fiche > 0 && plugnote !== null && plugnote.isexported && habilityToUseDelete() && (
                      <li
                          className={classes.pointer}
                          onClick={handleDeleteExportedPlugnote}
                      >
                        <span className="material-icons md-25 ">delete</span>
                        <div className={classes.pointer}>
                          {t("common:multiple.delete-exported")}
                        </div>
                      </li>
                  )}

                  <li className={classes.pointer} onClick={handleOpenHelp}>
                    <Tooltip title={t('common:global.help-manuels')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                            <span className="material-icons green md-25 ">
                                                help
                                            </span></Tooltip>
                    <div>{t('common:global.help-manuels')}</div>
                  </li>

                </ul>
                <input
                    key={fileInputKey}
                    type="file"
                    ref={inputFile}
                    className="form-control"
                    multiple={false}
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                />
                {/* this line to make open the modal to add attachments on drawer list */}
              </div>

            </div>
          </List>
        </Drawer>
        <Plugforms
            mode="shared-update"
            shared={true}
            fields={fields}
            attemptGetDataUnique={props.attemptGetDataUnique}
            model={model}
            hashOwnerModel={hashOwnerModel}
            dataHasChanged={dataHasChanged}
            handleChangeTableValue={handleChangeTableValue}
            handleEditValue={handleEditValue}
            handleEditPhoneValue={handleEditPhoneValue}
            handleChangeBoolean={handleChangeBoolean}
            handleChangePassword={handleChangePassword}
            handleEditDateTime={handleEditDateTime}
            handleChangePlugform={handleChangePlugform}
            handleChangeIntDataMaj={handleChangeIntDataMaj}
            handleChangeExtData={handleChangeExtData}
            handleChangePlugcode={handleChangePlugcode}
            handleChangePlugcodeInternal={handleChangePlugcodeInternal}
            handleChangeRadioTable={handleChangeRadioTable}
            handleChangeInputTable={handleChangeInputTable}
            handleUpdateInputTable={handleUpdateInputTable}
            handleDeleteInputTable={handleDeleteInputTable}
            handleChangeKeyword={handleChangeKeyword}
            handleChangeLocalisation={handleChangeLocalisation}
            setFields={setFields}
            plugnoteAnnexes={plugnoteAnnexes}
            nameModel={nameModel}
            plugnote={plugnote}
            handleExitUpdatePlugnote={handleExitUpdatePlugnote}
            handleSaveUpdatePlugnote={handleSaveUpdatePlugnote}
            handleSaveUpdatePlugnoteWithoutRedirect={handleSavePlugnote}
            isOpenModalListAnnexes={isOpenModalListAnnexes}
            _handleOpenModalListAnnexes={_handleOpenModalListAnnexes}
            _handleCloseModalListAnnexes={_handleCloseModalListAnnexes}
            isAuthorisedToEdit={isAuthorisedToEdit}
            downloadEmployeeData={downloadEmployeeData}
            handleUpdateAnnexe={handleUpdateAnnexe}
            handleModalAnnexeOpen={handleModalAnnexeOpen}
            handleDeleteAnnexe={handleDeleteAnnexe}
            handleModalListAnnexesVersionOpen={handleModalListAnnexesVersionOpen}
            handleChangeRadio={handleChangeRadio}
            handlePreviewAnnexe={handlePreviewAnnexe}
            handlePreviewPdfAnnexe={handlePreviewPdfAnnexe}
            onChangeHandlerDrop={onChangeHandlerDrop}
            renderAnnexeIconPreview={renderAnnexeIconPreview}
            renderAnnexeImagePreview={renderAnnexeImagePreview}
            environment={environment}
            fullNamePlugnote={fullNamePlugnote}
            disabled={locked}
            isOpenModalAnnexe={isOpenModalAnnexe}
            handleModalAnnexeClose={handleModalAnnexeClose}
            visibleAll={visibleAll}
            handleNoteOpen = {handleNoteOpen}
            handleCreateNote = {handleCreateNote}
            handleUpdateNote = {handleUpdateNote}
            handleNoteClose = {handleNoteClose}
            handleChangeTableRowsValue={handleChangeTableRowsValue}
            handleSavePassword={handleSavePassword}
            handleSaveSharedPlugnote={handleSavePlugnote}
        />
        {/* modals for the left menu drawer */}
        {
          openNote &&
          <ModalNoteComponent selectedNote={selectedNote} open={openNote}  close={handleNoteClose} handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote}/>
        }

        {
          getPlugnote() && isOpenModalListAnnexesVersion &&
          <ModalListAnnexesVersionComponent open={isOpenModalListAnnexesVersion} close={handleModalListAnnexesVersionClose}/>
        }

        {isOpenModalPrint && (
            <ModalPrintComponent
                open={isOpenModalPrint}
                close={_handleCloseModalPrint}
                plugnoteAnnexes={plugnoteAnnexes}
                mode="shared-update"
                model={model}
                fields={fields}
                user={props.user}
                plugnote={plugnote}
                onChangeHandlerDrop={onChangeHandlerDrop}
            />
        )}
        {getPlugnote() && isOpenModalPlugnotePlugcode ? (
            <ModalPlugnotePlugcodeComponent
                open={isOpenModalPlugnotePlugcode}
                close={handleModalPlugnotePlugcodeClose}
                modelShared={false}
            />
        ) : null}


        {
          openModalHelp  ?
              <ModalHelpComponent open={openModalHelp} close={handleModalHelpClose} from="plugnote"/>
              :
              null
        }

        <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('global.plugnotes')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                t('plugnote-create-update.plug-form-mandatory-fields-empty')
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            { cancel &&
            <Button onClick={handleClickCancelModal} color="primary">
              {
                t('global.cancel')
              }
            </Button>
            }
            <Button onClick={handleClickContinueModal} color="primary" autoFocus>
              {
                t('global.ok')
              }
            </Button>
          </DialogActions>
        </Dialog>
        <input key={fileUpdateInputKey} type="file" ref={inputUpdateFile} className="form-control" multiple={false} onChange={onChangeUpdateHandler} style={{display:'none'}}/>

      </div>
  );
}

// redux providing state takeover
const mapStateToProps = (state) => {

  const annexes = getAnnexes();

  if (!!state.annexe.list) {
    if (state.annexe.list.length > 0) {
      state.annexe.list.map((an, index) => {
        if (!annexes.find((element) => element.hash === an.hash)) {
          annexes.push(an);
          setAnnexes(annexes);
        } else if (annexes.find((element) => element.hash === an.hash)) {
          let findIndex = annexes.findIndex(
              (element) => element.hash === an.hash
          );
          annexes[findIndex].lastversion = an.lastversion;
          annexes[findIndex].creationdate = an.creationdate;
          annexes[findIndex].countVersion = an.countVersion;
          setAnnexes(annexes);
        }
      });
    }
  }

  return {
    user: state.user,
    token: state.user.token,
    plugnotes: !!state.data.list ? state.data.list : [],
    plugnote: getPlugnote(),
    plugnoteRefresh: state.data.plugnoterefresh,
    fetching: state.data.fetching,
    progress: state.annexe.progress,
    annexes: annexes,
    isDataSearchActive: state.data.isDataSearchActive,
    authapi : state?.user?.user?.auth || false,
    userid : state?.user?.user?._value?.id || null,
    params : state?.params?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptGetDataUnique: (datahash, userhash) => dispatch(getDataUniqueRequest(datahash, userhash)),
    attemptResetPlugnotePlugcode: () => dispatch(reduxGeneratePlugnotecodeReset()),
    attemptUpdateData: (modelHash, userHash, formData) => {
      return dispatch(
          putUpdateSharedDataRequest(modelHash, userHash, formData)
      );
    },
    attemptUpdateDataWithoutRedirect: (modelHash, userHash, formData) => {
      return dispatch(
          putUpdateSharedDataWithoutRedirectRequest(modelHash, userHash, formData)
      );
    },
    attemptPostAnnexes: (modelhash, userhash, datahash, modelshared, newdata, formdata, formfile) => dispatch(postAnnexesSharedRequest(modelhash, userhash, datahash, modelshared, newdata, formdata, formfile)),
    attemptPostUpdateAnnexes: (modelhash, userhash, datahash, annexehash, modelshared, newdata, formdata, formfile) => dispatch(postAnnexesVersionSharedRequest(modelhash, userhash, datahash, annexehash, modelshared, newdata, formdata, formfile)),
    attemptDeleteAnnexe: (annexehash, userhash,modelhash,formdata) => dispatch(delDeleteAnnexeRequest(annexehash, userhash,modelhash,formdata)),
    attemptAddNoteAnnexe: (modelhash, datahash, userhash, name, note, newdata, formdata) => dispatch(postAddNoteRequest(modelhash, datahash, userhash, name, note, newdata, formdata)),
    attemptEditNoteAnnexe: (annexehash, notehash, name, note) => dispatch(putEditNoteRequest(annexehash, notehash, name, note)),
    attemptDeletePlugote: (datahash, userhash, back) => dispatch(delDeleteDataRequest(datahash, userhash, back)),
    attemptUpdateFavorisPlugnote: (datahash, favorite, userhash) => dispatch(putUpdateDataFavorisRequest(datahash, favorite, userhash)),
    attemptUpdateLockPlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataLockRequest(datahash, isarchived, locked, userhash)),
    attemptUpdateArchivePlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataArchiveRequest(datahash, isarchived, locked, userhash)),
    attemptGetAnnexesVersion: (annexehash) => dispatch(getAnnexesVersionRequest(annexehash)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlugnoteSharedUpdateV2);
