import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlugTooltip } from '../../custom/CustomComponents'
import { isDefined } from '../../../utils/utils'
import { getFormatDefaultValue } from '../../../utils/Fields'
import { LockedIcon } from '../../custom/icons/CustomIcons'

const useStyles = makeStyles(() => ({
  mainCtn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
  },
}))

const FormatTableMultipleDefaultValuesEditorComponent = (props) => {
  console.log('TMDefaultValues got props', props)
  const classes = useStyles()
  const { t } = useTranslation(['common'])
  const setup = props.field.options.setup
  const columns = isDefined(props.field.options.lines) ? props.field.options.lines : []
  
  const [defaultRows, setDefaultRows] = useState(isDefined(setup.defaultRows) ? setup.defaultRows : [])


  // Methods
  const handleAddRow = () => {
    let isFull = false
    if (setup.rowLimit > 0) {
      if (defaultRows.length === setup.rowLimit) {
        isFull = true
      }
    }
    if (!isFull) {
      let newRow = {}
      columns.forEach(col => {
        if (col.id === 1001 || col.id === 1002 || col.format === 'formula') {
          newRow[col.id] = t('tablemulti.not-editable')
        } else { newRow[col.id] = getFormatDefaultValue(col.format) }
      })
      newRow['locked'] = false
      setDefaultRows(previous => [...previous, newRow])
    }
  }

  const handleToggleLockRow = (index) => {
    let _rows = [...defaultRows]
    _rows[index].locked = !defaultRows[index].locked
    setDefaultRows(_rows)
  }
  const handleDeleteRow = (rindex) => {
    let _rows = defaultRows.filter((row, index) => index !== rindex)
    setDefaultRows(_rows)
  }

  return (
    <div className={classes.mainCtn} >
      <div className={classes.header} >
        <PlugTooltip title={t('tablemulti.add-row')} placement="top" >
          <div onClick={handleAddRow}>
            <span className="material-icons" >
              add_circle_outline
            </span>
          </div>
        </PlugTooltip>
      </div>

      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>N°</TableCell>
              {
                columns.map(col => {
                  return (
                    <TableCell key={'field-' + props.field.id + '-col-' + col.id}>
                      {
                        (col.label || t('tablemulti.missing-label')) + '\n' + col.formatLabel
                      }
                    </TableCell>
                  )
                })
              }
              <TableCell>
                <span className="material-icons" >
                    lock
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {
                defaultRows.map((row, rindex) => {
                  let rowNb = rindex + 1
                  return (
                    <TableRow key={'field-' + props.field.id + '-row-' + rowNb}>
                      <TableCell>{rowNb}</TableCell>
                      <TableCell>
                        <PlugTooltip title={t('tablemulti.default-lock')} placement="top" >
                          <div onClick={() => handleToggleLockRow(rindex)}>
                            <LockedIcon locked={row.locked} />
                          </div>
                        </PlugTooltip>
                      </TableCell>
                      {
                        columns.map(col => {
                          return (
                            <TableCell key={'field-' + props.field.id + '-col-' + col.id}>
                              {
                                row[col.id]
                              }
                            </TableCell>
                          )
                        })
                      }
                      <TableCell>
                        <PlugTooltip title={t('tablemulti.delete-row')} placement="top" >
                          <div onClick={() => handleDeleteRow(rindex)}>
                            <span className="material-icons" >
                                delete
                            </span>
                          </div>
                        </PlugTooltip>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
          </TableBody>
        </Table>
      </div>
      
    </div>
  );
}

export default FormatTableMultipleDefaultValuesEditorComponent
