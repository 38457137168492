import axios from 'axios'
import ApplicationConfig from '../config/ApplicationConfig'
import { trackPromise } from 'react-promise-tracker'
import qs from 'querystringify'
import { toast } from 'react-toastify'
import i18n from '../translations/i18n'


export const getPlugnoteFromCode = async (plugCode, userhash) => {
  const token = sessionStorage.getItem('token')
  let plugnote = null
  if (plugCode.startsWith('PF')) {
    await trackPromise(
      axios.get(ApplicationConfig.apiUrl + 'pfcode/modelcodelogin.json' + qs.stringify({
        code: encodeURIComponent(plugCode),
        uh: encodeURIComponent(userhash)
      }, '?'), { // token
        headers: {
          'X-AUTH-TOKEN': token
        }
      })
        .then((response) => {
          // console.log('response from API :', response)
          if (response.data.result === true || response.data.result === 'ok') {
            plugnote = (response.data)
          } else if (response.data.result === false || response.data.result === 'onk') {
            toast.warn(`${i18n.t('common:template.code-error')}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000
            })
            return (null)
          } else if (response.data.result === 'nok') {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          } else {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          }
        })
        .catch((response) => {
          // je lance une notification (toast error)
          // toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
        })
    )
  } else {
    await trackPromise(
      axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogin.json' + qs.stringify({
        code: encodeURIComponent(plugCode),
        uh: encodeURIComponent(userhash)
      }, '?'),
      { // token
        headers: {
          'X-AUTH-TOKEN': token
        }
      }
      )
        .then((response) => {
          console.log('response from API :', response)
          if (response.statusText === 'OK' && typeof response.data.result === 'undefined') {
            plugnote = (response.data)
          } else if (response.data.result === false) {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          } else if (response.data.result === 'nok') {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          } else {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          }
        })
        .catch((response) => {
          // toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
        })
    )
  }
  return plugnote
}