import Toolbar from "@material-ui/core/Toolbar";
import {NavLink} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ExitToApp from "@material-ui/icons/ExitToApp";
import {postLogoutRequest} from "../../actions/user";


import React from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {AccountCircle, CloseRounded, HelpOutline, MenuRounded} from "@material-ui/icons";
import Images from "../../assets/themes/Images";

import {history} from "../../hooks/history";
import {useTranslation} from "react-i18next";
import TimeComponent from "../time/TimeComponent";
import {Badge} from "@material-ui/core";
import {useLocation} from "react-router";
import {confirmAlert} from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    menuButtonAccount: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        color : '#58C3C1',
    },
    menuButtonHelp: {
        marginLeft: theme.spacing(1),
        //marginRight: theme.spacing(2),
        color : '#DADADA',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    li:{
        borderLeft: '1px solid #DADADA',
    },
    nav: {
        display: 'inline-block',
        padding: '0px 10px'
    },
    navLast: {
        display: 'inline-block',
        paddingLeft : '10px',
        paddingRight: '20px'
    }
}));

const plugcodesUrl = [
    '/plugcodes',
    '/plugcode-complete-gallery',
    '/plugcode-complete',
    '/plugcode-consult-gallery',
    '/plugcode-consult',
    '/plugcode-copy'
]

const oldPlugcodesUrl = [
    '/old-plugcodes',
    '/old-plugcode-complete-gallery',
    '/old-plugcode-complete',
    '/old-plugcode-consult-gallery',
    '/old-plugcode-consult',
    '/old-plugcode-copy'
]

const modelsUrl = [
    '/models',
    '/create-model',
    '/update-model',
    '/pluglist',
    '/pluglist-editable',
    '/create-plugnote',
    '/update-plugnote',
    '/plugnote-copy-move',
    '/models-shared',
    '/pluglist-shared',
    '/create-plugnote-shared',
    '/update-plugnote-shared',
    '/plugnote-view-shared'
]

const oldUrl = [
    '/old-models',
    '/old-pluglist'
]

const excludeExiteModelsUrl = [
    '/create-model',
    '/update-model',
]

const modelsSharedUrl = [
    '/models-shared',
    '/pluglist-shared',
    '/create-plugnote-shared',
    '/update-plugnote-shared',
    '/plugnote-view-shared'
]

const boxUrl = [
    '/inbox',
]

const excludes = [
    '/pluglist-shared',
    '/pluglist',
    '/pluglist-editable',
    '/old-pluglist'
]


const MenuComponent = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'menu']);
    const location = useLocation();

  const [menuSmall, setMenuSmall] = React.useState(false);

  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(
    window.onUnreadCountChange === 0 ? 0 : window.onUnreadCountChange
  );

  const menuVisibility = () => {
    return setMenuSmall((menuHidden) => !menuHidden);
  };

    const handleLogout = (e) => {
        if(excludeExiteModelsUrl.includes(location.pathname)){
            e.preventDefault();
            confirmAlert({
                title: t('global.confirm'),
                message: t('global.confirm-exit'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () =>  props.attemptLogout(props.userhash)
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            props.attemptLogout(props.userhash);
        }
    };

    const handleParams = (e) => {
        if(excludeExiteModelsUrl.includes(location.pathname)){
            e.preventDefault();
            confirmAlert({
                title: t('global.confirm'),
                message: t('global.confirm-exit'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () =>  history.push('params')
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            history.push('params')
        }
    };

    const handleModels = (e) => {
        if(excludeExiteModelsUrl.includes(location.pathname)){
            e.preventDefault();
            confirmAlert({
                title: t('global.confirm'),
                message: t('global.confirm-exit'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () =>  history.push('models')
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            history.push('models')
        }
    };

    const isFoldersUrlActive = (match, location) => { return modelsUrl.includes(location.pathname)};
    const isOldFoldersUrlActive = (match, location) => { return oldUrl.includes(location.pathname)};
    const isFoldersSharedUrlActive = (match, location) => { return modelsSharedUrl.includes(location.pathname)};
    const isPlugcodesUrlActive = (match, location) => { return plugcodesUrl.includes(location.pathname)};
    const isOldPlugcodesUrlActive = (match, location) => { return oldPlugcodesUrl.includes(location.pathname)};
    const isBoxUrlActive = (match, location) => { return boxUrl.includes(location.pathname)};

    const handleClick = (e, redirect) => {
        if(excludeExiteModelsUrl.includes(location.pathname)){
            e.preventDefault();
            confirmAlert({
                title: t('global.confirm'),
                message: t('global.confirm-exit'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick : () => history.push(redirect)
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            history.push(redirect);
        }
    }

  if (excludes.includes(location.pathname)) {
    return null;
  } else {
        return (
            <header className={`${classes.appBar} menuContainer`}>
                <div className="menuLogoIcon">
                    <img
                        className="logo"
                        src={Images.logo}
                        alt="logo"
                        onClick={(event) => handleModels(event)}
                    />
                    {props.type == "demo" && <TimeComponent />}

                    {/* icon menu for small screens  */}
                    <div className="menuIconSm">
                        <IconButton
                            edge="start"
                            className=""
                            color="inherit"
                            aria-label="menu"
                            id="menu_button"
                            onClick={menuVisibility}
                        >
                            {menuSmall ? <CloseRounded /> : <MenuRounded />  }
                        </IconButton>
                    </div>
                </div>


                <Toolbar className="nav_links">
                    <div className="menuGroupLinks1">
                        <NavLink
                            className={classes.nav}
                            to="/models"
                            activeClassName="black heavy"
                            isActive={isFoldersUrlActive}
                            onClick={(event) => handleClick(event, "models")}
                        >
                            <Badge badgeContent={props.listToAccept.length} color="primary">
                                {t("menu.title-folder")}
                            </Badge>
                        </NavLink>
                        {/*                    {
                        props.type !== 'demo' &&
                        <NavLink className={classes.nav} to='/models-shared' activeClassName="black heavy" isActive={isFoldersSharedUrlActive} onClick={(event) => handleClick(event, 'models-shared')}>
                            <Badge badgeContent={props.listToAccept.length} color="primary">
                                {t('menu.title-folder-shared')}
                            </Badge>
                        </NavLink>
                    }*/}
                        {props.type !== "demo" && (
                            <NavLink
                                className={classes.nav}
                                to="/plugcodes"
                                activeClassName="black heavy"
                                onClick={(event) => handleClick(event, "plugcodes")}
                                isActive={isPlugcodesUrlActive}
                            >
                                {t("menu.title-plugcodes")}
                            </NavLink>
                        )}

                        {/*                    { props.type !== 'demo' &&
                        <NavLink className={classes.nav} activeClassName="black heavy" to="/inbox" isActive={isBoxUrlActive}>{t('menu.title-inbox')}</NavLink>
                    }*/}
                        {/*{ props.type !== 'demo' &&*/}
                        {/*    <NavLink className={classes.nav} activeClassName="black heavy" to="/inbox" isActive={isBoxUrlActive}>{t('menu.title-inbox')}</NavLink>*/}
                        {/*}*/}
                        {/*<NavLink className={classes.navLast} activeClassName="black heavy" to="/index">Index</NavLink>*/}
                        {props.type !== "demo" && (
                            <IconButton
                                edge="start"
                                className={classes.menuButtonHelp}
                                color="inherit"
                                aria-label="menu"
                                id="menu_open_intercom_button"
                            >
                                <HelpOutline />
                            </IconButton>
                        )}
                    </div>
                    <div className="menuGroupLinks2">
                        {props.type !== "demo" && (
                            <IconButton
                                edge="start"
                                className={classes.menuButtonAccount}
                                color="inherit"
                                aria-label="menu"
                                onClick={(event) =>
                                    props.username ? handleParams(event) : null
                                }
                            >
                                <AccountCircle />
                            </IconButton>
                        )}

                        {/*{ !ApplicationConfig.production &&*/}
                        {/*    <NavLink className={classes.nav} activeClassName="black heavy" to="/test">Debug</NavLink>*/}
                        {/*}*/}

                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={(event) => (props.username ? handleLogout(event) : null)}
                        >
                            <ExitToApp /> <span>&nbsp; {t("common:params.logout")}</span>
                        </IconButton>
                        {/*<LanguageComponent/>*/}
                    </div>
                </Toolbar>

                {/* small screen adaptation */}
                <div className='menuVisSmall'>
                    <Toolbar className={`${menuSmall ? "nav_links_small" : "content_hidden"}`}>
                        <div
                            className={`${menuSmall ? "menuGroupLinks1_small" : "content_hidden"}`}
                        >
                            <NavLink
                                className={classes.nav}
                                to="/models"
                                activeClassName="black heavy"
                                isActive={isFoldersUrlActive}
                                onClick={(event) => handleClick(event, "models")}
                            >
                                <Badge badgeContent={props.listToAccept.length} color="primary">
                                    {t("menu.title-folder")}
                                </Badge>
                            </NavLink>
                            {/*                    {
                        props.type !== 'demo' &&
                        <NavLink className={classes.nav} to='/models-shared' activeClassName="black heavy" isActive={isFoldersSharedUrlActive} onClick={(event) => handleClick(event, 'models-shared')}>
                            <Badge badgeContent={props.listToAccept.length} color="primary">
                                {t('menu.title-folder-shared')}
                            </Badge>
                        </NavLink>
                    }*/}
                            {props.type !== "demo" && (
                                <NavLink
                                    className={classes.nav}
                                    to="/plugcodes"
                                    activeClassName="black heavy"
                                    onClick={(event) => handleClick(event, "plugcodes")}
                                    isActive={isPlugcodesUrlActive}
                                >
                                    {t("menu.title-plugcodes")}
                                </NavLink>
                            )}

                            {/*                    { props.type !== 'demo' &&
                        <NavLink className={classes.nav} activeClassName="black heavy" to="/inbox" isActive={isBoxUrlActive}>{t('menu.title-inbox')}</NavLink>
                    }*/}
                            {/*{ props.type !== 'demo' &&*/}
                            {/*    <NavLink className={classes.nav} activeClassName="black heavy" to="/inbox" isActive={isBoxUrlActive}>{t('menu.title-inbox')}</NavLink>*/}
                            {/*}*/}
                            {/*<NavLink className={classes.navLast} activeClassName="black heavy" to="/index">Index</NavLink>*/}
                            {props.type !== "demo" && (
                                <IconButton
                                    edge="start"
                                    className={classes.menuButtonHelp}
                                    color="inherit"
                                    aria-label="menu"
                                    id="menu_open_intercom_button"
                                >
                                    <HelpOutline />
                                </IconButton>
                            )}
                        </div>
                        <div
                            className={`${menuSmall ? "menuGroupLinks2_small" : "content_hidden"}`}
                        >
                            {props.type !== "demo" && (
                                <IconButton
                                    edge="start"
                                    className={classes.menuButtonAccount}
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={(event) =>
                                        props.username ? handleParams(event) : null
                                    }
                                >
                                    <AccountCircle />
                                </IconButton>
                            )}

                            {/*{ !ApplicationConfig.production &&*/}
                            {/*    <NavLink className={classes.nav} activeClassName="black heavy" to="/test">Debug</NavLink>*/}
                            {/*}*/}

                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="menu"
                                onClick={(event) => (props.userhash ? handleLogout(event) : null)}
                            >
                                <ExitToApp /> <span>&nbsp; {t("common:params.logout")}</span>
                            </IconButton>
                            {/*<LanguageComponent/>*/}
                        </div>
                    </Toolbar>
                </div>

            </header>
        );
    }
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        userhash : !!state.user && !!state.user.user ?  state.user.user.userhash : null,
        username : !!state.user && !!state.user.user ?  state.user.user.username : null,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        listToAccept : !!state.user && !!state.user.user ?  state.user.listToAccept : []
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptLogout: (userhash) => dispatch(postLogoutRequest(userhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
