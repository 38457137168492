import React, {useCallback, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote, getToken} from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import Data1001Printable from "../printable/Data1001Printable";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search, Visibility} from "@material-ui/icons";
import Data1002Printable from "../printable/Data1002Printable";
import Data1003Printable from "../printable/Data1003Printable";
import Data1004Printable from "../printable/Data1004Printable";
import Data1000Printable from "../printable/Data1000Printable";
import Data1005Printable from "../printable/Data1005Printable";
import Data1006Printable from "../printable/Data1006Printable";
import clsx from "clsx";
import Data9002Printable from "../printable/Data9002Printable";
import Data2001Printable from "../printable/Data2001Printable";
import ApplicationConfig from "../../config/ApplicationConfig";
import {isArray, isEmpty, isNull} from "lodash";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import {_getCodePnPpCodeTemplate} from "../../utils/Fields";
import SignatureCanvas from "react-signature-canvas";
import colors from "../../assets/themes/Colors";
import PropTypes from "prop-types";
import {Box, Tooltip, Typography} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTabContext } from "@material-ui/lab/TabContext"
import { TabContext, TabList } from "@material-ui/lab"
import {confirmAlert} from "react-confirm-alert";

function getModalStyle() {
    const top = 5;

    return {
        // top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

function getModalStylePreview() {
    const top = 5;

    return {
        // top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}


const useStyles = makeStyles((theme) => ({
    style :{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 4,
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    popup: {
        position: 'absolute',
        height : '95vh',
        '@media (max-width: 900px)': {
            minWidth: '95vw',
        },
        backgroundColor: 'white',
        width: '1000px',
        maxWidth: '80vw',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    table :  {
        borderCollapse: 'collapse',
        width: '100%'
    },
    tdth : {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px'
    },
    cursor : {
        cursor : 'pointer'
    },
    pdfCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '65vh'
    },
    previewCtn: {
        border: 'none',
        width: '100%',
        minWidth: '400px',
        height: '100%',
    },
    sous_header_button: {
        width: "150px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    button_save: {
        color: "white",
        backgroundColor: colors.mainButton,
        width : '70px',
        border: `2px solid transparent`,
        alignSelf : 'flex-end'
    },
    button_sign: {
        color: "white",
        backgroundColor: colors.mainButton,
        width : '70px',
        border: `2px solid transparent`,
        alignSelf : 'flex-end',
        marginRight : '5px',
    },
    button_cancel: {
        color: colors.mainDark,
        backgroundColor: "transparent",
        border: `2px solid ${colors.mainDark}`,
        fontWeight: "600",
        width : '70px',
        marginLeft : '5px',
        marginRight : '5px',
        alignSelf : 'flex-end'
    },
}));

const useStylesSign = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    mainContainer: {
        display: 'flex',
        gap: 5,
        flexDirection: 'column'
    },
    formatInputFieldInline: {
        backgroundColor: colors.greyLighter,
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: "3px 10px",
    },
    signature: {
        justifyContent : 'center',
        alignItems : 'center',
        backgroundColor: colors.greyLighter,
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: "3px 10px",
    },
    signatureBis: {
        backgroundColor: colors.white,
        // height: '150px',
        justifyContent : 'center',
        alignItems : 'center',
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button:{
        margin : '5px',
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
        height : '35px'
    },
    div:{
        /* border: '1px red solid', */
        display: 'flex',
        justifyContent: 'flex-end',
        margin: "5px",
        padding: "10px",
        width : '20%'
    },
    signButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    signCanvas: {
        height: '150px!important',
        width: '100%'
    }
}));


function TabPanel(props) {
    const {
        children,
        className,
        style,
        value: id,
        containerProps,
        ...other
    } = props

    const context = useTabContext()

    if (context === null) {
        throw new TypeError("No TabContext provided")
    }
    const tabId = context.value

    return (
        <div
            className={className}
            style={{
                width: "100%",
                margin: 0,
                padding: 0,
                //display: "flex",
                ...style,
                position: "absolute",
                left: 0,
                visibility: id === tabId ? "visible" : "hidden",
            }}
            {...other}
        >
            {children}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SignComponent(props) {

    const [t, i18n] = useTranslation('common');


    const [textAppend, setTextAppend] = React.useState("")
    const handleChangeTextAppend = (value) => setTextAppend(value);

    const handleClearSign = () => {
        sigPad.current.clear();
        let copySign = [...props.sign];
        copySign[props.indice].value = null;
        copySign[props.indice].text = null;
        setTextAppend("");
        props.setSign([...copySign]);
        props.handlePreviewTemplateReload();
    }

    const handleConfirmSign = () => {

        let signature = sigCanvas.current.toDataURL('image/png', 0.1);
        let result = signature.replace('data:image/png;base64,', "");
        let copySign = [...props.sign];
        copySign[props.indice].value = result;
        if(textAppend !== ""){
            copySign[props.indice].text = textAppend;
        }
        props.setSign([...copySign]);
        props.handlePreviewTemplateReload();
    }


    const useClasses = useStylesSign();

    const sigCanvas = useRef({})
    const sigPad = useRef({})

    const measuredRef = useCallback(node => {
        const resizeCanvas = (signaturePad, canvas) => {
            canvas.width = canvas.parentElement.clientWidth // width of the .canvasWrapper
            canvas.height = canvas.parentElement.clientWidth / 3
            signaturePad.clear()
        }
        if (node !== null) {
            sigCanvas.current = node.getCanvas()
            sigPad.current = node.getSignaturePad()
            resizeCanvas(node.getSignaturePad(), node.getCanvas())
        }
    }, [3])

    return (
        <div className={useClasses.signature}>
            <div className="display_flex">
                <div className="div_width_80 breakText padding_10">
                    <span>{props?.element?.title}</span>
                </div>
                <div className={useClasses.div}>
                    <div className={useClasses.button} onClick={(event) => handleClearSign()}>
                        <span id="icon-listForm" className={`material-icons md-small white`} style={{color: colors.main}} >clear</span>
                    </div>
                    <div className={useClasses.button} onClick={(event) => handleConfirmSign()}>
                        <span id="icon-listForm" className={`material-icons md-small white`} style={{color: colors.main}}>check</span>
                    </div>
                </div>

            </div>

            <div className={useClasses.signatureBis}>
                <div id={"canvas-signature-"+ props.indice}>
                    <SignatureCanvas
                        // canvasProps={{className: `sigCanvas`}}
                        canvasProps={{ width: "calc(100vw - 60px)", height: 150, className: 'sig-canvas' }}
                        penColor='black'
                        ref={measuredRef}
                        clearOnResize={false}
                    />
                </div>
            </div>
            <br/>
            <div className={useClasses.signatureBis}>
                <Input
                    disabled={false}
                    autoComplete='off'
                    id={"standard-full-width" + props.indice}
                    label="Label"
                    style={{ margin: 0, fontSize: 12 , height: 50}}
                    helpertext="Full width!"
                    fullWidth={true}
                    margin="none"
                    disableUnderline={true}
                    inputlabelprops={{
                        shrink: true,
                    }}
                    value={textAppend}
                    onChange={(event) => handleChangeTextAppend(event.target.value)}
                />
            </div>
        </div>
    )

}


function ChildModal(props) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const [open, setOpen] = useState(false);
    const [f28, setF28] = useState(false);

    const [t, i18n] = useTranslation('common');

    const handleClose = () => {
        setOpen(false);
        props.close();
    };

    useEffect(() => {
        if(props.open){
            if(props.fields !== null){
                setF28(props.fields.find(item => item.format === 'pluglink'))
            }
        }
        setOpen(props.open)
    }, [props.open])


    const handleSetTextChoice = (value) => {
        props.setText(value);
        setOpen(false);
        props.close();
    }

    return (
        <div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <div style={modalStyle} className={classes.popup}>
                    <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                        <div className="popup_wrapper_content">
                            <div className="sous-header_modal_content">
                                <div>
                                    <span>{t('print.select-template')}</span>
                                </div>
                                <div onClick={props.close}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                        close
                                    </span>
                                </div>

                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <div className="popup_wrapper_content">
                                <table className={classes.table}>
                                    <thead>
                                    <tr>
                                        <th className={classes.tdth}>{t('common:print.code')}</th>
                                        <th className={classes.tdth}>{t('common:print.description')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1000')}>
                                        <td className={[classes.tdth]}>1000</td>
                                        <td className={classes.tdth}>{t('common:print.1000')}</td>
                                    </tr>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1001')}>
                                        <td className={classes.tdth}>1001</td>
                                        <td className={classes.tdth}>{t('common:print.1001')}</td>
                                    </tr>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1002')}>
                                        <td className={classes.tdth}>1002</td>
                                        <td className={classes.tdth}>{t('common:print.1002')}</td>
                                    </tr>
                                    {
                                        f28 &&
                                        <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1003')}>
                                            <td className={classes.tdth}>1003</td>
                                            <td className={classes.tdth}>{t('common:print.1003')}</td>
                                        </tr>
                                    }
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1004')}>
                                        <td className={classes.tdth}>1004</td>
                                        <td className={classes.tdth}>{t('common:print.1004')}</td>
                                    </tr>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1005')}>
                                        <td className={classes.tdth}>1005</td>
                                        <td className={classes.tdth}>{t('common:print.1005')}</td>
                                    </tr>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('1006')}>
                                        <td className={classes.tdth}>1006</td>
                                        <td className={classes.tdth}>{t('common:print.1006')}</td>
                                    </tr>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('2001')}>
                                        <td className={classes.tdth}>2001</td>
                                        <td className={classes.tdth}>{t('common:print.2001')}</td>
                                    </tr>
                                    <tr className={clsx(classes.table, classes.cursor)} onClick={() => handleSetTextChoice('9002')}>
                                        <td className={classes.tdth}>9002</td>
                                        <td className={classes.tdth}>{t('common:print.9002')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

function ChildModalPreview(props) {
    const classes = useStyles();

    const [modalStyle] = useState(getModalStylePreview);

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(null);

    const [environment] = useState(getCurrentEnvironnement());


    const [t, i18n] = useTranslation('common');

    const handleClose = () => {
        setOpen(false);
        props.close();
    };



    useEffect(() => {
        if(props.open){
            const dataUrl = URL.createObjectURL(props.pdf);
            setContent(dataUrl);
            setOpen(props.open)
        }

    }, [props.open])

    useEffect(() => {
        if(props.open){
            const dataUrl = URL.createObjectURL(props.pdf);
            setContent(dataUrl);
        }

    },[props.pdf])


    const [tabId, setTabId] = React.useState("tab0")
    const handleChange = (event, id) => setTabId(id)

    const handleDownload = () => {
        // process to auto download it
        const dt = new Date();
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        const fileURL = URL.createObjectURL(props.pdf);
        const link = document.createElement('a');
        const filename = 'Doc-' + padL(dt.getFullYear().toString().substr(-2)) + padL(dt.getMonth()+1) + padL(dt.getDate()) + '-' +  padL(dt.getHours()) + padL(dt.getMinutes()) + '.pdf';
        link.href = fileURL;
        link.download = filename;
        link.click();
    }


    const fieldConcatFunction = (okSignValue) => {
        const regex = /[0-9]{1,}-[0-9]{1,}-[0-9]{1,}/g;


        let fieldConcat = "";
        let fieldConcatSplit = okSignValue.toString().split(';');

        fieldConcatSplit.map((txt,i) => {

            if (txt.match(regex)) {
                let fieldSubConcatSplit = txt.toString().split('-');

                if(props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'tablemulti' || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'tablemultin' || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'document' || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'smailvalidmulti'){
                    let tmpValueFromArray = "";

                    try {
                        let decodeActualValue = JSON.parse(props.plugnote['field'+fieldSubConcatSplit[0]]);

                        if(props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'tablemulti'){
                            tmpValueFromArray = Object.values(decodeActualValue[(fieldSubConcatSplit[1] - 1)])[(fieldSubConcatSplit[2] - 1)];
                        }else{
                            tmpValueFromArray = Object.values(decodeActualValue.list[(fieldSubConcatSplit[1] - 1)])[(fieldSubConcatSplit[2] - 1)];
                        }
                        fieldConcat += tmpValueFromArray;

                    }catch (e) {
/*                        confirmAlert({
                            title: i18n.t('common:global.plugnotes'),
                            message: i18n.t('common:global.administrator-error'),
                            buttons: [
                                {
                                    label: i18n.t('common:global.ok'),
                                }
                            ]
                        });*/
                    }

                }
                else if(props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'free-single-select' || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'single-select'  || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'free-multiple-select'  || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'multiple-select' || props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'select'  ){
                    try {
                        let tmpSelectValue = JSON.parse(props.plugnote['field'+fieldSubConcatSplit[0]]);
                        if(isArray(tmpSelectValue)){
                            if(tmpSelectValue.length > 0){
                                if(tmpSelectValue.length === 1){
                                    fieldConcat += tmpSelectValue[0];
                                }else{
                                    fieldConcat += tmpSelectValue.join(' ~ ');
                                }
                            }
                        }
                    }catch (e) {
                        fieldConcat = "";
                    }
                }
                else if(props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'boolean'){
                    if(props.plugnote['field'+fieldSubConcatSplit[0]] === '1' || props.plugnote['field'+fieldSubConcatSplit[0]] === 'Y' || props.plugnote['field'+fieldSubConcatSplit[0]] === true){
                        fieldConcat += 'X';
                    }
                }
                else if(props.plugnote['field'+fieldSubConcatSplit[0]+'format'] === 'intdatamaj'){
                    try {
                        let decodeActualValue = JSON.parse(props.plugnote['field'+fieldSubConcatSplit[0]]);
                        if(decodeActualValue.hasOwnProperty('value') && decodeActualValue.hasOwnProperty('updatedDate')){
                            if(decodeActualValue.updatedDate.toString() !== ""){
                                fieldConcat += decodeActualValue.value.toString() + " " + decodeActualValue.updatedDate;
                            }else{
                                fieldConcat += decodeActualValue.value.toString();
                            }
                        }
                    }catch (e) {

                    }
                }
                else{
                    fieldConcat += props.plugnote['field'+fieldSubConcatSplit[0]];
                }

                if((fieldConcatSplit.length - 1) !== i){
                    fieldConcat += ' ';
                }
            }else{
                if(typeof props.plugnote['field'+txt] !== "undefined"){
                    fieldConcat += props.plugnote['field'+txt];
                    if((fieldConcatSplit.length - 1) !== i){
                        fieldConcat += ' ';
                    }
                }
            }
        })

        return fieldConcat;
    }

    const handleOKSign = () => {

        let reader = new FileReader();
        reader.readAsDataURL(props.pdf);
        reader.onloadend = function() {
            const dt = new Date();

            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            const filename = 'Oki-' + padL(dt.getFullYear().toString().substr(-2)) + padL(dt.getMonth() + 1) + padL(dt.getDate()) + '-' + padL(dt.getHours()) + padL(dt.getMinutes()) + '.pdf';

            let file = new File([props.pdf], filename);
            file.base64data = reader.result;

            let url = ApplicationConfig.apiv2Url + 'User/SignPdf.php';

            let signs = [];

             props.okSign.map((si, index) => {
                 signs.push(si.attach)
             })

            let data = {
                "uh": environment.userhash,
                "datahash": props.plugnote.hash,
                "modelhash": props.model.hash,
                "modelshared": props.model.isModelShared,
                "filename": filename,
                "signs": signs,
                'user-name' : "",
                'user-email' : "",
                'user-mobile' : "",
                "user-fonction": "Signataire",
                "sign-width": props.okSign[0].value[8],
                "sign-height": props.okSign[0].value[9],
                "pdf": file.base64data.replace('data:application/pdf;base64,', '')
            }

            if(typeof props.okSign[0].value[11] !== "undefined" && props.okSign[0].value[11] !== ''){
                data['user-name'] = fieldConcatFunction(props.okSign[0].value[11]);
            }

            if(typeof props.okSign[0].value[12] !== "undefined" && props.okSign[0].value[12] !== ''){
                data['user-email'] = fieldConcatFunction(props.okSign[0].value[12]);
            }

            if(typeof props.okSign[0].value[13] !== "undefined" && props.okSign[0].value[13] !== ''){
                data['user-mobile'] = fieldConcatFunction(props.okSign[0].value[13]);
            }

           trackPromise(
                axios.post(url
                    , data,{
                        responseType: 'json',
                        crossdomain: true,
                        'headers': {
                            'X-AUTH-TOKEN': getToken(),
                        }
                    }).then((response) => {
                        if(response.data.result === true){
                            if(typeof response.data.signUrl !== "undefined"){
                                window.open(response.data.signUrl, '_blank').focus();
                                setOpen(false);
                                props.fullClose();
                                confirmAlert({
                                    title: i18n.t('common:global.plugnotes'),
                                    message: i18n.t('common:global.okSign-refresh'),
                                    buttons: [
                                        {
                                            label: i18n.t('common:global.ok'),
                                        }
                                    ]
                                });
                            }
                        }else{
                            confirmAlert({
                                title: i18n.t('common:global.plugnotes'),
                                message: i18n.t('common:global.okSign-error'),
                                buttons: [
                                    {
                                        label: i18n.t('common:global.ok'),
                                    }
                                ]
                            });
                        }
                    }).catch((response) => {

                }));



        }
    }


    const downloadPreviewAndAttachFileToAnnexe = () => {
        let reader = new FileReader();
        reader.readAsDataURL(props.pdf);
        reader.onloadend = function() {

            const dt = new Date();
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            const filename = 'Doc-' + padL(dt.getFullYear().toString().substr(-2)) + padL(dt.getMonth()+1) + padL(dt.getDate()) + '-' +  padL(dt.getHours()) + padL(dt.getMinutes()) + '.pdf';

            let file = new File([props.pdf], filename);
            file.base64data = reader.result;
            props.onChangeHandlerDrop([file]);
            setOpen(false);
            props.fullClose();
        }
    }

    return (
        <div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <div style={modalStyle} className={classes.popup}>
                    <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                        <div className="">
                            <div className="sous-header_modal_content">
                                <div className="display_justify_content">
                                    {
                                        props.isTPL24 &&
                                            <Tooltip title={t('common:global.draw-ok-sign')}>
                                                <button className={`${classes.sous_header_button} ${classes.button_sign}`} onClick={handleOKSign}>
                                                    <span className="material-icons" style={{ color: colors.hoverBgnd}}>
                                                        draw
                                                    </span>{" "}
                                                </button>
                                            </Tooltip>
                                    }
                                    <Tooltip title={t('common:global.download')}>
                                        <button className={`${classes.sous_header_button} ${classes.button_save}`} onClick={handleDownload}>
                                        <span className="material-icons" style={{ color: colors.hoverBgnd}}>
                                            download
                                        </span>{" "}
                                        </button>
                                    </Tooltip>

                                    <Tooltip title={t('global.cancel-exit')}>
                                        <button className={`${classes.sous_header_button} ${classes.button_cancel}`} onClick={() => handleClose()}>
                                        <span className="material-icons" style={{ color: colors.main}} >
                                            cancel
                                        </span>
                                        </button>
                                    </Tooltip>

                                    {
                                        props?.onChangeHandlerDrop &&
                                        <Tooltip title={t('common:global.safe-exit')}>
                                            <button className={`${classes.sous_header_button} ${classes.button_save}`} onClick={downloadPreviewAndAttachFileToAnnexe}>
                                            <span className="material-icons" style={{color: colors.hoverBgnd}}>
                                                logout
                                            </span>{" "}
                                            </button>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <div>
                                <div className={classes.pdfCtn}>
                                    <iframe title='pdf-preview' src={content + "#toolbar=1&Fit"} className={classes.previewCtn} height="100%"/>
                                </div>
                                <div>
{/*                                    {
                                        props.sign.length > 0 &&
                                        <div>
                                            <span>signer document</span>
                                            {
                                                props.sign.map((elm, i) => {
                                                    return (
                                                        <SignComponent key={i} indice={i} sign={props.sign} setSign={props.setSign} handlePreviewTemplateReload={props.handlePreviewTemplateReload}/>
                                                    )
                                                })
                                            }
                                        </div>
                                    }*/}
                                    <div className={classes.root}>

                                            <TabContext value={tabId}>
                                                <TabList
                                                    value={tabId}
                                                    onChange={handleChange}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs"
                                                >
                                                    {
                                                        props.sign.map((elm, i) => {
                                                            return (
                                                                <Tab label={`Signature `+ (i + 1)} value={`tab`+i} key={i}/>
                                                            )
                                                        })
                                                    }
                                                </TabList>
                                                {
                                                    props.sign.map((elm, i) => {
                                                        return (
                                                            <TabPanel value={`tab`+i} index={i} key={i}>
                                                                <div className="padding_5">
                                                                    <SignComponent key={i} indice={i} element={elm} sign={props.sign} setSign={props.setSign} handlePreviewTemplateReload={props.handlePreviewTemplateReload}/>
                                                                </div>
                                                            </TabPanel>
                                                        )
                                                    })
                                                }
                                            </TabContext>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}


const printableListTemplate = ['1000','1001','1002','1003', '1004' ,'1005', '1006', '2001', '9002'];

const ModalPrintComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [open, setOpen] = useState(true);
    const [view, setView] = useState(false);
    const [title, setTitle] = useState(t('common:print.code-print'));

    const [model, setModel] = useState(null);
    const [plugnote, setPlugnote] = useState(null);
    const [annexes, setAnnexes] = useState(null);
    const [fields, setFields] = useState(null);
    const [user, setUser] = useState(null);
    const [text, setText] = useState("2001");
    const [fileName, setFileName] = useState("");
    const [plugcode, setPlugcode] = useState('');

    const [previewTPL23, setPreviewTPL23] = useState(false);
    const [previewTPL23Content, setPreviewTPL23Content] = useState(null);
    const [sign, setSign] = useState([]);
    const [okSign, setOkSign] = useState([]);
    const [reloadPdf, setReloadPdf] = useState(false);
    const [isTPL24, setIsTPL24] = useState(false);
    const [environment] = useState(getCurrentEnvironnement());

    const [openSubModal, setOpenSubModal] = useState(false);
    const handleOpen = () => setOpenSubModal(true);
    const handleClose = () => setOpenSubModal(false);


    const handleCloseModal = () => {
        props.close();
    };

    const handleResetSearch = () => {
        setText("");
    }

    const handleChangeText = (value) => {
        setText(value);
    }

    const handleViewCodeList = () => {
        handleOpen();
    }

    const handlePreviewTemplateReload = () => {
        setReloadPdf(true);
    }

    const handlePreviewTemplate = () => {

        const regex = /[0-9]{1,}-[0-9]{1,}-[0-9]{1,}/g;

        setReloadPdf(false);

        let modelclef = 'TPL23';
        let serachValue = text;

        let url = ApplicationConfig.apiUrl + `tpl/data/uniq/by/indice.json?clef=${modelclef}&uh=${environment.userhash}&internal=${environment.userhash}&indice=${serachValue}&source=${model.clef}`;

        if(model.isModelShared){
            url = ApplicationConfig.apiUrl + `tpl/data/uniq/by/indice.json?clef=${modelclef}&uh=${environment.userhash}&internal=${environment.userhash}&indice=${serachValue}&source=${model.clef}&shared=${true}&owner=${model.mainOwner}`
        }

        trackPromise(
            axios.get(url, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
                .then((response) => {
                if(response.status === 200 && response.data.hasOwnProperty('model') && response.data.hasOwnProperty('data')){

                    try {
                        let linkedField = [];
                        let linkedFieldDatas = [];
                        let originPlugnote = response.data.data;
                        let originModel = getModel();
                        let uniqPlugnote =  getPlugnote();
                        let fields = JSON.parse(originPlugnote.field5);
                        fields = fields.sort();
                        let pdfurl = ApplicationConfig.downloadUrl + environment.userhash + '/' + originPlugnote.hash + '/' + originPlugnote.field2;
                        if(model.isModelShared){
                            pdfurl = ApplicationConfig.downloadUrl + model.mainOwner + '/' + originPlugnote.hash + '/' + originPlugnote.field2;
                        }

                        fields.map((field, index) => {

                            let value = Object.values(field);
                            let tmp;

                            if(value[0] === 'T1 Data' || value[0] === 'T2 Table'){

                                let fieldSlit = value[1].toString().split(';');
                                let contentField = '';
                                fieldSlit.map((field, fieldIndex) => {
                                    let uField = field;


                                    if(typeof uniqPlugnote['field'+uField] !== "undefined"){
                                        if(uniqPlugnote['field'+uField+'format'] === 'tablemulti' || uniqPlugnote['field'+uField+'format'] === 'tablemultin' || uniqPlugnote['field'+uField+'format'] === 'document' || uniqPlugnote['field'+uField+'format'] === 'smailvalidmulti'){
                                            let tmpValueFromArray = "";

                                            try {
                                                let decodeActualValue = JSON.parse(uniqPlugnote['field'+uField]);

                                                let columsTmp = value[3].toString().split(';');

                                                columsTmp.map((columTmp) => {
                                                    if(uniqPlugnote['field'+uField+'format'] === 'tablemulti'){
                                                        tmpValueFromArray = Object.values(decodeActualValue[(value[2] - 1)])[(columTmp - 1)];
                                                    }else{
                                                        tmpValueFromArray = Object.values(decodeActualValue.list[(value[2] - 1)])[(columTmp - 1)];
                                                    }
                                                    contentField += ' ' + tmpValueFromArray;
                                                })
                                            }catch (e) {
/*                                                confirmAlert({
                                                    title: i18n.t('common:global.plugnotes'),
                                                    message: i18n.t('common:global.administrator-error'),
                                                    buttons: [
                                                        {
                                                            label: i18n.t('common:global.ok'),
                                                        }
                                                    ]
                                                });*/
                                            }

                                        }
                                        else if(uniqPlugnote['field'+uField+'format'] === 'free-single-select' || uniqPlugnote['field'+uField+'format'] === 'single-select'  || uniqPlugnote['field'+uField+'format'] === 'free-multiple-select'  || uniqPlugnote['field'+uField+'format'] === 'multiple-select' || uniqPlugnote['field'+uField+'format'] === 'select'  ){
                                            try {
                                                let tmpSelectValue = JSON.parse(uniqPlugnote['field'+uField]);
                                                if(isArray(tmpSelectValue)){
                                                    if(tmpSelectValue.length > 0){
                                                        if(tmpSelectValue.length === 1){
                                                            contentField += tmpSelectValue[0];
                                                        }else{
                                                            contentField += tmpSelectValue.join(' ~ ');
                                                        }
                                                    }
                                                }
                                            }catch (e) {
                                                contentField = "";
                                            }
                                        }
                                        else if(uniqPlugnote['field'+uField+'format'] === 'boolean'){
                                            if(uniqPlugnote['field'+uField] === '1' || uniqPlugnote['field'+uField] === 'Y' || uniqPlugnote['field'+uField] === true){
                                                contentField += 'X';
                                            }
                                        }
                                        else if(uniqPlugnote['field'+uField+'format'] === 'intdatamaj'){
                                            try {
                                                let decodeActualValue = JSON.parse(uniqPlugnote['field'+uField]);
                                                if(decodeActualValue.hasOwnProperty('value') && decodeActualValue.hasOwnProperty('updatedDate')){
                                                    if(decodeActualValue.updatedDate.toString() !== ""){
                                                        contentField += decodeActualValue.value.toString() + " " + decodeActualValue.updatedDate;
                                                    }else{
                                                        contentField += decodeActualValue.value.toString();
                                                    }
                                                }
                                            }catch (e) {

                                            }
                                        }
                                        else{
                                            contentField += uniqPlugnote['field'+uField];
                                        }
                                    }
                                })

                                if(contentField !== ''){
                                    tmp = {'t'  : 'text', 'pnb' : value[4], 'x' : value[5], 'y': value[6], 'ct' : contentField, 'fs' : value[7]};
                                    linkedField.push(tmp);
                                }

                            }
                            else if(value[0] === 'T4 PN'){
                                    tmp = {'t'  :  'text', 'pnb' : value[4], 'x' : value[5], 'y': value[6], 'sx' : 0, 'sy': 0, 'ct' : _getCodePnPpCodeTemplate(uniqPlugnote), 'fs' : value[7]};
                                    linkedField.push(tmp);

                                }
                            else if(value[0] === 'T3 Sign'){
                                    if(sign.length > 0){
                                        sign.map((s, i) => {
                                            if(s.index === index && s.value !== null){
                                                tmp = {'t'  :  'img', 'pnb' : value[4], 'x' : value[5], 'y': value[6], 'sx' : value[8], 'sy': value[9], 'ct' : s.value, 'fs' : value[7]};
                                                linkedField.push(tmp);
                                                if(!isNull(s.text)){
                                                    tmp = {'t'  :  'text', 'pnb' : value[4], 'x' : value[5], 'y': value[6] + 10, 'sx' : 0, 'sy': 0, 'ct' : s.text, 'fs' : 8};
                                                    linkedField.push(tmp);
                                                }
                                            }
                                        });
                                    }else{
                                        setSign(oldArray => [...oldArray, {"index" : index , "value" :  null, "title" : value[10], 'text' : null}]);
                                    }
                            }
                            else if(value[0] === 'T5 OkSi'){

                                let fieldConcat = "";
                                let fieldConcatSplit = value[1].toString().split(';');

                                    fieldConcatSplit.map((txt,i) => {

                                    if(txt.match(regex)){
                                        let fieldSubConcatSplit = txt.toString().split('-');
                                        if(typeof uniqPlugnote['field'+fieldSubConcatSplit[0]] !== "undefined"){

                                            if(uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'tablemulti' || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'tablemultin' || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'document' || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'smailvalidmulti'){
                                                let tmpValueFromArray = "";

                                                try {
                                                    let decodeActualValue = JSON.parse(uniqPlugnote['field'+fieldSubConcatSplit[0]]);

                                                    if(uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'tablemulti'){
                                                        tmpValueFromArray = Object.values(decodeActualValue[(fieldSubConcatSplit[1] - 1)])[(fieldSubConcatSplit[2] - 1)];
                                                    }else{
                                                        tmpValueFromArray = Object.values(decodeActualValue.list[(fieldSubConcatSplit[1] - 1)])[(fieldSubConcatSplit[2] - 1)];
                                                    }
                                                    fieldConcat += tmpValueFromArray;

                                                }catch (e) {
/*                                                    confirmAlert({
                                                        title: i18n.t('common:global.plugnotes'),
                                                        message: i18n.t('common:global.administrator-error'),
                                                        buttons: [
                                                            {
                                                                label: i18n.t('common:global.ok'),
                                                            }
                                                        ]
                                                    });*/
                                                }

                                            }
                                            else if(uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'free-single-select' || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'single-select'  || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'free-multiple-select'  || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'multiple-select' || uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'select'  ){
                                                try {
                                                    let tmpSelectValue = JSON.parse(uniqPlugnote['field'+fieldSubConcatSplit[0]]);
                                                    if(isArray(tmpSelectValue)){
                                                        if(tmpSelectValue.length > 0){
                                                            if(tmpSelectValue.length === 1){
                                                                fieldConcat += tmpSelectValue[0];
                                                            }else{
                                                                fieldConcat += tmpSelectValue.join(' ~ ');
                                                            }
                                                        }
                                                    }
                                                }catch (e) {
                                                    fieldConcat = "";
                                                }
                                            }
                                            else if(uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'boolean'){
                                                if(uniqPlugnote['field'+fieldSubConcatSplit[0]] === '1' || uniqPlugnote['field'+fieldSubConcatSplit[0]] === 'Y' || uniqPlugnote['field'+fieldSubConcatSplit[0]] === true){
                                                    fieldConcat += 'X';
                                                }
                                            }
                                            else if(uniqPlugnote['field'+fieldSubConcatSplit[0]+'format'] === 'intdatamaj'){
                                                try {
                                                    let decodeActualValue = JSON.parse(uniqPlugnote['field'+fieldSubConcatSplit[0]]);
                                                    if(decodeActualValue.hasOwnProperty('value') && decodeActualValue.hasOwnProperty('updatedDate')){
                                                        if(decodeActualValue.updatedDate.toString() !== ""){
                                                            fieldConcat += decodeActualValue.value.toString() + " " + decodeActualValue.updatedDate;
                                                        }else{
                                                            fieldConcat += decodeActualValue.value.toString();
                                                        }
                                                    }
                                                }catch (e) {

                                                }
                                            }
                                            else{
                                                fieldConcat += uniqPlugnote['field'+fieldSubConcatSplit[0]];
                                            }

                                            if((fieldConcatSplit.length - 1) !== i){
                                                fieldConcat += '~';
                                            }
                                        }

                                    }
                                    else{
                                        if(typeof uniqPlugnote['field'+txt] !== "undefined"){
                                            fieldConcat += uniqPlugnote['field'+txt];
                                            if((fieldConcatSplit.length - 1) !== i){
                                                fieldConcat += '~';
                                            }
                                        }
                                    }
                                });

                                    fieldConcat = fieldConcat.replaceAll(' ', '~')
                                    tmp = {'t'  :  'text', 'pnb' : value[4], 'x' : value[5], 'y': value[6], 'sx' : 0, 'sy': 0, 'ct' : value[10] + ' ' + fieldConcat+'~'+ index, 'fs' : value[7]};
                                    linkedField.push(tmp);
                                    setOkSign(oldArray => [...oldArray, {"index" : index , "value" :  value, attach : fieldConcat+'~'+ index}]);
                                    setIsTPL24(true);
                            }
                            else if(value[0] === 'T6 Memo'){

                                try {
                                    let fieldSlit = value[1].toString().split(';');
                                    let contentField = '';

                                    fieldSlit.map((field, fieldIndex) => {

                                        let uField = field;

                                        if(typeof uniqPlugnote['field'+uField] !== "undefined"){
                                            if(uniqPlugnote['field'+uField+'format'] === 'textarea'){
                                                contentField += uniqPlugnote['field'+uField];
                                            }
                                        }

                                        if(contentField !== ''){

                                            let start = 0;
                                            let contentArray = [];

                                            if(value[2]){
                                                for(let i = 0; i < value[2]; i++){
                                                    if(contentField.substring(start, (start + value[8]))){
                                                        contentArray.push(contentField.substring(start, (start + value[8])));
                                                        start = start + value[8];
                                                    }
                                                }

                                                if(contentArray.length  > 0 && contentArray.concat().length < contentField.length){
                                                    contentArray[(contentArray.length  - 1)] = contentArray[(contentArray.length  - 1)] + '...';
                                                }
                                            }

                                            if(contentArray.length  > 0){
                                                let yTmp =  value[6];
                                                let first = true;
                                                for(let i = 0; i < contentArray.length; i++){
                                                    if(!first){
                                                        yTmp = yTmp +  value[9];
                                                    }
                                                    tmp = {'t'  : 'text', 'pnb' : value[4], 'x' : value[5], 'y': yTmp, 'ct' : contentArray[i], 'fs' : value[7]};
                                                    first = false;
                                                    linkedField.push(tmp);
                                                }
                                            }
                                        }
                                    })
                                }catch (e) {

                                }
                            }
                            else if(value[0] === 'T7 PTable' || value[0] === 'T8 PVTable'){
                                try {
                                    let fieldSlit = value[1].toString().split(';');

                                    fieldSlit.map((field, fieldIndex) => {

                                        let uField = field;

                                        if(typeof uniqPlugnote['field'+uField] !== "undefined"){
                                            if(uniqPlugnote['field'+uField+'format'] === 'tablemulti' || uniqPlugnote['field'+uField+'format'] === 'tablemultin' || uniqPlugnote['field'+uField+'format'] === 'document' || uniqPlugnote['field'+uField+'format'] === 'smailvalidmulti'){

                                                let decodePlugnoteValues = JSON.parse(uniqPlugnote['field'+uField]);
                                                let decodePlugnoteValuesLists = typeof decodePlugnoteValues.list === "undefined" ? decodePlugnoteValues: decodePlugnoteValues.list;
                                                let decodeModelOptions = JSON.parse(originModel['field'+uField+'formatoptions']);
                                                let decodeModelOptionsLines = typeof decodeModelOptions.lines === "undefined" ? decodeModelOptions: decodeModelOptions.lines;
                                                let tmparray = [];

                                                decodePlugnoteValuesLists.map((vOptions, indes) => {
                                                    let line = {};

                                                    decodeModelOptionsLines.map((option, index) => {

                                                        let label = option.label.toString();
                                                        let customLabel = "";
                                                        if (label.includes('~')) {
                                                            const parts = label.split("~");
                                                            parts.map((part, i) => {
                                                                let section;
                                                                if (part.includes('#')) {
                                                                    section = part.split('#')[0] + '...';
                                                                } else section = part;

                                                                if (i !== parts.length - 1) {
                                                                    customLabel += section + '<br/>';
                                                                }else customLabel += section;
                                                            });
                                                        }
                                                        else if (label.includes('#')) {
                                                            const index = label.indexOf("#");
                                                            customLabel = label.slice(0, index) + '...';
                                                        }
                                                        else {
                                                            customLabel = label;
                                                        }

                                                        if(value[0] === 'T7 PTable'){
                                                            if((typeof option.showOnPdf !== "undefined" && option.showOnPdf === true) || typeof option.showOnPdf === "undefined" ){
                                                                line[customLabel] = vOptions[option.id];
                                                            }

                                                        }else if(value[0] === 'T8 PVTable'){
                                                            line[customLabel] = vOptions[option.id];
                                                        }
                                                    });

                                                    if(!isEmpty(line)){
                                                        tmparray.push(line)
                                                    }

                                                });

                                                if(tmparray.length > 0){
                                                    tmp = {'pnb' : value[4], 'x' : value[5], 'y': value[6], 'data' : tmparray, 'fs' : value[7]  , 'cw' : 0, 'ch' : 0, 'tbstyle' : "border: 1px solid black; border-spacing: 0", 'tdstyle' : "color:#000;border: 1px solid black", 'thstyle' : "color: black; background-color: #B9E5E4;border: 1px solid black" };
                                                    linkedFieldDatas.push(tmp);
                                                }
                                            }
                                        }
                                    })
                                }catch (e) {
                                    //console.log(e.message);
                                    //console.log(e.line);
                                }
                            }


                        });

                        linkedField = linkedField.filter(Boolean);
                        linkedFieldDatas = linkedFieldDatas.filter(Boolean);

                        trackPromise(
                            axios.post(ApplicationConfig.apiv2Url + 'Pdf/WriteOverPdf.php'
                                , {
                                    "uh": environment.userhash,
                                    "src": pdfurl,
                                    "cts": linkedField,
                                    "tables": linkedFieldDatas
                                },{
                                    responseType: 'blob',
                                    crossdomain: true,
                                    'headers': {
                                        'X-AUTH-TOKEN': getToken(),
                                    }
                                }).then((response) => {
                                setPreviewTPL23Content(response.data);
                                setPreviewTPL23(true);
                            }).catch((response) => {
                                console.error(
                                    "Could not Download the Excel report from the backend.",
                                    response
                                );
                                setIsTPL24(false);
                            }))
                    }
                    catch (e) {
                        console.log(e.message);
                        setIsTPL24(false);
                    }
                }
            }).catch((response) => {
                setIsTPL24(false);
            }))
    }

    useEffect(() => {
        if(reloadPdf){
            handlePreviewTemplate();
        }
    }, [reloadPdf])


    useEffect(() => {
        if(text !== ''){
            switch (text) {
                case '1000' :
                    setView(true)
                    break;
                case '1001' :
                    setView(true)
                    break;
                case '1002' :
                    setView(true)
                    break;
                case '1003' :
                    if(plugnote !== null && fields.find(item => item.format === 'pluglink')){
                        setView(true)
                    }
                    else{
                        setView(false);
                    }
                    break;
                case '1004' :
                    setView(true);
                    break;
                case '1005' :
                    setView(true);
                    break;
                case '1006' :
                    setView(true);
                    break;
                case '2001' :
                    setView(true);
                    break;
                case '9002' :
                    setView(true);
                    break;
                default:
                    setView(false)
                    break;
            }
        }
        else {
            setView(false);
        }

    }, [text])

    useEffect(() => {
        if(props.open && props !== null){
            let currentPlugnote = props !==null ? props.plugnote : undefined;
            let environnement = getCurrentEnvironnement();
            let userIndex = typeof currentPlugnote !== "undefined" ?  currentPlugnote.userindex : 0;
            let dataNumber = userIndex.toString().padStart(6, 0);

            let model = props.model;
            let modelName = model.label;
            let modelClef = model.clef;

            let fileName = dataNumber + ' - [' + modelClef + ']';
            let _plugcode = _getCodePnPpCodeTemplate(props.plugnote);
            setModel(props.model);
            setUser(props.user);
            setPlugnote(props.plugnote);
            setAnnexes(props.plugnoteAnnexes);
            setFields(props.fields);
            setFileName(fileName);
            setPlugcode(_plugcode)
            setOpen(true);
        }
    }, [props.open])

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <TextField
                                        disabled={true}
                                        id="standard-full-width"
                                        //label={field.title}
                                        type="text"
                                        placeholder="Edit Titre"
                                        helperText=""
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //className={classes.fieldRight}
                                        defaultValue={title}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal} style={{ alignSelf: 'center' }}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                        <div className="popup_wrapper_content">
                            <Input
                                id={`standard-full-width-template-pdf`}
                                label=""
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>}
                                endAdornment={
                                    <div style={{display : 'flex' , flexDirection : 'row', marginRight : 10}}>
                                        {text.length > 0 && <Close onClick={handleResetSearch} style={{cursor : 'pointer'}}/>}
                                        <Visibility onClick={handleViewCodeList} style={{cursor : 'pointer'}} />
                                    </div>
                                }
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={`text-line ${classes.textField} input`}
                                onChange={(event) => handleChangeText(event.target.value)}
                                value={text}
                            />
                            <br/>
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1000' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1000Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1001' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1001Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1002' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1002Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1003' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1003Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1004' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1004Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1005' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1005Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '1006' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data1006Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            {
                                plugnote !== null && annexes !== null && fields !== null && user !== null && model !== null && view && text === '2001' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data2001Printable
                                    plugnote={plugnote} annexes={annexes} fields={fields} user={user}
                                    mode={props.mode}
                                    model={model} filename={fileName} plugcode={plugcode} t={t}
                                />
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && view && text === '9002' && open && !openSubModal && printableListTemplate.includes(text) &&
                                <Data9002Printable plugnote={plugnote} annexes={annexes} fields={fields} user={user} model={model} filename={fileName}/>
                            }
                            { plugnote !== null && annexes!== null && fields !== null && user !== null && model !== null && !view && open && !openSubModal && !printableListTemplate.includes(text) && text !== "" &&
                                <div className="fullWidth">
                                    <button onClick={handlePreviewTemplate} className="button fullWidth">
                                        <span className="material-icons md-25 ">search</span>
                                        <div className={classes.pointer}>
                                            {t("common:global.view")}
                                        </div>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                <ChildModal open={openSubModal} close={handleClose} fields={fields} setText={setText}/>
                <ChildModalPreview model={model} plugnote={plugnote} open={previewTPL23} close={(event) => {setPreviewTPL23(false); setPreviewTPL23Content(null)}} fullClose={(event) => {setPreviewTPL23(false); setPreviewTPL23Content(null); handleCloseModal()}} pdf={previewTPL23Content} sign={sign} setSign={setSign} handlePreviewTemplateReload={handlePreviewTemplateReload} onChangeHandlerDrop={props.onChangeHandlerDrop} isTPL24={isTPL24} okSign={okSign}/>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    // console.log('Modale d\'impression state', state)
    return {
        // userhash: state.user.profil.hash,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrintComponent)
