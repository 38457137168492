import { colors, makeStyles } from "@material-ui/core";

const useTooltipStyle =  makeStyles({
  tooltip: {
    backgroundColor: colors.grey,
    color: colors.white,
    border: '1px solid',
    padding: '3px 8px',
    margin: 5,
    fontSize: '16px',
    borderRadius: 5
  },
});

export { useTooltipStyle };