import {api} from "./index";
import * as qs from "querystringify";

export let
    postLoginReal,
    postLogout,
    resetPassword,
    resetNewPassword,
    getUser,
    updateUser,
    postCreateEnvironment,
    getEnvironments,
    getUserQuota,
    postCreateDemoAccount,
    getCreatedDemoAccount,
    getProfils,
    register;

postLoginReal = (username, password) => api.post('loginreals.json', {username, password});
getProfils = (userhash) => api.get( `v3userprofils/${userhash}.json`);
postLogout = (username) => api.post('logouts.json', {username: username});
resetPassword = (email, language) => api.post('v2resetpwds.json', {email, language});
resetNewPassword = (payload) => api.post('v3resetnewpwds.json', payload);
register = (payload) => api.post('v2createaccounts', payload);
getUser = (uh, umh) => api.get('users/' + umh + '.json');
updateUser = (payload, uh) => api.put('users/' + uh + '.json', payload);
postCreateEnvironment = (payload, userhash) => api.post('userprofils/' + userhash + '.json', payload);
getEnvironments = (userhash) => api.get('userprofils/' + userhash + '.json');
//récupération du quota et de l'abonnement de l'utilisateur
getUserQuota = (uh, umh) =>  api.get('packages/quota/history.json' + qs.stringify({ uh, umh }, '?'));
postCreateDemoAccount = (email) => api.post('createaccountdemos.json'+ qs.stringify({ email }, '?'));
getCreatedDemoAccount = (hash) => api.get( `createaccountdemos.json?userhash=${hash}`);
