import React from 'react';
import {Switch} from 'react-router';
import PublicRoute from "./router/PublicRoute";
import Login from "./containers/logon/Login";
import PrivateRoute from "./router/PrivateRoute";
import PlugnoteCreateV2 from "./containers/plugnotes/PlugCreateV2";
import PlugnoteUpdateV2 from "./containers/plugnotes/PlugUpdateV2";
import PlugViewSharedV2 from "./containers/plugnotes/PlugViewSharedV2";
import ModelUpdateV1 from "./containers/models/ModelUpdateV1";
import ModelUpdateV2 from "./containers/models/ModelUpdateV2";
import Register from "./containers/logon/Register";
import LostPassword from "./containers/logon/LostPassword";
import Finalize from "./containers/logon/Finalize";
import ConfirmPassword from "./containers/logon/ConfirmPassword";
import Confirm from "./containers/logon/Confirm";
import PlugcodeList from "./containers/plugcodes/PlugcodeList";
import PlugCreateFromCodeV2 from "./containers/plugcodes/PlugCreateFromCodeV2";
import PlugConsultFromCodeV2 from "./containers/plugcodes/PlugConsultFromCodeV2";
import PlugcodeCopy from "./containers/plugcodes/PlugcodeCopy";
import PlugnoteCopyMove from "./containers/plugnotes/PlugnoteCopyMove";
import Params from "./containers/params/Params";
import TestModule from "./containers/tests/Test";
import ModelSharedList from "./containers/models/ModelSharedList";
import PlugnoteSharedCreateV2 from "./containers/plugnotes/PlugnoteSharedCreateV2";
import PlugnoteSharedUpdateV2 from "./containers/plugnotes/PlugnoteSharedUpdateV2";
import InboxMail from "./containers/box/InboxMail";
import PlugCreateFromCodeGalleryV2 from "./containers/plugcodes/PlugCreateFromCodeGalleryV2";
import PlugConsultFromCodeGalleryV2 from "./containers/plugcodes/PlugConsultFromCodeGalleryV2";
import Demo from "./containers/demo/Demo";
import DemoRoute from "./router/DemoRoute";
import OpenRoute from "./router/OpenRoute";
import PlugExternalCreateFromCodeV2 from './containers/plugcodes/PlugExternalCreateFromCodeV2';
import ModelListLight from "./containers/models/ModelListLight";
import ModelTranslate from "./containers/models/ModelTranslate";
import PlugListLight from "./containers/plugnotes/PlugListLight";
import PlugSharedListLight from "./containers/plugnotes/PlugSharedListLight";
import PlugListEditV2 from "./containers/plugnotes/PlugListEditV2";
import ModelCreateV2 from "./containers/models/ModelCreateV2";
import RedirectRoute from "./router/RedirectRoute";
import RegisterV2 from './containers/logon/RegisterV2';


export default function Routes() {
    return (
        <Switch>
            /** PUBLIC ROUTE */
            <PublicRoute  exact path="/" component={Login}/>
            <PublicRoute  exact path="/lost-password" component={LostPassword}/>
            <PublicRoute  exact path="/confirm-account" component={Confirm}/>
            <PublicRoute  exact path="/confirm-password" component={ConfirmPassword}/>
            /** SECURE ROUTE */
            <PrivateRoute  exact path="/finalize-account-password" component={Finalize}/>
            <PrivateRoute  exact path="/models" component={ModelListLight}/>
            <PrivateRoute  exact path="/models-shared" component={ModelSharedList}/>
            <PrivateRoute  exact path="/create-model" component={ModelCreateV2}/>
            <PrivateRoute  exact path="/update-model-v1" component={ModelUpdateV1}/>
            <PrivateRoute  exact path="/update-model" component={ModelUpdateV2}/>
            <PrivateRoute  exact path="/translate-model" component={ModelTranslate}/>
            <PrivateRoute  exact path="/pluglist" component={PlugListLight}/>
            <PrivateRoute  exact path="/pluglist-editable" component={PlugListEditV2}/>
            <PrivateRoute  exact path="/pluglist-shared" component={PlugSharedListLight}/>
            <PrivateRoute  exact path="/create-plugnote" component={PlugnoteCreateV2}/>
            <PrivateRoute  exact path="/create-plugnote-shared" component={PlugnoteSharedCreateV2}/>
            <PrivateRoute  exact path="/update-plugnote" component={PlugnoteUpdateV2}/>
            <PrivateRoute  exact path="/update-plugnote-shared" component={PlugnoteSharedUpdateV2}/>
            <PrivateRoute  exact path="/plugnote-copy-move" component={PlugnoteCopyMove}/>
            <PrivateRoute  exact path="/plugnote-view-shared" component={PlugViewSharedV2}/>
            <PrivateRoute  exact path="/plugcodes" component={PlugcodeList}/>
            <PrivateRoute  exact path="/plugcode-complete" component={PlugCreateFromCodeV2}/>
            <PrivateRoute  exact path="/plugcode-complete-gallery" component={PlugCreateFromCodeGalleryV2}/>
            <PrivateRoute  exact path="/plugcode-consult" component={PlugConsultFromCodeV2}/>
            <PrivateRoute  exact path="/plugcode-consult-gallery" component={PlugConsultFromCodeGalleryV2}/>
            <PrivateRoute  exact path="/plugcode-copy" component={PlugcodeCopy}/>
            <PrivateRoute  exact path="/params" component={Params}/>
            <PrivateRoute  exact path="/inbox" component={InboxMail}/>
            <PrivateRoute  exact path="/test" component={TestModule}/>
            /** SPECIAL ROUTE */
            <DemoRoute  exact path="/demo" component={Demo}/>
            <OpenRoute  exact path="/register" component={RegisterV2}/>
            <RedirectRoute  exact path="/plugcodes-shared/:code?/:folder?/:fiche?"/>
        </Switch>
    );
}
