import { Checkbox, Input, InputLabel, Tooltip } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import React from "react";
import colors from "../../assets/themes/Colors";
import Dropzone from "react-dropzone";

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        position: 'absolute',
        left: 0,
        top: 0,
        paddingLeft: 10,
        paddingTop: 10,
        height: '100vh',
        width: 370,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white,
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        }
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding:'0 10px',
        gap: 10,
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    margin:{
        marginTop : '15px',
        marginBottom : '5px',
    },
    subtitle: {
        fontWeight: 'bold'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // paddingLeft: 20,
    },
    marginbuttonsBox : {
        margin : 5,
        cursor : 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height:'20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },
    selectType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    bodyContainer:{
        marginTop: '15px',
        width: '100%',
        // maxHeight: '60vh',
        // overflowY: 'auto',
    },
    inputType:{
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
        width: '100%',
    },
    visibleBox:{
        height:'40px',
        margin:'5px 0',
        justifyContent: 'left',
        textAlign: 'start',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        '& .MuiIconButton-label': {
            color: colors.mainButton
        },
    },
    visibleText:{
        verticalAlign: 'text-bottom',
        color: 'black',
        fontStyle: 'italic',
        fontSize: 'small',
    },
    mainContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        margin: '5px 0px 5px 5px',
        gap: 5,
        overflowY: 'auto',
    },
    selectsContainer: {
        gap: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    selectItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
        minHeight: 27,
    },
    dropzoneContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        //marginLeft: -15,
        marginRight: '10px',
        //position: 'absolute',
        position: 'sticky',
        bottom: 0
    },
    dropZone: {
        alignSelf: "center",
        cursor: 'pointer'
    },
}))

export function SetupSelects (props) {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const field = props.field
    const index = props.index
    const newData = props.newData
    const newDataSelect = props.newDataSelect
    const visibleByDefault = props.visibleByDefault

    let formatLabel = ''
    switch(props.format) {
        case 'F11':
        case 'T01':
            formatLabel = t("common:formats.preselection-type-unique")
            break;
        case 'F12':
        case 'T02':
            formatLabel = t("common:formats.preselection-type-unique-and-free")
            break;
        case 'F13':
        case 'T03':
            formatLabel = t("common:formats.preselection-type-multiple")
            break;
        case 'F14':
        case 'T04':
            formatLabel = t("common:formats.preselection-type-multiple-and-free")
            break;
        case 'F23':
            formatLabel = t("common:formats.preselection-table-type-unique")
            break;
        case 'F24':
            formatLabel = t("common:formats.preselection-table-type-unique-and-free")
            break;
        case 'F25':
            formatLabel = t("common:formats.preselection-table-type-multiple")
            break;
        case 'F26':
            formatLabel = t("common:formats.preselection-table-type-multiple-and-free")
            break;

        default:
            break;
    }
    // console.log('setup selects props', props);

    return(
    <div className={classes.sideMenu}>
        <div className={classes.sideHeader}>
            <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"}>{formatLabel}</InputLabel>

            <span id="icon-close" className="material-icons md-50 lightgrey" onClick={() => {props.handleClose()}}>
                close
            </span>
        </div>
        <div className={classes.mainContainer}>
            <div className={classes.inputType}>
                <InputLabel className={classes.subtitle}>{t('common:ext-table.for-field')} {props.field.id}: {props.field.label}</InputLabel>
                <InputLabel className={classes.margin}>{t('common:selects.instructions')}</InputLabel>
                <div className="flex_display_direction_row div_width_100">
                    <Input
                        autoComplete="new-password"
                        id={'new-select-input-' + props.field.id}
                        label="Label"
                        style={{ width: '100%' }}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{ shrink: true }}
                        value={newData}
                        className="text-line"
                        onChange={(event) => props.setNewData(event.target.value)}
                        onKeyPress={(e) => { if (e.key === 'Enter') { props.handleAddPresel(newData, props.field, props.index) }}}
                    />
                    <span id="icon-add-playlist" className="material-icons md-small" onClick={() => props.handleAddPresel(newData, props.field, props.index)}>
                        playlist_add
                    </span>
                </div>
            </div>

            <div className={classes.bodyContainer}>
                {props.handleChangeVisibility !== null &&
                <div className={classes.visibleBox} onClick={() => {props.handleChangeVisibility(!visibleByDefault, props.field, props.index)}}>
                    <Checkbox
                        style={{color: colors.main, width: '30px', marginRight: '5px'}}
                        checked={visibleByDefault}
                        onChange={() => {props.handleChangeVisibility(!visibleByDefault, props.field, props.index)}}
                        />
                    <span className={classes.visibleText}>{ t('plugnote.make-visible-default') }</span>
                </div>}
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"}>{ t('common:selects.select-list') }</InputLabel>
                <div className={classes.selectsContainer}>
                    {newDataSelect.length > 0 &&
                    newDataSelect
                        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                        .map((value, index) => (
                        <div className={classes.selectItem} key={index}>
                            <span key={`select-item-${index}`}>{value}</span>
                            <Tooltip title={t('selects.delete-item')} placement="top-end">
                                <span id="icon-delete-playlist" className="material-icons md-small" onClick={() => props.handleDelPresel(value, props.field, index)}>
                                    delete
                                </span>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>

            <div className={classes.dropzoneContainer}>
                <Dropzone
                    // onDrop={acceptedFiles => console.log('dropped ',acceptedFiles)}
                    onDrop={acceptedFiles => props.onChangeHandlerDrop(acceptedFiles, field, index)}
                    >
                    {({getRootProps, getInputProps}) => (
                        <section className={classes.dropZone}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="flexbox-item-drop draganddrop m3">
                                    <span className="titres-default-line-height grey margin-auto">
                                        {t('selects.import-table')}
                                    </span>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        </div>
    </div>)
}
