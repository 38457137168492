import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Input, IconButton, Tooltip} from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import { Settings } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {ReactComponent as MultiTable} from '../../assets/images/format-icon/svg/multi-table.svg'
import {ReactComponent as MultiTableAdd} from '../../assets/images/format-icon/svg/free-multi-table.svg'
import {ReactComponent as SingleTable} from '../../assets/images/format-icon/svg/single-table.svg'
import {ReactComponent as SingleTableAdd} from '../../assets/images/format-icon/svg/free-single-table.svg'
import { SetupSelects } from "../custom/SetupSelects";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        width : '100%',
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    selectType:{
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    inputType:{
        marginTop: '15px',
    },
    inputListType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    visibleBox:{
        height:'40px',
        margin:'5px 0',
        justifyContent: 'left',
        textAlign: 'start',
        alignItems: 'center',
        display: 'flex',
    },
    visibleText:{
        verticalAlign: 'text-bottom',
        color: 'black',
        fontStyle: 'italic',
        fontSize: 'small',
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        // '&:hover': {
        //     borderRadius: 50,
        //     background: colors.frost
        // }
    },
    fieldIcon: {
        // stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 20,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer'
    },
    preselConfig: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5
    },
}));

const getTypeValue = (value) => {
    // console.log('get value', value);
    let multiselect;
    let editable;
    if (value === null || value === undefined || value === '') {
        return 'F23';
    } 
    else {
        multiselect = value.multiselect
        editable = value.editable
        if(multiselect === false && editable === false){
            return 'F23';
        }
        else if(multiselect === false && editable === true){
            return 'F24';
        }
        else if(multiselect === true && editable === false){
            return 'F25';
        }
        else if(multiselect === true && editable === true){
            return 'F26';
        }
    }
}

const RenderTypeList = (props) => {
    const { t, } = useTranslation(['common', 'models']);
    const selectTypes = ['F23', 'F24', 'F25', 'F26']
    const classes = useStyles()
    const [newData, setNewData] = useState(getTypeValue(props.formatoptions));

    useEffect(() => {
        setNewData(getTypeValue(props.formatoptions));
    }, [props.formatoptions]);

    const  handleTypeChange = (value, index) =>{
        setNewData(value.toString());
        props.handleUpdateType(value, index);
    }
    function rowRender (value)
        {
        let icon = ""
        let tooltip = ""
        let label = ""
        switch(value) {
            case 'F23':
                icon = <SingleTable alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-two")
                label = t("selects.single")
                break;
            case'F24':
                icon = <SingleTableAdd alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-and-free-two")
                label = t("selects.single-add")
                break;
            case'F25':
                icon = <MultiTable alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-two")
                label = t("selects.multiple")
                break;
            case'F26':
                icon = <MultiTableAdd alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-and-free-two")
                label = t("selects.multiple-add")
                break;
            default:
                break;
        }
        let result = {icon: icon, label: label, tooltip: tooltip}
        // console.log('result', result);
        return result
    }
    // console.log('presel type props', props);
// freeSingleSelectIconSvg
    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {selectTypes.map((row, index) => {
                let rindex = index
                return (
                    <Tooltip title={rowRender(row).tooltip} key={"presel-" + rindex}>
                        <div className={row === newData ? classes.selectedMask : classes.unselectedMask} key={index + "-select"}
                            onClick={() => { handleTypeChange(row, props.index) }}
                        >
                            <div className={classes.fieldIcon} style={{ width: '20px', fill: 'white !important' }}>
                                {rowRender(row).icon}
                            </div>
                            {rowRender(row).label}
                        </div>
                    </Tooltip>
            )
            })}
        </div>
    );
}

const FormatTableConstructorComponent = (props) => {
    const { t } = useTranslation(['common', 'models']);
    const classes = useStyles();

    const [newDataSelect, setNewDataSelect] = useState(props.field.options.presel !== "" && props.field.options.presel !== "[]"  ? props.field.options.presel  : []);
    const [newData, setNewData] = useState('');

    let dataType = 'F23';
    let visualDefault = false;
    if(typeof props.field.options !== 'undefined'){
        let multiselect = props.field.options.multiselect;
        let editable = props.field.options.editable;
        visualDefault = props.field.options?.visibledefault || false;

        if(!multiselect && !editable){
            dataType = 'F23';
        }
        else if(!multiselect && editable){
            dataType = 'F24';
        }
        else if(multiselect && !editable){
            dataType = 'F25';
        }
        else if(multiselect && editable){
            dataType = 'F26';
        }
    }

    const [newDataType, setNewDataType] = useState(dataType);
    const [open, setOpen] = useState(false);
    const [visibleByDefault, setVisibleByDefault] = useState(visualDefault); // variable intermédiaire ; visibledefault reçoit la valeur attendue

    const handleChangeTypeSelect = (value, index) => {
        let field = {...props.field}
        let multiselect;
        let editable;
        switch (value) {
            case 'F23':
                multiselect = false;
                editable = false;
                break;
            case 'F24':
                multiselect = false;
                editable = true;
                break;
            case 'F25':
                multiselect = true;
                editable = false;
                break;
            case 'F26':
                multiselect = true;
                editable = true;
                break;
            default:
                break;
        }
        setNewDataType(value);

        field.options.editable = editable;
        field.options.multiselect = multiselect;

        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.editable = editable;
            model[index].options.multiselect = multiselect;

            props.setNewModel(model);
        }else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.editable = editable;
            model[index].options.multiselect = multiselect;

            props.setUpdateModel(model);
        }
    }

    const handleChangeVisibility = (value, field, index) => {
        setVisibleByDefault(value);

        field.options.visibledefault = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.visibledefault = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.visibledefault = value;
            props.setUpdateModel(model);
        }
        // console.log("defaultVisible props", field.options.visibledefault);
    }

    const handleAddPresel = (value, field, index) => {
        let find = field.options.presel.find((item) => item === value)

        if(!find){
            field.options.presel.push(value);
            setNewDataSelect(field.options.presel);
            setNewData('');
        }
    }

    const handleDelPresel = (value, field, index) => {
        field.options.presel.splice(index,1);
        setNewDataSelect(list => list.filter(item => item !== value));
    }

    useEffect(() => {
        if(props.field.options.presel !== "" && props.field.options.presel !== "[]" && Array.isArray(props.field.options.presel)){
            setNewDataSelect(props.field.options.presel);
        }
        else{
            setNewDataSelect([]);
        }
    }, [props.field.options.presel])

    return (
        <div className={classes.mainField}>
            <div className={classes.field}>
                <Input
                    id="standard-full-width"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div>

            <div className={classes.preselConfig}>
                <RenderTypeList {...props} formatoptions={props.field.formatoptions} index={props.index}  handleUpdateType={(value, index) => handleChangeTypeSelect(value, index)}/>
                <IconButton aria-label="expand row" size="small" style={{alignItems: 'flex-start'}} onClick={() => {setOpen(!open)}}
                >
                    {open ? <div className={classes.subOpenClose}><Settings/><KeyboardArrowUpIcon/></div> : <div className={classes.subOpenClose}><Settings/><KeyboardArrowDownIcon/></div>}
                    ({props.field.options.presel.length})
                </IconButton>
            </div>
            {
                open &&
                <SetupSelects
                    field={props.field}
                    format={newDataType}
                    handleAddPresel={handleAddPresel}
                    handleChangeVisibility={handleChangeVisibility}
                    handleClose={() => setOpen(false)}
                    handleDelPresel={handleDelPresel}
                    index={props.index}
                    newData={newData}
                    newDataSelect={newDataSelect}
                    onChangeHandlerDrop={props.onChangeHandlerDrop}
                    presels={props.field.presel}
                    setNewData={setNewData}
                    visibleByDefault={visibleByDefault}
                />
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableConstructorComponent);





