import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import colors from "../../assets/themes/Colors";
import {ReactComponent as AutoImport} from '../../../src/assets/images/format-icon/svg/auto-import.svg'
import { postExtDataReset } from "../../actions/extdata";
import ModalListExtDataComponent from "../modals/extdata/ModalListExtDataComponent";
import { Search } from "@material-ui/icons";
import { convertersAlphaToNumber, isDefined } from "../../utils/utils";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { HyperFormula } from "hyperformula";
import ModalListExtDataMultipleComponent from "../modals/extdata/ModalListExtDataMultipleComponent";
import { Tooltip } from "@material-ui/core";
import { DurationInput, PlugTooltip, RestyledNumericInput } from "../custom/CustomComponents";
import NumberFormat from "react-number-format";
import { RenderItemMultipleSelectComponent, RenderItemSingleSelectComponent } from "./../custom/FormatTable";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import {getTableView, getToken} from "../../utils/Common";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import { isNull } from "lodash";
import { getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV } from "../../utils/Fields";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	//Top buttons
	topBtnCtn: {
		zIndex: 200,
		right: 0,
		gap: 3,
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'flex-start',
		// maxHeight: 45,
	},
	urlButton:{
		/* border: '1px red solid', */
		display: 'inline-block',
		color: "white",
		backgroundColor: "#58c3c1",
		padding: "3px 15px",
		borderRadius: "3px",
		cursor: "pointer",
	},
	topBtn: {
		width: 60,
		height: 30,
		padding: 5,
		borderRadius: 5,
		boxShadow: '0px 1px 2px 1px #ddd',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		backgroundColor: colors.mainButton,
		color: 'white',
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
			borderColor: colors.mainButton,
		},
	},
	disabledBtn: {
		cursor: 'not-allowed',
		backgroundColor: colors.iconGrey,
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
			borderColor: colors.iconGrey,
		},
	},
	topBtnImg: {
		color: 'white',
		width: 20,
	},
	table: {
		userSelect: 'none',
		"&::-youbkit-user-select": {
			color: 'red',
			backgroundColor: 'yellow',
		},
	},
	tableRoot: {
		width: "99%", //it was 100%
		marginTop: 3,
		overflowX: "auto",
		//marginRight: 'auto',

	},
	margin_left_5: {
		marginLeft: '5px',
	},
	input_add_item: {
		flexDirection: 'row',
		width: '100%',
		display: 'flex'
	},
	input: {
		color: 'white',
		fontFamily: 'Arial, FontAwesome'
	},
	textField: {
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingBottom: 0,
		marginTop: 0,
		fontWeight: '500',
		borderRadius: '5px',
		height: '38px',
		lineHeight: '38px',
		paddingLeft: '10px',
		backgroundColor: '#F1F1F1',
		fontSize: '14px',
		width: '100%',
		MozBorderRadius: '5px',
		border: 'none',
		WebkitBorderRadius: '5px',
	},
	scrollBar: {
		overflowX: 'auto',
		scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
		scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
		"&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '5px',
            background: '#E3E3E3',
            width: '5px',
		},
		"&::-webkit-scrollbar-thumb": {
			background: '#9B9B9B',
		},
		//if buttons wanted
		/*  "&::-webkit-scrollbar-button:single-button": {
			backgroundColor: '#bbbbbb',
			display: 'block',
			height: '13px',
			width: '16px',
		}, */

	},
	addCellButton: {
		backgroundColor: colors.greyLighter,
	},
	rowDelCellButton: {
		backgroundColor: colors.greyLighter,
		display: 'flex',
		alignItems: 'center',
	},
	rowNum: {
		color: colors.grey,
		//fontWeight: 'bold',
	},
	tableHeader: {
		padding: 15,
	},
	tableMultiButton: {
		justifyContent: 'center',
		alignItems: 'center',
		color: colors.main,
		// fontSize: 22,
	},
	tableMultiAddButton: {
		// width : 20,
		// height : 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: colors.main,
		fontSize: 28,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	tableMultiExportButton: {
		width: 20,
		height: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: colors.main,
		color: colors.main,
		fontSize: 16,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	headerSeparator: {
		borderColor: colors.greyLight,
		borderStyle: 'solid',
		borderWidth: 0,
		borderRightWidth: 1,
		borderBottomWidth: 1,
		padding: 10,
	},
	buttonBar: {
		marginLeft: 'auto',
		backgroundColor: colors.greyLighter,
		display: 'flex',
		justifyContent: 'space-between',
		height: 30,
		alignItems: 'center'
	},
	RadioGroupCell: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingLeft: 10,
		//border: '1px solid greenyellow',
	},
	radioIcon: {
		color: colors.main,
		width: 25,
	},
	radioIconChecked: {
		color: colors.main,
	},
	radioLabel: {
		fontSize: 10,
	},
	stickyCol: {
		position: "sticky",
		left: '30px',
		background: colors.white,
		zIndex: 100,
		width: '100%',
		wordBreak: 'break-all'
	},
	stickyColRight: {
		position: "sticky",
		left: 0,
		//background: colors.white,
		zIndex: 50,
	},
	paginationSpacer: {
		flex: 'none',
	},
	width: {
		width: '99%'
	},
	paginationStyle: {
		border: '1px solid blue',
		position: "sticky",
		left: '-10px',
		background: colors.white,
		borderWidth: 0,
	},
	tableMultiPageText: {
		color: colors.main,
		fontSize: 15,
		display: 'flex',
		gap: '8px'
	},
	tableMultiPageChoice: {
		color: colors.white,
		backgroundColor: colors.unselectedAlpha,
		height: '100%',
		fontSize: 15,
		// fontWeight: 'bold',
		padding: '0 5px',
		borderRadius: '6px',
		display: 'flex',
		alignItems: 'center'
	},
	paginationStyleRow: {
		//border: '3px solid blue',
	},
	paginationStyleFooter: {
		//border: '3px solid blue',
	},
	//Render Item Single Select Component Styles
	renderSingleFieldContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		flexGrow: 1,
	},
	renderSingleSelMainContainer: {
		justifyContent: 'flex-start',
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
	},
	renderSingleSelContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		background: colors.greenLightest,
		borderRadius: 4,
		cursor: 'pointer',
		marginBottom: 3,
		flexGrow: 1,
		gap: 3,
		flexWrap: 'nowrap',
	},
	renderSingleFormulaContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		background: colors.greenLightest,
		borderRadius: 4,
		cursor: 'pointer',
		marginBottom: 3,
		flexGrow: 1,
		gap: 3,

	},
	renderSingleSubTotalContainer: {
		padding: '0 !important',
		display: 'flex',
		justifyContent: 'flex-start',
		background: colors.greenLightest,
		borderRadius: 4,
		cursor: 'pointer',
		marginBottom: 3,
		flexGrow: 1,
		gap: 3,
	},
	durationInputContainer: {
		display: 'flex',
		width: '100%',
		background: colors.greenLightest,
		marginBottom: 3,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		borderBottom: '1px solid black',
		position: 'relative'
	},
	renderSingleSelSubContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	renderSingleSelPreselNum: {
		fontSize: 10,
		color: colors.greyLight,
		border: `1px solid ${colors.greyLight}`,
		borderRadius: 50,
		padding: '1px 2px',
	},
	renderSingleSelIcon: {
		fontSize: 28,
		color: colors.greyDark,
	},
	fontChange: {
		fontSize: 12,
		fontWeight: 'bold',
	},
	fontError: {
		fontSize: 12,
		color: colors.red,
		fontWeight: 'bold',
		paddingLeft: '5px!important',
		width: '-webkit-fill-available',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	deleteBtn: {
		fontSize: 12,
		color: colors.mainButton,
		fontWeight: 'bold',
		position: 'absolute',
		right: 0
	},
	textFont: {
		fontSize: 12,
		fontWeight: 'bold',
		paddingLeft: '5px!important',
		marginTop: 5
	},
	textLength: {
		fontSize: 8,
		position: 'absolute',
		top: -6,
		right: 0,
	},
	fontChangeLabel: {
		fontSize: 12,
		fontWeight: 'bold',
		color: colors.main
	},
	fontChangeWidth: {
		fontSize: 12,
		minWidth: 120
	},
	formatInputFieldComposed: {
		borderRadius: "4px",
		paddingTop: 6,
		// width: "100%",
		padding: "5px 0",
		overflowX: 'visible!important', //temp fix for f32 size issue
	},
	formatInputFieldLockedComposed: {
		backgroundColor: colors.white,
		// marginLeft: '29%',
		paddingTop: 6,
		borderRadius: "4px",
		// width: "100%",
		overflowX: 'visible!important', //temp fix for f32 size issue
	},
	mainCtn: {
		width: '100%',
		marginTop: -30,
		overflowY: 'visible',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	tooltipCtn: {
		maxWidth: 'none',
		backgroundColor: colors.greyLighter,
		boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
		borderRadius: 8,
	},
	noTooltip: {
		backgroundColor: colors.transparent,
	},
	previewCtn: {
		backgroundColor: colors.transparent,
		margin: 10,
		maxWidth: 'none',
	},
	selectItemContainer: {
		display: 'flex',
		gap: 3,
		minHeight: 27,
		width: '100%',
		flexWrap: 'wrap',
	},
	selectItem: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	selectedItem: {
		color: colors.white,
		backgroundColor: colors.selectedAlpha,
		padding: '0px 5px',
		margin: '1px 0px',
		borderRadius: 4,
		cursor: 'pointer',
		fontSize: 13,
	},
	unselectedItem: {
		color: colors.white,
		backgroundColor: colors.unselectedAlpha,
		padding: '0px 5px',
		margin: '1px 0px',
		borderRadius: 4,
		cursor: 'pointer',
		fontSize: 13,
	},
	noSelectedItem: {
		color: colors.dark,
		fontSize: 13,
		fontStyle: 'italic',
		marginLeft: 10
	},
	selectIcon: {
		left: 2,
		fontSize: 28,
		cursor: 'pointer',
	},
	selectSelect: {
		marginLeft: 33,
		paddingLeft: 2,
	},
	selectField: {
		minHeight: 24
	},
	searchField: {
		border: "solid #E3E3E3 1px",
		borderRadius: 4,
		paddingLeft: 10,
		marginTop: 0,
		marginBottom: 0,
	},
	searchBtn: {
		fontSize: 15,
		minWidth: 70,
	},
	fieldContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		width: '100%',
		justifyContent: 'space-between',
		marginBottom: 3,
	},
	widerFieldContainer: {
		marginLeft: -50,
		order: 2,
		width: 'calc(60% + 32px)',
	},
	fieldTitleContainer: {
		marginRight: 'auto',
		order: 1,
		width: '40%',
	},
	fieldValueContainer: {
		display: 'flex',
		marginLeft: 'auto',
		order: 2,
		width: '60%'
	},
	fieldValues: {
		padding: 0,
		paddingLeft: 5,
		cursor: 'pointer',
		wordBreak: 'break-word',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'left',
	},
	fieldLabelContainer: {
		marginRight: 'auto',
		order: 1,
		width: '30%'
	},
	fieldLabels: {
		fontWeight: 'bold',
		wordBreak: 'break-word',
		color: colors.main,
		textAlign: 'left',
		padding: '10px 15px',
	},
	fontFieldNumber: {
		fontSize: 8,
		marginRight: 2,
		color: colors.main,
		border: '1px solid',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		verticalAlign: 'middle',
		width: 16,
		height: 16,
	},
	timeNowButton: {
		height: '20px',
		width: '20px',
		color: colors.main,
		alignItems: 'center',
		fontSize: "20px",
		margin: '0px 5px',
	},
	endButton: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		alignSelf: 'center'
	},
	tableMultiiVisibilityBtn: {
		width: 50,
		color: colors.main,
	},
	//New design
	totalValues: {
		color: colors.bloodOrange,
		padding: 0,
		paddingLeft: 5,
		wordBreak: 'break-word',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'left',
		fontSize: 12,
	},
	summaryCtn: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		gap: 5
	},
	summary: {
		paddingRight: 4
	},
	rowCtn: {
		justifyContent: 'space-between',
		zIndex: 200,
		display: 'flex',
		flexDirection: 'row',
        width: '70%',
        alignSelf: 'flex-end',
	},
	leftCtn: {
		display: 'flex',
		flexDirection: 'column',
        flex: 1,
	},
	inputCtn: {
		paddingRight: 10
	},
	colorEnable: {
		color: colors.main
	},
	colorDisable: {
		color: colors.grey
	},
	warnToast: {
			backgroundColor: colors.unselectedMain,
	},
}))

function getCellFormat (cellId, columns) {
    if (cellId) {
        let column = columns.find(obj => {
            return obj.id.toString() === cellId.toString()
        })
        return column
    }
}

const FormatTableMultiplenComponent = (props) => {
	// console.log('TableMultin got props', props);
	let tmpDisabled = false;
	if (typeof props.disabled !== "undefined") {
		tmpDisabled = props.disabled;
	}
	else if (typeof props.field.disabled !== "undefined") {
		tmpDisabled = props.field.disabled;
	}
	let _table = getTableView() || null
	const setup = props.field.options?.setup
	const classes = useStyles();
	const { t } = useTranslation(['common']);
	const [colonnes, setColonnes] = useState(props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []);
	const [table,] = useState(_table?.hash === props.hash ? _table : null)
	const [rows, setRows] = useState(props.field.value?.list?.length > 0 ? props.field?.value?.list : []);
	const [disabled, setDisabled] = useState(tmpDisabled);
	const [open, setOpen] = useState(false);
	const [openTable, setOpenTable] = useState(false)
	const [openRow, setOpenRow] = useState(null);
	const [openDownload, setOpenDownload] = useState(false);
	const [datasToDownload, setDatasToDownload] = useState([]);
	const plugNb = props?.plugNb
	const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null : props.owner);
	const [openModal, setOpenModal] = useState(false);
	// const [view, setView] = useState(false);
	const [ref, setRef] = useState(props.field.options.ref);
	const [field, setField] = useState(props.field.options.field);
	const [column, setColumn] = useState(props.field.options.column);
	const [columns, setColumns] = useState(props.field.options.columns);
	const [hideSearch,] = useState(isDefined(props?.model?.authoriseExternalDbInPf) ? (!props?.model?.authoriseExternalDbInPf && props.mode.includes('external')) : false);
	const [options, setOptions] = useState(props.field.options);
	const [dest, setDest] = useState(props.field.options.dest);
	const [value, setValue] = useState(!!props.field?.value?.value ? props.field?.value?.value : props.field.options.search !== undefined? props.field.options.search : "");
	const [ownerId, setOwnerId] = useState(props.ownerId);
	const [criteria, setCriteria] = useState("= xx");
	const [optionFormule, setOptionFormule] = useState({ licenseKey: 'gpl-v3' });
	const [recap, setRecap] = useState(false);
	const [pages, setPages] = useState(0);
	const [tableLength, setTableLength] = useState(recap ? rows.length - 1 : rows.length)
  const [newRow, setNewRow] = useState(null)
	const [excessLimit, setExcessLimit] = useState(0)

	//function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
	function deleteEmptyRows(rows) {
		let newRows = []
		if (rows.length > 0) {
			rows.forEach((row, index) => {
				let value = ''
				Object.values(row).forEach((valeur, id) => {
					if (valeur !== '' && valeur !== undefined) value = valeur
				})
				if (value !== '') newRows.push(row)
			})
		}
		return newRows
	}
	useEffect(() => {
		let hasRecap = false
        props.field.options.lines.forEach((col) => {if(col.recap === true) hasRecap = true})
        setRecap(hasRecap)
		let newRows = deleteEmptyRows(rows)
		setRows(newRows)
	}, [])

	useEffect(() => {
		if (table !== null && table !== undefined && table.field.split('field')[1] === props.field.id.toString()) setOpenTable(true)
	}, [])

	const handleAddTableMulti = async () => {
		if (rows.length !== 0) {
			let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
			let isEmpty = true
			let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
			_rows.filter(Boolean)
			if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
				if (_rows.length > 0) {
					let lastOne = _rows[_rows.length - 1];
					Object.values(lastOne).forEach((k) => {
						let control = typeof k !== "string" ? k.toString() : k;
						if (control != "" && control != '0:00' && control.length > 0) isEmpty = false
					})
					if (!isEmpty) {
						let _newRow = createData()
						if (recap) setRows([..._rows, _newRow, createRecapData()]);
						else setRows([..._rows, _newRow]);//ouvre automatiquement la ligne créée
						setNewRow(_newRow)
					}
					else {
						setOpen(true)
						setOpenRow(_rows.length - 1)
					}
				}
				else {
					let _newRow = createData()
					if (recap) setRows([_newRow, createRecapData()])
					else setRows([_newRow]);
					setNewRow(_newRow)
				}
			} else {
				toast.info(t('tablemulti.row-limit-warn'),
				{
					autoClose: 3000,
					hideProgressBar: true,
					bodyClassName: classes.warnToast,
					className: classes.warnToast,
					toastClassName: classes.warnToast,
				})
			}
		} else {
			let _newRow = createData()
			if (recap) setRows([...rows, _newRow, createRecapData()])
			else setRows([...rows, _newRow]);
			setNewRow(_newRow)
		}
	}

	const handleClickAddRow = () => {
		handleAddTableMulti()
		if(!openTable) setOpenTable(true)
}

	const handleRemoveRecap = () => {
		if (rows[(rows.length - 1)].hasOwnProperty('recap')) {
			handleRemoveSpecificRow((rows.length - 1));
		}
	}

	const handleAddRecap = () => {
		if(rows.length > 0 && !rows[rows.length - 1].hasOwnProperty('recap')) setRows([...rows, createRecapData()]);
	}

	const handleExportTableMulti = () => {
		let datas = [];
		if (colonnes.length > 0) {
			let line1 = [];
			line1.push(t('export.export-date'))
			line1.push(t('export.export-key'))
			line1.push(t('export.export-number'))
			line1.push(t('export.export-field-number'))
			line1.push(t('export.export-field'))
			colonnes.forEach(col => {
				if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
			})
			datas.push(line1);
			rows.forEach((row, index) => {
				let data = [];
				let now = new Date()
				data.push(now.toLocaleString())
				data.push(props.plugKey)
				data.push(plugNb)
				data.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
				data.push(props.title)

				colonnes.forEach(col => {
					if (isVisibleInCSV(col, props.mode)) {
					let someText = typeof row[col.id] === 'string' ? row[col.id].replace(/\r?\n?/g, '').trim() : row[col.id];
					if (someText === undefined) {
						someText = "";
					}
					data.push(someText);
				}})

				datas.push(data);
			});
		}
		if (datas.length > 0) {
			setDatasToDownload(datas);
			setOpenDownload(true);
		}
	}

	useEffect(() => {
		if (openDownload) {
			setDatasToDownload([]);
			setOpenDownload(false);
		}
	}, [openDownload]);

	const handleRemoveSpecificRow = async (value) => {
		let items
		if (Array.isArray(value)) {
			items = rows.filter((_, index) => !value.includes(index + 1))
		} else {
			items = [...rows];
			items = items.filter((_, index) => index !== value);
		}
		if (recap) {
            let row = items.find(row => row?.recap === true)
            if (row && items.length > 1) {
                let rindex = items.indexOf(row)
                colonnes.forEach( (col, index) => {
                    if (col?.recap){
                        row["recap"] = true;
                        row[col.id] = getAllValueFromRowsToSpecificIndex(items, col.id)
                    }
                })
                items[rindex] = row;
                setRows([...items]);
            }
			else setRows([])
        }
		else setRows(items);
	};

	function createRecapData() {
		let data = {};
		colonnes.forEach((col, index) => {
			if (col?.recap) {
				data["recap"] = true;
				data[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
			}
		})
		return data;
	}

	function createRecapDataForce(rows) {
		let data = {};
		colonnes.forEach((col, index) => {
			if (col?.recap === true) {
				data["recap"] = true;
				data[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
			}
		})
		return data;
	}

	function refreshRecapData() {
		let _rows = [...rows]
		let row = _rows.find(row => row?.recap === true)
		if (row) {
			let rindex = _rows.indexOf(row)
			colonnes.forEach((col, index) => {
				if (col?.recap) {
					row["recap"] = true;
					row[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
				}
			})
			rows[rindex] = row;
			setRows([...rows]);
		}
	}

	const getAllValueFromRowsToSpecificIndex = (rows, id) => {
        let total
		let format = getCellFormat(id, colonnes)
        if (format.format === "time" || format.format === "duration" || (typeof rows[0] !== "undefined" && isNaN(Number(rows[0][id])))) {
            total = ''
        }
        else {
            total = 0;
            rows.forEach((row, index) => {
                if (row[id] && row?.recap !== true) {
                    // console.log('row', row, format);
                    total += Number(row[id]);
                }
            });
        }
		return total;
	}

	function createData() {
		let data = {};
		colonnes.forEach((col, index) => {
			data[col.id] = '';
		})
		return data;
	}

	const handleChangeTableValue = () => {
		props.handleChangeTableValue(value, rows, props.field, props.index);
	}

	const handleEditValue = (value) => {
		setValue(value);
		props.handleChangeTableValue(value, rows, props.field, props.index);
	}

	const handleCalculateValueAuto = async (row, formula) => {
		let answer = null;
		let tmp = [];

		colonnes.forEach((elm, index) => {
			let tmpValue = row[elm.id];

			if (Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
				tmpValue = "";
			}

			if (elm.format === 'numeric' && typeof tmpValue === "string" && typeof tmpValue !== "undefined") {
				// console.log('tmpValue', tmpValue, typeof tmpValue);
				if (tmpValue !== null && tmpValue !== '') tmpValue = tmpValue.replace(', ', '.');
			}
			tmp.push([tmpValue]);
		});

		if (formula !== null) {
			let data = tmp;
			const sheetInfo = {
				pnSheet: {
					sheetName: "F32"
				},
				formulas: {
					sheetName: "Formulas"
				}
			};

			let sheetAData = data;

			let newFormula = formula;

			const hfInstance = HyperFormula.buildEmpty(optionFormule);

			let formulasData = [[
				newFormula
			]];
			// add 'TeamA' sheet
			hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
			// insert playersA content into targeted 'TeamA' sheet
			hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);
			// add a sheet named 'Formulas'
			hfInstance.addSheet(sheetInfo.formulas.sheetName);
			// add formulas to that sheet
			hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);
			const cellAddress = hfInstance.simpleCellAddressFromString(
				`${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
			);

			try {
				const mySum = hfInstance.getCellValue(cellAddress);

				if (!mySum.hasOwnProperty('type')) {
					answer = mySum;
				} else {
					answer = "";
				}
			}
            catch (e) {
				//setResult(e.message)
				answer = "";
			}
			hfInstance.destroy();
		}
		return answer;
	}

	const handleChangeChildrenValue = async (value, col, row, rindex) => {
		if (!props.disabled) {
			let copyColonnes = [...colonnes];
			let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);

			switch (col.format) {
				case 'presel':
					let multiselect = col.mask.multiselect;
					let editable = col.mask.editable;
					if (multiselect === false && editable === false) {
						row[col.id] = value;
						if(obj){
							for (const list of obj) {
								let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
								if (tmpValue !== null){
									row[list.id] = tmpValue
								}
							}
						}
						rows[rindex] = row;
						setRows([...rows]);
					}
                    else if (multiselect === false && editable === true) {
						row[col.id] = value;
						if(obj){
							for (const list of obj) {
								let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
								if (tmpValue !== null){
									row[list.id] = tmpValue
								}
							}
						}
						rows[rindex] = row;
						setRows([...rows]);
					}
                    else if (multiselect === true && editable === false) {
						row[col.id] = value;
						if(obj){
							for (const list of obj) {
								let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
								if (tmpValue !== null){
									row[list.id] = tmpValue
								}
							}
						}
						rows[rindex] = row;
						setRows([...rows]);
					}
                    else if (multiselect === true && editable === true) {
						row[col.id] = value;
						if(obj){
							for (const list of obj) {
								let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
								if (tmpValue !== null){
									row[list.id] = tmpValue
								}
							}
						}
						rows[rindex] = row;
						setRows([...rows]);
					}
					break;
				default:
					row[col.id] = value;
					if(obj){
						for (const list of obj) {
							let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
							if (tmpValue !== null){
								row[list.id] = tmpValue
							}
						}
					}
					rows[rindex] = row;
					setRows([...rows]);
					break;
			}
			refreshRecapData();
		}
	}

	const handleChangeChildrenRowValue = async (value, col, row, rindex) => {
		if (!props.disabled) {
			switch (col.format) {
				case 'extdata':
					rows[rindex] = value;
					setRows([...rows]);

					if (typeof row.recap === "undefined") {
						await handleRemoveRecap();
					}
					break;
				default:
					break;
			}
		}
	}

	function handleImportAuto(e) {
		e.preventDefault();
		e.stopPropagation();
		setPages(1);
	}

	useEffect(async () => {
		if (pages > 0 && typeof value !== 'undefined' && value !== "") {
			await fetchDatas()
		}
	}, [pages])

	const fetchDatas = async () => {
		let valueTmp = value;
		if (valueTmp == "") {
			valueTmp = 'ùµùµùµ?';
		}

		let payload = {
			"column": options.column,
			"query": typeof options.query !== "undefined" ? options.query : 'LIKE',
			"columns": options?.columns?.split(';') || [],
			"userhash": owner,
			"value": valueTmp,
			"file": options.ref,
			"ownerId": ownerId,
			"page": pages
		}

		if (payload.value !== "") {
			await trackPromise(
				axios.post(ApplicationConfig.apiUrl + 'extdata/autos/retrieves/ands/filters/datas.json', payload,
					{
						crossdomain: true,
						'headers': {
							'X-AUTH-TOKEN': getToken(),
						}
					}).then(async (response) => {
					if (response.data.result && response.data.data.length > 0) {
						await handleSelectData((response.data.data));
					}

					if (pages < response.data.end) {
						setPages(pages + 1)
					} else {
						setPages(0)
					}
				}).catch((error) => {
					setPages(0);
				})
			)
			//api.post('extdata/generates/datas.json', payload)
		}
	};

	useEffect(() => {
		handleChangeTableValue();
		setTableLength(recap  && rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap') ? rows.length - 1 : rows.length)
	}, [rows]);

	useEffect(() => {
		let tmpDisabled = false;
		if (typeof props.disabled !== "undefined") {
			tmpDisabled = props.disabled;
		}
		else if (typeof props.field.disabled !== "undefined") {
			tmpDisabled = props.field.disabled;
		}
		setDisabled(tmpDisabled);
	}, [props]);

	useEffect(() => {
		switch (props.field.options.query) {
			case 'CONTAINS':
				setCriteria("..xx..")
				break;
			case 'START':
				setCriteria("xx..")
				break;
			case 'MULTIPLE':
				setCriteria("..xx ; xx..")
				break;
			default: setCriteria("= xx")
				break;
		}
	}, [props.field.options.query])

	useEffect(() => {
		setValue(!!props.field.value.value ? props.field.value.value : "");
	},[props.field.value])

	const handleOpenSearchModel = () => {
		props.attemptGetDataFromAnotherBaseByClientReset();
		setOpenModal(true);
	}

	const handleSelectData = async (objects) => {
		let datas = [];
		let datasFields = null;
		if (objects.length > 0) {
			let resolve = new Promise((resolve, reject) => {
				objects.map(async (object, indexObject) => {
					let jsonObjectToArray = Object.values(object)
					let destinations = [];
					let fieldDestinations = [];
					let champs = dest.split('-');

					champs.forEach((elm, index) => {
						let field = elm.split(':');
						if (field.length > 0 && field.length <= 3) {
							let fieldDest = field[0];
							if(fieldDest.startsWith('F')){
								fieldDest = fieldDest.replace('F', '');
								let dest = fieldDest
								let col = field[1];
								let copyValue = col?.split(';') || col;
								let value = ""

								if(typeof props.fields[(dest - 1)] !== "undefined"){
									if(props.fields[(dest - 1)].format === "glocalis"){
										value = {
											latitude : "",
											longitude : "",
											address : ""
										}
									}
									else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti" || props.fields[(dest - 1)].format === "document"){
										value = {
											value : "",
											list : [],
										}
									}
									else if(props.fields[(dest - 1)].format === "intdatamaj"){
										value = {
											value : "",
											updatedDate : "",
										}
									}
									copyValue.map((item, index) => {
										let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
										let data = jsonObjectToArray[alphaNumIndexDest];
										if(typeof data !== "undefined"){
											let temp = data;
											if(temp !== "" && typeof temp !== "undefined"){
												if(temp === null){
													temp = "";
												}

												if(temp != ""){
													switch (props.fields[(dest - 1)].format) {
														case 'free-single-select':
														case 'single-select':
														case 'multi-select':
														case 'free-multi-select':
															try {
																value = [temp];
															}catch (e) {
																value = [];
															}
															break;
														case 'glocalis' :
															value.address = value.address == "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
															break;
														case 'tablemultin' :
														case 'smailvalidmulti' :
															value.value = temp;
															break;
														case 'localis' :
															value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
															break;
														case 'intdatamaj' :
															value.value = temp;
															break;
														default:
															if (item == item.toUpperCase()) {
																value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
															}else{
																value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
															}
															break;

													}
												}
											}
										}
									});


									if(props.fields[(dest - 1)].format === "glocalis" ){
										value = JSON.stringify(value);
									}

									fieldDestinations.push({
										"dest" : dest,
										"value" : value
									})
								}
							}
							else {
								let dest = colonnes[(fieldDest - 1)].id;
								let col = field[1];
								let copyValue = col?.split(';') || col;
								let value = ""

								copyValue.forEach((item, index) => {
									let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
									let data = jsonObjectToArray[alphaNumIndexDest];
									if (typeof data !== "undefined") {
										let temp = data;
										if (temp !== "" && typeof temp !== "undefined") {
											if (temp === null) {
												temp = "";
											}

											if (temp !== "") {
												if (item === item.toUpperCase()) {
													value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
												}
												else {
													value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
												}
											}
										}
									}
								});
								destinations.push({
									"dest": dest,
									"value": value
								})
							}
						}
					})

					if (destinations.length > 0) {
						let copyColonnes = [...colonnes];
						let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);
						let obj2 = copyColonnes.filter(o => o.format === 'numeric-ref').filter(Boolean);
						let obj3 = copyColonnes.filter(o => o.format === 's33data').filter(Boolean);

						let data = createData();
						destinations.map(async (item, index) => {
							let col = colonnes.find(o => ((o.id === Number(item.dest)) && (o.format === 'numeric')));

							if (typeof col === "undefined") {
								data[item.dest] = item.value;
							} else {
								data[item.dest] = item.value.replace(',', '.');
							}

							if(obj2){
								for (const list2 of obj2) {
									let tmpValue = data[list2.id];
									if (tmpValue == "") {
										let target = list2?.configuration?.target;
										if (typeof target !== "undefined") {
											let field = props.fields.find((field, index) => Number(field.id) === Number(target));
											if (field) {
												data[list2.id] = field.value;
											}
										}
									}
								}
							}

							if(obj3){
								for (const list3 of obj3) {
									let value = data[list3.id];

									try {
										if (value !== "") {
											let target = list3?.configuration?.target;
											let targettable = list3?.configuration?.targettable;
											if (typeof target !== "undefined") {
												let field = props.fields.find((field, index) => Number(field.id) === Number(target));
												if (field) {
													let tmpValue = field?.value?.list ? field.value.list : field.value;
													if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
														let options = field.options?.lines ? field.options.lines : field.options;
														data[list3.id] = tmpValue[(value - 1)][options[(targettable - 1)].id];
													}
												}
											}
										} else {
											data[list3.id] = value;
										}
									} catch (e) {
										data[list3.id] = value
									}
								}
							}

							if (obj) {
								for (const list of obj) {
									let tmpValue = await handleCalculateValueAuto(data, list?.configuration?.formula);
									if (tmpValue !== null) {
										data[list.id] = tmpValue
									}
								}
							}
						})

						datas.push(data);
						//props.handleChangeExtData(destinations);
					}

					if(fieldDestinations.length > 0){
						datasFields = fieldDestinations;
					}

					resolve(true);
				});

			})

			let rowLimit = setup.rowLimit
			let rowsLength = recap ? rows.length -1 : rows.length
			if (rowLimit > 0 && datas.length > rowLimit - rowsLength) {
				setExcessLimit(previous => {
					let excess = datas.length - (rowLimit - rowsLength)
					if (excess !== 100 && previous > 0) {
						toast.dismiss('row-limit-toast')
						toast.info(
							t('tablemulti.row-limit-warn') + ': ' + (previous + excess) + t('tablemulti.rows-ignored'),
							{
								autoClose: 3000,
								hideProgressBar: true,
								bodyClassName: classes.warnToast,
								className: classes.warnToast,
								toastClassName: classes.warnToast,
							})
						return 0
					}
					else { return previous + excess }
				})
				datas = datas.slice(0, rowLimit - rowsLength)
			}

			if ((datas.length > 0 || (!isNull(datasFields) && datasFields.length > 0)) && await resolve) {
				if(datas.length > 0 ){
					let newRows = [...rows]
					if (newRows.length > 0) {
						if (newRows[(newRows.length - 1)].hasOwnProperty('recap')) {
							newRows = newRows.filter((row, index) => index !== (newRows.length - 1));
						}
					}

					let forek = new Promise((resolve, reject) => {
						datas.forEach((item, index, array) => {
							newRows.push(item);
							if (index === array.length -1) resolve();
						});
					});

					forek.then(() => {
						if (recap) setRows([...newRows, createRecapDataForce(newRows)]);
						else setRows(newRows);
					});
				}

				if(!isNull(datasFields) && datasFields.length > 0){
					props.handleChangeExtData(datasFields);
				}
			}
		}
	}

	useEffect(() => {
		if (excessLimit > 0 && !toast.isActive('row-limit-toast')) {
			toast.warn(t('tablemulti.row-limit-warn'), {
				hideProgressBar: true,
				bodyClassName: classes.warnToast,
				className: classes.warnToast,
				toastClassName: classes.warnToast,
				toastId: 'row-limit-toast',
				isLoading: true,
				autoClose: 3000,
			})
		}
	}, [excessLimit])

	const handleViewTable = () => {
		setOpenTable(true)
	}

  const handleClickPreview = (index, id) => {
		if (!disabled) {
				if (isDefined(id)) { setOpenRow({row: rows[index], id: id}); }
				// else setOpenRow(index);
		}
		handleViewTable();
	}

	function isLimitReached() {
		return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
	}

	const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`
	// console.log('S32 props', props);

	// console.log('S32 options',props.field.options);

	return (
		<div className={`${classes.mainCtn}`}>
			<div className={classes.rowCtn}>
				<div className={classes.leftCtn}>
					<div className={classes.inputCtn}>
						<TextField
							disabled={disabled}
							onChange={(event) => handleEditValue(event.target.value)}
							id="standard-full-width"
							type="text"
							multiline={false}
							InputProps={{
								disableUnderline: true,
								endAdornment: (
									<React.Fragment>
										{
										!hideSearch &&
										<div className={`${classes.searchBtn} ${disabled? classes.colorDisable : ''} `} > {criteria}
											<span id="icon-listForm" className={`material-icons md-25 color: ${disabled? classes.colorDisable : classes.colorEnable}`} onClick={() => {if (!disabled) handleOpenSearchModel()}}>
												search
											</span>
										</div>
										}
									</React.Fragment>),
							}}
							placeholder={t('ext-table.search-table')}
							helperText=""
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
							className={classes.searchField}
							onKeyDown={(e) => { if (e.key === 'Enter' && !props.disabled && !hideSearch) handleOpenSearchModel()}}
							value={value}
						/>
					</div>
				</div>
				{
					openModal &&
					<ModalListExtDataMultipleComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={openModal} close={(event) => setOpenModal(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external} />
				}
				{
					openTable &&
					<ModalTablemultiTableViewComponent
						{...props}
						attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
						criteria={criteria}
						colonnes={colonnes}
						datasToDownload={datasToDownload}
						disabled={disabled}
						field={props.field}
						fields={props.fields}
						handleAddRecap={handleAddRecap}
						handleAddTableMulti={handleClickAddRow}
						handleChangeChildrenRowValue={handleChangeChildrenRowValue}
						handleChangeChildrenValue={handleChangeChildrenValue}
						handleClickPreview={handleClickPreview}
						handleClose={() => setOpenTable(false)}
						handleDeleteRow={handleRemoveSpecificRow}
						handleEditValue={handleEditValue}
						handleExportTableMulti={handleExportTableMulti}
						handleOpenSearchModel={handleOpenSearchModel}
						handleSafeExitPlugnote={props.handleSafeExitPlugnote}
						hasDb={true}
						hideSearch={hideSearch}
						mode={props.mode}
						model={props.model}
						newRow={newRow}
						open={openTable}
						openDownload={openDownload}
						openRow={openRow}
						owner={owner}
						ownerId={ownerId}
						plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
						recap={recap}
						rows={rows}
						search={value}
						setNewRow={setNewRow}
						setOpenRow={setOpenRow}
						title={props.title}
					/>
				}

				<div className={classes.topBtnCtn}>
					{rows.length > 0 &&
						<PlugTooltip title={t('tablemulti.table-view').concat(` ${tableLength} `).concat(t('tablemulti.rows'))} placement="top">
							<div className={classes.topBtn} onClick={handleViewTable}>
								<span id="icon-add-playlist" className="material-icons">
									visibility
								</span>
								<span style={{ fontSize: 10, fontWeight: 'bolder', }}>
									{`(${tableLength})`}
								</span>
							</div>
						</PlugTooltip>}
					{
						!disabled && props.field.options?.setup?.rowLimit !== 1 &&
						<PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
								<div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
										<span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
												add_circle_outline
										</span>
								</div>
						</PlugTooltip>
					}

					{
						!disabled && typeof props.field.options.importauto !== "undefined" && props.field.options.importauto === true && value !== undefined && value !== '' &&
						<PlugTooltip title="Auto-import" placement="top">
							<div className={classes.topBtn} onClick={(e) => handleImportAuto(e)}>
								<AutoImport className={classes.topBtnImg} />
							</div>
						</PlugTooltip>
					}
				</div>
			</div>
			<div>
				<ModalTablemultiTableViewComponent
					{...props}
					attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
					criteria={criteria}
					colonnes={colonnes}
					datasToDownload={datasToDownload}
					disabled={disabled}
					field={props.field}
					fields={props.fields}
					handleAddTableMulti={handleClickAddRow}
					handleChangeChildrenRowValue={handleChangeChildrenRowValue}
					handleChangeChildrenValue={handleChangeChildrenValue}
					handleClickPreview={handleClickPreview}
					handleClose={() => setOpenTable(false)}
					handleDeleteRow={handleRemoveSpecificRow}
					handleEditValue={handleEditValue}
					handleExportTableMulti={handleExportTableMulti}
					handleOpenSearchModel={handleOpenSearchModel}
					handleViewTable={handleViewTable}
					hasDb={true}
					hideSearch={hideSearch}
					isPreview={true}
					mode={props.mode}
					model={props.model}
					newRow={newRow}
					open={openTable}
					openDownload={openDownload}
					openRow={openRow}
					owner={owner}
					ownerId={ownerId}
					rows={rows}
					search={value}
					setNewRow={setNewRow}
					setOpenRow={setOpenRow}
					title={props.title}
				/>
			</div>
		</div>
	);
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
	let cached = state.data.uniqueClefUserIndex || null;
	let fieldToRefresh = state.data.fieldToRefresh || null;

	return {
		cached,
		fieldToRefresh,
		"plugKey": state?.model?.model?.clef || null
	}
}

const mapDispatchToProps = (dispatch) => ({
	attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultiplenComponent);
