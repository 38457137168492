let production = true;
let developpement = true;

let  ApplicationConfig;

if(developpement){
    ApplicationConfig =  {
        intercom : false,
        google : false,
        maintenance : false,
        templateUrl: 'https://plug.plugnotes.com/',
        baseUrl : production ? 'https://test.plugnotes.com' : 'http://localhost:3000/',
        url: production ? 'test.plugnotes.com' : 'www.old.plugnotes.local',
        backop: production ? 'https://test.plugnotes.com/bckop/' : 'http://bckop.plugnotes.local/',
        webUrl : production ? 'https://react.plugnotes.com/' : 'http://localhost:3000/',
        plugcodesUrl : production ? 'https://plug.plugnotes.com/?shared=' : 'http://localhost:3001/?shared=',
        downloadUrl:  production ? 'https://test.plugnotes.com/uploads/' : 'http://old.plugnotes.local/uploads/',
        apiUrl: production ? 'https://apitest.plugnotes.com/app_dev.php/api/' : 'http://api.plugnotes.local/api/',
        //apiv2Url: production ? 'https://test2022.plugnotes.com/Apis/' : 'http://apiv2.plugnotes.local/Apis/',
        apiv2Url: production ? 'https://test2022vg.plugnotes.com/Apis/' : 'http://apiv2.plugnotes.local/Apis/',
        paymentAboIndexUrl: production ? 'https://apitest.plugnotes.com/app_dev.php/web/package/index/' : 'http://api.plugnotes.local/web/package/index/',
        paymentCriteraIndexUrl: production ? 'https://apitest.plugnotes.com/app_dev.php/web/critera/payments/critera/' : 'http://api.plugnotes.local/web/critera/payments/critera/',
        simulator: false,
        production : false,
        library: '/Users/tech/',
        tilt: '~/',

        annexeFrom: {
            CAPTURE_PHOTO: "CAPTURE_PHOTO",
            FROM_GALLERY: "FROM_GALLERY",
            DOCUMENT: "DOCUMENT",
            AUDIO: "AUDIO",
            SCANNER: "SCANNER",
            EXTENSION: "EXTENSION",
            CAPTURE_PHOTO_OLD : "CAPTURE_PHOTO_OLD",
        },
        templateLanguages : [
            {name : "Français", id : 1, shortName : 'fr'},
            {name : "English", id : 2 ,shortName : 'en'},
        ]
    };
}
else {
    ApplicationConfig =  {
        intercom : true,
        google : false,
        maintenance: false,
        templateUrl: 'https://plugcodes.com/',
        baseUrl : production ? 'https://web.plugnotes.com' : 'https://test.plugnotes.com',
        url: production ? 'www.plugcodes.com' : 'test.plugnotes.com',
        backop: production ? 'https://web.plugnotes.com/bckop/' : 'https://test.plugnotes.com/bckop/',
        downloadUrl:  production ? 'https://plugcodes.com/uploads/' : 'https://test.plugnotes.com/uploads/',
        plugcodesUrl : production ? 'https://plugcodes.com/?shared=' : 'http://localhost:3001/?shared=',
        apiUrl: production ? 'https://api.plugnotes.com/api/' : 'https://apitest.plugnotes.com/app_dev.php/api/',
        apiv2Url: production ? 'https://prod2022.plugnotes.com/Apis/' : 'https://test2022.plugnotes.com/Apis/',
        paymentAboIndexUrl: production ? 'https://api.plugnotes.com/web/package/index/' : 'https://apitest.plugnotes.com/app_dev.php/web/package/index/',
        paymentCriteraIndexUrl: production ? 'https://api.plugnotes.com/web/critera/payments/critera/' : 'https://apitest.plugnotes.com/app_dev.php/web/critera/payments/critera/',
        simulator: false,
        library: '/Users/tech/',
        tilt: '~/',
        production : true,

        annexeFrom: {
            CAPTURE_PHOTO: "CAPTURE_PHOTO",
            FROM_GALLERY: "FROM_GALLERY",
            DOCUMENT: "DOCUMENT",
            AUDIO: "AUDIO",
            SCANNER: "SCANNER",
            EXTENSION: "EXTENSION",
            CAPTURE_PHOTO_OLD : "CAPTURE_PHOTO_OLD",
        },
        templateLanguages : [
            {name : "Français", id : 1, shortName : 'fr'},
            {name : "English", id : 2 ,shortName : 'en'},
        ]
    };
}


export default ApplicationConfig
