import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {toast} from "react-toastify";
import Input from "@material-ui/core/Input";
import * as XLSX from 'xlsx';

import {Content, } from "rsuite";
import {getCurrentEnvironnement, } from "../../utils/Common";

import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getFieldsRequest} from "../../actions/field";
import _, {camelCase, isEmpty, isNull, sortBy} from 'lodash'
import Images from "../../assets/themes/Images";
import {postCreateModelBiblioV2Request, postModelRequest} from "../../actions/model";
import FormatSelectConstructorComponent from "../../components/constructors/FormatSelectConstructorComponent";
import FormatFieldConstructorComponent from "../../components/constructors/FormatFieldConstructorComponent";
import {
    getTemplateCodeCategorieRequest,
    getTemplateCodeGroupRequest,
    getTemplateCodeModelRequest, getTemplateModelCodeRequest,
    resetTemplateHistoryRequest, resetTemplateModelCodeRequest,
    resetTemplateOpenListCategorieRequest,
    resetTemplateOpenListGroupRequest,
    resetTemplateOpenListModelsRequest
} from "../../actions/template";
import InputAdornment from "@material-ui/core/InputAdornment";
import ModalModelTemplateComponent from "../../components/modals/ModalModelTemplateComponent";
import ModalBiblioTemplateComponent from "../../components/modals/ModalBiblioTemplateComponent";
import {fieldTypes, formatTypes} from "../../utils/Compatibility";
import {confirmAlert} from "react-confirm-alert";
import FormatTableConstructorComponent from "../../components/constructors/FormatTableConstructorComponent";
import FormatPlugformConstructorComponent from "../../components/constructors/FormatPlugformConstructorComponent";
import FormatPluglinkConstructorComponent from "../../components/constructors/FormatPluglinkConstructorComponent";
import FormatFormulaConstructorComponent from "../../components/constructors/FormatFormulaConstructorComponent";
import FormatTableMultipleConstructorComponent from "../../components/constructors/FormatTableMultipleConstructorComponent";
import FormatExtDatanConstructorComponent from "../../components/constructors/FormatExtDatanConstructorComponent";
import FormatSignConstructorComponent from "../../components/constructors/FormatSignConstructorComponent";
import FormatSMailConstructorComponent from "../../components/constructors/FormatSMailConstructorComponent";
import FormatSMailValidMultiConstructorComponent from "../../components/constructors/FormatSMailValidMultiConstructorComponent";
import colors from '../../assets/themes/Colors';
import FormatPlugcodeInternalConstructorComponent from "../../components/constructors/FormatPlugcodeInternalConstructorComponent";
import FormatIndiceConstructorComponent from "../../components/constructors/FormatIndiceConstructorComponent";
import FormatDocumentConstructorComponent from "../../components/constructors/FormatDocumentConstructorComponent";
import Modal from "@material-ui/core/Modal";
import {isDefined, renderFormatText} from "../../utils/utils";
import FormatIntDataMajConstructorComponent from "../../components/constructors/FormatIntDataMajConstructorComponent";
import { ColorCheckbox, PlugTooltip } from '../../components/custom/CustomComponents';
import FormatTableMultiplenConstructorComponent from '../../components/constructors/FormatTableMultiplenConstructorComponent';
import { Asterisk, AsteriskOutline, VisibilityIndicator, SettingsIcon, InputIcon, EditValueIcon } from '../../components/custom/icons/CustomIcons';
import { Box, Collapse, Tab, Tabs } from "@material-ui/core"
import RenderEditDefaultValueComponent from '../../components/constructors/shared/RenderEditDefaultValueComponent';
import { fieldsWithDefaultValue, fieldsWithOptions } from '../../utils/Fields';
import { ErrorToastMessage } from '../../components/custom/toast/ErrorToast';
import { ContactMail } from '@material-ui/icons';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mandatory:{
        color : 'black',
    },
    notMandatory:{
        color : '#7F7F7F',
    },
    isTrue:{
        color : '#58C3C1',
    },
    isFalse:{
        color : '#E54F62',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '80px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
        padding: '20px 20px',
    },
    contentModel : {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow : 'scroll',
        maxHeight : '100vh'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'blue'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldIconSvg : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    field : {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldLeft : {
        alignContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
    },
    fieldRightLeft : {
        alignContent: 'flex-end'
    },
    fieldRight:{
        textAlign : 'center'
    },
    fullWidth :{
        width : '100%'
    },
    paper : {
        display: 'block !important',
        overflow : 'hidden !important'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    listHeight:{
        height: '80%'
    },
    subListHeight:{
        height: '100%'
    },
    scrollBar: {
        padding: '5px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            overflowX: 'hidden',
            display: "block",
            overflowY: 'scroll',
            width: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */
    },
    stickyHeader: {
        position: 'sticky',
        top: -30,
        zIndex: 10,
        backgroundColor: colors.white,
        boxShadow: '0px 6px 6px -4px rgba(136, 136, 136, 0.12)',
    },
    formatContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '10px 10px 0px',
        justifyContent: 'space-between',
    },
    subFormatContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: '5px',
    },
    paramBtn: {
        height: 'fit-content',
        color: colors.white,
        cursor: 'pointer',
        overflow: 'visible',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        backgroundColor: colors.mainButton,
        padding: 4,
        margin: '-7px auto 0px',
        zIndex: 1,
        '&:hover': {
            backgroundColor: colors.main,
            boxShadow: '0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 20px rgb(0 0 0 / 20%)'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 40
    },
    // Tabs
    mainField: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    tabsWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    tabsCtn: {
        '& .MuiTab-root': {
            textTransform: 'none !important',
        },
        '& .MuiTabs-indicator': {
            top: 0,
            backgroundColor: colors.main,
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: colors.main,
            fontWeight: 700,
        },
        '& div.MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'auto !important',
        }
    },
    tab: {
        // paddingTop: -20,
    },
    tabIcon: {
        height: 24,
        width: 24,
    },
    unselectedTab: {
        backgroundColor: colors.greyLight,
    },
    voidSpace: {
        flex: 1,
        background: `linear-gradient(90deg, ${colors.greyLight}, transparent)`,
        opacity: 0.7,
    },
}))

const useModalStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    indicator:{
        backgroundColor: '#58C3C1'
    },
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '50%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    fieldImage: {
        height: 18,
        width: 18
    },
    fieldIcon : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldMandatoryIcon : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toolbarButtons: {
        marginLeft: 'auto',
        marginRight: -12
    },
}))

const specialFields = ['formula', 'plugform', 'pluglink','extdata', 'extdatan','tablemultin','smailvalidmulti','document','intdatamaj']
const subFields = ['smail','smailvalidmulti','extdata','extdatan','tablemulti', 'tablemultin','document','intdatamaj'];
const notEncodable = ['qrcodeint'];

function getModalStyle() {
    const top = 5;
    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

function RenderItemFormatComponent(props){
    switch (props.field.format.toLowerCase()) {
        case "smail":
        case "smailvalidmulti":
            return <FormatFieldConstructorComponent 
                        field={props.field} handleEditLabel={props.handleEditLabel}
                        index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop}
                        setNewModel={props.setNewModel}
                        handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail}
                        isCreate={props.isCreate}
                    />
        case "sign":
            return <FormatSignConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "select":
            return <FormatSelectConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "indice":
            return <FormatIndiceConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "table":
            return <FormatTableConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "formula":
            return <FormatFormulaConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "plugform":
            return <FormatPlugformConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "qrcodeint":
            return <FormatPlugcodeInternalConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update}/>
        case "intdatamaj":
            return  <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail} index={props.index}/>
        case "extdata":
            return  <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail} index={props.index}/>
        case "extdatan":
            return  <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail} index={props.index}/>
        case "pluglink":
            return <FormatPluglinkConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} folderName={props.folderName}/>
        case "tablemulti":
        case "tablemultin":
        case 'document':
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail}/>
        // case 'document':
        //     return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail}/>
        default:
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail}/>
    }
}

function RenderSubItemFormatComponent(props){
    let subitem = null
    const t = props.t
    const classes = props.classes
    // Tabs
    const [tabNb, setTabNb] = useState(0)
    const handleChangeTab = (_, nb) => {
        setTabNb(nb);
        if (nb === 0) { // ouvrir le Drawer
            props.setOpenDrawer(true)
        }
    }

    switch (props.field.format.toLowerCase()) {
        case "smail":
            if (props.field.id === props.openSMail) {
                subitem = <FormatSMailConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} handleOpenCloseSmail={props.handleOpenCloseSmail} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} folderName={props.folderName} />
            }
            break
        case "smailvalidmulti":
            if(props.field.id === props.openSMail){
                return(
                    <div className="fullWidth">
                        <FormatSMailValidMultiConstructorComponent
                            field={props.field}
                            handleEditLabel={props.handleEditLabel}
                            handleOpenCloseSmail={props.handleOpenCloseSmail}
                            index={props.index}
                            newModel={props.newModel}
                            setNewModel={props.setNewModel}
                            setOpenDrawer={props.setOpenDrawer}
                            update={props.update}
                            folderName={props.folderName}
                        />
                        {/* <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail}/> */}
                    </div>
                )
            }
            return null;
        case "tablemulti":
            if(props.field.id === props.openSMail){
                return <FormatTableMultipleConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} folderName={props.folderName}/>
            }
            return null;
        case "tablemultin":
            if(props.field.id === props.openSMail){
                return (
                    <div className="fullWidth">
                        <FormatTableMultiplenConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} folderName={props.folderName} setOpenDrawer={props.setOpenDrawer}/>
                        {/* <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail}/> */}
                    </div>
                )
            }
            return null;
        // case "extdata":
        // if(props.field.id === props.openSMail){
        //     return <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail}/>
        // }
        //     return null;
        case "intdatamaj":
            if(props.field.id === props.openSMail){
                return <FormatIntDataMajConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail} />
            }
            return null;
        case "extdata":
        case "extdatan":
            if(props.field.id === props.openSMail){
                return <FormatExtDatanConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail}/>
            }
            return null;
        case "document":
            if(props.field.id === props.openSMail){
                return <FormatDocumentConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setNewModel={props.setNewModel} newModel={props.newModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail}/>
            }
            return null;
        default:
            return  null
    }
    if (fieldsWithDefaultValue.includes(props.field.format.toLowerCase())) {
        return (
            <Collapse in={props.field.id === props.openSMail} style={{ flex: 1 }}>
                <div className={classes.mainField}>
                    <div className={classes.tabsWrapper}>
                        <div className={classes.tabsCtn} >
                            <Tabs
                                onChange={handleChangeTab}
                                textColor="inherit"
                                value={tabNb}
                            >
                                <Tab
                                    icon={<InputIcon color={tabNb === 0 ? colors.main : colors.coal} height={24} width={24} />}
                                    disableRipple
                                    label={'1. ' + t('helper.default-value')}
                                    className={`${classes.tab} ${tabNb !== 0 && classes.unselectedTab}`} />
                                <Tab
                                    disableRipple
                                    icon={<ContactMail />}
                                    label={'2. ' + t('smailvalidmulti.config-email')}
                                    className={`${classes.tab} ${tabNb !== 1 && classes.unselectedTab}`} />
                            </Tabs>
                        </div>
                        <span className={classes.voidSpace} />
                    </div>
                </div>
                {
                    tabNb === 0 &&
                    <RenderEditDefaultValueComponent
                        field={props.field}
                        handleChangeDefaultValue={(value) => props.handleChangeDefaultValue(props.index, value)}
                        handleChangeDefaultValueEdit={(value) => props.handleChangeDefaultValueEdit(props.index, value)}
                    />
                }
                {
                    tabNb === 1 &&
                    subitem
                }
            </Collapse>
        )
    } else {
        return subitem
    }
}

const ModalBiblioPreviewImportComponent = (props) => {
    const classes = useModalStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    let viewModel = props.viewModel;
    let viewFields = props.viewFields;
    let listFields = props.listFields;

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase());
    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());
    const [currentLanguageFirstUpper, setCurrentLanguageFirstUpper] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toLowerCase());
    const environment = getCurrentEnvironnement();

    const renderFormatText = (field) => {
        let fieldFormat = field.format;
        // Vérifier les préselections
        let template = '';
        if (typeof field.presel !== 'undefined') {

            if(field.multiselect && !field.editable){
                fieldFormat = "multi-select";
            }else if(!field.multiselect && !field.editable){
                fieldFormat = "single-select";

            }else if(field.multiselect && field.editable){
                fieldFormat = "free-multi-select";

            }else if(!field.multiselect && field.editable){
                fieldFormat = "free-single-select";
            }

        }

        switch (fieldFormat) {
            case fieldTypes.QRCODE:
                template = <span>{formatTypes.QRCODE}</span>; break;
            case fieldTypes.BIRTHDAY:
                template = <span>{formatTypes.BIRTHDAY}</span>; break;
            case fieldTypes.TEXT:
                template = <span>{formatTypes.TEXT}</span>; break;
            case fieldTypes.TEXTAREA:
                template = <span>{formatTypes.TEXTAREA}</span>; break;
            case fieldTypes.PHONE:
                template = <span>{formatTypes.PHONE}</span>; break;
            case fieldTypes.MAIL:
                template = <span>{formatTypes.MAIL}</span>; break;
            case fieldTypes.URL:
                template = <span>{formatTypes.URL}</span>; break;
            case fieldTypes.LOCALIS:
                template = <span>{formatTypes.LOCALIS}</span>; break;
            case fieldTypes.NUMERIC:
                template = <span>{formatTypes.NUMERIC}</span>; break;
            case fieldTypes.FREE_MULTI_SELECT:
                template = <span>{formatTypes.FREE_MULTI_SELECT}</span>; break;
            case fieldTypes.MULTI_SELECT:
                template = <span>{formatTypes.MULTI_SELECT}</span>; break;
            case fieldTypes.SINGLE_SELECT:
                template = <span>{formatTypes.SINGLE_SELECT}</span>; break;
            case fieldTypes.FREE_SINGLE_SELECT:
                template = <span>{formatTypes.FREE_SINGLE_SELECT}</span>; break;
            case fieldTypes.LIST:
                template = <span>{formatTypes.LIST}</span>; break;
            case fieldTypes.NOW:
                template = <span>{formatTypes.NOW}</span>; break;
            case fieldTypes.AGENDA:
                template = <span>{formatTypes.AGENDA}</span>; break;
            case fieldTypes.DATE:
                template = <span>{formatTypes.DATE}</span>; break;
            case fieldTypes.BOOLEAN:
                template = <span>{formatTypes.BOOLEAN}</span>; break;
            case fieldTypes.TABLEMULTI:
                template = <span>{formatTypes.TABLEMULTI}</span>; break;
            case fieldTypes.INDICE:
                template = <span>{formatTypes.INDICE}</span>; break;
        }

        return template;
    }

    const handlePressMultipleCategoryCreate = () => {

        // {"askingChoice":[{"id":30},{"id":30}],"models":[{"id":8,"group":30},{"id":75,"group":30}],"hidden":true}
        let askingChoice = [];
        let models = [];
        let hidden = true;


        let params = {
            askingChoice : askingChoice,
            models : models,
            hidden : hidden
        }

        askingChoice.push({"id" : props.viewModel.bbgroups_idGroup});
        models.push({"id" : props.viewModel.bcmodels_idModel, "group" : props.viewModel.bbgroups_idGroup})

        if(models.length > 0){
            props.attemptCreateModelBiblioAsking(params, environment.userhash,currentLanguage);
            props.close();
        }
    }

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.mid} style={modalStyle} className={classes.popup}>
                <div className="" style={{flex:1, width: '100%'}}/>
                { viewModel !== null ?
                    <div>
                        <div className="liste-champ-modele_apercu">
                            <div className="champ-apercu grey">{viewModel.bcmodelslgs_titreModel}</div>
                            {
                                viewFields && listFields.map((field,index) => {
                                    return (
                                        <div id="liste-champ-modele_apercu-ligne" key={index}>
                                            <div className={field.mandatory ? classes.fieldMandatoryIcon : classes.fieldIcon}>
                                                <img
                                                    className={classes.fieldImage}
                                                    src ={ !field.mandatory ? Images[camelCase(field.format.toLowerCase())+"IconSvg"] : Images[camelCase(field.format.toLowerCase())+"IconSvgRed"]}
                                                    alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                />
                                            </div>
                                            <div>
                                                    <span className="references">
                                                        {field.label}
                                                    </span>
                                            </div>
                                            <div>
                                                    <span className="references">
                                                        {
                                                            (field.position > 9) ?
                                                                <span>n°{field.position} {renderFormatText(field)}</span>
                                                                :
                                                                <span>n°{field.position <=9 && <span>0</span>}{field.position} {renderFormatText(field)}</span>
                                                        }

                                                    </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="float_right">
                            <button className="button" onClick={(event) => handlePressMultipleCategoryCreate()}>{t('common:models.create-selected-models')}</button>
                        </div>
                    </div>
                    :
                    <div className="liste-champ-modele_apercu">
                    </div>
                }
            </div>
        </Modal>
    )
}

function RenderPoperItem (props) {
    const classes = useStyles();
    const [field,] = useState(props.field);

    const handleClickPpoperLink = (event,link) => {
        props.handleClickPoper(event,null);
        window.open("https://www.plugcodes.com?code="+link,"_blank")
    }

    if(!isNull(props.open)){
        return (
            <div className={classes.paperPoper}>
                {
                    field?.pnpfcodefr && field.pnpfcodefr !== "" &&
                    <PlugTooltip title={field.pnpfcodefr}>
                        <span id="icon-more" className="font_size_15 cursor text-span-round" onClick={(event) => { event.stopPropagation(); event.preventDefault(); handleClickPpoperLink(event,field.pnpfcodefr)}}>
                            FR
                        </span>
                    </PlugTooltip>
                }
                {
                    field?.pnpfcodeen && field.pnpfcodeen !== "" &&
                    <PlugTooltip title={field.pnpfcodeen}>
                        <span id="icon-more" className="font_size_15 cursor text-span-round" onClick={(event) => { event.stopPropagation(); event.preventDefault(); handleClickPpoperLink(event,field.pnpfcodeen)}}>
                            EN
                        </span>
                    </PlugTooltip>
                }
                {
                    field?.pnpfcodenl && field.pnpfcodenl !== "" &&
                    <PlugTooltip title={field.pnpfcodenl}>
                        <span id="icon-more" className="font_size_15 cursor text-span-round" onClick={(event) => { event.stopPropagation(); event.preventDefault(); handleClickPpoperLink(event,field.pnpfcodenl)}}>
                            NL
                        </span>
                    </PlugTooltip>
                }
            </div>
        )

    }else return null;

}

function ModelCreateV2(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);

    const [langue, setLangue] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase() === 'fr' ? '' : 'En');
    const [openDrawer, setOpenDrawer] = useState(true);
    const environment = getCurrentEnvironnement();
    const [newModel, setNewModel] = useState([]);
    const [fieldEmptyVisible, setFieldEmptyVisible] = useState(false);
    const [authoriseExternalDbInPf, setAuthoriseExternalDbInPf] = useState(false);
    const [authoriseShowPlugLinkData, setAuthoriseShowPlugLinkData] = useState(false);
    const [nameModel, setNameModel] = useState("");
    const [searchTemplate, setSearchTemplate] = useState("");
    const [openModalModelTemplate, setOpenModalModelTemplate] = useState(false);
    const [openModalBiblioTemplate, setOpenModalBiblioTemplate] = useState(false);
    const [openModalBiblioTemplatePreviewImport, setOpenModalBiblioTemplatePreviewImport] = useState(false);
    const [group, setGroup] = useState(false);
    const [models, setModels] = useState(false);
    const [category, setCategory] = useState(false);
    const [countable, setCountable] = useState(0);
    const [openSMail, setOpenSMail] = useState(-1);
    const [viewModel, setViewModel] = useState(null);
    const [viewFields, setViewFields] = useState(false);
    const [listFields, setListFields] = useState([]);
    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());
    const [openPoper, setOpenPoper] = useState(null)
    const [openDefaultEdit, setOpenDefaultEdit] = useState(null)
    const [missingLabel, setMissingLabel] = useState(null)

    const defaultTablemultiSetup = {
        comment: '',
        layout: {
            alignment: 'full',
            showHeader: true,
            showRowNb: true,
        },
        printable: true,
        rowLimit: 0,
    }
    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleListItemClick = (event, fieldSelected) => {
        // console.log('model', newModel)
        if (newModel.length > 0 && newModel[newModel.length - 1].label === '') {
            toast.info(t('tablemulti.missing-label'),
                {
                    autoClose: 3000,
                    hideProgressBar: true,
                    bodyClassName: classes.warnToast,
                    className: classes.warnToast,
                    toastClassName: classes.warnToast,
                })
            setMissingLabel(newModel.length - 1)
        }
        else if (newModel.length < 15) {
            let options = {};
            // Récupération du dernier item, avec le tri des valeurs en fonction de la clé
            let lastField = _.orderBy(Object.values(newModel), 'key', 'desc')[0];

            // Si c'est le premier champ on initialise à 0
            if (!lastField) lastField = { key: 0, id: 0 }

            let newItem
            switch (fieldSelected.identifiant) {
                case 'select':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        position: fieldSelected.position,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            visibledefault: false,
                            mandatory: false,
                            editable: false,
                            multiselect: false,
                            presel: [],
                            setup: {
                                printable: true,
                                comment: '',
                            },
                        },
                        newField: true,
                    }
                    break
                case 'table':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            editable: false,
                            infos_bules: false,
                            mandatory: false,
                            multiselect: false,
                            presel: [],
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true,
                            },
                            visibledefault: false,
                        },
                        position: fieldSelected.position,
                    }
                    break
                case 'plugform':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        position: fieldSelected.position,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            mandatory: false,
                            editable: false,
                            setup: {
                                printable: true,
                                comment: '',
                            },
                        },
                        newField: true,
                    }
                    break
                case 'qrcodeint':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        position: fieldSelected.position,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            mandatory: false,
                            clef: "",
                            comment: "",
                            setup: {
                                printable: true,
                                comment: '',
                            },
                        },
                        newField: true,
                    }
                    break
                case 'formula':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            editable: false,
                            formula: null,
                            infos_bules: false,
                            mandatory: false,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position,
                    }
                    break
                case 'indice':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            editable: false,
                            indice: "",
                            infos_bules: false,
                            mandatory: false,
                            racine: "",
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true,
                            },
                        },
                        position: fieldSelected.position,
                    }
                    break
                case 'sign':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            infos_bules: false,
                            mandatory: false,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            },
                            template: "2001"
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'smail':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            auto: "N",
                            body: "",
                            cc: "",
                            from: "",
                            infos_bules: false,
                            mandatory: false,
                            note: "N",
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            },
                            template: "",
                            title: ""
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'smailvalidmulti':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: {
                                comment: '',
                                layout: {
                                    alignment: 'full',
                                    showHeader: true,
                                    showRowNb: true
                                },
                                printable: true,
                                rowLimit: 0
                            }
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            alignment: 'left',
                            body: "",
                            cc: "",
                            column: "",
                            columns: "",
                            dest: "",
                            from: "",
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            note: "N",
                            plugform: "",
                            query: "LIKE",
                            ref: "",
                            rgpd: false,
                            setup: defaultTablemultiSetup,
                            template: "",
                            title: ""
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'tablemulti':
                    newItem ={
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: defaultTablemultiSetup
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            alignment: 'left',
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            rgpd: false,
                            setup: defaultTablemultiSetup
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'tablemultin':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: defaultTablemultiSetup
                        },
                        id: lastField.id + 1,
                        importauto: false,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            column: "",
                            columns: "",
                            dest: "",
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            plugform: "",
                            query: "LIKE",
                            ref: "",
                            rgpd: false,
                            setup: defaultTablemultiSetup
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'document':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: defaultTablemultiSetup
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            import_annexes: false,
                            import_from_ftp: false,
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            rgpd: false,
                            setup: defaultTablemultiSetup
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'extdata':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            column: null,
                            columns: null,
                            dest: null,
                            field: null,
                            infos_bules: false,
                            mandatory: false,
                            query: null,
                            ref: null,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'extdatan':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            column: null,
                            columns: null,
                            dest: null,
                            infos_bules: false,
                            mandatory: false,
                            plugform: null,
                            query: null,
                            ref: null,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'intdatamaj':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            dest: null,
                            dest_infos: null,
                            display_search: false,
                            infos_bules: false,
                            mandatory: false,
                            plugform: null,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position
                    }
                    break
                default:
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            infos_bules: false,
                            mandatory: false,
                            rgpd: false,
                            ...options || {},
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position,
                    }
                    break
            }

            // if (fieldsWithDefaultValue.includes(fieldSelected.identifiant)) {
            //     newItem.formatoptions.setup = {
            //         printable: true,
            //         comment: '',
            //         defaultValue: null,
            //         editable: true,
            //         editablePf: true,
            //         editablePw: true,
            //     }
            // }

            setNewModel(oldArray => [...oldArray, newItem])

            // if(fieldSelected.identifiant === 'select'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         label: "",
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             visibledefault : false,
            //             mandatory: false,
            //             editable : false,
            //             multiselect : false,
            //             presel : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'table'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             visibledefault : false,
            //             mandatory: false,
            //             editable : false,
            //             multiselect : false,
            //             presel : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'plugform'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             editable : false,
            //             multiselect : false,
            //             presel : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'qrcodeint'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         isEncodable : false,
            //         isVisible : true,
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             clef : "",
            //             comment : "",
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'formula'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             editable : false,
            //             multiselect : false,
            //             formula : null,
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'indice'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             editable : false,
            //             racine : "",
            //             indice : "",
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'sign'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             template : "9001",
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'smail'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             template : "",
            //             cc :"",
            //             from : "",
            //             title: "",
            //             body:"",
            //             note : "N",
            //             auto : "N"
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'tablemulti'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             alignment: 'left',
            //             infos_bules: false,
            //             mandatory: false,
            //             lines : [],
            //         },
            //         formatoptions: {
            //             lines : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'tablemultin'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         importauto : true,
            //         options: {
            //             rgpd : false,
            //             alignment: 'left',
            //             infos_bules: false,
            //             mandatory: false,
            //             ref: "",
            //             query: "LIKE",
            //             columns: "",
            //             column: "",
            //             plugform: "",
            //             dest: "",
            //             lines : [],
            //         },
            //         formatoptions: {
            //             lines : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'smailvalidmulti'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             alignment: 'left',
            //             infos_bules: false,
            //             mandatory: false,
            //             template : "",
            //             cc :"",
            //             from : "",
            //             title: "",
            //             body:"",
            //             note : "N",
            //             ref: "",
            //             query: "LIKE",
            //             columns: "",
            //             column: "",
            //             plugform: "",
            //             dest: "",
            //             lines : [],
            //         },
            //         formatoptions: {
            //             lines : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'document'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             alignment: 'left',
            //             infos_bules: false,
            //             mandatory: false,
            //             import_annexes : false,
            //             import_from_ftp : false,
            //             lines : [],
            //         },
            //         formatoptions: {
            //             lines : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'extdata'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             ref : null,
            //             query : null,
            //             columns : null,
            //             column : null,
            //             field : null,
            //             dest : null,
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if(fieldSelected.identifiant === 'extdatan'){
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             ref : null,
            //             query : null,
            //             columns : null,
            //             column : null,
            //             plugform : null,
            //             dest : null,
            //         },
            //         newField : true,
            //     }]);
            // }
            // else if (fieldSelected.identifiant === 'intdatamaj') {
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible : true,
            //         isEncodable : true,
            //         options: {
            //             rgpd : false,
            //             infos_bules: false,
            //             mandatory: false,
            //             display_search : false,
            //             plugform : null,
            //             dest : null,
            //             dest_infos : null
            //         },
            //         formatoptions: {
            //             lines : [],
            //         },
            //         newField : true,
            //     }]);
            // }
            // else {
            //     setNewModel(oldArray => [...oldArray, {
            //         dragid : 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         position : fieldSelected.position,
            //         indice : fieldSelected.indice,
            //         label: "",
            //         isVisible : true,
            //         isEncodable : true,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             mandatory: false,
            //             rgpd : false,
            //             infos_bules: false,
            //             ...options || {}
            //         },
            //         newField : true,
            //     }]);
            // }
        }
    }

    const handleDeleteField = (fieldToDelete) => {
        const temp = newModel.filter(item => fieldToDelete !== item);
        setNewModel(temp);
    }

    const handleToggleMandatoryField = (field, position) => {
        const temp = newModel.map((item, index) => {

            if(field === item && position === index){

                if(item.isEncodable === false && !item.options.mandatory === true) {
                    item.options.mandatory = !item.options.mandatory;
                    item.isEncodable = true;
                }else if(item.isEncodable === true && !item.options.mandatory === false){
                    item.options.mandatory = !item.options.mandatory;
                }else{
                    item.options.mandatory = !item.options.mandatory;
                }

            }
            return item;
        } );
        setNewModel(temp);
    }

    const handleEditableField = (field, position) => {
        const temp = newModel.map((item, index) => {

            if(field === item && position === index){
                if(!item.isEncodable === false && item.options.mandatory === true){
                    item.isEncodable = !item.isEncodable;
                    item.options.mandatory = false
                }else{
                    item.isEncodable = !item.isEncodable;
                }
            }
            return item;
        } );
        setNewModel(temp);
    }

    const handleVisibleField = (field, position) => {
        const temp = newModel.map((item, index) => {
            if(field === item && position === index){
                item.isVisible = !item.isVisible;
            }
            return item;
        } );
        setNewModel(temp);
    }

    const handleToggleVisiblePDF = (field, position) => {
        const temp = [...newModel]
        if (temp[position] === field) {
            let _field = temp[position]
            if (isDefined(_field.options?.setup?.printable)) {
                _field.options.setup.printable = !_field.options.setup.printable
            } else {
                _field.options.setup.printable = true
            }
            temp[position] = _field
            setNewModel(temp)
        }
    }

    const handleToggleRGPD = (field, position) => {
        const temp = [...newModel]
        if (temp[position] === field) {
            let _field = temp[position]
            // console.log('rgpd? :', _field.options.rgpd, field)
            if (isDefined(_field.options?.rgpd)) {
                _field.options.rgpd = !_field.options.rgpd
            } else {
                _field.options.rgpd = true
            }
            temp[position] = _field
            setNewModel(temp)
        }
    }

    const handleChangeFolderName = (event, value) => {
        setNameModel(event.target.value);
    };

    const handleModalModelTemplateClose = () => {
        setOpenModalModelTemplate(false);
        props.attemptResetTemplateHistory();
    }

    const handleModalBiblioTemplateClose = () => {
        setOpenModalBiblioTemplate(false);
    }

    const handleModalBiblioTemplatePreviewImportClose = () => {
        setOpenModalBiblioTemplatePreviewImport(false);
    }

    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */
    const handleEditLabel = (value, field, index) => {
        let model = [...newModel];
        model[index].label = value;
        setNewModel(model);
    };

    const handleOpenCloseSmail = (index) => {
        if(index === openSMail){
            setOpenSMail(-1);
            if(!openDrawer) handleDrawerOpen()
        }else{
            setOpenSMail(index);
            if(!openDrawer) handleDrawerOpen()
        }
    };

    const handleCancelExitModel = () => { props.history.push("/models") }

    const handleSafeExitModel = () => {
        if (modelReadyToSave()) {
            handleSaveModel()
            //props.history.push("/models")
        }
    }

    const modelReadyToSave = () => {
        // Si la liste des champs est vide on affiche une alerte
        if (nameModel.length === 0) {
            toast.error(<ErrorToastMessage title={t('global.error')} message={t('model-create-update.model-form-title-empty')} />)
            return false
        }
        else if (newModel.length === 0) {
            toast.error(<ErrorToastMessage title={t('global.error')} message={t('model-create-update.model-form-fields-empty')} />)
            return false
        }
        // Si il manque un libellé sur un champ on affiche une pop-up
        else if (!isEmpty(newModel.find((field) => isEmpty(field.label) && (field.format !== "delete-field")))) {
            toast.error(<ErrorToastMessage title={t('model-create-update.model-form-fields-no-name-title')} message={t('model-create-update.model-form-fields-no-name')} />)
        } else { return true }
    }

    const handleClickSaveModel = () => {
        if (modelReadyToSave()) {
            handleSaveModel()
        }
    }

    const handleSaveModel = () => {
        let newFields = {};

        newModel.forEach((item, index) => {
            if(item.format === 'select' && !!item.options.presel){
                item.options.presel = item.options.presel.join(';')
            }

            if(item.format === 'table' && !!item.options.presel){
                item.options.presel = item.options.presel.join(';')
            }

            if(item.format === 'document' && !!item.formatoptions.lines){
                item.options.lines = item.formatoptions.lines;
            }

            if(item.format === 'tablemulti' && !!item.formatoptions.lines){
                item.options.lines = item.formatoptions.lines;
            }

            if(item.format === 'tablemultin' && !!item.formatoptions.lines){
                item.options.lines = item.formatoptions.lines;
            }

            if(item.format === 'smailvalidmulti' && !!item.formatoptions.lines){
                item.options.lines = item.formatoptions.lines;
            }

            item.key = index+1;
            newFields[`field${index+1}`] = item
        })

        let finalModel = {
            model: {
                name: nameModel,
                fields: newFields,
                fieldEmptyVisible : fieldEmptyVisible,
                authoriseExternalDbInPf : authoriseExternalDbInPf,
                authoriseShowPlugLinkData : authoriseShowPlugLinkData,
            }
        }
        props.attemptCreateModel(finalModel, environment.userhash);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#58C3C1" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            newModel,
            result.source.index,
            result.destination.index
        );

        setNewModel(items);
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        //padding: grid,
    });

    useEffect(() => {
        props.attemptGetFields()
    }, []);

    useEffect(() => {
        if(searchTemplate.length === 0 && props.list_models.length > 0){
            props.attemptResetTemplateHistory();
            setOpenModalModelTemplate(false);
        }else if(searchTemplate.length === 0 && !isNull(props.model_by_code)){
            props.attemptResetTemplateHistory();
            setOpenModalBiblioTemplatePreviewImport(false);
        }
    }, [searchTemplate]);

    useEffect(() => {
        if(props.open_list_models === true){
            setModels(true);
            setCategory(false);
            setGroup(false);
            setOpenModalModelTemplate(true);
            props.attemptResetTemplateOpenListModels();
        }else if(props.open_list_group === true){
            setModels(false);
            setCategory(false);
            setGroup(true);
            setOpenModalModelTemplate(true);
            props.attemptResetTemplateOpenListGroup();
        }else if(props.open_list_categorie === true){
            setModels(false);
            setCategory(true);
            setGroup(false);
            setOpenModalModelTemplate(true);
            props.attemptResetTemplateOpenListCategorie();
        }else if(props.open_model_code === true){
            handleSelectedModelToPreview(props.model_by_code);
        }
    }, [props.open_list_models,props.open_list_group,props.open_list_categorie,props.open_model_code]);

    const handleSearchTemplate = () => {
        if(searchTemplate.length){
            let regexp = new RegExp('^[A-Z]{2}[0-9]{2}$');
            let regexp2 = new RegExp('^[A-Z]{3}[0-9]{1}$');
            let regexp3 = new RegExp('^[A-Z]{3}[0-9]{2}$');
            let  test = regexp.test(searchTemplate.trim());
            let  test2 = regexp2.test(searchTemplate.trim());
            let  test3 = regexp3.test(searchTemplate.trim());
            if (test) {
                props.attemptGetTemplateCodeModelGroup(searchTemplate,environment.userhash);
            }else if(test2){
                props.attemptGetTemplateCodeModelGroup(searchTemplate,environment.userhash);
            }else if(test3){
                props.attemptGetTemplateMobelByCode(searchTemplate,environment.userhash,i18n.language.toUpperCase());
            } else if(searchTemplate.charAt(0) === 'C' && (searchTemplate.charAt(2) === '-' || searchTemplate.charAt(2) === '–')){
                props.attemptgetTemplateCodeCategory(searchTemplate.substring(1),environment.userhash, i18n.language.toUpperCase())
            } else if(searchTemplate.charAt(0) === 'G' && (searchTemplate.charAt(2) === '-' || searchTemplate.charAt(2) === '–')) {
                props.attemptGetTemplateCodeGroup(searchTemplate.substring(1), environment.userhash)
            }else {
                toast.error(<ErrorToastMessage title={t('global.plugnotes')} message={t('template.code-error')} />)
            }
        }
    }

    // process CSV data
    const processData = (dataString, field, index) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const list = [];
        let isFromTable = false
        for (let i = 0; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            for (let j = 0; j < 1; j++) {
                let item = row[j];
                if (item.length > 0) {
                    if (item[0] === '"')
                        item = item.substring(1, item.length - 1);
                    if (item[item.length - 1] === '"')
                        item = item.substring(item.length - 2, 1);
                }
                if(field.options.presel !== undefined) {
                    if(item.length > 0 && !field.options.presel.includes(item)){
                        list.push(item);
                    }
                }
                else if(item.length > 0 && !field.formatoptions[index].presel.includes(item)){
                    list.push(item);
                    isFromTable = true
                }
            }
        }

        if(list.length > 0){
            //field.options.presel = field.options.presel.concat(list);
            let model = [...newModel];
            if (!isFromTable) {
                model[index].options.presel = model[index].options.presel.concat(list);
                setNewModel(model);
            }
            else { //case tablemultiple
                model[field.id - 1].formatoptions[index].presel = model[field.id - 1].formatoptions[index].presel.concat(list)
                setNewModel(model);
            }
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.traitement-finish'),
                buttons: [{label: t('global.yes'),}]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.traitement-no-data'),
                buttons: [{label: t('global.yes'),}]
            });
        }
    }

    const onChangeHandlerDrop = (acceptedFiles, field, index) => {
        var files = acceptedFiles, f = files[0];
        var reader = new FileReader();
        reader.onload = function(e) {
            var bstr = new Uint8Array(e.target.result);
            var workbook = XLSX.read(bstr, {type: 'array'});
            /* DO SOMETHING WITH workbook HERE */
            /* Get first worksheet */
            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data, field, index);
        };
        reader.readAsArrayBuffer(f);
    }

    const handleSelectedModelToPreview = (model) => {
        setViewModel(model);
        setViewFields(model.bcmodels_bdfields);
        setListFields([]);
        if(model.bcmodels_bdfields === true){
            for (let i = 1; i <= 15 ; i++) {
                if(typeof model.bcmodels_bdfields_lists['bdfields_field'+i+'format'] !== "undefined"  && model.bcmodels_bdfields_lists['bdfields_field'+i+'format'] !== null && model.bcmodels_bdfields_lists['bdfields_field'+i+'position'] === i){
                    let format = model.bcmodels_bdfields_lists['bdfields_field'+i+'format'];
                    if(format === 'select'){
                        switch (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionMultiselect']) {
                            case true :
                                if (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable']) {
                                    format = "free-multi-select";
                                } else {
                                    format = "multi-select";
                                }
                                break;
                            default:
                                if (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable']) {
                                    format = "free-single-select";
                                } else {
                                    format = "single-select";
                                }
                        }
                    }else if(format === 'table'){
                        switch (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionMultiselect']) {
                            case true :
                                if (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable']) {
                                    format = "free-multi-table";
                                } else {
                                    format = "multi-table";
                                }
                                break;
                            default:
                                if (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable']) {
                                    format = "free-single-table";
                                } else {
                                    format = "single-table";
                                }
                        }
                    }

                    let element = {
                        format : format,
                        label : model.bcmodels_bdfields_lists['bdfields_field'+i+'label'+currentLanguageLower],
                        mandatory : model.bcmodels_bdfields_lists['bdfields_field'+i+'mandatory'],
                        presel : model.bcmodels_bdfields_lists['bdfields_field'+i+'format'] === 'select' ? model.bcmodels_bdfields_lists['bdfields_field'+i+'content'+currentLanguageLower].split(';') : undefined,
                        multiselect : model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionMultiselect'],
                        editable : model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable'],
                        position : i,
                    };
                    setListFields(oldArray => [...oldArray, element]);
                }
            }
        }
        setOpenModalBiblioTemplatePreviewImport(true);
        props.attemptResetTemplateModelCode();
    }

    const handleClickPoper = (event, id) => {
        event.stopPropagation();
        event.preventDefault();
        if(id === null){
            setOpenPoper(null);
        }else if(openPoper === id){
            setOpenPoper(null);
        }else{
            setOpenPoper(id);
        }
    }

    const handleOpenDefaultValue = (index) => {
        setOpenDefaultEdit(previous => isDefined(previous) ? null : index )
    }

    const handleChangeDefaultValueEdit = (index, value) => {
        let model = [...newModel]
        console.log('field to change', model[index].options.setup)
        model[index].options.setup = {...model[index].options.setup, ...value}
        console.log('field changed', model[index].options.setup )
    }

    const handleChangeDefaultValue = (index, value) => {
        let model = [...newModel]
        console.log('field to change', model[index])
        model[index].options.setup.defaultValue = value;
        setNewModel(model)
    }

    useEffect(() => {
        if (isDefined(missingLabel)) {
            if (newModel.length > 0 && newModel[newModel.length - 1].label !== '') {
                setMissingLabel(null)
            }
        }
    }, [newModel])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.paper, classes.drawer, {
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClose]: !openDrawer,
                })}
                classes={{
                    paper: clsx(classes.paper, classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
                <div className={classes.openClose}>
                    <IconButton className={classes.openCloseButton} onClick={openDrawer ? handleDrawerClose : handleDrawerOpen}>
                        {!openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>

                <div>
                    <div className={`left-menu_contents_models `}>
                        <div className="">
                            <div className="inputWrapper" style={{width : '95%'}}>
                                <Input
                                    className="input font_familly"
                                    autoComplete='off'
                                    id="standard-full-width"
                                    style={{ margin: 8 }}
                                    placeholder={t('common:models.search-by-ref-number')}
                                    fullWidth={true}
                                    margin="none"
                                    disableUnderline={true}
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <div onClick={handleSearchTemplate}>
                                                <span className="material-icons">
                                                    search
                                                </span>
                                            </div>
                                        </InputAdornment>
                                    }
                                    value={props.fetchingTemplate ? t('global-loading') : searchTemplate}
                                    onChange={(event) => {
                                        setSearchTemplate(event.target.value)
                                    }}

                                />
                            </div>

                            <div className="cliquables text_align" >
                                <button className="button" style={{width : '95%'}}  onClick = {(event) => {
                                    setCountable(countable + 1);
                                    setOpenModalBiblioTemplate(true);
                                }}>
                                    {t('common:models.open-biblio')}
                                </button>
                            </div>
                        </div>

                        {openDrawer &&
                            <div className="left-menu_contents_wrapper text_align">
                            <span className="titres unbreakText ">
                                {t('common:models.select-formats')}
                            </span>
                                <br/>
                                <span className="titres unbreakText">
                                {t('common:models.select-formats-sub')}
                            </span>
                            </div>
                        }
                        <div className={"left-menu_contents_wrapper scrollbar-hidden"}>
                            <ul className={classes.scrollBar + " left-menu_contents_actions liste-formats-v5"}>
                                {
                                    props.fields.map((field, index) => (
                                    (field.format !== "timeplus" && !specialFields.includes(field.format)) &&
                                    <PlugTooltip  key={index} title={field[`description${langue}`]} aria-label="add" placement="top-start">
                                        <li className="formats flex_display_direction_column" onClick={(event) => handleListItemClick(event, field)}>
                                            <div className="flexSpacebetween">
                                                {(field.format.toLowerCase() === 'now') ?
                                                    /*<div className={classes.fieldIcon}>
                                                        <img
                                                            className={classes.fieldImage}
                                                            src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                            alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                        />
                                                        <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                    </div>*/
                                                    <div style={{display : 'flex', alignItems : 'baseline'}}>
                                                        <div className={classes.fieldIcon} style={{width : '20px'}}>
                                                            <img
                                                                className={classes.fieldImage}
                                                                src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        {
                                                            openDrawer && <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                        }                                                </div>
                                                    :
                                                    <div style={{display : 'flex', alignItems : 'inherit'}}>
                                                        <div className={classes.fieldIconSvg} style={{width : '20px'}}>
                                                            <img
                                                                className={classes.fieldImageSvg}
                                                                src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        {
                                                            openDrawer && <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                        }                                                </div>
                                                }
                                                {
                                                    openDrawer &&
                                                    <div className="grey flexSpacebetween padding_2">
                                                        <span className="references line_height_22 flex_display_direction_column" >
                                                        { (field?.pnpfcodefr && field.pnpfcodefr !== "" || field?.pnpfcodeen && field.pnpfcodeen !== ""|| field?.pnpfcodenl && field.pnpfcodenl !== "")  &&
                                                            <span id="icon-more" className={`material-icons md ${!isNull(openPoper)  && openPoper === field.id ? 'red' : 'green'}`}  onClick={(event) => handleClickPoper(event,field.id)} >
                                                                qr_code_scanner
                                                            </span>
                                                        }
                                                        </span>
                                                        <span className="references line_height_22" >
                                                            {
                                                                field.indice
                                                            }
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                !isNull(openPoper)  && openPoper === field.id &&
                                                <div>
                                                    <RenderPoperItem field={field} open={openPoper} handleClickPoper={handleClickPoper}  />
                                                </div>
                                            }
                                        </li>
                                    </PlugTooltip>
                                ))}
                                <li className="inheritClass"><div className="line flex_style" /></li>

                                {
                                    props.fields.map((field, index) => (
                                    (field.format !== "timeplus" && specialFields.includes(field.format)) &&
                                    <PlugTooltip  key={index} title={field[`description${langue}`]} aria-label="add" placement="top-start">
                                        <li className="formats flex_display_direction_column" onClick={(event) => handleListItemClick(event, field)}>
                                            <div className="flexSpacebetween">
                                                {
                                                    (field.format.toLowerCase() === 'now') ?
                                                        <div style={{display : 'flex', alignItems : 'baseline'}}>
                                                            <div className={classes.fieldIcon} style={{width : '20px'}}>
                                                                <img
                                                                    className={classes.fieldImage}
                                                                    src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                    alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                />
                                                            </div>
                                                            {
                                                                openDrawer &&
                                                                <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                            }
                                                        </div>
                                                        :
                                                        <div style={{display : 'flex', alignItems : 'inherit'}}>
                                                            <div className={classes.fieldIconSvg} style={{width : '20px'}}>
                                                                <img
                                                                    className={classes.fieldImageSvg}
                                                                    src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                    alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                />
                                                            </div>
                                                            {
                                                                openDrawer && <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                            }
                                                        </div>
                                                }
                                                    {
                                                        openDrawer &&
                                                        <div className="grey flexSpacebetween padding_2">
                                                            <span className="references line_height_22 flex_display_direction_column" >
                                                                {(field?.pnpfcodefr && field.pnpfcodefr !== "" || field?.pnpfcodeen && field.pnpfcodeen !== "" || field?.pnpfcodenl && field.pnpfcodenl !== "") &&
                                                                    <span id="icon-more" className={`material-icons md ${!isNull(openPoper) && openPoper === field.id ? 'red' : 'green'}`} onClick={(event) => handleClickPoper(event, field.id)} >
                                                                        qr_code_scanner
                                                                    </span>
                                                                }
                                                            </span>
                                                                <span className="references line_height_22" >
                                                                    {field.indice}
                                                                </span>
                                                        </div>
                                                    }
                                                </div>

                                            {
                                                !isNull(openPoper)  && openPoper === field.id &&
                                                <div>
                                                    <RenderPoperItem field={field} open={openPoper} handleClickPoper={handleClickPoper}  />
                                                </div>
                                            }
                                        </li>
                                    </PlugTooltip>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classes.openClose}>
                    <IconButton className={classes.openCloseButton} onClick={openDrawer ? handleDrawerClose : handleDrawerOpen}>
                        {!openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Content className={`${classes.contentModel} scrollbar-hidden`}>
                    <div className="sous-header-without-height p1 grey">
                        <div className="text_align">
                            <h3 className="">{t('common:models.create-model')}</h3>
                        </div>
                    </div>
                    <div className={`${classes.stickyHeader} sous-header p1 m2 grey`}>
                        <div className="sous-header_content">
                            <div className="div_width_50">
                                <Input
                                    id="standard-full-width"
                                    label="Label"
                                    style={{ margin: 8 }}
                                    placeholder={t('common:models.form-title')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className : classes.fieldRight
                                    }}
                                    onChange={handleChangeFolderName}
                                    value={nameModel}
                                />
                            </div>
                            <PlugTooltip title={t('global.cancel-exit')}>
                                <button className={`button ${classes.closeBtn}`} onClick={handleCancelExitModel}>
                                    <span className="material-icons" style={{ color: colors.hoverBgnd }}>
                                        cancel
                                    </span>
                                </button>
                            </PlugTooltip>

{/*                            <PlugTooltip title={t('common:multiple.save')} >
                                <button className="button" onClick={handleClickSaveModel}>
                                    <span className="material-icons" style={{ color: colors.hoverBgnd }} onClick={handleClickSaveModel}>
                                        save
                                    </span>
                                </button>
                            </PlugTooltip>*/}

                            <PlugTooltip title={t('common:global.safe-exit')}>
                                <button className="button" onClick={handleSafeExitModel}>
                                    <span className="material-icons" style={{ color: colors.hoverBgnd }}>
                                        logout
                                    </span>
                                </button>
                            </PlugTooltip>
                        </div>

                        <div className="sous-header_content black">
                            <div className="line flex_style">
                            </div>
                        </div>
                        <div>
                            <span>{t('common:global.model-annexe')}</span>
                        </div>
                    </div>
                    <div className="split-100 formulaireGauche p1">
                        <div id="liste-champs_remplir">
                            {t('common:plugnote.view-all-fields-empty')}
                            <input className="checkbox" type="checkbox" checked={fieldEmptyVisible}
                                   onChange={(event) => setFieldEmptyVisible(!fieldEmptyVisible)}/>
                        </div>

                        </div>

                    {newModel.find((elm) =>
                        (elm !== undefined  && elm.format === "extdata") || elm.format === "extdatan" || elm.format === "tablemultin"  || elm.format === "smailvalidmulti" ||
                        (elm.format === "tablemulti" && !_.isEmpty(elm?.formatoptions) && (elm?.formatoptions?.lines === undefined && elm?.formatoptions.find((subElm) => subElm?.format === 'extdata')) || (elm?.formatoptions?.lines !== undefined && elm?.formatoptions.lines.find((subElm) => subElm?.format === 'extdata'))) !== undefined) &&
                        <div className="split-100 formulaireGauche p1">
                            <div id="liste-champs_remplir">
                                {t('common:plugnote.view-search-external-db')}
                                <input className="checkbox" type="checkbox" checked={authoriseExternalDbInPf}
                                       onChange={(event) => setAuthoriseExternalDbInPf(!authoriseExternalDbInPf)}/>
                            </div>
                        </div>
                    }

                    <div className="split-100 formulaireGauche p1">
                        <div id="liste-champs_remplir">
                            {t('common:plugnote.view-pluglink-data')}
                            <input className="checkbox" type="checkbox" checked={authoriseShowPlugLinkData}
                                   onChange={(event) => setAuthoriseShowPlugLinkData(!authoriseShowPlugLinkData)}/>
                        </div>
                    </div>

                    <div className="split-100 formulaireGauche">
                        <div id="liste-champs_remplir">
                            <div className="champs_remplir flexbox-field-item m2">
                                <div className={classes.formatContainer} style={{paddingBottom: 10}}>
                                    <div style={{
                                        width: '10%',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly',
                                        marginTop: 'auto' 
                                    }}>
                                        <span>Icon</span>
                                        <span>N°</span>
                                    </div>
                                    <div style={{width: '15%', textAlign :'center', marginTop: 'auto' }}>
                                        <span>{t('common:helper.format')}</span>
                                    </div>
                                    <div style={{width: '30%', textAlign :'center', marginTop: 'auto' }}>
                                        <span>{t('common:helper.label')}</span>
                                    </div>

                                    {/* Default Value */}
                                    <div style={{width: '10%', textAlign :'center'}}>
                                        <span style={{ whiteSpace: 'pre-line' }}>{t('helper.options')}</span>
                                    </div>
                                    
                                    {/* Visible in PDF / PN / PF */}
                                    <div style={{ width: '15%', textAlign: 'center', whiteSpace: 'pre-line', marginTop: 'auto' }}>
                                        {t('common:helper.visibility')}
                                    </div>
                                    <div style={{width: '5%', justifyContent : 'center' , display : 'flex', marginTop: 'auto' }}>
                                        <PlugTooltip title={t('common:helper.rgpd')}>
                                            <span id="icon-more" className="md">
                                                RGPD
                                            </span>
                                        </PlugTooltip>
                                    </div>
                                    <div style={{width: '15%', textAlign :'center', marginTop: 'auto' }}>
                                        <span>{t('helper.actions')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="splitContent flexSpacebetween">
                        <div className="split-100 formulaireGauche">
                            {newModel.length > 0 &&
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {newModel.map((field, index) => (
                                                    <Draggable key={field.dragid} draggableId={field.dragid} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div id="liste-champs_remplir">
                                                                    <div className="champs_remplir flexbox-field-item m2">
                                                                        <div className={classes.formatContainer}>
                                                                            <div style={{ width: '5%', textAlign: 'center', display: 'flex', flexDirection: 'column', }}>
                                                                                <span className="green">
                                                                                    {
                                                                                        field.format.toLowerCase() === 'now' ?
                                                                                            <div className={classes.fieldIcon}>
                                                                                                <img
                                                                                                    className={classes.fieldImage}
                                                                                                    src={Images[camelCase(field.format.toLowerCase()) + "Icon"]}
                                                                                                    alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            <div className={classes.fieldIconSvg}>
                                                                                                <img
                                                                                                    className={classes.fieldImageSvg}
                                                                                                    src={Images[camelCase(field.format.toLowerCase()) + "IconSvg"]}
                                                                                                    alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                />
                                                                                            </div>
                                                                                    }
                                                                                </span>
                                                                                <span className="references_8 line_height_22">({(index + 1)})</span>
                                                                            </div>
                                                                            <div style={{ textAlign: 'center', width: '5%' }}>
                                                                                <span className="references line_height_22" >{renderFormatText(field)}
                                                                                </span>
                                                                            </div>

                                                                            <div style={{width: '15%', textAlign: 'center' }}>
                                                                                <span>{t('fields.format-choice-'+field.format.toLowerCase())}</span>
                                                                            </div>
                                                                            <div className="champsFormulaire flexSpacebetween m3" 
                                                                                style={{width: fieldsWithDefaultValue.includes(field.format) && !fieldsWithOptions.includes(field.format) ? '30%' : '40%'}}>
                                                                                {
                                                                                    isDefined(field.options.setup?.defaultValue) && field.options.setup.defaultValue !== '' &&
                                                                                    <PlugTooltip placement="top" title={t('helper.default-value') + ':\n' + field.options.setup.defaultValue} >
                                                                                        <span onClick={() => handleOpenDefaultValue(index)} >
                                                                                            <EditValueIcon style={{ cursor: 'pointer', color: colors.bloodOrange }}/>
                                                                                        </span>
                                                                                    </PlugTooltip>
                                                                                }
                                                                                <RenderItemFormatComponent {...props} 
                                                                                    openSMail={openSMail} handleOpenCloseSmail={handleOpenCloseSmail}
                                                                                    field={field} handleEditLabel={handleEditLabel} index={index}
                                                                                    onChangeHandlerDrop={onChangeHandlerDrop}
                                                                                    setNewModel={setNewModel} newModel={newModel}
                                                                                    update={false} folderName={nameModel}
                                                                                    isCreate
                                                                                />

                                                                                {
                                                                                    missingLabel === index &&
                                                                                    <PlugTooltip placement="top" title={t('tablemulti.missing-label')} >
                                                                                        <span className="material-icons" style={{ color: 'red' }}>
                                                                                            error
                                                                                        </span>
                                                                                    </PlugTooltip>
                                                                                }
                                                                            </div>

                                                                            {
                                                                                fieldsWithDefaultValue.includes(field.format) && !fieldsWithOptions.includes(field.format) &&
                                                                                <span style={{ width: '10%' }}>
                                                                                    <PlugTooltip title={t('helper.default-edit')} placement="top">
                                                                                        <span onClick={() => handleOpenDefaultValue(index)} className={classes.paramBtn}>
                                                                                            <SettingsIcon color="white" />
                                                                                            <span className="material-icons" style={{ color: 'white' }}>
                                                                                                {openDefaultEdit === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                                                            </span>
                                                                                        </span>
                                                                                    </PlugTooltip>
                                                                                </span>
                                                                            }
                                                                            <PlugTooltip title={t('helper.toggle-visibility')} placement="top">
                                                                                <div style={{ width: '15%', justifyContent: 'center', display: 'flex', gap: 5 }}>
                                                                                    <span onClick={() => handleToggleVisiblePDF(field, index)} >
                                                                                        <VisibilityIndicator text="PDF" visible={field.options.setup?.printable === true}/>
                                                                                    </span>
                                                                                    <span onClick={!notEncodable.includes(field.format) ? handleVisibleField.bind(this, field, index) : null} >
                                                                                        <VisibilityIndicator text="PN-" visible={field.isVisible === true}/>
                                                                                    </span>
                                                                                    <span onClick={!notEncodable.includes(field.format) ? handleEditableField.bind(this, field, index) : null} >
                                                                                        <VisibilityIndicator text="PF-" visible={field.isEncodable === true}/>
                                                                                    </span>
                                                                                </div>
                                                                            </PlugTooltip>
                                                                            <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
                                                                                <div className="grey flexSpacebetween">
                                                                                    <PlugTooltip
                                                                                        title={t('common:helper.rgpd')}
                                                                                        aria-label="add"
                                                                                        placement="top-start">
                                                                                        <div className="grey flexSpacebetween" onClick={() => handleToggleRGPD(field, index)}>
                                                                                            <ColorCheckbox
                                                                                                checked={field?.options?.rgpd}
                                                                                            />
                                                                                        </div>
                                                                                    </PlugTooltip>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{width: '15%', justifyContent : 'center' , display : 'flex'}}>
                                                                                <div className="grey flexSpacebetween">
                                                                                    <PlugTooltip  title={t('common:helper.move-field')} aria-label="add" placement="top-start">
                                                                                    <span id="icon-more" className="material-icons md-small"
                                                                                            {...provided.dragHandleProps}>
                                                                                        drag_handle
                                                                                    </span>
                                                                                    </PlugTooltip>
                                                                                    <PlugTooltip title={t('common:helper.remove-field')} aria-label="add" placement="top-start">
                                                                                        <span id="icon-more" className="material-icons md-small" onClick={handleDeleteField.bind(this, field)}>
                                                                                            clear
                                                                                        </span>
                                                                                    </PlugTooltip>

                                                                                    {
                                                                                        field.format !== 'plugform' &&
                                                                                            <PlugTooltip
                                                                                                title={field.options.mandatory ? t('helper.mandatory-field') : t('helper.not-mandatory-field')}
                                                                                                aria-label="add"
                                                                                                placement="top-start">
                                                                                                <span onClick={handleToggleMandatoryField.bind(this, field, index)} >
                                                                                                    {field.options.mandatory ?
                                                                                                        <Asterisk color="black" size={18} />
                                                                                                        :
                                                                                                        <AsteriskOutline color="black" size={18} />}
                                                                                                </span>
                                                                                            </PlugTooltip>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            subFields.includes(field.format.toLowerCase()) &&
                                                                            <div className={classes.subFormatContainer}>
                                                                                <RenderSubItemFormatComponent
                                                                                    classes={classes}
                                                                                    openSMail={openSMail} field={field}
                                                                                    handleChangeDefaultValue={handleChangeDefaultValue}
                                                                                    handleChangeDefaultValueEdit={handleChangeDefaultValueEdit}
                                                                                    handleEditLabel={handleEditLabel}
                                                                                    handleOpenCloseSmail={handleOpenCloseSmail}
                                                                                    index={index}
                                                                                    onChangeHandlerDrop={onChangeHandlerDrop}
                                                                                    setNewModel={setNewModel}
                                                                                    newModel={newModel}
                                                                                    update={false}
                                                                                    setOpenDrawer={setOpenDrawer}
                                                                                    folderName={nameModel}
                                                                                    t={t}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        <Collapse in={openDefaultEdit === index} timeout="auto" unmountOnExit>
                                                                            <Box>
                                                                                <div className={classes.subFormatContainer}>
                                                                                    <RenderEditDefaultValueComponent 
                                                                                        field={field}
                                                                                        handleChangeDefaultValue={(value) => handleChangeDefaultValue(index, value)} 
                                                                                        handleChangeDefaultValueEdit={(value) => handleChangeDefaultValueEdit(index, value)}
                                                                                    />
                                                                                </div>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            }
                            <div className="flexbox-container-hidden"/>
                        </div>
                    </div>
                </Content>
            </main>

            {
                openModalModelTemplate ?
                    <ModalModelTemplateComponent
                        open={openModalModelTemplate}
                        close={handleModalModelTemplateClose}
                        list_models={props.list_models}
                        list_group={props.list_group}
                        list_categorie={props.list_categorie}
                        setNewModel={setNewModel}
                        models={models}
                        group={group}
                        categorie={category}
                        code={searchTemplate}
                    />
                    :
                    null
            }

            {
                openModalBiblioTemplate ?
                    <ModalBiblioTemplateComponent
                        open={openModalBiblioTemplate}
                        close={handleModalBiblioTemplateClose}
                        countable={countable}
                    />
                    :
                    null
            }

            { openModalBiblioTemplatePreviewImport ?
                <ModalBiblioPreviewImportComponent
                    open={openModalBiblioTemplatePreviewImport}
                    close={handleModalBiblioTemplatePreviewImportClose}
                    listFields={listFields}
                    viewFields={viewFields}
                    viewModel={viewModel}
                    attemptCreateModelBiblioAsking={props.attemptCreateModelBiblioAsking}
                />
                :
                null
            }
        </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {

    let tmpFields = state.field.list;
    //let fields = sortBy(state.field.list, 'position').filter((field) => field.active);
    let fields;

    if(typeof state.user.user.fields !== "undefined" && state.user.user.fields.length > 0){
        let tmp = tmpFields.filter((field, fieldIndex) => {

            if (field.check && state.user.user.fields.includes((field.indice))){
                return field;
            }else if (!field.check){
                return field
            }
        });

        tmp.filter(Boolean)

        fields = sortBy(tmp, 'position').filter((field) => field.active)
    }else{
        fields = sortBy(tmpFields, 'position').filter((field) => field.active && !field.check)
    }

    return {
        fetchingTemplate : state.template.fetchingTemplate,
        user : state.user,
        token : state.user.token,
        fields : fields,
        list_models : state.template.list_models,
        model_by_code : state.template.model_by_code,
        list_group : state.template.list_group,
        list_categorie : state.template.list_categorie,
        open_list_models :state.template.open_list_models,
        open_list_group :state.template.open_list_group,
        open_list_categorie :state.template.open_list_categorie,
        open_model_code :state.template.open_model_code,
        categories : Object.assign([], state.model.categories),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetFields: () => dispatch(getFieldsRequest()),
        attemptCreateModel: (model,userhash) => dispatch(postModelRequest(model,userhash)),
        attemptGetTemplateCodeGroup: (codeGroup,userhash) => dispatch(getTemplateCodeGroupRequest(codeGroup,userhash)),
        attemptGetTemplateCodeModelGroup: (codeModel,userhash) => dispatch(getTemplateCodeModelRequest(codeModel,userhash)),
        attemptgetTemplateCodeCategory: (codeCategorie,userhash,langue) => dispatch(getTemplateCodeCategorieRequest(codeCategorie,userhash, langue)),
        attemptGetTemplateMobelByCode: (code,userhash,langue) => dispatch(getTemplateModelCodeRequest(code,userhash, langue)),
        attemptResetTemplateHistory:() => dispatch(resetTemplateHistoryRequest()),
        attemptResetTemplateOpenListModels:() => dispatch(resetTemplateOpenListModelsRequest()),
        attemptResetTemplateModelCode:() => dispatch(resetTemplateModelCodeRequest()),
        attemptResetTemplateOpenListGroup:() => dispatch(resetTemplateOpenListGroupRequest()),
        attemptResetTemplateOpenListCategorie:() => dispatch(resetTemplateOpenListCategorieRequest()),
        attemptCreateModelBiblioAsking: (payload,userhash,language) => dispatch(postCreateModelBiblioV2Request(payload,userhash,language)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelCreateV2)
