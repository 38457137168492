import React, {useEffect, useRef, useState} from 'react';
import {saveAs} from 'file-saver';
import {
    deleteHistoryPlugAction,
    getAnnexes,
    getCurrentEnvironnement,
    getModel,
    getPlugnote,
    getToken,
    getUser,
    setAnnexes
} from '../../utils/Common';
import axios from "axios";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel, faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faStickyNote
} from "@fortawesome/free-regular-svg-icons";


import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {putUpdateDataRequest} from "../../actions/data";
import {connect} from "react-redux";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import UserIcon from "@material-ui/icons/PeopleAlt";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {Content, Header} from "rsuite";
import Grid from "@material-ui/core/Grid";
import {delDeleteAnnexeRequest, postAddNoteRequest, postAnnexesRequest, putEditNoteRequest} from "../../actions/annexe";
import ApplicationConfig from "../../config/ApplicationConfig";
import {trackPromise} from "react-promise-tracker";
import _, {camelCase, isString} from "lodash";
import {useTranslation} from "react-i18next";
import Immutable from "seamless-immutable";
import moment from "moment";
import Images from "../../assets/themes/Images";
import PhoneInput from "react-phone-number-input";
import FormatDateComponent from "../../components/formats/FormatDateComponent";
import FormatAgendaComponent from "../../components/formats/FormatAgendaComponent";
import FormatNowComponent from "../../components/formats/FormatNowComponent";
import FormatBirthdayComponent from "../../components/formats/FormatBirthdayComponent";
import FormatTimeComponent from "../../components/formats/FormatTimeComponent";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import FormatMultipleSelectComponent from "../../components/formats/FormatMultipleSelectComponent";
import FormatSingleSelectComponent from "../../components/formats/FormatSingleSelectComponent";
import FormatKeywordComponent from "../../components/formats/FormatKeywordComponent";
import FormatBooleanComponent from "../../components/formats/FormatBooleanComponent";
import FormatPlugcodeComponent from "../../components/formats/FormatPlugcodeComponent";
import FormatPasswordComponent from "../../components/formats/FormatPasswordComponent";
import InputLabel from "@material-ui/core/InputLabel";
import FormatLocalisationComponent from "../../components/formats/FormatLocalisationComponent";
import Tooltip from "@material-ui/core/Tooltip";
import ModalNoteComponent from "../../components/modals/ModalNoteComponent";
import FormatMemoComponent from "../../components/formats/FormatMemoComponent";
import ModalCopyOrSavePlugcodeComponent from "../../components/modals/ModalCopyOrSavePlugcodeComponent";
import FormatPhoneComponent from "../../components/formats/FormatPhoneComponent";
import FormatMailComponent from "../../components/formats/FormatMailComponent";
import FormatUrlComponent from "../../components/formats/FormatUrlComponent";
import FormatMultipleSelectTableComponent from "../../components/formats/FormatMultipleSelectTableComponent";
import FormatSingleSelectTableComponent from "../../components/formats/FormatSingleSelectTableComponent";
import FormatPlugformComponent from "../../components/formats/FormatPlugformComponent";
import FormatPlugLinkComponent from "../../components/formats/FormatPlugLinkComponent";
import FormatFormulaComponent from "../../components/formats/FormatFormulaComponent";
import FormatGeoLocalisationComponent from "../../components/formats/FormatGeoLocalisationComponent";
import {ViewList} from "@material-ui/icons";
import Plugforms from "../../components/plugforms/Plugforms";
import parse from 'html-react-parser';
import {confirmAlert} from "react-confirm-alert";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    confirmContainer: {
        border: "1px solid #F9F9F9",
        backgroundColor: 'white',
        borderRadius: 15,
        padding: '15px',
    },
    confirmTitle: {
        color: 'black',
        fontSize: '30px',
    },
    confirmText: {
        color: '#88949C',
    },
    confirmButtons: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    confirmButtonStyle: {
        width: "100px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            /* width: "100%", */
        },
    },
    confirmAffirmative: {
        color: "white",
        backgroundColor: "#58c3c1",
        border: "none",
    },
    confirmNegative: {
        color: "#E3E3E3",
        backgroundColor: "transparent",
        border: "2px solid #E3E3E3",
        fontWeight: "600",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '57px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        position: 'absolute',
        width : '100%'
    },
    openCloseRelative: {
        marginTop : 5,
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'5px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    content: {
        flexGrow: 1,
        padding: 0,
    },
    contentModel : {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow : 'scroll',
        maxHeight : 'calc(100vh - 200px)'
    },
    contentModelMain : {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'blue'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',

    },
    selectEnvironnement : {
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldIconMandatory : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border : '1px solid red'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    field : {
        display: 'flex',
        flexDirection: 'row',
        width : '100%'
    },
    fieldLeft : {
        alignContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
    },
    fieldRight : {
        width : '100%'
    },
    fieldRightLeft : {
        alignContent: 'flex-end'
    },
    fullWidth :{
        width : '100%'
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
}));

const specialFields = ['plugform','textarea', 'list', 'single-select', 'free-single-select', 'multi-select', 'free-multi-select','single-table', 'free-single-table', 'multi-table', 'free-multi-table', 'qrcode', 'localis', 'glocalis'];
const zeroPad = (num, places) => String(num).padStart(places, '0');

function PlugConsultFromCodeGalleryV2(props) {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);

    const inputFile = useRef(null);

    const plugnote = props.plugnote;

    const dataForHash = props.plugnote;
    const model = props.plugform;

    const modelForHash = props.plugform;

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [cancel, setCancel] = React.useState(false);

    const [isOpenModalAnnexe, setIsOpenModalAnnexe] = React.useState(false);
    const [selectedAnnexe, setSelectedAnnexe] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);


    const [openModal, setOpenModal] = React.useState(false);
    const [openNote, setOpenNote] = React.useState(false);
    const [isOpenFloatingMenu, setIsOpenFloatingMenu] = React.useState(false);

    const [dataHasChanged, setDataHasChanged] = React.useState(false);

    const environment = getCurrentEnvironnement();




    const [fields, setFields] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState(null);

    const user = getUser();
    const token = getToken();

    const [plugnoteAnnexes, setPlugnotesAnnexes] = useState( typeof props.annexes !== "undefined" && props.annexes.length > 0 ? props.annexes  : []);
    //const [plugnoteAnnexes, setPlugnotesAnnexes] = useState( getAnnexes());
    const [loading, setLoading] = useState(true);
    const [openClassify, setOpenClassify] = useState(false);
    const [loaded, setLoaded] = useState(0);

    const [nameModel, setNameModel] = useState(modelForHash !== null ?  modelForHash.label : '');

    const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
    const [images, setImages] = useState([null]);

    const [openModalPreview, setOpenModalPreview] = React.useState(false);

    const [isOpenModalListAnnexes, setIsOpenModalListAnnexes] = React.useState(false);


    useEffect(() => {
        const labels = Object.keys(model).filter((key) => {
            const regex = /field[0-9]+label/gi;
            return regex.test(key);
        });

        let champs = labels.map((item) => {
            return model[item];
        });

        let listFields = [];

        champs.forEach((field, index) => {
            if (field) {

                const fieldIndex = index + 1;
                let value = "";
                let format = {};
                let visuelFormat = {};
                let show = false;
                let event = "";
                let visible = false;
                let visualDefault = false;

                let options = [];


                if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
                    // options = JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]);
                    options = modelForHash[`field${fieldIndex}formatoptions`];
                    if (typeof options === 'string') {
                        options = JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]);
                    }
                }

                // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                if (!_.isEmpty(modelForHash)) {
                    format = modelForHash[`field${fieldIndex}format`];
                    visible = modelForHash[`field${fieldIndex}visible`];
                    visuelFormat = modelForHash[`field${fieldIndex}format`];
                }

                // Si c'est une préselection on initialise la valeur à un tableau vide
                if ( options != null && (options.presel || format === "list")) {
                    value = [];
                }

                if (!_.isEmpty(dataForHash)) {
                    if(format === 'numeric' && typeof dataForHash[`field${fieldIndex}`] !== "undefined"){
                        value = Number.isInteger(dataForHash[`field${fieldIndex}`]) ?  parseInt(dataForHash[`field${fieldIndex}`]) : parseFloat(dataForHash[`field${fieldIndex}`].replace(',', '.'));
                    }else if(format === 'list'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                            value = [];
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            let keywordList = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            value = [];
                            keywordList.map((item, index) => {
                                value.push(item.text);
                            })
                        }
                    }else if(format === 'plugform'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "index" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'intdatamaj'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "updatedDate" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'qrcodeint'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"code" : "", "model" : "", "data" : "", "comment" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'pluglink'){
                        //value = {"code" : JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]).pfcode, "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                        value = {"code" : typeof modelForHash[`field${fieldIndex}formatoptions`] !== "undefined" ? JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])?.pfcode : "", "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                    }
                    else if(format === 'tablemulti'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'tablemultin'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "list" : []};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'document'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "list" : []};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'smailvalidmulti'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else{
                        value = dataForHash[`field${fieldIndex}`];
                    }
                }

                // On vérifie si le champ est une préselection avec les options
                if (options != null && options.presel && format === 'select') {
                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                        value = Immutable.asMutable(value);
                    }

                    if(options?.visibledefault){
                        visualDefault = options?.visibledefault;
                    }

                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                    if (!Array.isArray(value)) {
                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }

                    switch (options.multiselect) {
                        case true :
                            if (options.editable) {
                                format = "free-multi-select";
                            } else {
                                format = "multi-select";
                            }
                            break;
                        default:
                            if (options.editable) {
                                format = "free-single-select";
                            } else {
                                format = "single-select";
                            }
                    }
                    options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                }
                else if (options != null && options.presel && format === 'table') {
                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                        value = Immutable.asMutable(value);
                    }

                    if(options?.visibledefault){
                        visualDefault = options?.visibledefault;
                    }

                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                    if (!Array.isArray(value)) {
                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }

                    switch (options.multiselect) {
                        case true :
                            if (options.editable) {
                                format = "free-multi-table";
                            } else {
                                format = "multi-table";
                            }
                            break;
                        default:
                            if (options.editable) {
                                format = "free-single-table";
                            } else {
                                format = "single-table";
                            }
                    }
                    options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                }
                else {

                    if (format === 'select') {
                        format = 'multi-select'
                        options = [];

                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }
                    if (format === 'table') {
                        format = 'multi-table'
                        options = [];

                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }
                }

                if (format === "now" && !value) {
                    value = moment().format("YYYY-MM-DD HH:mm:ss");
                    setDataHasChanged(true);
                }

                if( (format === "free-multi-select" || format === "free-single-select") && value.length > 0){
                    value.map((item, index) => {
                        if(!options.presel.includes(item)){
                            options.presel.push(item);
                        }
                    })
                }

                if( (format === "free-multi-table" || format === "free-single-table") && value.length > 0){
                    value.map((item, index) => {
                        if(!options.presel.includes(item.text)){
                            options.presel.push(item.text);
                        }
                    })
                }


                listFields.push({
                    id: fieldIndex,
                    title: modelForHash[`field${fieldIndex}label`],
                    titleTranslate: modelForHash[`field${fieldIndex}label`],
                    value: typeof value === "undefined" ? "" : value,
                    visuelValue : value,
                    visible,
                    format,
                    visuelFormat,
                    options,
                    mandatory: modelForHash[`field${fieldIndex}mandatory`],
                    show,
                    event: event,
                    visualDefault
                });

            }
        });

        setFields(listFields);
    }, []);

    useEffect(() => {
        setPlugnotesAnnexes(props.annexes);
    }, [props.annexes]);

    useEffect(() => {
        if(selectedFiles !== null){
            onClickHandler();
        }
    }, [selectedFiles]);

    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */

    const handleModalAnnexeClose = () => {
        setSelectedAnnexe(null);
        setIsOpenModalAnnexe(false);
    }

    const handleModalAnnexeOpen = (annexe) => {
        setSelectedAnnexe(annexe);
        setIsOpenModalAnnexe(true);
    }

    const handleEditValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
        return value;
    }

    const handleEditPhoneValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleEditDateTime = (date,value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;

        listFields[index].visuelValue = date;

        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };

    const handleClickCancelModal = () => {
        props.history.push("/pluglist");
        setOpenModal(false);
    };

    const handleClickContinueModal = () => {
        setOpenModal(false);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleExitPlugnote = () => {
        props.history.push("/plugcodes");
    }

    const checkData = (fields) => {
        return fields.some((field) =>{
            if(field.format !== null && typeof field.format !== "undefined"){
                if(!!field.mandatory && field.mandatory === 1){
                    if(field.format.includes('select')){
                        return field.value.length === 0;
                    }else if(field.format === 'list'){
                        return ((!field.value || field.value === '[]' || field.value.length === 0) && field.format != "indice");
                    }else if(field.format === 'boolean'){
                        return (field.value === "" );
                    }else{
                        return (!field.value && field.format != "indice");
                    }
                }
            }
        })
    }

    /**
     * Construction de la nouvelle fiche à partir des champs
     * @param fields
     */
    const buildUpdateAnnexe = (fields) => {

        let newData = plugnote;

        fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else if (field.format === "plugform") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "qrcodeint") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({"code" : "", "model" : "", "data" : "", "comment" : ""});
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }else if (field.format === "intdatamaj") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "list") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = correctValue;
                }
            }
            else if(field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "tablemultin") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "smailvalidmulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            }
            else  if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });

        return newData;
    };

    const handleClickAlertWithCancel = () => {
        setCancel(true);
        setOpenModal(true);
    }

    const handleClickAlertWithoutCancel = () => {
        setCancel(false);
        setOpenModal(true);
    }

    const handleSavePlugnote = () => {
        setOpenClassify(true);
    };

    const handleChangeTextField = () => {

    }

    const handleChangeCheckBox = (event, field, index, multiselect) => {

    }

    const includesInJson = (search , value) => {
        return search.findIndex((item, index) => {
            return item.text === value
        })
    }

    const handleChangeInputTable = (value, indexation,field,index,multiselect,mandatory) => {

        let listFields = fields;

        if(mandatory){
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value.text, "value" : value.value});
            }else{
                if(includesInJson(listFields[index].value, value.text) === -1){
                    listFields[index].value.push({"text" : value.text, "value" : value.value});
                }else{
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value =  value.value;
                }            }
        }
        else{
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value.text, "value" :  value.value});
            }else{
                if(includesInJson(listFields[index].value, value.text) === -1){
                    listFields[index].value.push({"text" : value.text, "value" : value.value});
                }else{
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value =  value.value;
                }            }
        }

        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleChangeRadio = (value, indexation,field,index,multiselect,mandatory) => {

        let listFields = fields;

        if(mandatory){
            if(listFields[index].value.includes(value)){
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            }else {
                if (!multiselect) {
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                } else {
                    listFields[index].value.push(value);
                }
            }
        }else{
            if(listFields[index].value.includes(value)){
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            }else{
                if(!multiselect){
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                }else{
                    listFields[index].value.push(value);
                }
            }
        }
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleChangeKeyword = (value, indexation,field,index) => {

        let listFields = fields;
        if(listFields[index].value.includes(value)){
            listFields[index].value = listFields[index].value.filter(item => item !== value)
        }else{
            listFields[index].value.push(value);
        }

        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeBoolean  = (value,field,index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangePlugcode = (plugcode, comment, field, index) => {
        let listFields = fields;
        listFields[index].value = plugcode + ';' + comment;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangePlugcodeInternal = (plugcode, comment, field, index, destinations) => {
        let listFields = [...fields];

        listFields[index].value = plugcode;

        if (destinations?.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        switch (field.format) {
                            case 'numeric':
                                let tmpValue = destination.value.replaceAll(",", ".");
                                return {...field, value : tmpValue, visuelValue :  tmpValue}
                            default:
                                return {...field, value : destination.value, visuelValue :  destination.value}
                        }
                    }
                }
                return field;
            });

            setFields(updatedListFields);
            setDataHasChanged(true);
        }else{
            setFields(listFields);
            setDataHasChanged(true);
        }
    }

    const handleChangePassword = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeLocalisation = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleUploadClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    useEffect(() => {
        setPlugnotesAnnexes(getAnnexes());
    }, [props.annexes]);

    const handleDeleteAnnexe = (event, annexe, previousFile) => {
        event.preventDefault();
        event.stopPropagation();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classes.confirmContainer}>
                        <h1 className={classes.confirmTitle}>{t('global.confirm')}</h1>
                        <p className={classes.confirmText}>{t('plugnote.confirm-delete-annexe')}</p>
                        <div className={classes.confirmButtons}>
                            <button className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`} onClick={onClose}>{t('global.cancel')}</button>
                            <button
                                className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                                onClick={() => {
                                    props.attemptDeleteAnnexe(annexe.hash, plugnote.owner_hash);
                                    if(typeof previousFile !== "undefined" && previousFile !== null){
                                        previousFile();
                                    }
                                    onClose();
                                }}
                            >
                                {t('global.yes')}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    };

    const handleDownloadFile = (annexe) => {
        saveAs(ApplicationConfig.downloadUrl + environment.userhash + '/' + plugnote.hash + '/' + annexe.name + '.' + annexe.type, annexe.name + '.' + annexe.type);
    };

    //      .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
    const downloadEmployeeData = (event, annexe) => {
        event.preventDefault();
        event.stopPropagation();

        // Fetch the dynamically generated excel document from the server.
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.data.result){

                    const fileNameHeader = "x-suggested-filename";
                    const suggestedFileName = response.headers[fileNameHeader];
                    const effectiveFileName = (suggestedFileName === undefined
                        ? annexe.name + '.' + annexe.type
                        : suggestedFileName);


                    var a = document.createElement("a"); //Create <a>
                    a.href = response.data.base64; //Image Base64 Goes here
                    a.download = effectiveFileName; //File name Here
                    a.click(); //Downloaded file

                }
            }).catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            })
        )

    }

    const getUrlPreviewAnnexe = (annexe) => {
        return ApplicationConfig.downloadUrl + plugnote.owner_hash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
    }

    const renderAnnexePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} />;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;
        }
    };

    const handleSaveNote = (title, content) => {
        props.attemptAddNoteAnnexe(model.hash, plugnote.hash,environment.userhash, title, content , false, null);
    }

    const handleUpdateNote = (title, content, annexehash, notehash) => {
        setSelectedNote(null);
        props.attemptEditNoteAnnexe(annexehash,notehash,title,content)
    }

    const handleNoteOpen = (annexe) => {
        if(typeof annexe !== "undefined"){
            setSelectedNote(annexe);
        }else{
            setSelectedNote(null);
        }

        setOpenNote(true);

    };

    const handleNoteClose = () => {
        setOpenNote(false);
    };

    const handleCloseClassify = () => {
        setOpenClassify(false);
    }

    const handleCreateNote = () => {
        handleNoteOpen();
    }

    const onError = (e) => {
        //console.log(e, 'error in file-viewer');
    }

    const checkMimeType=(event)=>{
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        // const types = ['image/png', 'image/jpeg', 'image/gif']
        // // loop access array
        // for(var x = 0; x<files.length; x++) {
        //     // compare file type find doesn't matach
        //     if (types.every(type => files[x].type !== type)) {
        //         // create error message and assign to container
        //         err[x] = files[x].type+' is not a supported format\n';
        //     }
        // };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    }
    const maxSelectFile=(event)=>{
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    }
    const checkFileSize=(event)=>{
        let files = event.target.files
        let size = 1000000000
        let err = [];
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+'is too large, please pick a smaller file\n';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    }
    const onChangeHandler=event=>{
        var files = event.target.files
        if(maxSelectFile(event) && checkMimeType(event) &&    checkFileSize(event)){
            // if return true allow to setState
            setLoaded(0);
            setSelectedFiles(files);
        }
    }
    const onClickHandler = () => {
        const data = new FormData();
        for(let x = 0; x < selectedFiles.length; x++) {
            data.append('file', selectedFiles[x])
        }

        props.attemptPostAnnexes(model.hash, environment.userhash, plugnote.hash, false, false, null ,data);
    };

    const maxSelectFileDrop=(files)=>{
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            toast.warn(msg)
            return false;
        }
        return true;
    };

    const checkFileSizeDrop=(files)=>{
        let size = 100000000;
        let err = [];
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+'is too large, please pick a smaller file\n';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
        }
        return true;
    };

    const onChangeHandlerDrop = (acceptedFiles) => {
        const files = acceptedFiles;

        if(maxSelectFileDrop(files) &&  checkFileSizeDrop(files)){
            setLoaded(0);
            setSelectedFiles(files);
        }
    }


    const renderAnnexeIconPreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                /* return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>; */
                return <FontAwesomeIcon icon={faFileImage} className={classes.faIconSize}  />;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} className={classes.faIconSize}  />;
                break;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} className={classes.faIconSize}   onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} className={classes.faIconSize}  />;
                break;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} className={classes.faIconSize}   />;
                break;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} className={classes.faIconSize}  />;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize}  />;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} className={classes.faIconSize}  />;
                break;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} className={classes.faIconSize}   />;
                break;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize}  />;
                break;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} className={classes.faIconSize}  />;
                break;
            default :
                return <FontAwesomeIcon icon={faFile} className={classes.faIconSize}/>;
                break;
        }
    };

    const renderAnnexeImagePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                /* case 'jfif': */
                return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} /* className={classes.modalFile} *//>;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <video className={classes.modalFile} controls><source  src={getUrlPreviewAnnexe(annexeToPreview)} type={`video/${annexeToPreview.type.toLowerCase()}`} /></video>;
                break;

            case 'pdf':
                return <iframe src={getUrlPreviewAnnexe(annexeToPreview)} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;

            case 'zip':
            case 'rar':
                return <div className={classes.notRender}><FontAwesomeIcon icon={faFileArchive} className={classes.faIconView}  /><p class={classes.notRenderText}>{t("multiple.file-not-previewed")}</p></div>;
                break;

            case 'mp3':
            case 'aac':
                return <audio className={classes.modalFile} controls><source  src={getUrlPreviewAnnexe(annexeToPreview)} type={`audio/${annexeToPreview.type.toLowerCase()}`} /></audio>;
                break;

            case 'html':
                return <iframe src={getUrlPreviewAnnexe(annexeToPreview)} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;

            case 'txt':
                return <iframe src={getUrlPreviewAnnexe(annexeToPreview)} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;

            case 'sheet':
            case 'doc':
            case 'docx':
                /* return <iframe src={`https://docs.google.com/gview?url=${getUrlPreviewAnnexe(annexeToPreview)}&embedded=true`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />; */
                return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${getUrlPreviewAnnexe(annexeToPreview)}`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${getUrlPreviewAnnexe(annexeToPreview)}`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;

            case 'note':
                return <div className={classes.notRender}><FontAwesomeIcon icon={faStickyNote} className={classes.faIconSize}   />
                    <p class={classes.notRenderText}>
                        {/* {t("multiple.file-not-previewed")} */}
                        {parse(annexeToPreview)}
                    </p>
                </div>;
                break;

            case 'pages':
                return <div className={classes.notRender}><FontAwesomeIcon icon={faFileWord} className={classes.faIconSize}  /><p class={classes.notRenderText}>{t("multiple.file-not-previewed")}</p></div>;
                break;

            case 'pptx':
            case 'ppt':
                return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${getUrlPreviewAnnexe(annexeToPreview)}`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />;
                break;
            default :
                return <div className={classes.notRender}><FontAwesomeIcon icon={faFile} className={classes.faIconView}/><p class={classes.notRenderText}>{t("multiple.file-not-previewed")}</p></div>;
                break;
        }
    };

    const handlePreviewAnnexe = (event,hash) => {
        event.preventDefault();
        event.stopPropagation();
        const images = [];
        plugnoteAnnexes.map((annexeToPreview, index) => {
            switch (annexeToPreview.type.toLowerCase()) {
                case 'jpg':
                case 'JPG':
                case 'jpeg':
                case 'png':
                case 'PNG':
                case 'gif':
                    let url = getUrlPreviewAnnexe(annexeToPreview);
                    images.push(url);
                    if(hash === annexeToPreview.hash){
                        setSelectedPreviewAnnexe(images.length - 1);
                    }
                    break;
            }
        });

        setImages(images);
        setOpenModalPreview(true);
    };

    const handlePreviewPdfAnnexe = (event,url) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
        //setOpenPdfPreview(true);
        //setSelectedPreviewPdfAnnexe(url);
    };

    const _handleOpenModalListAnnexes = () => {
        setIsOpenModalListAnnexes(true);
    }

    const _handleCloseModalListAnnexes = () => {
        setIsOpenModalListAnnexes(false)
    }

    const handleChangeTableValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleChangeTableRowsValue = (value, rows, field, index) => {
        let listFields = fields;
        listFields[index].value.value = value;
        listFields[index].value.list = rows;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleUpdatePlugnote = () => {
        //props.history.push("/pluglist");
        if (dataHasChanged) {
            if (checkData(fields)) {
                if(selectedFiles !== null){
                    handleClickAlertWithCancel();
                }else{
                    handleClickAlertWithoutCancel();
                }
            }
            else{

                let dataForHash = buildUpdateAnnexe(fields);

                props.attemptUpdateData(
                    modelForHash.hash,
                    environment.userhash,
                    dataForHash, '/plugcodes');
            }
        }
        else{
            if (checkData(fields)) {
                if(selectedFiles !== null){
                    handleClickAlertWithCancel();
                }else{
                    handleClickAlertWithoutCancel();
                }
            }else{
                props.history.push("/plugcodes");
            }
        }

    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Plugforms
                mode = 'plugcode-consult-gallery'
                shared = {false}
                fields = {fields}
                visibleAll = {true}
                attemptGetDataUnique = {props.attemptGetDataUnique}
                handleEditValue = {handleEditValue}
                handleEditDateTime = {handleEditDateTime}
                plugnoteAnnexes = {plugnoteAnnexes}
                nameModel = {nameModel}
                plugnote = {plugnote}
                model={modelForHash}
                setFields={setFields}
                consult={true}
                handleChangeBoolean={handleChangeBoolean}
                handleChangePlugcode={handleChangePlugcode}
                handleChangePlugcodeInternal={handleChangePlugcodeInternal}
                handleChangeInputTable={handleChangeInputTable}
                handleChangeTableValue={handleChangeTableValue}
                handleChangeTableRowsValue={handleChangeTableRowsValue}
                handleChangeKeyword={handleChangeKeyword}
                handleChangePassword={handleChangePassword}
                handleEditPhoneValue={handleEditPhoneValue}
                handleChangeRadio={handleChangeRadio}
                //handleExitUpdatePlugnote = {handleExitUpdatePlugnote}
                //handleSaveUpdatePlugnote = {handleSaveUpdatePlugnote}
                isOpenModalListAnnexes = {isOpenModalListAnnexes}
                _handleOpenModalListAnnexes = {_handleOpenModalListAnnexes}
                _handleCloseModalListAnnexes = {_handleCloseModalListAnnexes}
                handleChangeLocalisation = {handleChangeLocalisation}
                //isAuthorisedToEdit = {isAuthorisedToEdit}
                downloadEmployeeData = {downloadEmployeeData}
                //handleUpdateAnnexe = {handleUpdateAnnexe}
                handleModalAnnexeOpen = {handleModalAnnexeOpen}
                handleNoteOpen = {handleNoteOpen}
                handleDeleteAnnexe = {handleDeleteAnnexe}
                //handleModalListAnnexesVersionOpen = {handleModalListAnnexesVersionOpen}
                handlePreviewAnnexe = {handlePreviewAnnexe}
                handlePreviewPdfAnnexe = {handlePreviewPdfAnnexe}
                onChangeHandlerDrop = {onChangeHandlerDrop}
                renderAnnexeIconPreview = {renderAnnexeIconPreview}
                renderAnnexeImagePreview = {renderAnnexeImagePreview}
                environment = {environment}
                //fullNamePlugnote = {fullNamePlugnote}
                disabled={dataForHash?.pncodeprivileges !== 'readedit'}
                isOpenModalAnnexe = {isOpenModalAnnexe}
                handleModalAnnexeClose = {handleModalAnnexeClose}
                handleExitPlugnote={handleExitPlugnote}
                handleUpdatePlugnote={handleUpdatePlugnote}
            />

            {
                openNote &&
                <ModalNoteComponent selectedNote={selectedNote} open={openNote}  close={handleNoteClose} handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote} disabled={true}/>
            }
            {
                openClassify &&
                <ModalCopyOrSavePlugcodeComponent open={openClassify} close={handleCloseClassify}/>
            }
        </div>
    );



    return (
        <div className={classes.root}>
            <CssBaseline />
            <Plugforms
                mode = 'plugcode-consult-gallery'
                shared = {false}
                fields = {fields}
                visibleAll = {true}
                attemptGetDataUnique = {props.attemptGetDataUnique}
                handleEditValue = {handleEditValue}
                handleEditDateTime = {handleEditDateTime}
                plugnoteAnnexes = {plugnoteAnnexes}
                nameModel = {nameModel}
                plugnote = {plugnote}
                model={modelForHash}
                setFields={setFields}
                consult={true}
                handleChangeBoolean={handleChangeBoolean}
                handleChangePlugcode={handleChangePlugcode}
                handleChangePlugcodeInternal={handleChangePlugcodeInternal}
                handleChangeInputTable={handleChangeInputTable}
                handleChangeTableValue={handleChangeTableValue}
                handleChangeTableRowsValue={handleChangeTableRowsValue}
                //handleUpdateInputTable={handleUpdateInputTable}
                handleChangeKeyword={handleChangeKeyword}
                //handleChangeRadioTable={handleChangeRadioTable}
                //handleChangePlugform={handleChangePlugform}
                handleChangePassword={handleChangePassword}
                handleEditPhoneValue={handleEditPhoneValue}
                handleChangeRadio={handleChangeRadio}
                //handleExitUpdatePlugnote = {handleExitUpdatePlugnote}
                //handleSaveUpdatePlugnote = {handleSaveUpdatePlugnote}
                isOpenModalListAnnexes = {isOpenModalListAnnexes}
                _handleOpenModalListAnnexes = {_handleOpenModalListAnnexes}
                _handleCloseModalListAnnexes = {_handleCloseModalListAnnexes}
                handleChangeLocalisation = {handleChangeLocalisation}
                //isAuthorisedToEdit = {isAuthorisedToEdit}
                downloadEmployeeData = {downloadEmployeeData}
                //handleUpdateAnnexe = {handleUpdateAnnexe}
                handleModalAnnexeOpen = {handleModalAnnexeOpen}
                handleNoteOpen = {handleNoteOpen}
                handleDeleteAnnexe = {handleDeleteAnnexe}
                //handleModalListAnnexesVersionOpen = {handleModalListAnnexesVersionOpen}
                handlePreviewAnnexe = {handlePreviewAnnexe}
                handlePreviewPdfAnnexe = {handlePreviewPdfAnnexe}
                onChangeHandlerDrop = {onChangeHandlerDrop}
                renderAnnexeIconPreview = {renderAnnexeIconPreview}
                renderAnnexeImagePreview = {renderAnnexeImagePreview}
                environment = {environment}
                //fullNamePlugnote = {fullNamePlugnote}
                disabled= {dataForHash?.pncodeprivileges !== 'readedit'}
                isOpenModalAnnexe = {isOpenModalAnnexe}
                handleModalAnnexeClose = {handleModalAnnexeClose}
                handleExitPlugnote={handleExitPlugnote}
                handleUpdatePlugnote={handleUpdatePlugnote}
            />

            {
                openNote &&
                <ModalNoteComponent selectedNote={selectedNote} open={openNote}  close={handleNoteClose} handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote} disabled={true}/>
            }
            {
                openClassify &&
                <ModalCopyOrSavePlugcodeComponent open={openClassify} close={handleCloseClassify}/>
            }
        </div>
    );

}

// redux providing state takeover
const mapStateToProps = (state) => {
    const plugnote = state.plugcode.plugnote
    const annexes = getAnnexes();

    if(!!state.annexe.list){
        if(state.annexe.list.length > 0){
            state.annexe.list.map((an, index) => {
                if(!annexes.find(element => element.hash === an.hash)){
                    annexes.push(an);
                    setAnnexes(annexes);
                }else if(annexes.find(element => element.hash === an.hash)){
                    let findIndex = annexes.findIndex(element => element.hash === an.hash);
                    annexes[findIndex].lastversion = an.lastversion;
                    annexes[findIndex].creationdate = an.creationdate;
                    annexes[findIndex].countVersion = an.countVersion;
                    setAnnexes(annexes);
                }
            })
        }
    }

    return {
        user : state.user,
        token : state.user.token,
        plugnote : plugnote,
        plugform : state.plugcode.plugform,
        fetching : state.data.fetching,
        progress : state.annexe.progress,
        annexes : annexes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptUpdateData: (modelHash, userHash ,formData,forward) => {
            return dispatch(putUpdateDataRequest(modelHash, userHash, formData,forward))
        },
        attemptPostAnnexes: (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => dispatch(postAnnexesRequest(modelhash,userhash, datahash,modelshared,newdata,formdata,formfile)),
        attemptDeleteAnnexe : (annexehash, userhash) => dispatch(delDeleteAnnexeRequest(annexehash, userhash)),
        attemptAddNoteAnnexe: (modelhash, datahash, userhash, name, note, newdata, formdata) => dispatch(postAddNoteRequest(modelhash, datahash, userhash, name, note, newdata,formdata)),
        attemptEditNoteAnnexe: (annexehash, notehash, name, note) => dispatch(putEditNoteRequest(annexehash, notehash, name, note)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlugConsultFromCodeGalleryV2);
