import React, {useEffect, useState} from "react"
import InputMask from 'react-input-mask';
import { makeStyles } from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import colors from "../../assets/themes/Colors";
import {postExtDataReset} from "../../actions/extdata";
import ModalListExtDataComponent from "../modals/extdata/ModalListExtDataComponent";
import {Search} from "@material-ui/icons";
import {convertersAlphaToNumber, currentDate, isDefined, urlify} from "../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt, faFilePdf} from "@fortawesome/free-regular-svg-icons";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import {HyperFormula} from "hyperformula";
import ModalListExtDataMultipleComponent from "../modals/extdata/ModalListExtDataMultipleComponent";
import {getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV, strReplaceContentField} from "../../utils/Fields";
import {getCurrentEnvironnement, getPlugnote, getToken,} from "../../utils/Common";
import {confirmAlert} from "react-confirm-alert";
import Dialog from "@material-ui/core/Dialog";
import {Content, Header} from "rsuite";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Data9002PrintableGetURL} from "../printable/Data9002Printable";
import {postSendMailV3Request} from "../../actions/utils";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {
    deleteShareDataWithEmailUser,
    getShareDataWithEmailUser,
    postShareDataWithEmailUser,
    putShareDataWithEmailUser
} from "../../api/shareApi";
import {Tooltip} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { ColorCheckbox, DurationInput, PlugTooltip, RestyledNumericInput } from "../custom/CustomComponents";
import { RenderItemMultipleSelectComponent, RenderItemSingleSelectComponent } from "../custom/FormatTable";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import Data2001Printable from "../printable/Data2001Printable";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
	mainCtn: {
		width: '100%',
		marginTop: -30,
		overflowY: 'visible',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    mainPhoneContainer:{
        marginTop : '20px',
    },
    phoneContainer:{
        border: '1px black solid',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    mailButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.mainButton,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    unregisteredBtn: {
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.bloodOrange,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    inputArea: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&::-youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },

    },
    tableRoot: {
        // width: "99%", //it was 100%
        marginTop: 8,
        overflowX: "auto",
        //marginRight: 'auto',
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        // padding: '5px',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
            width: '5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none'
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
    },
    rowNum: {
        color: colors.grey,
        //fontWeight: 'bold',
    },
    tableHeader: {
        padding: 15,
    },
    tableMultButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        // fontSize: 22,
    },
    tableMultAddButton: {
        // width : 20,
        // height : 20,
        display : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableMultExportButton: {
        width : 20,
        height : 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerSeparator: {
        borderColor: colors.greyLight,
        borderStyle: 'solid',
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    stickyCol:{
        position: "sticky",
        left: '30px',
        background: colors.white,
        zIndex: 100,
        width :'100%',
        wordBreak: 'break-all',
        borderBottom: 'none'
    },
    stickyColRight:{
        position: "sticky",
        left: 0,
        textAlignLast: 'center',
        zIndex: 50,
        borderBottom: 'none'
    },
    paginationSpacer: {
        flex: 'none',
    },
    tableMultPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tableMultPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        height: 24,
        fontSize: 15,
        alignSelf: 'center',
        padding: '0 5px',
        borderRadius: '6px'
    },
    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'space-between',
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderBlankContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        // background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderSingleFormulaContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    fontChange: {
        fontSize: 12,
        paddingLeft: '5px!important',
    },
    fontChangeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formatInputFieldComposed: {
        marginLeft: '29%',
        borderRadius: "4px",
        paddingRight: '8px',
        height: 41,
        // padding: "5px 0",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    formatInputFieldLockedComposed: {
        backgroundColor: colors.white,
        marginLeft: '29%',
        paddingRight: '7px',
        borderRadius: "4px",
        height: 41,
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    upliftedContainer: {
        marginTop: '-37px',
        overflowY : 'visible'
    },
    searchField: {
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: '0px 10px',
        margin: '0px 10px'
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 70,
    },
    textFont: {
        fontSize: 12,
        // fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 0,
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        padding: '0px 2px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        minHeight: 33,
        order: 2,
        width : '60%'
    },
    fieldValues: {
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        paddingLeft: 5,
    },
    attItemsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginLeft : '10px'
    },
    attItem: {
        display: "flex",
        alignItems: "center",
        gap: 5,
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    header: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalBody: {
        paddingTop: 50,
        position: 'relative',
        paddingBottom: 50
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    RadioGroupModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        // paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    showOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 5
    },
    radioIconModal: {
        color: colors.mainButton,
        backgroundColor: colors.mainButton
    },
    radioLabelModal: {
        fontSize: 14,
        cursor: 'pointer'
    },
    '&input[name="show-empty-option"]': {
        backgroundColor: 'blue'
    },
    pwcodeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
        gap: 10,
    },
    modalBtnContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: "space-between",
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30,
        display: 'flex',
        flexDirection: 'row',
    },
    valuesCell: {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    totalValues: {
        color: colors.bloodOrange,
        padding: 0,
        paddingLeft: 5,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
    },
    //New design
    summaryCtn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 5
    },
    summary: {
        paddingRight: 4
    },
    rowCtn: {
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        zIndex: 200,
        display: 'flex',
        width: '70%',
        flexDirection: 'row',
    },
    topBtnCtn: {
        right: 0,
        gap: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    topBtn: {
        width: 60,
        height: 30,
        padding: 5,
        borderRadius: 5,
        boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.mainButton,
        color: 'white',
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
			borderColor: colors.mainButton,
		},
	},
    disabledBtn: {
        cursor: 'not-allowed',
        backgroundColor: colors.iconGrey,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.iconGrey,
        },
    },
    openRow: {
        backgroundColor: colors.greyLighter
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: `1px solid ${colors.greyLight}`
    },
    warnToast: {
        backgroundColor: colors.unselectedMain,
    },
}));

const ConfigModal = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const [email] = useState(props.value);
    const [phone, setPhone] = useState("");
    const [rules, setRules] = useState(-1);
    const [shareList, setShareList] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [hasChanged, setHasChanged] = useState(false)
    const [external, setExternal] = useState(props?.external || false)

    const handleChangeRules = (event) => {
        setRules(event)
        setHasChanged(true)
    }

    const handleSubmitShare = () => {
        if (getPlugnote() !== null){
            let tmpRules = Number(rules.toString());

            if ((email.length > 0 && tmpRules !== -1 && !external) || (email.length > 0 && tmpRules !== -1 && isValidPhoneNumber(phone) && external)){
                let payload =  {
                    read_fiche: "1",
                    edit_fiche: tmpRules >= 1 ? "1" : "0",
                    active: "1",
                    corporate: "0",
                    datahash: getPlugnote().hash,
                    email : props.value,
                    phone : isValidPhoneNumber(phone) && phone.length > 0 ? phone : ""
                };

                trackPromise(
                    postShareDataWithEmailUser(payload).then((response) => {
                        if (response.data.result){
                            setShareList(response.data.data);
                            let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                            switch (code.toString()) {
                                case "10":
                                    setRules(0);
                                    break;
                                case "11":
                                    setRules(1);
                                    break;
                                default:
                                    break;
                            }
                            setEditMode(true);
                            props.setPwCode(response.data.data.code);
                            props.setPwField(response.data.data.code + ' ' + t('smailvalidmulti.unsent'))
                        }else if (!response.data.result){
                            confirmAlert({
                                title: t('global.plugnotes'),
                                message: t('common:global.user-not-exist'),
                                buttons: [
                                    {
                                        label: t('global.ok'),
                                    },
                                ]
                            });
                        }
                    }).catch((response) => {
                        alert('error');
                    })
                );
            }
        }

    };

    const handleUpdateShare = (share) => {
        let tmpRules = Number(rules.toString());

        if ((email.length > 0 && tmpRules !== -1 && !external) || (email.length > 0 && tmpRules !== -1 && isValidPhoneNumber(phone) && external)){
            let payload  ={
                read_fiche: "1",
                edit_fiche: tmpRules >= 1 ? "1" : "0",
                active: "1",
                corporate: "0",
                datahash: props.plugnote.hash,
                email : props.value,
                hash : share.hash,
                phone : isValidPhoneNumber(phone) && phone.length > 0 ? phone : ""
            };

            trackPromise(
                putShareDataWithEmailUser(payload).then((response) => {
                    if (response.data.result){
                        setShareList(response.data.data);
                        let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                        switch (code.toString()) {
                            case "10":
                                setRules(0);
                                break;
                            case "11":
                                setRules(1);
                                break;
                            default:
                                break;
                        }
                        if (response.data.data.phone !== null){
                            setPhone(response.data.data.phone)
                        }
                        setEditMode(true);
                        props.setPwCode(response.data.data.code);
                    }
                }).catch((response) => {
                    alert('error');
                })
            );
        }
        setHasChanged(false)
    }

    const handleCancelShare = (share) => {

        confirmAlert({
            title: t('global.confirm'),
            message: t('share.model-share-confirm-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        trackPromise(
                            deleteShareDataWithEmailUser(share.hash).then((response) => {
                                if (response.data.result){
                                    props.close();
                                }
                            }).catch((response) => {
                                alert('error');
                            })
                        );
                    }
                },
                { label: t('global.cancel')},

            ]
        });
    };

    const handleOpenMailModal = () => {
        props.setPwField(shareList.code)
        props.openMail()
    }

    useEffect(() => {
        if (props?.plugnote?.hash && props.value !== ""){
            trackPromise(
                getShareDataWithEmailUser(props.plugnote.hash,props.value).then((response) => {
                    if (response.data.result){
                        setShareList(response.data.data);
                        let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                        switch (code.toString()) {
                            case "10":
                                setRules(0);
                                break;
                            case "11":
                                setRules(1);
                                break;
                            default:
                                break;
                        }
                        if (response.data.data.phone !== null){
                            setPhone(response.data.data.phone)
                        }
                        setEditMode(true);
                        props.setPwCode(response.data.data.code);
                    }
                }).catch((response) => {
                    console.error(
                        "Could not Download the Excel report from the backend.",
                        response
                    );
                })
            )
        }
    },[])
    // console.log("share pw", shareList);

    return(
        <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth="md">
            <Header>
            </Header>
            <div className={classes.mSeparatorWrapper}/>
            <Content>
                <div>
                    <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                        <div className="popup_wrapper_content_small">
                            <div className="sous-header_content">
                                <div>
                                    <span className="titres">
                                        { t('common:smailvalidmulti.config-title') }{email} <br/>
                                    </span>
                                </div>
                                <div>
                                    <span id="icon-close" className="material-icons md-50 lightgrey" onClick={props.close}>
                                        close
                                    </span>
                                </div>
                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <br/>
                            <div className="flexSpacebetween">
                                <div className=" split-40">
                                    <span className="grey">{t('plugcode.rules')}</span>
                                    <div className={classes.RadioGroupModal} name={t('common:plugcode.rules')} value={rules}>
                                        <div className={classes.showOption} onClick={() => handleChangeRules(0)}>
                                            <input type='radio' size='small' value={0} checked={rules === 0} className={classes.radioIconModal} onChange={(e) => null}/>
                                            <span className={classes.radioLabelModal}>
                                                    { t('common:plugcode.rule-read') }
                                                <span> (</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        visibility
                                                    </span>
                                                    <span>) </span>
                                                </span>
                                        </div>
                                        <div className={classes.showOption} onClick={() => handleChangeRules(1)}>
                                            <input type='radio' size='small' name={"show-empty-option"} value={1} checked={rules === 1} className={classes.radioIconModal} onChange={(e) => null}/>
                                            <span className={classes.radioLabelModal}>
                                                    { t('common:plugcode.rule-read-edit') }
                                                <span> (</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        visibility
                                                    </span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        create
                                                    </span>
                                                    <span>) </span>
                                                </span>
                                        </div>
                                    </div>
                                    { external &&
                                        <div className={classes.mainPhoneContainer}>
                                            <span className="grey">{ t('common:plugcode.phones-access') }</span>
                                            <div className={classes.phoneContainer}>
                                                <PhoneInput
                                                    className={`${classes.phoneComponent} phoneComponent phoneSmailComponent`}
                                                    defaultCountry="BE"
                                                    international
                                                    value={phone}
                                                    placeholder={t('fields.format-choice-placeholder-phone')}
                                                    onClick={(e) => e.target.placeholder='' }
                                                    onBlur={(e) => e.target.placeholder=t('fields.format-choice-placeholder-phone')}
                                                    onChange={(value) => {
                                                        setPhone(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                {editMode === false ?
                                    <div className="split-60">
                                        <div className="confirmation_pop-up" onClick={handleSubmitShare}>
                                            {
                                                external ?
                                                    <button className={rules === -1 || !isValidPhoneNumber(phone) || phone?.length === 0 ? "grey-button" : "button"}>{ t('common:global.create') } PW- code</button>
                                                    :
                                                    <button className={rules === -1 ? "grey-button" : "button"}>{ t('common:global.create') } PW- code</button>
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div className="split-60">
                                        {/* <div>
                                        <span className="grey">{ t('common:smail.share-pw-with') } :</span>
                                    </div> */}
                                        <span className="grey">{t('smailvalidmulti.current-link')}</span>

                                        {shareList !== null &&
                                            <div className={`grey ${classes.pwcodeContainer}`}>
                                                {/* <span className={"info-maximum-width "}>{shareList.email}</span> */}
                                                <span className="grey">{shareList.code}</span>
                                                <div>
                                                    <span>(</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                    visibility
                                                    </span>
                                                    {shareList.editFiche === 1 &&
                                                        <span id="icon-create" className="material-icons md-small green">
                                                        create
                                                    </span>}
                                                    <span>)</span>
                                                </div>
                                                <span id="icon-create" className="material-icons md-small red" onClick={() => handleCancelShare(shareList)}>
                                                    delete
                                                </span>
                                            </div>
                                        }

                                    </div>}
                            </div>

                        </div>

                        <div className="popup_wrapper_content">
                            {editMode === false ?
                                null
                                :
                                <div className={classes.modalBtnContainer}>

                                    <div className="confirmation_pop-up">
                                        <button className="button-exit" onClick={() => handleUpdateShare(shareList)} style={hasChanged ? null : {display: 'none'}}>{ t('common:smailvalidmulti.update-rules') }</button>
                                    </div>

                                    <div className="confirmation_pop-up" style={{float: 'right'}}>
                                        <button className="button" onClick={handleOpenMailModal}>{ t('common:annexe.send-email') }</button>
                                    </div>
                                </div>
                            }

                        </div>

                        {/* <div className="popup_wrapper_content">
                            {shareList !== null &&
                            <div className="confirmation_pop-up" onClick={() => handleCancelShare(shareList)}>
                                <button className="button-text-red">{ t('common:global.delete') } PW- code</button>
                            </div>
                            }
                        </div> */}
                    </div>
                </div>
            </Content>
        </Dialog>
    );
}

const SmailModal = (props) => {
    // console.log('SmailModal props', props);
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);

    let content = "";
    if (props?.field?.options?.body){
        content = strReplaceContentField(props?.field?.options?.body, props?.fields, props?.model, props?.plugnote, props?.user,props?.pwCode);
    }
    const [body, setBody] = useState(content);
    const [sujet, setSujet] = useState(props?.field?.options?.title || "");
    const [expediteur, setExpediteur] = useState(props?.field?.options?.from || "noreply@plugnotes.com");
    const [viewNotes, setViewNotes] = useState(props?.field?.options?.note ? props?.field?.options?.note === 'Y' : false);
    const [mail, setMail] = useState(props.value || "");
    const [mailCc, setMailCc] = useState(props?.field?.options?.cc || "");
    const [mailCci, setMailCci] = useState(props?.field?.options?.cci || "");
    const [attachments, setAttachments] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [extraAttachments, setExtraAttachments] = useState([]);
    const [printRelated, setPrintRelated] = useState(false);
    const [hasRelated, setHasRelated] = useState(false);

    const environment = getCurrentEnvironnement();

    let nowTime = new Date()
    let pwField = props.pwCode
    if (props.pwCode !== '') pwField = pwField + ' (' + nowTime.toLocaleString([], {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) + ')'

    const handlePressValidate = () => {
        if (mail.length > 0){
            let message = t('global.send');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let bodyReplace = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            let bodyUrlReplace = urlify(bodyReplace);
                            let payload = {};
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            payload.body = bodyUrlReplace.length > 0 ? bodyUrlReplace : null;
                            payload.from = expediteur.length > 0 ? expediteur : null;
                            payload.cc = mailCc.length > 0 ? mailCc : null;
                            payload.cci = mailCci.length > 0 ? mailCci : null;
                            payload.to = mail.length > 0 ? mail : null;
                            payload.attachments = attachments.length > 0 ? attachments : null;
                            payload.attachment = attachment !== "" ? attachment : null;
                            payload.extraAttachments = extraAttachments.length > 0 ? extraAttachments : null;
                            props.handleCloseWhenSendModalSmail();
                            props.attemptSendMailSimple(payload,environment?.userhash);
                            if (props.pwCode !== '') props.setPwField(pwField)
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    const handleTogglePlugnoteAttachment = (annexe) => {
        if (!attachments.includes(annexe.hash)) {
            setAttachments([...attachments, annexe.hash]);
        } else {
            let tmp = attachments.filter(item => item !== annexe.hash);
            setAttachments(tmp);
        }
    }

    const handleToggleAttachments = () => {
        if (attachment === null) {
            setAttachment(props.emailAttachments);
        } else {
            setAttachment(null);
        }
    }

    const handleToggleRelated = () => {
        setPrintRelated(previous => {
            if (previous) { setExtraAttachments([]) }
            return !previous })
    }

    const handleAddRelatedPlugnote = (blob) => {
        // console.log('got extra blob', blob);
        if (blob) {
            let reader = new FileReader();
            blob.then(result => {
                try {
                    reader.readAsDataURL(result);
                    reader.onloadend = function () {
                        let base64data = reader.result;
                        let _attachments = [...extraAttachments, base64data]
                        setExtraAttachments(_attachments)
                    }
                } catch (e) {
                    console.error(`❌ onLoadBlob ${e}`);
                }
            })
        }
    }

    return(
        <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth="md">
            <div className={classes.modalBody}>

                <div className={classes.header}>
                    <div className="popup_wrapper_content justify_content_space">
                        <button
                            className="button-exit"
                            onClick={props.close}
                        >
                            {t("common:global.cancel")}
                        </button>
                        <button className="button float_right" onClick={handlePressValidate}>{ t('common:global.send') }</button>
                    </div>
                </div>

                <div className="popup_wrapper_content">
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel  style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{t('common:mails.destinatairefrom')}</InputLabel>
                        <div className={classes.inputContainer}>
                            <Input
                                id={'from-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={expediteur}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel  style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.destinataire') }</InputLabel>

                        <div className={classes.inputContainer}>
                            <Input
                                id={'to-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={mail}
                                disabled={false}
                                onChange={(event) => setMail(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.destinatairecc') }</InputLabel>
                        <div className={classes.inputContainer}>
                            <Input
                                id={'cc-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={mailCc}
                                disabled={false}
                                onChange={(event) => setMailCc(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.destinatairecci') }</InputLabel>
                        <div className={classes.inputContainer}>
                            <Input
                                id={'cci-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={mailCci}
                                disabled={false}
                                onChange={(event) => setMailCci(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.sujet') }</InputLabel>
                        <div className={classes.inputContainer}>
                            <Input
                                id={'subject-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                onChange={(event) => setSujet(event.target.value)}
                                value={sujet}
                            />
                        </div>
                    </div>

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.message') }</InputLabel>
                        <span style={{height : '25px', fontSize: 10, fontStyle: 'italic', fontWeight: 'bold', color: colors.red}}>
                        {body.includes('<sup>') && t('common:smail.info-tags')}
                    </span>
                    </div>

                    <TextField
                        id="outlined-multiline-static"
                        multiline={true}
                        style={{ fontSize: 11 }}
                        minRows={10}
                        value={body}
                        margin="none"
                        fullWidth={true}
                        inputlabelprops={{className: classes.inputArea}}
                        InputProps={{disableUnderline: true}}
                        className={classes.textFieldArea}
                        onChange={(event) => setBody(event.target.value)}
                    />
                </div>
                {
                    (props.field.template !== '' || props.annexes.length > 0) &&
                    <div className="popup_wrapper_content">
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{ fontWeight: 'bold' }} shrink>{t('common:mails.attachments')}</InputLabel>
                            <span style={{ height: '25px' }} />
                        </div>
                        {
                            (props.field.template !== '' || props.annexes.length > 0) &&
                            <div className={classes.attachmentsCtn}>
                                {props.annexes.length > 0 &&
                                    props.annexes.map((annexe, index) => {
                                        if ((annexe.type === 'note' && viewNotes) || annexe.type !== 'note') {
                                            return (
                                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} key={'annexes-' + props.field.index + '-' + index}>
                                                    <div className={classes.attItemsContainer} onClick={() => handleTogglePlugnoteAttachment(annexe)}>
                                                        <div className={classes.attItem}>
                                                            <ColorCheckbox checked={attachments.includes(annexe.hash)} color={colors.main} size={24}/>
                                                            <div className={classes.attIconType}>
                                                                {annexe.type === 'note' && viewNotes && props.renderAnnexeIconPreview(annexe)}
                                                                {annexe.type !== 'note' && props.renderAnnexeIconPreview(annexe)}
                                                            </div>

                                                            <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                                {annexe.type === 'note' && viewNotes &&
                                                                    <span>{annexe.name}</span>
                                                                }

                                                                {annexe.type !== 'note' &&
                                                                    <span>{annexe.name}.{annexe.type}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else return null;
                                    })
                                }

                                {
                                    props.field.options.template === '9002' && props.emailAttachments !== null &&
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} key={'annexes-' + props.field.index + '-' + (props.annexes.length + 1)}>
                                        <Checkbox
                                            className={classes.multipleCheckBox + ' ' + classes.switchBase}
                                            name={t('common:smail.template-9002-content')}
                                            checked={attachment?.length > 0}
                                            onChange={handleToggleAttachments}
                                        />
                                        <div className={classes.attItemsContainer} onClick={handleToggleAttachments}>
                                            <div className={classes.attItem}>
                                                <div className={classes.attIconType}>
                                                    <FontAwesomeIcon
                                                        icon={faFilePdf}
                                                        className={classes.faIconSize}
                                                    />
                                                </div>

                                                <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                    <span>{t('common:smail.template-9002-content')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    props.field.options.template === '2001' &&
                                    <div className={classes.attItemsContainer} onClick={handleToggleAttachments}>
                                        <div className={classes.attItem}>
                                            <Data2001Printable
                                                {...props}
                                                addRelatedPlugnote={handleAddRelatedPlugnote}
                                                checked={attachment !== null}
                                                isEmailAttachment={true}
                                                printRelated={printRelated}
                                                setHasRelated={setHasRelated}
                                                setPdfBlob={props.setPdfBlob}
                                                t={t}
                                            />
                                            <div className={classes.attIconType}>
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                    className={classes.faIconSize}
                                                />
                                            </div>

                                            <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                <span>{t('common:smail.template-9002-content')}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    attachment !== null && hasRelated &&
                                    <div className={classes.attItemsContainer} onClick={handleToggleRelated}>
                                        <div className={classes.attItem}>
                                            <ColorCheckbox
                                                checked={printRelated}
                                            />
                                            <div className={classes.attIconType}>
                                                <FontAwesomeIcon
                                                    icon={faFileAlt}
                                                    className={classes.faIconSize}
                                                />
                                            </div>
                                            <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                <span>{t('common:print.related-plugnote')}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                <div className={classes.modalFooter}>
                    <div className="popup_wrapper_content justify_content_space">
                        <button
                            className="button-exit"
                            onClick={props.close}
                        >
                            {t("common:global.cancel")}
                        </button>
                        <button className="button float_right" onClick={handlePressValidate}>{ t('common:global.send') }</button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

const restylizeNumber = (number) => {
    if ((typeof number === "number" || typeof number === 'string') && number !== ''){
        let nombre = 0
        let coma = false
        if (typeof number === "number") nombre = number;
        else if (number.charAt(number.length -1) === ',') {
            nombre = parseFloat(number.slice(0,-1))
            coma = true
        }
        else nombre = parseFloat(number.replace(',','.'))
        let restyled = ''
        let ent = 0
        ent = Math.floor(nombre);
        let stInt = ''
        let decim = number.toString().split('.')[1]
        if (!number.toString().includes('.')) decim = number.toString().split(',')[1]
        if (nombre > 999) {
            ent += ''
            let rank = 1
            for (let index = ent.length - 1; index >= 0 ; index--) {
                const nb = ent.charAt(index);
                if (rank % 3 === 0 && index >= 1 && rank !== 0) stInt = '.' + nb + stInt;
                else stInt = nb + stInt.toString()
                rank ++;
            }
        }
        else stInt = ent.toString()
        if (decim !== undefined) restyled = stInt + ',' + decim;
        else restyled = stInt
        return restyled.concat(coma ? ',' : '')
    }
    return ''
}

const RenderItemByTableCell =  (props) => {
    let classes = useStyles();
    let fields = Object.values(props.field.options.lines)
    return (
        <div key={props.index} className={classes.fieldValues}>
            {Object.values(props.row).length > 0 ?
                Object.keys(props.row)
                    .sort((a, b) => {if (a === '1000') return 1; if (b === '1000') return -1; else return a.localeCompare(b)})
                    .map((id, index) => {
                        let value = props.row[id]
                        if (value !== null) {
                            let fieldId = -1
                            let i = 0
                            while (fieldId === -1 && i < fields.length) {
                                if (fields[i].id == id) fieldId = i + 1;
                                i++
                            }
                            let _value = ''
                            if (value !== undefined) {
                                if (typeof value === 'string' || typeof value === 'number') _value = props.row[id];
                                else if (value.length > 0) {
                                    _value= value.join(', ')
                                }
                            }
                            if (_value !== ""){
                                if (fieldId !== 1) {
                                    return (
                                        <span className={classes.fontChange} key={'span-' + fieldId}>
                             | <span className={classes.fontFieldNumber}>{fieldId}</span> {_value}
                        </span>)
                                }
                                else {
                                    return (
                                        <span className={classes.fontChange} key={'span-' + fieldId}>
                            <span className={classes.fontFieldNumber}>{fieldId}</span>
                                            {_value}
                        </span>)}
                            }}
                        else return null;
                    })
                : null}
        </div>)
}

const RenderItemByTableCellInLine =  (props) => {
    const { t, } = useTranslation(['common']);
    let template;
    let classes = useStyles();
    let tmpValue = "";

    if (typeof props.value !== "undefined" && props.col.format !== 'numeric' && props.value.length > 0){
        tmpValue = props.value;
    }
    else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric'){
        tmpValue = restylizeNumber(props.value);
    }

    const [value, setValue] = useState(tmpValue);
    const [duration, setDuration] = useState(value !== '' && value !== undefined && value !== null && props.col.format === 'duration' ? props?.col?.regex === '99:99'? [value.split(':')[0] || '', value.split(':')[1]] || '' : [value.split(':')[0] || '', value.split(':')[1] || '', value.split(':')[2] || ''] : ['', '',''])
    const [accept, setAccept] = useState(false)
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [ref, setRef] = useState(props?.col?.configuration?.ref || "");
    const [query, setQuery] = useState(props?.col?.configuration?.query || "");
    const [column, setColumn] = useState(props?.col?.configuration?.column || "");
    const [columns, setColumns] = useState(props?.col?.configuration?.columns || "");
    const [options, setOptions] = useState(props?.col?.configuration || {ref : "", query : "LIKE", columns : "", column : "", dest : ""});
    const [dest, setDest] = useState(props?.col?.configuration?.dest || "");
    const [hideSearch, setHideSearch] = useState(props?.model?.authoriseExternalDbInPf === undefined ? false  : !props?.model?.authoriseExternalDbInPf);
    const [formula, setFormula] = useState(props?.col?.configuration?.formula || null);
    const [ownerId, setOwnerId] = useState(props.ownerId);
    const [contentTooltip, setContentTooltip] = useState('');
    const [open, setOpen] = useState(false);
    const [openSelect, setOpenSelect] = useState(false)
    const [loading, setLoading] = useState(false);
    const [optionFormule, setOptionFormule] = useState({licenseKey: 'gpl-v3'});
    const [pwCode, setPwCode] = useState('');
    const [pwField, setPwField] = useState(props.row[1000] || '')
    const [registered, setRegistered] = useState(pwField !== '')
    const [openConfigurator, setOpenConfigurator] = useState(false);
    const [openExternalConfigurator, setOpenExternalConfigurator] = useState(false);
    const [openMail, setOpenMail] = useState(false);
    const [emailAttachments, setEmailAttachments] = useState(null);
    const [templateOn, setTemplateOn] = useState(props.field?.options?.template || "");
    const [openPromised, setOpenPromised] = useState(false);
    const { promiseInProgress } = usePromiseTracker();
    const [pdfBlob, setPdfBlob] = useState(null);

    let unregisteredMsg = t('smailvalidmulti.unregistered')
    let unsentMsg = t('smailvalidmulti.unsent')
    let emptyMsg = t('smailvalidmulti.empty-mail')

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpen(true);
    }

    const handleChangeTableValue = (value, event) => {
        // solution pour le curseur qui saute à la fin de l'input ('tel' & 'text' uniquement)
        if (event !== undefined) {
            const caret = event.target.selectionStart
            const element = event.target
            window.requestAnimationFrame(() => {
                element.selectionStart = caret
                element.selectionEnd = caret
            })
            element.focus();
        }
        props.handleChangeChildrenValue(value, props.col, props.row, props.rindex);
    }

    const checkRegistered = (email) => {
        let url = ApplicationConfig.apiUrl + 'utils/check/user/exist.json?email=' + email
        // console.log('check url', url);
        if (email === 'tech2@plugnotes.com' || email === 'camille@plugnotes.com' || email === 'camille-db@hotmail.com' || email === 'vdb@plugnotes.com') setRegistered(true);
        else
            trackPromise(
                axios
                    .get(url,
                        {'headers': {
                                // 'X-AUTH-TOKEN': 'rKDvZfk/vfZaGN4riX/b1PSk9JuQPBHwCyqJFk/exRs/WRKOn5ta0gc45LGkRiqepcU='},
                                'X-AUTH-TOKEN': getToken()},
                        }
                    )
                    .then((response) => {
                        if (response) {
                            // setResponse(response)
                            // console.log('check registration', response.data.result);
                            setRegistered(response.data.result)
                        }
                    })
                    .catch((response) => {
                        console.error(
                            "Could not check registration from the backend.",
                            response
                        );
                    })
            )
    }

    useEffect(() => {
        if (props.value !== undefined) setValue(props.value);
        else setValue('')
    }, [props.value])

    useEffect(() => {
        if (openPromised){
            if (!promiseInProgress && getPlugnote() !== null){
                switch (props.mode) {
                    case 'create':
                    case 'update':
                    case 'shared-create':
                    case 'shared-update':
                    case 'modal-shared-update':
                        try {
                            handleOpenModalConfigurator();
                        }
                        catch (e) {
                            //console.log('error',e);
                        }
                        break;
                    default:
                        break;
                }
                setOpenPromised(false);
            }
            else {
                setOpenPromised(true);
            }
        }
    }, [promiseInProgress, openPromised, getPlugnote()])

    const handleOpenModalConfigurator = () => {
        setOpenConfigurator(true);
    }

    const handleSelectData = (object) => {
        setOpen(false);
        let jsonObjectToArray = Object.values(object)
        let destinations = [];
        let champs = dest.split('-');

        champs.map((elm, index) => {
            let field = elm.split(':');
            if (field.length > 0 && field.length <= 3){
                let dest = field[0];
                let col = field[1];
                let separator = typeof field[2] !== 'undefined' ?  field[2] : null;
                let copyValue = col?.split(';') || col;
                let value = "";

                let row = Object.values(props.row);
                let keys = Object.keys(props.row);

                if (typeof row[(dest - 1)] !== "undefined"){
                    copyValue.map((item, index) => {
                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                        let data = jsonObjectToArray[alphaNumIndexDest];
                        if (typeof data !== "undefined"){
                            let temp = data;
                            if (temp !== "" && typeof temp !== "undefined"){
                                if (temp === null){
                                    temp = "";
                                }
                                if (temp !== ""){
                                    if (item === item.toUpperCase()) {
                                        value = value === "" ? value.concat(temp) : value.concat(' ~ \n'+ temp);
                                    }else{
                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                    }
                                }
                            }
                        }
                    });

                    destinations.push({
                        "dest" : keys[(dest - 1)],
                        "value" : value
                    })
                }
            }
        });

        if (destinations.length > 0){
            let value = {...props.row};
            destinations.map((destination, index) => {
                let obj =  props.colonnes.find((item) => {
                    if ((Number(destination.dest) === item.id) && item.format == 'numeric'){
                        return item
                    }
                } )

                if (obj){
                    value[destination.dest] = restylizeNumber(destination.value);
                }else{
                    value[destination.dest] = destination.value;
                }
            })
            props.handleChangeChildrenRowValue(value,props.col, props.row, props.rindex);
        }
    }

    const handleCalculateValue = async () => {
        setLoading((prevLoading) => !prevLoading);
        let tmp = [];

        props.colonnes.map((elm, index) => {
            tmp.push([props.row[elm.id]]);
        });

        if (formula !== null) {
            let data = tmp;

            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };
            let sheetAData = data;
            let newFormula =  formula;
            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);


            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (mySum.type !== 'ERROR' && mySum.type !== 'REF') {
                    handleChangeTableValue(mySum);
                }
            } catch (e) {
                handleChangeTableValue("");
            }

            hfInstance.destroy();

        }

        setLoading((prevLoading) => !prevLoading);
    }

    const handleOpenModalSmail = () => {
        if (templateOn !== "" && templateOn == '9002'){
            generateEmailAttachmentsFromTemplate9002().then((result) => {
                let reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function() {
                    let base64data = reader.result;
                    setEmailAttachments(base64data);
                    setOpenMail(true);
                }
            });
        } else if (templateOn !== "" && templateOn == '2001') {
            generateEmailAttachmentsFromTemplate2001()
            // .then((result) => {
            //     let reader = new FileReader();
            //     reader.readAsDataURL(result);
            //     reader.onloadend = function() {
            //         let base64data = reader.result;
            //         setemailAttachments(base64data);
            //         setOpenMail(true);
            //     }
            // });
        }
        else{
            setEmailAttachments(null);
            setOpenMail(true);
        }
    }

    const controlBeforeOpen = () => {
        checkRegistered(value)
        setOpenMail(false);
        setOpenConfigurator(false);
        setOpenExternalConfigurator(false);

        if (props.mode !== "modal-shared-create"){
            if (props.share && registered){
                if (typeof props.control !== "undefined" && props.control){
                    switch (props.mode) {
                        case 'create':
                        case 'shared-create':
                            setOpenPromised(true);
                            if (getPlugnote() === null){
                                props.controlCreate();
                            }
                            else {
                                props.controlUpdate();
                            }
                            break;
                        case 'update':
                        case 'shared-update':
                        case 'modal-shared-update':
                            setOpenPromised(true);
                            props.controlUpdate();
                            break;
                        default:
                            break;
                    }
                }
                else {
                    setOpenMail(true);
                }
            }
            else if (props.share && !registered){
                setOpenExternalConfigurator(true)
            }
            else {
                handleOpenModalSmail();
            }
        }
        else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:global.send-before'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    const getInformations = () => {
        if (getPlugnote() !== null && registered){
            if (props?.plugnote?.hash && props.value !== ""){
                trackPromise(
                    getShareDataWithEmailUser(getPlugnote().hash,props.value).then((response) => {
                        if (response.data.result){
                            let rules =  null;
                            let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                            switch (code.toString()) {
                                case "10":
                                    rules = 0;
                                    break;
                                case "11":
                                    rules = 1;
                                    break;
                                default:
                                    break;
                            }

                            setContentTooltip(
                                <div>
                                    <p><span className="bold">{t('common:smail.share-to')} : <span className="normal">{props.value}</span></span></p>
                                    <p><span className="bold">{t('common:smail.share-code')} : <span className="normal">{response.data.data.code}</span></span></p>
                                    {
                                        rules !== null && rules === 0 &&
                                        <p><span className="bold">{t('common:smail.share-rules')} : <span className="normal">{t('common:plugcode.rule-read')}</span></span></p>
                                    }
                                    {
                                        rules !== null && rules === 1 &&
                                        <p><span className="bold">{t('common:smail.share-rules')} : <span className="normal">{t('common:plugcode.rule-read-edit')}</span></span></p>
                                    }
                                    <p><span className="bold">{t('common:smail.share-count')} : <span className="normal">{response.data.data.countopen.toString()}</span></span></p>

                                </div>
                            );
                        }
                        else{
                            setContentTooltip(
                                <div>
                                    <p><span>{t('common:smail.share-no-to')} <span>{props.value}</span></span></p>
                                </div>
                            );
                        }
                    }).catch((response) => {
                        setContentTooltip(
                            <div>
                                <p><span>{t('common:smail.share-no-to')} <span>{props.value}</span></span></p>
                            </div>
                        );
                    })
                )
            }
        }
    }

    const handleCloseModalSmail = () => {
        setOpenMail(false);
    }

    const handleCloseWhenSendModalSmail = () => {
        if (openConfigurator){
            setOpenConfigurator(false);
        }
        setOpenMail(false);
    }

    const generateEmailAttachmentsFromTemplate9002 = async () => {
        return Data9002PrintableGetURL(props);
    }

    const generateEmailAttachmentsFromTemplate2001 = async () => {
        setOpenMail(true)
    }

    const monthLength = (month, bissextile) => {
        if (month === 2) {
            if (bissextile === true || bissextile === undefined) return 29;
            else return 28
        }
        if (month %2 === 0 && month < 7) return 30
        if (month > 8 && month % 2 !== 0) return 30
        return 31
    }
    const isBissextile = (year) => {
        if (year % 4 === 0 && year % 100 !== 0) return true
        if (year % 400 === 0) return true
        return false
    }

    useEffect(() => {
        // console.log('pdfBlob', pdfBlob, typeof pdfBlob);
      if (isDefined(pdfBlob)) {
        pdfBlob.then(result => onLoadBlob(result))
      } else { setEmailAttachments(null) }
    }, [pdfBlob]);

    const onLoadBlob = (result) => {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(result);
            reader.onloadend = function () {
                let base64data = reader.result;
                setEmailAttachments(base64data);
                setOpenMail(true);
            }
        } catch (e) {
            console.error(`❌ onLoadBlob ${e}`);
        }
    }

    useEffect(() => {
        if (props.col.id === 1001 && pwField !== '') {
            let _row = props.colonnes.find(row => row.id === 1000)
            props.handleChangeChildrenValue(pwField, _row, props.row, props.rindex)
        }
    }, [pwField])

    useEffect(() => {
        if (props.col.id === 1001 && value !== '') {
            checkRegistered(value)
        }
    }, [])

    useEffect(() => {
        if (props.col.id === 1001) {
            let _row = props.colonnes.find(row => row.id === 1000)
        }
    }, [registered])

    useEffect(() => {
        if (props.value !== undefined) setValue(props.value);
        else setValue('')
    }, [props.value])

    useEffect(() => {
        if (openPromised){
            if (!promiseInProgress && getPlugnote() !== null){
                switch (props.mode) {
                    case 'create':
                    case 'update':
                    case 'shared-create':
                    case 'shared-update':
                    case 'modal-shared-update':
                        try {
                            handleOpenModalConfigurator();
                        }
                        catch (e) {
                            //console.log('error',e);
                        }
                        break;
                    default:
                        break;
                }
                setOpenPromised(false);
            }
            else{
                setOpenPromised(true);
            }
        }
    }, [promiseInProgress, openPromised, getPlugnote()])

    switch (props.col.format) {
        case 'date':
            let dateMask = ''
            let retrievedDate
            if (value !== '' && value !== undefined) {
                retrievedDate = value.split('-')
                if (retrievedDate.length > 2) {
                    let year = retrievedDate[retrievedDate.length - 1]
                    let month = retrievedDate[retrievedDate.length - 2]
                    let day = retrievedDate[retrievedDate.length - 3]
                    if (month.length === 1) month = '0' + month.toString()
                    if (day.toString().trim().length === 1) day = '0'.concat(day.toString())
                    retrievedDate = day + '/' + month + '/' + year
                }}

            if (typeof props.col.regex !== "undefined" && props.col.regex !== null){
                dateMask = props.col.regex
            }
            let dateIsInvalid = false
            if (value !== '' && value !== undefined) {
                switch (dateMask) {
                    case '99/99':
                        if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) > monthLength(parseInt(value.substr(3,2))) || parseInt(value.substr(3,2)) >= 13
                        break;
                    case '99/99/99':
                        if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) > monthLength(parseInt(value.substr(3,2))) || parseInt(value.substr(3,2)) >= 13
                        break;
                    case '99/9999':
                        if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) >= 13
                        break;
                    case '99/99/9999':
                        if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) > monthLength(parseInt(value.substr(3,2)), isBissextile(parseInt(value.substr(6,4)))) || parseInt(value.substr(3,2)) >= 13
                        break;
                    case '99':
                        dateMask = ''
                        break;
                    default:
                        break;
                }}
            template = (
                <div className={classes.fieldValueContainer}>
                    {dateMask !== ''?
                        <InputMask
                            disabled={props.disabled}
                            maskChar="_"
                            mask={dateMask}
                            value={props.col.id !== 1003? value : retrievedDate}
                            onChange={(event) => {if (props.col.id !== 1003) {
                                setAccept(false);
                                handleChangeTableValue(event.target.value)
                            }}}
                        >
                            {(inputProps) =>
                                <TextField
                                    {...inputProps}
                                    inputProps={{ style: {textAlign: 'left', paddingLeft: 5} }}
                                    placeholder={dateMask === ''? '' : props.col.mask}
                                    name={props.col.format + '-' + props.cindex}
                                    type="text"
                                    fullWidth={true}
                                    InputProps={{
                                        classes: {
                                            input: classes.fontChange,
                                            root: classes.renderInputContainer,
                                        },
                                        endAdornment:
                                            (value !== undefined && value.length > 0 && dateIsInvalid && !accept ?
                                                <React.Fragment>
                                                    <Tooltip title={t('common:multiple.validate')} placement="right">
                                        <span className={classes.fontError} onClick={() => setAccept(true)}>
                                            {t('common:multiple.invalid-value')}
                                            <span id="icon-add-playlist" className={`${classes.tableMultButton} material-icons`}>
                                                check
                                            </span>
                                        </span>
                                                    </Tooltip>
                                                </React.Fragment> : null),
                                    }}
                                />
                            }
                        </InputMask>
                        :
                        <TextField
                            inputProps={{ style: {textAlign: 'left', paddingLeft: 5} }}
                            placeholder={dateMask === '' ? '' : props.col.mask}
                            name={props.col.format + '-' + props.cindex}
                            type="text"
                            disabled={props.col.id === 1003}
                            fullWidth={true}
                            InputProps={{
                                classes: {
                                    input: classes.fontChange,
                                    root: classes.renderInputContainer,
                                },
                            }}
                            onChange={(event) => {handleChangeTableValue(event.target.value)}}
                            value={value}
                        />}
                </div>
            );
            break;
        case 'duration':
            let durMask = '99:99:99'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null){
                durMask = props.col.regex
            }
            template = (
                <div className={classes.fieldValueContainer}>
                    <DurationInput
                        {...props}
                        accept={accept}
                        duration={duration}
                        handleChangeTableValue={handleChangeTableValue}
                        mask={durMask}
                        setAccept={setAccept}
                        setDuration={setDuration}
                        value={value}
                    />
                </div>
            );
            break;
        case 'extdata':
            const titleFormat = `( ${ref} )  ( ${query} ) [ ${column} ] [ ${columns} ] [ ${dest} ]`
            template = (
                <div className={classes.fieldValueContainer}>
                    <Input
                        className={classes.fontChange}
                        classes={{root: classes.renderSingleSelContainer}}
                        disabled={props.disabled}
                        fullWidth={true}
                        inputProps={{ maxLength: props.col.mask, width : '100%' }}
                        multiline={true}
                        name={props.col.format + '-' + props.cindex}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter' && value !== '' && !hideSearch) handleOpenSearchModel()}}
                        placeholder={'S01'}
                        endAdornment={
                            value !== "" && !disabled ?
                                <InputAdornment position="start" onClick={() => {if (!hideSearch) handleOpenSearchModel()}} style={{cursor : 'pointer', right: 0, position: 'absolute', }}>
                                    <Search />
                                </InputAdornment>
                                : null
                        }
                        type="text"
                        value={value}
                    />
                    {open && value !== "" &&
                        <ModalListExtDataComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={open} close={(event) => setOpen(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external}/>
                    }
                </div>
            )
            break;
        case 'formula':
            template = (
                <div className={classes.fieldValueContainer}>
                    <Input
                        classes={{root: classes.renderSingleFormulaContainer}}
                        fullWidth={true}
                        placeholder={props?.col?.configuration?.formula || 'formula'}
                        multiline={true}
                        className={classes.fontChange}
                        inputProps={{ maxLength: props.col.mask, width : '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={typeof value !== 'string' ? value !== null ?  value?.toString() : "" : value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={true}
                        startAdornment={loading &&
                            <Fade
                                in={loading}
                                style={{
                                    transitionDelay: loading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress size={10}/>
                            </Fade>
                        }
                        endAdornment={ !disabled &&
                            <InputAdornment position="end" onClick={(event) => handleCalculateValue()} style={{cursor : 'pointer'}}>
                                <span className="material-icons md-medium green">calculate</span>
                            </InputAdornment>
                        }
                    />
                </div>
            )
            break;
        case 'url':
            template = (
                <div  className={classes.fieldValueContainer}>
                    <TextField
                        inputProps={{ style: {textAlign: 'left', paddingLeft: 5} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontChange,
                                root: classes.renderSingleSelContainer
                            },
                        }}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        type="url"
                        value={value}
                    />
                    {
                        typeof value !== 'undefined' &&  value !== '' &&
                        <div style={{marginLeft: '10px'}}>
                            <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                        </div>
                    }
                </div>
            );
            break;
        case 'mail':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        inputProps={{ style: {textAlign: 'center'} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        defaultValue={value}
                        fullWidth={true}
                        onChange={(e) => {
                            handleChangeTableValue(e.target.value);
                            checkRegistered(e.target.value);
                        }}
                        disabled={props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.fontChange,
                                root: classes.renderSingleSelContainer,
                            },
                        }}
                    />
                    {typeof value !== 'undefined' &&  value !== '' && props.col.id === 1001 && !props.mode.includes('plugcode') &&
                        <div style={{marginLeft: '10px'}}>
                            <span className={registered? classes.mailButton : classes.unregisteredBtn} onClick={controlBeforeOpen}><span className="material-icons md-small white">forward_to_inbox</span></span>
                        </div>
                    }

                    {typeof value !== 'undefined' &&  value !== '' && props.col.id === 1001 &&
                        <div style={{marginLeft: '10px'}}>
                            <Tooltip title={registered? contentTooltip : unregisteredMsg} placement="top">
                                <span className={registered? classes.mailButton : classes.unregisteredBtn} onClick={getInformations} onMouseEnter={getInformations}><span className="material-icons md-small white">info</span></span>
                            </Tooltip>
                        </div>
                    }

                    {
                        openMail && props.col.id === 1001 &&
                        <SmailModal {...props}
                            open={openMail}
                            close={handleCloseModalSmail}
                            value={value}
                            emailAttachments={emailAttachments}
                            pwCode={pwCode}
                            handleCloseWhenSendModalSmail={handleCloseWhenSendModalSmail}
                            setPdfBlob={setPdfBlob}
                            setPwField={setPwField}
                        />
                    }

                    {
                        openConfigurator && props.col.id === 1001 &&
                        <ConfigModal {...props} open={openConfigurator} external={false} close={() => setOpenConfigurator(false)} value={value} openMail={handleOpenModalSmail} setPwCode={setPwCode} setPwField={setPwField}/>
                    }

                    {
                        openExternalConfigurator && props.col.id === 1001 &&
                        <ConfigModal {...props} open={openExternalConfigurator} external={true} close={() => setOpenExternalConfigurator(false)} value={value} openMail={handleOpenModalSmail} setPwCode={setPwCode} setPwField={setPwField}/>
                    }
                </div>
            );
            break;
        case 'numeric':
            let separator = false
            let hasDecimal = false
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null){
                // console.log('case: ', props.col.regex);
                switch(props.col.regex) {
                    case "99,999999":
                        hasDecimal = true
                        break;
                    case "9.999":
                        separator = true;
                        // errorMsg = 'Integer only'
                        break;
                    case "9.999,99999":
                        separator = true;
                        hasDecimal = true;
                        break;
                    default:
                        break;
                }
            }
            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${classes.fieldValueContainer} ${classes.renderSingleSelContainer}`}>
                    <NumberFormat
                        {...props}
                        // allowedDecimalSeparators={[',', '.']}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={hasDecimal? 99 : 0}
                        decimalSeparator={"."}
                        format={null}
                        onCopy={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(value)
                        }}

                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', //value after applying formatting
                            // value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
                            //setNumericValue(v);
                            setValue(v.floatValue);
                            if (infos.event !== null) {
                                handleChangeTableValue(v.floatValue);
                            }else if (infos.event === null && v.value !== value.toString()){
                                handleChangeTableValue(v.floatValue);
                            }
                        }}
                        thousandSeparator={separator ? ' ' : false}
                        value={value}
                    />
                </div>
            );
            break;
        case 'presel':
            let multiselect = props?.col?.mask?.multiselect || false;
            let editable = props?.col?.mask?.editable || false;
            if (multiselect === false && editable === false){
                template = (
                    <div className={classes.widerFieldValueContainer}>
                        <RenderItemSingleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={props.col.presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            else if (multiselect === false && editable === true){
                let presel = props.col.presel;
                if (!presel.includes(value)){
                    presel.push(value);
                }
                template = (
                    <div className={classes.widerFieldValueContainer}>
                        <RenderItemSingleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            else if (multiselect === true && editable === false){
                template = (
                    <div className={classes.widerFieldValueContainer}>
                        <RenderItemMultipleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={props.col.presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            else if (multiselect === true && editable === true){
                let tmpValue = Array.isArray(value) ? value : [];
                let presel = props.col.presel;
                tmpValue.map((item, index) => {
                    if (!presel.includes(item)){
                        presel.push(item);
                    }
                });
                template = (
                    <div className={classes.widerFieldValueContainer}>
                        <RenderItemMultipleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            break;
        case 'radio':
            let masks = props.col.mask.split('/')
            template = (
                <div className={classes.fieldValueContainer}>
                    <div style={{float : 'right'}} className={classes.renderSingleSelContainer}>
                        <RadioGroup className={classes.RadioGroupCell} aria-label="gender" name="gender1" value={value} onChange={(event => handleChangeTableValue(event.target.value))}>
                            {masks.map((mask, index) => {
                                return(
                                    <FormControlLabel size='small' value={mask} control={<Radio classes={{root: classes.radioIcon, checked: classes.radioIconChecked}}  size='small' disabled={props.disabled}/>} label={<span className={classes.radioLabel}>{mask}</span>} key={index} />
                                );})}
                        </RadioGroup>
                    </div>
                </div>
            );
            break;
        case 'select':
            let presels = props.col.presel;
            template = (
                <div className={classes.widerFieldValueContainer}>
                    <Select
                        classes={{
                            icon: classes.selectIcon,
                            // nativeInput: `${classes.renderSingleSelContainer} ${classes.selectSelect}`,
                            root: `${classes.fontChangeWidth} ${classes.selectField}`,
                            select: `${classes.renderSingleSelContainer} ${classes.selectSelect}`,
                        }}
                        disabled={props.disabled}
                        disableUnderline
                        displayEmpty
                        fullWidth={true}
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        onChange={(event => handleChangeTableValue(event.target.value))}
                        onClick={() => setOpenSelect(!openSelect)}
                        open={openSelect}
                        renderValue={(value) => value !== '' && (<span  className={classes.selectedItem}>{value}</span>)}
                        value={value}
                    >
                        {presels.map((row, index) => (
                            <MenuItem key={index} value={row} dense>{row}</MenuItem>
                        ))}
                    </Select>
                </div>
            );
            break;
        case 'tel':
            let telMask = '+99999999999999'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null){
                telMask = props.col.regex
            }
            template = (
                <div  className={classes.fieldValueContainer}>
                    <TextField
                        inputProps={{ style: {textAlign: 'left', paddingLeft: 5} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontChange,
                                root: classes.renderSingleSelContainer
                            },
                        }}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        type="tel"
                        value={value}
                    />
                </div>
            );
            break;
        case 'text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: props.col.mask, width : '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        id={'text-input' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        disabled={props.col.id === 1000? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.textFont,
                                root: props.col.id === 1000? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                    <React.Fragment>
                                        <span className={classes.textLength}>{value.length}/{props.col.mask}</span>
                                    </React.Fragment>
                                    : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'time':
            let timeMask = '99:99:99'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null){
                // console.log('regex', props.col.regex);
                timeMask = props.col.regex
            }
            let timeIsInvalid = false
            switch (timeMask) {
                case '99:99:99':
                    if (!value.includes('_')) timeIsInvalid = parseInt(value.substr(0,2)) >= 24 || parseInt(value.substr(3,2)) >= 60 || parseInt(value.substr(6,2)) >= 60
                    break;
                case '99:99':
                    if (!value.includes('_')) {
                        if (props.col.mask.includes('H')) {timeIsInvalid = parseInt(value.substr(0,2)) >= 24 || parseInt(value.substr(3,2)) >= 60}
                        else timeIsInvalid = parseInt(value.substr(0,2)) >= 60 || parseInt(value.substr(3,2)) >= 60
                    }
                    break;
                case '99 H 99':
                    if (!value.includes('_')) timeIsInvalid = parseInt(value.substr(0,2)) >= 24 || parseInt(value.substr(5,2)) >= 60
                    break;
                case '99':
                    if (!value.includes('_')) timeIsInvalid = false
                    timeMask = ''
                    break;
                default:
                    break;
            }
            template = (
                <div className={classes.fieldValueContainer}>
                    {timeMask !== ''?
                        <InputMask
                            disabled={props.disabled}
                            maskChar="_"
                            mask={timeMask}
                            value={value}
                            onChange={(event) => {
                                setAccept(false);
                                handleChangeTableValue(event.target.value);
                            }}
                        >
                            {(inputProps) =>
                                <TextField
                                    {...inputProps}
                                    inputProps={{ style: {textAlign: 'left', paddingLeft: 5} }}
                                    placeholder={timeMask === '' ? '' : props.col.mask}
                                    name={props.col.format + '-' + props.cindex}
                                    type="text"
                                    fullWidth={true}
                                    InputProps={{
                                        classes: {
                                            input: classes.fontChange,
                                            root: classes.renderInputContainer,
                                        },
                                        endAdornment:
                                            (value.length > 0 && timeIsInvalid &&!accept ?
                                                <React.Fragment>
                                                    <Tooltip title={t('common:multiple.validate')} placement="right">
                                        <span className={classes.fontError} onClick={() => setAccept(true)}>
                                            {t('common:multiple.invalid-value')}
                                            <span id="icon-add-playlist" className={`${classes.tableMultButton} material-icons`}>
                                                check
                                            </span>
                                        </span>
                                                    </Tooltip>
                                                </React.Fragment> : null),
                                    }}
                                />
                            }
                        </InputMask>
                        :
                        <TextField
                            inputProps={{ style: {textAlign: 'left', paddingLeft: 5} }}
                            placeholder={timeMask === '' ? '' : props.col.mask}
                            name={props.col.format + '-' + props.cindex}
                            type="text"
                            disabled={props.disabled}
                            fullWidth={true}
                            InputProps={{
                                classes: {
                                    input: classes.fontChange,
                                    root: classes.renderInputContainer,
                                },
                            }}
                            onChange={(event) => {handleChangeTableValue(event.target.value)}}
                            value={value}
                        />
                    }
                </div>
            );
            break;
        default:
            break;
    }

    return (
        <div key={props.record} className={classes.fieldContainer}>
            <div className={classes.fieldTitleContainer}>
                <span className={classes.fontFieldNumber}>{props.cindex + 1}</span>
                <span className={classes.fontChangeLabel}>{props.col.label} : </span>
            </div>
            {template}
        </div>
    )
}

const FormatSMailValidMultiComponent = (props) => {
    // console.log('SMailValid props', props);
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined"){
        tmpDisabled = props.disabled;
    }
    else if (typeof props.field.disabled !== "undefined"){
        tmpDisabled = props.field.disabled;
    }

    const classes = useStyles();
    const { t } = useTranslation(['common']);

    let tmpColonnes = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    if (props.mode.includes('plugcode')) tmpColonnes = tmpColonnes.filter((col) => col.visible !== false)

    const [colonnes, ] = useState(tmpColonnes);
    const [rows , setRows] = useState(props.field.value?.list?.length > 0 ? props.field?.value?.list : []);
    const [disabled , setDisabled] = useState(tmpDisabled)
    const [openDownload, setOpenDownload] = useState(false);
    const [datasToDownload, setDatasToDownload] = useState([]);
    const [excessLimit, setExcessLimit] = useState(0)

    const setup = props.field.options?.setup
    let hideSearchValue = props?.model?.authoriseExternalDbInPf === undefined ? false  : !props?.model?.authoriseExternalDbInPf;
    if (typeof props.hideSearch !== "undefined"){hideSearchValue = props.hideSearch}
    const [hideSearch, setHideSearch] = useState(hideSearchValue);
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [openModal, setOpenModal] = useState(false);
    const [ref, setRef] = useState(props.field.options.ref);
    const [field, setField] = useState(props.field.options.field);
    const [column, setColumn] = useState(props.field.options.column);
    const [columns, setColumns] = useState(props.field.options.columns);
    const [options, setOptions] = useState(props.field.options);
    const [dest, setDest] = useState(props.field.options.dest);
    const [value, setValue] = useState(!!props.field?.value?.value ? props.field?.value?.value : "")
    const [ownerId, setOwnerId] = useState(props.ownerId)
    const [criteria, setCriteria] = useState("= xx")
    const [hasDb, ] = useState((ref !== '' && column !== '' && columns !== '') || false)
    const [optionFormule, setOptionFormule] = useState({licenseKey: 'gpl-v3'})

    //TableView
    const [newRow, setNewRow] = useState(null)
    const [openTable, setOpenTable] = useState(false)

    //Add pw line when missing //! inutile: feature not released
    // if (colonnes.length > 0 && share) {
    //     if (colonnes[1].label !== 'PW-code') {
    //         let copyRows = [...colonnes]
    //         copyRows.splice(1, 0, {'label':'PW-code', 'formatLabel':'Texte', 'format':'text', 'mask':'40', 'presel':null, 'id':1000, 'regex':[], 'configuration':null, 'visible':true, 'editable':false})
    //         setColonnes(copyRows)
    //     }
    //     // console.log('cols after', colonnes);
    // }
    // //Remove pw line if no share
    // if (colonnes.length > 0 && !share) {
    //     if (colonnes[1].label === 'PW-code') {
    //         let copyRows = [...colonnes]
    //         copyRows.splice(1, 1)
    //         // setRows(copyRows)
    //     }
    //     console.log('rows after', colonnes);
    // }

    //function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows (rows) {
        let newRows = []
        if (rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if (valeur !== '' && valeur !== undefined) value = valeur
                })
                if (value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    useEffect(() => {
        let newRows = deleteEmptyRows(rows)
        setRows(newRows)
    }, [])

    const handleAddTableMulti = async () => {
        let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
        let isEmpty = true
        let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
        _rows.filter(Boolean)
        if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
            if (_rows.length > 0) {
                let lastOne = _rows[_rows.length - 1]
                Object.values(lastOne).forEach(k => {
                    let control = typeof k !== "string" ? k.toString() : k;
                    if (control !== "" && control !== '0:00' && control.length > 0) { isEmpty = false }
                })
                if (!isEmpty) {
                    let _newRow = createData()
                    setRows([..._rows, _newRow]);
                    setNewRow(_newRow)
                }
                else {
                    setNewRow(_rows[_rows.length - 1])
                }
            }
            else {
                let _newRow = createData()
                setRows([_newRow])
                setNewRow(_newRow)
            }
        } else {
            toast.info(t('tablemulti.row-limit-warn'),
                {
                    autoClose: 3000,
                    hideProgressBar: true,
                    bodyClassName: classes.warnToast,
                    className: classes.warnToast,
                    toastClassName: classes.warnToast,
                })
        }
    }

    const handleClickAddRow = () => {
        handleAddTableMulti()
        if(!openTable) setOpenTable(true)
    }

    const handleExportTableMulti = () => {
		let datas = [];
		if (colonnes.length > 0) {
			let line1 = [];
			line1.push(t('export.export-date'))
			line1.push(t('export.export-key'))
			line1.push(t('export.export-number'))
			line1.push(t('export.export-field-number'))
			line1.push(t('export.export-field'))
			colonnes.forEach(col => {
				if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
			})
			datas.push(line1);
			rows.forEach((row, index) => {
				let data = [];
				let now = new Date()
				data.push(now.toLocaleString())
				data.push(props.plugKey)
				data.push(props.plugNb)
				data.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
				data.push(props.title)

				colonnes.forEach(col => {
					if (isVisibleInCSV(col, props.mode)) {
					let someText = typeof row[col.id] === 'string' ? row[col.id].replace(/\r?\n?/g, '').trim() : row[col.id];
					if (someText === undefined) {
						someText = "";
					}
					data.push(someText);
				}})

				datas.push(data);
			});
		}
		if (datas.length > 0) {
			setDatasToDownload(datas);
			setOpenDownload(true);
		}
	}

    useEffect(() => {
        if (openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        let items
        if (Array.isArray(value)) { // Array des index à supprimer
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
            items = rows.filter((_, index) => index !== value);
        }
        setRows(items);
    };

    function createData() {
        let data = {};
        colonnes.map((col, index) => {
            data[col.id] = '';
        })
        return data;
    }

    const handleChangeTableValue =  () => {
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleEditValue = (value) => {
        setValue(value);
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        if (formula !== null && formula !== '') {
            colonnes.map(elm => {
                let tmpValue = row[elm.id];
                if (elm.format === 'numeric'){
                    tmpValue = row[elm.id].replace(', ', '.');
                }
                tmp.push([tmpValue]);
            });
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            let newFormula =  formula;

            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);


            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')){
                    answer =  mySum;
                }else{
                    answer =  "";
                }
            }catch (e){
                //setResult(e.message)
                answer =  "";
            }

            hfInstance.destroy();

        }

        return answer;

    }

    const handleChangeChildrenValue = async (value, col, row, rindex) => {
        // console.log('changechildren col - row - rindex', col, row, rindex);
        let copyColonnes = [...colonnes];
        let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);

        if (col !== null && col !== undefined) switch (col.format) {
            case 'presel':
                let multiselect = col.mask.multiselect;
                let editable = col.mask.editable;
                if (multiselect === false && editable === false){
                    row[col.id] =  value;
                    for (const list of obj) {
                        let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                        if (tmpValue !== null){
                            row[list.id] = tmpValue
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else if (multiselect === false && editable === true){
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else if (multiselect === true && editable === false){
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else if (multiselect === true && editable === true){
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                break;
            case 'radio':
                if (col.id === 1002){
                    let colDate = colonnes.filter((colonne) => colonne.id === 1003);
                    if (colDate.length > 0){
                        if (props.profil !== null){
                            row[colDate[0].id] = props.profil.lastname + ' ' + props.profil.firstname + ' - ' +  currentDate();
                        }
                        else row[colDate[0].id] = currentDate();
                    }
                    row[col.id] =  value;

                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else {
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                break;
            default:
                row[col.id] =  value;
                if (obj) {
                    for (const list of obj) {
                        let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                        if (tmpValue !== null){
                            row[list.id] = tmpValue
                        }
                    }
                }
                rows[rindex] = row;
                setRows([...rows]);
                break;
        }
    }

    const handleChangeChildrenRowValue =  (value,col, row,rindex) => {
        switch (col.format) {
            case 'extdata':
                rows[rindex] = value;
                setRows([...rows]);
                break;
            default:
                break;
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    function handleCloseTable () {
        setOpenTable(false)
        setNewRow(null)
        // delTableView()
    }

    useEffect(() => {
        // console.log('u-e rows')
        handleChangeTableValue();
    }, [rows]);

    useEffect (() => {
        setValue(!!props.field?.value?.value ? props.field?.value?.value : "");
    }, [props.field])

    useEffect(() => {
        let tmpDisabled = false;
        if (typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if (typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props]);

    useEffect(()=>{
        switch (props.field.options.query) {
            case 'CONTAINS':
                setCriteria("..xx..")
                break;
            case 'START':
                setCriteria("xx..")
                break;
            case 'MULTIPLE':
                setCriteria("xxx")
                break;
            default: setCriteria("= xx")
                break;
        }
    }, [props.field.options.query])

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpenModal(true);
    }

    const handleSelectData = (objects) => {
        let datas = [];
        if (objects.length > 0){
            objects.map((object, indexObject) => {
                let jsonObjectToArray = Object.values(object)
                let destinations = [];
                let champs = dest.split('-');
                champs.map((elm, index) => {
                    let field = elm.split(':');

                    if (field.length > 0 && field.length <= 3){

                        let dest = colonnes[(field[0] - 1)].id;
                        let col = field[1];
                        let copyValue = col?.split(';') || col;
                        let value = ""

                        copyValue.map((item, index) => {
                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                            let data = jsonObjectToArray[alphaNumIndexDest];
                            if (typeof data !== "undefined"){
                                let temp = data;
                                if (temp !== "" && typeof temp !== "undefined"){
                                    if (temp === null){
                                        temp = "";
                                    }

                                    if (temp !== ""){
                                        if (item === item.toUpperCase()) {
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                        }else{
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                        }
                                    }
                                }
                            }
                        });
                        destinations.push({
                            "dest" : dest,
                            "value" : value
                        })
                    }
                })
                if (destinations.length > 0){
                    let data = createData();
                    destinations.map((item, index) => {
                        data[item.dest] = item.value;
                    })
                    datas.push(data)
                }
            });
        }
        let rowLimit = setup.rowLimit
        if (rowLimit > 0 && datas.length > rowLimit - rows.length) {
            setExcessLimit(previous => {
                let excess = datas.length - (rowLimit - rows.length)
                if (excess !== 100 && previous > 0) {
                    toast.dismiss('row-limit-toast')
                    toast.info(
                        t('tablemulti.row-limit-warn') + ': ' + (previous + excess) + t('tablemulti.rows-ignored'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                    return 0
                }
                else { return previous + excess }
            })
            datas = datas.slice(0, rowLimit - rows.length)
        }
        if (datas.length > 0){
            const newRows = [...rows]
            datas.forEach(item => {
                newRows.push(item)
            })
            setRows(newRows)
        }
    }

    useEffect(() => {
		if (excessLimit > 0 && !toast.isActive('row-limit-toast')) {
			toast.warn(t('tablemulti.row-limit-warn'), { 
				hideProgressBar: true,
				bodyClassName: classes.warnToast,
				className: classes.warnToast,
				toastClassName: classes.warnToast,
				toastId: 'row-limit-toast',
				isLoading: true,
				autoClose: 3000,
			})
		}
	}, [excessLimit])

    const handleClickPreview = (index, id) => {
        handleViewTable()
    }

	function isLimitReached() {
		return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }

    const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`

    return (
        colonnes.length > 0 &&
        <div className={classes.mainCtn}>
            <div className={classes.rowCtn}>
                {
                    hasDb &&
                    <TextField
                        disabled={disabled}
                        onChange={(event) => handleEditValue(event.target.value)}
                        id={'search-db-input-' + props.field.id}
                        type="text"
                        multiline={false}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <React.Fragment>
                                    {
                                        !hideSearch && !disabled &&
                                        <div className={classes.searchBtn} > {criteria}
                                            <span id="icon-listForm" className={`material-icons md-25`} style={{ color: colors.main }} onClick={(event) => handleOpenSearchModel()}>
                                                search
                                            </span>
                                        </div>
                                    }
                                </React.Fragment>
                            ),
                        }}
                        helperText=""
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.searchField}
                        value={value}
                    />
                }
                <div className={classes.topBtnCtn}>
                    {
                        !disabled && props.field.options?.setup?.rowLimit !== 1 &&
                        <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                            <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
                                <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                    add_circle_outline
                                </span>
                            </div>
                        </PlugTooltip>
                    }

                    {
                        rows.length > 0 &&
                        <PlugTooltip title={t('tablemulti.table-view').concat(` ${rows.length} `).concat(t('tablemulti.rows'))} placement="top">
                            <div className={classes.topBtn} onClick={handleViewTable}>
                            <span id="icon-add-playlist" className="material-icons">
                                visibility
                            </span>
                                <span style={{fontSize: 10, fontWeight: 'bolder',}}>
                                {`(${rows.length})`}
                            </span>
                            </div>
                        </PlugTooltip>
                    }
                </div>
            </div>
            {
                openModal &&
                <ModalListExtDataMultipleComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={openModal} close={(event) => setOpenModal(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external} />
            }
                <ModalTablemultiTableViewComponent
                    {...props}
                    attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                    attemptSendMailSimple={props.attemptSendMailSimple}
                    colonnes={colonnes}
                    controlCreate={props.controlCreate}
                    controlUpdate={props.controlUpdate}
                    datasToDownload={datasToDownload}
                    disabled={disabled}
                    field={props.field}
                    fields={props.fields}
                    handleAddRecap={() => null}
                    handleAddTableMulti={handleClickAddRow}
                    handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                    handleChangeChildrenValue={handleChangeChildrenValue}
					handleClickPreview={handleClickPreview}
                    handleClose={handleCloseTable}
                    handleDeleteRow={handleRemoveSpecificRow}
                    handleEditValue={handleEditValue}
                    handleExportTableMulti={handleExportTableMulti}
                    handleOpenSearchModel={handleOpenSearchModel}
                    handleSafeExitPlugnote={props.handleSafeExitPlugnote}
                    hasDb={hasDb}
					hideSearch={hideSearch}
                    isPreview={!openTable}
                    mode={props.mode}
                    newRow={newRow}
                    open={openTable}
                    openDownload={openDownload}
                    openModal={openModal}
                    owner={owner}
                    ownerId={ownerId}
                    plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
                    renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                    rows={rows}
                    search={value}
                    setNewRow={setNewRow}
                    share={props.field?.options?.share === undefined ? false : props.field.options?.share}
					title={props.title}
                />
                
            {/* <TableContainer component={Paper} className={`${classes.tableRoot} `} classes={{root: classes.scrollBar}}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead className={classes.tableHeader}>
                        {
                            typeof rows !== "string" && Array.isArray(rows) && rows.length > 0 &&
                            <TableRow style={{height: 30, verticalAlign: 'top'}} className={classes.pageRowCtn}>
                                <TableCell className={classes.stickyColRight} style={{backgroundColor: colors.greyLighter, width: 25, justifyContent: 'center', margin: '0 auto'}} padding='none' >
                                <span className={classes.tableMultButton} style={{fontSize: 13, margin: '0 auto'}}>
                                    {`Rows: ${rows.length}`}
                                </span>
                                </TableCell>

                                <TableCell className={classes.stickyColRight} style={{marginLeft: 'auto', backgroundColor: colors.greyLighter, display: 'flex', justifyContent:'space-between', height: 30}} padding='none' >
                                <span id="icon-add-playlist" className={classes.tableMultPageText} style={{display: 'flex', justifyContent:'center', width: '-webkit-fill-available'}}>
                                    {rows.length > 3? rowsPerPage > 3 && <span className={classes.tableMultPageChoice} onClick={() => handleChangeRowsPerPage(3)}>View by 3</span> : null }
                                    {rows.length > 10? rowsPerPage !== 10 && <span className={classes.tableMultPageChoice} onClick={() => handleChangeRowsPerPage(10)}>View by 10</span> : null}
                                    {rows.length > 3? rowsPerPage < rows.length && <span className={classes.tableMultPageChoice} onClick={() => handleChangeRowsPerPage(rows.length + 1)}>View All ({rows.length})</span> : null}
                                    <span  style={{cursor: 'pointer'}} className={classes.tableMultPageChoice} onClick={()=>handleOpenAll()}>{openAll? 'Collapse ' : 'Open '}all</span>
                                </span>

                                    <div className={classes.tripleCell} >
                                    <span padding='none' className={`${classes.arrowLeft} ${page === 0 && classes.hidden}`}>
                                        <span id="icon-toggle-table" className={` material-icons`} onClick={() => {handleTurnPage("-"); }} disabled={page === 0}>
                                            keyboard_arrow_left
                                        </span>
                                    </span>

                                        <span padding='none' className={`${classes.pageCell} ${maxPage === 1 && classes.hidden}`}>
                                        {page + 1}/{maxPage}
                                    </span>

                                        <span padding='none' className={`${classes.arrowRight} ${page + 1 === maxPage && classes.hidden}`}>
                                        <span id="icon-toggle-table" className={`material-icons`} onClick={() => handleTurnPage("+")} disabled={page + 1 === maxPage}>
                                            keyboard_arrow_right
                                        </span>
                                    </span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {
                            typeof rows !== "string" && Array.isArray(rows) && (rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows)
                                .map((row, rindex) => {
                                    let rowId = rindex + 1 + rowsPerPage * page
                                    let visibleRows = (rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows)
                                    let lastOfPage = rindex === rowsPerPage - 1 || rindex === visibleRows.length - 1
                                    return(
                                        <React.Fragment key={rindex}>
                                            <TableRow className={openRow === rindex ? classes.openRow : null}>
                                                <TableCell className={`${classes.addCellButton}`} style={{padding: 15, width: 25}}>
                                                    <div  className={classes.rowDelCellButton}>
                                                        <span className={classes.rowNum}>{rowId < 9 ? '0' : null}{rowId}</span>
                                                        {!disabled &&
                                                            <span id="icon-add-playlist" className="material-icons md-small" onClick={(event) => handleRemoveSpecificRow(rindex + page * rowsPerPage)}>
                                                delete
                                            </span>}
                                                    </div>
                                                </TableCell>

                                                <TableCell
                                                    align='left'
                                                    onClick={(e) => handleOpenRow(e, rindex)}
                                                    className={`${classes.stickyCol} ${classes.valuesCell}`}
                                                    style={{cursor: 'pointer'}}
                                                    key={rindex}>
                                                    <RenderItemByTableCell
                                                        row={row}
                                                        field={props.field}
                                                        disabled={true}
                                                        rindex={rindex + rowsPerPage * page}
                                                    />
                                                    <div className={`${classes.rowBtn} `} onClick={(e) => {handleOpenRow(e, rindex)}}>
                                                        <IconButton aria-label="expand row" size="small">
                                                            {openAll || (openRow === rindex) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </div>
                                                    { !disabled && lastOfPage &&
                                                        <div className={`${classes.stickyColRight} `} style={{ width: 25 }}>
                                                            <Tooltip title="Add empty row" placement="bottom">
                                                <span id="icon-add-playlist" className={`${classes.tableMultAddButton} material-icons`} onClick={(e) => handleAddTableMulti(e)}>
                                                    add_circle_outline
                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={openAll || (openRow === rindex)} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            {colonnes.map((col, cindex) => {
                                                                if (col.visible !== false){
                                                                    // console.log('col', cindex, ' : ', col);
                                                                    return (
                                                                        <RenderItemByTableCellInLine
                                                                            {...props}
                                                                            cindex={cindex}
                                                                            col={col}
                                                                            colonnes={colonnes}
                                                                            field={props.field}
                                                                            disabled={disabled}
                                                                            handleChangeChildrenValue={handleChangeChildrenValue}
                                                                            handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                                                                            key={cindex}
                                                                            mode={props.mode}
                                                                            rindex={rindex + rowsPerPage * page}
                                                                            row={row}
                                                                            share={share}
                                                                            style={{padding: 15}}
                                                                            value={row[col.id]}
                                                                            record={cindex}
                                                                        />
                                                                    )
                                                                } return null;
                                                            })}
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )})
                        }
                    </TableBody>
                </Table>
            </TableContainer> */}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        user2 : state?.user || null,
        profil : state?.user?.profil || null,
		"plugKey": state?.model?.model?.clef || null
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
    attemptSendMailSimple: (payload, userhash) => dispatch(postSendMailV3Request(payload,userhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailValidMultiComponent);
