import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {confirmAlert} from "react-confirm-alert";

import {connect} from "react-redux";
import {
    delAllInviteModelSharesRequest,
    delModelShareRequest,
    getModelSharesRequest,
    postModelSharesRequest,
    putModelSharesRequest
} from "../../actions/share";
import {getCurrentEnvironnement, getModel} from "../../utils/Common";
import Select from "@material-ui/core/Select";
import {FormControlLabel, Input, Switch, Tooltip} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close} from "@material-ui/icons";
import colors from "../../assets/themes/Colors";

function getModalStyle() {
    const top = 10;

    return {
        top: `${top}%`,
        margin:'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin : 0,
    },
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        overflowY: 'auto',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '85%',
        minHeight: '300px',
        maxHeight: '85vh',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 8,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    switchTrack: {
        backgroundColor: colors.unselectedAlpha,

    },
    switchBase: {
        color: colors.unselectedMain,
        "&.Mui-checked": {
            color: colors.selectedMain
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: colors.selectedMain
        }
    },
    rowMailCtn: {
        width: '20vw',
        textAlign: 'start'
    },
    rowCtn: {
        display: 'flex',
		zIndex: 200,
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'grey'
    },
    bouton: {
        padding: '8px 20px',
        color: 'white',
        backgroundColor: colors.mainButton,
        border: 'none',
        borderRadius: 3,
        margin: 5,
        cursor: 'pointer',
        width: 200,
    },
    reverseBtn: {
        padding: '8px 20px',
        border: '2px solid',
        borderColor: colors.mainButton,
        color: colors.mainButton,
        backgroundColor: 'white',
        borderRadius: 3,
        margin: 5,
        cursor: 'pointer',
        width: 200,
        fontWeight: 'bold',
    },
    editIcons: {
        padding: '3px 8px',
        alignSelf: 'baseline',
        '&:hover': {
            backgroundColor: colors.paleGrey,
            borderRadius: 6
        }
    }
}));

const ModalModelShareComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [t, i18n] = useTranslation('common');
    const environnement = getCurrentEnvironnement();
    const currentChooseModel = getModel();
    const model = props.model === null ? currentChooseModel : props.model;
    const [email, setEmail] = useState("");
    const [rules, setRules] = useState(-1);
    const [share, setShare] = useState(false);
    const [shareList, setShareList] = useState(null);

    const [shareValue, setShareValue] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [updateSwitchCall, setUpdateSwitchCall] = useState(false);
    const [payload, setPayload] = useState({});


    const handleSubmitModelShare = () => {
        let tmpRules = Number(rules.toString());
        if(email.length > 0 && tmpRules !== -1){
            let payload =  {
                email: email,
                read_fiche: "1",
                add_fiche: tmpRules >= 1 ? "1" : "0",
                edit_fiche: tmpRules >= 1 ? "1" : "0",
                delete_fiche: tmpRules === 2 ? "1" : "0",
                read_annexe: "1",
                add_annexe: tmpRules >= 1 ? "1" : "0",
                edit_annexe: tmpRules >= 1 ? "1" : "0",
                delete_annexe: tmpRules === 2 ? "1" : "0",
                only_creator: "0",
                share: "1",
                active: "1",
                corporate: "0",
                share_as_model: null,
                modelhash: model.hash
            };

            props.attemptPostModelSharing(payload, environnement.userhash, model.hash, false, model);
            setShare(false);
            setEmail("");
            setRules(-1);
            setEditMode(false);
        }
    };

    const handleUpdateModelShare = () => {
        let tmpRules = Number(rules.toString());

        if(email.length > 0 && tmpRules !== -1){
            let payload = {
                email: email,
                read_fiche: "1",
                add_fiche: tmpRules >= 1 ? "1" : "0",
                edit_fiche: tmpRules >= 1 ? "1" : "0",
                delete_fiche: tmpRules === 2 ? "1" : "0",
                read_annexe: "1",
                add_annexe: tmpRules >= 1 ? "1" : "0",
                edit_annexe: tmpRules >= 1 ? "1" : "0",
                delete_annexe: tmpRules === 2 ? "1" : "0",
                only_creator: shareValue.modelsshared_onlyCreator === 1 ? "1" : "0",
                share: "1",
                active: "1",
                corporate: "0",
                share_as_model: null,
                modelhash: model.hash,
                modelsharedhash: shareValue.modelsshared_hash
            };

            props.attemptPutModelSharing(payload, environnement.userhash, model.hash, false, model);
            setEmail("");
            setRules(-1);
            setShare(false);
            setShareValue({});
            setEditMode(false);
        }
    }

    const handleCancelModelShares = () => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('share.model-share-confirm-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        let modelsshared_modId = shareList[0].modelsshared_modId;
                        let modelsshared_ownerId = shareList[0].modelsshared_ownerId;
                        props.attemptRemoveAllInviteSharedForModel(modelsshared_modId,modelsshared_ownerId, model.hash, false,model);
                    }
                },
                { label: t('global.cancel')},

            ]
        });
    };

    const handleCancelModelShare = (share) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('share.model-share-confirm-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        const result  = shareList.filter(elm => elm.modelsshared_id !== share.modelsshared_id);
                        props.attemptRemoveSharedForModel(share.modelsshared_hash,model);
                        setShareList(result);
                    }
                },
                { label: t('global.cancel')},

            ]
        });
    };

    const handleEditModelShare = (share) => {
        setEditMode(true);
        setEmail(share.email);
        setShareValue(share);

        let code = share.modelsshared_readFiche.toString() + share.modelsshared_addFiche.toString() + share.modelsshared_editFiche.toString() + share.modelsshared_deleteFiche.toString();

        switch (code.toString()) {
            case "1000":
                setRules(0);
                break;
            case "1110":
                setRules(1);
                break;
            case "1111":
                setRules(2);
                break;
            default:
                break;
        }
    };

    const handleChangeRules = (event) => {
        setRules(event.target.value)
    }

    const handleToggleSharePlugnotes = (item) => {
        let id = shareList.indexOf(item);
        shareList[id]['modelsshared_onlyCreator'] = shareList[id]['modelsshared_onlyCreator'] === 0 ? 1 : 0
        setShareList([...shareList]);
        setUpdateSwitchCall(true);
    }

    useEffect(() => {
        if(updateSwitchCall){
            handleUpdateModelShare();
            setUpdateSwitchCall(false);
        }
    }, [updateSwitchCall])

    useEffect(() => {
        props.attemptGetSharesForModel(model.hash, environnement.userhash, false, model);
    }, []);

    useEffect(() => {
        setShareList(props.sharesByModel)
    }, [props.sharesByModel]);

    const body = (
        <div style={modalStyle} className={classes.popup}>
            <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                <div className="popup_wrapper_content">
                    <div className="sous-header_content">
                        <div>
                            <span className="titres">
                                {t('plugcode.plugform-sharing')} <br />
                            </span>
                            <span className="grey">
                                [{model.clef}] {model.label}
                            </span>
                        </div>

                        <div className="flexSpacebetween" style={{alignItem: 'center'}}>
                            {
                                shareList !== null && shareList.length > 0 &&
                                <li className="confirmation_pop-up" onClick={handleCancelModelShares}>
                                    <button className={classes.reverseBtn}>{t('plugcode.cancel-share')}</button>
                                </li>
                            }
                            <span id="icon-close" className="material-icons md-50 lightgrey" onClick={props.close}>
                                close
                            </span>
                        </div>

                    </div>
                    <div className="sous-header_content lightgrey">
                        <div className="line flex_style">
                        </div>
                    </div>
                </div>

                <div className="flexSpacebetween">
                    <div className="popup_wrapper_content split-40">
                        <ul className="inputWrapper">
                            <li>
                                <Input
                                    disabled={editMode}
                                    id="standard-full-width"
                                    label=""
                                    placeholder="Email"
                                    fullWidth={true}
                                    margin="none"
                                    disableUnderline={true}
                                    endAdornment={ email.length > 0 &&
                                        <InputAdornment position="end" style={{cursor : "pointer" , color : 'black'}} onClick={(event) => {
                                            setEmail("");
                                            setEditMode(false);
                                            setRules(-1);
                                            setShareValue({});
                                        }}>
                                            <Close />
                                        </InputAdornment>
                                    }
                                    inputlabelprops={{
                                        className: classes.input,
                                    }}
                                    value={email}
                                    className={classes.textField}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                {/*<input className="input field input_font" type="text" placeholder="email" value={email} onChange={(event) => setEmail(event.target.value)}/>*/}
                            </li>
                            <li>
                                <Select
                                    disableUnderline
                                    native
                                    value={rules}
                                    onChange={handleChangeRules}
                                    label={t('share.select-rules-label')}
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                        classes: {
                                            icon: classes.iconSelect,
                                        },
                                    }}
                                    className="input"
                                    IconComponent={props => (
                                        <i {...props} className={`material-icons ${props.className}`}>
                                            keyboard_arrow_down
                                        </i>
                                    )}
                                >
                                    <option value="-1" disabled={true}>Choisir droits</option>
                                    <option value="0">Lecture</option>
                                    <option value="1">Lecture, ajout, modification</option>
                                    <option value="2">Lecture, ajout, modification et suppression</option>
                                </Select>
                            </li>
                        </ul>
                    </div>
                    <div className="popup_wrapper_content split-40">
                        <ul className="inputWrapper">
                            {
                                editMode === false && rules !== -1 && email !== "" ?
                                    <li className="confirmation_pop-up" onClick={handleSubmitModelShare}>
                                        <button className={classes.bouton}>Partager</button>
                                    </li>
                                    :
                                    editMode === true &&
                                    <li className="confirmation_pop-up" onClick={handleUpdateModelShare}>
                                        <button className={classes.bouton}>Modifier</button>
                                    </li>
                            }
                        </ul>
                    </div>
                </div>
                <div className="popup_wrapper_content" style={{width: '-webkit-fill-available'}}>
                    <div style={{display: 'inline-flex',}}>
                        <span className="grey" style={{width: '20vw'}}>{t('plugcode.share-list')}</span>
                        <span className="grey" >{t('plugcode.share-rules')}</span>
                    </div>

                    <div className="inputWrapper">

                        {shareList !== null && shareList.map((item, index) => {
                            console.log('item', item);
                            return (
                                <div className={classes.rowCtn} key={index}>
                                    <span className={classes.rowMailCtn} style={{color: item.modelsshared_active === 1 ? "green" : "red"}}>
                                        {
                                            item.modelsshared_accepted === 0 &&
                                            <span id="icon-hourglass_empty" className="material-icons md-small">
                                                hourglass_empty
                                            </span>
                                        }
                                        {item.email}
                                    </span>

                                    <Tooltip title={t('plugcode.edit-rules')} placement='top'>
                                        <div className={classes.editIcons} onClick={(event) => handleEditModelShare(item)}>
                                            <span id="icon-visibility" className={item.modelsshared_readFiche === 1 ? "material-icons md green" : "material-icons md red"}>
                                                visibility
                                            </span>

                                            <span id="icon-add" className={item.modelsshared_addFiche === 1 ? "material-icons md green" : "material-icons md red"} style={{fontWeight: 'bold',}}>
                                                add_circle_outline
                                            </span>

                                            <span id="icon-create" className={item.modelsshared_editFiche === 1 ? "material-icons md green" : "material-icons md red"}>
                                                create
                                            </span>

                                            <span id="icon-close" className={item.modelsshared_deleteFiche === 1 ? "material-icons md green" : "material-icons md red"} style={{fontWeight: 'bold',}}>
                                                highlight_off
                                            </span>
                                        </div>
                                    </Tooltip>
                                     <FormControlLabel
                                        control={
                                            <Switch
                                                color="default"
                                                classes={{
                                                    track: classes.switchTrack,
                                                    switchBase: classes.switchBase,
                                                }}
                                                checked={item.modelsshared_onlyCreator === 0}
                                                onChange={() => handleToggleSharePlugnotes(item)}
                                                name={t('common:plugcode.share-plugnotes')}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        }
                                        label={t('common:plugcode.share-plugnotes')}
                                    />
                                    <div>
                                        <span id="icon-close" className="material-icons md" onClick={(event) => handleCancelModelShare(item)}>
                                            delete
                                        </span>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                </div>
            </div>
        </div>
    );

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.handleModalModelShareClose}
            aria-labelledby="simple-modal-title"
            // aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            { shareList !== null  ? body : <div/>}
        </Modal>
    )
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        listSharesModelByUser: state.share.listSharesModelByUser,
        sharesByModel: state.share.sharesByModel,
        model : state.share.model,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetSharesForModel: (modelhash, userhash, corporate, model) => dispatch(getModelSharesRequest(modelhash, userhash, corporate, model)),
        attemptPostModelSharing: (payload, userhash, modelhash, corporate, model) => dispatch(postModelSharesRequest(payload, userhash, modelhash, corporate, model)),
        attemptRemoveAllInviteSharedForModel: (modelSharedModelId,modelSharedOwnerId, modelhash, corporate, model) => dispatch(delAllInviteModelSharesRequest(modelSharedModelId, modelSharedOwnerId, modelhash, corporate, model)),
        attemptRemoveSharedForModel: (modelsharedhash,model) => dispatch(delModelShareRequest(modelsharedhash,model)),
        attemptPutModelSharing: (payload, userhash, modelhash, corporate, model) => dispatch(putModelSharesRequest(payload, userhash, modelhash, corporate, model)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalModelShareComponent)
