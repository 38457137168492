import React, {useEffect} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import * as moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    menuButtonAccount: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        color : '#58C3C1',
    },
    menuButtonHelp: {
        marginLeft: theme.spacing(1),
        //marginRight: theme.spacing(2),
        color : '#DADADA',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    li:{
        borderLeft: '1px solid #DADADA',
    },
    nav: {
        display: 'inline-block',
        padding: '0px 10px'
    },
    navLast: {
        display: 'inline-block',
        paddingLeft : '10px',
        paddingRight: '20px'
    }
}));

const TimeComponent = (props) => {

    let a = moment(props.creation);//now
    let b = moment(a).add(8, 'hours');
    let c = moment();
    let duration = moment.duration(b.diff(c));
    let hours = duration.hours(); //hours instead of asHours
    let minutes = duration.minutes(); //minutes instead of asMinutes

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'menu']);
    const [diffDate, setDiffDate]  =  React.useState(null);

    useEffect(() => {

        let a = moment(props.creation);//now
        let b = moment(a).add(8, 'hours');
        let c = moment();
        let duration = moment.duration(b.diff(c));
        let hours = duration.hours(); //hours instead of asHours
        let minutes = duration.minutes(); //minutes instead of asMinutes

        if(diffDate === null){
            setDiffDate(hours+':'+minutes)
        }
        setInterval(() => {
            setDiffDate(hours+':'+minutes)
        }, 30000)
    })

    return (
        <div><span className="references_timer">{i18n.t('common:global.validity-of-session')} {diffDate}</span></div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        username : !!state.user && !!state.user.user ?  state.user.user.username : null,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        creation : !!state.user && !!state.user.user  ? state.user.user.creationdate : null,
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeComponent);





