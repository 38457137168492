
import { PDFDocument } from "pdf-lib"


// const postponeAction = ms => new Promise(res => setTimeout(res, ms))

export const handleMergeMultiplePDF = async (url, files) => {
  // I chose to handle the main pdf first, then the attached pdf, because they are from two different sources and of different types
  const pdfDoc = await PDFDocument.create()
  let promise = new Promise(async resolve => {
    await fetch(url)
      .then(res => {
        res.arrayBuffer()
          .then(async result => {
            let docPart = await PDFDocument.load(result)
            let pages = []
            let docLength = docPart.getPageCount()
            pages = Array.from({ length: docLength }, (v, i) => i)

            pdfDoc.copyPages(docPart, pages)
              .then(res => {
                for (const page of res) {
                  pdfDoc.addPage(page)
                }
              })
              .catch(e => { console.error(`❌ copyPages main ${e}`) })
            // const docPages = docPart.getPages()
            if (files?.length > 0) {
              files.forEach(async (file, index) => {
                await fetch(file.path)
                  .then(res => {
                    res.arrayBuffer()
                      .then(async result => {
                        const docPart = await PDFDocument.load(result)

                        let docLength = docPart.getPageCount()
                        pages = Array.from({ length: docLength }, (v, i) => i)
                        pdfDoc.copyPages(docPart, pages)
                        .then(res => {
                          for (const page of res) {
                            pdfDoc.addPage(page)
                          }
                          if (index === files.length - 1) {
                            resolve(true)
                          }
                        })
                        .catch(e => { console.error(`❌ copyPages att #${index} ${e} `) })
                      })
                  })
              })
            } else { resolve(true) }
          })
      })
  })
  if (await promise) {
    return await pdfDoc.save()
  }
}

