import { makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from '../../../assets/themes/Colors'
import { isDefined } from '../../../utils/utils'
import { ColorCheckbox } from '../../custom/CustomComponents'
import { InputIcon } from '../../custom/icons/CustomIcons'

const useStyles = makeStyles((theme) => ({
  mainCtn: {
    padding: '12px 12px 12px 40px',
  },
  inputsCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainInputCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  secondaryCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 20,
    marginLeft: 40,
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      gap: 8,
    },  },
  title: {
    fontSize: 18,
    color: colors.dark,
    fontWeight: 600,
    opacity: 0.7,
  },
  disabled: {
    color: colors.iconGrey,
  },
  enabled: {
    cursor: 'pointer',
  },
}))

const RenderEditDefaultValueComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const setup = props.field.options.setup
  const _default = isDefined(setup.defaultValue) ? setup.defaultValue : ''
  const _editable = isDefined(setup.editable) ? setup.editable : true
  const _editablePf = isDefined(setup.editablePf) ? setup.editablePf : true
  const _editablePw = isDefined(setup.editablePw) ? setup.editablePw : true

  const [defaultValue, setDefaultValue] = useState(_default)
  const [editable, setEditable] = useState(_editable)
  const [editablePf, setEditablePf] = useState(_editablePf)
  const [editablePw, setEditablePw] = useState(_editablePw)

  const handleChangeDefaultValue = (e) => {
    let value = e.target.value
    setDefaultValue(value)
    props.handleChangeDefaultValue(value)
  }

  const handleDeleteValue = () => {
    setDefaultValue('')
    props.handleChangeDefaultValue('')
  }

  const handleToggleEditable = () => {
    setEditable(previous => {
      if (previous) {
        props.handleChangeDefaultValueEdit({ editable: false, editablePf: false, editablePw: false })
      } else {
        props.handleChangeDefaultValueEdit({ editable: true })
      }
      return !previous
    })
  }

  const handleToggleEditablePf = () => {
    if (editable) {
      setEditablePf(previous => {
        props.handleChangeDefaultValueEdit({ editablePf: !previous })
        return !previous
      })
    }
  }

  const handleToggleEditablePw = () => {
    if (editable) {
      setEditablePw(previous => {
        props.handleChangeDefaultValueEdit({ editablePw: !previous })
        return !previous
      })
    }
  }

  useEffect(() => {
    if (!editable) {
      setEditablePf(false)
      setEditablePw(false)
    }
  }, [editable])

  return (
    <div className={classes.mainCtn} >
      <span className={classes.title}>{t('helper.default-edit')} :</span>
      <div className={classes.inputsCtn} >
        <div className={classes.mainInputCtn} >
          <TextField
            value={defaultValue}
            onChange={handleChangeDefaultValue}
            InputProps={{
              endAdornment: 
                defaultValue?.length > 0 &&
                <span className="material-icons" onClick={handleDeleteValue} style={{ color: colors.main, zIndex: 1 }}>
                  highlight_off
                </span>              
            }}
          />
        </div>
        <div className={classes.secondaryCtn}>
          <span onClick={handleToggleEditable} className={classes.enabled}>
            <ColorCheckbox 
              checked={editable}
            />
            {t('constructor.editable')}
          </span>
          <span onClick={handleToggleEditablePf} className={!editable ? classes.disabled : classes.enabled} >
            <ColorCheckbox 
              checked={editablePf}
              disabled={!editable}
            />
            {t('constructor.editable-pf')}
          </span>
          <span onClick={handleToggleEditablePw} className={!editable ? classes.disabled : classes.enabled} >
            <ColorCheckbox 
              checked={editablePw}
              disabled={!editable}
            />
            {t('constructor.editable-pw')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default RenderEditDefaultValueComponent
