import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'

import UserReducer from './user';
import ModelReducer from './model';
import DataReducer from './data';
import FieldReducer from './field';
import StartupReducer from './startup';
import AnnexeReducer from './annexe';
import PlugcodeReducer from './plugcode';
import SearchReducer from './search';
import TemplateReducer from './template';
import ShareReducer from './share';
import ExImportReducer from './eximport'
import GalleryReducer from './gallery'
import ExternalReducer from './external'
import MailboxReducer from './mailbox'
import RepertoryReducer from './repertory'
import TranslateReducer from './translate'
import ExtDataReducer from './extdata'
import FilterReducer from './filter'
import ParamsReducer from './params'

const appReducer  = (history) => combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    model: ModelReducer,
    data: DataReducer,
    field : FieldReducer,
    startup : StartupReducer,
    annexe : AnnexeReducer,
    plugcode : PlugcodeReducer,
    search : SearchReducer,
    template : TemplateReducer,
    share : ShareReducer,
    eximport : ExImportReducer,
    gallery: GalleryReducer,
    external: ExternalReducer,
    mailbox: MailboxReducer,
    repertory: RepertoryReducer,
    translate: TranslateReducer,
    extdata: ExtDataReducer,
    filter: FilterReducer,
    params: ParamsReducer,
})



const rootReducer = (state, action) => {
    if(typeof action !== "undefined"){
        switch (action.type.toUpperCase()) {
            case "RESET_ALL_DATA":
                // Pour faire la purge de l'application
                state = undefined;
                return appReducer(state, action);
            default:
                return appReducer(state, action);
        }
    }
};

export default appReducer
