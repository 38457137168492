import { Checkbox, Input, InputLabel, Tooltip } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import colors from "../../assets/themes/Colors";
import Dropzone from "react-dropzone";

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        position: 'absolute',
        left: 0,
        top: 0,
        paddingLeft: 10,
        paddingTop: 10,
        height: '100vh',
        width: 370,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white,
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        }
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding:'0 10px',
        gap: 10,
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    margin:{
        marginTop : '15px',
        marginBottom : '5px',
    },
    subtitle: {
        fontWeight: 'bold',
        whiteSpace: 'pre-line',
        lineHeight: '1.5em',
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // paddingLeft: 20,
    },
    marginbuttonsBox : {
        margin : 5,
        cursor : 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height:'20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },
    selectType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    bodyContainer:{
        marginTop: '15px',
        width: '100%',
        // maxHeight: '60vh',
        // overflowY: 'auto',
    },
    inputType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    visibleBox:{
        height:'40px',
        margin:'5px 0',
        justifyContent: 'left',
        textAlign: 'start',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        '& .MuiIconButton-label': {
            color: colors.mainButton
        },
    },
    visibleText:{
        verticalAlign: 'text-bottom',
        color: 'black',
        fontStyle: 'italic',
        fontSize: 'small',
    },
    mainContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '5px 0px 5px 5px',
        paddingRight: 15,
        gap: 5,
        overflowY: 'auto',
    },
    selectsContainer: {
        gap: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    selectItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
        minHeight: 27,
    },
    dropzoneContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: -15,
        position: 'absolute',
        bottom: 0
    },
    dropZone: {
        alignSelf: "center",
        cursor: 'pointer'
    },
    checkboxContainer: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        marginTop: 10,
        width: '100%',
    },
    inputField: {
        width: 150,
    },
}))

export function SetupIndice (props) {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const racine = props.racine;
    const indice = props.indice;
    const editable = props.editable;

    const handleChangeRacine = (value) => {
        props.handleChangeRacine(value, props.index)
    }

    const handleChangeIndice = (value) => {
        props.handleChangeIndice(value, props.index)

    }

    const handleChangeEditable = (value) => {
        props.handleChangeEditable(value, props.index)
    }

    function resultIndex () {
        let valA = racine
        let valB = racine
        let valC = racine
        let num = ""
        for (let index = 0; index < indice.toString().length - 1; index++) {
            num = num.concat("0")
        }
        return valA.concat(num.concat("1, ")).concat(valB.concat(num.concat("2, "))).concat(valC.concat(num.concat("3, etc.")))
    }


    return(
    <div className={classes.sideMenu}>
        <div className={classes.sideHeader}>
            <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"}>{t("common:formats.indice")}</InputLabel>

            <span id="icon-close" className="material-icons md-50 lightgrey" onClick={() => {props.handleClose()}}>
                close
            </span>
        </div>
        <div className={classes.mainContainer}>

            <InputLabel className={classes.subtitle}>{t('common:ext-table.for-field')} {props.field.id}: {props.field.label}</InputLabel>

            <div className={classes.inputType}>
                <InputLabel className={classes.subtitle}>{t('common:indice.racine-instructions')}</InputLabel>
                <div className={classes.inputField}>
                    <Input
                        autoComplete="new-password"
                        id="standard-root"
                        placeholder={t('common:indice.racine-ex')}
                        style={{ width: '100%', paddingLeft: 10 }}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{shrink: true}}
                        value={racine}
                        onChange={(event) => {
                            handleChangeRacine(event.target.value);
                        }}
                        onKeyPress={(e) => { if (e.key === 'Enter') { props.handleChangeRacine(racine, props.index) }}}
                    />
                </div>
            </div>

            <div className={classes.inputType}>
                <InputLabel className={classes.subtitle}>{t('common:indice.indice-instructions')}</InputLabel>
                <div className={classes.inputField}>
                    <Input
                        autoComplete="new-password"
                        id="standard-index"
                        style={{ width: '100%', paddingLeft: 10 }}
                        fullWidth={true}
                        margin="none"
                        placeholder={t('common:indice.indice-ex')}
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            maxLength: 40
                        }}
                        type="number"
                        value={indice}
                        onChange={(event) => {
                            handleChangeIndice(event.target.value)
                        }}
                        onKeyPress={(e) => { if (e.key === 'Enter') { props.handleChangeIndice(indice, props.index) }}}
                    />
                </div>
            </div>

            {racine !== null && racine !== undefined && racine !== "" && indice !== null && indice !== undefined && indice !== "" ?
            <div className={classes.inputType}>
                <InputLabel className={classes.subtitle}>{t('common:indice.result')}</InputLabel>
                <span>{resultIndex()}</span>
            </div>
            : null}

            <div className={classes.checkboxContainer} onClick={() => {if(editable === true) handleChangeEditable(false); else handleChangeEditable(true)}}>
                <InputLabel className={classes.subtitle}>{t('common:indice.editable-instructions')}</InputLabel>
                {editable?
                <span
                    id="icon-more"
                    className={`material-icons`}
                    style={{ margin: 0, marginRight : '5px', color: colors.selectedMain,}}>
                    check_box
                </span>
                :
                <span
                    id="icon-more"
                    className={`material-icons`}
                    style={{ margin: 0, marginRight : '5px', color: colors.selectedMain,}}>
                    check_box_outline_blank
                </span>
                }
            </div>

        </div>
    </div>)
}
