import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Tab, Tabs } from "@material-ui/core"
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {toast} from "react-toastify";
import {Content} from "rsuite";
import {getCurrentEnvironnement, getModelToUpdate, getToken, removeModelToUpdate} from "../../utils/Common";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getFieldsRequest} from "../../actions/field";
import _, {camelCase, isEmpty, isNull, sortBy} from 'lodash'
import Images from "../../assets/themes/Images";
import {getModelUniqueRequest, postModelRequest, putModelRequest} from "../../actions/model";
import Input from "@material-ui/core/Input";
import FormatSelectConstructorComponent from "../../components/constructors/FormatSelectConstructorComponent";
import FormatFieldConstructorComponent from "../../components/constructors/FormatFieldConstructorComponent";
import { formatTypes} from "../../utils/Compatibility";
import { confirmAlert } from "react-confirm-alert";
import * as XLSX from "xlsx";
import FormatTableConstructorComponent from "../../components/constructors/FormatTableConstructorComponent";
import FormatPlugformConstructorComponent from "../../components/constructors/FormatPlugformConstructorComponent";
import FormatPluglinkConstructorComponent from "../../components/constructors/FormatPluglinkConstructorComponent";
import FormatFormulaConstructorComponent from "../../components/constructors/FormatFormulaConstructorComponent";
import FormatTableMultipleConstructorComponent from "../../components/constructors/FormatTableMultipleConstructorComponent";
import FormatExtDatanConstructorComponent from "../../components/constructors/FormatExtDatanConstructorComponent";
import FormatSignConstructorComponent from "../../components/constructors/FormatSignConstructorComponent";
import FormatSMailConstructorComponent from "../../components/constructors/FormatSMailConstructorComponent";
import FormatSMailValidMultiConstructorComponent from "../../components/constructors/FormatSMailValidMultiConstructorComponent";
import colors from '../../assets/themes/Colors';
import FormatPlugcodeInternalConstructorComponent from "../../components/constructors/FormatPlugcodeInternalConstructorComponent";
import FormatIndiceConstructorComponent from "../../components/constructors/FormatIndiceConstructorComponent";
import FormatDocumentConstructorComponent from "../../components/constructors/FormatDocumentConstructorComponent";
import {ColorCheckbox, PlugTooltip} from '../../components/custom/CustomComponents';
import ModalEditInfobulleComponent from '../../components/modals/ModalEditInfobulleComponent';
import {api} from '../../api';
import {trackPromise} from 'react-promise-tracker';
import {renderFormatText, isDefined} from "../../utils/utils";
import FormatIntDataMajConstructorComponent from "../../components/constructors/FormatIntDataMajConstructorComponent";
import FormatTableMultiplenConstructorComponent from '../../components/constructors/FormatTableMultiplenConstructorComponent';
import { AddInfoIcon, Asterisk, AsteriskOutline, EditInfoIcon, EditValueIcon, InfoIcon, InputIcon, SettingsIcon, VisibilityIndicator } from '../../components/custom/icons/CustomIcons';
import RenderEditDefaultValueComponent from '../../components/constructors/shared/RenderEditDefaultValueComponent';
import { fieldsWithDefaultValue, fieldsWithOptions } from '../../utils/Fields';
import { ContactMail, ChevronLeft, ChevronRight } from '@material-ui/icons';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
    root: {
        scrollMarginTop: 100,
        display: 'flex',
    },
    mandatory: {
        color: 'black',
    },
    notMandatory: {
        color: colors.grey,
        fontSize: 16,
    },
    isTrue: {
        color: '#58C3C1',
    },
    isFalse: {
        color: '#E54F62',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '80px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        //bottom: '-20px',
        //position: 'absolute',
        width: '100%'
    },
    openCloseTop: {
        top: '80px',
        //bottom: '-20px',
        //position: 'absolute',
        width: '100%'
    },
    openCloseButton: {
        float: 'right'
    },
    content: {
        width: '100%',
        backgroundColor: 'white',
        padding: '0px 20px 20px',
    },
    contentModel: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow: 'scroll',
        maxHeight: '100vh',
    },
    icon: {
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color: 'black'
    },
    iconActive: {
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color: 'blue'
    },
    gridHeader: {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement: {},
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldIcon: {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldIconSvg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    field: {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldLeft: {
        alignContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
    },
    fieldRightLeft: {
        alignContent: 'flex-end'
    },
    fullWidth: {
        width: '100%'
    },
    paper: {
        display: 'block !important',
        overflow: 'hidden !important'
    },
    paperAnchorDockedLeft: {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border: 0,
    },
    fieldRight: {
        textAlign: 'center'
    },
    scrollBar: {
        padding: '5px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            overflowX: 'hidden',
            display: "block",
            overflowY: 'scroll',
            width: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    stickyHeader: {
        position: 'sticky',
        top: -30,
        zIndex: 10,
        backgroundColor: colors.white,
        boxShadow: '0px 6px 6px -4px rgba(136, 136, 136, 0.12)',
        scrollMarginTop: '30px',
        '@media (max-width: 1024px)': {
            marginBottom: 100,
        }
    },
    formatContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '10px 10px 0px',
    },
    subFormatContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: '5px',
    },
    formOptionsCtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
        }
    },
    closeBtn: {},
    formTitleCtn: {
        flex: 1,
        padding: '8px 16px',
    },
    infobulle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '6px 10px',
        margin: 5,
        fontSize: '18px',
        borderRadius: 5,
        whiteSpace: 'pre-line',
    },
    paperPoper: {
        padding: theme.spacing(1),
    },
    paramBtn: {
        height: 'fit-content',
        color: colors.white,
        cursor: 'pointer',
        overflow: 'visible',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        backgroundColor: colors.mainButton,
        padding: 4,
        margin: '-7px auto 0px',
        zIndex: 1,
        '&:hover': {
            backgroundColor: colors.main,
            boxShadow: '0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 20px rgb(0 0 0 / 20%)'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 40
    },
    // Tabs
    mainField: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    tabsWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    tabsCtn: {
        '& .MuiTab-root': {
            textTransform: 'none !important',
        },
        '& .MuiTabs-indicator': {
            top: 0,
            backgroundColor: colors.main,
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: colors.main,
            fontWeight: 700,
        },
        '& div.MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'auto !important',
        }
    },
    tab: {
        // paddingTop: -20,
    },
    tabIcon: {
        height: 24,
        width: 24,
    },
    unselectedTab: {
        backgroundColor: colors.greyLight,
    },
    voidSpace: {
        flex: 1,
        background: `linear-gradient(90deg, ${colors.greyLight}, transparent)`,
        opacity: 0.7,
    },
}));

const specialFields = ['formula', 'plugform', 'pluglink', 'extdata', 'extdatan', 'tablemulti', 'tablemultin', 'smailvalidmulti', 'document', 'intdatamaj']
const notEncodable = ['qrcodeint'];

function RenderItemFormatComponent(props) {
    switch (props.field.format.toLowerCase()) {
        case "smail":
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    index={props.index} setUpdateModel={props.setUpdateModel}
                                                    updateModel={props.updateModel} update={props.update}
                                                    handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                    openSMail={props.openSMail}/>
        case "smailvalidmulti":
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    index={props.index} setUpdateModel={props.setUpdateModel}
                                                    updateModel={props.updateModel} update={props.update}
                                                    handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                    openSMail={props.openSMail}/>
        case "sign":
            return <FormatSignConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                   index={props.index} setUpdateModel={props.setUpdateModel}
                                                   updateModel={props.updateModel} update={props.update}/>
        case "select":
            return <FormatSelectConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                     index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop}
                                                     setUpdateModel={props.setUpdateModel}
                                                     updateModel={props.updateModel} update={props.update}/>
        case "indice":
            return <FormatIndiceConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                     index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop}
                                                     setUpdateModel={props.setUpdateModel}
                                                     updateModel={props.updateModel} update={props.update}/>
        case "table":
            return <FormatTableConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop}
                                                    setUpdateModel={props.setUpdateModel}
                                                    updateModel={props.updateModel} update={props.update}/>
        case "formula":
            return <FormatFormulaConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                      index={props.index} setUpdateModel={props.setUpdateModel}
                                                      updateModel={props.updateModel} update={props.update}/>
        case "plugform":
            return <FormatPlugformConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                       index={props.index} setUpdateModel={props.setUpdateModel}
                                                       updateModel={props.updateModel} update={props.update}/>
        case "qrcodeint":
            return <FormatPlugcodeInternalConstructorComponent field={props.field}
                                                               handleEditLabel={props.handleEditLabel}
                                                               index={props.index} setUpdateModel={props.setUpdateModel}
                                                               updateModel={props.updateModel} update={props.update}/>
        case "intdatamaj":
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                    openSMail={props.openSMail} index={props.index}/>
        //return <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update}/>
        case "extdata":
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                    openSMail={props.openSMail} index={props.index}/>
        //return <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update}/>
        case "extdatan":
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                    openSMail={props.openSMail} index={props.index}/>
        //return <FormatExtDatanConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update}/>
        case "pluglink":
            return <FormatPluglinkConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                       index={props.index} setUpdateModel={props.setUpdateModel}
                                                       updateModel={props.updateModel} update={props.update}
                                                       folderName={props.folderName}/>
        case "tablemulti":
        case "tablemultin":
        case 'document':
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    index={props.index}
                                                    handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                    openSMail={props.openSMail}/>
        //return <FormatTableMultipleConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update} folderName={props.nameModel}/>
        // return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail}/>
        default:
            return <FormatFieldConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                    index={props.index}/>
    }
}

function RenderSubItemFormatComponent(props) {
    const t = props.t
    const classes = props.classes
    const plugnoteCount = props.model.numdatas

    // Tabs
    const [tabNb, setTabNb] = useState(0)
    const handleChangeTab = (_, nb) => {
        setTabNb(nb);
        if (nb === 0) { // ouvrir le Drawer
            props.setOpenDrawer(true)
        }
    }

    let subitem = null
    switch (props.field.format.toLowerCase()) {
        case "smail":
            if (props.field.id === props.openSMail) {
                subitem = <FormatSMailConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                        handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                        openSMail={props.openSMail} index={props.index}
                                                        setUpdateModel={props.setUpdateModel}
                                                        updateModel={props.updateModel} update={props.update}
                                                        folderName={props.folderName}/>
            }
            break
        case "smailvalidmulti":
            if (props.field.id === props.openSMail) {
                return (
                    <div className="fullWidth">
                        <FormatSMailValidMultiConstructorComponent
                            field={props.field}
                            handleEditLabel={props.handleEditLabel}
                            handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail}
                            index={props.index} onChangeHandlerDrop={props.onChangeHandlerDrop}
                            setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update}
                            setOpenDrawer={props.setOpenDrawer}
                            folderName={props.folderName}
                            plugnoteCount={plugnoteCount}
                        />
                        {/* <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update} /> */}
                    </div>
                )
            }
            return null;
        case "tablemulti":
            if (props.field.id === props.openSMail) {
                return <FormatTableMultipleConstructorComponent
                    field={props.field} handleEditLabel={props.handleEditLabel} index={props.index}
                    onChangeHandlerDrop={props.onChangeHandlerDrop} setUpdateModel={props.setUpdateModel}
                    updateModel={props.updateModel} update={props.update} folderName={props.folderName}
                    plugnoteCount={plugnoteCount}
                />
            }
            return null;
        case "tablemultin":
            if (props.field.id === props.openSMail) {
                return (
                    <div className="fullWidth">
                        <FormatTableMultiplenConstructorComponent field={props.field}
                                                                  handleEditLabel={props.handleEditLabel}
                                                                  index={props.index}
                                                                  onChangeHandlerDrop={props.onChangeHandlerDrop}
                                                                  setUpdateModel={props.setUpdateModel}
                                                                  updateModel={props.updateModel} update={props.update}
                                                                  folderName={props.folderName}
                                                                  setOpenDrawer={props.setOpenDrawer}
                                                                  plugnoteCount={plugnoteCount}
                        />
                        {/* <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update} handleOpenCloseSmail={props.handleOpenCloseSmail}/> */}
                    </div>
                )
            }
            return null;
        // case "extdata":
        //     if(props.field.id === props.openSMail)
        //     return <FormatExtDataConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel} handleOpenCloseSmail={props.handleOpenCloseSmail} openSMail={props.openSMail} index={props.index} setUpdateModel={props.setUpdateModel} updateModel={props.updateModel} update={props.update}/>
        //     return null
        case "intdatamaj":
            if (props.field.id === props.openSMail)
                return <FormatIntDataMajConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                             index={props.index} setUpdateModel={props.setUpdateModel}
                                                             updateModel={props.updateModel} update={props.update}
                                                             handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                             openSMail={props.openSMail}/>
            return null
        case "extdata":
        case "extdatan":
            if (props.field.id === props.openSMail)
                return <FormatExtDatanConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                           handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                           openSMail={props.openSMail} index={props.index}
                                                           setUpdateModel={props.setUpdateModel}
                                                           updateModel={props.updateModel} update={props.update}/>
            return null
        case "document":
            if (props.field.id === props.openSMail) {
                return <FormatDocumentConstructorComponent field={props.field} handleEditLabel={props.handleEditLabel}
                                                           index={props.index} setUpdateModel={props.setUpdateModel}
                                                           updateModel={props.updateModel} update={props.update}
                                                           handleOpenCloseSmail={props.handleOpenCloseSmail}
                                                           plugnoteCount={plugnoteCount}
                />
            }
            return null;
        default:
            return null
    }
    if (fieldsWithDefaultValue.includes(props.field.format.toLowerCase())) {
        return (
            <Collapse in={props.field.id === props.openSMail} style={{ flex: 1 }}>
                <div className={classes.mainField}>
                    <div className={classes.tabsWrapper}>
                        <div className={classes.tabsCtn} >
                            <Tabs
                                onChange={handleChangeTab}
                                textColor="inherit"
                                value={tabNb}
                            >
                                <Tab
                                    icon={<InputIcon color={tabNb === 0 ? colors.main : colors.coal} height={24} width={24} />}
                                    disableRipple
                                    label={'1. ' + t('helper.default-value')}
                                    className={`${classes.tab} ${tabNb !== 0 && classes.unselectedTab}`} />
                                <Tab
                                    disableRipple
                                    icon={<ContactMail />}
                                    label={'2. ' + t('smailvalidmulti.config-email')}
                                    className={`${classes.tab} ${tabNb !== 1 && classes.unselectedTab}`} />
                            </Tabs>
                        </div>
                        <span className={classes.voidSpace} />
                    </div>
                </div>
                {
                    tabNb === 0 &&
                    <RenderEditDefaultValueComponent
                        field={props.field}
                        handleChangeDefaultValue={(value) => props.handleChangeDefaultValue(props.index, value)}
                        handleChangeDefaultValueEdit={(value) => props.handleChangeDefaultValueEdit(props.index, value)}
                    />
                }
                {
                    tabNb === 1 &&
                    subitem
                }
            </Collapse>
        )
    } else {
        return subitem
    }
}

function RenderPopUpItem(props) {
    const classes = useStyles();
    const [field,] = useState(props.field);
    const handleClickPopUpLink = (event, link) => {
        props.handleClickPoper(event, null);
        window.open("https://www.plugcodes.com?code=" + link, "_blank")
    }

    if (!isNull(props.open)) {
        return (
            <div className={classes.paperPoper}>
                {
                    field?.pnpfcodefr && field.pnpfcodefr !== "" &&
                    <PlugTooltip title={field.pnpfcodefr}>
                        <span id="icon-more" className="font_size_15 cursor text-span-round" onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleClickPopUpLink(event, field.pnpfcodefr)
                        }}>
                            FR
                        </span>
                    </PlugTooltip>
                }
                {
                    field?.pnpfcodeen && field.pnpfcodeen !== "" &&
                    <PlugTooltip title={field.pnpfcodeen}>
                        <span id="icon-more" className="font_size_15 cursor text-span-round" onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleClickPopUpLink(event, field.pnpfcodeen)
                        }}>
                            EN
                        </span>
                    </PlugTooltip>
                }
                {
                    field?.pnpfcodenl && field.pnpfcodenl !== "" &&
                    <PlugTooltip title={field.pnpfcodenl}>
                        <span id="icon-more" className="font_size_15 cursor text-span-round" onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleClickPopUpLink(event, field.pnpfcodenl)
                        }}>
                            NL
                        </span>
                    </PlugTooltip>
                }
            </div>
        )

    } else return null;

}

const ErrorToastMessage = ({title, message}) => (
    <div>
        <h6> {title}</h6>
        <p> {message} </p>
    </div>
);

function purgeFormatoptions (opt) {
    let _options = {}
    if (typeof opt === "object" && !Array.isArray(opt)) {
        Object.keys(opt).forEach(key => {
            if (key !== 'alignment' && key !== 'setup') {
                _options[key] = opt[key]
            } else if (key === 'setup') {
                let _setup = {}
                Object.keys(opt.setup).forEach(clef => {
                    if (clef !== 'rgpd') {
                        _setup[clef] = opt.setup[clef]
                    }
                })
                _options.setup = _setup
            }
        })
    }
    // console.log('Purged', _options)
    return _options
}

function Model(props) {
    // console.log('Model got props', props)
    const classes = useStyles();
    const {t, i18n} = useTranslation(['common', 'models']);
    const langue = i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase() === 'fr' ? '' : 'En'
    
    let model = props.model
    const structureEditable = model.isStructureEditable === 1

    const labels = Object.keys(model).filter((key) => {
        const regex = /field[0-9]+label/gi;
        return regex.test(key) && model[key] != null;
    })

    // console.log('Model', model)

    let fields = labels.map((label, index) => {
        let format = model['field' + (index + 1) + 'format']
        let formatoptions = model['field' + (index + 1) + 'formatoptions'] || {}

        if (typeof formatoptions !== 'object') {
            formatoptions = JSON.parse(formatoptions)
        }
        if (Array.isArray(formatoptions)) {
            let lines = formatoptions
            formatoptions = {
                lines: lines,
                mandatory: false,
                rgpd: false,
                setup: {
                    printable: true,
                    comment: '',
                },
            }
        }
        // console.log('formatoptions', formatoptions)
        
        let _layout = isDefined(formatoptions.setup?.layout) ? formatoptions.setup.layout : {}
        let _options = {
            mandatory: formatoptions.mandatory || false, 
            setup: {
                printable: formatoptions.setup?.printable || true,
                comment: formatoptions.setup?.comment || '',
            },
            rgpd: formatoptions.rgpd || false,
            ...purgeFormatoptions(formatoptions),
        }
        if (formatoptions.hasOwnProperty('lines')) {
            _options.lines = Array.isArray(formatoptions.lines) ? formatoptions.lines : []
        }
        if (formatoptions.hasOwnProperty('alignment')) {
            _options.setup['layout'] = {
                ..._layout,
                alignment: formatoptions.alignment,
            }
        }
        formatoptions = _options

        if (format === "document" || format.includes('tablemulti') || format === 'smailvalidmulti') {
            // console.log('reconstitué', label, formatoptions, _options)
        }

        if (model['field' + (index + 1) + 'format'] === "select") {
            if (typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0) {
                formatoptions.presel = formatoptions.presel.toString().split(";")
            } else {
                formatoptions.presel = [];
            }
        }

        if (model['field' + (index + 1) + 'format'] === "table") {
            if (typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0) {
                formatoptions.presel = formatoptions.presel.toString().split(";")
            } else {
                formatoptions.presel = [];
            }
        }

        if (model['field' + (index + 1) + 'format'] === "tablemulti") {
            let _formatOptions = {};
            if (typeof formatoptions !== "undefined" && formatoptions !== "" && formatoptions !== "[]" && formatoptions.length !== 0 && formatoptions.lines === undefined) {
                _formatOptions.lines = formatoptions;
            } else if (typeof formatoptions.lines !== "undefined" && formatoptions.lines !== "" && formatoptions.lines !== "[]" && formatoptions.lines.length !== 0 && formatoptions.lines !== undefined) {
                _formatOptions.lines = formatoptions.lines;
            } else {
                _formatOptions.lines = [];
            }

            if (formatoptions?.infos_bules !== undefined) {
                _formatOptions.infoBulle = formatoptions.infos_bules || formatoptions.infoBulle;
            }
            _formatOptions.alignment = formatoptions.alignment || 'left';
            formatoptions = _formatOptions
        }

        if (model['field' + (index + 1) + 'format'] === "document") {
            if (typeof formatoptions.lines !== "undefined" && formatoptions.lines !== "" && formatoptions.lines !== "[]" && formatoptions.lines.length !== 0) {
                formatoptions.lines = formatoptions.lines;
            } else {
                formatoptions.lines = [];
            }

            if (typeof formatoptions.import_from_ftp !== "undefined") {
                formatoptions.import_from_ftp = formatoptions.import_from_ftp;
            } else {
                formatoptions.import_from_ftp = "";
            }

            if (typeof formatoptions.import_annexes !== "undefined") {
                formatoptions.import_annexes = formatoptions.import_annexes;
            } else {
                formatoptions.import_annexes = false;
            }
            formatoptions.alignment = formatoptions.alignment || 'left';
        }

        if (model['field' + (index + 1) + 'format'] === "tablemultin") {
            if (typeof formatoptions.lines !== "undefined" && formatoptions.lines !== "" && formatoptions.lines !== "[]" && formatoptions.lines.length !== 0) {
                formatoptions.lines = formatoptions.lines.map(entry => {
                    let line = {};
                    Object.keys(entry).forEach(key => {
                        if (key === 'info_bulles') {
                            line.infoBulle = entry[key];
                        } else if (key === 'rowfontsize') {
                            line.fontSize = entry[key];
                        } else if (key === 'headerlength') {
                            line.colWidth = entry[key];
                        } else {
                            line[key] = entry[key];
                        }
                    });
                    return line;
                });
            } else {
                formatoptions.lines = [];
            }

            if (typeof formatoptions.ref !== "undefined") {
                formatoptions.ref = formatoptions.ref;
            } else {
                formatoptions.ref = "";
            }

            if (typeof formatoptions.importauto !== "undefined") {
                formatoptions.importauto = formatoptions.importauto;
            } else {
                formatoptions.importauto = false;
            }


            if (typeof formatoptions.query !== "undefined") {
                formatoptions.query = formatoptions.query;
            } else {
                formatoptions.query = "";
            }


            if (typeof formatoptions.columns !== "undefined") {
                formatoptions.columns = formatoptions.columns;
            } else {
                formatoptions.columns = "";
            }


            if (typeof formatoptions.column !== "undefined") {
                formatoptions.column = formatoptions.column;
            } else {
                formatoptions.column = "";
            }


            if (typeof formatoptions.plugform !== "undefined") {
                formatoptions.plugform = formatoptions.plugform;
            } else {
                formatoptions.plugform = "";
            }

            if (typeof formatoptions.dest !== "undefined") {
                formatoptions.dest = formatoptions.dest;
            } else {
                formatoptions.dest = "";
            }
        }

        if (model['field' + (index + 1) + 'format'] === "smail") {
            if (typeof formatoptions !== "undefined" && formatoptions !== "" && formatoptions !== "[]" && formatoptions.length !== 0) {
                formatoptions = formatoptions;
            } else {
                formatoptions = [];
            }
        }

        if (model['field' + (index + 1) + 'format'] === "indice") {
            if (typeof formatoptions !== "undefined" && formatoptions !== "" && formatoptions !== "[]" && formatoptions.length !== 0) {
                formatoptions = formatoptions;
            } else {
                formatoptions = [];
            }
        }

        if (model['field' + (index + 1) + 'format'] === "smailvalidmulti") {
            if (typeof formatoptions !== "undefined" && formatoptions !== "" && formatoptions !== "[]" && formatoptions.length !== 0) {
                formatoptions = formatoptions;
            } else {
                formatoptions = [];
            }
        }

        if (format === "document" || format.includes('tablemulti') || format === 'smailvalidmulti') {
            if (!isDefined(formatoptions.setup?.layout)) {
                if (!isDefined(formatoptions.setup)) {
                    formatoptions.setup = {
                        layout: {
                            alignment: isDefined(formatoptions.alignment) ? formatoptions.alignment : 'left',
                        },
                        rowLimit: 0,
                    }
                    // console.log('Formatoptions corrected', formatoptions)
                }
            }
        }

        if (structureEditable) {
            return {
                newField: model?.numdatas === 0 || false,
                dragid: 'item-' + index,
                key: index + 1,
                id: index + 1,
                position: formatTypes[model['field' + (index + 1) + 'format'].toUpperCase()],
                indice: formatTypes[model['field' + (index + 1) + 'format'].toUpperCase()],
                isEncodable: !!model['field' + (index + 1) + 'encodable'] && model['field' + (index + 1) + 'encodable'] === 1,
                isVisible: !!model['field' + (index + 1) + 'visible'] && model['field' + (index + 1) + 'visible'] === 1,
                label: model['field' + (index + 1) + 'label'],
                format: model['field' + (index + 1) + 'format'],
                options: {
                    mandatory: !!model['field' + (index + 1) + 'mandatory'],
                    ...formatoptions
                },
                formatoptions: formatoptions
            }
        } else {
            return {
                newField: false,
                dragid: 'item-' + index,
                key: index + 1,
                id: index + 1,
                position: formatTypes[model['field' + (index + 1) + 'format'].toUpperCase()],
                indice: formatTypes[model['field' + (index + 1) + 'format'].toUpperCase()],
                isEncodable: !!model['field' + (index + 1) + 'encodable'] && model['field' + (index + 1) + 'encodable'] === 1,
                isVisible: !!model['field' + (index + 1) + 'visible'] && model['field' + (index + 1) + 'visible'] === 1,
                label: model['field' + (index + 1) + 'label'],
                format: model['field' + (index + 1) + 'format'],
                options: {
                    mandatory: !!model['field' + (index + 1) + 'mandatory'],
                    ...formatoptions
                },
                formatoptions: formatoptions
            }
        }
    })

    let title = model.label
    let visibleField = typeof model.fieldEmptyVisible !== "undefined" ? model.fieldEmptyVisible : false;
    let authoriseExternalDbInPfField = typeof model.authoriseExternalDbInPf !== "undefined" ? model.authoriseExternalDbInPf : false;

    const [openDrawer, setOpenDrawer] = useState(true)
    const environment = getCurrentEnvironnement()

    const [updateModel, setUpdateModel] = useState(fields)
    const [selectedField, setSelectedField] = useState(null)
    const [nameModel, setNameModel] = useState(title)
    const [fieldEmptyVisible, setFieldEmptyVisible] = useState(visibleField);
    const [authorizeExternalDbInPf, setAuthorizeExternalDbInPf] = useState(authoriseExternalDbInPfField);
    const [authorizeInfobulle, setAuthorizeInfobulle] = useState(false)
    const [authorizeShowPlugLinkData, setAuthorizeShowPlugLinkData] = useState(typeof model.authoriseShowPlugLinkData !== "undefined" ? model.authoriseShowPlugLinkData : false);

    const [openSMail, setOpenSMail] = useState(-1);
    const [hasInfobulles, setHasInfobulles] = useState(fields.some(field => (field.formatoptions?.infos_bules === true)))
    const [infobulles, setInfobulles] = useState(null);
    const [openEditInfobulle, setOpenEditInfobulle] = useState(false);

    let regexp = new RegExp('^[A-Z]{2}[0-9]{2}$');
    let test = regexp.test(model.clef);

    const [modelKeyCanChange, setModelKeyCanChange] = useState(test);
    const [modelKeyDoChange, setModelKeyDoChange] = useState(false);
    const [openPoper, setOpenPoper] = useState(null)
    const [openDefaultEdit, setOpenDefaultEdit] = useState(null)
    const [missingLabel, setMissingLabel] = useState(null)

    const defaultTablemultiSetup = {
        comment: '',
        layout: {
            alignment: 'full',
            showHeader: true,
            showRowNb: true,
        },
        printable: true,
        rowLimit: 0,
    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleListItemClick = (event, fieldSelected) => {
        if (updateModel.length > 0 && updateModel[updateModel.length - 1].label === '') {
            setMissingLabel(updateModel.length - 1)
            toast.info(t('tablemulti.missing-label'),
                {
                    autoClose: 3000,
                    hideProgressBar: true,
                    bodyClassName: classes.warnToast,
                    className: classes.warnToast,
                    toastClassName: classes.warnToast,
                })
        }
        else if (updateModel.length < 15) {
            let options = {};
            // Récupération du dernier item, avec le tri des valeurs en fonction de la clé
            let lastField = _.orderBy(Object.values(updateModel), 'key', 'desc')[0];

            // Si c'est le premier champ on initialise à 0
            if (!lastField) lastField = {key: 0, id: 0};

            let newItem
            switch (fieldSelected.identifiant) {
                case 'select':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        position: fieldSelected.position,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            visibledefault: false,
                            mandatory: false,
                            editable: false,
                            multiselect: false,
                            presel: [],
                            setup: {
                                printable: true,
                                comment: '',
                            },
                        },
                        newField: true,
                    }
                    break
                case 'table':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            editable: false,
                            infos_bules: false,
                            mandatory: false,
                            multiselect: false,
                            presel: [],
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true,
                            },
                            visibledefault: false,
                        },
                        position: fieldSelected.position,
                    }
                    break
                case 'plugform':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        position: fieldSelected.position,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            mandatory: false,
                            editable: false,
                            setup: {
                                printable: true,
                                comment: '',
                            },
                        },
                        newField: true,
                    }
                    break
                case 'qrcodeint':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        position: fieldSelected.position,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            mandatory: false,
                            clef: "",
                            comment: "",
                            setup: {
                                printable: true,
                                comment: '',
                            },
                        },
                        newField: true,
                    }
                    break
                case 'formula':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            editable: false,
                            formula: null,
                            infos_bules: false,
                            mandatory: false,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position,
                    }
                    break
                case 'indice':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            editable: false,
                            indice: "",
                            infos_bules: false,
                            mandatory: false,
                            racine: "",
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true,
                            },
                        },
                        position: fieldSelected.position,
                    }
                    break
                case 'sign':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            infos_bules: false,
                            mandatory: false,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            },
                            template: "2001"
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'smail':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            auto: "N",
                            body: "",
                            cc: "",
                            from: "",
                            infos_bules: false,
                            mandatory: false,
                            note: "N",
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            },
                            template: "",
                            title: ""
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'smailvalidmulti':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: {
                                comment: '',
                                layout: {
                                    alignment: 'full',
                                    showHeader: true,
                                    showRowNb: true
                                },
                                printable: true,
                                rowLimit: 0
                            }
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            alignment: 'left',
                            body: "",
                            cc: "",
                            column: "",
                            columns: "",
                            dest: "",
                            from: "",
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            note: "N",
                            plugform: "",
                            query: "LIKE",
                            ref: "",
                            rgpd: false,
                            setup: defaultTablemultiSetup,
                            template: "",
                            title: ""
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'tablemulti':
                    newItem ={
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: defaultTablemultiSetup
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            alignment: 'left',
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            rgpd: false,
                            setup: defaultTablemultiSetup
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'tablemultin':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: defaultTablemultiSetup
                        },
                        id: lastField.id + 1,
                        importauto: false,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            column: "",
                            columns: "",
                            dest: "",
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            plugform: "",
                            query: "LIKE",
                            ref: "",
                            rgpd: false,
                            setup: defaultTablemultiSetup
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'document':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: defaultTablemultiSetup
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            import_annexes: false,
                            import_from_ftp: false,
                            infos_bules: false,
                            lines: [],
                            mandatory: false,
                            rgpd: false,
                            setup: defaultTablemultiSetup
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'extdata':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            column: null,
                            columns: null,
                            dest: null,
                            field: null,
                            infos_bules: false,
                            mandatory: false,
                            query: null,
                            ref: null,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'extdatan':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            column: null,
                            columns: null,
                            dest: null,
                            infos_bules: false,
                            mandatory: false,
                            plugform: null,
                            query: null,
                            ref: null,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position
                    }
                    break
                case 'intdatamaj':
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        format: fieldSelected.identifiant,
                        formatoptions: {
                            lines: [],
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        id: lastField.id + 1,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        key: lastField.key + 1,
                        label: "",
                        newField: true,
                        options: {
                            dest: null,
                            dest_infos: null,
                            display_search: false,
                            infos_bules: false,
                            mandatory: false,
                            plugform: null,
                            rgpd: false,
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        position: fieldSelected.position
                    }
                    break
                default:
                    newItem = {
                        dragid: 'item-' + lastField.id,
                        key: lastField.key + 1,
                        id: lastField.id + 1,
                        label: "",
                        position: fieldSelected.position,
                        indice: fieldSelected.indice,
                        isEncodable: true,
                        isVisible: true,
                        format: fieldSelected.identifiant,
                        options: {
                            rgpd: false,
                            infos_bules: false,
                            mandatory: false,
                            ...options || {},
                            setup: {
                                comment: '',
                                printable: true
                            }
                        },
                        newField: true,
                    }
                    break
            }

            setUpdateModel(oldArray => [...oldArray, newItem])

            // if (fieldSelected.identifiant === 'select') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         isEncodable: true,
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         isVisible: true,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             visibledefault: false,
            //             mandatory: false,
            //             editable: false,
            //             multiselect: false,
            //             presel: [],
            //             setup: {
            //                 printable: true,
            //                 comment: '',
            //             },
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'table') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         isVisible: true,
            //         isEncodable: true,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             visibledefault: false,
            //             mandatory: false,
            //             editable: false,
            //             multiselect: false,
            //             presel: [],
            //             setup: {
            //                 printable: true,
            //                 comment: '',
            //             },
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'plugform') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         isEncodable: true,
            //         isVisible: true,
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             editable: false,
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'qrcodeint') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         isEncodable: false,
            //         isVisible: true,
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             clef: "",
            //             comment: "",
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'formula') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         isEncodable: true,
            //         isVisible: true,
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             editable: false,
            //             multiselect: false,
            //             formula: null,
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'indice') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         isEncodable: true,
            //         isVisible: true,
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             editable: false,
            //             indice: "",
            //             racine: "",
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'sign') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             template: "9001",
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'smail') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             template: "",
            //             cc: "",
            //             from: "",
            //             title: "",
            //             body: "",
            //             note: "N",
            //             auto: "N"
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'smailvalidmulti') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             alignment: 'left',
            //             infos_bules: false,
            //             mandatory: false,
            //             template: "",
            //             cc: "",
            //             from: "",
            //             title: "",
            //             body: "",
            //             note: "N",
            //             ref: "",
            //             query: "LIKE",
            //             columns: "",
            //             column: "",
            //             plugform: "",
            //             dest: "",
            //             lines: [],
            //         },
            //         formatoptions: {
            //             lines: [],
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'tablemulti') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             alignment: 'left',
            //             infos_bules: false,
            //             mandatory: false,
            //             lines: [],
            //         },
            //         formatoptions: {
            //             lines: [],
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'tablemultin') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         importauto: false,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             ref: "",
            //             query: "LIKE",
            //             columns: "",
            //             column: "",
            //             plugform: "",
            //             dest: "",
            //             lines: [],
            //         },
            //         formatoptions: {
            //             lines: [],
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'document') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             import_annexes: false,
            //             import_from_ftp: false,
            //             lines: [],
            //         },
            //         formatoptions: {
            //             lines: [],
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'extdata') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             ref: null,
            //             query: null,
            //             columns: null,
            //             column: null,
            //             field: null,
            //             dest: null,
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'extdatan') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             ref: null,
            //             query: null,
            //             columns: null,
            //             column: null,
            //             plugform: null,
            //             dest: null,
            //         },
            //         newField: true,
            //     }]);
            // } else if (fieldSelected.identifiant === 'intdatamaj') {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         format: fieldSelected.identifiant,
            //         isVisible: true,
            //         isEncodable: true,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             display_search: false,
            //             plugform: null,
            //             dest: null,
            //             dest_infos: null
            //         },
            //         formatoptions: {
            //             lines: [],
            //         },
            //         newField: true,
            //     }]);
            // } else {
            //     setUpdateModel(oldArray => [...oldArray, {
            //         dragid: 'item-' + lastField.id,
            //         key: lastField.key + 1,
            //         id: lastField.id + 1,
            //         label: "",
            //         position: fieldSelected.position,
            //         indice: fieldSelected.indice,
            //         isEncodable: true,
            //         isVisible: true,
            //         format: fieldSelected.identifiant,
            //         options: {
            //             rgpd: false,
            //             infos_bules: false,
            //             mandatory: false,
            //             ...options || {}
            //         },
            //         newField: true,
            //     }]);
            // }
            setModelKeyDoChange(true)
        }
    }

    const handleDeleteField = (fieldToDelete) => {

        const temp = updateModel.filter(item => fieldToDelete !== item);
        setUpdateModel(temp);
    }

    const handleChangeFolderName = (event, value) => {
        setNameModel(event.target.value);
        setModelKeyDoChange(true);
    };

    const handleToggleMandatoryField = (field, position) => {
        if (structureEditable) {
            const temp = updateModel.map((item, index) => {
                if (field === item && position === index) {
                    if (item.isEncodable === false && !item.options.mandatory === true) {
                        item.options.mandatory = !item.options.mandatory;
                        item.isEncodable = true;
                    } else if (item.isEncodable === true && !item.options.mandatory === false) {
                        item.options.mandatory = !item.options.mandatory;

                    } else {
                        item.options.mandatory = !item.options.mandatory;
                    }

                }
                return item;
            });
            setUpdateModel(temp);
        }
    }

    const handleToggleVisiblePDF = (field, position) => {
        const temp = [...updateModel]
        if (temp[position] === field) {
            let _field = temp[position]
            if (isDefined(_field.options?.setup?.printable)) {
                _field.options.setup.printable = !_field.options.setup.printable
            } else {
                _field.options.setup.printable = true
            }
            temp[position] = _field
            setUpdateModel(temp)
        }
    }

    const handleEditableField = (field, position) => {
        const temp = updateModel.map((item, index) => {

            if (field === item && position === index) {
                if (!item.isEncodable === false && item.options.mandatory === true) {
                    item.isEncodable = !item.isEncodable;
                    item.options.mandatory = false
                } else {
                    item.isEncodable = !item.isEncodable;
                }
            }
            return item;
        });
        setUpdateModel(temp);
    }

    const handleVisibleField = (field, position) => {
        const temp = updateModel.map((item, index) => {

            if (field === item && position === index) {
                item.isVisible = !item.isVisible;
            }
            return item;
        });
        setUpdateModel(temp);
    }

    const handleToggleRGPD = (field, position) => {
        const temp = [...updateModel]
        if (temp[position] === field) {
            let _field = temp[position]
            // console.log('rgpd? :', _field.options.rgpd)
            if (isDefined(_field.options?.rgpd)) {
                _field.options.rgpd = !_field.options.rgpd
            } else {
                _field.options.rgpd = true
            }
            temp[position] = _field
            setUpdateModel(temp)
        }
    }

    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */
    const handleEditLabel = (value, field, index) => {
        let model = [...updateModel];
        model[index].label = value;
        setUpdateModel(model);
        setModelKeyDoChange(true);
    };

    const handleOpenCloseSmail = (index) => {
        if (index === openSMail) {
            setOpenSMail(-1);
            if (!openDrawer) handleDrawerOpen()
        } else {
            setOpenSMail(index);
            if (!openDrawer) handleDrawerOpen()
        }
    }

    const handleSaveStayModel = () => {
        // Si la liste des champs est vide on affiche une pop-up
        if (nameModel.length === 0) {
            toast(<ErrorToastMessage title={t('global.error')}
                                     message={t('model-create-update.model-form-title-empty')}/>)
        } else if (updateModel.length === 0) {
            toast(<ErrorToastMessage title={t('global.error')}
                                     message={t('model-create-update.model-form-fields-empty')}/>)

        }
        // Si il manque un libellé sur un champ on affiche une pop-up
        else if (!isEmpty(updateModel.find((field) => isEmpty(field.label) && (field.format !== "delete-field")))) {
            toast(<ErrorToastMessage title={t('model-create-update.model-form-fields-no-name-title')}
                                     message={t('model-create-update.model-form-fields-no-name')}/>)
        } else {
            let modelToUpdate = _.cloneDeep(props.model);
            let newFieldsList = [];
            let copyUpdateModel = _.cloneDeep(updateModel);
            copyUpdateModel.forEach((item, index) => {
                newFieldsList.push(item);
            });

            newFieldsList.forEach((field, index) => {
                modelToUpdate["field" + (index + 1) + "label"] = field.label
                modelToUpdate["field" + (index + 1) + "format"] = field.format
                modelToUpdate["field" + (index + 1) + "mandatory"] = field.options.mandatory
                modelToUpdate["field" + (index + 1) + "encodable"] = field.isEncodable
                modelToUpdate["field" + (index + 1) + "visible"] = field.isVisible

                if (field.format === "select" || field.format === 'indice') {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "table") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "plugform") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "qrcodeint") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "intdatamaj") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "extdata") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "extdatan") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "pluglink") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "formula") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "tablemulti") {
                    let options = field.options;
                    options.lines = field.formatoptions?.lines ? field.formatoptions.lines : field.formatoptions;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else if (field.format === "tablemultin") {
                    let options = field.options;
                    options.lines = field.formatoptions.lines;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else if (field.format === "document") {
                    let options = field.options;
                    options.lines = field.formatoptions.lines;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else if (field.format === "sign") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "smail") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "smailvalidmulti") {
                    let options = field.options;
                    options.lines = field.formatoptions.lines;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = [];
                }

                if (field.format.includes("tablemulti") || field.format === "smailvalidmulti" || field.format === "document") {
                    let options = field.options;
                    if (options.hasOwnProperty('alignment')) {
                        modelToUpdate["field" + (index + 1) + "formatoptions"].alignment = options.alignment;
                    }
                }
            });

            let updatedModel = {};
            Object.keys(modelToUpdate).map(fieldName => {
                if (!/field/.test(fieldName)) {
                    updatedModel[fieldName] = modelToUpdate[fieldName]
                }
            });

            //console.log('updated model', updatedModel, newFieldsList)

            newFieldsList.forEach((field, index) => {
                updatedModel["field" + (index + 1) + "label"] = modelToUpdate["field" + (index + 1) + "label"]
                updatedModel["field" + (index + 1) + "format"] = modelToUpdate["field" + (index + 1) + "format"]
                updatedModel["field" + (index + 1) + "mandatory"] = modelToUpdate["field" + (index + 1) + "mandatory"]
                updatedModel["field" + (index + 1) + "visible"] = modelToUpdate["field" + (index + 1) + "visible"]
                updatedModel["field" + (index + 1) + "encodable"] = modelToUpdate["field" + (index + 1) + "encodable"]

                if (field.format === "select" || field.format === 'indice') {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                    if (field.format === "select" && updatedModel["field" + (index + 1) + "formatoptions"].presel.length > 0) {
                        updatedModel["field" + (index + 1) + "formatoptions"].presel = updatedModel["field" + (index + 1) + "formatoptions"].presel.join(";");
                    }
                } else if (field.format === "table") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                    if (field.format === "table" && updatedModel["field" + (index + 1) + "formatoptions"].presel.length > 0) {
                        updatedModel["field" + (index + 1) + "formatoptions"].presel = updatedModel["field" + (index + 1) + "formatoptions"].presel.join(";");
                    }
                } else if (field.format === "plugform") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "qrcodeint") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "intdatamaj") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "extdata") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "extdatan") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "pluglink") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "formula") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "tablemulti") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "tablemultin") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "document") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "sign") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "smail") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "smailvalidmulti") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else {
                    updatedModel["field" + (index + 1) + "formatoptions"] = field.options
                }
            });

            updatedModel.label = nameModel;
            updatedModel.hash = modelToUpdate.hash;
            updatedModel.fieldEmptyVisible = fieldEmptyVisible;
            updatedModel.authoriseExternalDbInPf = authorizeExternalDbInPf;
            updatedModel.authoriseShowPlugLinkData = authorizeShowPlugLinkData;

            if (modelKeyCanChange && modelKeyDoChange && structureEditable) {
                updatedModel.clef = "new";
            }

            console.log('saving model... ', updatedModel)
            props.attemptUpdateModel(updatedModel, environment.userhash, props.updateModel);
        }
    }

    const handleSafeQuitModel = () => {
        handleSaveModel();
    }

    const handleCancelExitModel = () => {
        if (props.back.length > 0) {
            props.history.push(props.back);
        } else {
            props.history.push("/models");
        }

        removeModelToUpdate();
        // !!! No alert needed !!!
        // confirmAlert({
        //     title: t('global.plugnotes'),
        //     message: t('global.exit-cancel-model-clef'),
        //     buttons: [
        //         {
        //             label: t('global.save'),
        //             onClick: () => {
        //                 handleSaveModel();
        //             }
        //         },
        //         {
        //             label: t('global.exit-with-no-save'),
        //             onClick: () => {
        //                 if(props.back.length > 0){
        //                     props.history.push(props.back);
        //                 }else{
        //                     props.history.push("/models");
        //                 }
        //                 removeModelToUpdate();
        //             }
        //         },
        //     ]
        // });
    }

    const handleSaveModel = () => {
        // Si la liste des champs est vide on affiche une pop-up
        if (nameModel.length === 0) {
            toast(<ErrorToastMessage title={t('global.error')}
                                     message={t('model-create-update.model-form-title-empty')}/>)
        } else if (updateModel.length === 0) {
            toast(<ErrorToastMessage title={t('global.error')}
                                     message={t('model-create-update.model-form-fields-empty')}/>)

        }
        // Si il manque un libellé sur un champ on affiche une pop-up
        else if (!isEmpty(updateModel.find((field) => isEmpty(field.label) && (field.format !== "delete-field")))) {
            toast(<ErrorToastMessage title={t('model-create-update.model-form-fields-no-name-title')}
                                     message={t('model-create-update.model-form-fields-no-name')}/>)
        } else {
            let modelToUpdate = _.cloneDeep(props.model);
            let newFieldsList = [];
            let copyUpdateModel = _.cloneDeep(updateModel);

            copyUpdateModel.forEach((item, index) => {
                newFieldsList.push(item);
            });

            newFieldsList.forEach((field, index) => {
                modelToUpdate["field" + (index + 1) + "label"] = field.label
                modelToUpdate["field" + (index + 1) + "format"] = field.format
                modelToUpdate["field" + (index + 1) + "colWidth"] = field.colWidth
                modelToUpdate["field" + (index + 1) + "mandatory"] = field.options.mandatory
                modelToUpdate["field" + (index + 1) + "encodable"] = field.isEncodable
                modelToUpdate["field" + (index + 1) + "visible"] = field.isVisible

                if (field.format === "select" || field.format === 'indice') {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options
                } else if (field.format === "table") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "plugform") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "qrcodeint") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "intdatamaj") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "extdata") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "extdatan") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "pluglink") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "formula") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "tablemulti") {
                    let options = field.options;
                    options.lines = field.formatoptions?.lines ? field.formatoptions.lines : field.formatoptions;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else if (field.format === "tablemultin") {
                    let options = field.options;
                    options.lines = field.formatoptions.lines;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options
                } else if (field.format === "document") {
                    let options = field.options;
                    options.lines = field.formatoptions.lines;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else if (field.format === "sign") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "smail") {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else if (field.format === "smailvalidmulti") {
                    let options = field.options;
                    options.lines = field.formatoptions.lines;
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = options;
                } else {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options || { }
                }

                if (field.format.includes("tablemulti") || field.format === "smailvalidmulti" || field.format === "document") {
                    let options = field.options;
                    if (options.hasOwnProperty('alignment')) {
                        modelToUpdate["field" + (index + 1) + "formatoptions"].alignment = options.alignment;
                    }
                }
            });

            let updatedModel = {};
            Object.keys(modelToUpdate).map(fieldName => {
                if (!/field/.test(fieldName)) {
                    updatedModel[fieldName] = modelToUpdate[fieldName]
                }
            });

            newFieldsList.forEach((field, index) => {
                updatedModel["field" + (index + 1) + "label"] = modelToUpdate["field" + (index + 1) + "label"]
                updatedModel["field" + (index + 1) + "format"] = modelToUpdate["field" + (index + 1) + "format"]
                updatedModel["field" + (index + 1) + "mandatory"] = modelToUpdate["field" + (index + 1) + "mandatory"]
                updatedModel["field" + (index + 1) + "visible"] = modelToUpdate["field" + (index + 1) + "visible"]
                updatedModel["field" + (index + 1) + "encodable"] = modelToUpdate["field" + (index + 1) + "encodable"]

                if (field.format === "select" || field.format === 'indice') {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                    if (field.format === "select" && updatedModel["field" + (index + 1) + "formatoptions"].presel.length > 0) {
                        updatedModel["field" + (index + 1) + "formatoptions"].presel = updatedModel["field" + (index + 1) + "formatoptions"].presel.join(";");
                    }
                } else if (field.format === "table") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                    if (field.format === "table" && updatedModel["field" + (index + 1) + "formatoptions"].presel.length > 0) {
                        updatedModel["field" + (index + 1) + "formatoptions"].presel = updatedModel["field" + (index + 1) + "formatoptions"].presel.join(";");
                    }
                } else if (field.format === "plugform") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "qrcodeint") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "intdatamaj") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "extdata") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "extdatan") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "pluglink") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "formula") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "tablemulti") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "tablemultin") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "document") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "sign") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "smail") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else if (field.format === "smailvalidmulti") {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"]
                }
            });

            updatedModel.label = nameModel;
            updatedModel.hash = modelToUpdate.hash;
            updatedModel.fieldEmptyVisible = fieldEmptyVisible;
            updatedModel.authoriseExternalDbInPf = authorizeExternalDbInPf;
            updatedModel.authoriseShowPlugLinkData = authorizeShowPlugLinkData;

            if (modelKeyCanChange && modelKeyDoChange && structureEditable) {
                updatedModel.clef = "new";
            }
            props.attemptUpdateModel(updatedModel, environment.userhash, props.back);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#58C3C1" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            updateModel,
            result.source.index,
            result.destination.index
        );

        setUpdateModel(items);
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        //padding: grid,
        //width: 250
    });

    const fetchFieldTooltips = () => {
        let hash = model.hash
        trackPromise(
            api.get(`models/infosbulles/infb/unique.json?mh=${hash}`),
            {
                crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
            .then((response) => {
                if (response.data.result) {
                    setInfobulles(response.data.data)
                    setHasInfobulles(true)
                }
            })
            .catch(e => console.log(e))
    }

    const handleSaveFieldTooltip = (fieldId, tooltip) => {
        handleEditFieldTooltip(fieldId, tooltip);
        setInfobulles(null)
        fetchFieldTooltips()
    }

    const handleEditFieldTooltip = (fieldId, tooltip) => {
        const payload = {
            modelhash: model.hash,
        }
        payload["field" + fieldId] = tooltip
        trackPromise(
            api.post(`models/infosbulles/infbs/createupdates.json`, payload)
            , {
                crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
            if (response.data.result === 'ok' || response.data.result === true) {
                setInfobulles(null)
            }
        }).catch((response) => {
            //console.log(response);
        })
    }

    const handleDeleteFieldTooltip = (fieldId) => {
        handleEditFieldTooltip(fieldId, "")
    }

    const handleCloseEditModal = () => {
        setOpenEditInfobulle(false)
    }

    useEffect(() => {
        props.attemptGetFields()
    }, []);

    useEffect(() => {
        if (infobulles === null) {
            fetchFieldTooltips()
            setAuthorizeInfobulle(true);
        }
    }, [fields])

    useEffect(() => {
        if (isDefined(missingLabel)) {
            if (updateModel.length > 0 && updateModel[updateModel.length - 1].label !== '') {
                setMissingLabel(null)
            }
        }
    }, [updateModel])

    // useEffect(() => {
    //   console.log('Model updateV2', updateModel)
    // }, [updateModel])


    // process CSV data
    const processData = (dataString, field, index) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const list = [];
        let isFromTable = false
        let isFromSmail = false

        for (const element of dataStringLines) {
            const row = element.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            for (let j = 0; j < 1; j++) {
                let item = row[j]
                if (item.length > 0) {
                    if (item[0] === '"')
                        item = item.substring(1, item.length - 1);
                    if (item[item.length - 1] === '"')
                        item = item.substring(item.length - 2, 1);
                }
                if(item.length > 0) {
                    if(field.format.includes('select') && field.options.presel !== undefined) {
                        if(!field.options.presel.includes(item)){
                            list.push(item);
                        }
                    }
                    else if (field.options[index] !== undefined || isDefined(field.options?.lines[index])) {
                        console.log('defined ?', field.options[index] !== undefined || isDefined(field.options?.lines[index]))
                        if(!field.options[index]?.presel?.includes(item) || !field.options?.lines[index].presel?.includes(item)) list.push(item);
                        isFromTable = true
                    } else {
                        isFromSmail = true
                        if (!field.options.lines[index].presel.includes(item)) list.push(item);
                    }
                }
            }
        }

        if (list.length > 0) {
            let model = [...updateModel];
            if (isFromTable) { //case tablemultiple
                if (isDefined(model[field.id - 1].options[index]?.presel)) {
                    model[field.id - 1].options[index].presel = model[field.id - 1].options[index].presel.concat(list)
                } else {
                    model[field.id - 1].options.lines[index].presel = model[field.id - 1].options.lines[index].presel.concat(list)
                }
                // console.log('modified model', model)
                setUpdateModel(model);
            }
            else if(isFromSmail) { //case smail
                model[field.id - 1].options.lines[index].presel = model[field.id - 1].options.lines[index].presel.concat(list)
                setUpdateModel(model);
            } else {
                model[index].options.presel = model[index].options.presel.concat(list);
                setUpdateModel(model);
            }
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.traitement-finish'),
                buttons: [{label: t('global.yes'),}]
            });
        } else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.traitement-no-data'),
                buttons: [{label: t('global.yes'),}]
            });
        }
    }

    const onChangeHandlerDrop = (acceptedFiles, field, index) => {
        let files = acceptedFiles, f = files[0];
        let reader = new FileReader();
        reader.onload = function(e) {
            let bstr = new Uint8Array(e.target.result);
            let workbook = XLSX.read(bstr, {type: 'array'});
            /* DO SOMETHING WITH workbook HERE */
            /* Get first worksheet */
            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data, field, index);
        };
        reader.readAsArrayBuffer(f);

    }

    const handleEditInfobulle = (field) => {
        setSelectedField(field)
        setOpenEditInfobulle(true)
    }

    const handleClickPoper = (event, id) => {
        event.stopPropagation();
        event.preventDefault();
        if (id === null) {
            setOpenPoper(null);
        } else if (openPoper === id) {
            setOpenPoper(null);
        } else {
            setOpenPoper(id);
        }
    }

    const handleOpenDefaultValue = (index) => {
        setOpenDefaultEdit(previous => index === previous ? null : index)
    }
    const handleChangeDefaultValueEdit = (index, value) => {
        let model = [...updateModel]
        model[index].options.setup = {...model[index].options.setup, ...value}
    }

    const handleChangeDefaultValue = (index, value) => {
        let model = [...updateModel]
        model[index].options.setup.defaultValue = value;
        setUpdateModel(model)
    }
    // console.log('UpdateV2 model', updateModel)

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Drawer
                variant="permanent"
                className={clsx(classes.paper, classes.drawer, {
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClose]: !openDrawer,
                })}
                classes={{
                    paper: clsx(classes.paper, classes.paperAnchorDockedLeft, {
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
                <div className={classes.openClose}>
                    <IconButton className={classes.openCloseButton}
                                onClick={openDrawer ? handleDrawerClose : handleDrawerOpen}>
                        {!openDrawer ? <ChevronRight/> : <ChevronLeft/>}
                    </IconButton>
                </div>
                {
                    structureEditable &&
                    <div className="left-menu_contents_models">

                        {
                            openDrawer &&
                            <div className="text_align">
                                <span className="titres unbreakText">
                                    {t('common:models.select-formats')}
                                </span>
                                <br/>
                                <span className="titres unbreakText ">
                                    {t('common:models.select-formats-sub')}
                                </span>
                            </div>
                        }
                        <div className="left-menu_contents_wrapper scrollbar-hidden ">
                            <ul className={classes.scrollBar + " left-menu_contents_actions liste-formats-v3"}>
                                {props.fields.map((field, index) => (
                                    (field.format !== "timeplus" && field !== null && !specialFields.includes(field.format)) &&
                                    <PlugTooltip key={index} title={field[`description${langue}`]} aria-label="add"
                                             placement="top-start">
                                        <li className="formats flex_display_direction_column"
                                            onClick={(event) => handleListItemClick(event, field)}>
                                            <div className="flexSpacebetween">
                                                {field.format.toLowerCase() === 'now' ?
                                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                        <div className={classes.fieldIcon} style={{width: '20px'}}>
                                                            <img
                                                                className={classes.fieldImage}
                                                                src={Images[camelCase(field.format.toLowerCase()) + "Icon"]}
                                                                alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        {openDrawer &&
                                                            <div
                                                                style={{marginLeft: '5px'}}>{t('fields.format-choice-' + field.format.toLowerCase())}</div>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{display: 'flex', alignItems: 'inherit'}}>
                                                        <div className={classes.fieldIconSvg} style={{width: '20px'}}>
                                                            <img
                                                                className={classes.fieldImageSvg}
                                                                src={Images[camelCase(field.format.toLowerCase()) + "IconSvg"]}
                                                                alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        {
                                                            openDrawer && <div
                                                                style={{marginLeft: '5px'}}>{t('fields.format-choice-' + field.format.toLowerCase())}</div>
                                                        }
                                                    </div>
                                                }
                                                {openDrawer &&
                                                    <div className="grey flexSpacebetween padding_2">
                                                <span
                                                    className="references line_height_22 flex_display_direction_column">
                                                {(field?.pnpfcodefr && field.pnpfcodefr !== "" || field?.pnpfcodeen && field.pnpfcodeen !== "" || field?.pnpfcodenl && field.pnpfcodenl !== "") &&
                                                    <span id="icon-more"
                                                          className={`material-icons md ${!isNull(openPoper) && openPoper === field.id ? 'red' : 'green'}`}
                                                          onClick={(event) => handleClickPoper(event, field.id)}>
                                                        help_outline
                                                    </span>
                                                }
                                                </span>
                                                        <span className="references line_height_22 margin-left-5">
                                                    {field.indice}
                                                </span>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                !isNull(openPoper) && openPoper === field.id &&
                                                <div>
                                                    <RenderPopUpItem field={field} open={openPoper}
                                                                     handleClickPoper={handleClickPoper}/>
                                                </div>
                                            }
                                        </li>
                                    </PlugTooltip>
                                ))}
                                <li className="inheritClass">
                                    <div className="line flex_style"/>
                                </li>
                                {props.fields.map((field, index) => {
                                        let descTooltip = (isDefined(field[`description${langue}`]) && field[`description${langue}`] !== '') ? field[`description${langue}`] : ' - '

                                        if (field.format !== "timeplus" && field !== null && specialFields.includes(field.format)) return (
                                            <PlugTooltip key={index} title={descTooltip} aria-label="add" placement="top-start">
                                                <li className="formats flex_display_direction_column"
                                                    onClick={(event) => handleListItemClick(event, field)}>
                                                    <div className="flexSpacebetween">
                                                        {field.format.toLowerCase() === 'now' ?
                                                            <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                                <div className={classes.fieldIcon} style={{width: '20px'}}>
                                                                    <img
                                                                        className={classes.fieldImage}
                                                                        src={Images[camelCase(field.format.toLowerCase()) + "Icon"]}
                                                                        alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                    />
                                                                </div>
                                                                {openDrawer &&
                                                                    <div
                                                                        style={{marginLeft: '5px'}}>{t('fields.format-choice-' + field.format.toLowerCase())}</div>
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{display: 'flex', alignItems: 'inherit'}}>
                                                                <div className={classes.fieldIconSvg}
                                                                     style={{width: '20px'}}>
                                                                    <img
                                                                        className={classes.fieldImageSvg}
                                                                        src={Images[camelCase(field.format.toLowerCase()) + "IconSvg"]}
                                                                        alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                    />
                                                                </div>
                                                                {openDrawer &&
                                                                    <div
                                                                        style={{marginLeft: '5px'}}>{t('fields.format-choice-' + field.format.toLowerCase())}</div>
                                                                }
                                                            </div>
                                                        }
                                                        {openDrawer &&
                                                            <div className="grey flexSpacebetween padding_2">
                                                                <span
                                                                    className="references line_height_22 flex_display_direction_column">
                                                                    {(field?.pnpfcodefr && field.pnpfcodefr !== "" || field?.pnpfcodeen && field.pnpfcodeen !== "" || field?.pnpfcodenl && field.pnpfcodenl !== "") &&
                                                                        <span id="icon-more"
                                                                              className={`material-icons md ${!isNull(openPoper) && openPoper === field.id ? 'red' : 'green'}`}
                                                                              onClick={(event) => handleClickPoper(event, field.id)}>
                                                                        help_outline
                                                                        </span>
                                                                    }
                                                                </span>
                                                                <span className="references line_height_22 margin-left-5">
                                                                    {field.indice}
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        !isNull(openPoper) && openPoper === field.id &&
                                                        <div>
                                                            <RenderPopUpItem field={field} open={openPoper}
                                                                             handleClickPoper={handleClickPoper}/>
                                                        </div>
                                                    }
                                                </li>
                                            </PlugTooltip>);
                                        else return null;
                                    }
                                )}
                            </ul>

                        </div>
                    </div>
                }
                <div className={classes.openClose}>
                    <IconButton className={classes.openCloseButton}
                                onClick={openDrawer ? handleDrawerClose : handleDrawerOpen}>
                        {!openDrawer ? <ChevronRight/> : <ChevronLeft/>}
                    </IconButton>
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Content className={`${classes.contentModel} scrollbar-hidden`}>
                    <div className="sous-header-without-height p1 grey">
                        <div className="text_align">
                            <h3 className="">{t('common:models.update-model')}</h3>
                        </div>
                    </div>
                    <div className={`${classes.stickyHeader} p1 grey`}>
                        <div className="sous-header_content">
                            <div className={classes.formTitleCtn}>
                                <Input
                                    id="standard-full-width"
                                    label="Label"
                                    // style={{ margin: 8 }}
                                    placeholder={t('common:models.form-title')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.fieldRight
                                    }}
                                    onChange={handleChangeFolderName}
                                    value={nameModel}
                                />
                            </div>

                            <PlugTooltip title={t('global.cancel-exit')}>
                                <button className={`button ${classes.closeBtn}`} onClick={handleCancelExitModel}>
                                    <span className="material-icons" style={{color: colors.hoverBgnd}}>
                                        cancel
                                    </span>
                                </button>
                            </PlugTooltip>

                            <PlugTooltip title={t('common:multiple.save')}>
                                <button className="button" onClick={handleSaveStayModel}>
                                    <span className="material-icons" style={{color: colors.hoverBgnd}}
                                          onClick={() => handleSaveStayModel}>
                                        save
                                    </span>
                                </button>
                            </PlugTooltip>

                            <PlugTooltip title={t('common:global.safe-exit')}>
                                <button className="button" onClick={handleSafeQuitModel}>
                                    <span className="material-icons" style={{color: colors.hoverBgnd}}>
                                        logout
                                    </span>
                                </button>
                            </PlugTooltip>

                        </div>
                        <div style={{margin: '8px 0px'}}>
                            <span>{t('common:global.model-annexe')}</span>
                        </div>
                    </div>
                    <div className={classes.formOptionsCtn}>
                        <div id="liste-champs_remplir">
                            {t('common:plugnote.view-all-fields-empty')}
                            <input className="checkbox" type="checkbox" checked={fieldEmptyVisible}
                                   onChange={(event) => setFieldEmptyVisible(!fieldEmptyVisible)}/>
                        </div>
                        <div id="infobulles-option" onClick={() => setAuthorizeInfobulle(!authorizeInfobulle)}>

                            {/* InfoBulle  Checkbox, automatically checked and hidden

                            Hidden Label = {t('common:helper.infobulles')}

                            */}
                            {/* <input
                                className="checkbox" type="checkbox" checked={authorizeInfobulle} hidden={true}
                            /> */}
                        </div>
                    </div>

                    {
                        updateModel.find((elm) => (elm !== undefined && elm.format === "extdata") || elm.format === "extdatan" || elm.format === "tablemultin" || elm.format === "smailvalidmulti" ||
                            ((elm.format === "tablemulti" && !_.isEmpty(elm?.formatoptions) && ((elm?.formatoptions?.lines === undefined && elm?.formatoptions.find((subElm) => subElm?.format === 'extdata')))) || (elm?.formatoptions?.lines !== undefined && elm?.formatoptions.lines.find((subElm) => subElm?.format === 'extdata'))) !== undefined) &&
                        <div className="split-100 formulaireGauche p1">
                            <div id="liste-champs_remplir">
                                {t('common:plugnote.view-search-external-db')}
                                <input
                                    className="checkbox" type="checkbox" checked={authorizeExternalDbInPf}
                                    onChange={(event) => setAuthorizeExternalDbInPf(!authorizeExternalDbInPf)}
                                />
                            </div>
                        </div>
                    }

                    <div className="split-100 formulaireGauche p1">
                        <div id="liste-champs_remplir">
                            {t('common:plugnote.view-pluglink-data')}
                            <input className="checkbox" type="checkbox" checked={authorizeShowPlugLinkData}
                                   onChange={(event) => setAuthorizeShowPlugLinkData(!authorizeShowPlugLinkData)}/>
                        </div>
                    </div>

                    <div className="split-100 formulaireGauche">
                        <div id="liste-champs_remplir">
                            <div className="champs_remplir flexbox-field-item m2">
                                <div className={classes.formatContainer} style={{paddingBottom: 10}}>
                                    <div style={{
                                        width: '10%',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly',
                                        marginTop: 'auto',
                                    }}>
                                        <span>Icon</span>
                                        <span>N°</span>
                                    </div>
                                    <div style={{width: '15%', textAlign: 'center', marginTop: 'auto' }}>
                                        <span>{t('common:helper.format')}</span>
                                    </div>
                                    <div style={{width: '30%', textAlign: 'center', marginTop: 'auto' }}>
                                        <span>{t('common:helper.label')}</span>
                                    </div>
                                    <div style={{width: '10%', textAlign: 'center', marginTop: 'auto' }}>
                                        <span>{t('common:helper.options')}</span>
                                    </div>

                                    {/* Visible in PDF / PN / PF */}
                                    <div style={{ width: '15%', textAlign: 'center', whiteSpace: 'pre-line', marginTop: 'auto' }}>
                                        {t('common:helper.visibility')}
                                    </div>
                                    {
                                        authorizeInfobulle &&
                                        <PlugTooltip title={t('common:helper.infobulles-info')}>
                                            <div style={{ width: '5%', justifyContent: 'center', display: 'flex', marginTop: 'auto' }}>
                                                <InfoIcon style={{ color: "black" }} />
                                            </div>
                                        </PlugTooltip>
                                    }
                                    <div style={{width: '5%', justifyContent: 'center', display: 'flex'}}>
                                        <PlugTooltip title={t('helper.rgpd')}>
                                            <span id="icon-more" className="md"
                                                style={{ cursor: 'pointer', marginTop: 'auto' }}>
                                                RGPD
                                            </span>
                                        </PlugTooltip>
                                    </div>
                                    <div style={{width: '5%', textAlign: 'center', whiteSpace: 'pre-line', marginLeft: 'auto' }}>
                                        <span>{t('helper.mandatory-fields')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="splitContent flexSpacebetween">
                        <div className="split-100 formulaireGauche">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {
                                                updateModel.map((field, index) => (
                                                    <div key={index}>
                                                        {
                                                            !!field && field.format !== null &&
                                                            <div key={index}>
                                                                {
                                                                    !!field && field.newField ?
                                                                        <Draggable key={field.dragid}
                                                                            draggableId={field.dragid}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    style={getItemStyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}
                                                                                >
                                                                                    <div key={index + 1}
                                                                                        id="liste-champs_remplir">
                                                                                        <div className="champs_remplir flexbox-field-item m2 ">
                                                                                            <div className={classes.formatContainer}>
                                                                                                <div style={{ width: '5%', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                                                                                    <span className="green">
                                                                                                        {field.format.toLowerCase() === 'now' ?
                                                                                                            <div
                                                                                                                className={classes.fieldIcon}>
                                                                                                                <img
                                                                                                                    className={classes.fieldImage}
                                                                                                                    src={Images[camelCase(field.format.toLowerCase()) + "Icon"]}
                                                                                                                    alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                                />
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div
                                                                                                                className={classes.fieldIconSvg}>
                                                                                                                <img
                                                                                                                    className={classes.fieldImageSvg}
                                                                                                                    src={Images[camelCase(field.format.toLowerCase()) + "IconSvg"]}
                                                                                                                    alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </span>
                                                                                                    <span className="references_8 line_height_22">({(index + 1)})</span>
                                                                                                </div>
                                                                                                <div style={{ width: '5%', textAlign: 'center' }}>
                                                                                                    <span className="references">
                                                                                                        {field.indice}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ width: '15%', textAlign: 'center' }}>
                                                                                                    {t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="champsFormulaire flexSpacebetween m3"
                                                                                                    style={{ width: fieldsWithDefaultValue.includes(field.format) && !fieldsWithOptions.includes(field.format) ? '30%' : '40%' }}>
                                                                                                    {
                                                                                                        isDefined(field.options.setup?.defaultValue) && field.options.setup.defaultValue !== '' &&
                                                                                                        <PlugTooltip placement="top" title={t('helper.default-value') + ':\n' + field.options.setup.defaultValue} >
                                                                                                            <span onClick={() => handleOpenDefaultValue(index)} >
                                                                                                                <EditValueIcon style={{ cursor: 'pointer', color: colors.bloodOrange }}/>
                                                                                                            </span>
                                                                                                        </PlugTooltip>
                                                                                                    }
                                                                                                    <RenderItemFormatComponent {...props}
                                                                                                        field={field}
                                                                                                        openSMail={openSMail}
                                                                                                        handleOpenCloseSmail={handleOpenCloseSmail}
                                                                                                        handleEditLabel={handleEditLabel}
                                                                                                        index={index}
                                                                                                        onChangeHandlerDrop={onChangeHandlerDrop}
                                                                                                        setUpdateModel={setUpdateModel}
                                                                                                        updateModel={updateModel}
                                                                                                        update={true}
                                                                                                        folderName={nameModel}
                                                                                                    />
                                                                                                    {
                                                                                                        missingLabel === index &&
                                                                                                        <PlugTooltip placement="top" title={t('tablemulti.missing-label')} >
                                                                                                            <span className="material-icons" style={{ color: 'red' }}>
                                                                                                                error
                                                                                                            </span>
                                                                                                        </PlugTooltip>
                                                                                                    }
                                                                                                </div>

                                                                                                {
                                                                                                    fieldsWithDefaultValue.includes(field.format)  && !fieldsWithOptions.includes(field.format)&&
                                                                                                    <span style={{ width: '10%' }}>
                                                                                                        <PlugTooltip title={t('helper.default-edit')} placement="top">
                                                                                                            <span onClick={() => handleOpenDefaultValue(index)} className={classes.paramBtn}>
                                                                                                                <SettingsIcon color="white" />
                                                                                                                <span className="material-icons" style={{ color: 'white' }}>
                                                                                                                    {openDefaultEdit === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </PlugTooltip>
                                                                                                    </span>
                                                                                                }
                                                                                                <PlugTooltip title={t('helper.toggle-visibility')} placement="top">
                                                                                                    <div style={{ width: '15%', justifyContent: 'center', display: 'flex', gap: 5 }}>
                                                                                                        <span onClick={() => handleToggleVisiblePDF(field, index)} >
                                                                                                            <VisibilityIndicator text="PDF" visible={field.options.setup?.printable === true} />
                                                                                                        </span>
                                                                                                        <span onClick={!notEncodable.includes(field.format) ? handleVisibleField.bind(this, field, index) : null} >
                                                                                                            <VisibilityIndicator text="PN-" visible={field.isVisible === true} />
                                                                                                        </span>
                                                                                                        <span onClick={!notEncodable.includes(field.format) ? handleEditableField.bind(this, field, index) : null} >
                                                                                                            <VisibilityIndicator text="PF-" visible={field.isEncodable === true} />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </PlugTooltip>
                                                                                                {
                                                                                                    authorizeInfobulle &&
                                                                                                    <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
                                                                                                        <PlugTooltip
                                                                                                            title={!isDefined(infobulles) || !infobulles.hasOwnProperty('field' + field.id) ? t('common:helper.infobulles-info') : infobulles['field' + field.id]}
                                                                                                            placement="top">
                                                                                                            <span
                                                                                                                id="icon-more"
                                                                                                                onClick={() => handleEditInfobulle(field)}
                                                                                                                style={{ cursor: 'pointer' }}
                                                                                                            >
                                                                                                                {
                                                                                                                    isDefined(infobulles) && infobulles.hasOwnProperty('field' + field.id)
                                                                                                                        ? <EditInfoIcon style={{ color: colors.bloodOrange }} secondaryColor={colors.bloodOrange} />
                                                                                                                        : <AddInfoIcon style={{ color: colors.grey }} secondaryColor={colors.grey} />
                                                                                                                }
                                                                                                            </span>
                                                                                                        </PlugTooltip>
                                                                                                    </div>
                                                                                                }
                                                                                                <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
                                                                                                    <div className="grey flexSpacebetween">
                                                                                                        <PlugTooltip
                                                                                                            title={t('common:helper.rgpd')}
                                                                                                            aria-label="add"
                                                                                                            placement="top-start">
                                                                                                            <div className="grey flexSpacebetween" onClick={() => handleToggleRGPD(field, index)}>
                                                                                                                <ColorCheckbox
                                                                                                                    checked={field?.options?.rgpd}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </PlugTooltip>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div style={{
                                                                                                    width: '10%',
                                                                                                    justifyContent: 'flex-end',
                                                                                                    display: 'flex'
                                                                                                }}>
                                                                                                    <div
                                                                                                        className="grey flexSpacebetween">
                                                                                                        <PlugTooltip
                                                                                                            title={t('common:helper.move-field')}
                                                                                                            aria-label="add"
                                                                                                            placement="top-start">
                                                                                                            <span
                                                                                                                id="icon-more"
                                                                                                                {...provided.dragHandleProps}
                                                                                                                className="material-icons md-small">
                                                                                                                drag_handle
                                                                                                            </span>
                                                                                                        </PlugTooltip>
                                                                                                        <PlugTooltip
                                                                                                            title={t('common:helper.remove-field')}
                                                                                                            aria-label="add"
                                                                                                            placement="top-start">
                                                                                                            <span
                                                                                                                id="icon-more"
                                                                                                                className="material-icons md-small"
                                                                                                                onClick={handleDeleteField.bind(this, field)}>
                                                                                                                clear
                                                                                                            </span>
                                                                                                        </PlugTooltip>
                                                                                                        {
                                                                                                            field.format !== 'plugform' &&
                                                                                                            <PlugTooltip
                                                                                                                title={field.options.mandatory ? t('helper.mandatory-field') : t('helper.not-mandatory-field')}
                                                                                                                aria-label="add"
                                                                                                                placement="top-start">
                                                                                                                <span onClick={handleToggleMandatoryField.bind(this, field, index)} >
                                                                                                                    {field.options.mandatory ?
                                                                                                                        <Asterisk
                                                                                                                            color="black" size={18}
                                                                                                                        />
                                                                                                                        :
                                                                                                                        <AsteriskOutline color="black" size={18} />}
                                                                                                                </span>
                                                                                                            </PlugTooltip>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                fieldsWithOptions.includes(field.format.toLowerCase()) &&
                                                                                                    <div className={classes.subFormatContainer}>
                                                                                                        <RenderSubItemFormatComponent {...props}
                                                                                                            classes={classes}
                                                                                                            openSMail={openSMail}
                                                                                                            field={field}
                                                                                                            handleChangeDefaultValue={handleChangeDefaultValue}
                                                                                                            handleChangeDefaultValueEdit={handleChangeDefaultValueEdit}
                                                                                                            handleEditLabel={handleEditLabel}
                                                                                                            handleOpenCloseSmail={handleOpenCloseSmail}
                                                                                                            index={index}
                                                                                                            onChangeHandlerDrop={onChangeHandlerDrop}
                                                                                                            setUpdateModel={setUpdateModel}
                                                                                                            updateModel={updateModel}
                                                                                                            update={true}
                                                                                                            folderName={nameModel}
                                                                                                            setOpenDrawer={setOpenDrawer}
                                                                                                            t={t}
                                                                                                        />
                                                                                                    </div>
                                                                                            }

                                                                                            <Collapse in={openDefaultEdit === index} timeout="auto" unmountOnExit>
                                                                                                <div className={classes.subFormatContainer}>
                                                                                                    <RenderEditDefaultValueComponent
                                                                                                        field={field}
                                                                                                        handleChangeDefaultValue={(value) => handleChangeDefaultValue(index, value)}
                                                                                                        handleChangeDefaultValueEdit={(value) => handleChangeDefaultValueEdit(index, value)}
                                                                                                    />
                                                                                                </div>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                        :
                                                                        <div key={index + 1} id="liste-champs_remplir">
                                                                            <div
                                                                                className="champs_remplir flexbox-field-item m2 ">
                                                                                <div
                                                                                    className={classes.formatContainer}>
                                                                                    <div style={{ width: '5%', textAlign: 'center' }}>
                                                                                        <span className="green">
                                                                                            {
                                                                                                (field.format.toLowerCase() === 'now') ?
                                                                                                    <div className={classes.fieldIcon}>
                                                                                                        <img
                                                                                                            className={classes.fieldImage}
                                                                                                            src={Images[camelCase(field.format.toLowerCase()) + "Icon"]}
                                                                                                            alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                        />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div
                                                                                                        className={classes.fieldIconSvg}>
                                                                                                        <img
                                                                                                            className={classes.fieldImageSvg}
                                                                                                            src={Images[camelCase(field.format.toLowerCase()) + "IconSvg"]}
                                                                                                            alt={t('fields.format-choice-' + field.format.toLowerCase())}
                                                                                                        />
                                                                                                    </div>
                                                                                            }
                                                                                        </span>
                                                                                        <span className="references_8 line_height_22">({(index + 1)})</span>
                                                                                    </div>
                                                                                    <div style={{ width: '5%', textAlign: 'center' }}>
                                                                                        <span className="references">
                                                                                            {renderFormatText(field)}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div style={{ width: '15%', textAlign: 'center' }}>
                                                                                        <span>{t('fields.format-choice-' + field.format.toLowerCase())}</span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="champsFormulaire flexSpacebetween m3"
                                                                                        style={{ width: fieldsWithDefaultValue.includes(field.format) && !fieldsWithOptions.includes(field.format) ? '30%' : '40%' }}>
                                                                                        {
                                                                                            isDefined(field.options.setup?.defaultValue) && field.options.setup.defaultValue !== '' &&
                                                                                            <PlugTooltip placement="top" title={t('helper.default-value') + ':\n' + field.options.setup.defaultValue} >
                                                                                                <span onClick={() => handleOpenDefaultValue(index)} >
                                                                                                    <EditValueIcon style={{ cursor: 'pointer', color: colors.bloodOrange }}/>
                                                                                                </span>
                                                                                            </PlugTooltip>
                                                                                        }
                                                                                        <RenderItemFormatComponent {...props}
                                                                                            openSMail={openSMail}
                                                                                            handleOpenCloseSmail={handleOpenCloseSmail}
                                                                                            field={field}
                                                                                            handleEditLabel={handleEditLabel}
                                                                                            index={index}
                                                                                            onChangeHandlerDrop={onChangeHandlerDrop}
                                                                                            setUpdateModel={setUpdateModel}
                                                                                            updateModel={updateModel}
                                                                                            update={true}
                                                                                            folderName={nameModel} />
                                                                                    </div>

                                                                                    {
                                                                                        missingLabel === index &&
                                                                                        <PlugTooltip placement="top" title={t('tablemulti.missing-label')} >
                                                                                            <span className="material-icons" style={{ color: 'red' }}>
                                                                                                error
                                                                                            </span>
                                                                                        </PlugTooltip>
                                                                                    }
                                                                                    {
                                                                                        fieldsWithDefaultValue.includes(field.format) && !fieldsWithOptions.includes(field.format) &&
                                                                                        <span style={{ width: '10%' }}>
                                                                                            <PlugTooltip title={t('helper.default-edit')} placement="top">
                                                                                                <span onClick={() => handleOpenDefaultValue(index)} className={classes.paramBtn}>
                                                                                                    <SettingsIcon color="white" />
                                                                                                    <span className="material-icons" style={{ color: 'white' }}>
                                                                                                        {openDefaultEdit === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </PlugTooltip>
                                                                                        </span>
                                                                                    }
                                                                                    <PlugTooltip title={t('helper.toggle-visibility')} placement="top">
                                                                                        <div style={{ width: '15%', justifyContent: 'center', display: 'flex', gap: 5 }}>
                                                                                            <span onClick={() => handleToggleVisiblePDF(field, index)} >
                                                                                                <VisibilityIndicator text="PDF" visible={field.options.setup?.printable === true} />
                                                                                            </span>
                                                                                            <span onClick={!notEncodable.includes(field.format) ? handleVisibleField.bind(this, field, index) : null} >
                                                                                                <VisibilityIndicator text="PN-" visible={field.isVisible === true} />
                                                                                            </span>
                                                                                            <span onClick={!notEncodable.includes(field.format) ? handleEditableField.bind(this, field, index) : null} >
                                                                                                <VisibilityIndicator text="PF-" visible={field.isEncodable === true} />
                                                                                            </span>
                                                                                        </div>
                                                                                    </PlugTooltip>
                                                                                    {
                                                                                        authorizeInfobulle &&
                                                                                        <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
                                                                                            <PlugTooltip
                                                                                                title={!isDefined(infobulles) || !infobulles.hasOwnProperty('field' + field.id) ? t('common:helper.infobulles-info') : infobulles['field' + field.id]}
                                                                                                placement="top">
                                                                                                <span
                                                                                                    id="icon-more"
                                                                                                    onClick={() => handleEditInfobulle(field)}
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                >
                                                                                                    {
                                                                                                        isDefined(infobulles) && infobulles.hasOwnProperty('field' + field.id)
                                                                                                            ? <EditInfoIcon style={{ color: colors.bloodOrange }} secondaryColor={colors.bloodOrange} />
                                                                                                            : <AddInfoIcon style={{ color: colors.grey }} secondaryColor={colors.grey} />
                                                                                                    }
                                                                                                </span>
                                                                                            </PlugTooltip>
                                                                                        </div>
                                                                                    }

                                                                                    <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
                                                                                        <PlugTooltip
                                                                                            title={t('common:helper.rgpd')}
                                                                                            aria-label="add"
                                                                                            placement="top-start">
                                                                                            <div className="grey flexSpacebetween" onClick={() => handleToggleRGPD(field, index)}>
                                                                                                <ColorCheckbox
                                                                                                    checked={field?.options?.rgpd}
                                                                                                />
                                                                                            </div>
                                                                                        </PlugTooltip>
                                                                                    </div>

                                                                                    <div style={{
                                                                                        width: '10%',
                                                                                        justifyContent: 'flex-end',
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            className="grey flexSpacebetween">
                                                                                            <PlugTooltip
                                                                                                title={t('common:helper.move-field')}
                                                                                                aria-label="add"
                                                                                                placement="top-start">
                                                                                                <span id="icon-more"
                                                                                                    className="material-icons md-small invisible">
                                                                                                    drag_handle
                                                                                                </span>
                                                                                            </PlugTooltip>
                                                                                            <PlugTooltip
                                                                                                title={t('common:helper.remove-field')}
                                                                                                aria-label="add"
                                                                                                placement="top-start">
                                                                                                <span id="icon-more"
                                                                                                    className="material-icons md-small invisible"
                                                                                                    onClick={handleDeleteField.bind(this, field)}>
                                                                                                    clear
                                                                                                </span>
                                                                                            </PlugTooltip>
                                                                                            {
                                                                                                field.format !== 'plugform' &&
                                                                                                <PlugTooltip
                                                                                                    title={field.options.mandatory ? t('helper.mandatory-field') : t('helper.not-mandatory-field')}
                                                                                                    aria-label="add"
                                                                                                    placement="top-start">
                                                                                                    <span onClick={handleToggleMandatoryField.bind(this, field, index)} >
                                                                                                        {field.options.mandatory ?
                                                                                                            <Asterisk
                                                                                                                color="black" size={18}
                                                                                                            />
                                                                                                            :
                                                                                                            <AsteriskOutline color="black" size={18} />}
                                                                                                    </span>
                                                                                                </PlugTooltip>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    fieldsWithOptions.includes(field.format.toLowerCase()) &&
                                                                                    <div className={classes.subFormatContainer}>
                                                                                        <RenderSubItemFormatComponent {...props}
                                                                                            classes={classes}
                                                                                            openSMail={openSMail}
                                                                                            field={field}
                                                                                            handleChangeDefaultValue={handleChangeDefaultValue}
                                                                                            handleChangeDefaultValueEdit={handleChangeDefaultValueEdit}
                                                                                            handleOpenCloseSmail={handleOpenCloseSmail}
                                                                                            handleEditLabel={handleEditLabel}
                                                                                            index={index}
                                                                                            onChangeHandlerDrop={onChangeHandlerDrop}
                                                                                            setUpdateModel={setUpdateModel}
                                                                                            updateModel={updateModel}
                                                                                            update={true}
                                                                                            folderName={nameModel}
                                                                                            setOpenDrawer={setOpenDrawer}
                                                                                            t={t}
                                                                                        />
                                                                                    </div>
                                                                                }

                                                                                <Collapse in={openDefaultEdit === index} timeout="auto" unmountOnExit>
                                                                                    <div className={classes.subFormatContainer}>
                                                                                        <RenderEditDefaultValueComponent
                                                                                            field={field}
                                                                                            handleChangeDefaultValue={(value) => handleChangeDefaultValue(index, value)}
                                                                                            handleChangeDefaultValueEdit={(value) => handleChangeDefaultValueEdit(index, value)}
                                                                                        />
                                                                                    </div>
                                                                                </Collapse>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                ))
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div className="flexbox-container-hidden"/>
                        </div>
                    </div>
                </Content>
            </main>
            {
                openEditInfobulle &&
                <ModalEditInfobulleComponent
                    close={handleCloseEditModal}
                    field={selectedField}
                    handleDeleteFieldTooltip={handleDeleteFieldTooltip}
                    handleSaveFieldTooltip={handleSaveFieldTooltip}
                    open={openEditInfobulle}
                    tooltip={infobulles !== null && typeof infobulles !== 'undefined' && infobulles.hasOwnProperty("field" + selectedField.id) ? infobulles["field" + selectedField.id] : ""}
                />
            }
        </div>
    );
}

function ModelUpdateV2(props) {
    // const {t, i18n} = useTranslation(['common', 'models']);
    // const [langue, setLangue] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase() === 'fr' ? '' : 'En');
    const [model, setModel] = useState(null);

    const environment = getCurrentEnvironnement();

    useEffect(() => {
        props.attemptGetModelUniqueRequest(getModelToUpdate().hash, environment.userhash, props.profilHash);
    }, []);

    useEffect(() => {
        setModel(props.model)
    }, [props.model]);

    // useEffect(() => {
    //   console.log('UpdateV2 model', model)
    // }, [model])

    return (
        isDefined(model)
            ? <Model {...props} model={model}/>
            : null
    )
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    let back = "";
    if (typeof props.location.state !== "undefined") {
        if (typeof props.location.state.back !== "undefined") {
            back = props.location.state.back;
        }
    }

    let tmpFields = state.field.list;
    //let fields = sortBy(state.field.list, 'position').filter((field) => field.active);
    let fields;

    if (typeof state.user.user.fields !== "undefined" && state.user.user.fields.length > 0) {
        let tmp = tmpFields.filter((field, fieldIndex) => {
            if (field.check && state.user.user.fields.includes((field.indice))) {
                return field;
            } else if (!field.check) {
                return field
            }
        });
        tmp.filter(Boolean)
        fields = sortBy(tmp, 'position').filter((field) => field.active)
    } else {
        fields = sortBy(tmpFields, 'position').filter((field) => field.active && !field.check)
    }

    return {
        model: state.model.model,
        back,
        user: state.user,
        token: state.user.token,
        fields: fields.filter(Boolean)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetFields: () => dispatch(getFieldsRequest()),
        attemptCreateModel: (model, userhash) => dispatch(postModelRequest(model, userhash)),
        attemptUpdateModel: (model, userhash, back) => dispatch(putModelRequest(model, userhash, back)),
        attemptGetModelUniqueRequest: (modelhash, userhash, usermainhash) => dispatch(getModelUniqueRequest(modelhash, userhash, usermainhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelUpdateV2)
