import {all} from 'redux-saga/effects';
import UserSagas from './user';
import ModelSagas from "./model";
import FieldSagas from "./field";
import DataSagas from "./data";
import AnnexeSagas from "./annexe";
import PlugcodeSagas from "./plugcode";
import SearchSagas from "./search";
import TemplateSagas from "./template";
import ShareSagas from "./share";
import ExImportSagas from "./eximport";
import GallerySagas from "./gallery";
import ExternalSagas from "./external";
import MailboxSagas from './mailbox'
import RepertorySagas from "./repertory";
import UtilsSagas from "./utils";
import TranslateSagas from "./translate";
import ExtDataSagas from "./extdata";
import FilterSagas from "./filter";

// combine all sagas
export default function* rootSaga() {
    yield all([
        ...UserSagas,
        ...ModelSagas,
        ...FieldSagas,
        ...DataSagas,
        ...PlugcodeSagas,
        ...AnnexeSagas,
        ...SearchSagas,
        ...TemplateSagas,
        ...ShareSagas,
        ...ExImportSagas,
        ...GallerySagas,
        ...ExternalSagas,
        ...MailboxSagas,
        ...RepertorySagas,
        ...UtilsSagas,
        ...TranslateSagas,
        ...ExtDataSagas,
        ...FilterSagas,
    ]);
}
