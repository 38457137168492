import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {KeyboardDatePicker} from "@material-ui/pickers";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    dateComponent:{
        display: 'flex',
        justifyContent: 'space-between',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        marginRight: '3px'
    },
    timeNowButton: {
        height:'20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
        fontWeight: '900',
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));


const FormatDateComponent = (props) => {
    const classes = useStyles();
    const [selectedDate, handleDateChange] = useState(typeof props.field.value !== 'undefined' && props.field.value !== null && props.field.value.length > 0 ? props.field.value : null);
    const [value, setValue] = useState(selectedDate)
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false : props.disabled);
    
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        if (!!props.field.value && props.field.value !== "") handleDateChange(props.field.value);
    },[props.field.value])

    const handleChangeDate = (date) => {
        if(!disabled) {
            if (date !== null) {
                let myDate = new Date(date)
                let visualDate = ""
                let year = myDate.getFullYear()
                let month = myDate.getMonth() + 1
                if(month < 10) month = '0' + month
                let day = myDate.getDate()
                if(day < 10) day = '0' + day
                if (!isNaN(year)) visualDate = year + '-' + month + '-' + day
                props.handleEditDateTime(selectedDate, visualDate, props.field, props.index)
            }
        }
    }

    return (
    <div className={classes.timeContainer}>
        <KeyboardDatePicker
            InputAdornmentProps={{ position: 'start' }}
            disabled={disabled}
            className={`${classes.datePickerIcon}`}
            variant="inline"
            format="yyyy-MM-dd"
            placeholder="YYYY-MM-DD"
            fullWidth={true}
            id={'date-picker-inline-'+props.field.id}
            //label={props.field.title}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            value={selectedDate}
            onChange={(date, value) => {
                handleDateChange(date);
                setValue(value)
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
            classes={{
                root: classes.dateComponent, // class name, e.g. `classes-nesting-root-x`
                label: classes.label, // class name, e.g. `classes-nesting-label-x`
              }}
        />
        {!disabled &&
        <Tooltip title={(selectedDate === "" || selectedDate !== null) ? "Delete" : "Present date"} placement="top" className={classes.endButton} >
            {(selectedDate === "" || selectedDate !== null) ?
            <span className={`${classes.timeDelButton} material-icons`} onClick={() => handleDateChange(null)}>highlight_off</span>
        :
            <span className={`${classes.timeNowButton} material-icons`} onClick={() => {if (selectedDate === null) {
                    handleDateChange(new Date());
                    handleChangeDate(Date.now())
                }}}>check</span>}
        </Tooltip>
        }
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatDateComponent);





