import React, {Fragment, useEffect, useRef, useState} from "react";
import axios from "axios";
import ApplicationConfig from "../../../config/ApplicationConfig";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import colors from "../../../assets/themes/Colors";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Box, Checkbox, CircularProgress, Collapse, Fade, FormControlLabel, Input, InputAdornment, InputLabel,ListItem, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import InputMask from 'react-input-mask';
import { CSVDownload } from "react-csv";
import {convertersAlphaToNumber, getFilenameAndExtension, isDefined, urlify} from "../../../utils/utils";
import { HyperFormula } from "hyperformula";
import { DurationInput, FieldVisibilityIndicator, PlugTooltip, RestyledNumericInput } from "../../custom/CustomComponents";
import ModalListExtDataComponent from "../extdata/ModalListExtDataComponent";
import { RenderItemMultipleSelectComponent, RenderItemSingleSelectComponent } from "../../custom/FormatTable";
import { ToastMessage } from "../../../actions/gallery";
import Dialog from "@material-ui/core/Dialog";
import {Content, Header} from "rsuite";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { ExpandLess, ExpandMore, Search} from "@material-ui/icons";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import {faFile, faFileArchive, faFilePdf, faFileWord, faStickyNote} from "@fortawesome/free-regular-svg-icons";
import { isRowLimitReached, strReplaceContentField } from "../../../utils/Fields";
import { getCurrentEnvironnement, getPlugnote, getToken, getTableView } from "../../../utils/Common";
import { confirmAlert } from "react-confirm-alert";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import { deleteShareDataWithEmailUser, getShareDataWithEmailUser, postShareDataWithEmailUser, putShareDataWithEmailUser } from "../../../api/shareApi";
import { Data9002PrintableGetURL } from "../../printable/Data9002Printable";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import PDFTablemulti from "../../custom/pdftable/PDFTablemulti";
import ModalListIntDataComponent from "../intdata/ModalListIntDataComponent";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TableMultiPrintable from "../../printable/TableMultiPrintable"
import FormatIntDataMajComponent from "../../formats/FormatIntDataMajComponent";
import {isNull} from "lodash";

function getModalStyle() {
    // const top = 5;
    return {
        // top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
    };
}

// Finds special chars in column label
const getColumnLabel = (col, _defaultLabelSize) => {
    const headerStyle = {
        fontSize: isDefined(col.headerFontSize) && col.headerFontSize > -1 ? col.headerFontSize : _defaultLabelSize,
        maxWidth: col.colWidth > 0 ? col.colWidth * 8.5 : 'auto',
        whiteSpace: "break-spaces",
        textAlign: 'center',
        margin: '0px auto',
    }
    let template;
    let hasTooltip = false;
    if (col.label.includes('~')) {
        const parts = col.label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<span key={'label-section-' + section} style={headerStyle} onClick={() => console.log('Column', col)}>{section}<br /></span>);
            }
            return (<span key={'label-section-' + section} style={headerStyle}>{section}</span>);
        });
    } else if (col.label.includes('#')) {
        const index = col.label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span key={'label-slice-' + col.label} style={headerStyle} onClick={() => console.log('Column', col)}>
                {col.label.slice(0, index)}
                <span style={headerStyle}>...</span>
            </span>]
        );
    } else {
        template = (
            [<div
                key={'label-section-' + col.label}
                style={headerStyle}
                onClick={() => console.log('Column', col)}
            >
                {col.label}
            </div>]
        );
    }
    let label = col.label.replaceAll('#', ' ');
    return (hasTooltip ?
        <PlugTooltip title={label.replaceAll('~', '\n')} placement="top">
            <div style={headerStyle}>
                {template.map(part => part)}
            </div>
        </PlugTooltip>
        :
        <div>
            {template.map(part => part)}
        </div>
    )
}

const handleSpecialCharsInLabel = (label, classes) => {
    let template;
    let hasTooltip = false;
    if (label.includes('~')) {
        const parts = label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<span key={'label-section-' + section} className={classes.fontFieldLabel}>{section} </span>);
            }
            return (<span key={'label-section-' + section} className={classes.fontFieldLabel}>{section}</span>);
        });
    } else if (label.includes('#')) {
        const index = label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span key={'label-slice-' + label} className={classes.fontFieldLabel}>
                {label.slice(0, index)}
                <span >...</span>
            </span>]
        );
    } else {
        template = (
            [<div
                key={'label-section-' + label}
                className={classes.fontFieldLabel}
            >
                {label}
            </div>]
        );
    }
    let _label = label.replaceAll('#', '');
    return (hasTooltip ?
        <PlugTooltip title={_label.replaceAll('~', ' ')} placement="top">
            <span className={classes.fontFieldLabel}>
                {template.map(part => part)}
            </span>
        </PlugTooltip>
        :
        <>
            {template.map(part => part)}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    iframe:{
        width : '100%',
        height : '800px'
    },
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90vw',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        height: '99vh',
        scrollbarWidth: 'none!important',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        overflow: 'hidden',
        '@media (max-width: 1024px)': {
            width: '95vw',
            minHeight: '60vh'
        },
        '@media (max-width: 640px)': {
            padding: '8px 5px 30px',
        }
    },
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    closeBtn: {
        alignSelf: 'flex-end',
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: 15,
        '@media (max-width: 850px)': {
            marginBottom: 0,
        },
        position: 'relative',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    modalHeader: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 35,
        right: 25,
        zIndex: 9999,
        padding: '20px 10px 0',
        '@media (max-width: 850px)': {
            flexDirection: 'column-reverse',
        }
    },
    modalBody: {
        paddingTop: 80,
        position: 'relative',
        '@media (max-width: 850px)': {
            paddingTop: 90,
        }
    },
    printPreviewCtn: {
        paddingTop: 80,
    },
    headerTitle:{
        textAlign: 'center',
        '@media (max-width: 1299px)': {
            fontSize: '12px !important'
        },
    },
    exportIconCtn: {
        width: 100,
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 5,
    },
    searchInputCtn: {
        flex: 1,
        marginRight: 15,
        marginBottom: 15,
        '@media (max-width: 850px)': {
            position: 'absolute',
            left: 0,
            top: 25
        }
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '8px',
        width: '100%',
        minWidth: 140,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: 15,
        MozBorderRadius : '8px',
        WebkitBorderRadius: '8px',
    },
    tableCtn: {
        width: 'auto',
        height: 'calc(90vh - 30px)',
        overflowY: 'auto',
        marginLeft: 15,
        paddingRight: 25,
        '& .MuiTableCell-root': {
            borderRight: `1px solid ${colors.paleGrey}`,
            padding: '2px !important'
        }
    },
    tablePreviewCtn: {
        display: 'flex',
        // alignItems: 'center',
        flexDirection: 'column',
        width: 'auto',
        // maxHeight: '60vh',
        overflowY: 'auto',
        marginLeft: -30,
        marginRight: -5,
        marginBottom: 4,
        borderRadius: 4,
        '& .MuiTableCell-root': {
            borderRight: `1px solid ${colors.paleGrey}`,
            padding: '3px !important'
        }
    },
    tableHeader: {
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: colors.palePlugGreen
        },
        borderRadius: 5,
    },
    tableBodyCtn: {
        maxHeight: '55vh',
        overflow: 'auto',
        backgroundColor: 'white',
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        zIndex: 10,
    },
    scrollBar: {
        overflowX: 'auto',
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            background: colors.greyLight,
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
        background: colors.grey,
        },
    },
    rowNb: {
        fontSize: 12,
    },
    rowNbColumnHeader: {
        width: 30,
    },
    rowNbCell: {
        '& .MuiTableCell-root': {
            borderLeft: `1px solid ${colors.paleGrey}`,
            padding: '3px !important'
        }
    },
    columnHeader: {
        fontSize: '14px',
        maxWidth: 36,
    },
    rowNumber: {
        fontSize: 10,
        border: '1px solid',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 20,
        width: 20,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px',
            fontWeight: 'bold',
            backgroundColor: colors.mainButton,
            borderColor: colors.mainButton,
            color: 'white',
        },
    },
    rowNbPreview: {
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    rowHeader: {
        borderRadius: '50%',
    },
    //selects
    selectsCtn: {
        minHeight: 24,
        gap: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        maxWidth: 150
    },
    selectItem: {
        color: colors.unselectedAlpha,
        borderColor: colors.selectedMain,
        border: '1px solid',
        padding: '0px 0px',
        margin: '0px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 10,
        minHeight: 25,
        minWidth: 1,
    },
    //editor
    editFieldsCtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '45%',
        minWidth: 500,
        position: 'relative',
        padding: 2,
    },
    editIcon: {
        position: 'absolute',
        top: 10,
        left: -20,
        fontSize: 20,
        color: colors.mainButton,
        cursor: 'pointer'
    },
    editRow: {
        '& .MuiTableCell-root': {
            padding: '0px !important',
            verticalAlign: 'baseline'
        },
        zIndex: 0
    },
    singleEditorCtn: {
        zIndex: 50,
        display: 'flex',
        justifyContent: 'center',
        flexDirection:'row',
        backgroundColor: colors.hoverBgnd,
        width: '100%'
    },
    editorCtn: {
        zIndex: 50,
        display: 'flex',
        flexDirection:'row',
        padding :2,
        backgroundColor: colors.hoverBgnd
    },
    voidCell: {
        height: 24,
        width: '100%'
    },
    absoluteIcon: {
        position: 'absolute',
    },
    validateIcon: {
        fontSize: 20,
        backgroundColor: colors.mainButton,
        borderRadius: 5,
        padding: '3px 8px',
        color: 'white',
        cursor: 'pointer'
    },
    textLength: {
        fontSize: 8,
        alignSelf: 'flex-start',
    },
    //fields
    singleTitleCtn: {
        order: 1,
        maxWidth: '20%',
        marginRight: 10
    },
    singleValueCtn: {
        display: 'flex',
        justifyContent: 'space-between',
        order: 2,
        width: 'inherit'
    },
    sendMailPreviewCtn: {
        minWidth: 60,
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'break-spaces',
    },
    singleWiderValueCtn: {
        order: 2,
        width : '80%',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
        alignSelf: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
    },
    cellPreviewCtn: {
        display: 'flex',
        order: 2,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    fieldValueContainer: {
        display: 'flex',
        order: 2,
        width : '60%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fontFieldLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main,
        wordBreak: 'break-all',
        display: 'inline-flex',
        gap: 4,
    },
    fontFieldNumber: {
        fontSize: 12,
        display: 'inline-flex',
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'bottom',
        width: 20,
        height: 20,
    },
    stylizedValue: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontTablemulti: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        textAlign: 'center',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    scaledImg: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 10,
        paddingRight: '10px !important',
    },
    selectField: {
        maxHeight: 20,
    },
    tablemultiInputCtn: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        gap: 3,
        flexWrap: 'nowrap',
        minWidth: 60,
        textAlign: 'center',
    },
    tablemultiRadioCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 10,
        marginLeft: 3,
    },
    tablemultiInputBtn: {
        height:'20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        fontSize: "20px",
        margin: '0px 5px',
    },
    tablemultiBtn: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        // fontSize: 22,
    },
    deleteBtnCtn: {
        color: colors.main,
        position: 'absolute',
        right: -25,
        top: 'calc(50% - 12px)',
    },
    deleteConfirmationCtn: {
        backgroundColor: 'white',
        padding: '4px 10px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'absolute',
        right: 4,
        top: -28,
        zIndex: 10,
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    },
    confirmBtnCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
    },
    confirmBtn: {},
    confirmIcon: {},
    headerDeleteBtnCtn: {
        marginRight: -6,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    loaderCtn: {
        position: 'absolute',
        display: 'flex',
        zIndex: 50,
        width: '96%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        fontStyle: 'italic',
        backgroundColor: colors.hoverBgnd,
        borderBottom: `3px solid ${colors.mainButton}`,
    },
    totalValues: {
        color: colors.red,
        padding: 0,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 'bold'
    },
    totalCtn: {
        display: 'flex',
        height: 20,
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 7,
    },
	searchBtn: {
		fontSize: 15,
		minWidth: 70,
	},
    leftEditCtn: {
        // width: '60%',
        marginRight: 'auto',
    },
    centerEditCtn: {
        display: 'flex',
        justifyContent: 'center',
    },
    rightEditCtn: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    //Smail exclusifs
    mailButton:{
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.mainButton,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    unregisteredBtn: {
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.bloodOrange,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    //Smail modale
    header: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    inputArea: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    attItemsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginLeft : '10px'
    },
    attItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
    },

    RadioGroupModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    showOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 5
    },
    radioIconModal: {
        color: colors.mainButton,
        backgroundColor: colors.mainButton
    },
    radioLabelModal: {
        fontSize: 14,
        cursor: 'pointer'
    },
    pwcodeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
        gap: 10,
    },
    modalBtnContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: "space-between",
    },
    mainPhoneContainer:{
      marginTop : '20px',
    },
    phoneContainer:{
        border: '1px silver solid',
        borderRadius: 4,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    searchInput: {
        position: 'absolute',
        bottom: -30,
        right: 0,
        borderRadius: 8,
        minWidth: 140,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: 15
    },
    viewMoreCtn: {
        height: 100,
        color: `${colors.main} !important`,
        background: `linear-gradient(#ffffff2b, white)`,
        '&:hover': {
            background: `linear-gradient(transparent, ${colors.main}) !important`,
            color: 'white !important',
        },
        position: 'absolute',
        paddingTop: 70,
        bottom: 0,
        width: '100%',
        zIndex: 1,
        cursor: 'pointer',
    },
    viewMoreTxt: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontWeight: 'bold !important',
        backgroundColor: `${colors.transparent} !important`,
        '& .MuiTableCell-root': {
            border: 'none !important',
            borderRight: 'none !important',
            borderBottom: 'none !important',
            '&:hover': {
                backgroundColor: colors.transparent,
                color: 'white !important',
            }
        },
    },
    noDataCell: {
        textAlign: 'center',
        borderLeft: '1px solid #e0e0e0',
        borderRight: '1px solid #e0e0e0',
    },
    noDataTxt: {
        fontStyle: 'italic',
        fontSize: 12,
    },
    icon: { fontSize: 32, color: colors.mainButton },
    disabledIcon: {
        color: colors.iconGrey,
        cursor: "not-allowed",
        fontSize: 32,
    },
    //? impression PDF
    pdfComponentCtn: {
        textDecoration: 'none',
        color: 'black',
        display: 'flex',
        height: '50vh',
        width: '100%',
        justifyContent: 'center',
    },
    cancelPrintBtn: {
        position: 'absolute',
        bottom: 30,
        right: '15%',
        backgroundColor: colors.unselectedAlpha,
        padding: '4px 12px',
        cursor: 'pointer',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
    },
    previewCtn: {
        border: 'none',
        width: '100%',
        minWidth: '400px',
        height: '100%',
        '@media (min-width:1025px)': {
            minWidth: '60vw'
        }
    },
    pdfCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '80vh',
        position: 'relative',
    },
    pointer: {
        cursor: 'pointer'
    },
}))

function getCellColumn (cellId, columns) {
    // console.log('getCell', cellId, columns);
    if (cellId) {
        let column = columns.find(obj => {
            return obj.id.toString() === cellId.toString()
        })
        return column
    }
}

const immutableFormats = ['formula']

const RenderCellValue = (props) => {
    const classes = useStyles();
    const columns = props.columns
    const value = props.value
    const cellId = props.id
    const format = getCellColumn(cellId, columns)
    const col = getCellColumn(cellId, columns)
    const [modalStyle] = useState(getModalStyle);
    const { t } = useTranslation(['common']);
    const [openBase64PreviewFromFtp, setOpenBase64PreviewFromFtp] = useState(false);
    const [base64PreviewFromFtp, setBase64PreviewFromFtp] = useState(null)
    const [uriPreviewFromFtp, setUriPreviewFromFtp] = useState(null)

    const handleOpenBase64Preview = (e, value) => {
        e.preventDefault()
        e.stopPropagation()
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'ftp/preview/annexe?uri=' + value,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }
            ).then((response) => {
                if(response.data.result){
                    setBase64PreviewFromFtp(response.data.base64);
                    setUriPreviewFromFtp(response.data.uri);
                    setOpenBase64PreviewFromFtp(true);
                }
                else {
                    console.error(response);
                }
            }).catch((response) => {
            })
        )
    }

    const renderAnnexeImagePreview = (annexeToPreview, colonnes, base64, uri) => {
        try {
            let filePathId = colonnes.find((col => col.format === 'file-uri'));
            let filePath = annexeToPreview[filePathId.id];
            let fileInfos = getFilenameAndExtension(filePath);
            let name = fileInfos[0];
            let type = fileInfos[1];

            switch (type.toLowerCase()) {
                case "jpg":
                case "JPG":
                case "jpeg":
                case "png":
                case "PNG":
                case "gif":
                    return (
                        <img
                            src={base64}
                            alt={name}
                        />
                    );
                case "mp4":
                case "mkv":
                case "avi":
                case "mov":
                    return (
                        <video className={classes.modalFile} controls>
                            <source
                                src={base64}
                                type={`video/${type.toLowerCase()}`}
                            />
                        </video>
                    );
                case "pdf":
                    return (
                        <iframe title={'iframe-' + name} src={uri} className={classes.modalFile + ' ' +  classes.iframe } />
                    );
                case "html":
                case "txt":
                    return (
                        <iframe title={'iframe-' + name} src={base64} className={classes.modalFile + ' ' +  classes.iframe } />
                    );
                case "zip":
                case "rar":
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon
                                icon={faFileArchive}
                                className={classes.faIconView}
                            />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );
                case "mp3":
                case "aac":
                    return (
                        <audio className={classes.modalFile} controls>
                            <source
                                src={base64}
                                type={`audio/${type.toLowerCase()}`}
                            />
                        </audio>
                    );
                case "sheet":
                case "doc":
                case "docx":
                case "pptx":
                case "ppt":
                case "xls":
                case "xlsx":
                case "csv":
                    return (
                        <iframe title={'iframe-' + name}
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${base64}`}
                            className={classes.modalFile + ' ' +  classes.iframe }
                        />
                    );
                case "note":
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon
                                icon={faStickyNote}
                                className={classes.faIconSize}
                            />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );
                case "pages":
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );
                default:
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );
            }
        }catch (e) {
            setBase64PreviewFromFtp(null);
            setOpenBase64PreviewFromFtp(false);
        }
    };

    const handleCloseFTPPreviewModal = () => {
        setOpenBase64PreviewFromFtp(false)
    }

    const openInNewTab = (e, url) => {
        e.preventDefault()
        e.stopPropagation()
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const textStyle = {
        fontSize: col.fontSize > -1 ? col.fontSize : props.defaultFontsize,
    }

    switch (format.format) {
        case 'file-uri':
            if (isDefined(value && value.length > 0)) {
                let fileSource = props.columns.find(column => column.format === 'file-source');
                if(fileSource){
                    switch (props.row[fileSource.id]) {
                        case 'PLUGNOTE':
                            return (
                                <div className={classes.cellPreviewCtn}>
                                    <span onClick={(e) => openInNewTab(e, value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener">
                                        <span className="material-icons md-small white">link</span>
                                    </span>
                                </div>
                            )
                        case 'URL':
                            return (
                                <div className={classes.cellPreviewCtn}>
                                    <span onClick={(e) => openInNewTab(e, value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener"><span className="material-icons md-small white">link</span></span>
                                </div>
                            )
                        case 'FTP':
                            return (
                                <div className={classes.cellPreviewCtn}>
                                    <span className={classes.urlButton} rel="noreferrer" onClick={(e) => handleOpenBase64Preview(e, value.toString())}><span className="material-icons md-small white">link</span></span>
                                    {
                                        openBase64PreviewFromFtp &&
                                        <Modal
                                            key={props.rindex + '-' + fileSource.id}
                                            keepMounted={true}
                                            open={openBase64PreviewFromFtp}
                                            onClose={handleCloseFTPPreviewModal}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup}>
                                                <div className={classes.scaledImg}>
                                                    {renderAnnexeImagePreview(props.row, props.columns, base64PreviewFromFtp, uriPreviewFromFtp)}
                                                </div>
                                            </div>
                                        </Modal>
                                    }
                                </div>
                            )
                        default:
                            break;
                    }
                }
                break;
            } else return null
        case 'url':
            if (isDefined(value && value.length > 0)) {
                return (
                    <div className={classes.cellPreviewCtn}>
                        <span style={textStyle}>{value}</span>
                        <span onClick={() => openInNewTab(value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener">
                            <span className="material-icons md-small white">link</span>
                        </span>
                    </div>)
            } else return null
        case 'file-source':
        case 'file-refid':
        case 'file-name-text':
        case 'file-comment-text':
        case 'file-type-text':
        case 'file-size-num':
        case 'file-create-date':
        case 'date':
        case 'duration':
        case 'extdata':
        case 'intdata':
        case 'formula':
        case 'mail':
        case 'numeric':
        case 'radio':
        case 'select':
        case 'tel':
        case 'text':
        case 'textarea':
        case 'time':
        case 's33data':
            return <span style={textStyle}>{value}</span>
        case 'presel':
            if (format.mask.multiselect === true && Array.isArray(value)) return(
            <div className={classes.selectsCtn}>
                {value.length > 0 &&
                value
                    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    .map((value, index) => (
                <div className={classes.selectItem} key={index}>
                    <span key={`select-item-${index}`} style={textStyle}>{value}</span>
                </div>))}
            </div>
            );
            else return (
                <div className={classes.selectsCtn}>
                    <div className={classes.selectItem}>
                        <span key={`select-item`} style={textStyle}>{value}</span>
                    </div>
                </div>
            )
        case 'numeric-ref':
            let tmpValue = value;
            if (typeof tmpValue !== 'undefined' &&  tmpValue == '') {
                let target = col?.configuration?.target;
                if(typeof target !== "undefined"){
                    let field = props.fields.find((field, index) => Number(field.id) === Number(target) );
                    if(field){
                        tmpValue = field.value;
                    }
                }
            }
            return <span style={textStyle}>{tmpValue}</span>
        case 'intdatamaj':
            if(typeof value.value !== "undefined" && value.updatedDate !== "undefined"){
                return <span style={textStyle}>{value.value} {value.updatedDate}</span>
            }else if(typeof value.value !== "undefined" && value.updatedDate === "undefined"){
                return <span style={textStyle}>{value.value}</span>
            }else{
                return <span style={textStyle}/>
            }
        default:
            return(<span className={classes.columnHeader}>{`<!> ERROR <!>`}</span>)
    }
}

const ConfigModal = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [email, ] = useState(props.value);
    const [phone, setPhone] = useState("");
    const [rules, setRules] = useState(-1);
    const [shareList, setShareList] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [hasChanged, setHasChanged] = useState(false)
    const [external, ] = useState(props?.external || false)

    const handleChangeRules = (event) => {
        setRules(event)
        setHasChanged(true)
    }

    const handleSubmitShare = () => {
        if (getPlugnote() !== null) {
            let tmpRules = Number(rules.toString());

            if ((email.length > 0 && tmpRules !== -1 && !external) || (email.length > 0 && tmpRules !== -1 && isValidPhoneNumber(phone) && external)) {
                let payload =  {
                    read_fiche: "1",
                    edit_fiche: tmpRules >= 1 ? "1" : "0",
                    active: "1",
                    corporate: "0",
                    datahash: getPlugnote().hash,
                    email : props.value,
                    phone : isValidPhoneNumber(phone) && phone.length > 0 ? phone : ""
                };

                trackPromise(
                    postShareDataWithEmailUser(payload).then((response) => {
                        if (response.data.result) {
                            setShareList(response.data.data);
                            let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                            switch (code.toString()) {
                                case "10":
                                    setRules(0);
                                    break;
                                case "11":
                                    setRules(1);
                                    break;
                                default:
                                    break;
                            }
                            setEditMode(true);
                            props.setPwCode(response.data.data.code);
                            props.setPwField(response.data.data.code + ' ' + t('smailvalidmulti.unsent'))
                        }else if (!response.data.result) {

                            if(response.data.reason === 'user not exist'){
                                confirmAlert({
                                    title: t('global.plugnotes'),
                                    message: t('common:global.user-not-exist'),
                                    buttons: [
                                        {
                                            label: t('global.ok'),
                                        },
                                    ]
                                });
                            }else if(response.data.reason === 'datahash error'){
                                confirmAlert({
                                    title: t('global.plugnotes'),
                                    message: t('common:global.plugnote-not-exist'),
                                    buttons: [
                                        {
                                            label: t('global.ok'),
                                        },
                                    ]
                                });
                            }else{
                                confirmAlert({
                                    title: t('global.plugnotes'),
                                    message: t('common:global.error'),
                                    buttons: [
                                        {
                                            label: t('global.ok'),
                                        },
                                    ]
                                });
                            }


                        }
                    }).catch((response) => {
                        alert('error');
                    })
                );
            }
        }

    };

    const handleUpdateShare = (share) => {
        let tmpRules = Number(rules.toString());
        if ((email.length > 0 && tmpRules !== -1 && !external) || (email.length > 0 && tmpRules !== -1 && isValidPhoneNumber(phone) && external)) {
            let payload = {
                read_fiche: "1",
                edit_fiche: tmpRules >= 1 ? "1" : "0",
                active: "1",
                corporate: "0",
                datahash: props.plugnote.hash,
                email : props.value,
                hash : share.hash,
                phone : isValidPhoneNumber(phone) && phone.length > 0 ? phone : ""
            };
            trackPromise(
                putShareDataWithEmailUser(payload).then((response) => {
                    if (response.data.result) {
                        setShareList(response.data.data);
                        let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                        switch (code.toString()) {
                            case "10":
                                setRules(0);
                                break;
                            case "11":
                                setRules(1);
                                break;
                            default:
                                break;
                        }
                        if (response.data.data.phone !== null) {
                            setPhone(response.data.data.phone)
                        }
                        setEditMode(true);
                        props.setPwCode(response.data.data.code);
                    }
                }).catch((response) => {
                    alert('error');
                })
            );
        }
        setHasChanged(false)
    }

    const handleCancelShare = (share) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('share.model-share-confirm-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        trackPromise(
                            deleteShareDataWithEmailUser(share.hash).then((response) => {
                                if (response.data.result) {
                                    props.setPwCode("");
                                    props.close();
                                }
                            }).catch((response) => {
                                alert('error');
                            })
                        );
                    }
                },
                { label: t('global.cancel')},
            ]
        });
    };

    const handleOpenMailModal = () => {
        props.setPwField(shareList.code)
        props.openMail()
    }

    const handleOnCLose = (_, reason) => {
        console.log('reason to close', reason);
        if (reason && reason === 'backdropClick') { return }
        props.close()
    }

    useEffect(() => {
        if (props?.plugnote?.hash && props.value !== "") {
            trackPromise(
                getShareDataWithEmailUser(props.plugnote.hash,props.value).then((response) => {
                    if (response.data.result) {
                        setShareList(response.data.data);
                        let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                        switch (code.toString()) {
                        case "10":
                            setRules(0);
                            break;
                        case "11":
                            setRules(1);
                            break;
                        default:
                            break;
                        }
                        if (response.data.data.phone !== null) {
                            setPhone(response.data.data.phone)
                        }
                        setEditMode(true);
                        props.setPwCode(response.data.data.code);
                    }
                }).catch((response) => {
                    console.error(
                        "Could not Download the Excel report from the backend.",
                        response
                    );
                })
            )
        }
    },[])
    // console.log("share pw", shareList);

    return(
        <Dialog open={props.open} onClose={handleOnCLose} fullWidth={true} maxWidth="md" disableEscapeKeyDown >
            <Header>
            </Header>
            <div className={classes.mSeparatorWrapper}/>
            <Content>
                <div>
                    <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                        <div className="popup_wrapper_content_small">
                            <div className="sous-header_content">
                                <div>
                                    <span className="titres">
                                        {t('common:smailvalidmulti.config-title')}{email}
                                        {
                                            external &&
                                            <PlugTooltip title={t('smailvalidmulti.unregistered')} placement="bottom">
                                                <span id="icon-create" className="material-icons md-small" style={{ color: colors.bloodOrange, fontSize: 24 }}>
                                                    info
                                                </span>
                                            </PlugTooltip>
                                        }
                                        <br />
                                    </span>
                                </div>
                                <div>
                                    <span id="icon-close" className="material-icons md-50 lightgrey" onClick={props.close}>
                                        close
                                    </span>
                                </div>
                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <br/>
                            <div className="flexSpacebetween">
                                <div className=" split-40">
                                    <span className="grey">{t('plugcode.rules')}</span>
                                    <div className={classes.RadioGroupModal} name={t('common:plugcode.rules')} value={rules}>
                                        <div className={classes.showOption} onClick={() => handleChangeRules(0)}>
                                            <input type='radio' size='small' value={0} checked={rules === 0} className={classes.radioIconModal} onChange={(e) => null}/>
                                                <span className={classes.radioLabelModal}>
                                                    { t('common:plugcode.rule-read') }
                                                    <span> (</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        visibility
                                                    </span>
                                                    <span>) </span>
                                                </span>
                                        </div>
                                        <div className={classes.showOption} onClick={() => handleChangeRules(1)}>
                                            <input type='radio' size='small' name={"show-empty-option"} value={1} checked={rules === 1} className={classes.radioIconModal} onChange={(e) => null}/>
                                                <span className={classes.radioLabelModal}>
                                                    { t('common:plugcode.rule-read-edit') }
                                                    <span> (</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        visibility
                                                    </span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        create
                                                    </span>
                                                    <span>) </span>
                                                </span>
                                        </div>
                                    </div>
                                    { external &&
                                        <div className={classes.mainPhoneContainer}>
                                            <span className="grey">{ t('common:plugcode.phones-access') }</span>
                                            <div className={classes.phoneContainer}>
                                                <PhoneInput
                                                    className={`${classes.phoneComponent} phoneComponent phoneSmailComponent`}
                                                    defaultCountry="BE"
                                                    international
                                                    value={phone}
                                                    placeholder={t('fields.format-choice-placeholder-phone')}
                                                    onClick={(e) => e.target.placeholder='' }
                                                    onBlur={(e) => e.target.placeholder=t('fields.format-choice-placeholder-phone')}
                                                    onChange={(value) => {
                                                        setPhone(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                {editMode === false ?
                                <div className="split-60">
                                    <div className="confirmation_pop-up" onClick={handleSubmitShare}>
                                        {
                                            external ?
                                                <button className={rules === -1 || !isValidPhoneNumber(phone) || phone?.length === 0 ? "grey-button" : "button"}>{ t('common:global.create') } PW- code</button>
                                                :
                                                <button className={rules === -1 ? "grey-button" : "button"}>{ t('common:global.create') } PW- code</button>
                                        }

                                    </div>
                                </div>
                                :
                                <div className="split-60">
                                    {/* <div>
                                        <span className="grey">{ t('common:smail.share-pw-with') } :</span>
                                    </div> */}
                                    <span className="grey">{t('smailvalidmulti.current-link')}</span>

                                        {shareList !== null &&
                                            <div className={`grey ${classes.pwcodeContainer}`}>
                                                {/* <span className={"info-maximum-width "}>{shareList.email}</span> */}
                                                <span className="grey">{shareList.code}</span>
                                                <div>
                                                    <span>(</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                    visibility
                                                    </span>
                                                    {shareList.editFiche === 1 &&
                                                    <span id="icon-create" className="material-icons md-small green">
                                                        create
                                                    </span>}
                                                    <span>)</span>
                                                </div>
                                                <span id="icon-create" className="material-icons md-small red" onClick={() => handleCancelShare(shareList)}>
                                                    delete
                                                </span>
                                            </div>
                                        }

                                </div>}

                            </div>
                            {
                                external &&
                                <span>
                                    <div>
                                        <span id="icon-create" className="material-icons md-small" style={{ color: colors.bloodOrange, fontSize: 24 }}>
                                            info
                                        </span>
                                        <span style={{ whiteSpace: 'pre-line' }}>{t('smailvalidmulti.unregistered')}</span>
                                        {
                                            phone.length < 9 &&
                                            <span style={{ whiteSpace: 'pre-line' }}> - {t('smailvalidmulti.enter-mobile')}</span>
                                        }
                                    </div>
                                    <span style={{ whiteSpace: 'pre-line' }}>{t('smailvalidmulti.info-external')}</span>
                                </span>
                            }
                        </div>

                        <div className="popup_wrapper_content">
                            {editMode === false ?
                            null
                            :
                            <div className={classes.modalBtnContainer}>

                                <div className="confirmation_pop-up">
                                    <button className="button-exit" onClick={() => handleUpdateShare(shareList)} style={hasChanged ? null : {display: 'none'}}>{ t('common:smailvalidmulti.update-rules') }</button>
                                </div>

                                <div className="confirmation_pop-up" style={{float: 'right'}}>
                                    <button className="button" onClick={handleOpenMailModal}>{ t('common:annexe.send-email') }</button>
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                </div>
            </Content>
        </Dialog>
    );
}

const SmailModal = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    let content = "";

    if (props.field.options.body) {
        content = strReplaceContentField(props.field.options.body, props?.fields, props?.model, props?.plugnote, props?.user, props?.pwCode);
    }
    const [body, setBody] = useState(content);
    const [sujet, setSujet] = useState(props.field.options.title || "");
    const [expediteur, ] = useState(props.field.options.from || "noreply@plugnotes.com");
    const [viewNotes, ] = useState(props.field.options.note ? props.field.options.note === 'Y' : false);
    const [mail, setMail] = useState(props.value || "");
    const [mailCc, setMailCc] = useState(props.field.options.cc || "");
    const [attachments, setAttachments] = useState([]);
    const [attachment, setAttachment] = useState("");
    const environment = getCurrentEnvironnement();
    let nowTime = new Date()
    let pwField = props.pwCode
    if (props.pwCode !== '') pwField = pwField + ' (' + nowTime.toLocaleString([], {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) + ')'

    const handlePressValidate = () => {
        if (mail.length > 0) {
            let message = t('global.send');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let bodyReplace = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            let bodyUrlReplace = urlify(bodyReplace);
                            let payload = {};
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            payload.body = bodyUrlReplace.length > 0 ? bodyUrlReplace : null;
                            payload.from = expediteur.length > 0 ? expediteur : null;
                            payload.cc = mailCc.length > 0 ? mailCc : null;
                            payload.to = mail.length > 0 ? mail : null;
                            payload.attachments = attachments.length > 0 ? attachments : null;
                            payload.attachment = attachment !== "" ? attachment : null;
                            props.handleCloseWhenSendModalSmail();
                            props.attemptSendMailSimple(payload, environment?.userhash)
                            if (props.pwCode !== '') props.setPwField(pwField)
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }
        else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    return(
    <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth="md">
        <div className={classes.modalBody}>
            <div className={classes.header}>
                <div className="popup_wrapper_content justify_content_space">
                    <button
                        className="button-exit"
                        onClick={props.close}
                    >
                        {t("common:global.cancel")}
                    </button>
                    <button className="button float_right" onClick={handlePressValidate}>{ t('common:global.send') }</button>
                </div>
            </div>

            <div className="popup_wrapper_content" style={{paddingTop: 10}}>
                <div className="flex_display_direction_row justify_content_space align-items-center">
                    <InputLabel  style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{t('common:mails.destinatairefrom')}</InputLabel>
                    <div className={classes.inputContainer}>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            value={expediteur}
                            disabled={true}
                            // onChange={(event) => setExpediteur(event.target.value)}
                        />
                    </div>
                </div>
                <div className="flex_display_direction_row justify_content_space align-items-center">
                    <InputLabel  style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.destinataire') }</InputLabel>
                    <div className={classes.inputContainer}>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            value={mail}
                            disabled={false}
                            onChange={(event) => setMail(event.target.value)}
                        />
                    </div>
                </div>
                <div className="flex_display_direction_row justify_content_space align-items-center">
                    <InputLabel style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.destinatairecc') }</InputLabel>
                    <div className={classes.inputContainer}>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            value={mailCc}
                            disabled={false}
                            onChange={(event) => setMailCc(event.target.value)}
                        />
                    </div>
                </div>

                <div className="flex_display_direction_row justify_content_space align-items-center">
                    <InputLabel style={{fontWeight : 'bold'}} shrink className={classes.labelContainer}>{ t('common:mails.sujet') }</InputLabel>
                    <div className={classes.inputContainer}>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setSujet(event.target.value)}
                            value={sujet}
                        />
                    </div>
                </div>

                <div className="flex_display_direction_row justify_content_space align-items-center">
                    <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.message') }</InputLabel>
                    <span style={{height : '25px', fontSize: 10, fontStyle: 'italic', fontWeight: 'bold', color: colors.red}}>
                        {body.includes('<sup>') && t('common:smail.info-tags')}
                    </span>
                </div>

                <TextField
                    id="outlined-multiline-static"
                    multiline={true}
                    style={{ fontSize: 11 }}
                    minRows={10}
                    value={body}
                    margin="none"
                    fullWidth={true}
                    inputlabelprops={{className: classes.inputArea}}
                    InputProps={{disableUnderline: true}}
                    className={classes.textFieldArea}
                    onChange={(event) => setBody(event.target.value)}
                />
            </div>
            {(props.annexes?.length > 0 || props.customAnnexe !== null) &&
            <div className="popup_wrapper_content">
                <div className="flex_display_direction_row justify_content_space align-items-center">
                    <InputLabel  style={{fontWeight : 'bold'}} shrink>{ t('common:mails.attachments') }</InputLabel>
                    <span style={{height : '25px'}}/>
                </div>
                <div style={{flexDirection : 'column', display : 'flex'}}>
                    {
                        props.annexes?.length > 0 &&
                        props.annexes.map((annexe, index) => {
                            if ((annexe.type === 'note' && viewNotes) ||  annexe.type !== 'note') {
                                return (
                                    <div style={{flexDirection : 'row', display : 'flex', justifyContent : 'flex-start', alignItems : 'center'}} key={'annexes-' + props.field.index + '-' + index}>
                                        <Checkbox
                                            className={classes.multipleCheckBox + ' ' + classes.switchBase}
                                            //color={colors.main}
                                            //id={value + '-' + indexation + '-checkbox-'+props.field.id}
                                            name={annexe.hash}
                                            checked={attachments.includes(annexe.hash)}
                                            onChange={(event) => {
                                                if (!attachments.includes(annexe.hash)) {
                                                    setAttachments([...attachments, annexe.hash]);
                                                }
                                                else {
                                                    let tmp = attachments.filter(item => item !== annexe.hash);
                                                    setAttachments(tmp);
                                                }
                                                //
                                            }}
                                        />
                                        <div className={classes.attItemsContainer} onClick={() => {
                                            if (!attachments.includes(annexe.hash)) {
                                                setAttachments([...attachments, annexe.hash]);
                                            }
                                            else {
                                                let tmp = attachments.filter(item => item !== annexe.hash);
                                                setAttachments(tmp);
                                            }
                                        }}>
                                            <div className={classes.attItem}>
                                                <div className={classes.attIconType}>
                                                    {annexe.type === 'note' && viewNotes && props.renderAnnexeIconPreview(annexe)}
                                                    {annexe.type !== 'note' && props.renderAnnexeIconPreview(annexe)}
                                                </div>

                                                <div className={classes.attAnnexName} style={{marginRight : '10px'}}>
                                                    { annexe.type === 'note' && viewNotes &&
                                                    <span>{annexe.name}</span>
                                                    }

                                                    { annexe.type !== 'note' &&
                                                    <span>{annexe.name}.{annexe.type}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else return null;
                        })
                    }
                    {
                        props.customAnnexe !== null && props.customAnnexe !== undefined &&
                        <div style={{flexDirection : 'row', display : 'flex', justifyContent : 'flex-start', alignItems : 'center'}} key={'annexes-' + props.field.index + '-' + (props.annexes?.length + 1)}>
                            <Checkbox
                                className={classes.multipleCheckBox + ' ' + classes.switchBase}
                                //color={colors.main}
                                //id={value + '-' + indexation + '-checkbox-'+props.field.id}
                                name={ t('common:smail.template-9002-content') }
                                checked={attachment.includes(props.customAnnexe)}
                                onChange={(event) => {
                                    if (!attachment.includes(props.customAnnexe)) {
                                        setAttachment(props.customAnnexe);
                                    }else {
                                        setAttachment("");
                                    }
                                    //
                                }}
                            />
                            <div className={classes.attItemsContainer} onClick={() => {
                                if (!attachment.includes(props.customAnnexe)) {
                                    setAttachment(props.customAnnexe);
                                }
                                else {
                                    setAttachment("");
                                }
                            }}>
                                <div className={classes.attItem}>
                                    <div className={classes.attIconType}>
                                        <FontAwesomeIcon
                                            icon={faFilePdf}
                                            className={classes.faIconSize}
                                        />
                                    </div>

                                    <div className={classes.attAnnexName} style={{marginRight : '10px'}}>
                                        <span>{ t('common:smail.template-9002-content') }</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            }
            <div className={classes.modalFooter}>
                <div className="popup_wrapper_content justify_content_space">
                    <button
                        className="button-exit"
                        onClick={props.close}
                    >
                        {t("common:global.cancel")}
                    </button>
                    <button className="button float_right" onClick={handlePressValidate}>{ t('common:global.send') }</button>
                </div>
            </div>
        </div>
    </Dialog>
    );
}

const restylizeNumber = (number) => {
    if ((typeof number === "number" || typeof number === 'string') && number !== '') {
        let nombre = 0
        let coma = false
        if (typeof number === "number") nombre = number;
        else if (number.charAt(number.length -1) === ',') {
            nombre = parseFloat(number.slice(0,-1))
            coma = true
        }
        else nombre = parseFloat(number.replace(',','.'))
        // console.log('nombre: ', number, nombre);
        let restyled = ''
        let ent = 0
        ent = Math.floor(nombre);
        // console.log('ent', ent);
        let stInt = ''
        let decim = number.toString().split('.')[1]
        if (!number.toString().includes('.')) decim = number.toString().split(',')[1]
        // console.log('decim', decim);
        if (nombre > 999) {
            ent += ''
            let rank = 1
            for (let index = ent.length - 1; index >= 0 ; index--) {
                const nb = ent.charAt(index);
                if (rank % 3 === 0 && index >= 1 && rank !== 0) stInt = '.' + nb + stInt;
                else stInt = nb + stInt.toString()
                rank ++;
                // console.log(rank, '(',index, ') ',nombre,'- current char', nb, 'result =', stInt);
            }
        }
        else stInt = ent.toString()
        if (decim !== undefined) restyled = stInt + ',' + decim;
        else restyled = stInt
        // console.log('restyled', restyled);
        return restyled.concat(coma ? ',' : '')
    }
    return ''
}

const RenderFolderActionTemplate = (props) => {
    const useInternalStyles = makeStyles({
        root: {
            '&$selected': {
                backgroundColor: '#B9E5E4',
                '&:hover': {
                    backgroundColor: '#B9E5E4',
                }
            },
        },
        selected: {},
    });

    const classes = useInternalStyles();


    const [collapseFolderIndex, setCollapseFolderIndex] = useState(null);

    const handleClickOpenSubList = (index) => {
        if(index === collapseFolderIndex){
            setCollapseFolderIndex(null)
        }else setCollapseFolderIndex(index);
    }

    return (
        <div className="margin-left-20" key={props.index}>
            <ListItem
                button
                selected={props.element === collapseFolderIndex}
                onClick={(event) => handleClickOpenSubList(props.element)}
                classes={{ root: classes.root, selected: classes.selected }}
            >
                <ListItemText primary={props.element.name} />
                {collapseFolderIndex !== null && props.element === collapseFolderIndex ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={collapseFolderIndex !== null && props.element === collapseFolderIndex} timeout="auto" unmountOnExit>
                <List component="nav" aria-label="secondary mailbox folder">
                    {
                        props.element.files.map((annexe, anindex) => {
                            if(annexe.content === 'FILE'){
                                return <RenderFileActionTemplate key={anindex} {...props} element={annexe} index={anindex} handleListItemFromFtpClick={props.handleListItemFromFtpClick}/>
                            }else return <RenderFolderActionTemplate key={anindex} {...props} element={annexe}  index={anindex} handleListItemFromFtpClick={props.handleListItemFromFtpClick}/>
                        })
                    }
                </List>
            </Collapse>
        </div>
    )
}

const RenderFileActionTemplate = (props) => {
    return(
        <div className="margin-left-20" key={props.index}>
            <ListItem
                button
                //selected={selectedIndex === 3}
                onClick={(event) => props.handleListItemFromFtpClick(props.element,props.index, props.row, props.col, props.rindex)}
            >
                <ListItemText primary={props.element.name + ' ('  + props.element.type + ' )'} />
            </ListItem>
        </div>
    )
}

const RenderEditCellComponent = (props) => {
    // console.log('RenderEdit Cell props', props);
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    let template;
    const { t,  } = useTranslation(['common']);

    let tmpValue = "";
	if (typeof props.value !== "undefined" && (props.col.format !== 'numeric' || props.col.format !== 'numeric-ref' ) && props.value.length > 0) {
		tmpValue = props.value;
	}
    else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric') {
		tmpValue = restylizeNumber(props.value);
	}else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric-ref') {
        //console.log(props.value);
        if(props.value == ""){
            let target = props?.col?.configuration?.target;
            //console.log('target' , target);
            if(typeof target !== "undefined"){
                let field = props.fields.find((field, index) => Number(field.id) === Number(target) );
                if(field){
                    //console.log('field',field);
                    tmpValue = field.value;
                }
            }
        }else{
            tmpValue = props.value;
        }
    }
    //Props const
    const field = props.field
    console.log(field);
    const fields = props.fields
    const model = props.model
    const plugnote = props.plugnote
    const user = props.user

    //console.log('tmpValue',tmpValue);
	const [value, setValue] = useState(tmpValue);
    const [valueChange, setValueChange] = useState(tmpValue);
    const [disabled, ] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [owner, ] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [ref, ] = useState(props?.col?.configuration?.ref || "");
    const [query, ] = useState(props?.col?.configuration?.query || "");
    const [dbcolumn, ] = useState(props?.col?.configuration?.column || "");
    const [dbcolumns, ] = useState(props?.col?.configuration?.columns || "");
    const [format, ] = useState(getCellColumn(props.id, props.columns))
    const [options, ] = useState(props?.col?.configuration || {ref : "", query : "LIKE", columns : "", column : "", dest : ""});
    const [dest, ] = useState(props?.col?.configuration?.dest || "");

    const [hideSearch, ] = useState(!props?.mode?.includes("plugcode") && !props?.mode?.includes("shared") ? false : props?.model?.authoriseExternalDbInPf === undefined ? false  : !props?.model?.authoriseExternalDbInPf);
    const [formula, ] = useState(props?.col?.configuration?.formula || null);
    const [ownerId, ] = useState(props.ownerId);
    const [open, setOpen] = useState(false);
    const [openDefault, ] = useState(props.open === true ? true : false);
    const [openSelect, setOpenSelect] = useState(false)
    const [loading, setLoading] = useState(false);
    const [optionFormule, ] = useState({licenseKey: 'gpl-v3'});
    const [duration, setDuration] = useState(value !== '' && value !== undefined && value !== null && props.col.format === 'duration' ? props?.col?.regex === '99:99'? [value.split(':')[0] || '', value.split(':')[1]] || '' : [value.split(':')[0] || '', value.split(':')[1] || '', value.split(':')[2] || ''] : ['', '',''])
    const [accept, setAccept] = useState(false)
    const columns = props.columns

    //SmailValid
    const [contentTooltip, setContentTooltip] = useState('');
    const [customAnnexe, setCustomAnnexe] = useState(null);
    const [openConfigurator, setOpenConfigurator] = useState(false);
    const [openExternalConfigurator, setOpenExternalConfigurator] = useState(false);
    const [openPromised, setOpenPromised] = useState(false);
    const [openMail, setOpenMail] = useState(false);
    const [pwCode, setPwCode] = useState(props.row[1000]?.includes('PW') ? props.row[1000] : '');
    const [pwField, setPwField] = useState(props.row[1000] || '')
    const [registered, setRegistered] = useState(pwField !== '')
    const [templateOn, ] = useState(props.field?.options?.template || "");
    const [openListAnnexes, setOpenListAnnexes] = useState(false);
    const [openListAnnexesFromFtp, setOpenListAnnexesFromFtp] = useState(false);
    // const [annexesFromFtp, setAnnexesFromFtp] = useState(null);
    const [foldersFromFtp, setFoldersFromFtp] = useState([]);
    const [openBase64PreviewFromFtp, setOpenBase64PreviewFromFtp] = useState(false);
    const [base64PreviewFromFtp, setBase64PreviewFromFtp] = useState(null);


    const { promiseInProgress } = usePromiseTracker();
    let unregisteredMsg = t('smailvalidmulti.unregistered')
    // let unsentMsg = t('smailvalidmulti.unsent')
    // let emptyMsg = t('smailvalidmulti.empty-mail')

    let titleCtn,
        fieldCtn,
        widerCtn,
        editorCtn

    // styles différents selon que l'on édite une cellule seule ou toute une ligne
    switch (props.edit) {
        case "row":
            titleCtn = classes.fieldTitleContainer
            fieldCtn = classes.fieldValueContainer
            widerCtn = classes.widerFieldValueContainer
            editorCtn = classes.editorCtn
            break;
        case "cell":
            titleCtn = classes.singleTitleCtn
            fieldCtn = classes.singleValueCtn
            widerCtn = classes.singleWiderValueCtn
            editorCtn = classes.singleEditorCtn
            break;
        case "preview":
            fieldCtn = classes.sendMailPreviewCtn
            break
        default:
            break
    }

    // console.log('fieldCtn', fieldCtn);

    const handleOnBlur = (e) => {
        if (e.key === 'Enter') {
            if (format.format === 'extdata') handleOpenSearchModel();
            console.log('blur...');
            if (props.edit === 'cell') {
                props.handleValidate();
            }
        }
    }

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpen(true);
    }

    const handleChangeTableValue = (value, event) => {
        // solution pour le curseur qui saute à la fin de l'input ('tel' & 'text' uniquement)
        if (event !== undefined) {
            const caret = event.target.selectionStart
            const element = event.target
            window.requestAnimationFrame(() => {
                element.selectionStart = caret
                element.selectionEnd = caret
            })
            element.focus();
        }
        if (!props.disabled) props.handleChangeChildrenValue(value, props.col, props.row, props.rindex);
    }

    const handleGetValueFromTable = (col, fields, value) => {
        if(value !== ""){
            let target = col?.configuration?.target;
            let targettable = col?.configuration?.targettable;
            //console.log('target' , target);
            if(typeof target !== "undefined"){
                let field = fields.find((field, index) => Number(field.id) === Number(target) );
                if(field){
                    console.log(field.value);
                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                    if(tmpValue.length > 0 && Array.isArray(tmpValue)){
                        let options = field.options?.lines ? field.options.lines : field.options;
                        setValue(tmpValue[(value - 1)][options[(targettable - 1)].id]);
                        handleChangeTableValue(tmpValue[(value - 1)][options[(targettable - 1)].id]);
                    }

                }
            }
        }
    }

    const handleChangeChildrenRowWithPreValueValue = (value) => {
        if(value === 'URL'){

            let data = {...props.row};

            props.columns.forEach((col, index) => {
                switch (col.format) {
                    case 'file-source':
                        data[col.id] = value;
                        break;
                    case 'file-refid':
                        break;
                    case    'file-name-text':
                        data[col.id] = '-';
                        break;
                    case    'file-comment-text':
                        data[col.id] = '';
                        break;
                    case    'file-type-text':
                        data[col.id] = '-';
                        break;
                    case    'file-size-num':
                        data[col.id] = '0';
                        break;
                    case    'file-create-date':
                        data[col.id] = '-';
                        break;
                    case    'file-uri':
                        data[col.id] = '';
                        break;
                    default :
                        data[col.id] = '';
                        break;
                }

            })

            if (!props.disabled) props.handleChangeChildrenRowValue(data, props.col, props.row, props.rindex);

        }else{
            if (!props.disabled) props.handleChangeChildrenValue(value, props.col, props.row, props.rindex);
        }


    }

    const handleSelectData = (object) => {
        let jsonObjectToArray = Object.values(object)
        let destinations = [];
        if (dest.length > 0) {
            let champs = dest.split('-');
            champs.map((elm, index) => {
                elm = elm.replaceAll(' ', '')
                let field = elm.split(':');
                if (field.length > 0 && field.length <= 3) {
                    let dest = field[0];
                    let col = field[1];
                    let copyValue = col?.split(';') || [col];
                    let value = "";
                    let row = Object.values(props.row);
                    let keys = Object.keys(props.row);

                    if (typeof row[(dest - 1)] !== "undefined") {
                        copyValue.map((item, index) => {
                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                            let data = jsonObjectToArray[alphaNumIndexDest];
                            if (typeof data !== "undefined") {
                                let temp = data;
                                if (temp !== "" && typeof temp !== "undefined") {

                                        if (temp !== "") {
                                            if (item === item.toUpperCase()) {
                                                value = value === "" ? value.concat(temp) : value.concat(' ~ \n'+ temp);
                                            } else {
                                                value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    destinations.push({
                        "dest" : keys[(dest - 1)],
                        "value" : value
                    })
                }
            })
        }
        // console.log(destinations);
        if (destinations.length > 0) {
            let value = {...props.row};
            destinations.map((destination, index) => {
                value[destination.dest] = destination.value;
            })
            props.handleChangeChildrenRowValue(value, props.col, props.row, props.rindex);
        }
        //setOpen(false);
    }

    const handleCalculateValue = async () => {
        setLoading((prevLoading) => !prevLoading);
        let tmp = [];
        columns.map((elm, index) => {
            // console.log('handle calculate', elm, props.row);
            let tmpValue = props.row[elm.id];
            if (Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }
            if (elm.format === 'numeric' && props.row[elm.id] !== undefined && typeof props.row[elm.id] !== 'number') {
                tmpValue = props.row[elm.id].replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });
        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };
            let sheetAData = data;
            let newFormula =  formula;
            const hfInstance = HyperFormula.buildEmpty(optionFormule);
            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);

            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );
            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')) {
                    handleChangeTableValue(mySum);
                }
                else handleChangeTableValue("");
            }
            catch (e) {
                //setResult(e.message)
                handleChangeTableValue("");
            }
            hfInstance.destroy();
        }
        setLoading((prevLoading) => !prevLoading);
    }

    const handleOpenModalConfigurator = () => {
        setOpenConfigurator(true);
    }

    const renderEntryLabelMarkup = (label) => {

    }

    const checkRegistered = (email) => {
        let url = ApplicationConfig.apiUrl + 'utils/check/user/exist.json?email=' + email
        if (email === 'tech2@plugnotes.com' || email === 'camille@plugnotes.com' || email === 'camille-db@hotmail.com' || email === 'vdb@plugnotes.com') setRegistered(true);
        else
        trackPromise(
            axios
                .get(url,
                    {'headers': {
                        // 'X-AUTH-TOKEN': 'rKDvZfk/vfZaGN4riX/b1PSk9JuQPBHwCyqJFk/exRs/WRKOn5ta0gc45LGkRiqepcU='},
                        'X-AUTH-TOKEN': getToken()},
                    }
                )
                .then((response) => {
                    if (response) {
                        // console.log('check registration', response.data.result);
                        setRegistered(response.data.result)
                    }
                })
                .catch((response) => {
                    console.error(
                        "Could not check registration from the backend.",
                        response
                    );
                })
        )
    }

    const controlBeforeOpen = (e) => {
        e.preventDefault()
        e.stopPropagation()
        checkRegistered(value)
        setOpenMail(false);
        setOpenConfigurator(false);
        setOpenExternalConfigurator(false);
        //console.log('control before', props.control, 'mode', props.mode, props.share);
        if (props.mode !== "modal-shared-create") {
            if (props.share && registered) {
                if (typeof props.control !== "undefined" && props.control) {
                    switch (props.mode) {
                        case 'create':
                        case 'shared-create':
                            setOpenPromised(true);
                            if (getPlugnote() === null) {
                                props.controlCreate();
                            }
                            else {
                                props.controlUpdate();
                            }
                            break;
                        case 'update':
                        case 'shared-update':
                        case 'modal-shared-update':
                            setOpenPromised(true);
                            props.controlUpdate();
                            break;
                        default:
                            break;

                    }
                }
                else {
                    setOpenMail(true);
                }
            }
            else if (props.share && !registered) {
                setOpenExternalConfigurator(true)
            }
            else {
                setOpenMail(true);
            }
        }
        else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:global.send-before'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    const getInformations = () => {
        if (getPlugnote() !== null && registered) {
            if (props?.plugnote?.hash && props.value !== "") {
                trackPromise(
                    getShareDataWithEmailUser(getPlugnote().hash,props.value).then((response) => {
                        if (response.data.result) {
                            let rules =  null;
                            let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                            switch (code.toString()) {
                                case "10":
                                    rules = 0;
                                    break;
                                case "11":
                                    rules = 1;
                                    break;
                                default:
                                    break;
                            }

                            setContentTooltip(
                                <div>
                                    <p><span className="bold">{t('common:smail.share-to')} : <span className="normal">{props.value}</span></span></p>
                                    <p><span className="bold">{t('common:smail.share-code')} : <span className="normal">{response.data.data.code}</span></span></p>
                                    {
                                        rules !== null && rules === 0 &&
                                        <p><span className="bold">{t('common:smail.share-rules')} : <span className="normal">{t('common:plugcode.rule-read')}</span></span></p>
                                    }
                                    {
                                        rules !== null && rules === 1 &&
                                        <p><span className="bold">{t('common:smail.share-rules')} : <span className="normal">{t('common:plugcode.rule-read-edit')}</span></span></p>
                                    }
                                    <p><span className="bold">{t('common:smail.share-count')} : <span className="normal">{response.data.data.countopen.toString()}</span></span></p>

                                </div>
                            );
                            // setOpenTooltip(props.index);
                        }
                        else {
                            setContentTooltip(
                                <div>
                                    <p><span>{t('common:smail.share-no-to')} <span>{props.value}</span></span></p>
                                </div>
                            );
                            // setOpenTooltip(props.index);
                        }
                    }).catch((response) => {
                        setContentTooltip(
                            <div>
                                <p><span>{t('common:smail.share-no-to')} <span>{props.value}</span></span></p>
                            </div>
                        );
                        // setOpenTooltip(props.index);
                    })
                )
            }
        }
    }

    const handleOpenModalSmail = () => {
        if (templateOn !== "") {
            generateCustomAnnexeFromTemplate().then((result) => {
                let reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function() {
                    let base64data = reader.result;
                    setCustomAnnexe(base64data);
                    setOpenMail(true);
                }
            });
        }
        else {
            setCustomAnnexe(null);
            setOpenMail(true);
        }
    }

    const handleCloseModalSmail = () => {
        setOpenMail(false);
    }

    const handleCloseWhenSendModalSmail = () => {
        if (openConfigurator) {
            setOpenConfigurator(false);
        }
        setOpenMail(false);
    }

    const handleSendMail = (payload, userhash) => {
        props.attemptSendMailSimple(payload, userhash)
        handleCloseWhenSendModalSmail()
    }

    const generateCustomAnnexeFromTemplate = async () => {
        return Data9002PrintableGetURL(props);
    }

    const handleOpenListAnnexes = () => {
        setOpenListAnnexes(true);
    }

    const handleGetListOpenListAnnexesFromFtp = () => {
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'ftp/annexes?ownerid=' + props.model.owner,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }
            ).then((response) => {
                if(response.data.result){
                    setFoldersFromFtp(response.data.folders);
                    setOpenListAnnexesFromFtp(true);
                }
            }).catch((response) => {
            })
        )
    }

    const handleListItemClick = (index, row, col, rindex) => {
        let annexe = props.annexes[index];
        let tmpValue = {...props.row};
        let value = props.createDataForAnnexeWithContent(tmpValue, annexe);

        props.handleChangeChildrenRowValue(value, col, row, rindex);

        setOpenListAnnexes(false);

    }

    const handleListItemFromFtpClick = (annexe, index, row, col, rindex) => {
        let tmpValue = {...props.row};
        let value = props.createDataForAnnexeWithContent(tmpValue, annexe);
        props.handleChangeChildrenRowValue(value, col, row, rindex);
        setOpenListAnnexesFromFtp(false);
    }

    const handleOpenBase64Preview = (value, row) => {
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'ftp/preview/annexe?uri=' + value,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }
            ).then((response) => {
                if(response.data.result){
                    setBase64PreviewFromFtp(response.data.base64);
                    setOpenBase64PreviewFromFtp(true);
                }
                else {

                }
            }).catch((response) => {
            })
        )
    }

    const renderAnnexeImagePreview = (annexeToPreview, colonnes, base64, uri) => {
        try {
            let typeId = colonnes.find((col => col.format === 'file-type-text'));
            let nameId = colonnes.find((col => col.format === 'file-name-text'));

            let type = annexeToPreview[typeId.id];
            let name = annexeToPreview[nameId.id];

            switch (type.toLowerCase()) {
                case "jpg":
                case "JPG":
                case "jpeg":
                case "png":
                case "PNG":
                case "gif":
                    return (
                        <img
                            src={base64}
                            alt={name}
                        />
                    );

                case "mp4":
                case "mkv":
                case "avi":
                case "mov":
                    return (
                        <video className={classes.modalFile} controls>
                            <source
                                src={base64}
                                type={`video/${type.toLowerCase()}`}
                            />
                        </video>
                    );

                case "pdf":
                    return (
                        <iframe title={'iframe-' + name} src={uri} className={classes.modalFile + ' ' +  classes.iframe } />
                    );

                case "zip":
                case "rar":
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon
                                icon={faFileArchive}
                                className={classes.faIconView}
                            />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );

                case "mp3":
                case "aac":
                    return (
                        <audio className={classes.modalFile} controls>
                            <source
                                src={base64}
                                type={`audio/${type.toLowerCase()}`}
                            />
                        </audio>
                    );

                case "html":
                    return (
                        <iframe title={'iframe-' + name} src={base64}
                                className={classes.modalFile + ' ' +  classes.iframe }
                        />
                    );

                case "txt":
                    return (
                        <iframe
                            src={base64}
                            className={classes.modalFile + ' ' +  classes.iframe }
                        />
                    );

                case "sheet":
                case "doc":
                case "docx":
                    return (
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${base64}`}
                            className={classes.modalFile + ' ' +  classes.iframe }
                        />
                    );

                case "xls":
                case "xlsx":
                case "csv":
                    return (
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${base64}`}
                            className={classes.modalFile + ' ' +  classes.iframe }
                        />
                    );

                case "note":
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon
                                icon={faStickyNote}
                                className={classes.faIconSize}
                            />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );

                case "pages":
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );

                case "pptx":
                case "ppt":
                    return (
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${base64}`}
                            className={classes.modalFile + ' ' +  classes.iframe }
                        />
                    );
                default:
                    return (
                        <div className={classes.notRender}>
                            <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
                            <p class={classes.notRenderText}>
                                {t("multiple.file-not-previewed")}
                            </p>
                        </div>
                    );
            }



        }catch (e) {
            setBase64PreviewFromFtp(null);
            setOpenBase64PreviewFromFtp(false);
        }

    };

    const monthLength = (month, bissextile) => {
        if (month === 2) {
            if (bissextile === true || bissextile === undefined) return 29;
            else return 28
        }
        if (month %2 === 0 && month < 7) return 30
        if (month > 8 && month % 2 !== 0) return 30
        return 31
    }

    const isBissextile = (year) => {
        if (year % 4 === 0 && year % 100 !== 0) return true
        if (year % 400 === 0) return true
        return false
    }

    useEffect(() => {
        if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric-ref') {
                if(props.value == ""){
                    let target = props?.col?.configuration?.target;
                    //console.log('target' , target);
                    if(typeof target !== "undefined"){
                        let field = props.fields.find((field, index) => Number(field.id) === Number(target) );
                        if(field){
                            tmpValue = field.value;
                        }
                    }
                }else{
                    setValue(props.value);
                }
        }else if (props.value !== undefined) {setValue(props.value);}
        else setValue('')
    }, [props.value])

    useEffect(() => {
        if (props.col.format === 'duration') {
            if (value !== '' && value === undefined) {
                let one =   value.split(':')[0],
                    two =   value.split(':')[1],
                    three = value.split(':')[2]
                setDuration([one, two, three])
            }
        }else if (props.col.format === 'numeric-ref'){
                if(props.value == "" && value !== ""){
                    handleChangeTableValue(value);
                }
        }
    }, [value])

    useEffect(() => {
        if (props.id === '1001' && value !== '') {
            checkRegistered(value)
        }
        // if (props.id === '1001') {
        //     let _col = columns.find(col => col.id === 1000)
        //     //console.log('found code', _col, value, 'code:', pwCode, 'field', pwField);
        // }
    }, [])

    useEffect(() => {
        if (props.id === '1001') {
            let _col = columns.find(col => col.id === 1000)
        }
    }, [registered])


    useEffect(() => {
        if (openPromised) {
            if (!promiseInProgress && getPlugnote() !== null) {
                switch (props.mode) {
                    case 'create':
                    case 'update':
                    case 'shared-create':
                    case 'shared-update':
                    case 'modal-shared-update':
                        try {
                            handleOpenModalConfigurator();
                        }
                        catch (e) {
                            console.log('error',e);
                        }
                        break;
                    default:
                        break;
                }
                setOpenPromised(false);
            }
            else {
                setOpenPromised(true);
            }
        }
    }, [promiseInProgress, openPromised, getPlugnote()])

    let presentTime = new Date()
    let nowVal = ''

    switch (format.format) {
        case 'file-source':
            let sourcePresels = ['PLUGNOTE', 'FTP', 'URL'];
            template = (
                <div className={widerCtn}>
                    <Select
                        classes={{
                            icon: classes.selectIcon,
                            // nativeInput: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                            root: `${classes.fontTablemultiWidth} ${classes.selectField}`,
                            select: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                        }}
                        disabled={props.disabled}
                        disableUnderline
                        displayEmpty
                        fullWidth={true}
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        onChange={(event => handleChangeChildrenRowWithPreValueValue(event.target.value, props.row))}
                        onClick={() => setOpenSelect(!openSelect)}
                        open={openSelect}
                        renderValue={(value) => value !== '' && (<span  className={classes.selectedItem}>{value}</span>)}
                        value={value}
                    >
                        {sourcePresels.map((row, index) => (
                            <MenuItem key={index} value={row} dense>{row}</MenuItem>
                        ))}
                    </Select>
                </div>
            );
            break;
        case 'file-name-text':
        case 'file-comment-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: '512', width : '100%', textAlign: 'center' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={props.col.id === 1000? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.textFont,
                                root: props.col.id === 1000? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                    <Fragment>
                                        <span className={classes.textLength}>{value.length}/512</span>
                                    </Fragment>
                                    : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'file-type-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: '4', width : '100%', textAlign: 'center' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={props.col.id === 1000? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.textFont,
                                root: props.col.id === 1000? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                    <Fragment>
                                        <span className={classes.textLength}>{value.length}/4</span>
                                    </Fragment>
                                    : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'file-size-num':
            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${classes.fieldValueContainer} ${classes.renderSingleSelContainer}`}>
                    <NumberFormat
                        {...props}
                        // allowedDecimalSeparators={[',', '.']}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={true? 9 : 0}
                        decimalSeparator={","}
                        format={null}
                        onCopy={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(value)
                        }}
                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', //value after applying formatting
                            // value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
                            //setNumericValue(v);
                            setValue(v.floatValue);
                            if (infos.event !== null) {
                                handleChangeTableValue(v.floatValue);
                            }else if (infos.event === null && v.value !== value.toString()){
                                handleChangeTableValue(v.floatValue);
                            }
                        }}
                        thousandSeparator={false}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-create-date':
            let dateCreateMask = ''
            template = (
                <div className={fieldCtn}>
                    <TextField
                        inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                        placeholder={dateCreateMask === '' ? '' : props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        disabled={props.col.id === 1003}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onChange={(event) => {handleChangeTableValue(event.target.value)}}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-refid':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        disabled={true}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        //onChange={(event) => {handleChangeTableValue(event.target.value)}}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-uri':
            let fileSource = props.columns.find((column, index) => column.format === 'file-source');
            if(fileSource){
                switch (props.row[fileSource.id]) {
                    case 'PLUGNOTE':
                        if(value !== ""){
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                                    {
                                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                                    }
                                </div>
                            )
                        }else{
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <span id="icon-create" className="material-icons md-small red" onClick={() => handleOpenListAnnexes()}>open_in_new</span>
                                    { openListAnnexes &&
                                        <Modal
                                            key={props.rindex + '-'+ props.col.id}
                                            keepMounted={false}
                                            open={openListAnnexes}
                                            onClose={(event) => setOpenListAnnexes(false)}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup}>
                                                <List component="nav" aria-label="secondary mailbox folder">
                                                    {
                                                        props.annexes.map((annexe, index) => {
                                                            return(
                                                                <ListItem
                                                                    button
                                                                    //selected={selectedIndex === 3}
                                                                    onClick={(event) => handleListItemClick(index, props.row, props.col, props.rindex)}
                                                                >
                                                                    <ListItemText primary={annexe.name + ' ('  + annexe.type + ' )'} />
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </div>
                                        </Modal>
                                    }
                                </div>
                            )
                        }
                        break;
                    case 'URL':
                        if(value !== ""){
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                                    {
                                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>edit</span>
                                    }
                                </div>
                            )
                        }
                        else{
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <TextField
                                        fullWidth={true}
                                        multiline={true}
                                        inputProps={{ width : '100%', textAlign: 'center' }}
                                        name={props.col.format + '-' + props.cindex}
                                        type="text"
                                        value={valueChange}
                                        onChange={(event) => setValueChange(event.target.value)}
                                        disabled={props.col.id === 1000? true : props.disabled}
                                        InputProps={{
                                            classes: {
                                                input: classes.textFont,
                                                root: props.col.id === 1000? classes.renderBlankContainer : classes.renderSingleSelContainer
                                            },
                                            endAdornment:
                                                <Fragment>
                                                    {valueChange !== undefined && valueChange.length > 0  && !disabled ?
                                                        <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                                            <span onClick={() => handleChangeTableValue(valueChange)}>
                                                                <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                                    check
                                                                </span>
                                                            </span>
                                                        </PlugTooltip>: null}
                                                </Fragment>,
                                        }}
                                    />
                                </div>
                            )
                        }
                        break;
                    case 'FTP':
                        if(value !== ""){
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <span className={classes.urlButton} rel="noreferrer" onClick={(event) => handleOpenBase64Preview(value.toString())}><span className="material-icons md-small white">link</span></span>
                                    {
                                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                                    }
                                    { openBase64PreviewFromFtp &&
                                        <Modal
                                            key={props.rindex + '-'+ props.col.id}
                                            keepMounted={false}
                                            open={openBase64PreviewFromFtp}
                                            onClose={(event) => setOpenBase64PreviewFromFtp(false)}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup}>
                                                <div className={classes.scaledImg}>
                                                {
                                                    renderAnnexeImagePreview(props.row, props.columns,base64PreviewFromFtp)
                                                }
                                                </div>
                                            </div>
                                        </Modal>
                                    }
                                </div>
                            )
                        }
                        else{
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <span id="icon-create" className="material-icons md-small red" onClick={() => handleGetListOpenListAnnexesFromFtp()}>open_in_new</span>
                                    { openListAnnexesFromFtp &&
                                        <Modal
                                            key={props.rindex + '-'+ props.col.id}
                                            keepMounted={false}
                                            open={openListAnnexesFromFtp}
                                            onClose={(event) => setOpenListAnnexesFromFtp(false)}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup}>
                                                <List component="nav" aria-label="secondary mailbox folder">
                                                    <div>
                                                        {
                                                            foldersFromFtp?.map((element, index) => {
                                                                if(element.content === 'FOLDER'){
                                                                   return <RenderFolderActionTemplate key={index} {...props} element={element} index={index} handleListItemFromFtpClick={handleListItemFromFtpClick}/>
                                                                }else if(element.content === 'FILE'){
                                                                    return <RenderFileActionTemplate key={index} {...props} element={element} index={index} handleListItemFromFtpClick={handleListItemFromFtpClick}/>
                                                                }else return null
                                                            })
                                                        }
                                                    </div>

                                                </List>
                                            </div>
                                        </Modal>
                                    }
                                </div>
                            )
                        }
                        break;
                    default:
                        break;
                }
            }
            break;

        case 'intdatamaj':

            const handleChangeIntDataMajRow = (value) => {
                handleChangeTableValue(value)
            }


            let destIntDataMaj  = field.options.lines[props.cindex].configuration.dest;
            let indexLigne = props.rindex + 1;

            if (!isNull(destIntDataMaj)) {
                let replaceDestX = destIntDataMaj.replaceAll('LX', 'L'+indexLigne);
                field.options.lines[props.cindex].configuration.dest = replaceDestX;
            }
            let subField = {
                value : value,
                options : field.options.lines[props.cindex].configuration,
            }

            template = (
                <div  className={classes.fieldValueContainer}>
                    <FormatIntDataMajComponent
                        handleChangeIntDataMajRow={handleChangeIntDataMajRow}
                        field={subField}
                        cindex={props.cindex}
                        rindex={props.rindex}
                        disabled={disabled}
                        owner={props.hashOwnerModel}
                        mode='table'
                        dataHash={props?.dataHash}
                        hidden={true}
                        control={props.control}
                        controlCreate={props?.controlCreate}
                        controlUpdate={props?.controlUpdate}
                    />
                </div>
            )
            break;
        case 'date':
            let dateMask = ''
            let retrievedDate
            if (value !== '' && value !== undefined) {
                retrievedDate = value.split('-')
                if (retrievedDate.length > 3) {
                    let year = retrievedDate[retrievedDate.length - 1]
                    let month = retrievedDate[retrievedDate.length - 2]
                    let day = retrievedDate[retrievedDate.length - 3]
                    if (month.length === 1) month = '0' + month.toString()
                    if (day.toString().trim().length === 1) day = '0'.concat(day.toString())
                    retrievedDate = day + '/' + month + '/' + year
            }}
            let day = presentTime.getDate() < 10 ? '0' + presentTime.getDate() : presentTime.getDate()
            let month = presentTime.getMonth() + 1 < 10 ? '0' + (presentTime.getMonth() + 1) : presentTime.getMonth() + 1
            let year = presentTime.getFullYear().toString()

            if (typeof format.mask !== "undefined" && format.mask !== null) {
                dateMask = format.mask
            }
            let dateIsInvalid = false
            switch (format.mask) {
                case 'DD/MM':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) > monthLength(parseInt(value.substr(3,2))) || parseInt(value.substr(3,2)) >= 13
                    nowVal = day + '/' + month
                    dateMask = '99/99'
                    break;
                case 'DD/MM/YY':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) > monthLength(parseInt(value.substr(3,2))) || parseInt(value.substr(3,2)) >= 13
                    nowVal = day + '/' + month + '/' + year.slice(2,4)
                    dateMask = '99/99/99'
                    break;
                case 'MM/YYYY':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) >= 13
                    nowVal = month + '/' + year
                    dateMask = '99/9999'
                    break;
                case 'DD/MM/YYYY':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0,2)) > monthLength(parseInt(value.substr(3,2)), isBissextile(parseInt(value.substr(6,4)))) || parseInt(value.substr(3,2)) >= 13
                    nowVal = day + '/' + month + '/' + year
                    dateMask = '99/99/9999'
                    break;
                case '99':
                    nowVal = day + '/' + month + '/' + year
                    dateMask = ''
                    break;
                default:
                    break;
            }
            template = (
            <div className={fieldCtn}>
                {dateMask !== ''?
                <InputMask
                    disabled={disabled}
                    maskChar="_"
                    mask={dateMask}
                    value={props.col.id !== 1003? value : retrievedDate}
                    onKeyDown={handleOnBlur}
                    onChange={(event) => {
                        setAccept(false);
                        handleChangeTableValue(event.target.value)
                    }}
                    >
                        {(inputProps) =>
                        <TextField
                            {...inputProps}
                            autoFocus={props.edit === "cell"}
                            inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                            name={props.col.format + '-' + props.cindex}
                            fullWidth={true}
                            placeholder={dateMask === ''? '' : props.col.mask}
                            type="text"
                            InputProps={{
                                classes: {
                                    input: classes.fontTablemulti,
                                    root: classes.tablemultiInputCtn,
                                },
                            endAdornment:
                                <Fragment>
                                    {value !== undefined && value.length > 0 && dateIsInvalid && !accept && !disabled ?
                                    <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                        <span className={classes.fontError} onClick={() => setAccept(true)}>
                                            {t('tablemulti.invalid-value')}
                                            <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                check
                                            </span>
                                        </span>
                                    </PlugTooltip>: null}
                                    {!disabled &&
                                    <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-date')} placement="top" className={classes.endButton} >
                                        {value !== "" && value !== null && value !== undefined ?
                                        <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue("")}}>highlight_off</span>
                                        :
                                        <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue(nowVal)}}>check_circle_outline</span>}
                                    </PlugTooltip>}
                                </Fragment>,
                            }}
                        />
                        }
                </InputMask>
                :
                <TextField
                    autoFocus={props.edit === "cell"}
                    inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                    placeholder={dateMask === '' ? '' : props.col.mask}
                    name={props.col.format + '-' + props.cindex}
                    type="text"
                    fullWidth={true}
                    InputProps={{
                        classes: {
                            input: classes.fontTablemulti,
                            root: classes.tablemultiInputCtn,
                        },
                        endAdornment:
                            <Fragment>
                                {!disabled &&
                                <PlugTooltip title={value !== "" && value !== null && value !== undefined ? t('actions.delete') : t('tablemulti.current-date')} placement="top" className={classes.endButton} >
                                    {value !== "" && value !== null && value !== undefined ?
                                    // delete
                                    <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue("")}}>highlight_off</span>
                                    :
                                    // autocomplete
                                    <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue(nowVal)}}>check_circle_outline</span>}
                                </PlugTooltip>}
                            </Fragment>,
                    }}
                    onBlur={handleOnBlur}
                    onChange={(event) => {handleChangeTableValue(event.target.value)}}
                    value={value}
                />}
            </div>
            );
            break;
        case 'duration':
            let durMask = '99:99:99'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                durMask = props.col.regex
            }
            template = (
                <div className={fieldCtn}>
                    <DurationInput
                        {...props}
                        accept={accept}
                        duration={duration}
                        handleChangeTableValue={handleChangeTableValue}
                        mask={durMask}
                        setAccept={setAccept}
                        setDuration={setDuration}
                        value={value}
                        />
                </div>
            );
            break;
        case 'extdata':
            const titleFormat = `( ${ref} )  ( ${query} ) [ ${dbcolumn} ] [ ${dbcolumns} ] [ ${dest} ]`
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.fontTablemulti} ${classes.tablemultiInputCtn}`}
                        classes={{root: classes.tablemultiInputCtn}}
                        disabled={props.disabled}
                        fullWidth={true}
                        inputProps={{ maxLength: props.col.mask, width : '100%' }}
                        multiline={true}
                        name={props.col.format + '-' + props.cindex}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        placeholder={t('common:global.search')}
                        startAdornment={!disabled && !hideSearch &&
                            <InputAdornment onClick={(event) => handleOpenSearchModel()} style={{cursor : 'pointer', right: 0, position: 'absolute', }} position="end">
                                <Search/>
                            </InputAdornment>}
                        type="text"
                        value={value}
                    />
                    {open &&
                        <ModalListExtDataComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={open} close={(event) => setOpen(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external} />
                    }
                </div>
            )
            break;
        case 'intdata':
            const titleIntFormat = `( ${ref} )  ( ${query} ) [ ${dbcolumn} ] [ ${dbcolumns} ] [ ${dest} ]`
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.fontTablemulti} ${classes.tablemultiInputCtn}`}
                        classes={{root: classes.tablemultiInputCtn}}
                        disabled={props.disabled}
                        fullWidth={true}
                        inputProps={{ maxLength: props.col.mask, width : '100%' }}
                        multiline={true}
                        name={props.col.format + '-' + props.cindex}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        onKeyDown={(e) => {if (e.key === 'Enter') handleOpenSearchModel()}}
                        placeholder={t('common:global.search')}
                        startAdornment={!disabled && !hideSearch &&
                            <InputAdornment onClick={(event) => handleOpenSearchModel()} style={{cursor : 'pointer', right: 0, position: 'absolute', }}>
                                <Search/>
                            </InputAdornment>}
                        type="text"
                        value={value}
                    />
                    {open &&
                        <ModalListIntDataComponent titlePerso={props.title} titleFormat={titleIntFormat} format="intdata" open={open} close={(event) => setOpen(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external}/>
                    }
                </div>
            )
            break;
        case 'formula':
            template = (
                <div className={fieldCtn}>
                    <Input
                        classes={{root: classes.renderSingleFormulaContainer}}
                        fullWidth={true}
                        placeholder={props?.col?.configuration?.formula || 'formula'}
                        multiline={true}
                        className={classes.fontTablemulti}
                        inputProps={{ maxLength: props.col.mask, width : '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={true}
                        startAdornment={loading &&
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <CircularProgress size={10}/>
                        </Fade>
                        }
                        endAdornment={!disabled &&
                        <InputAdornment position="end" onClick={(event) => handleCalculateValue()} style={{cursor : 'pointer'}}>
                            <span className="material-icons md-medium green">calculate</span>
                        </InputAdornment>
                        }
                    />
                </div>
            )
            break;
        case 'url':
            template = (
                <div  className={classes.fieldValueContainer}>
                    <TextField
                        inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        type="url"
                        value={value}
                    />
                    {
                        typeof value !== 'undefined' &&  value !== '' &&
                        <div style={{marginLeft: '10px'}}>
                            <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                        </div>
                    }
                </div>
            );
            break;
        case 'mail':
            const textStyle = { fontSize: props.col.fontSize > 0 ? props.col.fontSize : 16, wordBreak: 'break-all', }
            // const previewFieldStyle = { justifyContent: 'center', padding: '0px 4px', flexWrap: 'wrap', gap: 10 }
            template = (
                <div
                    // style={props.isPreview && previewFieldStyle}
                    style={isDefined(props.containerStyle) ? props.containerStyle : null}
                    className={fieldCtn}
                >
                    {
                        props.isPreview
                            ? <span style={textStyle}>{value}</span>
                            : <TextField
                                autoFocus={props.edit === "cell"}
                                inputProps={{ style: { textAlign: 'center', paddingLeft: 5 } }}
                                placeholder={props.col.mask}
                                name={props.col.format + '-' + props.cindex}
                                type="text"
                                fullWidth={true}
                                disabled={props.disabled}
                                InputProps={{
                                    classes: {
                                        input: classes.fontTablemulti,
                                        root: classes.tablemultiInputCtn,
                                    },
                                }}
                                onChange={(e) => {
                                    handleChangeTableValue(e.target.value);
                                    if (props.id === '1001') checkRegistered(e.target.value);
                                }}
                                onKeyDown={handleOnBlur}
                                defaultValue={value}
                            />
                    }
                    {
                        typeof value !== 'undefined' &&  value !== '' && props.col.id === 1001 && !props.mode.includes('plugcode') &&
                            <PlugTooltip title={registered? contentTooltip : unregisteredMsg} placement="right">
                                <span className={registered? classes.mailButton : classes.unregisteredBtn} onClick={controlBeforeOpen}  onMouseEnter={getInformations}>
                                    <span className="material-icons md-small white">forward_to_inbox</span>
                                </span>
                            </PlugTooltip>
                    }

                    {
                        typeof value !== 'undefined' &&  value !== '' && props.col.id === 1001 && props.mode.includes('plugcode') &&
                        <div style={{marginLeft: '10px'}}>
                            <PlugTooltip title={registered? contentTooltip : unregisteredMsg} placement="top">
                                <span className={registered? classes.mailButton : classes.unregisteredBtn} onClick={getInformations} onMouseEnter={getInformations}>
                                    <span className="material-icons md-small white">info</span>
                                </span>
                            </PlugTooltip>
                        </div>
                    }

                    {
                        openMail && props.col.id === 1001 &&
                        <SmailModal
                            annexes={props.annexes}
                            attemptSendMailSimple={handleSendMail}
                            close={handleCloseModalSmail}
                            customAnnexe={customAnnexe}
                            field={field}
                            fields={fields}
                            handleCloseWhenSendModalSmail={handleCloseWhenSendModalSmail}
                            model={model}
                            open={openMail}
                            plugnote={plugnote}
                            pwCode={pwCode}
                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                            setPwField={setPwField}
                            user={user}
                            value={value}
                        />
                    }

                    {
                        openConfigurator && props.col.id === 1001 &&
                        <ConfigModal {...props} open={openConfigurator} external={false} close={() => setOpenConfigurator(false)} value={value} openMail={handleOpenModalSmail} setPwCode={setPwCode} setPwField={setPwField}/>
                    }

                    {
                        openExternalConfigurator && props.col.id === 1001 &&
                        <ConfigModal {...props} open={openExternalConfigurator} external={true} close={() => setOpenExternalConfigurator(false)} value={value} openMail={handleOpenModalSmail} setPwCode={setPwCode} setPwField={setPwField}/>
                    }
                </div>
            );
            break;
        case 'numeric':
            let separator = false
            let hasDecimal = false
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                switch(props.col.regex) {
                    case "99,999999":
                        hasDecimal = true
                        break;
                    case "9.999":
                        separator = true;
                        // errorMsg = 'Integer only'
                        break;
                    case "9.999,99999":
                        separator = true;
                        hasDecimal = true;
                        break;
                    default:
                        break;
                }
            }

            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${fieldCtn} ${classes.tablemultiInputCtn}`}>
                    <NumberFormat
                        {...props}
                        autoFocus={props.edit === "cell"}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={hasDecimal? 99 : 0}
                        decimalSeparator={"."}
                        format={null}
                        onKeyDown={handleOnBlur}
                        onCopy={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(value)
                        }}
                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', //value after applying formatting
                            // value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
                            setValue(v.floatValue);
							if (infos.event !== null && v.value !== value.toString()) {
								handleChangeTableValue(v.floatValue);
							}
                            // else if (infos.event === null && v.value !== value.toString()) {
							// 	handleChangeTableValue(v.floatValue);
							// }
                        }}
                        thousandSeparator={separator ? ' ' : false}
                        value={value}
                    />
                </div>
            );
            break;
        case 'numeric-ref':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => {
                            setValue(event.target.value);
                            handleChangeTableValue(event.target.value);
                        }}
                        type="number"
                        value={value}
                    />
                </div>
            );
            break;
        case 's33data':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        inputProps={{ style: {textAlign: 'right', paddingLeft: 5} }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => {
                            setValue(event.target.value);
                            handleChangeTableValue(event.target.value);
                        }}
                        type="number"
                        value={value}
                    />
                    <span id="icon-create" className="material-icons md-small green" onClick={() => handleGetValueFromTable(props.col, props.fields, value)}>check</span>
                    {
                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                    }
                </div>
            )
            break;
        case 'presel':
            let multiselect = props?.col?.mask?.multiselect || false;
            let editable = props?.col?.mask?.editable || false;
            if (multiselect === false && editable === false) {
                template = (
                <div className={widerCtn}>
                    <RenderItemSingleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={props.col.presel} disabled={props.disabled} value={value}/>
                </div>
                );
            }
            else if (multiselect === false && editable === true) {
                let presel = props.col.presel;
                if (!presel.includes(value)) {
                    presel.push(value);
                }
                template = (
                    <div className={widerCtn}>
                        <RenderItemSingleSelectComponent {...props} multiselect={multiselect} editable={editable} openDefault={openDefault} presel={presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            else if (multiselect === true && editable === false) {
                template = (
                    <div className={widerCtn}>
                        <RenderItemMultipleSelectComponent {...props} multiselect={multiselect} editable={editable} openDefault={openDefault} presel={props.col.presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            else if (multiselect === true && editable === true) {
                let tmpValue = Array.isArray(value) ? value : [];
                let presel = props.col.presel;
                tmpValue.map((item, index) => {
                    if (!presel.includes(item)) {
                        presel.push(item);
                    }
                });
                template = (
                    <div className={widerCtn}>
                        <RenderItemMultipleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={presel} disabled={props.disabled} value={value}/>
                    </div>
                );
            }
            break;
        case 'radio':
            let masks = props.col.mask.split('/')
            template = (
                <div className={fieldCtn}>
                    <div style={{float : 'left'}} className={classes.tablemultiInputCtn}>
                        <RadioGroup className={classes.tablemultiRadioCtn} aria-label="gender" name="gender1" value={value} onChange={(event => handleChangeTableValue(event.target.value))}>
                            {masks.map((mask, index) => {
                            return(
                                <FormControlLabel size='small' value={mask} control={<Radio classes={{root: classes.radioIcon, checked: classes.radioIconChecked}}  size='small' disabled={props.disabled}/>} label={<span className={classes.radioLabel}>{mask}</span>} key={index} />
                            );})}
                        </RadioGroup>
                    </div>
                </div>
            );
            break;
        case 'select':
            let presels = props.col.presel;
            template = (
                    <div className={widerCtn}>
                        <Select
                            classes={{
                                icon: classes.selectIcon,
                                // nativeInput: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                                root: `${classes.fontTablemultiWidth} ${classes.selectField}`,
                                select: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                            }}
                            disabled={props.disabled}
                            disableUnderline
                            displayEmpty
                            fullWidth={true}
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            onChange={(event => handleChangeTableValue(event.target.value))}
                            onClick={() => setOpenSelect(!openSelect)}
                            open={openSelect}
                            renderValue={(value) => value !== '' && (<span  className={classes.selectedItem}>{value}</span>)}
                            value={value}
                        >
                            {presels.map((row, index) => (
                                <MenuItem key={index} value={row} dense>{row}</MenuItem>
                            ))}
                        </Select>
                    </div>
            );
            break;
        case 'tel':
            let telMasks = '+99999999999999'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                telMasks = props.col.regex
            }
            template = (
            <div className={fieldCtn}>
                <TextField
                    autoFocus={props.edit === "cell"}
                    inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                    placeholder={props.col.mask}
                    name={props.col.format + '-' + props.cindex}
                    fullWidth={true}
                    InputProps={{
                        classes: {
                            input: classes.fontTablemulti,
                            root: classes.tablemultiInputCtn
                        },
                    }}
                    onKeyDown={handleOnBlur}
                    onChange={(event) => handleChangeTableValue(event.target.value, event)}
                    type="tel"
                    value={value}
                />
            </div>
            );
            break;
        case 'text':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: props.col.mask, width : '100%', }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        disabled={props.col.id === 1000? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                            endAdornment: value !== null && value !== undefined && props.id !== '1000' && value.length > parseInt(props.col.mask) - 10 ?
                                <Fragment>
                                    <span className={classes.textLength}>{value.length}/{props.col.mask}</span>
                                </Fragment>
                                : null
                        }}
                        style={{ textAlign: 'center' }}
                    />
                </div>
            )
            break;
        case 'time':
            let timeMask = '99:99:99'
            let hour = presentTime.getHours() > 9 ? presentTime.getHours() : '0' + presentTime.getHours()
            let minutes = presentTime.getMinutes() > 9 ? presentTime.getMinutes() : '0' + presentTime.getMinutes()
            let seconds = presentTime.getSeconds() > 9 ? presentTime.getSeconds() : '0' + presentTime.getSeconds()

            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                timeMask = props.col.regex
            }
            let timeIsInvalid = false
            if (value !== null && value !== undefined) switch (timeMask) {
                case '99:99:99':
                    if (!value.includes('_')) timeIsInvalid = parseInt(value.substr(0,2)) >= 24 || parseInt(value.substr(3,2)) >= 60 || parseInt(value.substr(6,2)) >= 60
                    nowVal = hour + ':' + minutes + ':' + seconds
                    break;
                case '99:99':
                    if (!value.includes('_')) {
                        if (props.col.mask.includes('H')) {timeIsInvalid = parseInt(value.substr(0,2)) >= 24 || parseInt(value.substr(3,2)) >= 60}
                        else timeIsInvalid = parseInt(value.substr(0,2)) >= 60 || parseInt(value.substr(3,2)) >= 60
                    }
                    if (props.col.mask.includes('H')) nowVal = hour + ':' + minutes;
                    else nowVal = minutes + ':' + seconds
                    break;
                case '99 H 99':
                    if (!value.includes('_')) timeIsInvalid = parseInt(value.substr(0,2)) >= 24 || parseInt(value.substr(5,2)) >= 60
                    nowVal = hour + ' H ' + minutes
                    break;
                case '99':
                    if (!value.includes('_')) timeIsInvalid = false
                    timeMask = ''
                    nowVal = hour + ':' + minutes + ':' + seconds
                    break;
                default:
                    break;
            }
            template = (
            <div className={fieldCtn}>
                {timeMask !== ''?
                <InputMask
                    disabled={disabled}
                    maskChar="_"
                    mask={timeMask}
                    value={value}
                    onChange={(event) => {
                        setAccept(false);
                        handleChangeTableValue(event.target.value);
                    }}
                    onKeyDown={handleOnBlur}
                    >
                        {(inputProps) =>
                        <TextField
                            {...inputProps}
                            autoFocus={props.edit === "cell"}
                            inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                            placeholder={timeMask === '' ? '' : props.col.mask}
                            name={props.col.format + '-' + props.cindex}
                            type="text"
                            fullWidth={true}
                            InputProps={{
                                classes: {
                                    input: classes.fontTablemulti,
                                    root: classes.tablemultiInputCtn},
                            endAdornment:
                                <Fragment>
                                    {value.length > 0 && timeIsInvalid &&!accept && !disabled ?
                                        <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                            <span className={classes.fontError} onClick={() => setAccept(true)}>
                                                {t('tablemulti.invalid-value')}
                                                <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                    check
                                                </span>
                                            </span>
                                        </PlugTooltip>
                                    : null}
                                    {!disabled &&
                                    <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                                        {value !== "" && value !== null && value !== undefined ?
                                        <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue("")}}>highlight_off</span>
                                        :
                                        <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue(nowVal)}}>check_circle_outline</span>}
                                    </PlugTooltip>}
                                </Fragment>
                                }}
                        />
                        }
                </InputMask>
                :
                <TextField
                    autoFocus={props.edit === "cell"}
                    inputProps={{ style: {textAlign: 'center', paddingLeft: 5} }}
                    placeholder={timeMask === '' ? '' : props.col.mask}
                    name={props.col.format + '-' + props.cindex}
                    type="text"
                    fullWidth={true}
                    InputProps={{
                        classes: {
                            input: classes.fontTablemulti,
                            root: classes.tablemultiInputCtn},
                        endAdornment: !disabled &&
                        <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                            {value !== "" && value !== null && value !== undefined ?
                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue("")}}>highlight_off</span>
                            :
                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {setAccept(false); handleChangeTableValue(nowVal)}}>check_circle_outline</span>}
                        </PlugTooltip>
                    }}
                    onKeyDown={handleOnBlur}
                    onChange={(event) => {handleChangeTableValue(event.target.value); }}
                    value={value}
                />
                }
            </div>
            );
            break;
        default:
            return(
            <div className={classes.editorCtn}>
                <span className={classes.columnHeader}>{`<!> ERROR <!>`}</span>
            </div>)
    }

    return (
        !props.isPreview ?
        <div className={editorCtn}>
            {
                props.edit === "row" &&
                <div className={titleCtn}>
                    <span className={classes.fontFieldNumber}> {columns.indexOf(format) + 1} </span>
                    <span className={classes.fontFieldLabel}> {handleSpecialCharsInLabel(format.label, classes)} </span>
                </div>
            }
            {template}
        </div>
        :
        template
    )
}

const RenderEditRowComponent = (props) => {
    const classes = useStyles();
    const row = props.row
    const { t } = useTranslation('common');

    return(
        <div className={classes.editFieldsCtn}>
            {Object.keys(row)
                .sort((a, b) => {
                    if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                })
                .map((id, cindex) => {
                    let column = getCellColumn(id, props.columns)
                    if (!isDefined(column) || ((isDefined(column.encodable) && props.mode === 'plugcode-complete' && column.encodable === false) || (isDefined(column.visible) && props.mode === 'plugcode-consult' && column.visible === false))) { return null; }
                    let value = row[id]
                    return (
                        <RenderEditCellComponent
                            annexes={props.annexes}
                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                            attemptSendMailSimple={props.attemptSendMailSimple}
                            col={column}
                            columns={props.columns}
                            control={props.control}
                            controlCreate={props.controlCreate}
                            controlUpdate={props.controlUpdate}
                            disabled={props.disabled}
                            edit="row"
                            field={props.field}
                            fields={props.fields}
                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, column, row)}
                            handleValidate={props.handleEndEdit}
                            id={id}
                            key={'edit-cell-' + id}
                            mode={props.mode}
                            model={props.model}
                            owner={props.owner}
                            ownerId={props.ownerId}
                            plugnote={props.plugnote}
                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                            rindex={props.rindex}
                            cindex={cindex}
                            row={row}
                            share={props.share}
                            title={props.title}
                            user={props.user}
                            value={value}
                            dataHash={props?.dataHash}
                        />)
                })}
            <PlugTooltip title={t('global.validate')} placement="right-start">
                <span className={`material-icons ${classes.validateIcon} ${classes.absoluteIcon}`} style={{ bottom: 8, right: -48 }} onClick={() => props.handleValidate()}>check</span>
            </PlugTooltip>
        </div>
    );
}

export const ModalTablemultiTableViewComponent = (props) => {
    // console.log('ModalTablemulti got props', props)
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const { t } = useTranslation('common')

    //Props
    const value = props.search
    const hideSearch = props.hideSearch
    const criteria = props.criteria

    //Refs
    const searchInput = useRef()
    const editedRow = useRef()

    //variables
    const [search, setSearch] = useState('')
    const [openSearch, setOpenSearch] = useState(false)
    const [rows, setRows] = useState(props.rows?.length > 0 ? props.rows : [])
    const [filteredRows, setFilteredRows] = useState(rows)
    const [displayedRows, setDisplayedRows] = useState(rows)
    const [keyList, setKeyList] = useState([])
    const [completed, setCompleted] = useState(false)
    const [openRow, setOpenRow] = useState(props.openRow !== null ? {row: rows[props.openRow]} : null)
    const [rowLength, setRowLength] = useState(rows.length)
    const [changed, setChanged] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [rowsToDelete, setRowsToDelete] = useState([])
    const [printing, setPrinting] = useState(false);

    // Double-click handler
    let timer
    function handleExitModal() {
        props.handleClose()
        setPrinting(false)
    }

    function handleCloseModal() {
        let ssTableView = getTableView()
        if (isDefined(ssTableView)) {
            props.handleSafeExitPlugnote()
        }
        else handleExitModal()
    }

    function handleChangeSearch(e) {
        setSearch(e.target.value)
    }

    function handleTogglePrintTable () { setPrinting(previous => !previous) }

    function handleSearch() {
        let result = []
        rows.forEach((row) => {
            let present = false
            Object.values(row).forEach(value => {
                if (value !== '' && typeof value !== 'boolean') {
                    if (typeof value === 'number') {
                        if (value.toString().includes(search.toString().toLowerCase())) present = true
                    } else if (typeof value === 'string') {
                        if (value.toLowerCase().includes(search.toLowerCase())) present = true
                    } else value.forEach(entry => {
                        if (entry.toLowerCase().includes(search.toLowerCase())) present = true
                    })
                }
            })
            if (present) result.push(row)
        })
        setFilteredRows(result)
    }

    function handleResetSearch() {
        setSearch('')
        searchInput.current.focus()
        setFilteredRows(rows)
    }

    function completeRows() {
        let _rows
        if (rows.length > 0 && !completed) {
            _rows = [...rows]
            _rows.forEach((row) => {
                if (row.recap !== true) keyList.forEach((key) => {
                    if (!row.hasOwnProperty(key)) row[key] = ''
                })
            })
            setRows(_rows)
            setCompleted(true)
        }
    }

    function handleEditCellValue(row, id) {
        let format = getCellColumn(id, props.colonnes)?.format
        // console.log('edit | row', row, '| id', id, '=', format);
        if (immutableFormats.includes(format) || id === '1000') {
            toast.error(
                <ToastMessage
                    message='multiple.edit-impossible'
                />, {autoClose: 3000})
        } else if (!props.disabled) setOpenRow({id: id, row: row})
    }

    function handleEndEdit() {
        setOpenRow(null)
    }

    function handleAddRow(e) {
        props.setNewRow(null)
        setChanged(true)
        props.handleAddTableMulti(e)
    }

    function handleClickRowNb(row) {
        if(openRow !== null && openRow?.row === row) { handleEndEdit() }
        else handleEditCellValue(row)
    }

    function handleClickDelete(e, id) {
        if (!isDefined(timer)) (timer = setTimeout(() => { setRowToDelete(id) }, 500))
        // if (!isDefined(timer)) (timer = setTimeout(() => { handleDeleteRow(id) }, 500))
        if(e.detail == 2){
            clearTimeout(timer)
            handleToggleToDelete(id)
        }
    }

    function handleDeleteSelected() {
        confirmAlert({
            title: t('tablemulti.delete-rows'),
            message: t('tablemulti.confirm-delete'),
            buttons: [
                {
                    label: t('common:global.delete'),
                    onClick: () =>  {
                        setOpenSearch(false)
                        // let toDelete = rowsToDelete.slice()
                        //     .sort((a, b) => b - a)
                        // toDelete.forEach(entry => props.handleDeleteRow(entry - 1))
                        props.handleDeleteRow(rowsToDelete)
                        setRowsToDelete([])
                    },
                },
                {label: t('common:global.cancel')
                },
            ]
        })
    }

    function handleCancelDelete() {
        setRowsToDelete([])
    }

    function handleToggleToDelete(id) {
        if (rowsToDelete.length > 0) {
            if (rowsToDelete.includes(id)) {
                if (rowsToDelete.length === 1) setRowsToDelete([]);
                else setRowsToDelete(rowsToDelete.filter(entry => entry !== id))
            } else setRowsToDelete([...rowsToDelete, id]);
        } else setRowsToDelete([id]);
    }

    function handleDeleteSingleRow() {
        setOpenSearch(false)
        props.handleDeleteRow(rowToDelete - 1)
        setRowToDelete(null)
    }

    function openLastRow() {
        let _rows = rows.filter(row => row.recap !== true)
        let finalRow = _rows[_rows.length - 1]
        setOpenRow(finalRow)
        setChanged(false)
        if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) setTimeout(() => {
            editedRow.current.scrollIntoView({block: 'center'})
            setTimeout(() => editedRow.current.scrollIntoView({block: 'center'}), 200)
        }, 200)
    }

    function handleToggleSearch() {
        setOpenSearch(!openSearch)
    }

    function handleCellClick(e, row, key) {
        e.preventDefault();
        e.stopPropagation();
        props.setNewRow(null)
        handleEditCellValue(row, key)
    }

    function isLimitReached() {
        return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }

    useEffect(() => {
        setRows(props.rows)
        let keys = []
        props.colonnes.forEach((col) => {
                if (!keys.includes(col.id.toString())) keys.push(col.id.toString())
            }
        )
        setKeyList(keys)
        setCompleted(false)
    }, [props.rows])

    useEffect(() => {
        if (rows.length !== props.field.value.length && search === "") setRows(props.rows)
    }, [search])

    completeRows()

    useEffect(() => {
        if (openRow === null) {
            if (rows.length !== rowLength) {
                setRowLength(rows.length)
            }
        }
        setFilteredRows(rows)
    }, [rows])

    useEffect(() => {
        if (changed) openLastRow()
        if (editedRow.current !== undefined && editedRow.current !== null) editedRow.current.scrollIntoView({ behavior: "smooth" })
    }, [rowLength])

    useEffect(() => {
        if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) setTimeout(() => {
            if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) editedRow.current.scrollIntoView({block: 'center'})
            setTimeout(() => {
                if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) editedRow.current.scrollIntoView({block: 'center'})
            }, 200)
        }, 200)
    }, [openRow])

    useEffect(() => {
        // console.log('MODAL TABLEMULTI openRow', props.openRow);
        if (isDefined(props.openRow)) {
            if (isDefined(props.openRow?.id)) {
                handleEditCellValue(props.openRow.row, props.openRow.id)
            } else {
                setOpenRow({ row: rows[props.openRow] })
                setTimeout(() => {
                    let scrollId = props.openRow > 0 ? (props.openRow - 1) : props.openRow
                    document.getElementById("row-index-" + scrollId)?.scrollIntoView()
                }, 400)
            }
        }
    }, [props.openRow])

    useEffect(() => {
        setDisplayedRows(openSearch ? filteredRows : rows)
    }, [openSearch, filteredRows, rows])

    let clickTimer;
    const handleClick = (e, rindex, key) => {
        if (!props.disabled && rows.length === 0) {
            props.handleAddTableMulti(e)
        }
        if (!isDefined(clickTimer)) {
            clickTimer = setTimeout(() => {
                props.handleClickPreview();
            }, 500);
        } else {
            if (isDefined(key)) props.handleClickPreview(rindex, key);
            else props.handleClickPreview();
            clearTimeout(clickTimer);
        }
    }

    let _defaultFontsize = isDefined(props.field.options?.setup?.layout?.defaultFontSize) ? props.field.options?.setup?.layout?.defaultFontSize : 14
    let _defaultLabelSize = isDefined(props.field.options?.setup?.layout?.defaultLabelSize) ? props.field.options?.setup?.layout?.defaultLabelSize: 14

    if (props.isPreview === true) {
        return (
        <div className={`${classes.tablePreviewCtn} ${classes.scrollBar}`} key={'table-' + props.field.label}>
            <Table stickyHeader style={{ position: 'relative' }}>
                <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table" onClick={(e) => { handleClick(e) }}>
                    <TableRow className={classes.columnHeader}>
                        <TableCell align="center" className={`${classes.stickyColumn} ${classes.rowNbColumnHeader}`}>
                            <span className={classes.columnHeader}>N°</span>
                        </TableCell>
                        {
                            props.colonnes.map((entry, id) => {
                                if ((props.mode === 'plugcode-complete' && entry.encodable === false) || (props.mode === 'plugcode-consult' && entry.visible === false)) { return null; }
                                let lastRow = rows[rows.length - 1]
                                let totals = []
                                let total = ""
                                if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                                    totals = Object.keys(lastRow)
                                    if (totals.includes(entry.id.toString())) {
                                        if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                                            if (lastRow[entry.id].toString().includes(':')) total = lastRow[entry.id];
                                            else total = Math.round(lastRow[entry.id] * 1000) / 1000
                                        }
                                    }
                                }
                                const headerStyle = {
                                    fontSize: isDefined(entry.headerFontSize) ? entry.headerFontSize : _defaultLabelSize,
                                    /* width: isDefined(entry.colWidth) ? entry.colWidth * 8.5 : 'auto', */
                                    maxWidth: entry.colWidth > 0 ? entry.colWidth * 8.5 : 'auto',
                                    whiteSpace: "break-spaces",
                                }

                                const totalStyle = {
                                    fontSize: isDefined(entry.fontSize) ? entry.fontSize : _defaultLabelSize,
                                    verticalAlign: 'top',
                                }

                                return (
                                    <TableCell
                                        key={'table-header-' + props.field.label + '-' + id}
                                        style={headerStyle}
                                        align="center"
                                    >
                                        <span className={classes.columnHeader}>
                                            {getColumnLabel(entry, _defaultLabelSize)}
                                            {
                                                total !== '' &&
                                                <div className={classes.totalCtn}>
                                                    <NumberFormat value={total} thousandSeparator="." decimalSeparator="," displayType="text"
                                                        renderText={
                                                            (value, _) => <span className={classes.totalValues} style={totalStyle}>
                                                                {value}
                                                            </span>}
                                                    />
                                                </div>
                                            }
                                            <FieldVisibilityIndicator field={entry} mode={props.mode} isTableColumn />
                                        </span>
                                    </TableCell>
                                )
                            })}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBodyCtn}>
                    {
                        rows.length > 0 &&
                        rows.slice(0, 49)
                            .map((row, rindex) => {
                                if (row.recap !== true) {
                                    return (
                                        <TableRow key={'table-row-' + props.field.label + '-' + rindex}>
                                            <TableCell
                                                align="center" key={"row-index-" + rindex}
                                                style={{ width: 25, borderLeft: `1px solid ${colors.paleGrey}`, backgroundColor: 'white' }}
                                                className={`${classes.rowNbCell} ${classes.stickyColumn} `}
                                                component="th"
                                                scope="row"
                                            >
                                                <span className={classes.rowNb}>{rindex + 1}</span>
                                            </TableCell>
                                            {
                                                row.recap !== true &&
                                                Object.keys(row)
                                                    .sort((a, b) => {
                                                        if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                                                    })
                                                    .map((key, cindex) => {
                                                        let col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                        if (!isDefined(col) || (props.mode === 'plugcode-complete' && col.encodable === false) || (props.mode === 'plugcode-consult' && col.visible === false)) { return null; }
                                                        const cellStyle = {
                                                            fontSize: isDefined(col.fontSize) && col.fontSize > -1 ? col.fontSize : _defaultFontsize,
                                                            width: col.colWidth > 0 ? col.colWidth * 8.5 : 'auto',
                                                            whiteSpace: "break-spaces",
                                                        }

                                                        if (row[key] === '' && keyList.includes(key)) {
                                                            return (
                                                                <TableCell
                                                                    align="center"
                                                                    key={props.field.label + "-cell-" + key + '-' + cindex}
                                                                    style={cindex === keyList.length - 1 ? { ...cellStyle, position: 'relative' } : cellStyle}
                                                                    onClick={(e) => { handleClick(e, rindex, key) }}
                                                                >
                                                                    <div className={classes.voidCell}>
                                                                        <span className={classes.voidCell} />
                                                                    </div>
                                                                </TableCell>
                                                            )
                                                        }
                                                        else return (keyList.includes(key) &&
                                                            <TableCell
                                                                align="center"
                                                                key={"cell-" + key + '-' + cindex}
                                                                style={{ ...cellStyle, position: 'relative' }}
                                                                onClick={(e) => handleClick(e, rindex, key)}
                                                            >
                                                                {
                                                                    key === '1001' ?
                                                                        <RenderEditCellComponent
                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                            annexes={props.annexes}
                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                            containerStyle={cellStyle}
                                                                            col={getCellColumn('1001', props.colonnes)}
                                                                            columns={props.colonnes}
                                                                            control={props.control}
                                                                            controlCreate={props.controlCreate}
                                                                            controlUpdate={props.controlUpdate}
                                                                            disabled={props.disabled}
                                                                            edit="preview"
                                                                            field={props.field}
                                                                            fields={props.fields}
                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, getCellColumn('1001', props.colonnes), row)}
                                                                            handleValidate={handleEndEdit}
                                                                            id="1001"
                                                                            isPreview
                                                                            key={'edit-cell-' + rindex + '-1001'}
                                                                            mode={props.mode}
                                                                            model={props.model}
                                                                            open={true}
                                                                            owner={props.owner}
                                                                            ownerId={props.ownerId}
                                                                            plugnote={props.plugnote}
                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                            rindex={rindex}
                                                                            cindex={cindex}
                                                                            row={row}
                                                                            share={props.share}
                                                                            title={props.title}
                                                                            user={props.user}
                                                                            value={row['1001']}
                                                                            dataHash={props?.dataHash}
                                                                        />
                                                                        :
                                                                        <RenderCellValue
                                                                            columns={props.colonnes}
                                                                            defaultFontsize={_defaultFontsize}
                                                                            id={key}
                                                                            value={row[key]}
                                                                            row={row}
                                                                            fields={props.fields}
                                                                        />
                                                                }
                                                            </TableCell>
                                                        )
                                                    })}
                                            {row.recap === true && keyList.map((key, cindex) => {
                                                let col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                if ((props.mode === 'plugcode-complete' && col.encodable === false) || (props.mode === 'plugcode-consult' && col.visible === false)) { return null; }
                                                if (row.hasOwnProperty(key)) return (keyList.includes(key) &&
                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                        <RenderCellValue
                                                            columns={props.colonnes}
                                                            defaultFontsize={_defaultFontsize}
                                                            id={key}
                                                            value={row[key]}
                                                            row={row}
                                                            fields={props.fields}
                                                        />
                                                    </TableCell>);
                                                else return (
                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: 'none' }}>
                                                        <div className={classes.voidCell} >
                                                            <span className={classes.voidCell} />
                                                        </div>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>)
                                }
                                else return null
                            })
                    }
                    {
                        rows.length === 0 &&
                        <TableRow >
                            <TableCell
                                align="center"
                                key={props.field.label + '-cell-'}
                                onClick={(e) => { handleClick(e) }}
                                className={classes.noDataCell}
                            >
                                <div className={classes.voidCell}>
                                    <span className={classes.voidCell} />
                                </div>
                            </TableCell>
                            {
                                props.colonnes.map((entry, cindex) => {
                                    const cellStyle = {
                                        fontSize: isDefined(entry.fontSize) ? entry.fontSize : 12,
                                        width: isDefined(entry.colWidth) ? entry.colWidth * 8.5 : 'auto',
                                        whiteSpace: "break-spaces",
                                    }
                                    if ((props.mode !== 'plugcode-complete' || entry.encodable !== false) && (props.mode !== 'plugcode-consult' || entry.visible !== false)) {
                                        return (<TableCell
                                            align="center"
                                            key={props.field.label + '-cell-' + cindex}
                                            style={cindex === keyList.length - 1 ? { ...cellStyle, position: 'relative' } : cellStyle}
                                            onClick={(e) => { handleClick(e) }}
                                            className={classes.noDataCell}
                                        >
                                            <div className={classes.voidCell}>
                                                <span className={classes.voidCell} />
                                            </div>
                                        </TableCell>)
                                    } else return null
                                })
                            }
                        </TableRow>
                    }
                </TableBody>
                {
                    rows.length > 50 &&
                    <TableBody className={classes.viewMoreCtn} onClick={props.handleViewTable}>
                        <TableRow className={classes.viewMoreTxt} onClick={props.handleViewTable}>
                            <TableCell className={classes.viewMoreTxt}>{t('tablemulti.view-more')}</TableCell>
                        </TableRow>
                    </TableBody>
                }
            </Table>
        </div>
        )
    }
    else if (props.toPrint === true) {
        if (props.field.options?.setup?.printable === false) {
            return null
        } else {
            let colonnes = []
            props.colonnes.forEach(entry => {
                let lastRow = rows[rows.length - 1]
                let hasTotal = false
                let totals = []
                let total = ""
                if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                    totals = Object.keys(lastRow)
                    if (totals.includes(entry.id.toString())) {
                        hasTotal = true
                        if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                            if (lastRow[entry.id].toString().includes(':')) total = lastRow[entry.id];
                            else total = Math.round(lastRow[entry.id] * 1000) / 1000
                        }
                    }
                }
                // Set the colWidth Value to send to PDF, preview or Modal if it is undefined or still not set
                if (entry.colWidth === undefined || entry.colWidth === null || entry.colWidth === '') {
                    const label = entry.label;

                    let maxLength = 0;
                    let currentLength = 0;
                    //check tilde and #
                    for (let i = 0; i < label.length; i++) {
                        if (label[i] === "~") {
                            maxLength = Math.max(maxLength, currentLength);
                            currentLength = 0;
                        } else if (label.includes("#")) {
                            const indexOfHash = label.indexOf("#");
                            currentLength = indexOfHash;
                        } else {
                            currentLength++;
                        }
                    }
                    maxLength = Math.max(maxLength, currentLength);

                    entry.colWidth = maxLength
                }

                // Set the fontSize Value to send to PDF, preview or Modal if it is undefined or still not set
                if (!isDefined(entry.fontSize) || entry.fontSize === '' || entry.fontSize === -1) {
                    entry.fontSize =  isDefined(_defaultFontsize) ? (_defaultFontsize) : 11
                }
                if (!isDefined(entry.headerFontSize) || entry.headerFontSize === -1) {
                    entry.headerFontSize = isDefined(_defaultLabelSize) ? _defaultLabelSize : 11
                }

                let col =
                // (entry.format === 'radio')
                // ? {id: entry.id, value: entry.label , colWidth : entry.colWidth , fontSize : entry.fontSize, headerFontSize: entry.headerFontSize, showOnPdf : entry.showOnPdf}
                // : {id: entry.id, value: entry.label , colWidth : entry.colWidth , fontSize : entry.fontSize, headerFontSize: entry.headerFontSize, showOnPdf : entry.showOnPdf}
                {
                    editable: entry.encodable, // PF- visibility
                    id: entry.id,
                    value: entry.label,
                    colWidth: entry.colWidth,
                    fontSize: entry.fontSize,
                    headerFontSize: entry.headerFontSize,
                    showOnPdf: entry.showOnPdf,
                    visible: entry.visible,
                }
                if (hasTotal) col['total'] = total
                colonnes.push(col);
            })

            let _rows = Array.isArray(props.rows) ? props.rows : []
            return (
                <PDFTablemulti
                    borderColor={colors.borderGrey}
                    fieldId={props.field.id}
                    fieldLabel={props.field.title}
                    header={colonnes}
                    hiddenOpt={props.hiddenOpt}
                    rows={_rows.filter((row) => !row.hasOwnProperty('recap'))}
                    setup={props.field.options.setup}
                />
            )
        }
    }
    else return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleExitModal}
            aria-labelledby="simple-modal-title"
            className={classes.modal}
            BackdropProps={{ style: { backgroundColor: colors.blackAlpha } }}
        >
            <div style={modalStyle} className={classes.popup} key={'modal-table-' + props.field.label}>
                <div className={classes.modalHeader}>
                    <div className={classes.searchInputCtn}>
                        {
                            props.hasDb === true &&
                            <TextField
                                disabled={props.disabled}
                                onChange={(event) => props.handleEditValue(event.target.value)}
                                id="standard-full-width"
                                style={{ margin: 0, maxWidth: 350 }}
                                type="text"
                                multiline={false}
                                className={classes.textField}
                                fullWidth
                                helperText=""
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <Fragment>
                                            {
                                                !hideSearch &&
                                                <div className={classes.searchBtn} > {criteria}
                                                    <span id="icon-listForm" className={`material-icons md-25`} style={{ color: colors.main }} onClick={(event) => { if (!props.disabled && !hideSearch) props.handleOpenSearchModel() }}>
                                                        search
                                                    </span>
                                                </div>
                                            }
                                        </Fragment>
                                    ),
                                }}
                                margin="normal"
                                onKeyDown={(e) => { if (e.key === 'Enter' && !props.disabled && !hideSearch) props.handleOpenSearchModel() }}
                                placeholder={t('ext-table.search-table')}
                                value={value}
                            />
                        }
                    </div>

                    <div className={classes.headerTitle}>
                        <span className="titres align-items-center">
                            {props.field.titleTranslate}
                        </span>
                        <br />
                    </div>

                    <div className={classes.closeBtn}>
                        <span className={classes.exportIconCtn}>
                            {
                                rows.length > 0 &&
                                <PlugTooltip title={openSearch ? t('filters.close-search') : t('filters.search')} placement="bottom">
                                    <span style={{ fontSize: 30, color: colors.main }}>
                                        <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons`} onClick={handleToggleSearch} style={{ fontSize: 30, color: colors.main }}>
                                            search
                                        </span>
                                    </span>
                                </PlugTooltip>
                            }
                            {
                                props.limited === true ?
                                    <PlugTooltip title={t('filters.update-selection')} placement="bottom">
                                        <span style={{ fontSize: 30, color: colors.main }}>
                                            <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons`} onClick={props.handleUpdateClose} style={{ fontSize: 30, color: colors.main }}>
                                                save
                                            </span>
                                        </span>
                                    </PlugTooltip>
                                    :
                                    <PlugTooltip title={t('filters.export-csv')} placement='bottom'>
                                        <span>
                                            <FontAwesomeIcon icon={faFileCsv} size="lg" style={{ fontSize: 33, color: colors.mainButton }} className={`${classes.tablemultiExportButton} material-icons`} onClick={props.handleExportTableMulti} />
                                            {props.openDownload &&
                                                <CSVDownload data={props.datasToDownload} separator={";"} />
                                            }
                                        </span>
                                    </PlugTooltip>
                            }
                            <PlugTooltip title={t('print.print-pdf')} placement="bottom">
                                <span onClick={handleTogglePrintTable}>
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" style={{ fontSize: 33, color: colors.mainButton }} className={`${classes.tablemultiExportButton} material-icons`} />
                                </span>
                            </PlugTooltip>
                            {
                                props.recap && props.rows.length > 0 && !props.rows[props.rows.length - 1].hasOwnProperty('recap') &&
                                <PlugTooltip title="Calculate total(s)" placement="bottom">
                                    <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons `} onClick={(e) => { props.handleAddRecap(e) }} style={{ fontSize: 32, color: colors.mainButton }}>
                                        title
                                    </span>
                                </PlugTooltip>
                            }
                            {
                                !props.disabled && props.field.options.setup?.rowLimit !== 1 &&
                                <PlugTooltip title={!isLimitReached() ? t('tablemulti.add-row') : t('tablemulti.row-limit-warn')} placement="bottom">
                                        <span id="icon-add-playlist"
                                            className={`${classes.tablemultiExportButton} material-icons ${isLimitReached() ? classes.disabledIcon : classes.icon} `}
                                            onClick={(e) => { if (!isLimitReached()) handleAddRow(e) }}
                                        >
                                        add_circle_outline
                                    </span>
                                </PlugTooltip>
                            }
                        </span>
                        {
                            isDefined(getTableView()) &&
                            <PlugTooltip title={t('global.cancel')} placement="bottom">
                                <span id="icon-close"
                                    className="material-icons md-50"
                                    color={colors.iconGrey}
                                    style={{
                                        color: colors.iconGrey,
                                        marginLeft: 20,
                                    }}
                                    onClick={handleExitModal}>
                                    cancel
                                </span>
                            </PlugTooltip>
                        }

                        <PlugTooltip title={t('multiple.exit')} placement="bottom">
                            <span id="icon-close"
                                className="material-icons md-50"
                                color={colors.iconGrey}
                                style={{
                                    color: colors.iconGrey,
                                    marginLeft: 20,
                                    // borderWidth: 4, borderRadius: '50%', width: 50, height: 50, border: 'solid', display: 'flex',
                                }}
                                onClick={handleCloseModal}>
                                logout
                            </span>
                        </PlugTooltip>

                        {
                            openSearch &&
                            <Input
                                autoComplete="new-password"
                                autoFocus
                                className={classes.searchInput}
                                disableUnderline={true}
                                endAdornment={
                                    search !== "" &&
                                    <InputAdornment
                                        onClick={handleResetSearch}
                                        position="start"
                                        style={{ cursor: 'pointer', color: colors.mainButton }}>
                                        <span className={`material-icons md-25 `}>highlight_off</span>
                                    </InputAdornment>
                                }
                                id="standard-search-field"
                                inputRef={searchInput}
                                margin="none"
                                placeholder={t('common:global.search')}
                                onChange={handleChangeSearch}
                                onKeyPress={(e) => { if (e.key === 'Enter') handleSearch() }}
                                startAdornment={
                                    <InputAdornment position="start" style={{ cursor: 'pointer', color: search === '' ? '#bababa' : colors.mainButton }} onClick={handleSearch}>
                                        <Search />
                                    </InputAdornment>}
                                value={search}
                            />
                        }
                    </div>
                </div>
                {
                    printing ?
                        <div className={classes.printPreviewCtn}>
                            <PDFDownloadLink
                                document={
                                    <TableMultiPrintable
                                        field={props.field}
                                        fieldLabel={props.field.title}
                                        plugnoteNb={props.plugnoteNb}
                                        model={props.model}
                                        t={t}
                                    />
                                }
                                fileName={props.field.title + '.pdf'}
                                className={classes.pdfComponentCtn}
                            >
                                {({ blob, url, loading, error }) =>
                                    loading
                                        ? <div style={{ display: 'flex' }}>
                                            <br />
                                            <span className="material-icons md-25 "> hourglass_top </span>
                                            <div className={classes.pointer}>{t('common:global.print-sync')}</div>
                                        </div>
                                        : <div className={classes.pdfCtn}>
                                            <iframe title='pdf-preview' src={url + '#toolbar=1&FitV'} className={classes.previewCtn} height="100%" style={{ width: 'calc(820px - 154px)', maxWidth: 'calc(80vw - 154px)' }} />
                                            <br />
                                            <div style={{ display: 'flex' }}>
                                                <span className="material-icons md-25 "> picture_as_pdf </span>
                                                <div className={classes.pointer}>{t('common:global.print')}</div>
                                            </div>
                                        </div>
                                }
                            </PDFDownloadLink>
                            <div className={classes.cancelPrintBtn} onClick={handleTogglePrintTable}>
                                <span style={{ color: 'white', fontWeight: "bold", fontSize: 18 }}>{t('global.cancel')}</span>
                            </div>
                        </div>
                    :
                    <div className={classes.modalBody}>
                        {
                            rowsToDelete.length > 0 &&
                            <span className={classes.headerDeleteBtnCtn}>
                                <PlugTooltip title={t('global.cancel')} placement="right-start">
                                    <span className={`material-icons`}
                                        onClick={handleCancelDelete}
                                        style={{ color: colors.plugformBlue, fontSize: 36 }}
                                    >cancel</span>
                                </PlugTooltip>
                                <PlugTooltip title={t('tablemulti.delete-rows')} placement="right-start">
                                    <span className={` material-icons`}
                                        onClick={handleDeleteSelected}
                                        style={{ color: colors.redDark, fontSize: 36 }}
                                    >
                                        delete
                                    </span>
                                </PlugTooltip>
                            </span>
                        }
                        <div className={`${classes.tableCtn} ${classes.scrollBar}`}>
                            <Table stickyHeader>
                                <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table">
                                    <TableRow style={{ position: 'relative' }}>
                                        {
                                            !props.disabled ?
                                                <PlugTooltip title={t('tablemulti.infos-edit')} placement="right-start">
                                                    <TableCell align="center">
                                                        <span className={`${classes.rowHeader} material-icons`}>edit</span>
                                                    </TableCell>
                                                </PlugTooltip>
                                                :
                                                <TableCell align="center">
                                                    <span className={classes.columnHeader}>Row</span>
                                                </TableCell>
                                        }
                                        {
                                            props.colonnes.map((entry, id) => {
                                                if ((props.mode === 'plugcode-complete' && entry.encodable === false) || (props.mode === 'plugcode-consult' && entry.visible === false)) { return null; }
                                                let lastRow = rows[rows.length - 1]
                                                let hasTotal = false
                                                let totals = []
                                                let total = ""
                                                if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                                                    totals = Object.keys(lastRow)
                                                    if (totals.includes(entry.id.toString())) {
                                                        hasTotal = true
                                                        if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                                                            if (lastRow[entry.id].toString().includes(':')) total = lastRow[entry.id];
                                                            else total = Math.round(lastRow[entry.id] * 1000) / 1000
                                                        }
                                                    }
                                                }
                                                const cellStyle = {
                                                    whiteSpace: "break-spaces",
                                                }
                                                const totalStyle = {
                                                    fontSize: isDefined(entry.fontSize) && entry.fontSize > -1 ? entry.fontSize : _defaultFontsize,
                                                    verticalAlign: 'top',
                                                }
                                                return (
                                                    <TableCell align="center" key={"header-" + id} style={cellStyle}>
                                                        <span className={classes.columnHeader} style={cellStyle}>
                                                            {getColumnLabel(entry, _defaultLabelSize)}
                                                        </span>
                                                        {
                                                            hasTotal &&
                                                            <div className={classes.totalCtn}>
                                                                <NumberFormat value={total} thousandSeparator="." decimalSeparator="," displayType="text"
                                                                    renderText={
                                                                        (value, props) => <span className={classes.totalValues} style={totalStyle}>
                                                                            {value}
                                                                        </span>}
                                                                />
                                                            </div>
                                                        }
                                                        <FieldVisibilityIndicator field={entry} mode={props.mode} />
                                                    </TableCell>)
                                            })}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBodyCtn}>
                                    {
                                        displayedRows.length > 0 &&
                                        displayedRows.map((row, rindex) => {
                                            let rowId = rows.indexOf(row) + 1
                                            if (row.recap !== true) return (
                                                <Fragment key={rindex}>
                                                    <TableRow id={"row-index-" + rindex} style={row.recap === true ? { backgroundColor: colors.hoverBgnd, position: 'relative' } : { position: 'relative' }}>
                                                        <TableCell align="center" key={"row-index-" + rindex} style={{ width: 25, position: 'relative' }} >
                                                            {row.recap === true ?
                                                                <span className={classes.rowTotal} >Total</span>
                                                                :
                                                                props.disabled ?
                                                                    <span className={classes.rowNb}>{rowId}</span>
                                                                    :
                                                                    <PlugTooltip title={t('multiple.edit-row')} placement="right-start">
                                                                        <span
                                                                            className={classes.rowNumber}
                                                                            onClick={() => handleClickRowNb(row)}
                                                                        >{rowId}</span>
                                                                    </PlugTooltip>
                                                            }
                                                        </TableCell>
                                                        {
                                                            row.recap !== true && Object.keys(row)
                                                            .sort((a, b) => {
                                                                if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                                                            })
                                                            .map((key, cindex) => {
                                                                let col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                                if (!isDefined(col) || (isDefined(col.encodable) && props.mode === 'plugcode-complete' && col.encodable === false) || (isDefined(col.visible) && props.mode === 'plugcode-consult' && col.visible === false)) { return null; }
                                                                if (openRow !== null && openRow.row === row && openRow.id === key) return (
                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                        <RenderEditCellComponent
                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                            annexes={props.annexes}
                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                            col={getCellColumn(openRow.id, props.colonnes)}
                                                                            columns={props.colonnes}
                                                                            control={props.control}
                                                                            controlCreate={props.controlCreate}
                                                                            controlUpdate={props.controlUpdate}
                                                                            disabled={props.disabled}
                                                                            edit="cell"
                                                                            field={props.field}
                                                                            fields={props.fields}
                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, getCellColumn(openRow.id, props.colonnes), row)}
                                                                            handleValidate={handleEndEdit}
                                                                            id={openRow.id}
                                                                            key={'edit-cell-' + openRow.id}
                                                                            mode={props.mode}
                                                                            model={props.model}
                                                                            open={true}
                                                                            owner={props.owner}
                                                                            ownerId={props.ownerId}
                                                                            plugnote={props.plugnote}
                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                            rindex={rindex}
                                                                            cindex={cindex}
                                                                            row={row}
                                                                            share={props.share}
                                                                            title={props.title}
                                                                            user={props.user}
                                                                            value={row[openRow.id]}
                                                                            dataHash={props?.dataHash}
                                                                        />
                                                                    </TableCell>
                                                                );
                                                                else if (row[key] === '' && keyList.includes(key)) {
                                                                    return (
                                                                        <TableCell align="center" key={"cell-" + key + '-' + cindex} style={cindex === keyList.length - 1 ? { position: 'relative' } : null} onClick={(e) => { handleCellClick(e, row, key) }}>
                                                                            <div className={classes.voidCell}>
                                                                                <span className={classes.voidCell} />
                                                                            </div>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else return (keyList.includes(key) &&
                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ position: 'relative', }} onClick={(e) => handleCellClick(e, row, key)}>
                                                                        <div>
                                                                            {
                                                                                key === '1001' ?
                                                                                    <RenderEditCellComponent
                                                                                        createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                        annexes={props.annexes}
                                                                                        attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                        attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                        col={getCellColumn('1001', props.colonnes)}
                                                                                        columns={props.colonnes}
                                                                                        control={props.control}
                                                                                        controlCreate={props.controlCreate}
                                                                                        controlUpdate={props.controlUpdate}
                                                                                        disabled={props.disabled}
                                                                                        edit="cell"
                                                                                        field={props.field}
                                                                                        fields={props.fields}
                                                                                        handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                        handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, getCellColumn('1001', props.colonnes), row)}
                                                                                        handleValidate={handleEndEdit}
                                                                                        id="1001"
                                                                                        isPreview
                                                                                        key={'edit-cell-' + rindex + '-1001'}
                                                                                        mode={props.mode}
                                                                                        model={props.model}
                                                                                        open={true}
                                                                                        owner={props.owner}
                                                                                        ownerId={props.ownerId}
                                                                                        plugnote={props.plugnote}
                                                                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                        rindex={rindex}
                                                                                        cindex={cindex}
                                                                                        row={row}
                                                                                        share={props.share}
                                                                                        title={props.title}
                                                                                        user={props.user}
                                                                                        value={row['1001']}
                                                                                        dataHash={props?.dataHash}
                                                                                    />
                                                                                    :
                                                                                    <RenderCellValue
                                                                                        columns={props.colonnes}
                                                                                        defaultFontsize={_defaultFontsize}
                                                                                        id={key}
                                                                                        value={row[key]}
                                                                                        row={row}
                                                                                        fields={props.fields}
                                                                                    />}
                                                                        </div>
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        {
                                                            row.recap === true && keyList.map((key, cindex) => {
                                                                if (openRow !== null && openRow.row === row && openRow.id === key) return (
                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                        <RenderEditCellComponent
                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                            annexes={props.annexes}
                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                            col={getCellColumn(openRow.id, props.colonnes)}
                                                                            columns={props.colonnes}
                                                                            control={props.control}
                                                                            controlCreate={props.controlCreate}
                                                                            controlUpdate={props.controlUpdate}
                                                                            disabled={props.disabled}
                                                                            edit="cell"
                                                                            field={props.field}
                                                                            fields={props.fields}
                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, getCellColumn(openRow.id, props.colonnes), row)}
                                                                            handleValidate={handleEndEdit}
                                                                            id={openRow.id}
                                                                            key={'edit-cell-' + openRow.id}
                                                                            mode={props.mode}
                                                                            model={props.model}
                                                                            open={true}
                                                                            owner={props.owner}
                                                                            ownerId={props.ownerId}
                                                                            plugnote={props.plugnote}
                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                            rindex={rindex}
                                                                            cindex={cindex}
                                                                            row={row}
                                                                            share={props.share}
                                                                            title={props.title}
                                                                            user={props.user}
                                                                            value={row[openRow.id]}
                                                                            dataHash={props?.dataHash}
                                                                        />
                                                                    </TableCell>
                                                                );
                                                                else if (row.hasOwnProperty(key)) return (keyList.includes(key) &&
                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                        <div >
                                                                            <RenderCellValue
                                                                                columns={props.colonnes}
                                                                                defaultFontsize={_defaultFontsize}
                                                                                id={key}
                                                                                value={row[key]}
                                                                                row={row}
                                                                                fields={props.fields}
                                                                            />
                                                                        </div>
                                                                    </TableCell>);
                                                                else return (
                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: 'none' }}>
                                                                        <div className={classes.voidCell} >
                                                                            <span className={classes.voidCell} />
                                                                        </div>
                                                                    </TableCell>
                                                                )
                                                            })}

                                                        {
                                                            rowsToDelete.length > 0 ?
                                                                <td className={`${classes.deleteBtnCtn} material-icons`}
                                                                    onClick={() => handleToggleToDelete(rowId)}
                                                                    style={{ color: rowsToDelete?.includes(rowId) ? 'red' : colors.steel }}
                                                                >
                                                                    delete
                                                                </td>
                                                            :
                                                                <PlugTooltip title={t('tablemulti.delete-row') + '\n' + t('tablemulti.delete-multiple')} placement="right-start">
                                                                    <td
                                                                        className={`${classes.deleteBtnCtn} material-icons`} onClick={(e) => handleClickDelete(e, rowId)}
                                                                        style={{ color: rowToDelete === rowId ? 'red' : colors.main }}
                                                                    >
                                                                        delete
                                                                    </td>
                                                                </PlugTooltip>
                                                        }
                                                        {
                                                            rowToDelete === rowId &&
                                                            <td className={classes.deleteConfirmationCtn} >
                                                                <span >{t('tablemulti.delete-row')}?</span>
                                                                <div className={classes.confirmBtnCtn}>
                                                                    <span className={classes.confirmBtn} onClick={() => setRowToDelete(null)}>
                                                                        <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.plugformBlue }}>cancel</span>
                                                                    </span>
                                                                    <span className={classes.confirmBtn} onClick={handleDeleteSingleRow}>
                                                                        <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.bloodOrange }}>delete</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        }
                                                    </TableRow>
                                                    <TableRow key="edit-row" className={classes.editRow} style={{ position: 'relative', }}>
                                                        <TableCell colSpan={1} className={classes.editRow}>
                                                            <Collapse in={openRow !== null && openRow.row === row && openRow.id === undefined} timeout="auto" unmountOnExit>
                                                                <Box margin={0}>
                                                                    <PlugTooltip title={t('global.validate')} placement="right-start">
                                                                        <span className={`material-icons ${classes.validateIcon}`} onClick={() => handleEndEdit()}>check</span>
                                                                    </PlugTooltip>
                                                                    <PlugTooltip title={t('global.validate')} placement="right-start">
                                                                        <span className={`material-icons `} style={{ position: "absolute", zIndex: -1, bottom: 6, left: 4, color: colors.hoverBgnd }} onClick={() => handleEndEdit()}>check</span>
                                                                    </PlugTooltip>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                        <TableCell colSpan={keyList.length} className={classes.editRow} ref={openRow !== null && openRow === row ? editedRow : null}>
                                                            <Collapse in={openRow !== null && openRow.row === row && openRow.id === undefined} timeout="auto">
                                                                <Box margin={0} style={{ position: 'relative' }}>
                                                                    {openRow !== null && openRow.row === row && openRow.id === undefined &&
                                                                        <RenderEditRowComponent
                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                            annexes={props.annexes}
                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                            columns={props.colonnes}
                                                                            control={props.control}
                                                                            controlCreate={props.controlCreate}
                                                                            controlUpdate={props.controlUpdate}
                                                                            disabled={props.disabled}
                                                                            edit="row"
                                                                            field={props.field}
                                                                            fields={props.fields}
                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                            handleChangeChildrenValue={(value, column) => props.handleChangeChildrenValue(value, column, row)}
                                                                            handleValidate={handleEndEdit}
                                                                            mode={props.mode}
                                                                            model={props.model}
                                                                            owner={props.owner}
                                                                            ownerId={props.ownerId}
                                                                            plugnote={props.plugnote}
                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                            rindex={rindex}
                                                                            row={row}
                                                                            share={props.share}
                                                                            title={props.field.title}
                                                                            user={props.user}
                                                                            dataHash={props?.dataHash}
                                                                        />
                                                                    }
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </Fragment>)
                                            else return null
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}
