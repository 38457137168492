import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {InputLabel} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    selectType:{
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    field : {
        width : '100%',
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    }
}));

const templates = [
    {"label" : "print.2001", "value" : "2001"},
    {"label" : "print.9002", "value" : "9002"},
    {"label" : "print.9099", "value" : "9099"},
]

const FormatSignConstructorComponent = (props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [newData, setNewData] = useState(props?.field?.options?.template ||  "9002");


    const handleChangeTemplateSelect = (value, field, index) => {

        setNewData(value);

        field.options.template = value;

        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.template = value;

            props.setNewModel(model);

        }else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.template = value;

            props.setUpdateModel(model);
        }
    }

    useEffect(() => {
        if(typeof props.field.options.template === 'undefined'){
            handleChangeTemplateSelect(newData,props.field, props.index);
        }
    }, [newData])

    return (
        <div className={classes.mainField}>
            <div className={classes.field}>
                <Input
                    id="standard-full-width"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div>
            <div className={classes.selectType}>
                <InputLabel id={props.field.id+ "-"+props.field.format.toLowerCase()}>{ t('common:global.choose-template-mobile') }</InputLabel>
                <Select
                    key="uniquevalue"
                    fullWidth
                    displayEmpty
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    id={props.field.id+ "-"+props.field.format.toLowerCase()}
                    value={newData}
                    onChange={(event) => handleChangeTemplateSelect(event.target.value, props.field, props.index)}
                >
                    {templates.map((row, index) => (
                        <MenuItem key={index} value={row.value}>{row.value} - {t(row.label)}</MenuItem>
                    ))}

                </Select>
            </div>
            </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSignConstructorComponent);





