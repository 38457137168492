import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import {InputLabel, TextField, Tooltip} from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import {addChar} from "../../utils/utils";
import { useRef } from "react";
import { SMailSideMenu } from "../custom/SMail";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    subfield : {
        width : '100%',
    },
    selectType:{
        //paddingRight: '24px',
        // marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    field : {
        // width : '100%',
        display: 'flex',
    },
    inputField:{
        margin : '5px'
        //padding : '0px 0px 7px',
    },
    margin:{
        marginTop : '15px',
        marginBottom : '5px',
    },
    MuiFormControl: {
        margin: "inherit",
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        // paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '26px',
        lineHeight: '38px',
        paddingLeft: '10px',
        // backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    buttonsBox : {
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        display : 'flex'
    },
    marginbuttonsBox : {
        margin : 5,
        cursor : 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    inputContainer: {
        width: '100%'
    },
    input: {
        padding: '0 10px'
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height:'20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },
    sideMenu: {
        position: 'absolute',
        left: 0,
        top: 66,
        marginLeft: 15,
        paddingTop: 40,
        paddingBottom: 150,
        overflowY: 'scroll',
        width: 335,
        height: 'calc(100% + 35px)',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding:'0 10px',
        gap: 10,
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    tooltipStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '16px',
        borderRadius: 5
    },
}));

const templates = [
    {"label" : "print.blank", "value" : ""},
    {"label" : "print.2001", "value" : "2001"},
    {"label" : "print.9002", "value" : "9002"},
]

const FormatSMailConstructorComponent = (props) => {
    const classes = useStyles();
    const inputSubjectRef = useRef();
    const inputBodyRef = useRef();
    const bottomRef = useRef()
    const { t, } = useTranslation(['common', 'models']);
    const [newData, setNewData] = useState(props?.field?.options?.template ||  "");
    const [elements, setElements] = useState([]);
    const [auto, setAuto] = useState(props?.field?.options?.auto ||  "N");
    const [title, setTitle] = useState(props?.field?.options?.title ||  "");
    const [body, setBody] = useState(props?.field?.options?.body ||  "");
    const [note, setNote] = useState(props?.field?.options?.note ||  "Y");
    const [from, setFrom] = useState(props?.field?.options?.from ||  "noreply@plugnotes.com");
    const [cc, setCc] = useState(props?.field?.options?.cc ||  "");
    const [cci, setCci] = useState(props?.field?.options?.cci ||  "");
    const [selectionStart, setSelectionStart] = useState();
    const [inserts, setInserts] = useState([])

    function setCaretPosition(elemId, caretPos) {
        var el = document.getElementById(elemId);
        el.value = el.value;
        // ^ this is used to not only get "focus", but
        // to make sure we don't have it everything -selected-
        // (it causes an issue in chrome, and having it doesn't hurt any other browser)
        if (el !== null) {
            if (el.createTextRange) {
                var range = el.createTextRange();
                range.move('character', caretPos);
                range.select();
                return true;
            }
            else {
                // (el.selectionStart === 0 added for Firefox bug)
                if (el.selectionStart || el.selectionStart === 0) {
                    el.focus();
                    el.setSelectionRange(caretPos, caretPos);
                    return true;
                }
                else  { // fail city, fortunately this never happens (as far as I've tested) :)
                    el.focus();
                    return false;
                }
            }
        }
    }

    const handleChangeTitle = (value, field, index) => {
        setTitle(value);
        field.options.title = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.title = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.title = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeBody = (value, addValue , field, index) => {
        let newBody = "";
        if(typeof selectionStart === 'undefined'){
            if(addValue !== null){
                newBody = `${value} ${addValue}`
            }
            else {
                newBody = `${value}`
            }
        }
        else{
            if(addValue !== null) {
                newBody = addChar(`${value}`, `${addValue}`, selectionStart);
                setSelectionStart((selectionStart + addValue.length) + 2);
                setTimeout(() => {
                    setCaretPosition("body-" + props.field.key, selectionStart + addValue.length + 2);
                    inputBodyRef.current.blur()
                    inputBodyRef.current.focus()
                }, 200)
            }
            else {
                newBody = `${value}`
            }
        }
        setBody(newBody);
        field.options.body = newBody;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.body = newBody;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.body = newBody;

            props.setUpdateModel(model);
        }
    }

    const handleChangeFrom = (value, field, index) => {
        setFrom(value);
        field.options.from = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.from = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.from = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeCc = (value, field, index) => {
        setCc(value);
        field.options.cc = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.cc = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.cc = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeCci = (value, field, index) => {
        setCci(value);
        field.options.cci = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.cci = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.cci = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeTemplateSelect = (value, field, index) => {
        setNewData(value);
        field.options.template = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.template = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.template = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeTemplateRadio = (value, field, index) => {
        setNote(value);
        field.options.note = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.note = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.note = value;
            props.setUpdateModel(model);
        }
    }

    const handleChangeAuto = (value, field, index) => {
        // console.log('ici');
        // console.log(value);
        // console.log(field);
        // console.log(index);
        setAuto(value);
        field.options.auto = value;
        if(props.update === false){
            let model = [...props.newModel];
            model[index].options.auto = value;
            props.setNewModel(model);
        }
        else if(props.update === true){
            let model = [...props.updateModel];
            model[index].options.auto = value;
            props.setUpdateModel(model);
        }
    }

    const updateSubjectSelectionStart = () => setSelectionStart(inputSubjectRef.current.selectionStart);

    const updateBodySelectionStart = () => {
        let cursor = inputBodyRef.current.selectionStart
        setSelectionStart(cursor);
        if (inserts.length > 0) {
            for (let index = 0; index < inserts.length; index++) {
                const element = inserts[index];
                if(cursor > element.start && cursor < element.end) {
                    inputBodyRef.current.setSelectionRange(element.start+1, element.end-1)
                }
            }
        }
    }

    const updateInsertedValues = () => {
        let values = []
        if(body !== '')
        for (let index = 0; index < body.length; index++) {
            const elementA = body.charAt(index);
            const elementB = body.charAt(index + 1);
            const elementC = body.charAt(index + 2);
            if(elementA ==='{' && elementB === '{'){
                let indice = index
                let char = ''
                let value = {}
                while (char !== '}' && indice < body.length) {
                    char = body.charAt(indice)
                    indice++
                }
                value.start = index - 1
                value.end = indice + 2
                value.value = body.substring(index + 2, indice - 1)
                values.push(value)
            }
            if(elementA ==='*' && elementB === '*' && elementC === '*') {
                let indice = index + 3
                let char = ''
                let value = {}
                while (char !== '*' && body.charAt(indice + 1) && indice < body.length) {
                    char = body.charAt(indice)
                    indice++
                }
                value.start = index - 1
                value.end = indice + 2
                value.value = body.substring(index + 3, indice - 1)
                values.push(value)
            }
        }
        setInserts(values)
    }

    /*    useEffect(() => {
            if(typeof props.field.options.template === 'undefined' || props.field.options.template === ""){
                handleChangeTemplateSelect(newData,props.field, props.index);
            }
        }, [newData]);*/

    useEffect(() => {
        if(typeof props.field.options.title === 'undefined' || props.field.options.title === ""){
            handleChangeTitle(title,props.field, props.index);
        }
    }, [title]);

    useEffect(() => {
        if(typeof props.field.options.body === 'undefined' || props.field.options.body === ""){
            handleChangeBody(body,"",props.field, props.index);
        }
        updateInsertedValues()
    }, [body]);

    useEffect(() => {
        if(typeof props.field.options.note === 'undefined' || props.field.options.note === ""){
            handleChangeTemplateRadio(note,props.field, props.index);
        }
    }, [note]);

    useEffect(() => {
        if(typeof props.field.options.auto === 'undefined' || props.field.options.auto === ""){
            handleChangeAuto(auto,props.field, props.index);
        }
    }, [auto]);

    // useEffect(() => {console.log('cursor : ', selectionStart);}, [selectionStart])

    useEffect(() => {
        if(typeof props.folderName !== "undefined"){
            if(title == ""){
                setTitle(props.folderName);
            }

            if(body == ""){
                setBody(t('common:mails.addto') +  props.folderName + ".");
            }
        }
    }, [props.folderName]);


    const getElements = () => {
        let elements = [];
        if(props.update){
            props.updateModel.map((item, index) => {
                elements.push({ label: item.label, value: "field"+item.id, keyLabel: 'label-'+item.key, keyValue: 'value-'+item.key });
            });
        }
        else{
            props.newModel.map((item, index) => {
                elements.push({ label: item.label, value: "field"+item.id, keyLabel: 'label-'+item.key, keyValue: 'value-'+item.key});
            });
        }
        return elements;
    };

    function onKeyPress (e) {
        let insertSelected = inputBodyRef.current.selectionStart !== inputBodyRef.current.selectionEnd
        let before = inputBodyRef.current.selectionStart
        let after = inputBodyRef.current.selectionEnd
        switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowUp':
                if(insertSelected) inputBodyRef.current.setSelectionRange(before, before)
                break;
            case 'ArrowRight':
            case 'ArrowDown':
                if(insertSelected) inputBodyRef.current.setSelectionRange(after, after)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setElements([...getElements()]);
    }, [props.fields]);

    useEffect(() => {updateInsertedValues()}, [])

    return (
    <div className={classes.mainField}>
        <div className={classes.subfield}>
            <div className={classes.sideHeader}>
                <div className={classes.inputContainer}>
                    <InputLabel className={classes.margin} htmlFor={"cc-"+props.field.key}>{ t('common:mails.destinatairecc') }</InputLabel>
                    <TextField
                        id={"cc-"+props.field.key}
                        placeholder="cc"
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            className: classes.textField,
                            fieldkey: "cc-"+props.field.key,
                        }}
                        InputLabelProps={{
                            className: classes.margin,
                        }}
                        InputProps={{disableUnderline: true,}}
                        className={classes.input}
                        onChange={(event) => handleChangeCc(event.target.value, props.field, props.index)}
                        value={cc}
                        variant="standard"
                    />
                </div>
                <div className={classes.inputContainer}>
                    <InputLabel className={classes.margin} htmlFor={"from-"+props.field.key}>
                        { t('common:mails.destinatairefrom') }
                        <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:smail.info-sender').split('\n').map((c, index) => {
                                return (<p key={'1' + index + '-' + c[0]}> {c} </p>)})} placement="top">
                            <span className="material-icons" style={{fontSize: 20, color: colors.mainButton}}>
                                info
                            </span>
                        </Tooltip>
                        </InputLabel>
                    <TextField
                        className={classes.input}
                        id={"from-"+props.field.key}
                        fullWidth={true}
                        placeholder="from"
                        InputProps={{disableUnderline: true,}}
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            fieldkey: "from-"+props.field.key,
                            className: classes.textField,
                        }}
                        margin="none"
                        onChange={(event) => handleChangeFrom(event.target.value, props.field, props.index)}
                        value={from}
                        variant="standard"
                    />
                </div>
            </div>
            <InputLabel className={classes.margin} htmlFor={"cc-"+props.field.key}>{ t('common:mails.destinatairecci') }</InputLabel>
            <TextField
                id={"cci-"+props.field.key}
                placeholder="cci"
                fullWidth={true}
                margin="none"
                inputlabelprops={{
                    shrink: true,
                }}
                inputProps={{
                    className: classes.textField,
                    fieldkey: "cci-"+props.field.key,
                }}
                InputLabelProps={{
                    className: classes.margin,
                }}
                InputProps={{disableUnderline: true,}}
                className={classes.input}
                onChange={(event) => handleChangeCci(event.target.value, props.field, props.index)}
                value={cci}
                variant="standard"
            />
            <InputLabel className={classes.margin} htmlFor={"title-"+props.field.key}>{ t('common:mails.sujet') }</InputLabel>
            <div className={classes.field}>
                <Input
                    id={"title-"+props.field.key}
                    placeholder="title"
                    fullWidth={true}
                    margin="none"
                    disableUnderline={true}
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={{
                        className: classes.textField,
                        fieldkey: "title-"+props.field.key,
                    }}
                    onSelect={updateSubjectSelectionStart}
                    inputRef={inputSubjectRef}
                    className="text-line-margin"
                    onChange={(event) => handleChangeTitle(event.target.value, props.field, props.index)}
                    value={title}
                />
            </div>
            <InputLabel className={classes.margin} htmlFor={"body-"+props.field.key}>{ t('common:mails.message') }</InputLabel>
            <span style={{height : '25px', fontSize: 10, fontStyle: 'italic', fontWeight: 'bold', color: colors.red}}>
                {t('common:smail.info-error')}
            </span>
            <div className={classes.field}>
                <Input
                    // autoFocus
                    className="text-line-margin"
                    disableUnderline={true}
                    fullWidth={true}
                    id={"body-"+props.field.key}
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={{
                        fieldkey: "body-"+props.field.key,
                        className: classes.textFieldArea,
                    }}
                    inputRef={inputBodyRef}
                    margin="none"
                    multiline={true}
                    onChange={(event) => handleChangeBody(event.target.value, null ,props.field, props.index)}
                    onKeyDown={(e) => onKeyPress(e)}
                    onSelect={updateBodySelectionStart}
                    placeholder="body"
                    rows={10}
                    value={body}
                />
            </div>
            <InputLabel className={classes.margin} style={{textAlignLast: 'center'}} ref={bottomRef}>***</InputLabel>
            <SMailSideMenu
                {...props}
                hasAuto={true}
                body={body}
                elements={elements}
                handleChangeBody={handleChangeBody}
                handleChangeTemplateRadio={handleChangeTemplateRadio}
                handleChangeAuto={handleChangeAuto}
                handleChangeTemplateSelect={handleChangeTemplateSelect}
                handleOpenCloseSmail={props.handleOpenCloseSmail}
                hasPW={false}
                newData={newData}
                note={note}
                auto={auto}
            />
            {/* <div className={classes.sideMenu}>
                <div className={classes.sideHeader}>
                    <InputLabel className={classes.margin} htmlFor={"body-"+props.field.key}>{ t('common:smail.options').toLocaleUpperCase() }</InputLabel>
                    <button className={`button ${classes.sideHeader}`} onClick={() => props.handleOpenCloseSmail()}>{t('common:global.validate')}</button>
                </div>
                <div className={classes.itemContainer}>
                    <InputLabel className={classes.margin}>{t('common:smail.instructions')}</InputLabel>
                    <InputLabel className={classes.sideInstructions}>
                        {/* <div className={classes.instructionsFont}><LabelOutlined className={classes.labelButton}/>: insert field label </div>
                        <div className={classes.instructionsFont}><Subject className={classes.valueButton}/>: insert item value</div>
                    </InputLabel>
                    <InputLabel className={classes.margin} htmlFor={"body-"+props.field.key}>1—{ t('common:smail.label-fields')}</InputLabel>
                    {elements && elements.length > 0 && elements.length <= 15 ? (
                        elements.map((el) => (
                            <button
                                className={classes.marginbuttonsBox}
                                key={el.keyLabel}
                                // onClick={() => handleChangeBody(body, el.label , props.field, props.index)}
                            >
                                {/* <Tooltip title={t('common:smail.insert-label')} placement="top-end">
                                    <LabelOutlined className={classes.labelButton} key={el.keyLabel}
                                    onClick={() => handleChangeBody(body, el.label , props.field, props.index)}/>
                                </Tooltip>
                                {el.label}
                                <Tooltip title={t('common:smail.insert-value')} placement="top-start">
                                    <Subject className={classes.valueButton}key={el.keyValue}
                                        onClick={() => handleChangeBody(body, '{{' + el.label.substr(0, 18) + '__' + el.value + '}}', props.field, props.index)}/>
                                </Tooltip>
                            </button>
                        ))
                    ) : null}
                </div>
                <InputLabel className={classes.margin} htmlFor={"body-"+props.field.key}>{ t('common:smail.value-complementary') }</InputLabel>
                <div className={classes.itemContainer}>
                    <div className={classes.itemGroupContainer}>
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => handleChangeBody(body, '{{PN}}', props.field, props.index)}
                        >
                            {'PN number'}
                            <Subject className={classes.valueButton}/>
                        </button>

                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => handleChangeBody(body, '{{PN-}}', props.field, props.index)}
                        >
                            {'PN code'}
                            <Subject className={classes.valueButton}/>
                        </button>
                    </div>
                    <div className={classes.itemGroupContainer}>
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => handleChangeBody(body, '{{PF}}', props.field, props.index)}
                        >
                            {'PF title'}
                            <Subject className={classes.valueButton}/>
                        </button>
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => handleChangeBody(body, '{{PF Key}}', props.field, props.index)}
                        >
                            {'PF key'}
                            <Subject className={classes.valueButton}/>
                        </button>
                    </div>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => handleChangeBody(body, '{{Date Modif}}', props.field, props.index)}
                    >
                        {'Date Modif'}
                        <Subject className={classes.valueButton}/>
                    </button>
                    <div className={classes.itemGroupContainer}>
                        <Tooltip title={t('common:smail.insert-value')} placement="top-start">
                            <button
                                className={classes.marginbuttonsBox}
                                onClick={() => handleChangeBody(body, '{{User}}', props.field, props.index)}
                            >
                                {'User'}
                                <Subject className={classes.valueButton}/>
                            </button>
                        </Tooltip>
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => handleChangeBody(body, '{{User Gsm}}', props.field, props.index)}
                        >
                            {'User Gsm'}
                            <Subject className={classes.valueButton}/>
                        </button>
                    </div>
                </div>
                <InputLabel className={classes.margin} id={props.field.id+ "-"+props.field.format.toLowerCase()}>2—{ t('common:smail.label-select-template') }</InputLabel>
                <div className={classes.selectType}>
                    <Select
                        key="uniquevalue"
                        fullWidth
                        displayEmpty
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                        id={props.field.id+ "-"+props.field.format.toLowerCase()}
                        value={newData}
                        onChange={(event) => handleChangeTemplateSelect(event.target.value, props.field, props.index)}
                    >
                        {templates.map((row, index) => (
                            <MenuItem key={index} value={row.value}>{row.value} - {t(row.label)}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div className={classes.sideHeader}>
                    <InputLabel className={classes.margin} htmlFor={"note-"+props.field.key}>3—{ t('common:mails.attachments-notes') }</InputLabel>
                    <div className={classes.field}>
                        <RadioGroup className={classes.RadioGroupCell} aria-label={"note-"+props.field.key} name={"note-"+props.field.key} value={note} onChange={(event => handleChangeTemplateRadio(event.target.value, props.field, props.index))}>
                            <FormControlLabel size='small' value="Y" control={<Radio classes={{root: classes.radioIcon, checked: classes.radioIconChecked}}  size='small' />} label={<span className={classes.radioLabel}>Y</span>} />
                            <FormControlLabel size='small' value="N" control={<Radio classes={{root: classes.radioIcon, checked: classes.radioIconChecked}}  size='small' />} label={<span className={classes.radioLabel}>N</span>} />
                        </RadioGroup>
                    </div>
                </div>
            </div> */}

        </div>
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailConstructorComponent);
