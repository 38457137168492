import { Checkbox, InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import React from "react";
import colors from "../../assets/themes/Colors";
import { Subject } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        position: 'absolute',
        left: 0,
        top: 0,
        marginLeft: 15,
        paddingTop: 10,
        paddingRight: 5,
        paddingBottom: 150,
        overflowY: 'auto',
        width: 335,
        height: 'calc(100% + 35px)',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white,
        boxShadow: '20px 0px 20px rgb(0, 0, 0, 0.2)'
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        }
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding:'0 10px',
        gap: 10,
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    margin:{
        marginTop : '15px',
        marginBottom : '5px',
    },
    subtitle: {
        fontWeight: 'bold'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // paddingLeft: 20,
    },
    marginbuttonsBox : {
        margin : 5,
        cursor : 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height:'20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },

}))

const templates = [
    {"label" : "print.blank", "value" : ""},
    {"label" : "print.2001", "value" : "2001"},
    {"label" : "print.9002", "value" : "9002"},
]

export function SMailSideMenu (props) {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models']);
    const body = props.body
    const elements = props.elements
    const newData = props.newData
    const note = props.note
    const auto = props.auto


    return(
    <div className={classes.sideMenu}>
            <div className={classes.sideHeader}>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"+props.field.key}>{ t('common:smail.options').toLocaleUpperCase() }</InputLabel>
                <span id="icon-close" className="material-icons md-50 lightgrey" onClick={() => {props.handleOpenCloseSmail()}}>
                    close
                </span>
            </div>
            <div className={classes.itemContainer}>
                <InputLabel className={classes.margin}>{t('common:smail.instructions')}</InputLabel>
                <InputLabel className={classes.sideInstructions}>
                </InputLabel>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"+props.field.key}>1—{ t('common:smail.label-fields')}</InputLabel>
                {elements && elements.length > 0 && elements.length <= 15 ? (
                    elements.map((el) => (
                        <button
                            className={classes.marginbuttonsBox}
                            key={el.keyLabel}
                            onClick={() => props.handleChangeBody(body, '***' + el.label.substr(0, 18) + '__' + el.value + '**', props.field, props.index)}
                        >
                            {el.label}
                            <Tooltip title={t('common:smail.insert-value')} placement="top-start">
                                <Subject className={classes.valueButton}key={el.keyValue}
                                    onClick={() => props.handleChangeBody(body, '***' + el.label.substr(0, 18) + '__' + el.value + '**', props.field, props.index)}/>
                            </Tooltip>
                        </button>
                    ))
                ) : null}
            </div>
            <InputLabel className={classes.margin} htmlFor={"body-"+props.field.key}>{ t('common:smail.value-complementary') }</InputLabel>
            <div className={classes.itemContainer}>
                <div className={classes.itemGroupContainer}>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***PN**', props.field, props.index)}
                    >
                        {'PN number'}
                        <Subject className={classes.valueButton}/>
                    </button>

                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***PN-**', props.field, props.index)}
                    >
                        {'PN code'}
                        <Subject className={classes.valueButton}/>
                    </button>
                </div>
                <div className={classes.itemGroupContainer}>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***PF**', props.field, props.index)}
                    >
                        {'PF title'}
                        <Subject className={classes.valueButton}/>
                    </button>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***PF Key**', props.field, props.index)}
                    >
                        {'PF key'}
                        <Subject className={classes.valueButton}/>
                    </button>
                </div>
                {props.hasPw &&
                <div className={classes.itemGroupContainer}>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***PW**', props.field, props.index)}
                    >
                        <Subject className={classes.valueButton}/>
                        {'PW'}
                    </button>

                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***PW Url**', props.field, props.index)}
                    >
                        <Subject className={classes.valueButton}/>
                        {'PW Url'}
                    </button>
                </div>
                    }

                <button
                    className={classes.marginbuttonsBox}
                    onClick={() => props.handleChangeBody(body, '***Date Modif**', props.field, props.index)}
                >
                    {'Date Modif'}
                    <Subject className={classes.valueButton}/>
                </button>
                <div className={classes.itemGroupContainer}>
                    <Tooltip title={t('common:smail.insert-value')} placement="top-start">
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => props.handleChangeBody(body, '***User**', props.field, props.index)}
                        >
                            {'User'}
                            <Subject className={classes.valueButton}/>
                        </button>
                    </Tooltip>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, '***User Gsm**', props.field, props.index)}
                    >
                        {'User Gsm'}
                        <Subject className={classes.valueButton}/>
                    </button>
                </div>
            </div>
            <InputLabel className={`${classes.margin} ${classes.subtitle}`} id={props.field.id+ "-"+props.field.format.toLowerCase()}>2—{ t('common:smail.label-select-template') }</InputLabel>
            <div className={classes.selectType}>
                <Select
                    key="uniquevalue"
                    fullWidth
                    displayEmpty
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    id={props.field.id+ "-"+props.field.format.toLowerCase()}
                    value={newData}
                    onChange={(event) => props.handleChangeTemplateSelect(event.target.value, props.field, props.index)}
                >
                    {templates.map((row, index) => (
                        <MenuItem key={index} value={row.value}>{row.value} - {t(row.label)}</MenuItem>
                    ))}
                </Select>
            </div>
            <div className={classes.sideHeader}>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"note-"+props.field.key}>3—{ t('common:mails.attachments-notes') }</InputLabel>
                <div className={classes.field}>
                    {/* <RadioGroup className={classes.RadioGroupCell} aria-label={"note-"+props.field.key} name={"note-"+props.field.key} value={note} >
                        <FormControlLabel size='small' value="Y" control={<Radio classes={{root: classes.radioIcon, checked: classes.radioIconChecked}}  size='small' />} label={<span className={classes.radioLabel}>Y</span>} onClick={((event) => props.handleChangeTemplateRadio('Y', props.field, props.index))}/>
                        <FormControlLabel size='small' value="N" control={<Radio classes={{root: classes.radioIcon, checked: classes.radioIconChecked}}  size='small' />} label={<span className={classes.radioLabel}>N</span>} onClick={((event) => props.handleChangeTemplateRadio('N', props.field, props.index))}/>
                    </RadioGroup> */}
                    <Checkbox
                    style={{color: colors.main, width: '30px', marginRight: '5px'}}
                    checked={note === 'Y'}
                    onChange={() => {if(note === 'Y') props.handleChangeTemplateRadio('N', props.field, props.index); else props.handleChangeTemplateRadio('Y', props.field, props.index)}}
                />
                </div>
            </div>
        {
            props.hasAuto &&
            <div className={classes.sideHeader}>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"auto-"+props.field.key}>4—{ t('common:smail.auto-send') }</InputLabel>
                <div className={classes.field}>
                    <Checkbox
                        style={{color: colors.main, width: '30px', marginRight: '5px'}}
                        checked={auto === 'Y'}
                        onChange={() => {if(auto === 'Y') props.handleChangeAuto('N', props.field, props.index); else props.handleChangeAuto('Y', props.field, props.index)}}
                    />
                </div>
            </div>
        }

            {props.hasPw &&
            <div className={classes.sideHeader}>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"title-"+props.field.key}>4—{ t('common:smail.share-pw') }</InputLabel>
                <Checkbox
                    style={{color: colors.main, width: '30px', marginRight: '5px'}}
                    checked={props.share}
                    onChange={(event) => {props.handleChangeShare(event.target.checked, props.field, props.index)}}
                />
            </div>}
        </div>
    )
}
