import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import {getCurrentEnvironnement} from "../../utils/Common";
import ModalFormatFormulaConstructorComponent from "./modal/ModalFormatFormulaConstructorComponent";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        minWidth: '100%',
        display: 'flex',
        flexDirection : 'column'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        display: 'flex',
    },
    mainInputFieldTools:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom : '20px',
        cursor : "pointer"
    }
}));


const FormatFormulaConstructorComponent = (props) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [openModal, setOpenModal] = useState(false);
    const [formula, setFormula] = useState(typeof props.field.options.formula !== "undefined" && props.field.options.formula !== null  && props.field.options.formula !== ""  ? props.field.options.formula  : "");

    const environment = getCurrentEnvironnement();

    const handleDataFormula = (formula) => {

        props.field.options.formula = formula;

        if(props.update === false){
            let model = [...props.newModel];
            model[props.index].options.formula = formula;

            props.setNewModel(model);

        }else if(props.update === true){
            let model = [...props.updateModel];
            model[props.index].options.formula = formula;

            props.setUpdateModel(model);
        }

        setFormula(formula)
    }


    const handleConfigurator = (field) => {
        handleModalOpen();
    }

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const handleModalOpen = () => {
        setOpenModal(true);
    }


    return (
        <div className={classes.mainField}>
            <div className={classes.field}>
                <Input
                    id="standard-full-width-plugform"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth={true}
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div>
            <div className={classes.inputType}>
                <div className={classes.mainInputFieldTools} onClick={(event) => handleConfigurator(props.field)}>
                    <Tooltip  title={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-configurator')} aria-label="add" placement="top-start">
                        <span id="icon-more" className={`material-icons md-30`}>
                            settings
                        </span>
                    </Tooltip>
                    <span>{t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-configurator')}</span>
                </div>
                <div className={classes.mainInputField}>
                    { formula !== "" &&
                    <Input
                        id="standard-full-width-field"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-formula')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: 'pluglinkfields-'+props.field.id,
                                className: classes.inputField,
                                maxLength: 40
                            }
                        }
                        disabled={true}
                        className="text-line"
                        value={formula}
                    />
                    }
                </div>
            </div>
            {
                openModal &&
                <ModalFormatFormulaConstructorComponent
                    folderName={props.folderName}
                    field={props.field}
                    open={openModal}
                    close={handleModalClose}
                    fields={props.update ? props.updateModel : props.newModel}
                    handleDataFormula={handleDataFormula}
                />
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatFormulaConstructorComponent);





