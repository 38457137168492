import React, {useEffect} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel} from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import {Input, Tooltip} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import {confirmAlert} from "react-confirm-alert";
import {postExportDataTemplateCsvRequest, postExportTemplateCsvRequest} from "../../actions/eximport";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {MailExportWithoutDataTemplateFR} from "../../templates/mail/export/MailExportWithoutDataTemplateFR";
import {MailExportWithoutDataTemplateEN} from "../../templates/mail/export/MailExportWithoutDataTemplateEN";
import {MailExportWithDataTemplateFR} from "../../templates/mail/export/MailExportWithDataTemplateFR";
import {MailExportWithDataTemplateEN} from "../../templates/mail/export/MailExportWithDataTemplateEN";
import ModalSearchInRepertoriesComponent from "./ModalSearchInRepertoriesComponent";
import colors from "../../assets/themes/Colors";
import {MailExportWithoutDataTableTemplateFR} from "../../templates/mail/export/MailExportWithoutDataTableTemplateFR";
import {MailExportWithoutDataTableTemplateEN} from "../../templates/mail/export/MailExportWithoutDataTableTemplateEN";

function getModalStyle() {
    // const top = 5;

    return {
        // top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,

    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        maxHeight: '95vh',
        overflowY: 'auto',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    div:{
        /* border: '1px red solid', */
        display: 'flex',
        justifyContent: 'flex-start',
        padding: "10px",
        width : '100%'
    },    button:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "#58c3c1",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
        width : '35px',
        height : '35px',
    },
    selectedButton:{
        margin : '5px',
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
        height : '35px',
        width : '35px',
    },
    sous_header_button: {
        width: "150px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    button_save: {
        color: "white",
        backgroundColor: colors.mainButton,
        width : '70px',
        border: `2px solid transparent`,
        alignSelf : 'flex-end'
    },
    button_cancel: {
        color: colors.mainDark,
        backgroundColor: "transparent",
        border: `2px solid ${colors.mainDark}`,
        fontWeight: "600",
        width : '70px',
        marginLeft : '5px',
        marginRight : '5px',
        alignSelf : 'flex-end'
    },
}));

const getCurrentDate=()=>{

    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    //Alert.alert(date + '-' + month + '-' + year);
    // You can turn it in to your desired format
    return date + '/' + month + '/' + year;//format: dd-mm-yyyy;
}

const checkDoublon = (list, text) => {
    return list.findIndex((item) => item === text) > -1
};

const exists = (model) => {
    return Object.keys(model).some(function(k) {
        return model[k] === "tablemulti" || model[k] === 'tablemultin' || model[k] === 'smailvalidmulti' ;
    })
};

const ModalTemplateImportExportCsvComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    let languageCode = i18n.language.substr(0, 2).toLowerCase();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const model = getModel();

    const environment = getCurrentEnvironnement();

    const modelName = model.label + ' (' +  model.clef + ')';
    const titleModelName = '[' + model.clef + '] ' +  model.label;
    const env = '(' + environment.envIndex + ' - ' + environment.name + ')';
    const profilName = props.mainProfil.firstname + ' ' + props.mainProfil.lastname;


    const [body, setBody] = React.useState(  "");
    const [sujet, setSujet] = React.useState("");
    const [expediteur, setExpediteur] = React.useState("");
    const [mailNotification, setMailNotification] = React.useState([props.mainProfil.email]);
    const [mailNotificationCc, setMailNotificationCc] = React.useState([]);
    const [mail, setMail] = React.useState("");
    const [mailCc, setMailCc] = React.useState("");
    const [template, setTemplate] = React.useState("new");
    const [method, setMethod] = React.useState('');
    const [openRepertory, setOpenRepertory] = React.useState(false);

    const [exist,setExist] = React.useState(exists(props.model));


    const handleChange = (value) => {
        switch (method){
            case 'setMailNotification':
                value.map((item, index) => {
                    handleAddNotificationValue(item.email);
                })
                break;
            case 'setMailNotificationCc':
                value.map((item, index) => {
                    handleAddNotificationCcValue(item.email);
                })
                break;
        }
        setMethod('');
    }

    const handleCloseSubModal = () => {
        setOpenRepertory(false);
    }


    const handleCloseModal = () => {
        props.close();
    };

    const handleSendMail = () => {

    }

    /**
     * Ajout d'une vérif pour le type 'email'
     */
    const isEmail = (value) => {
        return /(.)+@(.)+/.test(value)
    }

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotification = () => {
        let email = mail;
        let tmpMailNotification = mailNotification;

        if(isEmail(email)){
            if (!checkDoublon(tmpMailNotification, email)) {
                mailNotification.push(email);
            }

            setMailNotification(mailNotification);
            setMail("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationCc = () => {
        let emailCC = mailCc;
        let tmpMailNotification = mailNotificationCc;

        if(isEmail(emailCC)){
            if (!checkDoublon(tmpMailNotification, emailCC)) {
                mailNotificationCc.push(emailCC);
            }

            setMailNotificationCc(mailNotificationCc);
            setMailCc("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationValue = (value) => {
        let email = value;
        let tmpMailNotification = mailNotification;

        if(isEmail(email)){
            if (!checkDoublon(tmpMailNotification, email)) {
                mailNotification.push(email);
            }

            setMailNotification(mailNotification);
            setMail("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationCcValue = (value) => {
        let emailCC = value;
        let tmpMailNotification = mailNotificationCc;

        if(isEmail(emailCC)){
            if (!checkDoublon(tmpMailNotification, emailCC)) {
                mailNotificationCc.push(emailCC);
            }

            setMailNotificationCc(mailNotificationCc);
            setMailCc("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    /**
     * Permet de supprimer un mail pour la notification
     * @private
     */
    const handleDeleteNotification = (mail, mailIndex) => {
        setMailNotification(mailNotification.filter((item , index) => {
            return item !== mail
        }));
    };

    /**
     * Permet de supprimer un mail pour la notification
     * @private
     */
    const handleDeleteNotificationCc = (mail, index) => {
        setMailNotificationCc(mailNotificationCc.filter((item , index) => {
            return item !== mail
        }));
    };

    const handlePressValidate = () => {

        if(mailNotification.length > 0){
            let message = t('export.plug-list-export-csv');
            message += ' ' + t('export.plug-list-export-csv-2');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let payload = {
                                model : model.hash,
                                data : template === 'edit',
                            };
                            payload.mailNotification = mailNotification;
                            payload.mailNotificationCc = mailNotificationCc.length > 0 ? mailNotificationCc : null;
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            payload.body = body.length > 0 ? body : null;
                            if(template === 'edit-table'){
                                payload.filter = props.payload.filter;
                                payload.plugList = props.payload.plugList;
                                payload.plugsSelectAll = props.payload.plugsSelectAll;
                            }
                            props.close();

                            if(template === 'edit-table'){
                                props.attemptExportTableTemplateCsv(payload,props.modelShared,environment.userhash);
                            }else{
                                props.attemptExportTemplateCsv(payload,props.modelShared,environment.userhash);
                            }
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    useEffect(() => {
        let params = {};

        if(template === 'new'){
            setSujet(languageCode === 'fr' ? model.clef + '-' + model.label + ' - Template 1 - Création Plugnotes': model.clef + '-' + model.label + ' - Template 1 - Create Plugnotes');
            setBody(languageCode === 'fr' ? MailExportWithoutDataTemplateFR(params) :  MailExportWithoutDataTemplateEN(params));
        }else if(template === 'edit'){
            setSujet(languageCode === 'fr' ? model.clef + '-' + model.label + ' - Template 3 - Mise à jour Plugnotes': model.clef + '-' + model.label + ' - Template 3 - Update Plugnotes');
            setBody(languageCode === 'fr' ? MailExportWithDataTemplateFR(params) :  MailExportWithDataTemplateEN(params));
        }else if(template === 'edit-table'){
            setSujet(languageCode === 'fr' ? model.clef + '-' + model.label + ' - Template 2 - Remplir les tableaux': model.clef + '-' + model.label + ' - Template 2 - Fill in Tables');
            setBody(languageCode === 'fr' ? MailExportWithoutDataTableTemplateFR(params) :  MailExportWithoutDataTableTemplateEN(params));
        }


    }, [template]);

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <span>Template import et export via excel {titleModelName} </span>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>{ t('common:export.template-import-export-title') }</InputLabel>
                        </span>

                        <div className={classes.div}>
                            <Tooltip title={t('common:export.template-import-new-csv')}>
                                <button className={`${classes.sous_header_button} ${template === 'new' ? classes.button_save : classes.button_cancel}`} onClick={(event) => setTemplate('new')}>
                                        <span className="material-icons" style={{ color: template === 'new' ? colors.hoverBgnd : colors.main}}>
                                            table_chart
                                        </span>{" "}
                                </button>
                            </Tooltip>

                            {
                                exist &&
                                <Tooltip title={t('common:export.template-import-edit-table-csv')}>
                                    <button className={`${classes.sous_header_button} ${template === 'edit-table' ? classes.button_save : classes.button_cancel}`} onClick={() => setTemplate('edit-table')}>
                                        <span className="material-icons" style={{ color: template === 'edit-table' ? colors.hoverBgnd : colors.main}} >
                                            table_rows
                                        </span>
                                    </button>
                                </Tooltip>
                            }

                        </div>




                        {/*                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={template}
                            onChange={(event) => setTemplate(event.target.value)}
                        >
                            <MenuItem value={'new'}>{t("common:export.template-import-new-csv")}</MenuItem>
                            <MenuItem value={'edit'}>{t("common:export.template-import-edit-csv")}</MenuItem>
                        </Select>*/}

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>{ t('common:mails.destinataire') }</InputLabel>
                        </span>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            endAdornment={
                                <div style={{display : 'flex' , flexDirection : 'row'}}>
                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setMailNotification')}}>
                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                            search
                                        </span>
                                    </span>
                                    <span onClick={handleAddNotification} style={{cursor : "pointer" , color : 'black', marginRight : '5px'}}>
                                        <Add />
                                    </span>
                                    {/*                                    <InputAdornment position="end" onClick={handleAddNotification} style={{cursor : 'pointer'}}>
                                        <Add />
                                    </InputAdornment>*/}
                                </div>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setMail(event.target.value)}
                            value={mail}
                        />
                        {
                            mailNotification.map((mail, index) => {
                                return(
                                    <div key={index} onClick={(event) => handleDeleteNotification(mail,index)}>
                                        <div>
                                            {mail}
                                            <span id="icon-close" className="material-icons md-25 lightgrey">
                                                delete
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>{ t('common:mails.destinatairecc') }</InputLabel>
                        </span>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            endAdornment={
                                <div style={{display : 'flex' , flexDirection : 'row'}}>
                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setMailNotificationCc')}}>
                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                            search
                                        </span>
                                    </span>
                                    <span onClick={handleAddNotificationCc} style={{cursor : "pointer" , color : 'black', marginRight : '5px'}}>
                                        <Add />
                                    </span>
                                    {/*                                    <InputAdornment position="end" onClick={handleAddNotificationCc} style={{cursor : 'pointer'}}>
                                        <Add />
                                    </InputAdornment>*/}
                                </div>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setMailCc(event.target.value)}
                            value={mailCc}
                        />

                        {
                            mailNotificationCc.map((mail, index) => {
                                return(
                                    <div key={index} onClick={(event) => handleDeleteNotificationCc(mail,index)}>
                                        <div>
                                            {mail}
                                            <span id="icon-close" className="material-icons md-25 lightgrey">
                                                delete
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>{ t('common:mails.sujet') }</InputLabel>
                        </span>

                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setSujet(event.target.value)}
                            value={sujet}
                        />

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>{ t('common:mails.message') }</InputLabel>
                        </span>

                        <TextField
                            id="outlined-multiline-static"
                            //label="Commentaire d'annexe"
                            multiline
                            minRows={10}
                            value={body}
                            variant="outlined"
                            fullWidth={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            //className={classes.textField}
                            onChange={(event) => setBody(event.target.value)}
                        />

                    </div>
                    <button className="button" onClick={handlePressValidate}>Envoyer</button>
                </div>
                <ModalSearchInRepertoriesComponent open={openRepertory} multiple={true} onChange={handleChange} close={handleCloseSubModal}/>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainProfil : state.user.profil
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptExportTemplateCsv: (payload, shared, userhash) => dispatch(postExportTemplateCsvRequest(payload, shared, userhash)),
        attemptExportTableTemplateCsv: (payload, shared, userhash) => dispatch(postExportDataTemplateCsvRequest(payload, shared, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTemplateImportExportCsvComponent)
