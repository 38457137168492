import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    getCheckTokenRequest,
    getListModelsSharedInWaitingRequest,
    getProfilsRequest,
    postLogoutRequest
} from './actions/user';
import {history} from './hooks/history'
import {delFieldsSelected, getConnected, getCurrentEnvironnement, getToken, removeDemoHash} from './utils/Common';
import 'rsuite/dist/styles/rsuite-default.css';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'react-phone-number-input/style.css'

import {Container} from "rsuite";
import {connect} from "react-redux";
import MenuComponent from "./components/menu/MenuComponent";
import Routes from "./routes";
import {ToastContainer} from "react-toastify";
import {ConnectedRouter} from "connected-react-router";
import withClearCache from "./ClearCache";
import {useTranslation} from "react-i18next";
import Intercom from "./components/intercom/Intercom";
import ApplicationConfig from "./config/ApplicationConfig";
import Maintenance from "./containers/maintenance/Maintenance";
import {useIdleTimer} from 'react-idle-timer'
import * as moment from "moment";


import {LicenseInfo} from '@material-ui/x-grid';
import { useTitle } from './utils/utils';
import Cron from "./class/Cron";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'


const ClearCacheComponent = withClearCache(MainApp);

LicenseInfo.setLicenseKey(
    '8577b96a5067a40c01905d78d0de755fT1JERVI6MjUwMzUsRVhQSVJZPTE2NTMwNDc1MjkwMDAsS0VZVkVSU0lPTj0x',
);

if (!ApplicationConfig.devMode) {
    Bugsnag.start({
        apiKey: '10d6f0dbd6239d62be2d2fd64d377d4e',
        plugins: [new BugsnagPluginReact()]
    })
}


const isAuthenticated = () => {
    return getConnected() === 'true' && getToken() !== 'null' && getCurrentEnvironnement() !== 'null';
}

function App(props) {

    const [authLoading, setAuthLoading] = useState(true);
    const [logout, setLougout] = useState(false);

    const { t, i18n } = useTranslation(['common', 'login']);

    const handleOnIdle = event => {
        if(typeof props.user !== "undefined" && props.user !== null && getToken() !== null && getConnected() !== false ){
            setLougout(false);
        }else{
            setLougout(true);
        }
    }

    const handleOnActive = event => {
        setLougout(false);
    }

    const handleOnAction = (e) => {
        setLougout(false);
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: props.type === 'demo' ?  1000 * 60 * 2400 : 1000 * 60 * 30,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(() => {
        if(logout === true){
            props.attemptLogout(props.userhash)
        }
    },[logout])

    useEffect(() => {
        if (!props.user && !isAuthenticated()) {
            return;
        }

        if(typeof props.uid !== "undefined" && !!props.uid){
            props.attemptCheckToken(props.uid,props.token);
        }
        setAuthLoading(false);
    }, []);


    if (authLoading && getToken() !== null && getConnected() !== false && !!props.user) {
        return <div className="content">{t('global.loading')}</div>
    }

    const cron = new Cron(props.userhash, props.type, props.creation, props.username, props.uid);
    cron.init();

    return <ClearCacheComponent {...props}/>;
}

function MainApp(props) {
    //useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDhcQZNiwclLgi3Q0uiIrTHyjLXZwnbxCw&libraries=places');

    const [authLoading, setAuthLoading] = useState(true);
    const [maintenance, setMaintenance] = useState(ApplicationConfig.maintenance);
    const [title, setTitle] = useState("")
    const { t, } = useTranslation(['common', 'login']);


    useEffect(() => {
        if (!props.user && !isAuthenticated()) {
            return;
        }

        if(typeof props.uid !== "undefined" && !!props.uid){
            props.attemptCheckToken(props.uid,props.token);
        }
        setAuthLoading(false);
    }, []);

    useEffect(() => {
        let docTitle = t('global.plugnotes')
        if(props.username !== null && props.username !== undefined) docTitle += ' - ' + props.username
        setTitle(docTitle)
    }, [props.username])

    useTitle(title)

    if (authLoading && getToken() && getConnected() && !!props.user) {
        return <div className="content">{t('global.loading')}</div>
    }


    if(maintenance){
        return(
            <Maintenance/>
        )
    }

    return (
        <ConnectedRouter history={history}>

            <div className="App">
                <div className="container">
                <Container>
                    { getToken() && getConnected() &&
                        <MenuComponent/>
                    }
                    <Routes/>
                </Container>
                </div>
                <ToastContainer autoClose={3000} />
            </div>
        </ConnectedRouter>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        user : !!state.user ? state.user : null,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        userhash : !!state.user && !!state.user.user ? state.user.user.userhash : null,
        uid : !!state.user && !!state.user.main ? state.user.main.id : null,
        token : !!state.user ? state.user.token : null,
        username : !!state.user && !!state.user.user ?  state.user.user.username : null,
        creation : !!state.user && !!state.user.user  ? state.user.user.creationdate : null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptCheckToken: (userhash, token) => dispatch(getCheckTokenRequest(userhash, token)),
    attemptLogout: (username) => dispatch(postLogoutRequest(username)),
    attemptGetListModelsSharedInWaiting: (userhash) => dispatch(getListModelsSharedInWaitingRequest(userhash)),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
