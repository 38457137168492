import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getConnected, getCurrentEnvironnement, getToken} from "../utils/Common";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
    const isAuthenticated = () => {
        return getConnected() === 'true' && getToken() !== 'null' && getCurrentEnvironnement() !== 'null';
    }

  return (
      <Route
          {...rest}
          render={(props) => isAuthenticated()  ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} /> }
          //render={(props) => <Component {...props} />}
      />
  )
}


export default PrivateRoute
