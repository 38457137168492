import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import {Content} from "rsuite";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Colors from "../../assets/themes/Colors";
import colors from "../../assets/themes/Colors";
import Checkbox from "@material-ui/core/Checkbox";
import {confirmAlert} from "react-confirm-alert";
import { isDefined, urlify} from "../../utils/utils";
import {postSendMailV3Request} from "../../actions/utils";
import {getCurrentEnvironnement} from "../../utils/Common";
import {strReplaceContentField} from "../../utils/Fields";
import {Data9002PrintableGetURL} from "../printable/Data9002Printable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt, faFilePdf} from "@fortawesome/free-regular-svg-icons";
import Data2001Printable, { getData2001PrintableBlob } from "../printable/Data2001Printable";
import ApplicationConfig from "../../config/ApplicationConfig";
import {isNull} from "lodash";
import { ColorCheckbox, PlugTooltip } from "../custom/CustomComponents";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    '& .MuiDialog-paperScrollPaper': {
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'inherit !important',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
       flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mailButton:{
    /* border: '1px red solid', */
    display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
    header: {
        flex: "0 0 100%",
    },
    sous_header_content: {
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        gap: 5,
        "@media (max-width: 1024px)": {
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            gap: 15,
        },
    },
    sous_header_content_title: {
        flexGrow: 1,
        overflow: "hidden",
        display: "flex",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    sous_header_content_title_input: {
        color: "black",
        "@media (max-width: 1024px)": {
            textAlign: "center",
        },
    },
    sous_header_content_buttons: {
        display: "flex",
        gap: 5,
        "@media (max-width: 1024px)": {
            width: "100%",
            justifyContent: "space-between",
        },
    },
    sous_header_button: {
        width: "150px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    faIconSize: {
        fontSize: 20,
        color: "#51C3C1",
    },
    textFieldLocked: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    mSeparatorWrapper:{
        height:3,
        backgroundColor: Colors.plugnotesBlueApp,
    },
    images: {
        paddingTop: 10,
        paddingBottom: 10,
        zIndex: 10
    },
    imageContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight : 100,
    },
    attachmentTitle: {
        color: "black",
        fontWeight: "600",
    },
    attachmentBox: {
        margin: "0 0 40px 0",
        border: "dashed 2px #51C3C1",
        borderRadius: "5px",
        backgroundColor: "#c5e4e3",
        height: 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    attachmentsCtn: {
        display: "flex",
        flexDirection: "column",
        gap: 5
    },
    attItemsContainer: {
        overflow: "hidden",
    },
    attItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    attAnnexName: {
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        height: "30px",
        flex: "none",
        width : "100%",
        fontSize: "14px",
        cursor: "pointer",
    },
    attIconDlInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#D8D8D8",
    },
    attIconType: {
        margin: 10,
        width: "30px",
        height: "30px",
        cursor: "pointer",
    },
    multipleCheckBox: {
        color: colors.main,
        width: 15,
        height: 15,
    },
    switchBase: {
        "&.Mui-checked": {
            color: colors.main
        }
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    modalHeader: {
        // position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        // position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));

const SmailModal = (props) => {
    // console.log('SmailModal got props', props);
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    let content = "";
    if(props.options?.body){
        content = strReplaceContentField(props.options?.body, props.fields, props?.model, props?.plugnote, props?.user);
    }
    let userIndex = typeof props.plugnote !== "undefined" && props.plugnote !== null ?  props.plugnote.userindex : 0;
    let dataNumber = userIndex.toString().padStart(6, 0);
    let model = props.model;
    let modelClef = model.clef
    const fileName = 'PN-' + dataNumber + '-' + modelClef

    const [body, setBody] = useState(content);
    const [sujet, setSujet] = useState(props.options?.title || "");
    const [expediteur, setExpediteur] = useState(props.options?.from || "noreply@plugnotes.com");
    const [viewNotes, setViewNotes] = useState(props.options?.note ? props.options?.note === 'Y' : false);
    const [mail, setMail] = useState(props.value || "");
    const [mailCc, setMailCc] = useState(props.options?.cc || "");
    const [mailCci, setMailCci] = useState(props.options?.cci || "");
    const [attachments, setAttachments] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [extraAttachments, setExtraAttachments] = useState([]);
    const [hasRelated, setHasRelated] = useState(false);
    const [printRelated, setPrintRelated] = useState(false);

    const environment = getCurrentEnvironnement();

    const handlePressValidate = () => {
        if(mail.length > 0){
            let message = t('global.send');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let bodyReplace = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            let bodyUrlReplace = urlify(bodyReplace);
                            const searchRegExp = /(PN|PF|PW)-\d{6}-[A-Z,0-9]{5}/g;
                            if(bodyUrlReplace.search(searchRegExp)){
                                const searchedValues = bodyUrlReplace.match(searchRegExp);

                                if(!isNull(searchedValues)){
                                    if(searchedValues.length > 0){
                                        searchedValues.forEach((value, index) => {
                                            bodyUrlReplace = bodyUrlReplace.replace(value, '<a href="' + ApplicationConfig.templateUrl + '?code='+ value + '">' + value + '</a>');
                                        })
                                    }
                                }
                            }

                            let payload = {};
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            payload.body = bodyUrlReplace.length > 0 ? bodyUrlReplace : null;
                            payload.from = expediteur.length > 0 ? expediteur : null;
                            payload.cc = mailCc.length > 0 ? mailCc : null;
                            payload.cci = mailCci.length > 0 ? mailCci : null;
                            payload.to = mail.length > 0 ? mail : null;
                            payload.attachments = attachments.length > 0 ? attachments : null;
                            payload.attachment = attachment !== "" ? attachment : null;
                            payload.extraAttachments = printRelated && extraAttachments.length > 0 ? extraAttachments : null;
                            props.close()
                            props.attemptSendMailSimple(payload, environment?.userhash);
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        } else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    const handleTogglePlugnoteAttachment = (annexe) => {
        if (!attachments.includes(annexe.hash)) {
            setAttachments([...attachments, annexe.hash]);
        } else {
            let tmp = attachments.filter(item => item !== annexe.hash);
            setAttachments(tmp);
        }
    }

    const handleToggleAttachments = () => {
        // console.log('toggling attachment...', )
        setAttachment(previous => isDefined(previous) ? null : props.attachedPlugnote)
    }

    const handleToggleRelated = () => {
        setPrintRelated(previous => {
            // if (previous) { setExtraAttachments([]) }
            return !previous })
    }

    const handleAddRelatedPlugnote = (blob) => {
        if (blob) {
            let reader = new FileReader();
            blob.then(result => {
                try {
                    reader.readAsDataURL(result);
                    reader.onloadend = function () {
                        let base64data = reader.result;
                        let _attachments = [...extraAttachments, base64data]
                        setExtraAttachments(_attachments)
                    }
                } catch (e) {
                    console.error(`❌ onLoadBlob ${e}`);
                }
            })
        }
    }

    return (
        <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth="md">
            <div className={classes.modalHeader}>
                <div className="popup_wrapper_content justify_content_space">
                    <button
                        className="button-exit"
                        onClick={props.close}
                    >
                        {t("common:global.cancel")}
                    </button>
                    <button className="button float_right" onClick={handlePressValidate}>{t('common:global.send')}</button>
                </div>
            </div>
            <Content >
                <div className="popup_wrapper_content">
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinatairefrom')}</InputLabel>
                        <div className={classes.inputContainer}>
                            <Input
                                id={'from-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={expediteur}
                                disabled={true}
                            // onChange={(event) => setExpediteur(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinataire')}</InputLabel>

                        <div className={classes.inputContainer}>
                            <Input
                                id={'to-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={mail}
                                disabled={false}
                                onChange={(event) => setMail(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinatairecc')}</InputLabel>

                        <div className={classes.inputContainer}>
                            <Input
                                id={'cc-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={mailCc}
                                disabled={false}
                                onChange={(event) => setMailCc(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinatairecci')}</InputLabel>

                        <div className={classes.inputContainer}>
                            <Input
                                id={'cci-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                value={mailCci}
                                disabled={false}
                                onChange={(event) => setMailCci(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.sujet')}</InputLabel>
                        <div className={classes.inputContainer}>
                            <Input
                                id={'subject-input-' + props.field.id}
                                label=""
                                style={{ margin: 8 }}
                                //placeholder={props.placeholder}
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                onChange={(event) => setSujet(event.target.value)}
                                value={sujet}
                            />
                        </div>
                    </div>

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{ fontWeight: 'bold' }} shrink>{t('common:mails.message')}</InputLabel>
                        <span style={{ height: '25px', fontSize: 10, fontStyle: 'italic', fontWeight: 'bold', color: colors.red }}>
                            {body.includes('<sup>') && t('common:smail.info-tags')}
                        </span>
                    </div>

                    <Input
                        id="outlined-multiline-static"
                        multiline={true}
                        rows={10}
                        value={body}
                        margin="none"
                        disableUnderline={true}
                        fullWidth={true}
                        inputlabelprops={{ className: classes.input }}
                        className={classes.textFieldArea}
                        onChange={(event) => setBody(event.target.value)}
                    />
                </div>
                {(props.field.template !== '' || props.annexes.length > 0 || props.attachedPlugnote !== null) &&
                    <div className="popup_wrapper_content">
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{ fontWeight: 'bold' }} shrink>{t('common:mails.attachments')}</InputLabel>
                            <span style={{ height: '25px' }} />
                        </div>
                        {
                            (props.field.template !== '' || props.annexes.length > 0) &&
                            <div className={classes.attachmentsCtn}>
                                {
                                    props.annexes.length > 0 &&
                                    props.annexes.map((annexe, index) => {
                                        if ((annexe.type === 'note' && viewNotes) || annexe.type !== 'note') {
                                            return (
                                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }} key={'annexes-' + props.field.index + '-' + index}>
                                                    <div className={classes.attItemsContainer} onClick={() => handleTogglePlugnoteAttachment(annexe)}>
                                                        <div className={classes.attItem}>
                                                            <ColorCheckbox checked={attachments.includes(annexe.hash)} color={colors.main} size={24}/>
                                                            <div className={classes.attIconType}>
                                                                {
                                                                    annexe.type === 'note' && viewNotes &&
                                                                    props.renderAnnexeIconPreview(annexe)
                                                                }
                                                                {
                                                                    annexe.type !== 'note' &&
                                                                    props.renderAnnexeIconPreview(annexe)
                                                                }
                                                            </div>

                                                            <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                                {
                                                                    annexe.type === 'note' && viewNotes &&
                                                                    <span>{annexe.name}</span>
                                                                }

                                                                {
                                                                    annexe.type !== 'note' &&
                                                                    <span>{annexe.name}.{annexe.type}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else return null;
                                    })}
                                {
                                    props.field.options.template === '9002' && props.attachedPlugnote !== null &&
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }} key={'annexes-' + props.field.index + '-' + (props.annexes.length + 1)}>
                                        <Checkbox
                                            className={classes.multipleCheckBox + ' ' + classes.switchBase}
                                            name={t('common:smail.template-9002-content')}
                                            checked={attachment !== null}
                                            onChange={handleToggleAttachments}
                                        />
                                        <div className={classes.attItemsContainer} onClick={handleToggleAttachments}>
                                            <div className={classes.attItem}>
                                                <div className={classes.attIconType}>
                                                    <FontAwesomeIcon
                                                        icon={faFilePdf}
                                                        className={classes.faIconSize}
                                                    />
                                                </div>

                                                <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                    <span>{t('common:smail.template-9002-content')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    props.field.options.template === '2001' &&
                                    <div className={classes.attItemsContainer} onClick={handleToggleAttachments}>
                                        <div className={classes.attItem}>
                                            <Data2001Printable
                                                {...props}
                                                addRelatedPlugnote={handleAddRelatedPlugnote}
                                                checked={attachment?.includes(props.attachedPlugnote)}
                                                filename={fileName}
                                                isEmailAttachment
                                                setHasRelated={setHasRelated}
                                                setPdfBlob={props.setPdfBlob}
                                                t={t}
                                            />
                                            <div className={classes.attIconType}>
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                    className={classes.faIconSize}
                                                />
                                            </div>

                                            <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                <span>{t('common:smail.template-9002-content')}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    hasRelated &&
                                    <div className={classes.attItemsContainer} onClick={handleToggleRelated}>
                                        <div className={classes.attItem}>
                                            <ColorCheckbox
                                                checked={printRelated}
                                                size={24}
                                            />
                                            <div className={classes.attIconType}>
                                                <FontAwesomeIcon
                                                    icon={faFileAlt}
                                                    className={classes.faIconSize}
                                                />
                                            </div>
                                            <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                                                <span>{t('common:print.related-plugnote')}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }

                <div className={classes.modalFooter}>
                    <div className="popup_wrapper_content justify_content_space">
                        <button
                            className="button-exit"
                            onClick={props.close}
                        >
                            {t("common:global.cancel")}
                        </button>
                        <button className="button float_right" onClick={handlePressValidate}>{t('common:global.send')}</button>
                    </div>
                </div>
            </Content>
        </Dialog>
    )
}

const FormatSMailComponent = (props) => {
    // console.log('SMail got props', props)
    const classes = useStyles()
    let initialValue = isDefined(props.field.options?.setup?.defaultValue) ? props.field.options?.setup?.defaultValue : ''
    if (isDefined(props.field.value) && props.field.value !== '') { initialValue = props.field.value }

    const { t } = useTranslation('common')
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [email, setEmail] = useState(initialValue);
    const [open, setOpen] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [attachedPlugnote, setAttachedPlugnote] = useState(null);
    const template = props.field?.options?.template || ''

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled)
        props.handleEditValue(initialValue, props.field, props.index)
    },[ props.disabled]);

    useEffect(() => {
        setEmail(!!props.field.value ? props.field.value : initialValue);
    },[props.field.value])

    useEffect(() => {
        // console.log('pdfBlob', pdfBlob, typeof pdfBlob);
      if (isDefined(pdfBlob)) {
        pdfBlob.then(result => onLoadBlob(result))
      } else { setAttachedPlugnote(null) }
    }, [pdfBlob]);

    const handleEditValue = (value, field, index) => {
        setEmail(value);
        props.handleEditValue(value, field, index)
    }

    const handleOpenModalSmail = async () => {
        if(template !== "" && template === '9002'){
            generateEmailAttachmentsFromTemplate9002()
                .then((result) => {
                    // console.log('generate attachedPlugnoteFromTemplate9002 result', result);
                    onLoadBlob(result)
                });
        }
        else if(template !== "" && template === '2001'){
            await generateAttachedPlugnoteFromTemplate2001()
        }
        else{
            setAttachedPlugnote(null);
            setOpen(true);
        }
    }

    const onLoadBlob = (result) => {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(result);
            reader.onloadend = function () {
                let base64data = reader.result;
                setAttachedPlugnote(base64data);
                setOpen(true);
            }
        } catch (e) {
            console.error(`❌ onLoadBlob ${e}`);
        }
    }

    const generateEmailAttachmentsFromTemplate9002 = async () => {
        return Data9002PrintableGetURL(props);
    }

    const generateAttachedPlugnoteFromTemplate2001 = async () => {
        setOpen(true)
    }

    const handleCloseModalSmail = () => {
        setOpen(false);
    }

    return (
        <div className={classes.mailContainer}>
            <TextField
                onChange={(event) => handleEditValue(event.target.value, props.field, props.index)}
                id={'smail-input-' + props.field.id}
                type="text"
                helperText=""
                InputLabelProps={{
                    shrink: true,
                }}
                className={`${classes.fieldRight} testeBorder`}
                value={email}
                disabled={disabled}
                InputProps={{ disableUnderline: true }}

            />
            {
                isDefined(email) && email !== '' && !props.mode.includes('plugcode') &&
                <PlugTooltip title={t('common:annexe.send-email')} placement="top">
                    <div className={classes.mailButton} onClick={handleOpenModalSmail}>
                        <span className="material-icons md-small white">forward_to_inbox</span>
                    </div>
                </PlugTooltip>
            }

            {
                open &&
                <SmailModal {...props}
                    close={handleCloseModalSmail}
                    attachedPlugnote={attachedPlugnote}
                    open={open}
                    options={props.field.options}
                    setPdfBlob={setPdfBlob}
                    value={email}
                />
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        profil : state?.user?.profil || null,
        user2 : state?.user || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptSendMailSimple: (payload, userhash) => dispatch(postSendMailV3Request(payload,userhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailComponent);





