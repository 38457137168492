import {delFieldsSelected, getConnected, getToken, removeDemoHash} from "../utils/Common";
import ApplicationConfig from "../config/ApplicationConfig";
import {getCheckTokenRequest, getListModelsSharedInWaitingRequest, postLogoutRequest} from "../actions/user";
import {store} from "../store"
import * as moment from "moment/moment";

const prod = ApplicationConfig.production;
const timeoutTokenControl = prod ? 5 * 60 * 1000 : 1 * 60 * 1000;
const timeoutControl = prod ? 30 * 60 * 1000 : 10 * 60 * 1000;

class Cron {
    constructor(userhash,type,creation,username,uid) {
        this.userhash = userhash;
        this.type = type;
        this.creation = creation;
        this.username = username;
        this.uid = uid;
    }

    init() {
        if(getToken() && getConnected()){
            this.useBackgroundService(this.userhash);
            this.useBackgroundTokenService(this.uid);
            this.useBackgroundCleanSelectedFieldsService();
            this.useBackgroundControlAccessDemoService(this.userhash, this.type, this.creation, this.username);
        }
    }

    callAPI() {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), 1000);
        });
    }

    useBackgroundService(userhash) { /* todo notification */
        const bgService = setInterval(() => {
            this.callAPI().then(() => {
                delFieldsSelected();
                if(typeof userhash !== "undefined" && !!userhash){
                    store.dispatch(getListModelsSharedInWaitingRequest(userhash));
                }
            });
        }, timeoutControl);

        return () => clearInterval(bgService);
    }

    useBackgroundTokenService(uid) { /* todo notification */
        const bgService = setInterval(() => {
            this.callAPI().then(() => {
                delFieldsSelected();
                if(typeof uid !== "undefined" && !!uid){
                    store.dispatch(getCheckTokenRequest(uid, getToken()));
                }
            });
        }, timeoutTokenControl);

        return () => clearInterval(bgService);
    }

    useBackgroundCleanSelectedFieldsService() { /* todo notification */
        const bgService = setInterval(() => {
            this.callAPI().then(() => {
                delFieldsSelected();
            });
        }, timeoutControl);

        return () => clearInterval(bgService);
    }

    useBackgroundControlAccessDemoService(userhash, type, creation, username){

        const bgService = setInterval(() => {
            this.callAPI().then(() => {
                if( !!userhash){
                    if(type === 'demo'){
                        setInterval(() => {
                            let a = moment(creation);//now
                            let b = moment(a).add(8, 'hours');
                            let c = moment();
                            let duration = moment.duration(b.diff(c));
                            let hours = duration.hours(); //hours instead of asHours
                            let minutes = duration.minutes(); //minutes instead of asMinutes

                            if(hours <= 0 && minutes <= 0 && !!userhash){
                                removeDemoHash();
                                store.dispatch(postLogoutRequest(username));
                            }

                        }, 1000)
                    }
                }
            });
        }, timeoutControl);

        return () => clearInterval(bgService);
    }
}

export default Cron;

