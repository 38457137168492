import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {getTemplateFormatText} from "../../utils/Compatibility";
import {useTranslation} from "react-i18next";
import {getModel} from "../../utils/Common";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    display: {
        "& .MuiSelect-select": {
            display : 'flex'
        }
    }
}));


const FilterViewSelectComponent = (props) => {
    const { t, i18n } = useTranslation(['common', 'models', 'global', 'plugnote']);

    const classes = useStyles();

    const [fields, setFields] = React.useState(props.fields);

    const [selected, setSelected] = useState( props.selectedFields.length > 0 ? props.selectedFields : []);
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [shared, setShared] = React.useState(typeof props.shared === 'undefined' ? false: props.shared);

    const [specialsList, setSpecialsList] = useState( [
        { item : 0, title : t('plugnote.plug-search-pn-number-type-title') },
        { item : 16, title : t('plugnote.plug-search-plugcode-type-title') },
        { item : 17, title : t('plugnote.plug-search-mail-tiers-type-title') },
        { item : 18, title : t('plugnote.plug-search-date-creation-pn-option-label-title') },
        { item : 19, title : t('plugnote.plug-search-date-modification-pn-option-label-title') },
        { item : 20, title : t('plugnote.plug-search-count-annexe-type-title') },
    ]);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setSelected(props.selectedFields.length > 0 ? props.selectedFields : []);
    },[ props.selectedFields]);

    return (
        <div className="m2 filtre_champs-liste">
            <div className="left_info">
                <ul className="cliquables">
                    <li onClick={(event) => props.handleAddAllFieldsInFilter()}>{t('common:pluglist.filter-view-all-fields')}</li>
                    <li onClick={(event) => props.handleRemoveAllSelectedFieldsInFilter()}>{t('common:pluglist.filter-hide-all-fields')}</li>
                </ul>
            </div>
            <div className="flexSpacebetween filtre_champs-liste-content">
                <ul className={`content_ul_filter_`+ (Math.ceil(fields.length / 4))}>
                    {
                        fields.map((field, index) => {
                            let template = getTemplateFormatText(getModel()['field'+(index + 1)+'format'],getModel()['field'+(index + 1)+'formatoptions']);
                            let visible = getModel()['field'+(index + 1)+'visible'];
                            let span =  ' ( ' + (index + 1) + ' - ' + template +' )';

                            if(!selected.includes(index + 1)){
                                return (
                                    <div key={'filter-field-' + (index + 1)}>
                                        <input
                                            disabled={disabled}
                                            type="checkbox"
                                            id={field + '-' + (index + 1)}
                                            name={field}
                                            checked={selected.includes(index + 1)}
                                            onChange={(event) => {
                                                if(!selected.includes(index + 1)){
                                                    setSelected([...selected, index + 1]);
                                                }else{
                                                    let tmp = selected.filter(item => item !== (index + 1));
                                                    setSelected(tmp);
                                                }
                                                props.handleChangeSelectedFieldsInFilter(index+1);
                                            }}
                                        /><label className={classes.margin_left_5} htmlFor={field + '-' + (index + 1)}>{field}</label>
                                        <span className="md-10">{ span } </span>
                                    </div>

                                    // <li key={index}><input className="checkbox" type="checkbox"/> {field}</li>
                                )
                            }


                        })
                    }
                </ul>

                <ul className={`content_ul_filter_`+ (Math.ceil(specialsList.length / 4))}>
                    {
                        specialsList.map((element, index) => {

                            if(!selected.includes(element.item)){
                                return (
                                    <div key={'special-field-' + (index)}>
                                        <input
                                            disabled={disabled}
                                            type="checkbox"
                                            id={element.title + '-' + (element.item)}
                                            name={element.title}
                                            checked={selected.includes(element.item)}
                                            onChange={(event) => {
                                                if(!selected.includes(index + 1)){
                                                    setSelected([...selected, index + 1]);
                                                }else{
                                                    let tmp = selected.filter(item => item !== (index + 1));
                                                    setSelected(tmp);
                                                }
                                                props.handleChangeSelectedFieldsInFilter(element.item);
                                            }}
                                        /><label className={classes.margin_left_5} htmlFor={element.item + '-' + (index + 1)}>{element.title}</label>
                                    </div>
                                )
                            }


                        })
                    }
                </ul>

            </div>
            <div className="filtre_champs-liste_close"
                 onClick={(event) => props.setFilterFieldsOpen(false)} >
                <span id="icon-close" className="material-icons md-30 lightgrey"
                      onClick={props.close}>
                    close
                </span>
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterViewSelectComponent);





