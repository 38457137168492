import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {KeyboardTimePicker} from "@material-ui/pickers";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
   timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        marginRight: '3px'
    },
    timeNowButton: {
        height:'21px',
        width: '21px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
        fontWeight: '900',
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));


const FormatTimeComponent = (props) => {
    //"2018-01-01T11:12:00.000Z"
    const classes = useStyles();

    // console.log('props',props);
    let currentValue = null;
    if(props.field.value !== null && props.field.value.length > 0) {
        // console.log(props.field.value);
        let split = props.field.value.split(':');
        // console.log(split);
        currentValue = new Date(null, null, null, split[0], split[1]);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [visual, setVisual] = useState(props.field.value !== null && props.field.value.length > 0? props.field.visuelValue : '')


    function addZeroBefore(n) {
        return (n < 10 ? '0' : '') + n;
    }

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(()=> {
    if (selectedDate !== null && !disabled){
        // console.log("time", selectedDate);
        let date = new Date(selectedDate)
        // console.log(selectedDate);
        let hours = addZeroBefore(selectedDate.getHours());
        let minutes = addZeroBefore(selectedDate.getMinutes());
        let visualDate = hours + ":" + minutes
        props.handleEditDateTime(selectedDate, visualDate, props.field, props.index)
    }
    else if(!disabled && selectedDate === null) {
            props.handleEditDateTime(selectedDate, '', props.field, props.index)
        }
    }, [selectedDate])

    return (
    <div className={classes.timeContainer}>
        <KeyboardTimePicker
            InputAdornmentProps={{ position: 'start' }}
            disabled={disabled}
            autoComplete="off"
            fullWidth={true}
            className={`${classes.datePickerIcon  + ` ` + classes.fullWidth}`}
            format="HH:mm"
            keyboardIcon={<Icon>access_time</Icon>}
            id="time-picker"
            ampm={false}
            KeyboardButtonProps={{
                'aria-label': 'change time',
            }}
            placeholder="HH:mm"
            value={selectedDate}
            onChange={(date, value) => {
                setVisual(value);
                handleDateChange(date);
                // console.log('date, value:', date, value);
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
            helperText={props.field?.value === 'NaN:NaN' && visual?.length === 5 && !visual?.includes('_') ? 'Invalid Time Format' : ''}
        />
        {!disabled &&
            <Tooltip title={(selectedDate === "" || selectedDate !== null) ? "Delete" : "Present time"} placement="top" className={classes.endButton} >
                {(selectedDate === "" || selectedDate !== null) ?
                <span className={`${classes.timeDelButton} material-icons`} onClick={() => handleDateChange(null)}>highlight_off</span>
            :
                <span className={`${classes.timeNowButton} material-icons`} onClick={() => {if (selectedDate === null) {handleDateChange(new Date()); props.handleEditDateTime(new Date(), selectedDate, props.field, props.index)}}}>check</span>}
            </Tooltip>

        }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTimeComponent);





