import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as moment from "moment";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import {
    delAnnexes,
    deleteHistoryPlugAction,
    delModel,
    delPlugnote,
    delSelection,
    delSpecifiFieldsSelected,
    delSpecifiFieldsSize,
    delTopRow, delTypeOfPlugnote,
    getCurrentEnvironnement,
    getModel,
    getPlugnote,
    getSelection,
    getToken,
    getTopRow, getTypeOfPlugnote,
    getUser,
    setAnnexes,
    setFieldsSelected, setFieldsSize,
    setModel,
    setPlugnote,
    setPlugnoteView,
    setSelection,
    setTableView,
    setTopRow, setTypeOfPlugnote,
} from '../../utils/Common';
import { makeStyles, } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {Content, Header} from "rsuite";
import {connect} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExcel,} from "@fortawesome/free-regular-svg-icons";
import {faFileCsv, faPaperclip, faTable} from "@fortawesome/free-solid-svg-icons";
import {
    delDeleteDataRequest,
    delDeleteDatasRequest,
    getDatasFilterLightRequest,
    getDatasFilterRequest,
    getDatasLightRequest,
    getDatasPaginateLightRequest,
    getDatasPaginateRequest,
    getDatasRequest,
    getDecryptPasswordRequest,
    putUpdateDataArchiveRequest,
    putUpdateDataFavorisRequest,
    putUpdateDataFieldManualRequest,
    putUpdateDataFieldRequest,
    putUpdateDataLockRequest,
    putUpdateDatasArchiveRequest,
    putUpdateDatasUnArchiveRequest,
    reduxDelAllDataFilterRequest,
    reduxDelDataFilterRequest,
    reduxDelGlobalApiDataFilterRequest,
    reduxDelGlobalDataFilterRequest,
    reduxGenerateDataPasswordReset,
    reduxSetDataFilterRequest
} from "../../actions/data";
import {usePromiseTracker} from "react-promise-tracker";
import ModalModelPlugcodeComponent from "../../components/modals/ModalModelPlugcodeComponent";
import ModalModelShareComponent from "../../components/modals/ModalModelShareComponent";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";
import {
    postModelGenerateMultiplePlugcodeRequest,
    reduxGeneratePlugformcodeReset,
    reduxGeneratePlugnotecodeReset
} from "../../actions/plugcode";
import {reduxGenerateShareReset} from "../../actions/share";
import {delModelUniqueRequest, getModelUniqueRequest, postExportFullFileDatasRequest} from "../../actions/model";
import ModalSimpleExportCsvComponent from "../../components/modals/ModalSimpleExportCsvComponent";
import ModalSimpleExportTableMultipleCsvComponent from "../../components/modals/ModalSimpleExportTableMultipleCsvComponent";
import ModalTemplateImportExportCsvComponent from "../../components/modals/ModalTemplateImportExportCsvComponent";
import ModalImportExportCsvComponent from "../../components/modals/ModalImportExportCsvComponent";
import ModalPlugnotePlugcodeComponent from "../../components/modals/ModalPlugnotePlugcodeComponent";
import ModalCopyOrMovePlugnoteComponent from "../../components/modals/ModalCopyOrMovePlugnoteComponent";
import FilterSelectComponent from "../../components/filters/FilterSelectComponent";
import {
    isDefined,
    isJsonString,
    isJsonStringObject,
    isJsonStringObjectAndCount,
    isJsonStringObjectAndCountSubElement,
    isJsonStringObjectAndGetElement,
    validateEmail,
    validateNumber,
    validateText,
    validateUrl
} from "../../utils/utils";
import {reduxGenerateAnnexeReset} from "../../actions/annexe";
import { SelectCellFormatter,  } from 'react-data-grid';
import Button from "@material-ui/core/Button";
import {getTemplateFormatText} from "../../utils/Compatibility";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import HeaderSearch from "../../components/searchs/HeaderSearch";
import Tooltip from "@material-ui/core/Tooltip";
import Images from "../../assets/themes/Images";
import {camelCase, isNull,} from "lodash";
import {
    GridOverlay,
    useGridApiRef,
    GRID_COLUMN_HEADER_DRAG_END,
    GRID_COLUMN_HEADER_DRAG_START,
    GridFilterPanel,
    GridToolbarContainer,
} from "@material-ui/x-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GridPanelContent } from "@material-ui/data-grid";
import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker} from "@material-ui/pickers";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import {history} from "../../hooks/history";
import SubModelMenuLeftPortalComponent from "../../components/specials/portal/SubModelMenuLeftPortalComponent";
import MenuLeftPlugSearchFromApiComponent from "../../components/searchs/MenuLeftPlugSearchFromApiComponent";
import FilterSelectionSaveComponent from "../../components/filters/FilterSelectionSaveComponent";
import FilterSelectionLoadComponent from "../../components/filters/FilterSelectionLoadComponent";
import FilterSelectionList from '../../components/filters/FilterSelectionList';
import colors from '../../assets/themes/Colors';
import { deleteFilterRequest, postSendListFilterRequest } from '../../actions/filter';
import { parseFilterParameters, parseModel } from '../../utils/Fields';
import InputMask from 'react-input-mask';
import { Date } from 'core-js';
import { DesignedGrid, PlugTooltip, } from '../../components/custom/CustomComponents';
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import ModalTotalListComponent from '../../components/modals/pluglist/ModalTotalListComponent';
import ModalHelpComponent from "../../components/modals/ModalHelpComponent";

const drawerWidth = 400;
const subDrawerWidth = 320;

//const specialFields = ['plugform','textarea', 'list', 'single-select', 'free-single-select', 'multi-select', 'free-multi-select','single-table', 'free-single-table', 'multi-table', 'free-multi-table', 'qrcode', 'localis'];
const specialFields = ['textarea', 'text', 'numeric', 'phone', 'mail', 'url', 'date', 'boolean', 'select', 'agenda', 'localis', 'glocalis', 'password', 'time', 'list', 'birthday', 'qrcode', 'extdata','intdatamaj'];
const editableDirectFields = ['text', 'numeric', 'phone', 'mail', 'url', 'date', 'boolean', 'select'];

const gridStyles = makeStyles((theme) => ({
    root: {
        '& .super-app-theme--cell': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.negative': {
            color: 'red !important',
            fontWeight: '600',
        },
        '& .super-app.deleted': {
            backgroundColor: '#868686',
            color: 'white',
            fontWeight: '600',
            textDecoration: 'line-through'
        },
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1})`,
            color: '#750f0f',
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    buttonSimple:{
        backgroundColor: '#58C3C1',
        color: 'white',
        "&:hover":{
            backgroundColor: '#58C3C1'
        },
        "&:disabled":{
            backgroundColor: '#58C3C1'
        }
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    fieldImageSvg : {
        width: '20px',
        marginLeft : '10px'
    },
    grid:{
        minHeight : '40vh',
        height : '80vh',
        maxHeight : '80vh',
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1})`,
            color: '#750f0f',
        },
        '@media (max-width: 1024px)': {
            height: 'calc(100vh - 200px)',
        }
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    subDrawer: {
        width: subDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    iconSearchActive:{
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    iconSearch:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '70px'
    },
    subDrawerOpen: {
        width: subDrawerWidth,
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        marginLeft : '70px'
    },
    subDrawerClose: {
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        overflowX: 'hidden',
        width: '0px'
    },
    formControl: {
        margin: theme.spacing(1),
        width : '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        // position: 'absolute',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'10px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
        padding: '0px 0px',
    },
    contentSub:{
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 0px',
        marginLeft : '-70px'
    },
    contentModelGrid : {
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        padding: '0px 20px',
        //columnGap: '20px',
        maxHeight : 'calc(85vh - 80px)',
    },
    contentModelList : {
        position: 'relative',
        marginTop : '0px',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        padding: '0px 20px',
        height : '-webkit-fill-available',
    },
    contentModelListOpenFilter : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        marginTop : '150px',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
    },
    openCloseRelative: {
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    pointer:{
        cursor: 'pointer'
    },
    listHeight:{
        height: '100%'
    },
    phone: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
    },
    editSelect: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
        width: '100%'
    },
    actionBtn: {
        position: 'relative',
        height: 41,
        padding: "4px 20px",
        margin: '3px 5px',
        borderRadius: "3px",
        cursor: "pointer",
        color: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // fontWeight: 'bold',
        backgroundColor: colors.mainButton
    },
    filterIndicator: {
        position: 'absolute',
        borderRadius: 50,
        top: -5,
        left: -5,
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        fontSize: 15,
        justifyContent: 'center',
        backgroundColor: colors.unselectedMain
    },
    selectionName: {
        marginLeft: 5,
        // fontWeight: 'bold'
    },
    endBtn: {
        color: colors.white,
        backgroundColor: colors.mainButton,
        margin: 15,
        padding: 5,
        borderRadius: "3px",
        cursor: "pointer",
        verticalAlign: '-webkit-baseline-middle'
    },
    newSelectionContainer: {
        margin: '5px 10px',
        '@media (min-width: 640px)': {
            width: '50%'
        }
    },
    selectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderRadius: 4,
        borderColor: colors.grey,
        cursor: 'pointer',
        position: 'relative'
    },
    filterPanelActionContainer: {
        display: 'flex',
        flexDirection: 'space-between'
    },
    filterRowsContainer: {
        '& div.MuiGridFilterForm-root': {  // & div pour appliquer à son enfant
            display: 'flex',
            padding: '8px',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
        },
        '& div label + .MuiInput-formControl': {
            width: '100%',
        },
        '& div.MuiGridFilterForm-closeIcon': {
            justifyContent: 'center',
            display: 'flex !important',
        },
        '& div.MuiGridFilterForm-operatorSelect': {
            textAlignLast: 'center'
        },
        '& div.MuiFormControl-root': {
            display: 'flex'
        }
    },
    selectionTextField: {
        backgroundColor: colors.mainButton,
        marginLeft: 5,
        height: 32,
        display: 'flex',
        flexDirection: 'row',
    },
    selectionDetails: {
        color: colors.grey,
        fontSize: 13
    },
    underline: {
        color: 'white' ,
        '&::after': {
            borderBottom: '1.5px solid white !important'
        },
        '&::before': {
            borderBottom: '1.5px solid white !important'
        },
        '&::hover': {
            borderBottom: '1.5px solid white !important'
        },
        '&::hover:not': {
            borderBottom: '1.5px solid white !important'
        }
    },
    deleteBtn: {
        color: colors.white,
        backgroundColor: colors.mainButton,
        borderRadius: "3px",
        cursor: "pointer",
        marginLeft: 5,
    },
    filterBtn: {
        height: 41,
        padding: "4px 0px",
        margin: '3px 10px',
        borderRadius: "3px",
        gap: 5,
        color: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: colors.mainButton
    },
    btnContainer: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        zIndex: 50
    },
    toolbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    selectedActionsContainer: {
        position: 'absolute',
        top: -10,
        left: 10,
        backgroundColor: colors.whiteAlpha,
        borderRadius: 4,
        padding: '3px 10px',
        zIndex: 3,
    },
    plugSearchContainer: {
        padding: '0px 8px',
        position: 'absolute',
        overflow: 'visible',
        height: 55,
        top: 50,
        left: 70,
        zIndex: 1250,
        backgroundColor: colors.white,
        borderRadius: "5px",
        boxSizing: "border-box",
        boxShadow: "24px 24px 38px rgb(0 0 0 / 14%), -10px 0px 5px 0px rgb(0 0 0 / 20%)",
    },
    tooltipStyle: {
        // borderColor: colors.main,
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '18px',
        borderRadius: 5
    },
    hiddenFieldsContainer: {
        display: 'flex',
        gap: 5
    },
    hiddenFieldItemContainer: {
        display: 'flex',
        border: '1px solid',
        borderColor: colors.main,
        borderRadius: 5,
        padding: '2px 5px',
        cursor: 'pointer',
        minWidth: 40,
    },
    //Cell
    cellContent: {
        width : "100%",
        textAlign : "left",
        cursor: "pointer"
    },
    fieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'middle',
        width: 16,
        height: 16,
    },
    fieldTotal: {
        color: colors.bloodOrange,
        padding: '0px 5px',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 'bold'
    },
    headerTotalCtn: {
        position: 'absolute',
        display: 'flex',
        bottom: 5,
        right: 5,
        borderColor: colors.bloodOrange,
        borderRadius: 4,
    },
    headerTotalValue: {
        fontSize: 12,
        color: colors.bloodOrange,
        fontWeight: 'bolder',
        border: 'none',
        width: '100%'
    },
    //row
    recentRow: {
        '& .MuiDataGrid-row': {
            backgroundColor : `${colors.selectedRow} !important`
        }
    },
    gridRoot: {
        '& .super-app-theme--cell': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.negative': {
            color: 'red !important',
            fontWeight: '600',
        },
        '& .super-app.deleted': {
            backgroundColor: '#868686',
            color: 'white',
            fontWeight: '600',
            textDecoration: 'line-through'
        },
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1})`,
            color: '#750f0f',
        },
    },
    //footer
    footerCtn: {
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
    },
    totalsCtn: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    totalCtn: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    totalLabel: {
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 16,
        fontWeight: 'bold',
        marginLeft: 8,
    },
    totalValue: {
        color: colors.bloodOrange,
        padding: '0px 5px',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 16,
        fontWeight: 'bold'
    },
    totalCb: {
        margin: 0,
        width: '20px',
        height: '20px',
        marginRight : '5px',
        color: colors.bloodOrange,
        alignSelf: 'flex-start',
    },
    plugcodeCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const defaultColumnProperties = {
    resizable: true,
    frozen: true,
    sortable : true,
    width: 120
};

const _getCodePnPpCodeTemplate = (plug) => {
    const {hash, idOffline, userindex, creationdate, codepncode, publicpncode, modname,modclef} = plug
    //file-download

    let codePn= 'PN';

    switch (publicpncode) {
        case 0:
            codePn = 'PP';
            break;
        case 1:
            codePn = 'PN';
            break;
        case 2:
            codePn = 'PE';
            break;
        case 3:
            codePn = 'PS';
            break;
        default:
            break;
    }

    let pncode = plug.codepncode ? `${codePn}-${moment(plug.creationdate).format('YYMMDD')}-${plug.codepncode}` : null

     if (pncode !== null) {
        pncode = pncode
    }
    else {
        pncode = "";
    }

    return pncode
};

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

function renderPhoneEditInputCell (params) {
    return <PhoneEditInputCell {...params} />;
}

function renderDateEditInputCell (params) {
    return <DateEditInputCell {...params} />;
}

function renderSelectEditInputCell(params) {
    return <SelectEditInputCell {...params} />
}

function PhoneEditInputCell (props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = React.useCallback(
        (value) => {
            const editProps = {
                value: value,
                format : "phone"
            };
            api.setEditCellProps({id, field,  props: editProps });
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    return (
        <div className={classes.phone}>
            <PhoneInput
                defaultCountry="BE"
                value={typeof value !== 'undefined' ? value.toString() : ''}
                placeholder={t('fields.format-choice-placeholder-phone')}
                onChange={(event) =>  handleChange(event)}
                //disabled={disabled}
            />
        </div>
    );
}

function DateEditInputCell (props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = React.useCallback(
        (value) => {
            const editProps = {
                value: value,
            };
            api.setEditCellProps({id, field,  props: editProps });
        },
        [api, field, id],
    );

    return (
        <div className={classes.phone}>
            <KeyboardDatePicker
                className={classes.fullWidth}
                variant="inline"
                format="yyyy-MM-dd"
                fullWidth={true}
                id={'date-picker-inline-' + id + field}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                value={typeof value !== 'undefined' ? value.toString() : ''}
                onChange={(date, value) =>  handleChange(value)}
            />
        </div>
    );
}

function SelectEditInputCell(props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = React.useCallback(
        (event) => {
            const editProps = {
                value: event.target.value,
                type : 'single-select',
                format : 'select',
                arrayValue : [event.target.value],
                arrayStringValue : JSON.stringify([event.target.value])
            };
            api.setEditCellProps({id, field,  props: editProps });
            event.stopPropagation();
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    const handleChangeMultiple = React.useCallback(
        (event) => {
            const editProps = {
                value: event.target.value,
                type : 'multiple-select',
                format : 'select',
                arrayValue : event.target.value,
                arrayStringValue : JSON.stringify(event.target.value)
            };
            api.setEditCellProps({id, field,  props: editProps });
            event.stopPropagation();
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    let valueSimple = "";
    let valueMultiple = [];

    let options = null

    if (typeof props.colDef.options !== "undefined" && isJsonStringObject(props.colDef.options)) {
        options = JSON.parse(props.colDef.options)
    }
    else if (typeof props.colDef.options !== "undefined" && !isJsonStringObject(props.colDef.options)) {
        options = props.colDef.options

    }

    let multiselect = false;
    let presel = [];

    if (options !== null) {
        multiselect = options.multiselect;
        presel = Array.isArray(options.presel) &&  options.presel.length  === 0 ?  [] : options.presel.split(";")
    }

    if (typeof props.value !== "undefined") {
        if (typeof props.row.plugnote[field] !== "undefined") {
            if (options !== null && !options.multiselect && isJsonString(props.row.plugnote[field])) {
                valueSimple = JSON.parse(props.row.plugnote[field])[0];
                if (options.editable) {
                    if (!presel.includes(valueSimple)) {
                        presel.push(valueSimple);
                    }
                }
            }
            else if (options !== null && !options.multiselect && props.row.plugnote[field].length > 0) {
                valueSimple = props.row.plugnote[field];
                if (options.editable) {
                    if (!presel.includes(valueSimple)) {
                        presel.push(valueSimple);
                    }
                }
            }
            else if (options !== null && options.multiselect && isJsonString(props.row.plugnote[field])) {
                valueMultiple = JSON.parse(props.row.plugnote[field]);
                if (options.editable) {
                    valueMultiple.map((item, index) => {
                        if (!presel.includes(item)) {
                            presel.push(item);
                        }
                    })
                }
            }
        }
    }


    const [selected, setSelected] = useState(valueSimple);
    const [selectedMultiple, setSelectedMultiple] = useState(valueMultiple);


    if (options !== null && typeof props.row.plugnote[field] !== "undefined") {
        if (options !== null && !options.multiselect && isJsonString(props.row.plugnote[field])) {
            valueSimple = JSON.parse(props.row.plugnote[field])[0];
            if (options.editable) {
                if (!presel.includes(valueSimple)) {
                    presel.push(valueSimple);
                }
            }
        }
        else if (options !== null && !options.multiselect && props.row.plugnote[field].length > 0) {
            valueSimple = props.row.plugnote[field];
            if (options.editable) {
                if (!presel.includes(valueSimple)) {
                    presel.push(valueSimple);
                }
            }
        }
        else if (options !== null && options.multiselect && isJsonString(props.row.plugnote[field])) {
            valueMultiple = JSON.parse(props.row.plugnote[field]);
            if (options.editable) {
                valueMultiple.map((item, index) => {
                    if (!presel.includes(item)) {
                        presel.push(item);
                    }
                })
            }
        }

        if (multiselect) {
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>
                        <Select
                            multiple={true}
                            value={selectedMultiple || []}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelectedMultiple(event.target.value);
                                handleChangeMultiple(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
        else {
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                    <Select
                        multiple={false}
                        value={selected || ""}
                        fullWidth={true}
                        id={id}
                        onChange={(event) => {
                            setSelected(event.target.value);
                            handleChange(event);
                        }}
                    >
                        {
                            presel.map((value, index) => {
                                return  <MenuItem key={index} value={value}>{value}</MenuItem>
                            })
                        }
                    </Select>
                    </FormControl>
                </div>
            );
        }
    }
    else if (options !== null && typeof props.row.plugnote[field] === "undefined") {

        if (multiselect) {

            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>
                        <Select
                            multiple={true}
                            value={selectedMultiple || []}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelectedMultiple(event.target.value);
                                handleChangeMultiple(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
        else {
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                        <Select
                            multiple={false}
                            value={selected || ""}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelected(event.target.value);
                                handleChange(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
    else return null;

}

function SimpleMemoDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');

    const handleClose = () => {
        onClose(infos, content, infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    minRows={10}
                    rowsMax={512}
                    inputProps={{ maxLength: 512 }}
                    defaultValue={content}
                    variant="outlined"
                    fullWidth={true}
                    onChange={(event) => setContent(event.target.value)}
                />
                <span style={{float : "right", fontSize : '10px'}}>{content.length +  ' / ' + '512'}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleAgendaDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [selectedDate, handleDateChange] = useState(value !== null && value.length > 0 ? value : null);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    fullWidth={true}
                    id={'date-picker-inline'}

                    //label={props.field.title}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    format="yyyy-MM-dd HH:mm"
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleLocalisDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [address, setAddress] = useState(value.length > 0 ? value : "");

    const [fullAddress, setFullAddress] = useState("http://maps.google.com/maps?daddr=" + address);

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                setAddress(results[0].formatted_address);
            })
            .catch(error => console.error('Error', error));
    };

    const  handleChange = address => {
        setAddress(address);
    };

    const handleClose = () => {
        onClose(infos, address,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, address,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div style={{width: '100%' , marginRight: '5px', display: 'flex'}}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        key={props.index}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{width : '100%'}}>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                key={index}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    { typeof address !== 'undefined' &&  address !== '' &&
                        <a href={fullAddress} target="_blank"><span className="material-icons md-medium green">room</span></a>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimplePasswordDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const handleClose = () => {
        props.props.attemptResetDataPassword();
        onClose(infos, decryptPassword,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        props.props.attemptResetDataPassword();
        onUpdateClose(infos, decryptPassword,infos.colDef.format);
    }

    const [password, setPassword] = useState(infos.value);
    const [decryptPassword, setDecryptPassword] = useState("");
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [fieldId, setFieldId] = useState(infos.field.replace("field", ""));

    const environment = getCurrentEnvironnement();
    const plugnote =  infos.row.plugnote;

    const handleViewCryptedPassword = (event) => {
        if (!edit) {
            props.props.attemptGetPasswordField(plugnote.hash,fieldId,environment.userhash);
        }
        setView(true);
        setEdit(true);
    }

    const handleHidePassword = (event) => {
        setView(false);
        props.props.attemptResetDataPassword();
        setEdit(false);
    }

    const { t, i18n } = useTranslation(['common', 'login']);


    useEffect(() => {
        if (!!props.props.password && props.props.password !== null) {
            if (typeof props.props.password[infos.field] !== "undefined") {
                setDecryptPassword(props.props.password[infos.field]);
            }
        }
    }, [props.props.password, view === true]);

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    {
                        view ?
                            <Input
                                autoComplete='new-password'
                                id={fieldId + '-' + 'password' + '-visible'}
                                placeholder="Password"
                                fullWidth={true}
                                style={{ margin: 0 }}
                                type="text"
                                margin="none"
                                disableUnderline={true}
                                className={`text-line ${classes.textField} input`}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div onClick={(event) => handleHidePassword(event)}>
                                    <span className="material-icons">
                                        visibility_off
                                    </span>
                                        </div>
                                    </InputAdornment>}
                                onChange={(event) => {
                                    setEdit(true);
                                    setPassword(event.target.value);
                                    setDecryptPassword(event.target.value);
                                    //props.handleChangePassword(event.target.value, props.field, props.index)
                                }}
                                value={props.props.fetchingPassword ? t('global.loading') :  decryptPassword }
                            />
                            :

                            <Input
                                disableUnderline={true}
                                autoComplete='new-password'
                                id={fieldId + '-' + 'password' + '-invisible'}
                                placeholder="password"
                                fullWidth={true}
                                style={{ margin: 0 }}
                                margin="none"
                                type="password"
                                className={`text-line ${classes.textField} input`}
                                endAdornment={ password.length > 0 &&
                                <InputAdornment position="end">
                                    <div onClick={(event) => handleViewCryptedPassword(event)}>
                                    <span className="material-icons">
                                        visibility
                                    </span>
                                    </div>
                                </InputAdornment>}
                                onChange={(event) => {
                                    setEdit(true);
                                    setPassword(event.target.value);
                                    setDecryptPassword(event.target.value);
                                    //props.handleChangePassword(event.target.value, props.field, props.index)
                                }}
                                value={password.length > 0 ?  password : ""}
                            />
                    }

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleTimeDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let currentValue = null;
    if (value !== null && value.length > 0) {
        let split = value.split(':');
        currentValue = new Date(null, null, null, split[0], split[1]);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardTimePicker
                    fullWidth={true}
                    className={classes.fullWidth}
                    format="HH:mm"
                    margin="normal"
                    id="time-picker"
                    //label={props.field.title}
                    ampm={false}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleListDialog(props) {
    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let tmpValue = [];

    if (typeof infos.row.plugnote[infos.field] === 'undefined') {
        tmpValue = [];
    }else if ( infos.row.plugnote[infos.field].length > 0) {

        let keywordList = JSON.parse(infos.row.plugnote[infos.field]);
        tmpValue = [];
        keywordList.map((item, index) => {
            tmpValue.push(item.text);
        })
    }


    const [selected, setSelected] = useState(tmpValue);
    const [newValue, setNewValue] = useState("");

    const handleAddValue = (value) =>
    {
        if (!selected.includes(value)) {
            setSelected([...selected, value]);
        }
        setNewValue('');
    }

    const handleRemoveValue = (value) =>
    {
        if (selected.includes(value)) {
            setSelected(selected.filter(item => item !== value));
        }
    }

    const handleClose = () => {
        onClose(infos, "",infos.colDef.format);
    };

    const handleUpdateClose = () => {
        let stringValue = selected.join(', ');
        let stringValueFormated = "";
        if (selected.length > 0) {
            let correctValue = [];
            selected.map((item, index) => {
                correctValue.push({"text" : item})
            });
            stringValueFormated = JSON.stringify(correctValue);
        }

        onUpdateClose(infos, stringValue,infos.colDef.format,stringValueFormated);
    }


    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    <div className={classes.input_add_item}>
                        <Input
                            autoComplete='off'
                            id="standard-full-width"
                            //label="Label"
                            style={{ margin: 0 }}
                            placeholder={t('common:multiple.add-item')}
                            //helpertext="Full width!"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div onClick={(event) => handleAddValue(newValue)}>
                                    <span className="material-icons">
                                        add
                                    </span>
                                    </div>
                                </InputAdornment>}
                            className={`text-line ${classes.textField}  ${classes.fieldRight} input`}
                            value={newValue}
                            disableUnderline={true}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValue(newValue)}}}
                            onChange={(event) => setNewValue(event.target.value)}

                        />
                    </div>
                    {
                        selected.map((value,indexation) => {
                            return (
                                <div key={indexation} className={classes.input_view_item}>
                                    <Input
                                        autoComplete='off'
                                        readOnly={true}
                                        id="standard-full-width"
                                        //label="Label"
                                        style={{ margin: 0 }}
                                        placeholder={t('common:multiple.add-item')}
                                        //helpertext="Full width!"
                                        fullWidth={true}
                                        margin="none"
                                        inputlabelprops={{
                                            shrink: true,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <div onClick={(event) => handleRemoveValue(value)}>
                                                    <span className="material-icons">
                                                        delete
                                                    </span>
                                                </div>
                                            </InputAdornment>
                                        }
                                        className={`text-line ${classes.fieldRight} input`}
                                        value={value}
                                        disableUnderline={true}
                                        onChange={(event) => setNewValue(event.target.value)}

                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleBirthdayDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let currentValue = null;
    if (value !== null && value.length > 0) {
        let currentTime = new Date();
        let split = value.split('-');
        currentValue = new Date(currentTime.getFullYear(), split[1] - 1, split[0], null, null);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardDatePicker
                    className={classes.fullWidth}
                    variant="inline"
                    format="dd-MM"
                    fullWidth={true}
                    id={'date-picker-inline'}
                    //label={props.field.title}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleQrcodeDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let plugcode = typeof value !== 'undefined' ? infos.row.plugnote[infos.field].split(';') : [];

    let initCode = "";
    let initComment = "";

    if (plugcode.length === 2) {
        initCode =  (plugcode[0] !== 'undefined' ? plugcode[0] : "");
        initComment =(typeof plugcode[1] !== 'undefined' ? plugcode[1] : "");

    }else if (plugcode.length === 1) {
        let constainsCode = plugcode[0].startsWith('PN') || plugcode[0].startsWith('PP') || plugcode[0].startsWith('PF') || plugcode[0].startsWith('PE');
        if (constainsCode) {
            initCode= (plugcode[0]);
            initComment =("");
        }else {
            initCode = ("");
            initComment = (plugcode[0]);
        }
    }

    const [code, setCode] = useState(initCode);
    const [comment, setComment] = useState(initComment);

    const handleClose = () => {
        onClose(infos, "(" + code + ") " + comment,infos.colDef.format, code + ';' + comment);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, "(" + code + ") " + comment,infos.colDef.format, code + ';' + comment);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    <Input
                        autoComplete='off'
                        style={{ margin: 0 }}
                        id="standard-full-width"
                        placeholder="code"
                        fullWidth={true}
                        margin="none"
                        className="text-line"
                        disableUnderline={true}
                        onChange={(event) => {
                            setCode(event.target.value);
                        }}
                        defaultValue={code}
                    />
                    <div className="height_12">
                        <div className="line lightgrey flex_style">
                        </div>
                    </div>
                    <Input
                        disableUnderline={true}
                        style={{ margin: 0 }}
                        autoComplete='off'
                        id="standard-full-width"
                        placeholder="comment"
                        fullWidth={true}
                        multiline={true}
                        margin="none"
                        className="text-line"
                        onChange={(event) => {
                            setComment(event.target.value);
                        }}
                        defaultValue={comment}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleMultipleTableDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleSimpleTableDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimplePlugformDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const CustomResizeIcon = (props) => {
    return (
        <div style={{height: 45, width: 2, backgroundColor: colors.paleGrey, borderRadius:'50%', right: 0, marginLeft: props.marginLeft? props.marginLeft : -14}} />
    )
}

const CustomMenuIcon = () => {
    const { t, } = useTranslation(['common'])
    return (
        <Tooltip placement='top-end' title={t('pluglist.column-menu')}>
            <span className="material-icons md-20 " style={{backgroundColor: '#00000000 !important' , color: colors.mainButton, position: 'absolute', right: -5, top: -25}}>
                more_vert
            </span>
        </Tooltip>
    )
}

const CustomFilterPanel = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models', 'global']);
    return (
        <GridPanelContent>
            <div className={classes.filterRowsContainer}>
                <GridFilterPanel
                    filterModel={{
                        items: props.filterSet,
                        linkOperator: props.linkOperator
                    }}
                    onFilterModelChange={(model) => props.handleModelChange(model)}
                />
            </div>
            <div className={classes.filterPanelActionContainer}>
                { props.newFilter && props.savedFilter?.saved !== true ?
                <div
                    className={`${classes.selectionContainer} ${classes.newSelectionContainer}`}
                    key="new-selection"
                    >
                    <TextField
                        error={props.selectionLabel === t("common:filters.new-selection") || props.selectionLabel === ""}
                        variant="standard"
                        fullWidth={false}
                        inputProps={{cursor: 'pointer',}}
                        InputProps={{
                            disableUnderline: false,
                            cursor: 'pointer',
                            endAdornment: (
                                <React.Fragment>
                                    <Tooltip title={t('filters.save-current')} placement="top">
                                        <span
                                            id="icon-save-selection"
                                            className="material-icons md-30"
                                            style={{color: props.selectionLabel === t("common:filters.new-selection") || props.selectionLabel.trim() === '' ? 'lightgrey': 'black'}}
                                            onClick={(e) => {e.preventDefault(); e.stopPropagation();
                                                if (props.selectionLabel !== t("common:filters.new-selection")) props.handleSave()
                                            }}>
                                            save
                                        </span>
                                    </Tooltip>
                                </React.Fragment>
                            )
                        }}
                        onFocus={() => {if (props.selectionLabel === t("common:filters.new-selection")) props.setSelectionLabel("")}}
                        onBlur={(e) => {if (e.target.value.trim() === "") props.setSelectionLabel(t("common:filters.new-selection"))}}
                        label={props.selectionLabel === t("common:filters.new-selection") || props.selectionLabel.trim() === '' ? "Please enter a valid name" : "Selection name"}
                        placeholder="Current selection"
                        onChange={(e) => props.setSelectionLabel(e.target.value)}
                        value={props.selectionLabel}
                    />
                    {props.name === parseFilterParameters(props.fields, props.newFilter)?
                    null :
                    <span className={classes.selectionDetails}>
                        {props.params}
                    </span>}
                </div>
                :
                props.savedFilter && props.hasChanged?
                <div
                    className={`${classes.selectionContainer} ${classes.newSelectionContainer}`}
                    key="update-selection">
                    <TextField
                        variant="standard"
                        fullWidth={false}
                        inputProps={{cursor: 'pointer',}}
                        InputProps={{
                            disableUnderline: false,
                            cursor: 'pointer',
                            endAdornment: (props.hasChanged?
                                <React.Fragment>
                                    {props.savedFilter.authorName?.hash === props.environment.userhash ?
                                    <Tooltip title={t('filters.overwrite-selection')} placement="top">
                                        <span
                                            id="icon-save-selection"
                                            className="material-icons md-30"
                                            onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleOverwrite()}}>
                                            save_as
                                        </span>
                                    </Tooltip>
                                    : null}
                                    <Tooltip title={props.selectionLabel === props.savedFilter.label || props.selectionLabel.trim() === '' ?t('filters.invalid-save') : t('filters.save-current')} placement="top">
                                        <span
                                            id="icon-save-selection"
                                            className="material-icons md-30"
                                            style={{color: props.selectionLabel === props.savedFilter.label || props.selectionLabel.trim() === '' ? 'lightgrey': 'black'}}
                                            onClick={(e) => {e.preventDefault(); e.stopPropagation();
                                                if (props.selectionLabel !== props.savedFilter.label && props.selectionLabel.trim() !== '')
                                                props.handleSave()}}
                                            >
                                            save
                                        </span>
                                    </Tooltip>
                                </React.Fragment>
                            : null)}}
                        // onFocus={() => {if (name === parseFilterParameters(fields, newFilter)) setName("")}}
                        onBlur={(e) => {if (e.target.value.trim() === "") props.setName(props.name)}}
                        label="Current selection"
                        placeholder="Selection name"
                        onChange={(e) => props.setSelectionLabel(e.target.value)}
                        value={props.selectionLabel}
                    />
                    <span className={classes.selectionDetails}>{props.name}</span>
                </div>
                : null}
            </div>
        </GridPanelContent>
    )
};

function ActionButtons (props) {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models', 'global']);
    return(
    <div className={classes.btnContainer}>
        {props.plugnotes.length > 0  &&
        <Tooltip title={t('filters.export-csv')} placement="bottom">
            <div
                onClick={() => props.apiRef.current.exportDataAsCsv()}
                className={classes.actionBtn}>
                {/* <span className="material-icons md-25 " style={{marginRight: 5}}>format_list_bulleted</span> */}
                <div className={classes.attIconType} style={{marginRight: 5}}>
                    <FontAwesomeIcon
                        icon={faFileCsv}
                        className={classes.faIconSize}
                    />
                </div>
                {t('filters.export')}
            </div>
        </Tooltip>}
        <Tooltip title={t('filters.saved-selections')} placement="bottom">
            <div
                onClick={() => {if (props.openSelections) props.setOpenSelections(false); else props.openSelectionList()}}
                className={classes.actionBtn}>
                <span className="material-icons md-25 ">filter_alt</span>
                <span className="material-icons md-25 " style={{marginRight: 5}}>format_list_bulleted</span>
                {t('filters.filters')}
            </div>
        </Tooltip>
        {/* Filter from Selection List */}
        {props.savedFilter !== null && props.savedFilter !== undefined && props.filterSet?.length > 0 ?
        <div
            className={classes.actionBtn}>
            <Tooltip title={t('filters.filter-show')} placement="bottom">
                <span
                    className="material-icons md-25 "
                    onClick={() => {props.handleOpenFilterPanel()}}>
                    filter_list
                </span>
            </Tooltip>
            <span className={classes.filterIndicator}>{props.filterSet.length}</span>

            <span className={classes.selectionName}>{props.savedFilter.label}</span>
            <Tooltip title={t('filters.filter-off')} placement="bottom" >
                <span className={classes.deleteBtn} onClick={props.handleDeleteCurrentFilter}>
                    <span className="material-icons">close</span>
                </span>
            </Tooltip>
        </div>
        : null
        }
        {(props.savedFilter === null || props.savedFilter === undefined) && props.filterSet.length > 0 ?
        <div
            className={classes.actionBtn}>
            <Tooltip title={t('filters.filter-show')} placement="bottom">
                <span
                    className="material-icons md-25 "
                    onClick={() => props.handleOpenFilterPanel()}>
                        filter_list
                </span>
            </Tooltip>
            <span className={classes.filterIndicator}>{props.filterSet.length}</span>
            <span className={classes.selectionName}>{props.selectionLabel}</span>
            <Tooltip title={t('filters.filter-off')} placement="bottom" >
                <span className={classes.deleteBtn} onClick={props.handleDeleteCurrentFilter}>
                    <span className="material-icons">close</span>
                </span>
            </Tooltip>
        </div>
        : null}
    </div>
    )
}

const FilterNumberInput = (props) => {
    const { item, applyValue } = props
    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };
    return (
        <TextField
            defaultValue={item.value}
            onChange={handleFilterChange}
            style={{margin: '0 5px', textAlignLast: 'center',}}
            type='number'
        />
    )
}

const DateTimeInput = (props) => {
    const { item, applyValue } = props
    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };
    let mask = ""
    let label = ""
    switch (props.type) {
        case 'birthday':
            mask = "99-99"
            label = "dd-MM"
            break;
        case 'date':
            mask = "99-99-99"
            label = "Date"
            break;
        case 'time':
            mask = "99:99"
            label = "Time"
            break;
        default:
            break;
    }
    return (
        <InputMask
            maskChar="_"
            mask={mask}
            value={item.value}
            onChange={handleFilterChange}
            >
                {(inputProps) =>
                <TextField
                label={label}
                name="custom-time-input"
                {...inputProps}
                />}
        </InputMask>
    );
}

DateTimeInput.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        operatorValue: PropTypes.string,
        value: PropTypes.any,
    }).isRequired,
};

const ViewPlugnotes = ({
        plugnotes, listView, actif, archived, favoris,
        handleSelectPlugnote, handleCreatePlugnote, handleFlip, flipped, handleDeletePlugnote,
        handleArchivePlugnote, handleLockPlugnote, handleFavorisPlugnote, handleModalPlugnotePlugcodeOpen,
        handleModalPlugnoteClassifyOpen, selectedFields,
        attemptUpdatePlugnoteField, attemptUpdatePlugnoteFieldManual,
        attemptGetPlugnotesPaginateByModelHashAndUserHash, hash, modelhash, limitPage, startPage,
        setSelectedFields, setFilters, setSubOpen, fields, filters, attemptDelAllDataFilter, attemptDelDataFilter,
        selectedRows, setSelectedRows, defaultSelectedFields, setDefaultSelectedFields,
        sizeFields, setSizeFields, defaultSizeFields, setDefaultSizeFields,
        props, filterSet, setFilterSet, linkOperator, setLink,exported
    }) => {
    const { t, i18n} = useTranslation(['common', 'models', 'global']);
    const classes = useStyles();
    const gridClasses = gridStyles();
    const apiRef = useGridApiRef();

    const [openMemo, setOpenMemo] = useState(false);
    const [openAgenda, setOpenAgenda] = useState(false);
    const [openLocalis, setOpenLocalis] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [openTime, setOpenTime] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [openSelections, setOpenSelections] = useState(false)
    const [retrieved, setRetrieved] = useState(false)
    const [savedFilter, setCurrentFilter] = useState(getSelection() || null)
    const [hasChanged, setHasChanged] = useState(false)
    const [isFromServer, setFromServer] = useState(false)
    const [openBirthday, setOpenBirthday] = useState(false);
    const [openQrcode, setOpenQrcode] = useState(false);
    const [openMultipleTable, setOpenMultipleTable] = useState(false);
    const [openSimpleTable, setOpenSimpleTable] = useState(false);
    const [openPlugform, setOpenPlugform] = useState(false);
    const [filtered, setFiltered] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);
    const [fieldFiltered, setFieldFiltered] = useState();
    const [infos, setInfos] = useState(null);
    const [infoCurrentModel, setInfoCurrentModel] = useState(props.model);

    let initialRows = [];
    let initColumns = [];
    const [columns, setColumns] = useState(initColumns);
    const [rows, setRows] = useState(initialRows);
    const [rowsCopy, setRowsCopy] = useState(initialRows);
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);
    // const [sortDirection, setSortDirection] = useState("NONE");
    // const [sortColumn, setSortColumn] = useState("userindex");
    const [rowCount, setRowCount] = useState(rows.length);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [onDrag, setOndrag] = useState(null);
    const [sourceColumnIndex, setSourceColumnIndex] = useState(null);
    const [targetColumnIndex, setTargetColumnIndex] = useState(null);
    const [filterIsValid, setValidFilter] = useState(false);
    const [selectionParams, setNewName] = useState('')
    const [selectionLabel, setSelectionLabel] = useState(t("common:filters.new-selection"))
    const [hiddenFields, setHiddenFields] = useState([])
    const environment = getCurrentEnvironnement();

    const [recent, ] = useState(getTopRow()) // last opened Plugnote
    const [plugArray, setPlugArray] = useState(selectedRows.length > 1 ? props.plugnotes.filter((note) => selectedRows.includes(note.hash) && note.isarchived === false) : props.plugnotes.filter((note) => archived ? true : note.isarchived === false))
    const [numericColumns, setNumColumns] = useState([])
    const [totals, setTotals] = useState([])
    const [showTotals, setShowTotals] = useState(false)

    const viewAction = (object) => {
        return (
            <div
                onClick={(event) => {handleSelectPlugnote(object.row.plugnote);}}
                style={{ cursor: "pointer", width : "100%" }}
                >
                <span className="material-icons md-small">
                    zoom_out_map
                </span>
            </div>
        )
    };

    useEffect(() => {
        setPlugArray((selectedRows.length > 0 ? props.plugnotes.filter((note) => selectedRows.includes(note.hash)) : props.plugnotes).filter( note => archived ? true : note.isarchived === false))
        // console.log('u-e selected rows', plugArray);
        calculateTotals()
    }, [selectedRows, archived])

    useEffect(() => {
        calculateTotals()
    }, [plugArray])

    const headerView = (object) => {
        return (
            <div className="flex_display_direction_column" >
                <span className="material-icons md-small">
                    zoom_out_map
                </span>
            </div>
        )
    };

/*    const headerView = (object) => {
        return (
            <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
                <div
                    style={{
                        color : '#58C3C1'
                    }}
                    className="flex_display_direction_column"
                >
                    <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, object.column.item)}>
                        {selectedFields.includes(object.column.item) ? 'check_box' : 'check_box_outline_blank'}
                    </span>
                    <span>{object.column.name}</span>
                </div>
            </DraggableHeaderRenderer>
        );
    }*/

    // const handleRemoveColumnVisibility = (event, indice) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setSelectedFields(selectedFields.filter((e)=>(e !== indice)))
    // }

    // const handleActiveFilterSearch = (event, item) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setSubOpen(true);
    //     let result = fields.find((field, indexField) => field.id === item);
    //     if (typeof result !== "undefined") {
    //         setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : result, value : null, query : null}])
    //     }
    // }

    // const handleUnActiveFilterSearch = (event, item, findIndex, result) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setFilters(filters.filter((item, index) => {
    //         return findIndex !== index;
    //     }));
    //     attemptDelDataFilter(result.field.key);
    // };

    // const handleUnActiveAllFiltersSearch = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setSubOpen(false);
    //     setFilters([]);
    //     attemptDelAllDataFilter();
    // };

    // const headerIdView = (object) => {
    //     return (
    //         <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
    //             <div
    //                 style={{
    //                     color : '#58C3C1'
    //                 }}
    //                 className="flex_display_direction_column"
    //             >
    //                 <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, object.column.item)}>
    //                     {selectedFields.includes(object.column.item) ? 'check_box' : 'check_box_outline_blank'}
    //                 </span>
    //                 <span>
    //                     {object.column.name}
    //                 </span>
    //             </div>
    //         </DraggableHeaderRenderer>
    //     );
    // }

    // const oldHeaderTitleView = (object) => {
    //     let item = object.colDef.item;
    //     let result = filters.find((element, indexField) => {
    //         if (typeof element.field !== "undefined" && element.field !== null) {
    //             return  element.field.id === item
    //         }
    //     });

    //     let findIndex = filters.findIndex((element, indexField) => {
    //         if (typeof element.field !== "undefined" && element.field !== null) {
    //             return  element.field.id === item
    //         }
    //     });

    //     let template = getTemplateFormatText(props.model['field' + item + 'format'],props.model['field' + item + 'formatoptions']);

    //     let span =  ' ( ' + item + ' - ' + template + ' )';
    //     return (
    //         <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
    //             <div className="flex_display_direction_column">
    //                 <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, item)}>
    //                         {selectedFields.includes(item) ? 'check_box' : 'check_box_outline_blank'}
    //                 </span>

    //                 {typeof result === 'undefined' ?
    //                     <span className="material-icons md-25 md-small pluglist-search-button" onClick={(event) => handleActiveFilterSearch(event, item)}>
    //                         search
    //                     </span>
    //                     :
    //                     <span className="material-icons md-25 md-small pluglist-search-button" onClick={(event) => handleUnActiveFilterSearch(event, item, findIndex, result)}>
    //                         close
    //                     </span>
    //                 }

    //                 <span>{object.colDef.name}</span>
    //                 <span style={{fontSize : '10px'}}>{span}</span>
    //             </div>
    //         </DraggableHeaderRenderer>
    //     );
    // }

    function calculateTotals () {
        let _totals = []
        let values = Object.values(props.model)
        if (values.includes('numeric')) {
            let keys = Object.keys(props.model)
            values.forEach((value, index) => {
                if (value === 'numeric') {
                    let field = keys[index].split('format')[0]
                    let total = {
                        id: field,
                        label: props.model[field + 'label'],
                        value: 0
                    }
                    _totals.push(total)
                }
            })
        }
        plugArray.forEach((note) => {
            _totals.forEach((field, index) => {
                let number = 0
                if (note[field.id] !== "" && note[field.id] !== undefined) {
                    number = parseFloat(note[field.id])
                    _totals[index].value += number
                }
            })
        })
        setTotals(_totals)
    }

    function handleToggleTotal (e, field) {
        e.preventDefault();
        e.stopPropagation();
        let _cols = [...numericColumns]
        if (_cols.includes(field)) {
            setNumColumns(_cols.filter((item) => item !== field));
        }
        else {
            setNumColumns([..._cols, field])
        }
    }

    function toggleTotal() {
        setShowTotals(previous => !previous)
    }

    const headerTitleView = (object) => {
        let item = object.colDef.item;
        let editable = object.colDef.editable;
        let complex = typeof object.colDef.complex !== "undefined" ? true : false;
        let template = getTemplateFormatText(props.model['field' + item + 'format'], props.model['field' + item + 'formatoptions']);
        let fieldNb =  ' ( ' + item + ' )';
        let formatNb =  '( ' + template + ' )';
        let rowError = typeof object.colDef.rowError !==  "undefined" ? object.colDef.rowError.length > 0 : false;

        let total = totals.find(entry => entry.field === item -1)?.value
        // {
        //     id: field,
        //     label: props.model[field + 'label'],
        //     value: 0
        // }
        return (
            <div className="flex_display_direction_column" style={{ textAlign: "center" }}>
                <span className="bold"  style={{width : "100%", textAlign : "center", paddingTop: 10, paddingLeft: 10}}>
                    {object.colDef.headerNameCustom}
                    <div style={{position: 'absolute', top: -5, left: 5}}>
                        <span style={{fontSize : 9}}>{fieldNb}</span>
                    </div>
                </span>
                <div className="text_align" style={{marginLeft: -10}}>
                    { complex ?
                        <div className="flex_display_direction_row_center">
                            { !rowError || ( rowError && filtered === null) ?
                                <div className="flex_display_direction_row_center">
                                    <Tooltip  title={t('common:helper.edit-possible-via-modal')} aria-label="add" placement="top-start">
                                        <span className={`material-icons md-15 orange`}>create</span>
                                    </Tooltip>
                                    <span style={{fontSize : '10px'}}>{formatNb}</span>
                                </div>
                                :
                                <div className="flex_display_direction_row_center">
                                </div>
                            }
                        </div>
                        :
                        <div className="flex_display_direction_row_center" style={{width : "100%"}}>
                            {!rowError || (rowError && filtered === null) ?
                                <div className="flex_display_direction_row_center" style={{paddingTop: -10}}>
                                    {/* <div >
                                        <span style={{fontSize: '10px'}}>{fieldNb}</span>
                                    </div>*/}
                                    <Tooltip
                                        title={editable ? t('common:helper.edit-possible') : t('common:helper.edit-not-possible')}
                                        aria-label="add" placement="top-start">
                                        <span
                                            className={`material-icons md-15 ${editable ? 'green' : 'red'}`}>create</span>
                                    </Tooltip>
                                    <span style={{fontSize: '10px'}}>{formatNb}</span>
                                </div>
                                :
                                <div className="flex_display_direction_row_center">
                                </div>
                            }
                        </div>
                        }
                </div>
                {
                    object.colDef.format === 'numeric' &&
                    (
                        isDefined(total) ?
                        <div className={classes.headerTotalCtn}>
                            <span className="material-icons" style={{fontSize: 15, color: colors.bloodOrange}}>
                                {total}
                            </span>
                        </div>
                        :
                        <PlugTooltip placement='top' title={t('pluglist.add-total')}>
                        <div className={classes.headerTotalCtn}>
                            <span className="material-icons" style={{fontSize: 15, color: colors.bloodOrange}}>
                                title
                            </span>
                        </div>
                    </PlugTooltip>
                    )
                }
            </div>
        );
    }

    const headerSpecialTitleView = (object) => {
        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="green bold">{object.colDef.headerName}</span>
                <div className="text_align" style={{width : "100%"}}>
                </div>
            </div>
        );
    }

    const headerSpecialQrCodeTitleView = (object) => {
        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="green bold">{object.colDef.headerName}</span>
                <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> qr_code </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div>
            </div>
        );
    }

    const headerActionsTitleView = (object) => {
        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="">{object.colDef.headerName}</span>
            </div>
        );
    }

    const headerSpecialTitleViewAnnexe = (object) => {
        return (
            <div className="flex_display_direction_column cursor" style={{width : "100%", textAlign : "center"}} onClick={(event) => {onRowClick(object)}}>
                <span className={`material-icons md-small green bold fa-rotate-90`}>
                    attachment
                </span>
                {/* <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> zoom_out_map </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div> */}
            </div>
        )
    }

    const onRowClick = (object) => {
        // console.log('object clicked', object)
        if (isDefined(object.row?.plugnote)) { handleSelectPlugnote(object.row.plugnote) }
    }

    const onTablemultiClick = (object) => {
        let table = {field: object.field, hash: object.row.plugnote.hash}
        setTableView(table)
        onRowClick(object)
    }

    const onRowPlugcodeClick = (object) => {
        handleClickQrCode(object.row.plugnote)
    }

    const specialAction = (object) => {
        let totals = []
        let count = 0
        switch (object.colDef.format) {
            case 'mail':
                return (
                    <div>
                        <span>
                            <a href={`mailto:${object.value}`}>{object.value}</a>
                        </span>
                    </div>
                );
            case 'phone':
                return (
                    <div>
                        <span>
                            <a href={`tel:${object.value}`}>{object.value}</a>
                        </span>
                    </div>
                );
            case 'smailvalidmulti':
                count = isJsonStringObjectAndCountSubElement(object?.value, 'list') || 0
                let fieldTxt = isJsonStringObjectAndGetElement(object?.value, 'value') || ""
                return (
                    <div>
                        <span>
                            {
                                fieldTxt.toString() !== "" &&
                                <span>{fieldTxt.toString()}  <span> - </span> </span>
                            }
                            {'(' + count.toString() + ')'}
                        </span>
                    </div>
                );
            case 'tablemulti':
                let hasRecap = object.formattedValue?.includes('recap')
                // if (object.field === "field11") console.log('isJSon...?', object);
                count = isJsonStringObjectAndCount(object.value)
                if (hasRecap && count > 0) { count -= 1 }
                if (hasRecap) {
                    let field = JSON.parse(props.model[object.field + 'formatoptions'])
                    let recapVal = null;
                    if(isJsonStringObject(object?.value)){
                        recapVal  = JSON.parse(object?.value);
                    }
                    if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                        recapVal = recapVal.find((obj) => obj.hasOwnProperty('recap'))
                        if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                            Object.keys(recapVal).forEach((key, index) => {
                                if(field?.lines){
                                    if (key !== 'recap') {
                                        let line = field.lines.find((line) => line.id.toString() === key.toString())
                                        if (isDefined(line)) {
                                            let value = {label: line.label, value: recapVal[key], index: field.lines.indexOf(line) + 1}
                                            totals.push(value)
                                        }
                                    }
                                } else {
                                    if (key !== 'recap') {
                                        let line = field.find((line) => line.id.toString() === key.toString())
                                        if (isDefined(line)) {
                                            let value = {label: line.label, value: recapVal[key], index: field.indexOf(line) + 1}
                                            totals.push(value)
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
                return (
                    <div onClick={(event) => {onTablemultiClick(object)}} style={{width : "100%", textAlign : "left", cursor: "pointer"}}>
                        {'(' + count.toString() + ')'}
                        {
                            hasRecap && totals.length > 0 &&
                            totals.map((entry, id) => {
                                return(
                                    <Tooltip key={"tooltip-total-" + entry.value + id} placement='top' title={entry.label + ': ' + entry.value}>
                                    <span className={classes.totalValue} key={"total-" + entry.value + id}>
                                        {` - `}
                                        <span className={classes.fieldNumber}>{entry.index}</span>
                                        <span className={classes.fieldTotal}>{entry.value}</span>
                                    </span>
                                    </Tooltip>
                                )
                            })
                        }
                    </div>
                );
            case 'document':
            case 'tablemultin':
                // console.log('object tablemultin', object, props.model)
                let recap = object.formattedValue?.includes('recap')
                let rowCount = isJsonStringObjectAndCountSubElement(object?.value, 'list')
                if (recap && rowCount > 0) rowCount -= 1
                if (recap) {
                    let field = JSON.parse(props.model[object.field + 'formatoptions'])
                    let recapVal = null;
                    if(isJsonStringObject(object?.value)){
                        recapVal  = JSON.parse(object?.value);
                    }
                    if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                        if (recapVal.list !== undefined) recapVal = recapVal.list;
                        recapVal = recapVal.find((obj) => obj.hasOwnProperty('recap'))
                        if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                            Object.keys(recapVal).forEach((key, index) => {
                                if (key !== 'recap') {
                                    let line = field.lines.find((line) => line.id.toString() === key.toString())
                                    if (isDefined(line)) {
                                        let value = { label: line.label, value: recapVal[key], index: field.lines.indexOf(line) + 1 }
                                        totals.push(value)
                                    }
                                }
                            })
                        }
                    }

                }
                return (
                    <div onClick={(event) => {onTablemultiClick(object)}} className={classes.cellContent}>
                        {'(' + rowCount.toString() + ')'}
                        {
                            recap && totals.length > 0 &&
                            totals.map((entry, id) => {
                                return(
                                    <Tooltip key={"tooltip-total-" + entry.value + id} placement='top' title={entry.label + ': ' + entry.value}>
                                    <span className={classes.totalValue} key={"total-" + entry.value + id}>
                                        {` - `}
                                        <span className={classes.fieldNumber}>{entry.index}</span>
                                        <span className={classes.fieldTotal}>{entry.value}</span>
                                    </span>
                                    </Tooltip>
                                )
                            })
                        }
                    </div>
                );
            case 'url':
                return (
                    <div>
                        <span>
                            <a href={object.value} target="_blank">{object.value}</a>
                        </span>
                    </div>
                );
            default :
                if (specialFields.includes(object.colDef.format)) {
                    return(
                        <div>
                            <span>
                                {object.value}
                            </span>
                        </div>
                    )
                }
                else {
                    switch (object.field) {
                        case "annexe":
                            // console.log('object', object);
                            return (
                                <div onClick={(event) => {onRowClick(object)}}  style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                    { object.value === 0 ?
                                        <span style={{color: colors.frost}}>
                                            0
                                        </span>
                                        :
                                        <span>
                                            {object.value}
                                        </span>
                                    }
                                </div>
                            )
                        //! Case userindex pour mettre en forme la valeur numérique tout en la traitant comme un nombre
                        case "userindex":
                            return (
                            <div onClick={(event) => {onRowClick(object)}} style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                {typeof object.value !== "undefined" && object.value >= 0 ?
                                <span>
                                    {'[' + environment.envIndex + '-' + object.value + ']'}
                                </span>
                                :
                                <span>
                                &nbsp;
                                </span>
                                }
                            </div>)
                        default:
                            return (
                            <div onClick={(event) => {onRowClick(object)}}  style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                {typeof object.value !== "undefined" && object.value.toString().length > 0 ?
                                <span>
                                    {object.value}
                                </span>
                                :
                                <span>
                                &nbsp;
                                </span>
                                }
                            </div>
            )}}
        }
    };

    const dateAction = (object) => {
        let date
        switch (object.field){
            case "creation":
                date = new Date(object.row?.plugnote?.creationdate).toLocaleString(i18n.language.includes('en')? 'en-GB' : 'fr-FR', {dateStyle: 'short', hour12: false, timeStyle: 'short'})
                break;
            case "modification":
                date = new Date(object.row?.plugnote?.moddate).toLocaleString(i18n.language.includes('en')? 'en-GB' : 'fr-FR', {dateStyle: 'short', hour12: false, timeStyle: 'short'})
                break;
            default:
                date = object.value
                break;
        }
        return(
            <div className="cursor" onClick={(event) => {onRowClick(object)}} style={{ cursor: "pointer", width : "100%" }}>
                <span>
                    {date}
                </span>
            </div>
        )
    };

    const handleClickQrCode = (object) => {
        setPlugnote(object);
        handleModalPlugnotePlugcodeOpen(object);
    };

    const allActions = (object) => {
        return (
            <div className="display_flex float_right grey">
                <PopupState variant="popover" popupId={object.row.plugnote.hash}>
                    {(popupState) => (
                        <div>
                            <span aria-describedby={object.row.plugnote.hash} className="material-icons md-medium" {...bindTrigger(popupState)}>
                                more_horiz
                            </span>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <div className="flex_display_direction_column">
                                        <span className="p3 cursor"  onClick={(event) => handleFavorisPlugnote(object.row.plugnote)}>
                                            <span className={"material-icons md-25 padding_0_10 " + (object.row.plugnote.favorite ? 'green' : 'black')}>star</span>
                                            {t('common:plugnote.favorite')}
                                        </span>
                                        <span className="p3 cursor"  onClick={(event) => handleArchivePlugnote(object.row.plugnote)}>
                                            {
                                                object.row.plugnote.isarchived ?
                                                    <span id="icon-archive" className="material-icons md-25 padding_0_10">
                                                        archive
                                                    </span>
                                                    :
                                                    <span id="icon-archive" className="material-icons md-25 padding_0_10">
                                                        unarchive
                                                    </span>
                                            }
                                            { object.row.plugnote.isarchived ? t('common:plugnote.unarchive') : t('common:plugnote.archive')}
                                        </span>
                                        <span className="p3 cursor"  onClick={(event) => handleLockPlugnote(object.row.plugnote)}>
                                            {
                                                object.row.plugnote.locked === true ?
                                                    <span id="icon-verouiller" className="material-icons md-25 black padding_0_10">
                                                        lock
                                                    </span>
                                                    :
                                                    <span id="icon-verouiller" className="material-icons md-25 black padding_0_10">
                                                        lock_open
                                                    </span>
                                            }
                                            { object.row.plugnote.locked ? t('common:plugnote.unlock') : t('common:plugnote.lock')}
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => {handleClickQrCode(object.row.plugnote)}}>
                                            <span className="material-icons md-25 black padding_0_10" >qr_code</span>
                                            {t('common:plugnote.manage-plugcode')}
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => {handleModalPlugnoteClassifyOpen(object.row.plugnote)}}>
                                            <span className="material-icons md-25 black padding_0_10" >sync_alt</span>
                                            {t('common:plugnote.move-plugnote')}
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => handleDeletePlugnote(object.row.plugnote)}>
                                            <span className="material-icons md-25 black padding_0_10" >delete</span>
                                            {t('common:plugnote.delete')}
                                        </span>
                                    </div>
                                </Box>
                            </Popover>
                        </div>
                    )}
                </PopupState>
            </div>
        )
    }

    const plugcodeAction = (object) => {
        return (
            <div onClick={(event) => {onRowPlugcodeClick(object)}} style={{ cursor: "pointer", width : "100%", textAlignLast: 'center'}}>
                {object.value.length > 0 ?
                <span className={classes.plugcodeCtn}>
                    {object.value}
                    {
                        object.row.plugnote?.pncode_countopen > 0 && <span className="references_8"> ({object.row.plugnote?.pncode_countopen}) </span>
                    }
                </span>
                :
                <Tooltip title={t('common:models.generate-plugcode')} aria-label="add" placement="top-start">
                    <div style={{ cursor: "pointer", width : "100%", position: 'relative'}}>
                        <span className="material-icons md-25 ">
                            qr_code
                        </span>
                        <span className="material-icons md-15" style={{position: "absolute", top: 0, right: '35%', backgroundColor: 'white', borderRadius: '50%'}}>
                            add_circle_outline
                        </span>
                    </div>
                </Tooltip>
                }
            </div>
        )
    }

    const selectAction = (object) => {
        let plugnote = object.row;
        if (plugnote.isarchived) {
            return null;
        }else {
            return (
                <SelectCellFormatter
                    aria-label="Select"
                    tabIndex={-1}
                    isCellSelected={object.isCellSelected}
                    value={object.isRowSelected}
                    //onClick={event.stopPropagation}
                    onChange={object.onRowSelectionChange}
                />
            );
        }
    }

    let preSizeFields = [];
    let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

    if (checkExistingSizeFields) {
        let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
        if (findIndex !== -1) {
            preSizeFields =checkExistingSizeFields[findIndex].fields;
        }
    }

    const emptyOperators = [{
        label: 'is empty',
        value: 'isEmpty',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            return params.value === "" || params.value === undefined
            };
        },
    },
    {
        label: 'is not empty',
        value: 'isNotEmpty',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            return params.value !== ""
            };
        },
    }]

    const timeOperators = [{
        label: 'at',
        value: 'at',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            if (filterItem.value.includes('_')) return true
            return params.value === filterItem.value
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: 'time' },
    },
    {
        label: 'before',
        value: 'before',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            if (filterItem.value.includes('_')) return true
            return params.value < filterItem.value
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: 'time' },
    },
    {
        label: 'after',
        value: 'after',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            if (filterItem.value.includes('_')) return true
            return params.value > filterItem.value
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: 'time' },
    },
    ...emptyOperators
    ]

    const annexesOperators = [
        {
            "label": " = ",
            "value": "=",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0) return (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined');
                    else return item.value === params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            "label": " ≠ ",
            "value": "!=",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0) return (!isNaN(params.value) && params.value !== 0 && typeof params.value !== 'undefined');
                    else return item.value !== params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            "label": " < ",
            "value": "<",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0) return false;
                    if (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined') return true;
                    else return item.value > params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            "label": " > ",
            "value": ">",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0 && !isNaN(params.value) && params.value !== 0 && typeof params.value !== 'undefined') return true;
                    if (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined') return false;
                    else return item.value < params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            label: 'none',
            value: 'none',
            getApplyFilterFn: (item, col) => {
                return (params) => {
                    // console.log('item /params', item, params);
                    if (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined') return true;
                    else return false
                }
            },
            InputComponent: null,
        },
    ]

    const dateOperators = (type) => [{
        label: 'on',
        value: 'on',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue ) {
            return null;
            }
            return (params) => {
            if (filterItem.value.includes('_')) return true
            return params.value === filterItem.value
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: type },
    },
    {
        label: 'before',
        value: 'before',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            if (filterItem.value.includes('_')) return true

            switch (type) {
                case 'date':
                    return new Date('20' + params.value + 'Z') < new Date('20' + filterItem.value + 'Z');
                case 'birthday':
                    let fMonth = parseInt(filterItem.value.substr(3,2))
                    let pMonth = parseInt(params.value.substr(3,2))
                    let fDay = parseInt(filterItem.value.substr(0,2))
                    let pDay = parseInt(params.value.substr(0,2))
                    if (fMonth > pMonth) return true;
                    else if (fMonth === pMonth) return pDay < fDay;
                    else return false
                default:
                    return params.value < filterItem.value
            }
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: type },
    },
    {
        label: 'after',
        value: 'after',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
            }
            return (params) => {
            if (filterItem.value.includes('_')) return true

            switch (type) {
                case 'date':
                    return new Date('20' + params.value + 'Z') > new Date('20' + filterItem.value + 'Z');
                case 'birthday':
                    let fMonth = parseInt(filterItem.value.substr(3,2))
                    let pMonth = parseInt(params.value.substr(3,2))
                    let fDay = parseInt(filterItem.value.substr(0,2))
                    let pDay = parseInt(params.value.substr(0,2))
                    if (fMonth < pMonth) return true;
                    else if (fMonth === pMonth) return pDay > fDay;
                    else return false
                default:
                    return params.value > filterItem.value
            }
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: type },
    },
    ...emptyOperators
    ]

    if (plugnotes.length > 0 && props.model !== null) {
        initColumns.push({
            field: 'view',
            key: 'view',
            headerName:  ' ',
            resizable: false,
            sortable : false,
            order : false,
            renderHeader: headerView,
            renderCell : viewAction,
            width: 65,
            align : 'center'
        });
        selectedFields.forEach((item, index) => {
            let find = preSizeFields.findIndex(elm => elm.field === item);
            let size = find !== -1 ? preSizeFields[find].width : 170;
            if (item === 0) {
                size = find !== -1 ? preSizeFields[find].width : 100;
            }
            else if (item === 20) {
                size = find !== -1 ? preSizeFields[find].width : 90;
            }
            if (item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ) {
                if (props.model['field' + item + 'label'] !== null) {
                    let template = getTemplateFormatText(props.model['field' + item + 'format'], props.model['field' + item + 'formatoptions']);
                    switch (props.model['field' + item + 'format']) {
                        case "table":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                complex : true,
                            });
                            break;
                        case 'textarea':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                complex : true,
                            });
                            break;
                        case "localis":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                complex : true,
                            });
                            break;
                        case "password":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                complex : true,
                            });
                            break;
                        case "time":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                filterOperators: timeOperators,
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                rowError : plugnotes.filter((plug) => {
                                    let regEx = /^\d{2}:\d{2}$/;
                                    return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx) === null
                                }),
                                cellClassName: (params) => {
                                    let regEx = /^\d{2}:\d{2}$/;
                                    return  clsx('super-app', {
                                        negative: typeof params.value !== "undefined" &&  params.value.length > 0 && params.value.match(regEx)  === null
                                    })
                                },
                                complex : true,
                            });
                            break;
                        case "list":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                complex : true,
                            });
                            break;
                        case "birthday":
                            initColumns.push({
                                field: 'field' + item,
                                filterOperators: dateOperators('birthday'),
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                rowError : plugnotes.filter((plug) => {
                                    let regEx = /^\d{2}-\d{2}$/;
                                    return typeof plug['field' + item] !== "undefined" &&  plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                }),
                                cellClassName: (params) => {
                                    let regEx = /^\d{2}-\d{2}$/;
                                    return  clsx('super-app', {
                                        negative: typeof params.value !== "undefined" &&  params.value.length > 0 && params.value.match(regEx)  === null
                                    })
                                },
                                complex : true,
                            });
                            break;
                        case "qrcode":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                complex : true,
                            });
                            break;
                        case "intdatamaj":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: false,
                            });
                            break;
                        case "agenda":
                            initColumns.push({
                                cellClassName: (params) => {
                                    let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/;
                                    return  clsx('super-app', {
                                        negative:  typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                    })
                                },
                                complex : true,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                field: 'field' + item,
                                format : props.model['field' + item + 'format'],
                                headerAlign: 'left',
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                hide : false,
                                item : item,
                                key: 'field' + item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                resizable: true,
                                rowError : plugnotes.filter((plug) => {
                                    let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/;
                                    return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                }),
                                sortable : true,
                                special : false,
                                type: 'date',
                                width: size,
                            });
                            break;
                        case 'phone':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                hide : false,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                renderEditCell: renderPhoneEditInputCell,
                                format : props.model['field' + item + 'format'],
                                special : false,
                                headerAlign: 'left',
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                rowError : plugnotes.filter((plug) => {
                                    return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && isPossiblePhoneNumber(plug['field' + item]) === false
                                }),
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: typeof params.value !== "undefined" && params.value.length > 0 && isPossiblePhoneNumber(params.value) === false
                                    })
                            });
                            break;
                        case 'numeric':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                type : 'number',
                                hide : false,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                rowError : plugnotes.filter((plug) => typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && isNaN(Number(plug['field' + item]))),
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: typeof params.value !== "undefined" && params.value.length > 0 && isNaN(Number(params.value))
                                    })
                            });
                            break;
                        case 'date':
                            initColumns.push({
                                cellClassName: (params) => {
                                    let regEx = /^\d{4}-\d{2}-\d{2}$/;
                                    return  clsx('super-app', {
                                        negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                    })
                                },
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                field: 'field' + item,
                                format : props.model['field' + item + 'format'],
                                headerAlign: 'left',
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                hide : false,
                                item : item,
                                key: 'field' + item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                resizable: true,
                                rowError : plugnotes.filter((plug) => {
                                    let regEx = /^\d{4}-\d{2}-\d{2}$/;
                                    return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                }),
                                special : false,
                                sortable : true,
                                type : 'date',
                                width: size,
                            });
                            break;
                        case 'boolean':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                type : 'boolean',
                                renderHeader: headerTitleView,
                                format : props.model['field' + item + 'format'],
                                special : false,
                                headerAlign: 'left',
                                hide : false,
                                width: size,
                                valueFormatter: ({ value }) => value === '1',
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                cellClassName: (params) => {
                                    return  clsx('super-app', {
                                        negative: typeof params.value !== "boolean"
                                    })
                                }
                            });
                            break;
                        case 'select':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                format : props.model['field' + item + 'format'],
                                special : false,
                                headerAlign: 'left',
                                hide : false,
                                width: size,
                                renderEditCell: renderSelectEditInputCell,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                options : props.model['field' + item + 'formatoptions']
                            });
                            break;
                        case 'mail':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                rowError : plugnotes.filter((plug) => {
                                    return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(new RegExp( /^[a-zA-Z0-9.!#$%&'* + /=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))  === null
                                }),
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative:   typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) === null
                                    })
                            });
                            break;
                        case 'url':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                rowError : plugnotes.filter((plug) => {
                                    return typeof plug['field'+item] !== "undefined" && plug['field'+item].length > 0 && plug['field'+item].match(new RegExp( /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}) {3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}) {2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}) {2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])) {2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)) === null
                                }),
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative:  typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(new RegExp( /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}) {3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}) {2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}) {2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])) {2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)) === null
                                    })
                                //
                            });
                            break;
                        case 'now':
                            initColumns.push({
                                cellClassName: (params) => {
                                    let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
                                    return  clsx('super-app', {
                                        negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                    })
                                },
                                editable: specialFields.includes(props.model['field' + item + 'format']),
                                field: 'field' + item,
                                format : props.model['field' + item + 'format'],
                                headerAlign: 'left',
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                hide : false,
                                item : item,
                                key: 'field' + item,
                                resizable: true,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                rowError : plugnotes.filter((plug) => {
                                    let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
                                    return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx);
                                }),
                                sortable : true,
                                special : false,
                                type: 'date',
                                width: size,
                            });
                            break;
                        default:
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                headerNameCustom:  props.model['field' + item + 'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : props.model['field' + item + 'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(props.model['field' + item + 'format'])
                            });
                            break;
                    }
                }
            }
            else {
                if (item === 0) {
                    initColumns.push({
                        field: 'userindex',
                        key: 'userindex',
                        headerName: "N°",
                        resizable: true,
                        sortable : true,
                        item : 0,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        renderCell : specialAction,
                        align : 'center',
                        special : true,
                        hide: false,
                        type: "number",
                        width: size,
                    });
                }

                if (item === 20) {
                    initColumns.push({
                        align : 'center',
                        field: 'annexe',
                        headerName: "Nbr d'Annexes",
                        key: 'annexe',
                        filterOperators: annexesOperators,
                        headerAlign: 'left',
                        hide: false,
                        item : 20,
                        renderCell : specialAction,
                        renderHeader: headerSpecialTitleViewAnnexe,
                        resizable: true,
                        sortable : true,
                        special : true,
                        type: "number",
                        width: size,
                    });
                }

                if (item === 16) {
                    initColumns.push({
                        field: 'plugcode',
                        key: 'plugcode',
                        headerName: "PN- Code",
                        resizable: true,
                        sortable : true,
                        item : 16,
                        headerAlign: 'left',
                        renderHeader: headerSpecialQrCodeTitleView,
                        hide: false,
                        renderCell : plugcodeAction,
                        special : true,
                        width: size,
                    });
                }

                if (item === 17) {
                    initColumns.push({
                        field: 'tiers',
                        key: 'tiers',
                        headerName: t('common:pluglist.header-created-by'),
                        resizable: true,
                        sortable : true,
                        item : 17,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        hide: false,
                        renderCell : specialAction,
                        special : true,
                        width: size,
                    });
                }

                if (item === 18) {
                    initColumns.push({
                        field: 'creation',
                        filterOperators: dateOperators('date'),
                        key: 'creation',
                        headerName: t('common:pluglist.header-created'),
                        resizable: true,
                        sortable : true,
                        item :18,
                        hide: false,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        renderCell : dateAction,
                        align : 'center',
                        special : true,
                        type: 'date',
                        width: size,
                    });
                }

                if (item === 19) {
                    initColumns.push({
                        field: 'modification',
                        filterOperators: dateOperators('date'),
                        key: 'modification',
                        headerName: t('common:pluglist.header-edited'),
                        resizable: true,
                        sortable : true,
                        headerAlign: 'left',
                        item : 19,
                        hide: false,
                        renderHeader: headerSpecialTitleView,
                        renderCell : dateAction,
                        special : true,
                        align : 'center',
                        type: 'date',
                        width: size,
                    });
                }
            }
        })

        defaultSelectedFields.forEach((item, index) => {
            let find = preSizeFields.findIndex(elm => elm.field === item);
            let size = find !== -1 ? preSizeFields[find].width : 170;
            if (item === 0) {
                size = find !== -1 ? preSizeFields[find].width : 100;
            }
            else if (item === 20) {
                size = find !== -1 ? preSizeFields[find].width : 90;
            }
            if (!selectedFields.includes(item)) {
                if (item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ) {
                    let template = getTemplateFormatText(props.model['field' + item + 'format'],props.model['field' + item + 'formatoptions']);
                    if (props.model['field' + item + 'label'] !== null) {
                        switch (props.model['field' + item + 'format']) {
                            case "table":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case 'textarea':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case "localis":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case "password":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case "time":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{2}:\d{2}$/;
                                        return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                    }),
                                    cellClassName: (params) => {

                                        let regEx = /^\d{2}:\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    complex : true,
                                });
                                break;
                            case "list":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case "birthday":
                                initColumns.push({
                                    field: 'field' + item,
                                    filterOperators: dateOperators('birthday'),
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{2}-\d{2}$/;
                                        return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                    }),
                                    cellClassName: (params) => {

                                        let regEx = /^\d{2}-\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    complex : true,
                                });
                                break;
                            case "qrcode":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case "intdatamaj":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: false,
                                });
                                break;
                            case "agenda":
                                initColumns.push({
                                    cellClassName: (params) => {

                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    complex : true,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    field: 'field' + item,
                                    format : props.model['field' + item + 'format'],
                                    headerAlign: 'left',
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    hide : false,
                                    item : item,
                                    key: 'field' + item,
                                    resizable: true,
                                    sortable : true,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/;
                                        return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                    }),
                                    special : false,
                                    type: 'date',
                                    width: size,
                                });
                                break;
                            case 'phone':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    hide : false,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    renderEditCell: renderPhoneEditInputCell,
                                    format : props.model['field' + item + 'format'],
                                    special : false,
                                    headerAlign: 'left',
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && isPossiblePhoneNumber(plug['field' + item]) === false
                                    }),
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && isPossiblePhoneNumber(params.value) === false
                                        })
                                });
                                break;
                            case 'numeric':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'number',
                                    hide : false,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && isNaN(Number(plug['field' + item]))),
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && isNaN(Number(params.value))
                                        })
                                });
                                break;
                            case 'date':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'date',
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    hide : false,
                                    headerAlign: 'left',
                                    //renderEditCell: renderDateEditInputCell,
                                    format : props.model['field' + item + 'format'],
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}$/;
                                        return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx)  === null
                                    }),
                                    cellClassName: (params) => {

                                        let regEx = /^\d{4}-\d{2}-\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    }

                                });
                                break;
                            case 'boolean':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'boolean',
                                    renderHeader: headerTitleView,
                                    format : props.model['field' + item + 'format'],
                                    special : false,
                                    headerAlign: 'left',
                                    hide : false,
                                    width: size,
                                    valueFormatter: ({ value }) => value === '1',
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    cellClassName: (params) => {
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "boolean"
                                        })
                                    }
                                });
                                break;
                            case 'select':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    format : props.model['field' + item + 'format'],
                                    special : false,
                                    headerAlign: 'left',
                                    hide : false,
                                    width: size,
                                    renderEditCell: renderSelectEditInputCell,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    options : props.model['field' + item + 'formatoptions']
                                });
                                break;
                            case 'mail':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        return typeof plug['field'+item] !== "undefined" && plug['field' + item].length > 0 && plug['field'+item].match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))  === null
                                    }),
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) === null
                                        })
                                });
                                break;
                            case 'url':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        return typeof plug['field'+item] !== "undefined" && plug['field'+item].length > 0 && plug['field'+item].match(new RegExp( /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}) {3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}) {2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}) {2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])) {2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)) === null
                                    }),
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(new RegExp( /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}) {3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}) {2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}) {2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])) {2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)) === null
                                        })
                                    //
                                });
                                break;
                            case 'now':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
                                        return typeof plug['field' + item] !== "undefined" && plug['field' + item].length > 0 && plug['field' + item].match(regEx);
                                    }),
                                    cellClassName: (params) => {

                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    type: 'date',
                                });
                                break;
                            default:
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format'])
                                });
                                break;
                        }
                    }
                }
                else {
                    if (item === 0) {
                        initColumns.push({
                            field: 'userindex',
                            key: 'userindex',
                            headerName: "N°",
                            resizable: true,
                            sortable : true,
                            item : 0,
                            renderHeader: headerSpecialTitleView,
                            renderCell : specialAction,
                            special : true,
                            headerAlign: 'left',
                            align : 'center',
                            hide: true,
                            width: size,
                            type: "number",
                        });
                    }
                    // annexes
                    if (item === 20) {
                        initColumns.push({
                            align : 'center',
                            field: 'annexe',
                            headerName: "Nbr d'Annexes",
                            key: 'annexe',
                            filterOperators: annexesOperators,
                            headerAlign: 'left',
                            hide: false,
                            item : 20,
                            renderCell : specialAction,
                            renderHeader: headerSpecialTitleViewAnnexe,
                            resizable: true,
                            sortable : true,
                            special : true,
                            type: "number",
                            width: size,
                        });
                    }
                    // plugcode
                    if (item === 16) {
                        initColumns.push({
                            field: 'plugcode',
                            key: 'plugcode',
                            headerName: "PN- Code",
                            resizable: true,
                            sortable : true,
                            item : 16,
                            headerAlign: 'left',
                            renderHeader: headerSpecialQrCodeTitleView,
                            hide: true,
                            renderCell : plugcodeAction,
                            special : true,
                            width: size,
                        });
                    }
                    // tiers
                    if (item === 17) {
                        initColumns.push({
                            field: 'tiers',
                            key: 'tiers',
                            headerName: t('common:pluglist.header-created-by'),
                            resizable: true,
                            sortable : true,
                            item : 17,
                            headerAlign: 'left',
                            renderHeader: headerSpecialTitleView,
                            hide: true,
                            renderCell : specialAction,
                            special : true,
                            width: size,
                        });
                    }
                    //creation
                    if (item === 18) {
                        initColumns.push({
                            field: 'creation',
                            filterOperators: dateOperators('date'),
                            key: 'creation',
                            headerName: t('common:pluglist.header-created'),
                            resizable: true,
                            sortable : true,
                            item :18,
                            headerAlign: 'left',
                            hide: true,
                            renderHeader: headerSpecialTitleView,
                            renderCell : dateAction,
                            align : 'center',
                            special : true,
                            width: size,
                        });
                    }

                    if (item === 19) {
                        initColumns.push({
                            field: 'modification',
                            filterOperators: dateOperators('date'),
                            key: 'modification',
                            headerName: t('common:pluglist.header-edited'),
                            resizable: true,
                            sortable : true,
                            item : 19,
                            headerAlign: 'left',
                            hide: true,
                            renderHeader: headerSpecialTitleView,
                            renderCell : dateAction,
                            align : 'center',
                            special : true,
                            type: 'date',
                            width: size,
                        });
                    }
                }
            }
        })

        initColumns.push({
            field: 'actions',
            key: 'actions',
            headerName:  'Actions',
            resizable: false,
            sortable : false,
            order : false,
            renderHeader: headerActionsTitleView,
            renderCell : allActions,
            width: 175,
            align : 'center'
        });

        plugnotes.forEach((plugnote, index) => {
            let finalObject = {};
            finalObject['id'] =  plugnote.id;
            finalObject.userindex = plugnote.userindex;

            defaultSelectedFields.forEach((item, index) => {
                if (item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ) {
                    if (props.model['field' + item + 'label'] !== null) {
                        let value = plugnote['field' + item];
                        if (plugnote['field' + item + 'format'] === 'select') {
                            try {
                                let values = JSON.parse(plugnote['field' + item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field' + item];
                            }
                        }
                        else if (plugnote['field' + item + 'format'] === 'list') {
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            }else if (plugValue == null || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if (plugnote['field' + item + 'format'] === 'table') {
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if (plugnote['field' + item + 'format'] === 'plugform') {
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if (plugnote['field' + item + 'format'] === 'qrcode') {
                            if (typeof value !== 'undefined' && value.length > 0) {
                                let qrCode = "";
                                let comment = "";
                                if (value.startsWith(';')) {
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }
                                else {
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if (qrCode !== undefined && qrCode.length > 0) {
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')) {
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if (comment !== undefined && comment.length > 0) {
                                    value = comment;
                                }

                                if (qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0) {
                                    value = '(' + qrCode.toUpperCase() + ')' + ' ' + comment;
                                }
                            }

                        }
                        else if (plugnote['field' + item + 'format'] === 'qrcodeint') {

                            if (typeof value !== 'undefined' && value.length > 0) {
                                let qrcodeIntValue = parseJson(plugnote['field' + item]);
                                let modelClef = qrcodeIntValue?.model || "";
                                let dataIndex = qrcodeIntValue?.data || "";
                                let comment = qrcodeIntValue?.comment || "";

                                if(comment !== ""){
                                    value = comment;
                                }else if (modelClef != "" && dataIndex != "") {
                                    value = modelClef + ' - ' + dataIndex;
                                }else {
                                    value = ""
                                }
                            }

                        }
                        else if (plugnote['field' + item + 'format'] === 'password') {
                            if (typeof value !== "undefined"  && value.length > 0) {
                                value = '*'.repeat(6);
                            }else {
                                value = "";
                            }
                        }
                        else if (plugnote['field' + item + 'format'] === 'glocalis') {
                            if (typeof value !== "undefined"  && value.length > 0 && isJsonStringObject(value)) {
                                let tmpValue = JSON.parse(value);
                                value = 'lnt : ' + tmpValue?.longitude +  ' lat : ' + tmpValue?.latitude;
                            }else {
                                value = "";
                            }
                        }
                        else if (plugnote['field' + item + 'format'] === 'intdatamaj') {
                            if (typeof value !== "undefined"  && value.length > 0 && isJsonStringObject(value)) {
                                let tmpValue = JSON.parse(value);
                                value = 'TO : ' + tmpValue?.value +  ' AT : ' + tmpValue?.updatedDate;
                            }else {
                                value = "";
                            }
                        }
                        else if (plugnote['field' + item + 'format'] === 'boolean') {
                            value = (value === "1");
                        }
                        finalObject['field' + item] = value;
                    }
                }
            })

            let sharedInfoUser = "";

            if (plugnote.shared) {
                sharedInfoUser =  plugnote.username;
            }

            if (plugnote.external) {
                sharedInfoUser =  plugnote.email;
            }

            finalObject['plugnote'] =  plugnote;
            finalObject['hash'] =  plugnote.hash;
            finalObject['isarchived'] =  plugnote.isarchived;
            finalObject['isexported'] =  plugnote.isexported;
            finalObject['plugcode'] = _getCodePnPpCodeTemplate(plugnote);
            finalObject['annexe'] = plugnote.numannexes;
            finalObject['tiers'] = sharedInfoUser;
            finalObject['creation'] = plugnote.creationdate ? moment(plugnote.creationdate).format(i18n.language.includes('en')? 'YY-MM-DD' : 'DD-MM-YY') : '';
            finalObject['modification'] = plugnote.moddate ? moment(plugnote.moddate).format(i18n.language.includes('en')? 'YY-MM-DD' : 'DD-MM-YY') : '';


            if (favoris === false) {
                if (actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true) && !plugnote.isexported) {

                    initialRows.push(finalObject);
                }
                else if (actif === true && archived === false && plugnote.isarchived === false && !plugnote.isexported) {

                    initialRows.push(finalObject);
                }
                else if (actif === false && archived === true && plugnote.isarchived === true && !plugnote.isexported) {

                    initialRows.push(finalObject);
                }
                else if (actif === false && archived === false && exported === true && plugnote.isarchived === false  && plugnote.isexported) {

                    initialRows.push(finalObject);
                }
            }
            else {
                if (actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true) && plugnote.favorite === 1  && !plugnote.isexported) {
                    initialRows.push(finalObject);
                }
                else if (actif === true && archived === false && plugnote.isarchived === false  && plugnote.favorite === 1  && !plugnote.isexported) {
                    initialRows.push(finalObject);
                }
                else if (actif === false && archived === true && plugnote.isarchived === true  && plugnote.favorite === 1  && !plugnote.isexported) {
                    initialRows.push(finalObject);
                }
            }
        });
    }

    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    function handleColumnsReorder(object) {
        setSourceColumnIndex(object.oldIndex);
        setTargetColumnIndex(object.targetIndex);
        setOndrag(false);
    }

    function handleColumnsResize(object) {
        let findIndex = sizeFields.findIndex(item  => item.field === object.colDef.item);
        if (findIndex !== -1) {
            sizeFields[findIndex].width = object.width;
            let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

            if (!checkExistingSizeFields) {

                if ((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))) {
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : sizeFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSize(fieldByModel);
                }
            }
            else {
                let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
                if (findIndex !== -1) {
                    if ((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))) {
                        let fieldByModel = {
                            'hash' : props.model.hash,
                            'fields' : sizeFields,
                            'environnement' : environment.userhash,
                            'date' : moment().format('YYYY/MM/DD'),
                        };

                        setFieldsSize(fieldByModel);
                    }else {
                        delSpecifiFieldsSize(props.model.hash);
                    }
                }
                else {
                    if ((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))) {
                        let fieldByModel = {
                            'hash' : props.model.hash,
                            'fields' : sizeFields,
                            'environnement' : environment.userhash,
                            'date' : moment().format('YYYY/MM/DD'),
                        };

                        setFieldsSize(fieldByModel);
                    }
                }
            }
        }

    }

    function handleColumnsVisibility (object) {
        if (typeof object.colDef.item !== "undefined" && object.isVisible === false) {
            let hidden = {
                "field": object.field,
                "isVisible": true,
                "colDef": object.colDef
            }
            let hiddens = [...hiddenFields]
            hiddens.push(hidden)
            setSelectedFields(selectedFields.filter((e)=>(e !== object.colDef.item)));
            // console.log('to hide :', hidden);
            setHiddenFields(hiddens)
        }
        else if (typeof object.colDef.item !== "undefined" && object.isVisible === true) {
            setSelectedFields([...selectedFields, object.colDef.item]);
            let hiddens = [...hiddenFields]
            hiddens = hiddens.filter((e)=>(e !== object.colDef.item))
            setHiddenFields(hiddens)
        }
    }

    const handleClose = (info , value, format, valueFormatted, type) => {
        switch (format) {
            case "textarea":
                setOpenMemo(false);
                break;
            case "agenda":
                setOpenAgenda(false);
                break;
            case "localis":
                setOpenLocalis(false);
                break;
            case "password":
                setOpenPassword(false);
                break;
            case "time":
                setOpenTime(false);
                break;
            case "list":
                setOpenList(false);
                break;
            case "birthday":
                setOpenBirthday(false);
                break;
            case "qrcode":
                setOpenQrcode(false);
                break;
            case "table":
                if (typeof type !== "undefined") {
                    if (type === "simple") {
                        setOpenSimpleTable(false);
                    }
                    else if (type === "multiple") {
                        setOpenMultipleTable(false);
                    }
                }
                break;
            case "plugform":
                setOpenPlugform(false);
                break;

            default:
                break;
            }
        setInfos(null);
    };

    const handleUpdateClose = (infos , value, format, valueFormatted, type) => {
        switch (format) {
            case "textarea":
                setOpenMemo(false);
                break;
            case "agenda":
                setOpenAgenda(false);
                break;
            case "localis":
                setOpenLocalis(false);
                break;
            case "password":
                setOpenPassword(false);
                break;
            case "time":
                setOpenTime(false);
                break;
            case "list":
                setOpenList(false);
                break;
            case "birthday":
                setOpenBirthday(false);
                break;
            case "qrcode":
                setOpenQrcode(false);
                break;
            case "table":
                if (typeof type !== "undefined") {
                    if (type === "simple") {
                        setOpenSimpleTable(false);
                    }else if (type === "multiple") {
                        setOpenMultipleTable(false);
                    }
                }
                break;
            case "plugform":
                setOpenPlugform(false);
                break;

            default:
                break;
        }

        const {id, field} = infos;

        const updatedRows = rows.map((row) => {
            let fieldName = field;
            if (row.hash === id ) {
                if (typeof row.plugnote[fieldName + 'format'] === "undefined" || row.plugnote[fieldName + 'format']  ===  null) {
                    row.plugnote[fieldName + 'format'] = format;
                }

                if (row.plugnote[fieldName + 'format'] === 'qrcode') {
                    row.plugnote[fieldName]  = valueFormatted;
                }
                else if (row.plugnote[fieldName + 'format'] === 'list') {
                    row.plugnote[fieldName]  = valueFormatted;
                }
                else {
                    row.plugnote[fieldName]  = value;
                }
                if (row.plugnote[fieldName + 'format'] !== 'qrcode' && row.plugnote[fieldName + 'format'] !== 'list') {
                    attemptUpdatePlugnoteField(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : value
                    });
                }
                else {
                    attemptUpdatePlugnoteField(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : valueFormatted
                    });
                }

                if (row.plugnote[fieldName + 'format'] !== 'password') {
                    return { ...row,
                        [fieldName]: value,
                        plugnote : row.plugnote
                    };
                }
                else {
                    return {
                        ...row,
                        [fieldName] : value.length > 0 ?  '*'.repeat(6) : "",
                        plugnote : row.plugnote
                    }
                }
            }
            return row;
        });
        setRows(updatedRows);
        setRowsCopy(updatedRows);
        setInfos(null);
    };

    function oldHandleColumnsReorder(sourceKey, targetKey) {

        const sourceColumnIndex = initColumns.findIndex(c => c.key === sourceKey);
        const targetColumnIndex = initColumns.findIndex(c => c.key === targetKey);

        const sourceColumnSelectedFieldsIndex = selectedFields.findIndex(c => c === initColumns[sourceColumnIndex].item);
        const targetColumnSelectedFieldsIndex = selectedFields.findIndex(c => c === initColumns[targetColumnIndex].item);
        const reorderedSelectedFieldsColumns = [...selectedFields];

        reorderedSelectedFieldsColumns.splice(
            targetColumnSelectedFieldsIndex,
            0,
            reorderedSelectedFieldsColumns.splice(sourceColumnSelectedFieldsIndex, 1)[0]
        );


        setSelectedFields(reorderedSelectedFieldsColumns);

    }

    useEffect(() => {
        setRows(initialRows);
        setRowsCopy(initialRows);
        setRowCount(initialRows.length);
    }, [favoris, actif, archived, plugnotes]);

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if (startPage < limitPage) {
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const fetchDatas = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        if (nextPage < limitPage) {
            setHasMore(true);
        }else {
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByModelHashAndUserHash(modelhash, hash, nextPage,exported);

    };

    const loadServerRows = () => {
        setLoading(true);
        let nextPage = page + 1;
        setPage(nextPage);
        if (nextPage < limitPage) {
            setHasMore(true);
        }else {
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByModelHashAndUserHash(modelhash, hash, nextPage,exported);
    };

    const handleEditCellChange = React.useCallback(
        ({ id, field, props }) => {
            let format = infoCurrentModel[field + 'format'];
            let fieldName = field;
            let data = props; // Fix eslint value is missing in prop-types for JS files
            let isValid = true;
            let newState = {};

            switch (format) {
                case 'boolean':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, value : data.value, type : 'boolean', stringValue :  data.value ? "1" : "", format : 'boolean'},
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'mail':
                    isValid = validateEmail(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'mail' },
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'date':
                    let date = data !==  null && data.value !== null ? moment(new Date(data.value.toString())).format('YYYY-MM-DD') : "";
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: false, value : date ,format : 'date'},
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'phone':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, format : 'phone' }
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'text':
                    isValid = validateText(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'text' },
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'numeric':
                    isValid = validateNumber(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'numeric' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'url':
                    isValid = validateUrl(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid,format : 'url' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'select':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, format : 'select' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                default:
                    break;
            }
        },
        [editRowsModel],
    );

    const handleEditCellChangeCommitted = useCallback(
        ({ id, field, props }) => {
            const updatedRows = rows.map((row) => {
                let fieldName = field;
                const data = props; // Fix eslint value is missing in prop-types for JS files

                if (typeof data !== 'undefined') {
                    if (row.hash === id && typeof data.type === "undefined" && editableDirectFields.includes(data.format)) {

                        row.plugnote[fieldName]  = data.value;

                        attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                            field : fieldName,
                            value : data.value
                        });

                        return { ...row,
                            [fieldName]: data.value,
                            plugnote : row.plugnote
                        };
                    }
                    else if (row.hash === id && typeof data.type !== "undefined" && editableDirectFields.includes(data.format)) {

                        switch (data.type) {
                            case 'boolean' :
                                row.plugnote[fieldName] = data.stringValue;

                                attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                                    field : fieldName,
                                    value : data.value
                                });

                                return { ...row,
                                    [fieldName]: data.value,
                                    plugnote : row.plugnote
                                };
                                break;

                            default:
                                row.plugnote[fieldName] = data.arrayStringValue;

                                attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                                    field : fieldName,
                                    value : data.arrayStringValue
                                });

                                return { ...row,
                                    [fieldName]: data.type === "single-select" ? data.value : data.arrayValue.join(", "),
                                    plugnote : row.plugnote
                                };
                                break;
                        }
                    }
                }
                return row;
            });
            setRows(updatedRows);
            setRowsCopy(updatedRows);
        },
        [rows],
    );

    const handleEditCellChangeCommittedManuel = async ({ id, field, props }) => {
        let rowsCopies = await getDatasCopy();
        const updatedRows = rowsCopies.map((row) => {

            let fieldName = field;
            const data = props; // Fix eslint value is missing in prop-types for JS files

            if (typeof data !== 'undefined') {
                if (row.hash === id && typeof data.type === "undefined" && editableDirectFields.includes(data.format)) {

                    row.plugnote[fieldName]  = "";

                    /*  attemptUpdatePlugnoteFieldManual(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : ""
                    });*/

                    return { ...row,
                        [fieldName]: "",
                        plugnote : row.plugnote
                    };
                }
                else if (row.hash === id && typeof data.type !== "undefined" && editableDirectFields.includes(data.format)) {

                    switch (data.type) {
                        case 'boolean' :
                            row.plugnote[fieldName] = "";

                            /*  attemptUpdatePlugnoteFieldManual(props.model.hash, row.plugnote.hash, environment.userhash, {
                                field : fieldName,
                                value : ""
                            });*/

                            return { ...row,
                                [fieldName]: "",
                                plugnote : row.plugnote
                            };
                            break;

                        default:
                            row.plugnote[fieldName] = "";

                            /*  attemptUpdatePlugnoteFieldManual(props.model.hash, row.plugnote.hash, environment.userhash, {
                                field : fieldName,
                                value : ""
                            });*/

                            return { ...row,
                                [fieldName]: data.type === "single-select" ? data.value : data.arrayValue.join(", "),
                                plugnote : row.plugnote
                            };
                            break;
                    }
                }
            }
            return row;
        });

        const tmpObject = [];

        filteredRows.map(r=> tmpObject.push(r.hash));

        let filteredRowsUpdated = updatedRows.filter((item) => {
            return tmpObject.includes(item.hash)
        })

        return [filteredRowsUpdated, updatedRows];
    };

    const handleEditCellChangeCommittedPreviewManuel = async (plugs, fieldFiltered) => {
        let rowsCopies = await getDatasCopy();
        const updatedRows = rowsCopies.map((row) => {
            if (plugs.includes(row.hash)) {
                let fieldName = 'field' + fieldFiltered;
                row.plugnote[fieldName]  = "";
                attemptUpdatePlugnoteFieldManual(props.model.hash, row.plugnote.hash, environment.userhash, {
                    field : fieldName,
                    value : ""
                });
                return { ...row,
                    [fieldName]: "",
                    plugnote : row.plugnote
                };
            }
            return row;
        });

        const tmpObject = [];

        filteredRows.map(r=> tmpObject.push(r.hash));

        let filteredRowsUpdated = updatedRows.filter((item) => {
            return tmpObject.includes(item.hash)
        })

        setRows(filteredRowsUpdated);
        setRowsCopy(updatedRows)

        return true;
    };

    const handleBlur = useCallback((params, event) => {
        if (params.cellMode === "edit" && params.colDef.format === "select") {
            event.stopPropagation();
        }
    }, []);

    const getDatasCopy = async () => {
        return [...rowsCopy]
    }

    const handleSelectedPlugFix = async (event) => {

        event.preventDefault();
        event.stopPropagation();
        confirmAlert({

        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body-copy">
                    <h1>{t('global.confirm')}</h1>
                    <p>{t('plugnote.plugnote-confirm-delete-fields')}</p>
                    <div className="react-confirm-alert-button-group" style={{justifyContent : 'center'}}>
                        <button
                            className="text_align_left"
                            onClick={async () => {
                                if (filteredRows.length > 0) {

                                    let plugs = [];
                                    let rowCopyUpdated = await getDatasCopy();
                                    filteredRows.map((item) => {
                                        let plug = rowCopyUpdated.find((rowCopy) => {
                                            return rowCopy.hash.toString() === item.hash.toString();
                                        })

                                        if (typeof plug !== "undefined") {
                                            plugs.push(plug.hash);
                                        }
                                    })

                                    if (plugs.length > 0) {
                                        await handleEditCellChangeCommittedPreviewManuel(plugs, fieldFiltered);
                                    }
                                }
                                onClose();
                            }}
                        >
                            <span>{t('global.yes')}</span>
                        </button>
                        <button
                            className="text_align_left"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <span>{t('global.cancel')}</span>
                        </button>
                    </div>
                </div>
            );
        }
        });

    }

    const handleFilterAndDelete = (event,object, item) => {
        event.preventDefault();
        event.stopPropagation();

        const tmpObject = [];

        object.map(r=> tmpObject.push(r.hash));

        let filteredRowsToView = rowsCopy.filter((item) => {
            return tmpObject.includes(item.hash)
        })

        setFieldFiltered(item);
        setFilteredRows(filteredRowsToView);
        setFiltered(true);
    }

    const handleFilterAndDeleteDisabled = (event,object, item) => {
        event.preventDefault();
        event.stopPropagation();

        setFieldFiltered();
        setFilteredRows(rowsCopy);
        setFiltered(false);
    }

    function handleDoubleClick (object) {
        //agenda,localis, password,time,list,birthday,qrcode,table,plugform,pluglink
        // console.log('clicked object',object);
        switch (object.colDef.format) {
            case "textarea":
                setOpenMemo(true);
                setInfos(object);
                break;
            case "agenda":
                setOpenAgenda(true);
                setInfos(object);
                break;
            case "localis":
                setOpenLocalis(true);
                setInfos(object);
                break;
            case "password":
                setOpenPassword(true);
                setInfos(object);
                break;
            case "time":
                setOpenTime(true);
                setInfos(object);
                break;
            case "list":
                setOpenList(true);
                setInfos(object);
                break;
            case "birthday":
                setOpenBirthday(true);
                setInfos(object);
                break;
            case "qrcode":
                setOpenQrcode(true);
                setInfos(object);
                break;
            case "table":
                //setOpenTable(true);
                setInfos(object);
                break;
            /*
            case "plugform":
                setOpenPlugform(true);
                setInfos(object);
                break;
            */
            case "tablemulti":
            case "tablemultin":
            case "document":
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (apiRef.current !== null && typeof apiRef.current.subscribeEvent !== "undefined") {
            return apiRef.current.subscribeEvent(
                 GRID_COLUMN_HEADER_DRAG_END,
                (params, event) => {
                    setOndrag(null);
                },
            );
        }
    }, [apiRef]);

    useEffect(() => {
        if (onDrag === false && sourceColumnIndex !== -1 && targetColumnIndex !== -1) {
            const sourceColumnIndexSource = sourceColumnIndex - 1;
            const targetColumnIndexSource = targetColumnIndex - 1;

            if (sourceColumnIndexSource !== null && targetColumnIndexSource !== null) {
                const sourceColumnSelectedFieldsIndex = selectedFields.findIndex(c => typeof initColumns[sourceColumnIndexSource] !== "undefined" && c === initColumns[sourceColumnIndexSource].item);
                const targetColumnSelectedFieldsIndex = selectedFields.findIndex(c => typeof initColumns[targetColumnIndexSource] !== "undefined" && c === initColumns[targetColumnIndexSource].item);

                if (sourceColumnSelectedFieldsIndex !== -1 && targetColumnSelectedFieldsIndex !== -1) {
                    const reorderedSelectedFieldsColumns = [...selectedFields];

                    reorderedSelectedFieldsColumns.splice(
                        targetColumnSelectedFieldsIndex,
                        0,
                        reorderedSelectedFieldsColumns.splice(sourceColumnSelectedFieldsIndex, 1)[0]
                    );

                    setSelectedFields(reorderedSelectedFieldsColumns);
                }

                setSourceColumnIndex(null);
                setTargetColumnIndex(null);
                setOndrag(null);
            }
        }
    }, [onDrag]);

    useEffect(() => {
        if (apiRef.current !== null && typeof apiRef.current.subscribeEvent !== "undefined") {
            return apiRef.current.subscribeEvent(
                 GRID_COLUMN_HEADER_DRAG_START,
                (params, event) => {
                    setOndrag(true)
                },
            );
        }
        // if (apiRef !== null) setTimeout(() => scrollGrid(), 1500)
    }, [apiRef]);

    useEffect(() => {
        if (filtered === true) {
            setRows(filteredRows);
            setColumns(initColumns);
            let filteredFilterRows = [];
            filteredRows.map((filteredRow) => filteredFilterRows.push(filteredRow.hash));
            setSelectedRows(filteredFilterRows);
        }
        else if (filtered === false) {
            setFiltered(null);
            setFieldFiltered();
            setRows(filteredRows);
            setColumns(initColumns);
            setSelectedRows([]);
        }
        else if (filtered === null) {
            setFieldFiltered();
            setRows(rowsCopy);
            setColumns(initColumns);
            setSelectedRows([]);
        }
        // exclude 'view' column from filtering
        let unfilteredIndex = columns.map(column => column.key).indexOf('view')
        if (unfilteredIndex !== -1) {
            columns[unfilteredIndex].filterable = false;
        }
    }, [filtered])

    // nouvel affichage des sélections : directement dans la 1ère modale
    const openSelectionList = () => {
        // setLoading(true)
        setOpenSelections(true)
    }

    const handleSave = (overwrite) => {
        let payload
        if (savedFilter !== null) payload = {
            clef : environment.envIndex + '-' + props.model.clef,
            description : savedFilter.description,
            filters : filterSet,
            id : !!overwrite ?? savedFilter.id,
            label: selectionLabel,
            modelhash : savedFilter.model,
            text : selectionLabel,
            userhash : environment.userhash,
        }
        else payload = {
            authorName: {hash: environment.userhash},
            clef : environment.envIndex + '-' + props.model.clef,
            description: "",
            filters : filterSet,
            label: selectionLabel,
            linkOperator: linkOperator,
            model: props.model,
            modelhash: props.model.hash,
            text : selectionLabel,
            userhash : environment.userhash,
        }
        try {props.attempListFilterSaveByModel(payload);}
        catch(e) {console.log(e)}
        if (savedFilter !== null && !!overwrite) props.attemptDeleteFilter({id: savedFilter.id})
        setCurrentFilter(payload)
        setHasChanged(false)
        setOpenSelections(false)
        setRetrieved(false)
        setFromServer(true)
        let objSelection = objectifySelection()
        objSelection['saved'] = true
        setSelection(objSelection)
        apiRef.current.hideFilterPanel()
    }

    const handleOverwrite = () => {
        handleSave(true)
    }

    const handleDeleteCurrentFilter = () => {
        delSelection()
        setTimeout(() => {
            setCurrentFilter(null);
            setValidFilter(false);
            setFromServer(false)
            setFilterSet([])
            setSelectionLabel(t('common:filters.new-selection'))
        }, 300)
    }

    function checkValidFilter() {
        if (filterSet.length > 0) {
            let invalidFilter = true
            Object.keys(filterSet).forEach(obj => {
                switch (filterSet[obj]["columnField"]) {
                    case 'view':
                        setValidFilter(false);
                        invalidFilter = true;
                        return;
                    default:
                        if (filterSet[obj]["operatorValue"] === 'isNotEmpty' || filterSet[obj]["operatorValue"] === 'isEmpty') {invalidFilter = false}
                        else if (filterSet[obj]["value"] !== undefined && filterSet[obj]["value"] !== "") {
                            invalidFilter = false;
                            setValidFilter(false);
                            return;
                        }
                        break;
                }
            })
            if (invalidFilter) {
                setValidFilter(false);
            }
            else setValidFilter(true)
        }
        else setValidFilter(false)
    }

    function deleteFilters () {
        setFilterSet([])
        setCurrentFilter(null)
        setSelectionLabel(t("common:filters.new-selection"))
        delSelection()
    }

    function handleOpenFilterPanel() {
        setFilterIsOpen(true)
        apiRef.current.showFilterPanel()
    }

    const handleModelChange = (model) => {
        setFilterSet(model.items);
        setHasChanged(true)
        if (model.linkOperator !== undefined) setLink(model.linkOperator);
        else setLink('and')
    }

    const objectifySelection = () => {
        let _selection = {}
        for(let i = 0; i < filterSet.length; i ++ ) {
            _selection[i] = filterSet[i]
        }
        _selection['linkOperator'] = linkOperator
        _selection['folder'] = modelhash
        if (savedFilter !== null) {
            _selection['label'] = savedFilter.label;
            hasChanged ? _selection['saved'] = false : _selection['saved'] = true
        }
        else {
            _selection['label'] = t("common:filters.new-selection")
            _selection['saved'] = false
        }
        return _selection
    }

    const parseSessionStorageSelection = () => {
        let sel = getSelection()
        let savedSel = {}
        savedSel['label'] = sel['label']
        setCurrentFilter(savedSel)
        if (sel['folder'] === modelhash) {
            let selArray = []
            Object.keys(sel).forEach((key, index) => {
                let parsed = parseInt(key)
                if (!isNaN(parsed)) selArray.push(sel[key])
            })
            setFilterSet(selArray)
        }
    }

    useEffect(() => {
        let parameters = parseFilterParameters(parseModel(props.model), filterSet)
        setNewName(parameters)
    }, [filterSet])

    const CustomToolbar = () => {
        return (
            <div className={classes.toolbarContainer}>
                <GridToolbarContainer>
                    <ActionButtons
                        apiRef={apiRef}
                        filterSet={filterSet}
                        handleDeleteCurrentFilter={handleDeleteCurrentFilter}
                        handleOpenFilterPanel={handleOpenFilterPanel}
                        handleSave={handleSave}
                        hasChanged={hasChanged}
                        model={props.model}
                        newName={selectionParams}
                        openSelections={openSelections}
                        openSelectionList={openSelectionList}
                        plugnotes={plugnotes}
                        savedFilter={savedFilter}
                        selectionLabel={selectionLabel}
                        setSelectionName={setSelectionLabel}
                        setOpenSelections={setOpenSelections}
                    />

                    {
                        openSelections &&
                        <FilterSelectionList
                            clef={environment.envIndex + '-' + props.model.clef}
                            deleteFilters={deleteFilters}
                            filterIsOpen={apiRef?.current?.state?.preferencePanel.open}
                            filterLink={linkOperator}
                            filterSet={filterSet}
                            handleOpenFilterPanel={handleOpenFilterPanel}
                            model={props.model}
                            modelHash={props.model.hash}
                            newFilter={filterIsValid && !isFromServer ? filterSet : null}
                            newName={selectionParams}
                            openSelectionList={openSelectionList}
                            retrieved={retrieved}
                            selectionLabel={selectionLabel}
                            setFilterSet={setFilterSet}
                            setLink={setLink}
                            setRetrieved={setRetrieved}
                            setSelectionLabel={setSelectionLabel}
                            setFromServer={setFromServer}
                            close={(filterObj) => {
                                setOpenSelections(false);
                                if (filterObj !== null && filterObj !== undefined) {
                                    setCurrentFilter(filterObj)
                                    setNewName(filterObj.text)
                                    setSelectionLabel(filterObj.label)
                                    setFromServer(true);
                                };
                                setRetrieved(false)
                                setHasChanged(false)
                            }}
                            open={openSelectionList}/>
                    }
                </GridToolbarContainer>
                {
                    totals.length > 0 ?
                    <div className={classes.actionBtn} onClick={toggleTotal}>{t('pluglist.totals')}</div>
                    : null
                }
                {
                    showTotals &&
                    <ModalTotalListComponent
                        handleToggleTotal={handleToggleTotal}
                        numericColumns={numericColumns}
                        onClose={toggleTotal}
                        totals={totals}
                        />
                }
            </div>
        );
    };

    const scrollGrid = () => {
        let topRow = getTopRow()
        if (topRow !== null && apiRef?.current !== null) {
            if (topRow.folder === modelhash) {
                apiRef.current.scroll(topRow.position)
                // apiRef.current.selectRow(topRow.rowId, true, false)
            }
        }
    }

    const CustomFooter = () => {
        const [showList, setShowList] = useState(false)
        return (
        <div className={classes.footerCtn}>
            {hiddenFields.length > 0 &&
            showList?
            <div className={classes.hiddenFieldsContainer}>
                <span>Hidden fields: </span>
                {hiddenFields.map((field) =>
                    {let fieldName = field.colDef.headerNameCustom ? field.colDef.headerNameCustom : field.colDef.headerName
                    return(
                        <span
                            className={classes.hiddenFieldItemContainer}
                            key={'field-' + field.colDef.item}
                            onClick={() => {
                                console.log('clicked: ', field);
                                console.log('fields', fields);
                                handleColumnsVisibility(field)
                            }}
                        >{fieldName}
                        </span>
                    )
                    }
                )}
                <Tooltip placement='top' title='Close'>
                    <span className={`material-icons md-25`}
                        onClick={() => setShowList(false)}
                        >
                        highlight_off
                    </span>
                </Tooltip>
            </div> :
            <Tooltip placement='top' title='Hidden columns'>
                <span className={`material-icons md-25`}
                    onClick={() => setShowList(true)}
                    >view_week</span>
            </Tooltip>
            }
        </div>
        )
    }

    useEffect(() => {
        checkValidFilter()
        if (filterSet.length > 0) {
            let objSelection = objectifySelection()
            setSelection(objSelection)
        }
    }, [filterSet])

    useEffect(() => {
        if (filterSet.length === 0) {
            parseSessionStorageSelection()
        }
        if (apiRef?.current !== null) setTimeout(() => {
            scrollGrid()
        }, 1200)
    }, [])

    const storeSelectedRow = (e, params) => {
        let position = apiRef.current.getScrollPosition()
        let rowId = params.id
        let row = {
            folder: modelhash,
            position: position,
            rowId: rowId
        }
        setTopRow(row)
        apiRef.current.selectRow(rowId, true, false)
    }
    return (
        <Box
            className="grid-container"
            sx={{
                '& .recent': {backgroundColor : `#51c3c13c !important`}
            }}
            >
            <DesignedGrid
                {...props}
                apiRef={apiRef}
                checkboxSelection={true}
                classes={gridClasses}
                className={classes.grid}
                columns={columns}
                components={{
                    ColumnMenuIcon: CustomMenuIcon,
                    ColumnResizeIcon: CustomResizeIcon,
                    Footer: CustomFooter,
                    FilterPanel: CustomFilterPanel,
                    Toolbar: CustomToolbar,
                }}
                componentsProps={{
                    filterPanel: {
                        environment: environment,
                        fields: fields,
                        filterSet: filterSet,
                        handleModelChange: handleModelChange,
                        handleOverwrite: handleOverwrite,
                        handleSave: handleSave,
                        hasChanged: hasChanged,
                        linkOperator: linkOperator,
                        params: selectionParams,
                        newFilter: filterIsValid && !isFromServer ? filterSet : null,
                        openSelectionList: openSelectionList,
                        savedFilter: savedFilter,
                        selectionLabel: selectionLabel,
                        setName: setNewName,
                        setSelectionLabel: setSelectionLabel,
                        validFilter: filterIsValid,
                    }
                }}
                disableSelectionOnClick={true}
                editRowsModel={editRowsModel}
                getRowClassName={(params) => {
                    if (recent !== null && params.id === recent.rowId) {
                        return 'recent'
                    }
                }}
                getRowId={(row) => row.hash || ''}
                filterModel={{
                    items: filterSet,
                    linkOperator: linkOperator
                }}
                hideFooterPagination
                loading={false}
                // hideFooter={true}
                onCellBlur={handleBlur}
                onCellClick={(params, e)=> storeSelectedRow(e, params)}
                onCellDoubleClick={handleDoubleClick}
                onEditCellChange={handleEditCellChange}
                onColumnOrderChange={handleColumnsReorder}
                onColumnVisibilityChange={handleColumnsVisibility}
                onColumnResizeCommitted={handleColumnsResize}
                onEditCellChangeCommitted={handleEditCellChangeCommitted}
                onFilterModelChange={(model) => handleModelChange(model)}
                onRowsScrollEnd={hasMore && props.globalApiDataFilter === null ? loadServerRows : null}
                onSelectionModelChange={(newSelection) => {setSelectedRows(newSelection)}}
                {...rows}
                rows={rows || []}
                rowHeight={50}
                selectionModel={selectedRows}
                showCellRightBorder={true}
            />

            {infos !== null && openMemo &&
            <SimpleMemoDialog
                value={infos.value}
                infos={infos}
                open={openMemo}
                onUpdateClose={handleUpdateClose}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                style={{ zIndex: 0 }}
                props={props}
            />
            }

            {
                infos !== null && openLocalis &&
                <SimpleLocalisDialog
                    value={infos.value}
                    infos={infos}
                    open={openLocalis}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openAgenda &&
                <SimpleAgendaDialog
                    value={infos.value}
                    infos={infos}
                    open={openAgenda}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openList &&
                <SimpleListDialog
                    value={infos.value}
                    infos={infos}
                    open={openList}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openPassword &&
                <SimplePasswordDialog
                    value={infos.value}
                    infos={infos}
                    open={openPassword}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openBirthday &&
                <SimpleBirthdayDialog
                    value={infos.value}
                    infos={infos}
                    open={openBirthday}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openQrcode &&
                <SimpleQrcodeDialog
                    value={infos.value}
                    infos={infos}
                    open={openQrcode}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {infos !== null && openPlugform &&
                <SimplePlugformDialog
                    value={infos.value}
                    infos={infos}
                    open={openPlugform}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}

                />
            }

            {infos !== null && openSimpleTable &&
                <SimpleSimpleTableDialog
                    value={infos.value}
                    infos={infos}
                    open={openSimpleTable}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {infos !== null && openMultipleTable &&
                <SimpleMultipleTableDialog
                    value={infos.value}
                    infos={infos}
                    open={openMultipleTable}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openTime &&
                <SimpleTimeDialog
                    props={props}
                    value={infos.value}
                    infos={infos}
                    open={openTime}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                />
            }
        </Box>
    );
};

function PlugListComponent(props) {
    const { t, i18n } = useTranslation(['common', 'models', 'global']);
    const classes = useStyles();
    const { promiseInProgress } = usePromiseTracker();

    const [modelBaseInfo, setModelBaseInfo] = useState(null);

    const user = getUser();
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(environment.userhash);
    const token = getToken();

    useEffect(() => {
        if (props.modelInfo !== null) {
            setModelBaseInfo(props.modelInfo)
        }
    }, [props.modelInfo]);

    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [subOpen, setSubOpen] = useState(false);
    const [archived, setArchived] = useState(false);
    const [actif, setActif] = useState(getTypeOfPlugnote() !== 'exported');
    const [exported, setExported] = useState(getTypeOfPlugnote() === 'exported');
    const [favoris, setFavoris] = useState(false);
    const [countArchived, setCountArchived] = useState(0);
    const [countFavorites, setCountFavorite] = useState(0);
    const [countActive, setCountActive] = useState(0);
    const [countExported, setCountExported] = useState(0);
    const [payload, setPayload] = useState(null);
    const [fields, setFields] = useState(() => {
        let currentModel = props.model;
        if (currentModel !== 'undefined' && currentModel !== null) {
            const labels = Object.keys(currentModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });

            let itemFields = labels.filter(Boolean);

            let currentItemFields = itemFields.map((item) => {
                return currentModel[item];
            });

            return currentItemFields.filter(Boolean);
        }
    });
    const [fieldsInfos, setFieldsInfos] = useState(() => {
        let currentModel = props.model;

        if (currentModel !== 'undefined' && currentModel !== null ) {
            const labels = Object.keys(currentModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key) && currentModel[key] != null;
            });

            let fields = labels.map((label, index) => {
                let formatoptions = currentModel['field' + (index + 1) + 'formatoptions'] || {};
                if (typeof formatoptions !== 'object') {
                    formatoptions = JSON.parse(formatoptions);
                }

                return {
                    key: index + 1,
                    id: index + 1,
                    label: currentModel['field' + (index + 1) + 'label'],
                    format: currentModel['field' + (index + 1) + 'format'],
                    options: {
                        mandatory: !!currentModel['field' + (index + 1) + 'mandatory'],
                        ...formatoptions
                    },
                    formatoptions: currentModel['field' + (index + 1) + 'formatoptions']
                }
            });

            return fields;
        }
    });

    let checkExistingSelection = JSON.parse(localStorage.getItem('fieldsSelected'));
    let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

    let preSelectedFields = [0];
    let preSpecialsSelectedFields = [16, 17, 18, 19, 20];

    let preSizeFields = [{width : 100, field : 0}];
    let preDefaultSizeFields = [{width : 100, field : 0}];
    let preSizeSelectedFields = [
        {width : 150, field : 16},
        {width : 150, field : 17},
        {width : 150, field : 18},
        {width : 150, field : 19},
        {width : 90, field : 20}
    ];
    let preDefaultSizeSelectedFields = [
        {width : 150, field : 16},
        {width : 150, field : 17},
        {width : 150, field : 18},
        {width : 150, field : 19},
        {width : 90, field : 20}
    ];

    if (typeof fields !== "undefined") {
        fields.map((field, Findex) => {
            if (field !== null) {
                preSelectedFields.push(Findex + 1);
                preSizeFields.push({width : 150, field : Findex + 1});
                preDefaultSizeFields.push({width : 150, field : Findex + 1});
            }
        });
    }
    preSelectedFields = preSelectedFields.concat(preSpecialsSelectedFields);
    preSizeFields = preSizeFields.concat(preSizeSelectedFields);
    preDefaultSizeFields = preDefaultSizeFields.concat(preDefaultSizeSelectedFields);

    const [defaultSelectedFields, setDefaultSelectedFields] = useState(preSelectedFields);
    const [defaultSizeFields, setDefaultSizeFields] = useState(preDefaultSizeFields);

    if (checkExistingSelection) {
        let findIndex = checkExistingSelection.findIndex(item  => item.hash === props.model.hash);
        if (findIndex !== -1) {
            preSelectedFields = checkExistingSelection[findIndex].fields;
            if (typeof checkExistingSelection[findIndex].specialFields !== 'undefined') {
                preSelectedFields.concat([0, 16, 17, 18, 19, 20]);
            }
        }
    }

    const [selectedFields, setSelectedFields] = useState(preSelectedFields);

    if (checkExistingSizeFields) {
        let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
        if (findIndex !== -1) {
            preSizeFields = checkExistingSizeFields[findIndex].fields;
        }
    }

    const [sizeFields, setSizeFields] = useState(preSizeFields);
    const [isOpenModalModelPlugcode, setIsOpenModalModelPlugcode] = useState(false);
    const [isOpenModalPlugnotePlugcode, setIsOpenModalPlugnotePlugcode] = useState(false);
    const [isOpenModalModelShare, setIsOpenModalModelShare] = useState(false);
    const [isOpenModalImportExportCsv, setIsOpenModalImportExportCsv] = useState(false);
    const [isOpenModalTemplateImportExportCsv, setIsOpenModalTemplateImportExportCsv] = useState(false);
    const [isOpenModalExportSimpleCsv, setIsOpenModalExportSimpleCsv] = useState(false);
    const [isOpenModalExportTableMultipleSimpleCsv, setIsOpenModalExportTableMultipleSimpleCsv] = useState(false);
    const [isOpenClassify, setIsOpenClassify] = useState(false);
    const [filters, setFilters] = useState([]);
    const [link, setLink] = useState("and")
    const [filterFieldsOpen, setFilterFieldsOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState(() => []);
    // const [plugsSelectAll, setPlugsSelectAll] = useState(false);
    const [plugnotes, setPlugnotes] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(true);
    //const [listView, setListView] = useState(getPlugnoteView() !== 'false');
    const [flipped, setFlipped] = useState(-1);
    const [newFilter, setNewFilter] = useState(null)
    const [filterSet, setFilterSet] = useState([]);
    const [openSaveSelectionFilter, setOpenSaveSelectionFilter] = useState(false);
    const [openLoadSelectionFilter, setOpenLoadSelectionFilter] = useState(false);
    const [openModalHelp, setOpenModalHelp] = useState(false);


    const handleListAnnexes = (plugnote) => {
        setPlugnote(plugnote);
        setAnnexes([]);
        if (!!plugnote.Annexes && plugnote.Annexes.length > 0) {
            setAnnexes(plugnote.Annexes);
        }
        props.history.push('/annexeslist');
    }

    const handleSelectPlugnote = (plugnote) => {
        setModel(props.model);
        props.attemptResetAnnexe();
        props.attemptResetDataPassword();
        setPlugnote(plugnote);
        setAnnexes([]);
        if (!!plugnote.Annexes && plugnote.Annexes.length > 0) {
            setAnnexes(plugnote.Annexes);
        }
        props.history.push('/update-plugnote');
    }

    const handleCreatePlugnote = () => {
        props.attemptResetAnnexe();
        props.attemptResetDataPassword();
        delPlugnote();
        delAnnexes();
        setAnnexes([]);
        setModel(props.model);
        props.history.push('/create-plugnote');
    };

    const handleDeletePlugnote = (item) => {

        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  props.attemptDeletePlugote(item.hash,environment.userhash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleArchivePlugnote = (item) => {
        props.attemptUpdateArchivePlugnote(item.hash, !item.isarchived, item.locked, environment.userhash);
    }

    const handleLockPlugnote = (item) => {
        props.attemptUpdateLockPlugnote(item.hash, item.isarchived, !item.locked, environment.userhash);
    }

    const handleFavorisPlugnote = (item) => {
        props.attemptUpdateFavorisPlugnote(item.hash, !item.favorite, environment.userhash);
    }

    const handleDrawerOpen = () => {
        setSubOpen(false);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSubDrawerClose = () => {
        setSubOpen(false);
        setOpen(false);
    };

    const handleResetPlugSearch = () => {
        props.attemptDelAllDataFilter();
        setFilters([]);
        setSubOpen(false);
        setOpen(false);
    };

    const handleAddFiltersLine = () => {
        setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : null, value : null, query : null}]);
    };

    const handleDelFiltersLine = (indexFilter) => {
        setFilters(filters.filter((item, index) => {
            return indexFilter !== index;
        }));
    };

    const handleChangeView = () => {
        setFilterFieldsOpen(false);
        setListView(!listView);
        setPlugnoteView(!listView);
    }

    const handleFlip = (value, event) => {
        event.preventDefault();
        event.stopPropagation();
        if (flipped === value) {
            setFlipped(-1);
        }else {
            setFlipped(value);
        }
    }

    const handleExitPlugList = () => {
        props.history.push("/models");
        delModel();
        delTopRow()
    }

    const handleModalPlugnotePlugcodeOpen = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(true);
    }

    const handleModalPlugnotePlugcodeClose = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(false);
    };

    const handleModalPlugnoteClassifyClose = () => {
        delPlugnote();
        setIsOpenClassify(false);
    };

    const handleModalPlugnoteClassifyOpen = (item) => {
        setPlugnote(item);
        setIsOpenClassify(true);
    };

    const handleModalModelPlugcodeOpen = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(true);
    };

    const handleGeneratePlugcodeForAllPlugnoteModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('plugcode.model-confirm-multiple-generation'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptGeneratePlugcodeForAllPlugnoteModel(props.model.hash, hash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    }

    const handleModalModelPlugcodeClose = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(false);

    };

    const handleModalModelShareOpen = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(true);
    };

    const handleOpenSubDrawer = () => {
        setOpen(false);
        setSubOpen(true);
    }

    const handleModalModelShareClose = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(false);
    };

    const handleModalExportCsvClose = () => {
        setPayload(null);
        setIsOpenModalExportSimpleCsv(false);
    }

    const handleModalExportTableMultipleCsvClose = () => {
        setPayload(null);
        setIsOpenModalExportTableMultipleSimpleCsv(false);
    }

    const handleModalTemplateImportExportCsvClose = () => {
        setPayload(null);
        setIsOpenModalTemplateImportExportCsv(false);
    }

    const handleModalImportExportCsvClose = () => {
        setIsOpenModalImportExportCsv(false);
    }

    const handleModalTemplateImportExportCsvOpen = () => {

        let filteredPlugList = plugnotes.map(searchData => {
            /*            if (!selectedFields.includes(searchData.userindex)) {
                return searchData.userindex
            }*/
            if (selectedRows.length > 0 && selectedRows.includes(searchData.hash)) {
                //console.log(cleanFilteredPlugList);
                return searchData.userindex
            }
        });

        let cleanFilteredPlugList = filteredPlugList.filter(Boolean);


        let filter = 'all';

        if (!archived && actif) {
            filter = 'isnotarchived';
        }else if (archived && !actif) {
            filter = 'isarchived';
        }else if (!archived && !actif) {
            filter = 'isexported';
        }

        let payload = {
            model : props.model.hash,
            filter : filter,
            plugList : selectedRows.length > 0 ? cleanFilteredPlugList : [],
            plugsSelectAll : selectedRows.length === 0
        };

        setPayload(payload);

        setIsOpenModalTemplateImportExportCsv(true);
    }

    const handleModalImportExportCsvOpen = () => {
        setIsOpenModalImportExportCsv(true);
    }

    const handlePressExportFile = () => {
        props.attemptExportFullFiles(props.model.hash, props.user.user.userhash );
    };

    const handlePressExportCsv = () => {
        let tmpFieldsChecked = Array.from(selectedFields);
        let filteredPlugList = plugnotes.map(searchData => {
            /*            if (!selectedFields.includes(searchData.userindex)) {
                return searchData.userindex
            }*/
            if (selectedRows.length > 0 && selectedRows.includes(searchData.hash)) {
                //console.log(cleanFilteredPlugList);
                return searchData.userindex
            }
        });

        let cleanFilteredPlugList = filteredPlugList.filter(Boolean);


        let filter = 'all';

        if (!archived && actif) {
            filter = 'isnotarchived';
        }else if (archived && !actif) {
            filter = 'isarchived';
        }else if (!archived && !actif) {
            filter = 'isexported';
        }

        let payload = {
            model : props.model.hash,
            field : tmpFieldsChecked,
            filter : filter,
            plugList : selectedRows.length > 0 ? cleanFilteredPlugList : [],
            plugsSelectAll : selectedRows.length === 0
        };

        setPayload(payload);
        setIsOpenModalExportSimpleCsv(true);
    };

    const handlePressExportTableMultipleCsv = () => {

        let tmpFieldsChecked = Array.from(selectedFields);

        let filteredPlugList = plugnotes.map(searchData => {
            /*            if (!selectedFields.includes(searchData.userindex)) {
                return searchData.userindex
            }*/
            if (selectedRows.length > 0 && selectedRows.includes(searchData.hash)) {
                //console.log(cleanFilteredPlugList);
                return searchData.userindex
            }
        });

        let cleanFilteredPlugList = filteredPlugList.filter(Boolean);


        let filter = 'all';

        if (!archived && actif) {
            filter = 'isnotarchived';
        }else if (archived && !actif) {
            filter = 'isarchived';
        }else if (!archived && !actif) {
            filter = 'isexported';
        }

        let payload = {
            model : props.model.hash,
            field : tmpFieldsChecked,
            filter : filter,
            plugList : selectedRows.length > 0 ? cleanFilteredPlugList : [],
            plugsSelectAll : selectedRows.length === 0
        };

        setPayload(payload);
        setIsOpenModalExportTableMultipleSimpleCsv(true);
    };

    const handleDeleteModel = () => {
        let model = props.model;
        const isDeletable = !model.nbFichesArchived && !model.nbFichesNotArchived;

        if (isDeletable) {
            confirmAlert({
                title: t('global.confirm'),
                message: t('models.model-confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => props.attemptRemoveModel(props.model.hash, hash, true)
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }
        else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:models.not-empty-folder'),
                buttons: [
                    {
                        label: t('global.ok'),
                    }
                ]
            });
        }
    }

    const handleAllArchive = () => {
        let dataToDeleteHash = [...selectedRows];
        let dataToDeleteHashMap = [];
        plugnotes.map((plug, index) => {
            if (dataToDeleteHash.includes(plug.hash) && plug.isarchived === false && plug.isexported === false) {
                dataToDeleteHashMap.push(plug.hash);
            }
        });

        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-archive-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptArchivesDatas(dataToDeleteHashMap,environment.userhash)
                        setSelectedRows([])
                    }
                },
                {
                label: t('global.cancel'),
                }
            ]
        });

    };

    const handleAllUnArchive = () => {
        let dataToDeleteHash = [...selectedRows];
        let dataToDeleteHashMap = [];
        plugnotes.map((plug, index) => {
            if (dataToDeleteHash.includes(plug.hash) && plug.isarchived === true && plug.isexported === false) {
                dataToDeleteHashMap.push(plug.hash);
            }
        });


        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-unarchive-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptUnArchivesDatas(dataToDeleteHashMap,environment.userhash)
                        setSelectedRows([])
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleAllDelete = () => {
        let dataToDeleteHashMap = [...selectedRows];

        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-delete-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptDeleteDatas(dataToDeleteHashMap,environment.userhash);
                        setSelectedRows([])
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const attemptSetDataFilter = (search, key) => {
        props.attemptSetDataFilter(search, key);
    };

    const attemptDelDataFilter = (search, key) => {
        props.attemptDelDataFilter(search, key);
    }

    const handleRemoveAllSelectedFieldsInFilter = () => {
        setSelectedFields([]);
    };

    const handleChangeSelectedFieldsInFilter = (index) => {
        if (selectedFields.includes(index)) {
            setSelectedFields(selectedFields.filter((e)=>(e !== index)))
        }
        else {
            setSelectedFields([...selectedFields, index])
        }
    };

    const handleAddAllFieldsInFilter = () => {
        let allSelect = [];
        allSelect.push(0);
        fields.map((field, Findex) => {
            if (field !== null) {
                allSelect.push(Findex + 1);
            }
        });
        allSelect = allSelect.concat([16,17,18,19,20]);
        setSelectedFields(allSelect);
    };

    const handleModels = () => {
        history.push('models')
    };

    const handleSelectModelEdit = (model) => {
        delModel();
        setModel(model);
        props.history.push({
            pathname: '/pluglist-editable',
            state: { back: '/pluglist' }
        });
    }

    const handleFilterContentData = (filter) => {
        if (filter.length > 0) {
            props.attemptGetPlugnotesByModelHashAndUserHashAndFilter(props.model.hash, hash,filter);
        }
    }

    const handleRestoreContentData = (filter) => {
        props.attemptDelGlobalApiDataFilter();
        props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash);
    }

    const handleSaveCurrentFilterSelection = () => {
        setOpenSaveSelectionFilter(true);
    }

    const handleOpenListFilterSelection = () => {
        setOpenLoadSelectionFilter(true);
    }

    const handleSelectedExported = () => {
        if (exported) {
            setActif (true);
            setFavoris(false);
            setArchived(false);
            setExported(!exported);
            setTypeOfPlugnote('active');
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setActif (false);
            setFavoris(false);
            setArchived(false);
            setExported(!exported);
            setTypeOfPlugnote('exported');
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,true);
        }
    }

    const handleSelectedActif = () => {
        if (exported) {
            setActif (true);
            setFavoris(false);
            setArchived(false);
            setExported(!exported);
            setTypeOfPlugnote('active');
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setActif (!actif);
            delTypeOfPlugnote();
        }
    }

    const handleSelectedFavoris = () => {
        if (exported) {
            setActif (false);
            setFavoris(true);
            setArchived(false);
            setExported(!exported);
            setTypeOfPlugnote('favoris');
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setFavoris(!favoris);
            delTypeOfPlugnote();
        }
    }

    const handleSelectedArchived = () => {
        if (exported) {
            setActif (false);
            setFavoris(false);
            setArchived(true);
            setExported(!exported);
            setTypeOfPlugnote('archived');
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setArchived(!archived);
            delTypeOfPlugnote();
        }
    }

    const handleGenerateOrDeleteTable = () => {
        //props.history.push('/create-model');
        if (props.userid !== null) {
            let bodyFormData = new FormData();
            bodyFormData.append('uid', props.userid);
            bodyFormData.append('hash',  getToken());
            bodyFormData.append('mh',  getModel().hash);
            bodyFormData.append('shared',  false);
            bodyFormData.append('owner',  getModel().owner);

            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:tables.row-automatisation'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                        onClick: () => {
                            axios({
                                method: "post",
                                url: ApplicationConfig.backop + 'process_transfert_customer_tables.php',
                                data: bodyFormData,
                                headers: {
                                    "Content-Type": "multipart/form-data" ,
                                    "Access-Control-Allow-Origin": "*"
                                },
                            })
                                .then(function (response) {
                                    //handle success
                                    console.log(response);
                                })
                                .catch(function (response) {
                                    //handle error
                                    console.log(response);
                                });
                        }
                    },
                    {
                        label: i18n.t('common:global.cancel')
                    },
                ]
            });
        }
        else {
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:global.administrator-error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
        }
    }

    const handleOpenHelp = () => {
        setOpenModalHelp(true);
    }

    const handleModalHelpClose = () => {
        setOpenModalHelp(false);
    }

    useEffect(() => {
        if (props.model !== null) {
            let dataSource = props.plugnotes;

            if (props.globalDataFilter !== null) {

                dataSource = dataSource.filter(data => {
                    return fields.reduce((res, field, fieldIndex) => {
                        if (!!field) {
                            const index = fieldIndex + 1 ;
                            let value = data[`field${index}`];
                            if (data[`field${index}format`] === 'select' && data[`field${index}`]) {
                                if (isJsonString(data[`field${index}`])) {
                                    value = JSON.parse(data[`field${index}`]).join(', ');
                                }else {
                                    value = '';
                                }
                            }

                            if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                                if (isJsonString(data[`field${index}`])) {
                                    value = JSON.parse(data[`field${index}`]).reduce((res, obj) => {
                                        return res.concat(obj.text + ' : ' + obj.value || '');
                                    }, []).join(', ');
                                }else {
                                    value = '';
                                }
                            }

                            if (data[`field${index}format`] === 'list' && data[`field${index}`] && (data[`field${index}`] !== '\'\'' && data[`field${index}`] !== '[]' &&  data[`field${index}`] !== 'NULL' && data[`field${index}`] !== '""' )) {
                                if (isJsonString(data[`field${index}`])) {
                                    value = JSON.parse(data[`field${index}`]).reduce((res, obj) => {
                                        return res.concat(obj.text || '');
                                    }, []).join(', ');
                                }else {
                                    value = '';
                                }
                            }

                            if (data[`field${index}format`] === 'password' && data[`field${index}`]) {
                                value = '*'.repeat(value.length);
                            }

                            if (data[`field${index}format`] === 'glocalis' && data[`field${index}`]) {
                                if (typeof value !== "undefined"  && value.length > 0 && isJsonStringObject( data[`field${index}`])) {
                                    let tmpValue = JSON.parse( data[`field${index}`]);
                                    value = 'lnt : ' + tmpValue?.longitude +  ' lat : ' + tmpValue?.latitude;
                                }else {
                                    value = "";
                                }
                            }

                            if (data[`field${index}format`] ===  'intdatamaj'  && data[`field${index}`]) {
                                if (typeof value !== "undefined"  && value.length > 0 && isJsonStringObject(data[`field${index}`])) {
                                    let tmpValue = JSON.parse(data[`field${index}`]);
                                    value = 'TO : ' + tmpValue?.value +  ' AT : ' + tmpValue?.updatedDate;
                                }else {
                                    value = "";
                                }
                            }

                            if ((value || '').toLowerCase().includes(props.globalDataFilter.toLowerCase()) || (typeof data !== "undefined" && data.userindex.toString().includes(props.globalDataFilter))) {
                                return true;
                            }

                            return res || false;
                        }
                        return res;
                    }, false);
                })

                setCountActive(dataSource.filter((plug, index) => {
                    return !plug.isarchived && !plug.isexported
                }).length)
                setCountFavorite(dataSource.filter((plug, index) => {
                    return plug.favorite && !plug.isexported
                }).length)
                setCountArchived(dataSource.filter((plug, index) => {
                    return plug.isarchived && !plug.isexported
                }).length)

                setCountExported(dataSource.filter((plug, index) => {
                    return plug.isexported
                }).length)

            }
            if (props.dataFilter.length > 0) {
                props.dataFilter.forEach((selectedDataFilter, index) => {
                    if (selectedDataFilter.simpleSearch) {
                        dataSource = dataSource.filter(data => {
                            return fields.reduce((res, field, fieldIndex) => {
                                if (!!field) {
                                    const index = fieldIndex + 1;
                                    let value = data[`field${index}`];

                                    if (!!selectedDataFilter[`field${index}`]) {
                                        if (index === selectedDataFilter[`field${index}`].field.key && data[`field${index}format`] ===  selectedDataFilter[`field${index}`].field.format && data[`field${index}`]) {
                                            if (data[`field${index}format`] === 'select' && data[`field${index}`]) {
                                                if (isJsonString(data[`field${index}`])) {
                                                    value = data[`field${index}`];
                                                    //.map(v => v.toLowerCase())
                                                }
                                                else {
                                                    value = '';
                                                }
                                            }

                                            if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                                                if (isJsonString(data[`field${index}`])) {
                                                    value = data[`field${index}`];
                                                    //.map(v => v.toLowerCase())
                                                }
                                                else {
                                                    value = '';
                                                }
                                            }

                                            if (data[`field${index}format`] === 'list' && data[`field${index}`] && (data[`field${index}`] !== '\'\'' && data[`field${index}`] !== '[]' &&  data[`field${index}`] !== 'NULL' && data[`field${index}`] !== '""' )) {
                                                if (isJsonString(data[`field${index}`])) {
                                                    value = data[`field${index}`];
                                                }
                                                else {
                                                    value = '';
                                                }
                                            }

                                            if (data[`field${index}format`] === 'password' && data[`field${index}`]) {
                                                value = '*'.repeat(value.length);
                                            }

                                            if (data[`field${index}format`] === 'glocalis' && data[`field${index}`]) {
                                                if (typeof value !== "undefined"  && value.length > 0 && isJsonStringObject( data[`field${index}`])) {
                                                    let tmpValue = JSON.parse( data[`field${index}`]);
                                                    value = 'lnt : ' + tmpValue?.longitude +  ' lat : ' + tmpValue?.latitude;
                                                }
                                                else {
                                                    value = "";
                                                }
                                            }

                                            if (data[`field${index}format`] ===  'intdatamaj'  && data[`field${index}`]) {
                                                if (typeof value !== "undefined"  && value.length > 0 && isJsonStringObject(data[`field${index}`])) {
                                                    let tmpValue = JSON.parse(data[`field${index}`]);
                                                    value = 'TO : ' + tmpValue?.value +  ' AT : ' + tmpValue?.updatedDate;
                                                }else {
                                                    value = "";
                                                }
                                            }

                                            if (data[`field${index}format`] === 'select' && data[`field${index}`]) {

                                                let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {

                                                    let found = false;
                                                    if (value.includes(v)) {
                                                        found = true;
                                                    }
                                                    return found;
                                                });
                                                return !!selectedDateFilterValue.includes(true);

                                            }
                                            else if (data[`field${index}format`] === 'date' && data[`field${index}`]) {
                                                if (!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null) {
                                                    switch (selectedDataFilter[`field${index}`].query) {
                                                        case 'from':
                                                            let date1 = new Date(value);
                                                            let date2 = new Date(selectedDataFilter[`field${index}`].value);
                                                            if (date1 >= date2) {
                                                                return true;
                                                            }
                                                            break;
                                                        case 'to':
                                                            let date3 = new Date(value);
                                                            let date4 = new Date(selectedDataFilter[`field${index}`].value);
                                                            if (date3 <= date4) {
                                                                return true;
                                                            }
                                                            break;
                                                        case 'equal':
                                                            if ((value || '').toLowerCase().includes(selectedDataFilter[`field${index}`].value.toLowerCase())) {
                                                                return true;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            else if (data[`field${index}format`] === 'agenda' && data[`field${index}`]) {
                                                if (!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null) {
                                                    switch (selectedDataFilter[`field${index}`].query) {
                                                        case 'from':
                                                            //                valueTZ = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date1 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date2 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if (date1 >= date2) {
                                                                return true;
                                                            }
                                                            break;
                                                        case 'to':
                                                            let date3 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date4 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if (date3 <= date4) {
                                                                return true;
                                                            }
                                                            break;
                                                        case 'equal':
                                                            let date5 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date6 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if (date5 == date6) {
                                                                return true;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            else if (data[`field${index}format`] === 'now' && data[`field${index}`]) {
                                                if (!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null) {
                                                    switch (selectedDataFilter[`field${index}`].query) {
                                                        case 'from':
                                                            //                valueTZ = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date1 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date2 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if (date1 >= date2) {
                                                                return true;
                                                            }
                                                            break;
                                                        case 'to':
                                                            let date3 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date4 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if (date3 <= date4) {
                                                                return true;
                                                            }
                                                            break;
                                                        case 'equal':
                                                            let date5 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date6 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if (date5 == date6) {
                                                                return true;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            else if (data[`field${index}format`] === 'numeric' && data[`field${index}`]) {
                                                switch (selectedDataFilter[`field${index}`].query) {
                                                    case 'from':
                                                        let number1 = Number(value);
                                                        let number2 = Number(selectedDataFilter[`field${index}`].value);
                                                        if (number1 >= number2) {
                                                            return true;
                                                        }
                                                        break;
                                                    case 'to':
                                                        let number3 = Number(value);
                                                        let number4 = Number(selectedDataFilter[`field${index}`].value);
                                                        if (number3 <= number4) {
                                                            return true;
                                                        }
                                                        break;
                                                    case 'equal':
                                                        let number5 = Number(value);
                                                        let number6 = Number(selectedDataFilter[`field${index}`].value);
                                                        if (number5 == number6) {
                                                            return true;
                                                        }
                                                        break;
                                                }
                                            }
                                            else if (data[`field${index}format`] === 'list' && data[`field${index}`]) {
                                                let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {
                                                    let found = false;
                                                    if (value.includes(v)) {
                                                        found = true;
                                                    }
                                                    return found;
                                                });
                                                return !!selectedDateFilterValue.includes(true);
                                            }
                                            else if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                                                let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {
                                                    let found = false;
                                                    if (value.includes(v)) {
                                                        found = true;
                                                    }
                                                    return found;
                                                });
                                                return !!selectedDateFilterValue.includes(true);
                                            }
                                            else {

                                                if ((value || '').toLowerCase().includes(selectedDataFilter[`field${index}`].value.toLowerCase())) {
                                                    return true;
                                                }
                                            }
                                        }

                                    }
                                    return res || false;
                                }
                                return res;
                            }, false);
                        });
                    }

                });


                if (actif) {
                    setCountActive(dataSource.filter((plug, index) => {
                        return !plug.isarchived && !plug.isexported
                    }).length)
                }

                if (favoris) {
                    setCountFavorite(dataSource.filter((plug, index) => {
                        return plug.favorite && !plug.isexported
                    }).length)
                }

                if (archived) {
                    setCountArchived(dataSource.filter((plug, index) => {
                        return plug.isarchived && !plug.isexported
                    }).length)
                }

                if (exported) {
                    setCountExported(dataSource.filter((plug, index) => {
                        return plug.isexported
                    }).length)
                }

                if (filters.length === 0) {
                    props.dataFilter.map((item, index) => {
                        const  fieldKey = Object.keys(item);
                        setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : item[fieldKey[0]].field, value: item[fieldKey[0]].value, query: item[fieldKey[0]].query}]);
                    });
                    setOpen(false);
                    setSubOpen(true);
                }
            }
            if (props.globalDataFilter === null && props.dataFilter.length === 0) {
                setCountActive(dataSource.filter((plug, index) => {
                    return !plug.isarchived && !plug.isexported
                }).length);
                setCountFavorite(dataSource.filter((plug, index) => {
                    return plug.favorite && !plug.isexported
                }).length);
                setCountArchived(dataSource.filter((plug, index) => {
                    return plug.isarchived && !plug.isexported
                }).length);
                setCountExported(dataSource.filter((plug, index) => {
                    return plug.isexported
                }).length);
                dataSource = dataSource.filter(data => {
                    return data;
                });

            }

            setPlugnotes(dataSource);
        }
    }, [props.plugnotes, props.globalDataFilter, props.dataFilter]);

    useEffect(() => {
        if (props.model !== null) {
            let currentModel = props.model;

            if (currentModel !== 'undefined' && currentModel !== null) {
                const labels = Object.keys(currentModel).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key);
                });

                let itemFields = labels.filter(Boolean);

                let currentItemFields = itemFields.map((item) => {
                    return currentModel[item];
                });

                setFields(currentItemFields.filter(Boolean));
            }

            if (currentModel !== 'undefined' && currentModel !== null) {
                const labels = Object.keys(currentModel).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key) && currentModel[key] != null;
                });

                let fields = labels.map((label, index) => {
                    let formatoptions = currentModel['field' + (index + 1) + 'formatoptions'] || {};
                    if (typeof formatoptions !== 'object') {
                        formatoptions = JSON.parse(formatoptions);
                    }

                    return {
                        key: index + 1,
                        id: index + 1,
                        label: currentModel['field' + (index + 1) + 'label'],
                        format: currentModel['field' + (index + 1) + 'format'],
                        options: {
                            mandatory: !!currentModel['field' + (index + 1) + 'mandatory'],
                            ...formatoptions
                        },
                        formatoptions: currentModel['field' + (index + 1) + 'formatoptions']
                    }
                });

                setFieldsInfos(fields);
            }
        }
    }, [props.model]);

    useEffect(() => {
        let checkExistingSelection = JSON.parse(localStorage.getItem('fieldsSelected'));

        if (!checkExistingSelection) {

            if ((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))) {
                let fieldByModel = {
                    'hash' : props.model.hash,
                    'fields' : selectedFields,
                    'environnement' : environment.userhash,
                    'date' : moment().format('YYYY/MM/DD'),
                };

                setFieldsSelected(fieldByModel);
            }
        }
        else {
            let findIndex = checkExistingSelection.findIndex(item  => item.hash === props.model.hash);
            if (findIndex !== -1) {
                if ((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))) {
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : selectedFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSelected(fieldByModel);
                }else {
                    delSpecifiFieldsSelected(props.model.hash);
                }
            }else {
                if ((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))) {
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : selectedFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSelected(fieldByModel);
                }
            }
        }

    }, [selectedFields]);

    const abilityToUse = () => {
        let obj =  props.params.find((elm) => elm.clef === 'process_transfert_customer_tables');
        if (obj) {
            let values = obj.valeur.split(';')
            let valueObj = values.find((val) => val ===  environment.envIndex + '-' + props.model.clef);
            return !!valueObj
        }

        return false;

    }

    return (
        <div className={classes.root}>
            <CssBaseline />
                { !open && openSearch ?
                <div className={classes.plugSearchContainer}>
                    <MenuLeftPlugSearchFromApiComponent
                        globalApiDataFilter={props.globalApiDataFilter}
                        handleFilterContentData={handleFilterContentData}
                        handleRestoreContentData={handleRestoreContentData}
                        close={() => setOpenSearch(false)}
                        />
                </div>
                :
                null
                }
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
{/*
                <div className={classes.toolbar}/>
*/}
                {
                    !subOpen &&
                    <div className={classes.openCloseRelative}>
                        <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {!open ? <ChevronRightIcon  /> : <ChevronLeftIcon  />}
                        </IconButton>
                    </div>
                }

                <div style={{padding: '0px 8px'}}>
                    { props.isDataSearchActive &&
                    <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleRestoreContentData()} >
                        close
                    </span>
                    }
                    { open ?
                        <MenuLeftPlugSearchFromApiComponent
                            globalApiDataFilter={props.globalApiDataFilter}
                            handleFilterContentData={handleFilterContentData}
                            handleRestoreContentData={handleRestoreContentData}
                            close={() => setOpenSearch(false)}
                            />
                    :
                    <Tooltip title={t("common:plugnote.plug-search")} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                        <span id="icon-listForm" className={`material-icons md-25 ${props.isDataSearchActive ? classes.iconSearchActive : classes.iconSearch}`} onClick={(event) => setOpenSearch(true)}>
                            search
                        </span>
                    </Tooltip>
                    }
                </div>

                <List className={classes.listHeight}>
                    <div className="left-menu_contents">
                        <div className="left-menu_contents_wrapper">
                            <ul className="left-menu_contents_actions">

                                    <li>
                                        <span className="titres_small_green">
                                        {
                                    open ? t('common:plugnote.options-model-label', {label : props.modelInfo.label})
                                    : null}
                                    </span>
                                    </li>

                                <li className={classes.pointer} onClick={handleCreatePlugnote}>
                                <Tooltip title={t('common:pluglist.add-plugnote')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                <span className="material-icons md-25 ">
                                    add
                                </span></Tooltip>
                                    <div>{t('common:pluglist.add-plugnote')}</div>
                                </li>
{/*                                <li className={classes.pointer} onClick={handleOpenSubDrawer}>
                                <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                    filter_list
                                </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li>*/}
                                {/* <li className={classes.pointer} onClick={handleSaveCurrentFilterSelection}>
                                    <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                        filter_none
                                    </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li>
                                <li className={classes.pointer} onClick={handleOpenListFilterSelection}>
                                <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                    filter_1
                                </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li> */}
                                <li className={classes.pointer} onClick={handleModalModelShareOpen}>
                                <Tooltip title={t('common:pluglist.share-folder')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                <span className="material-icons md-25 ">
                                    group_add
                                </span></Tooltip>
                                    <div>{t('common:pluglist.share-folder')}</div>
                                </li>
                                {
                                   props.model !== null && !props.model.nbFichesArchived && !props.model.nbFichesNotArchived && props.plugnotes.length === 0 &&
                                   <li className={classes.pointer} onClick={handleDeleteModel}>
                                   <Tooltip title={t('common:pluglist.delete-folder')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons md-25 ">
                                            delete
                                        </span></Tooltip>
                                       <div>{t('common:pluglist.delete-folder')}</div>
                                   </li>
                                }
                                <li className={classes.pointer} onClick={handleModalModelPlugcodeOpen}>
                                <Tooltip title={t('common:pluglist.send-folder')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                <span className="material-icons md-25 ">
                                    qr_code
                                </span></Tooltip>
                                    <div>{t('common:pluglist.send-folder')}</div>
                                </li>
                                <li className={classes.pointer} onClick={handleGeneratePlugcodeForAllPlugnoteModel}>
                                    <Tooltip title={t('common:models.manage-plugcodes-multiple')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                    <img className="material-icons md-25" src ={Images[camelCase("gridIconSvg")]} alt={t('common:models.manage-plugcodes-multiple')} style={{width : '25px'}}/>
                                    </Tooltip>
                                    <span>&nbsp;</span>
                                    <div>
                                        {t('common:models.manage-plugcodes-multiple')}
                                    </div>
                                </li>
{/*                                <li className={classes.pointer} onClick={handleUpdateModel}>
                                <span className="material-icons md-25 ">
                                    create
                                </span>
                                    <div>{t('common:pluglist.edit-folder')}</div>
                                </li>*/}
                                {
                                    props.model !== null &&
                                    <li className={classes.pointer} >
                                        <PopupState variant="popover" popupId={props.model.hash}>
                                            {(popupState) => (
                                                <div className="display_flex">
                                                <Tooltip title={t('common:pluglist.edit-folder')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                                    <span aria-describedby={props.model.hash} className="material-icons md-medium" {...bindTrigger(popupState)}>
                                                        create
                                                    </span></Tooltip>
                                                    <div>{t('common:pluglist.edit-folder')}</div>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Box p={2}>
                                                            <div className="flex_display_direction_column">
                                                                <SubModelMenuLeftPortalComponent
                                                                    model={props.model}
                                                                    plugnotes={plugnotes}
                                                                    history={props.history}
                                                                    handleSelectModelEdit={handleSelectModelEdit}
                                                                />
                                                            </div>
                                                        </Box>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                    </li>
                                }

                                {/*                                <li>
                                <span className="material-icons md-25 ">
                                    file_copy
                                </span>
                                    <div> Dupliquer formulaire</div>
                                </li>*/}
                                <li style={{height:'20px'}}>
                                    <div className="line lightgrey flex_style">
                                    </div>
                                </li>

                                {
                                    open &&
                                    <li>
                                        <span className="titres_small_green">{t('common:plugnote.options-pluglist-label')}</span>
                                    </li>
                                }

                                <li className={classes.pointer} onClick={handlePressExportCsv}>
                                <Tooltip title={t('common:pluglist.export-excel')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                    <span className="fontAweome-icons">
                                        <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : 24}}/>
                                    </span></Tooltip>
                                    <div>{t('common:pluglist.export-excel')}</div>
                                </li>

                                {props.model && Object.values(props.model).find(element => element === "tablemulti" ||  element === "tablemultin" ||  element === "document") &&
                                <li className={classes.pointer} onClick={handlePressExportTableMultipleCsv}>
                                <Tooltip title={t('common:pluglist.export-excel')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                <span className="fontAweome-icons">
                                    <FontAwesomeIcon icon={faTable} size="lg" style={{fontSize : 21}}/>
                                </span></Tooltip>
                                    <div>{t('common:pluglist.export-excel')}</div>
                                </li>
                                }

                                <li className={classes.pointer} onClick={handleModalTemplateImportExportCsvOpen}>
                                <Tooltip title={t('common:pluglist.template-import-generate')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                <span className="material-icons md-25 ">
                                    table_view
                                </span></Tooltip>
                                    <div>{t('common:pluglist.template-import-generate')}</div>
                                </li>

                                <li className={classes.pointer} onClick={handleModalImportExportCsvOpen}>
                                <Tooltip title={t('common:pluglist.template-import-receiver')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                <span className="material-icons md-25 ">
                                    import_export
                                </span></Tooltip>
                                    <div>{t('common:pluglist.template-import-receiver')}</div>
                                </li>
                                <li className={classes.pointer} onClick={handlePressExportFile}>
                                <Tooltip title={t('common:pluglist.export-attachments')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                    <span className="fontAweome-icons">
                                        <FontAwesomeIcon icon={faPaperclip} size="lg" style={{fontSize : 21}}/>
                                    </span></Tooltip>
                                    <div>{t('common:pluglist.export-attachments')}</div>
                                </li>

                                {
                                    !props.invite && props.authapi && props.params.length > 0 && abilityToUse() &&

                                    <li className={classes.pointer} onClick={handleGenerateOrDeleteTable}>
                                        <Tooltip title={t('common:pluglist.transfert-table')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons md-25 ">
                                            trending_flat
                                        </span></Tooltip>
                                        <div>{t('common:pluglist.transfert-table')}</div>
                                    </li>
                                }
                                <li className={classes.pointer} onClick={handleOpenHelp}>
                                    <Tooltip title={t('common:global.help-manuels')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons green md-25 ">
                                            help
                                        </span></Tooltip>
                                    <div>{t('common:global.help-manuels')}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </List>
                {!subOpen &&
                <div className={classes.openClose}>
                    <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                }
            </Drawer>

            <Drawer
                variant="permanent"
                className={clsx(classes.subDrawer, {
                    [classes.subDrawerOpen]: subOpen,
                    [classes.subDrawerClose]: !subOpen,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.subDrawerOpen]: subOpen,
                        [classes.subDrawerClose]: !subOpen,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
                <List>
                    <div className="left-menu_contents">
                        <div className="left-menu_contents_wrapper flexSpacebetween">
                                <span className="titres">
                                    Filtrer
                                </span>
                            <div className="lightgrey letterSpacingNegatif10">
                                    <span className="material-icons md-25 cursor" onClick={(event) => handleResetPlugSearch()}>
                                        close
                                    </span>
                                <span className="material-icons md-30 cursor" onClick={(event) => handleSubDrawerClose()}>
                                        keyboard_arrow_left
                                    </span>
                            </div>
                        </div>
                        { filters.length > 0 &&
                            filters.map((item, index) => {
                                return (
                                <FilterSelectComponent
                                    key={"filter-" + index}
                                    fields={fieldsInfos}
                                    index={index}
                                    filter={item}
                                    handleDelFiltersLine={handleDelFiltersLine}
                                    attemptSetDataFilter={attemptSetDataFilter}
                                    attemptDelDataFilter={attemptDelDataFilter}
                                    length={props.dataFilter.length}
                                />
                                )
                            })
                        }
                        <div className="left-menu_contents_wrapper" onClick={(event) => handleAddFiltersLine()}>
                            <div className="cliquables centre">
                                <span> + {t('common:pluglist.add-another-filter')}</span>
                            </div>
                        </div>
                    </div>
                </List>
            </Drawer>

            <main className={subOpen === false ? classes.content : classes.contentSub}>
                {
                    (props.isSearchActive || props.isDataSearchActive ) &&
                    <HeaderSearch/>
                }

                {
                    modelBaseInfo !== null &&
                    <Header>
                        <div className="sous-header-without-height p1 grey">

                            <div className="sous-header_content sous-header_content_center">
                                <div style={{flex : 1}}>
                                    <img className="logo" src={Images.logo} alt="logo" onClick={(event) => handleModels()} />
                                    {props.type !== 'demo' &&
                                    <Button className="grey" style={{textTransform: 'none'}}>
                                        <span className="grey">{environment.envIndex} - {environment.name}</span>
                                    </Button>
                                    }
                                </div>
                                <div style={{flex : 1, textAlign : 'center'}}>
                                <span className="titres">
                                [{environment.envIndex} - {modelBaseInfo.clef}] {modelBaseInfo.label}
                                </span>
                                </div>
                                <div style={{flex : 1}}>
                                    <div className="grey display_flex display_flex_right float_right">
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={actif}
                                                    onChange={(event) => {handleSelectedActif ()}}/>
                                            {t('common:plugnote.plugnote-active')} ({countActive})
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={archived}
                                                    onChange={(event) => {handleSelectedArchived()}}/>
                                            {t('common:plugnote.plugnote-archived')} ({countArchived})
                                        </div>
                                        {
                                            props.authapi && abilityToUse() &&

                                            <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={exported}
                                                       onChange={(event) => handleSelectedExported()}/>
                                                {t('common:plugnote.plugnote-exported')} ({countExported})
                                            </div>
                                        }
                                            <div className="padding_left">
                                                <input className="checkbox" type="checkbox" checked={favoris}
                                                    onChange={(event) => {handleSelectedFavoris()}}/>
                                            {t('common:plugnote.plugnote-favorite')} ({countFavorites})
                                        </div>
                                        <div className="padding_left">
                                        <span id="icon-close" className="material-icons md-30" onClick={handleExitPlugList}>
                                            close
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sous-header_content black">
                                <div className="line flex_style">
                                </div>
                            </div>
                            {/*
                        {
                            plugnotes.length > 0 &&
                            <div className="sous-header_content lightgrey md-30 margin-top_sub-header">

                                {
                                    filterFieldsOpen &&
                                    <FilterViewSelectComponent
                                        fields={fields}
                                        setFilterFieldsOpen={setFilterFieldsOpen}
                                        selectedFields={selectedFields}
                                        handleAddAllFieldsInFilter={handleAddAllFieldsInFilter}
                                        handleRemoveAllSelectedFieldsInFilter={handleRemoveAllSelectedFieldsInFilter}
                                        handleChangeSelectedFieldsInFilter={handleChangeSelectedFieldsInFilter}
                                    />
                                }

                                {
                                    !filterFieldsOpen && listView &&
                                        <div>
                                            <span className="black cursor margin-left-18" onClick={(event) => setFilterFieldsOpen(true)}>
                                                <span className="material-icons md-25 black" onClick={(event) => setFilterFieldsOpen(true)}>
                                                    visibility
                                                </span>
                                                <span className=" margin-left-8">{i18n.t('common:pluglist.virtualised-fields')}</span>
                                            </span>
                                        </div>
                                }

                                {
                                    !filterFieldsOpen &&

                                    <div className="grey display_flex display_flex_right">
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={actif}
                                                    onChange={(event) => setActif (!actif)}/>
                                            {t('common:plugnote.plugnote-active')} ({countActive})
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={archived}
                                                    onChange={(event) => setArchived(!archived)}/>
                                            {t('common:plugnote.plugnote-archived')} ({countArchived})
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={favoris}
                                                    onChange={(event) => setFavoris(!favoris)}/>
                                            {t('common:plugnote.plugnote-favorite')} ({countFavorites})
                                        </div>
                                    </div>
                                }
                            </div>
                        }*/}
                        </div>
                    </Header>
                }

                {
                    props.model !== null &&
                    <Content className={!listView ? classes.contentModelGrid : !filterFieldsOpen ?  classes.contentModelList : classes.contentModelListOpenFilter}>
                        {listView && typeof plugnotes !== "undefined" && typeof selectedRows !== "undefined" &&  plugnotes.length > 0 &&  selectedRows.length > 0 &&
                        <span className={classes.selectedActionsContainer}>
                            {/*<span id="icon-favoris" className="material-icons md-25 green">*/}
                            {/*    star*/}
                            {/*</span>*/}
                            <Tooltip title={t('common:plugnote.archives')} aria-label="add" placement="top-start">
                                <span id="icon-archive" className="material-icons md-25" onClick={handleAllArchive}>
                                    archive
                                </span>
                            </Tooltip>
                            <Tooltip title={t('common:plugnote.unarchives')} aria-label="add" placement="top-start">
                                <span id="icon-unarchive" className="material-icons md-25" onClick={handleAllUnArchive}>
                                    unarchive
                                </span>
                            </Tooltip>
                            <Tooltip title={t('common:plugnote.deletes')} aria-label="add" placement="top-start">
                                <span id="icon-supprimer" className="material-icons md-25" onClick={handleAllDelete}>
                                    delete
                                </span>
                            </Tooltip>
                        </span>
                        }

                        {
                            plugnotes.length > 0 &&
                            <ViewPlugnotes
                                plugnotes={plugnotes}
                                listView={listView}
                                actif={actif}
                                archived={archived}
                                favoris={favoris}
                                handleSelectPlugnote={handleSelectPlugnote}
                                handleCreatePlugnote={handleCreatePlugnote}
                                handleFlip={handleFlip}
                                flipped={flipped}
                                handleDeletePlugnote={handleDeletePlugnote}
                                handleFavorisPlugnote={handleFavorisPlugnote}
                                handleArchivePlugnote={handleArchivePlugnote}
                                handleLockPlugnote={handleLockPlugnote}
                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
                                handleModalPlugnotePlugcodeClose={handleModalPlugnotePlugcodeClose}
                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
                                attemptUpdatePlugnoteField={props.attemptUpdatePlugnoteField}
                                attemptUpdatePlugnoteFieldManual={props.attemptUpdatePlugnoteFieldManual}
                                selectedFields={selectedFields}
                                attemptGetPlugnotesPaginateByModelHashAndUserHash={props.attemptGetPlugnotesPaginateByModelHashAndUserHash}
                                hash={hash}
                                modelhash={props.model.hash}
                                limitPage={props.end}
                                startPage={props.start}
                                setSelectedFields={setSelectedFields}
                                defaultSelectedFields={defaultSelectedFields}
                                setDefaultSelectedFields={setDefaultSelectedFields}
                                setFilters={setFilters}
                                setSubOpen={setSubOpen}
                                fields={fieldsInfos}
                                filters={filters}
                                linkOperator={link}
                                setLink={setLink}
                                attemptDelAllDataFilter={props.attemptDelAllDataFilter}
                                attemptDelDataFilter={attemptDelDataFilter}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                sizeFields = {sizeFields}
                                setSizeFields ={setSizeFields}
                                defaultSizeFields = {defaultSizeFields}
                                setDefaultSizeFields = {setDefaultSizeFields}
                                props={props}
                                filterSet={filterSet}
                                setFilterSet={setFilterSet}
                                newFilter={newFilter}
                                handleSaveCurrentFilterSelection={handleSaveCurrentFilterSelection}
                                handleOpenListFilterSelection={handleOpenListFilterSelection}
                                exported={exported}
                            />
                        }
                        {
                            plugnotes.length > 0 &&
                            <span className="dot_fixed red" onClick={handleCreatePlugnote.bind(this)}>
                                <span id="icon-qrcode" className="material-icons icon-add_style">
                                    add
                                </span>
                            </span>
                        }

                        {
                            plugnotes.length === 0 && props.dataFilter.length === 0 && !promiseInProgress &&
                            <div className="centre dossier_vide grey">
                                <div className="titres_vide">
                                    {t('plugnote.empty-plugnote', {label : props.modelInfo.label})}<br/>
                                    {t('plugnote.new-plugnote')}
                                </div>
                                <span className="dot red centre">
                                    <span id="icon-qrcode" className="material-icons white" style={{color:'white', fontSize:'60px'}} onClick={handleCreatePlugnote.bind(this)}>
                                        add
                                    </span>
                                </span>
                                <div className="titres_vide P1 cliquables">
                                    {t('global.how-to-do')}
                                </div>
                            </div>
                        }
                    </Content>
                }
            </main>


            {
                props.model && isOpenModalModelPlugcode ?
                <ModalModelPlugcodeComponent open={isOpenModalModelPlugcode} handleModalModelPlugcodeClose={handleModalModelPlugcodeClose} close={handleModalModelPlugcodeClose}/>
                :
                null
            }
            {
                props.model && isOpenModalModelShare ?
                <ModalModelShareComponent open={isOpenModalModelShare} handleModalModelShareClose={handleModalModelShareClose} close={handleModalModelShareClose}/>
                :
                null
            }

            { props.model  && isOpenModalExportSimpleCsv ?
                <ModalSimpleExportCsvComponent open={isOpenModalExportSimpleCsv} close={handleModalExportCsvClose} modelShared={false} payload={payload}/>
                :
                null
            }

            { props.model && isOpenModalExportTableMultipleSimpleCsv ?
                <ModalSimpleExportTableMultipleCsvComponent open={isOpenModalExportTableMultipleSimpleCsv} close={handleModalExportTableMultipleCsvClose} modelShared={false} payload={payload}/>
                :
                null
            }

            { props.model && isOpenModalTemplateImportExportCsv ?
                <ModalTemplateImportExportCsvComponent open={isOpenModalTemplateImportExportCsv} close={handleModalTemplateImportExportCsvClose} modelShared={false} model={props.model} payload={payload}/>
                :
                null
            }

            { props.model && isOpenModalImportExportCsv ?
                <ModalImportExportCsvComponent open={isOpenModalImportExportCsv} close={handleModalImportExportCsvClose} modelShared={false} model={props.model}/>
                :
                null
            }

            {
                getPlugnote() && isOpenModalPlugnotePlugcode ?
                <ModalPlugnotePlugcodeComponent open={isOpenModalPlugnotePlugcode} close={handleModalPlugnotePlugcodeClose} modelShared={false}/>
                :
                null
            }

            {
                isOpenClassify &&
                <ModalCopyOrMovePlugnoteComponent open={isOpenClassify} close={handleModalPlugnoteClassifyClose}/>
            }

            {
                props.model && openSaveSelectionFilter && filterSet.length > 0 &&
                <FilterSelectionSaveComponent
                    modelHash={props.model.hash} clef={environment.envIndex + '-' + props.model.clef}
                    open={openSaveSelectionFilter}
                    close={(filterObj) => {setOpenSaveSelectionFilter(false); if (filterObj !== null) {setNewFilter(filterObj); };}}
                    filterSet={filterSet} />
            }

            {
                props.model && openLoadSelectionFilter &&
                <FilterSelectionLoadComponent
                    modelHash={props.model.hash}
                    open={openLoadSelectionFilter}
                    close={(event) => setOpenLoadSelectionFilter(false)}
                    setFilterSet={setFilterSet} />
            }

            {
                openModalHelp  ?
                    <ModalHelpComponent open={openModalHelp} close={handleModalHelpClose} from="plugnotes"/>
                    :
                    null
            }

        </div>
    );
}

function PlugListEmptyComponent(props) {

    const { t, i18n } = useTranslation(['common', 'models', 'global']);
    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(environment.userhash);
    const [open, setOpen] = useState(false);
    const [subOpen, setSubOpen] = useState(false);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div style={{padding: '0px 8px'}}>
                    <div>
                        <span id="icon-listForm" className={`material-icons md-25 ${props.isDataSearchActive ? classes.iconSearchActive : classes.iconSearch}`}>
                            search
                        </span>
                        { props.isDataSearchActive &&
                            <span id="icon-listForm" className={`material-icons md-25`}> close </span>
                        }
                    </div>
                </div>
                <List className={classes.listHeight}>
                    <div className="left-menu_contents">
                        <div className="left-menu_contents_wrapper">
                            <ul className="left-menu_contents_actions">
                                {
                                    open &&
                                    <li>
                                        <span className="titres_small_green">{t('common:plugnote.options-model-label',{label : props.modelInfo.label})}</span>
                                    </li>
                                }
                                <li className={classes.pointer}>
                                <span className="material-icons md-25 ">
                                    add
                                </span>
                                    <div>{t('common:pluglist.add-plugnote')}</div>
                                </li>
                                <li className={classes.pointer}>
                                <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                    filter_list
                                </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li>
                                <li className={classes.pointer}>
                                <span className="material-icons md-25 ">
                                    group_add
                                </span>
                                    <div>{t('common:pluglist.share-folder')}</div>
                                </li>
                                {
                                    props.model !== null && !props.model.nbFichesArchived && !props.model.nbFichesNotArchived && props.plugnotes.length === 0 &&
                                    <li className={classes.pointer}>
                                        <span className="material-icons md-25 ">
                                            delete
                                        </span>
                                        <div>{t('common:pluglist.delete-folder')}</div>
                                    </li>
                                }
                                <li className={classes.pointer}>
                                <span className="material-icons md-25 ">
                                    qr_code
                                </span>
                                    <div>{t('common:pluglist.send-folder')}</div>
                                </li>
                                <li className={classes.pointer}>
                                    <img className="material-icons md-25" src ={Images[camelCase("gridIconSvg")]} alt={t('common:models.manage-plugcodes-multiple')} style={{width : '25px'}}/>
                                    <span>&nbsp;</span>
                                    <div>
                                        {t('common:models.manage-plugcodes-multiple')}
                                    </div>
                                </li>

                                <li style={{height:'20px'}}>
                                    <div className="line lightgrey flex_style">
                                    </div>
                                </li>

                                <li className={classes.pointer}>
                                    <span className="fontAweome-icons">
                                        <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : 24}}/>
                                    </span>
                                    <div>{t('common:pluglist.export-excel')}</div>
                                </li>

                                <li className={classes.pointer}>
                                <span className="material-icons md-25 ">
                                    table_view
                                </span>
                                    <div>{t('common:pluglist.template-import-generate')}</div>
                                </li>

                                <li className={classes.pointer}>
                                <span className="material-icons md-25 ">
                                    import_export
                                </span>
                                    <div>{t('common:pluglist.template-import-receiver')}</div>
                                </li>
                                <li className={classes.pointer}>
                                    <span className="fontAweome-icons">
                                        <FontAwesomeIcon icon={faPaperclip} size="lg" style={{fontSize : 21}}/>
                                    </span>
                                    <div>{t('common:pluglist.export-attachments')}</div>
                                </li>


                            </ul>
                        </div>
                    </div>
                </List>
            </Drawer>
            <main className={classes.content}>
                {
                    props.modelInfo !== null &&
                    <Header>
                        <div className="sous-header-without-height p1 grey">

                            <div className="sous-header_content sous-header_content_center">
                                <div style={{flex : 1}}>
                                    <img className="logo" src={Images.logo} alt="logo" />
                                    {
                                        props.type !== 'demo' &&
                                        <Button className="grey" style={{textTransform: 'none'}}>
                                            <span className="grey">{environment.envIndex} - {environment.name}</span>
                                        </Button>
                                    }
                                </div>
                                <div style={{flex : 1, textAlign : 'center'}}>
                                    <span className="titres">
                                    [{environment.envIndex} - {props.modelInfo.clef}] {props.modelInfo.label}
                                    </span>
                                </div>
                                <div style={{flex : 1}}>
                                    <div className="grey display_flex display_flex_right float_right">
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={false} readOnly={true}/>
                                            {t('common:plugnote.plugnote-active')} (0)
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={false} readOnly={true}/>
                                            {t('common:plugnote.plugnote-archived')} (0)
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={false} readOnly={true}/>
                                            {t('common:plugnote.plugnote-favorite')} (0)
                                        </div>
                                        <div className="padding_left">
                                            <span id="icon-close" className="material-icons md-30">
                                                close
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sous-header_content black">
                                <div className="line flex_style">
                                </div>
                            </div>
                        </div>
                    </Header>
                }
            </main>
        </div>
    )
}

function PlugListLight(props) {
    const environment = getCurrentEnvironnement();
    useEffect(() => {
        deleteHistoryPlugAction();
        if (props.globalApiDataFilter === null) {
            if(getTypeOfPlugnote() === 'exported'){
                props.attemptGetPlugnotesByModelHashAndUserHash(getModel().hash, environment.userhash,true);
            }else{
                props.attemptGetPlugnotesByModelHashAndUserHash(getModel().hash, environment.userhash);
            }
        }
        else {
            props.attemptGetPlugnotesByModelHashAndUserHashAndFilter(getModel().hash, environment.userhash,props.globalApiDataFilter);
        }
    }, [])
    // console.log('PLUGLISTLIGHT PROPS', props);

    if (props.model !== null) {
        return(
            <PlugListComponent modelInfo={getModel()} {...props} />
        )
    }
    else {
        return(
            <PlugListEmptyComponent modelInfo={getModel()} {...props} />
        );
    }
}

// redux providing state takeover
const mapStateToProps = (state) => {
    let datas = [];
    if (state.search.isSearchActive && state.search.isSearchResult && state.data.list.length > 0 && (state.search.searchParams.hasOwnProperty('pnnumber') || state.search.searchParams.hasOwnProperty('content') )) {
        state.data.list.map((item, index) => {
            let find = false;
            state.search.searchResult.map((search, index) => {
                if (item.hash === search.datahash) {
                    find = true;
                }
            });
            if (find) {
                datas.push(item);
            }
        });
    }
    else {
        datas = !!state.data.list ? state.data.list : [];
    }

    if (!state.search.isSearchActive && !state.search.isSearchResult) {
        datas = !! state.data.list ? state.data.list : [];
    }


    let dataFilter = [];
    let globalDataFilter = null;
    let globalApiDataFilter = null;

    if (typeof state.data.dataFilter !== 'undefined' ) {
        if (state.data.dataFilter.length > 0) {
            dataFilter = state.data.dataFilter;
        }
    }

    if (typeof state.data.dataGlobalFilter !== 'undefined' ) {
        if (state.data.dataGlobalFilter !== null) {
            globalDataFilter = state.data.dataGlobalFilter;
        }
    }

    if (typeof state.data.globalApiDataFilter !== 'undefined' ) {
        if (state.data.globalApiDataFilter !== null) {
            globalApiDataFilter = state.data.globalApiDataFilter;
        }
    }

    let datasSorted = datas.sort((a,b) => {
        return a.userindex - b.userindex;
    })

    // console.log(state);

    return {
        model : state.model.model,
        end : state.data.end,
        start : state.data.start,
        user : state.user,
        token : state.user.token,
        plugnotes : datasSorted.reverse(),
        models : !! state.model.datas ? state.model.datas : [],
        fetching : state.data.fetching,
        dataFilter : dataFilter,
        globalDataFilter : globalDataFilter,
        globalApiDataFilter : globalApiDataFilter,
        isSearchActive: state.search.isSearchActive,
        isDataSearchActive : state.data.isDataSearchActive,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        fetchingPassword : state.data.fetchingPassword,
        password : state.data.password,
        authapi : state?.user?.user?.auth || false,
        userid : state?.user?.user?._value?.id || null,
        params : state?.params?.list || [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptSetDataFilter: (dataFilter, key) => dispatch(reduxSetDataFilterRequest(dataFilter, key)),
        attemptDelDataFilter: (dataFilter) => dispatch(reduxDelDataFilterRequest(dataFilter)),
        attemptDelAllDataFilter: () => dispatch(reduxDelAllDataFilterRequest()),
        attemptGetPlugnotesByModelHashAndUserHash: (modelhash,userhash,mode) => dispatch(getDatasLightRequest(modelhash,userhash,mode)),
        old_attemptGetPlugnotesByModelHashAndUserHash: (modelhash,userhash) => dispatch(getDatasRequest(modelhash,userhash)),
        attemptGetPlugnotesByModelHashAndUserHashAndFilter: (modelhash,userhash,filter) => dispatch(getDatasFilterLightRequest(modelhash,userhash,filter)),
        old_attemptGetPlugnotesByModelHashAndUserHashAndFilter: (modelhash,userhash,filter) => dispatch(getDatasFilterRequest(modelhash,userhash,filter)),
        attemptGetPlugnotesPaginateByModelHashAndUserHash: (modelhash,userhash,page,mode) => dispatch(getDatasPaginateLightRequest(modelhash,userhash,page,mode)),
        old_attemptGetPlugnotesPaginateByModelHashAndUserHash: (modelhash,userhash,page) => dispatch(getDatasPaginateRequest(modelhash,userhash,page)),
        attemptDeletePlugote: (datahash, userhash) => dispatch(delDeleteDataRequest(datahash, userhash)),
        attemptUpdateFavorisPlugnote: (datahash, favorite, userhash) => dispatch(putUpdateDataFavorisRequest(datahash, favorite, userhash)),
        attemptUpdateLockPlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataLockRequest(datahash, isarchived, locked, userhash)),
        attemptUpdateArchivePlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataArchiveRequest(datahash, isarchived, locked, userhash)),
        attemptUpdatePlugnoteField: (modelhash, datahash, userhash, formData) => dispatch(putUpdateDataFieldRequest(modelhash, datahash, userhash, formData)),
        attemptUpdatePlugnoteFieldManual: (modelhash, datahash, userhash, formData) => dispatch(putUpdateDataFieldManualRequest(modelhash, datahash, userhash, formData)),
        attemptResetModelPlugcode: () => dispatch(reduxGeneratePlugformcodeReset()),
        attemptResetPlugnotePlugcode: () => dispatch(reduxGeneratePlugnotecodeReset()),
        attemptResetModelShare: () => dispatch(reduxGenerateShareReset()),
        attemptResetAnnexe: () => dispatch(reduxGenerateAnnexeReset()),
        attemptRemoveModel :(modelhash,userhash, back) => dispatch(delModelUniqueRequest(modelhash, userhash, back)),
        attemptExportFullFiles:(modelhash, usermainhash, payloadhash) => dispatch(postExportFullFileDatasRequest(modelhash, usermainhash, payloadhash)),
        attemptDeleteDatas: (payloadhash, userhash) => dispatch(delDeleteDatasRequest(payloadhash, userhash)),
        attemptArchivesDatas: (payloadhash, userhash) => dispatch(putUpdateDatasArchiveRequest(payloadhash, userhash)),
        attemptUnArchivesDatas: (payloadhash, userhash) => dispatch(putUpdateDatasUnArchiveRequest(payloadhash, userhash)),
        attemptDelGlobalDataFilter: () => dispatch(reduxDelGlobalDataFilterRequest()),
        attemptDelGlobalApiDataFilter: () => dispatch(reduxDelGlobalApiDataFilterRequest()),
        attemptGeneratePlugcodeForAllPlugnoteModel :(modelhash,userhash) => dispatch(postModelGenerateMultiplePlugcodeRequest(modelhash, userhash)),
        attemptResetDataPassword: () => dispatch(reduxGenerateDataPasswordReset()),
        attemptGetPasswordField: (datahash, fieldId, userhash) => dispatch(getDecryptPasswordRequest(datahash, fieldId, userhash)),
        attemptGetModelUniqueRequest: (modelhash,userhash,usermainhash) => dispatch(getModelUniqueRequest(modelhash,userhash,usermainhash)),
        attempListFilterSaveByModel: (payload) => dispatch(postSendListFilterRequest(payload)),
        attemptDeleteFilter: (payload) => dispatch(deleteFilterRequest(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlugListLight)
