// create initial state for reducers
import {Types} from "../actions/extdata";

const INIT_STATE = {
    list: [],
    listHeader: [],
    data: null,
    fetching : false
};


// reducer function to transform state
export default function extdata(state = INIT_STATE, action) {


    switch(action.type) {

        case Types.POST_EXT_DATA_RESET : return INIT_STATE;

        case Types.POST_EXT_DATA_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                listHeader: action.payload.header,
                fetching: true,
            }
        }

        case Types.POST_EXT_DATA_ERROR: {
            return {
                ...state,
                fetching: false,
            }
        }

        case Types.POST_EXT_DATA_FAILURE: {
            return {
                ...state,
                fetching: false,
            }
        }

        default: return state;
    }

};
