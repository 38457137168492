// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import React from "react";

export const Types = {

    REDUX_GENERATE_ANNEXE_RESET: 'redux_generate_annexe_reset',

    POST_ANNEXES_REQUEST: 'post_annexes_request',
    POST_ANNEXES_PROGRESS: 'post_annexes_progress',
    POST_ANNEXES_SUCCESS: 'post_annexes_success',
    POST_ANNEXES_FAILURE: 'post_annexes_failure',
    POST_ANNEXES_ERROR: 'post_annexes_error',

    POST_ANNEXES_MODAL_REQUEST: 'post_annexes_modal_request',
    POST_ANNEXES_MODAL_PROGRESS: 'post_annexes_modal_progress',
    POST_ANNEXES_MODAL_SUCCESS: 'post_annexes_modal_success',
    POST_ANNEXES_MODAL_FAILURE: 'post_annexes_modal_failure',
    POST_ANNEXES_MODAL_ERROR: 'post_annexes_modal_error',

    POST_ANNEXES_VERSION_REQUEST: 'post_annexes_version_request',
    POST_ANNEXES_VERSION_PROGRESS: 'post_annexes_version_progress',
    POST_ANNEXES_VERSION_SUCCESS: 'post_annexes_version_success',
    POST_ANNEXES_VERSION_FAILURE: 'post_annexes_version_failure',
    POST_ANNEXES_VERSION_ERROR: 'post_annexes_version_error',

    POST_ANNEXES_VERSION_MODAL_REQUEST: 'post_annexes_version_modal_request',
    POST_ANNEXES_VERSION_MODAL_PROGRESS: 'post_annexes_version_modal_progress',
    POST_ANNEXES_VERSION_MODAL_SUCCESS: 'post_annexes_version_modal_success',
    POST_ANNEXES_VERSION_MODAL_FAILURE: 'post_annexes_version_modal_failure',
    POST_ANNEXES_VERSION_MODAL_ERROR: 'post_annexes_version_modal_error',

    POST_ANNEXES_VERSION_SHARED_REQUEST: 'post_annexes_version_shared_request',
    POST_ANNEXES_VERSION_SHARED_PROGRESS: 'post_annexes_version_shared_progress',
    POST_ANNEXES_VERSION_SHARED_SUCCESS: 'post_annexes_version_shared_success',
    POST_ANNEXES_VERSION_SHARED_FAILURE: 'post_annexes_version_shared_failure',
    POST_ANNEXES_VERSION_SHARED_ERROR: 'post_annexes_version_shared_error',

    POST_ANNEXES_VERSION_SHARED_MODAL_REQUEST: 'post_annexes_version_shared_modal_request',
    POST_ANNEXES_VERSION_SHARED_MODAL_PROGRESS: 'post_annexes_version_shared_modal_progress',
    POST_ANNEXES_VERSION_SHARED_MODAL_SUCCESS: 'post_annexes_version_shared_modal_success',
    POST_ANNEXES_VERSION_SHARED_MODAL_FAILURE: 'post_annexes_version_shared_modal_failure',
    POST_ANNEXES_VERSION_SHARED_MODAL_ERROR: 'post_annexes_version_shared_modal_error',

    POST_ANNEXES_SHARED_REQUEST: 'post_annexes_shared_request',
    POST_ANNEXES_SHARED_PROGRESS: 'post_annexes_shared_progress',
    POST_ANNEXES_SHARED_SUCCESS: 'post_annexes_shared_success',
    POST_ANNEXES_SHARED_FAILURE: 'post_annexes_shared_failure',
    POST_ANNEXES_SHARED_ERROR: 'post_annexes_shared_error',

    POST_ANNEXES_SHARED_MODAL_REQUEST: 'post_annexes_shared_modal_request',
    POST_ANNEXES_SHARED_MODAL_PROGRESS: 'post_annexes_shared_modal_progress',
    POST_ANNEXES_SHARED_MODAL_SUCCESS: 'post_annexes_shared_modal_success',
    POST_ANNEXES_SHARED_MODAL_FAILURE: 'post_annexes_shared_modal_failure',
    POST_ANNEXES_SHARED_MODAL_ERROR: 'post_annexes_shared_modal_error',

    POST_ADD_NOTE_REQUEST: 'post_add_note_request',
    POST_ADD_NOTE_SUCCESS: 'post_add_note_success',
    POST_ADD_NOTE_FAILURE: 'post_add_note_failure',
    POST_ADD_NOTE_ERROR: 'post_add_note_error',

    POST_ADD_NOTE_MODAL_REQUEST: 'post_add_note_modal_request',
    POST_ADD_NOTE_MODAL_SUCCESS: 'post_add_note_modal_success',
    POST_ADD_NOTE_MODAL_FAILURE: 'post_add_note_modal_failure',
    POST_ADD_NOTE_MODAL_ERROR: 'post_add_note_modal_error',

    POST_ANNEXES_SEND_MAIL_REQUEST: 'post_annexes_send_mail_request',
    POST_ANNEXES_SEND_MAIL_SUCCESS: 'post_annexes_send_mail_success',
    POST_ANNEXES_SEND_MAIL_FAILURE: 'post_annexes_send_mail_failure',
    POST_ANNEXES_SEND_MAIL_ERROR: 'post_annexes_send_mail_error',

    POST_ANNEXES_SEND_MAIL_MODAL_REQUEST: 'post_annexes_send_mail_modal_request',
    POST_ANNEXES_SEND_MAIL_MODAL_SUCCESS: 'post_annexes_send_mail_modal_success',
    POST_ANNEXES_SEND_MAIL_MODAL_FAILURE: 'post_annexes_send_mail_modal_failure',
    POST_ANNEXES_SEND_MAIL_MODAL_ERROR: 'post_annexes_send_mail_modal_error',

    PUT_EDIT_NOTE_REQUEST: 'put_edit_note_request',
    PUT_EDIT_NOTE_SUCCESS: 'put_edit_note_success',
    PUT_EDIT_NOTE_FAILURE: 'put_edit_note_failure',
    PUT_EDIT_NOTE_ERROR: 'put_edit_note_error',

    PUT_EDIT_NOTE_MODAL_REQUEST: 'put_edit_note_modal_request',
    PUT_EDIT_NOTE_MODAL_SUCCESS: 'put_edit_note_modal_success',
    PUT_EDIT_NOTE_MODAL_FAILURE: 'put_edit_note_modal_failure',
    PUT_EDIT_NOTE_MODAL_ERROR: 'put_edit_note_modal_error',

    DEL_DELETE_ANNEXE_REQUEST: 'del_delete_annexe_request',
    DEL_DELETE_ANNEXE_SUCCESS: 'del_delete_annexe_success',
    DEL_DELETE_ANNEXE_FAILURE: 'del_delete_annexe_failure',
    DEL_DELETE_ANNEXE_ERROR: 'del_delete_annexe_error',

    DEL_DELETE_ANNEXE_MODAL_REQUEST: 'del_delete_annexe_modal_request',
    DEL_DELETE_ANNEXE_MODAL_SUCCESS: 'del_delete_annexe_modal_success',
    DEL_DELETE_ANNEXE_MODAL_FAILURE: 'del_delete_annexe_modal_failure',
    DEL_DELETE_ANNEXE_MODAL_ERROR: 'del_delete_annexe_modal_error',

    DEL_DELETE_ANNEXE_VERSION_REQUEST: 'del_delete_annexe_version_request',
    DEL_DELETE_ANNEXE_VERSION_SUCCESS: 'del_delete_annexe_version_success',
    DEL_DELETE_ANNEXE_VERSION_FAILURE: 'del_delete_annexe_version_failure',
    DEL_DELETE_ANNEXE_VERSION_ERROR: 'del_delete_annexe_version_error',

    DEL_DELETE_ANNEXE_VERSION_MODAL_REQUEST: 'del_delete_annexe_version_modal_request',
    DEL_DELETE_ANNEXE_VERSION_MODAL_SUCCESS: 'del_delete_annexe_version_modal_success',
    DEL_DELETE_ANNEXE_VERSION_MODAL_FAILURE: 'del_delete_annexe_version_modal_failure',
    DEL_DELETE_ANNEXE_VERSION_MODAL_ERROR: 'del_delete_annexe_version_modal_error',

    PUT_ANNEXE_COMMENT_REQUEST: 'put_annexe_comment_request',
    PUT_ANNEXE_COMMENT_SUCCESS: 'put_annexe_comment_success',
    PUT_ANNEXE_COMMENT_FAILURE: 'put_annexe_comment_failure',
    PUT_ANNEXE_COMMENT_ERROR: 'put_annexe_comment_error',

    PUT_ANNEXE_COMMENT_MODAL_REQUEST: 'put_annexe_comment_modal_request',
    PUT_ANNEXE_COMMENT_MODAL_SUCCESS: 'put_annexe_comment_modal_success',
    PUT_ANNEXE_COMMENT_MODAL_FAILURE: 'put_annexe_comment_modal_failure',
    PUT_ANNEXE_COMMENT_MODAL_ERROR: 'put_annexe_comment_modal_error',

    GET_ANNEXE_VERSION_REQUEST: 'get_annexe_version_request',
    GET_ANNEXE_VERSION_SUCCESS: 'get_annexe_version_success',
    GET_ANNEXE_VERSION_FAILURE: 'get_annexe_version_failure',
    GET_ANNEXE_VERSION_ERROR: 'get_annexe_version_error',

    GET_ANNEXE_VERSION_MODAL_REQUEST: 'get_annexe_version_modal_request',
    GET_ANNEXE_VERSION_MODAL_SUCCESS: 'get_annexe_version_modal_success',
    GET_ANNEXE_VERSION_MODAL_FAILURE: 'get_annexe_version_modal_failure',
    GET_ANNEXE_VERSION_MODAL_ERROR: 'get_annexe_version_modal_error',

    GET_ANNEXES_VERSION_REQUEST: 'get_annexes_version_request',
    GET_ANNEXES_VERSION_SUCCESS: 'get_annexes_version_success',
    GET_ANNEXES_VERSION_FAILURE: 'get_annexes_version_failure',
    GET_ANNEXES_VERSION_ERROR: 'get_annexes_version_error',

    GET_ANNEXES_VERSION_MODAL_REQUEST: 'get_annexes_version_modal_request',
    GET_ANNEXES_VERSION_MODAL_SUCCESS: 'get_annexes_version_modal_success',
    GET_ANNEXES_VERSION_MODAL_FAILURE: 'get_annexes_version_modal_failure',
    GET_ANNEXES_VERSION_MODAL_ERROR: 'get_annexes_version_modal_error',

};

/*
Token
 */
// function that returns an object literal
export const postAnnexesRequest = (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesFailure = () => ({
    type: Types.POST_ANNEXES_FAILURE,
});

// function that returns an object literal
export const postAnnexesError = () => ({
    type: Types.POST_ANNEXES_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postAnnexesModalRequest = (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_MODAL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesModalSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_MODAL_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesModalProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_MODAL_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesModalFailure = () => ({
    type: Types.POST_ANNEXES_MODAL_FAILURE,
});

// function that returns an object literal
export const postAnnexesModalError = () => ({
    type: Types.POST_ANNEXES_MODAL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postAnnexesSharedRequest = (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_SHARED_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesSharedSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_SHARED_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesSharedProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_SHARED_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesSharedFailure = () => ({
    type: Types.POST_ANNEXES_SHARED_FAILURE,
});

// function that returns an object literal
export const postAnnexesSharedError = () => ({
    type: Types.POST_ANNEXES_SHARED_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postAnnexesSharedModalRequest = (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_SHARED_MODAL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesSharedModalSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_SHARED_MODAL_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesSharedModalProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_SHARED_MODAL_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesSharedModalFailure = () => ({
    type: Types.POST_ANNEXES_SHARED_MODAL_FAILURE,
});

// function that returns an object literal
export const postAnnexesSharedModalError = () => ({
    type: Types.POST_ANNEXES_SHARED_MODAL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postAnnexesVersionRequest = (modelhash,userhash, datahash, annexehash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_VERSION_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    annexehash : annexehash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesVersionSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_VERSION_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesVersionProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_VERSION_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesVersionFailure = () => ({
    type: Types.POST_ANNEXES_VERSION_FAILURE,
});

// function that returns an object literal
export const postAnnexesVersionError = () => ({
    type: Types.POST_ANNEXES_VERSION_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postAnnexesVersionModalRequest = (modelhash,userhash, datahash, annexehash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_VERSION_MODAL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    annexehash : annexehash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesVersionModalSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_VERSION_MODAL_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesVersionModalProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_VERSION_MODAL_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesVersionModalFailure = () => ({
    type: Types.POST_ANNEXES_VERSION_MODAL_FAILURE,
});

// function that returns an object literal
export const postAnnexesVersionModalError = () => ({
    type: Types.POST_ANNEXES_VERSION_MODAL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postAnnexesVersionSharedRequest = (modelhash,userhash, datahash, annexehash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    annexehash : annexehash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesVersionSharedSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_VERSION_SHARED_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesVersionSharedProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesVersionSharedFailure = () => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_FAILURE,
});

// function that returns an object literal
export const postAnnexesVersionSharedError = () => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const postAnnexesVersionSharedModalRequest = (modelhash,userhash, datahash, annexehash,modelshared,newdata,formdata,formfile) => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_MODAL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    datahash : datahash,
    annexehash : annexehash,
    modelshared : modelshared,
    newdata : newdata,
    formdata : formdata,
    formfile : formfile

});

// function that returns an object literal

export const postAnnexesVersionSharedModalSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_ANNEXES_VERSION_SHARED_MODAL_SUCCESS,
            payload: { data }
        });
    }
};

export const postAnnexesVersionSharedModalProgress = (file: File, progress: number) => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_MODAL_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postAnnexesVersionSharedModalFailure = () => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_MODAL_FAILURE,
});

// function that returns an object literal
export const postAnnexesVersionSharedModalError = () => ({
    type: Types.POST_ANNEXES_VERSION_SHARED_MODAL_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const delDeleteAnnexeRequest = (annexehash, userhash,modelhash,formdata) => ({
    type: Types.DEL_DELETE_ANNEXE_REQUEST,
    annexehash : annexehash,
    userhash : userhash,
    modelhash:modelhash,
    formdata: formdata

});

// function that returns an object literal

export const delDeleteAnnexeSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.DEL_DELETE_ANNEXE_SUCCESS,
            payload: { data }
        });
    }
};

// function that returns an object literal
export const delDeleteAnnexeFailure = () => ({
    type: Types.DEL_DELETE_ANNEXE_FAILURE,
});

// function that returns an object literal
export const delDeleteAnnexeError = () => ({
    type: Types.DEL_DELETE_ANNEXE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const delDeleteAnnexeModalRequest = (annexehash, userhash,modelhash,formdata) => ({
    type: Types.DEL_DELETE_ANNEXE_MODAL_REQUEST,
    annexehash : annexehash,
    userhash : userhash,
    modelhash:modelhash,
    formdata: formdata
});

// function that returns an object literal

export const delDeleteAnnexeModalSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.DEL_DELETE_ANNEXE_MODAL_SUCCESS,
            payload: { data }
        });
    }
};

// function that returns an object literal
export const delDeleteAnnexeModalFailure = () => ({
    type: Types.DEL_DELETE_ANNEXE_MODAL_FAILURE,
});

// function that returns an object literal
export const delDeleteAnnexeModalError = () => ({
    type: Types.DEL_DELETE_ANNEXE_MODAL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const delDeleteAnnexeVersionRequest = (annexehash, userhash, version) => ({
    type: Types.DEL_DELETE_ANNEXE_VERSION_REQUEST,
    annexehash : annexehash,
    userhash : userhash,
    version : version,

});

// function that returns an object literal

export const delDeleteAnnexeVersionSuccess = (data, version) => {
    return function(dispatch) {
        dispatch({
            type: Types.DEL_DELETE_ANNEXE_VERSION_SUCCESS,
            payload: { data },
            version : {version}
        });
    }
};

// function that returns an object literal
export const delDeleteAnnexeVersionFailure = () => ({
    type: Types.DEL_DELETE_ANNEXE_VERSION_FAILURE,
});

// function that returns an object literal
export const delDeleteAnnexeVersionError = () => ({
    type: Types.DEL_DELETE_ANNEXE_VERSION_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const delDeleteAnnexeVersionModalRequest = (annexehash, userhash, version) => ({
    type: Types.DEL_DELETE_ANNEXE_VERSION_MODAL_REQUEST,
    annexehash : annexehash,
    userhash : userhash,
    version : version,

});

// function that returns an object literal

export const delDeleteAnnexeVersionModalSuccess = (data, version) => {
    return function(dispatch) {
        dispatch({
            type: Types.DEL_DELETE_ANNEXE_VERSION_MODAL_SUCCESS,
            payload: { data },
            version : {version}
        });
    }
};

// function that returns an object literal
export const delDeleteAnnexeVersionModalFailure = () => ({
    type: Types.DEL_DELETE_ANNEXE_VERSION_MODAL_FAILURE,
});

// function that returns an object literal
export const delDeleteAnnexeVersionModalError = () => ({
    type: Types.DEL_DELETE_ANNEXE_VERSION_MODAL_ERROR,
});

// function that returns an object literal
export const putAnnexeCommentRequest = (annexehash, comment, userhash) => ({
    type: Types.PUT_ANNEXE_COMMENT_REQUEST,
    annexehash : annexehash,
    comment : comment,
    userhash : userhash
});

// function that returns an object literal
export const putAnnexeCommentSuccess = (data, data1) => ({
    type: Types.PUT_ANNEXE_COMMENT_SUCCESS,
    payload: { data },
    payload1 : { data1 }
});

// function that returns an object literal
export const putAnnexeCommentFailure = () => ({
    type: Types.PUT_ANNEXE_COMMENT_FAILURE,
});

// function that returns an object literal
export const putAnnexeCommentError = () => ({
    type: Types.PUT_ANNEXE_COMMENT_ERROR,
});

// function that returns an object literal
export const putAnnexeCommentModalRequest = (annexehash, comment, userhash) => ({
    type: Types.PUT_ANNEXE_COMMENT_MODAL_REQUEST,
    annexehash : annexehash,
    comment : comment,
    userhash : userhash
});

// function that returns an object literal
export const putAnnexeCommentModalSuccess = (data, data1) => ({
    type: Types.PUT_ANNEXE_COMMENT_MODAL_SUCCESS,
    payload: { data },
    payload1 : { data1 }
});

// function that returns an object literal
export const putAnnexeCommentModalFailure = () => ({
    type: Types.PUT_ANNEXE_COMMENT_MODAL_FAILURE,
});

// function that returns an object literal
export const putAnnexeCommentModalError = () => ({
    type: Types.PUT_ANNEXE_COMMENT_MODAL_ERROR,
});

// function that returns an object literal
export const postAddNoteRequest = (modelhash, datahash, userhash, name, note, newdata,formdata) => ({
    type: Types.POST_ADD_NOTE_REQUEST,
    modelhash : modelhash,
    datahash : datahash,
    userhash : userhash,
    name : name,
    note : note,
    newdata : newdata,
    formdata: formdata
});

// function that returns an object literal
export const postAddNoteSuccess = (data) => ({
    type: Types.POST_ADD_NOTE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postAddNoteFailure = () => ({
    type: Types.POST_ADD_NOTE_FAILURE,
});

// function that returns an object literal
export const postAddNoteError = () => ({
    type: Types.POST_ADD_NOTE_ERROR,
});

// function that returns an object literal
export const postAddNoteModalRequest = (modelhash, datahash, userhash, name, note, newdata,formdata) => ({
    type: Types.POST_ADD_NOTE_MODAL_REQUEST,
    modelhash : modelhash,
    datahash : datahash,
    userhash : userhash,
    name : name,
    note : note,
    newdata : newdata,
    formdata: formdata
});

// function that returns an object literal
export const postAddNoteModalSuccess = (data) => ({
    type: Types.POST_ADD_NOTE_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postAddNoteModalFailure = () => ({
    type: Types.POST_ADD_NOTE_MODAL_FAILURE,
});

// function that returns an object literal
export const postAddNoteModalError = () => ({
    type: Types.POST_ADD_NOTE_MODAL_ERROR,
});


// function that returns an object literal
export const postAnnexesSendMailRequest = (sendMailData, attachments, userhash, modelhash, datahash, dataUserIndex ,formData, newData) => ({
    type: Types.POST_ANNEXES_SEND_MAIL_REQUEST,
    sendMailData : sendMailData,
    attachments : attachments,
    userhash : userhash,
    modelhash : modelhash,
    datahash : datahash,
    dataUserIndex : dataUserIndex,
    formData : formData,
    newData : newData
});

// function that returns an object literal
export const postAnnexesSendMailSuccess = (data) => ({
    type: Types.POST_ANNEXES_SEND_MAIL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postAnnexesSendMailFailure = () => ({
    type: Types.POST_ANNEXES_SEND_MAIL_FAILURE,
});

// function that returns an object literal
export const postAnnexesSendMailError = () => ({
    type: Types.POST_ANNEXES_SEND_MAIL_ERROR,
});

// function that returns an object literal
export const postAnnexesSendMailModalRequest = (sendMailData, attachments, userhash, modelhash, datahash, dataUserIndex ,formData, newData) => ({
    type: Types.POST_ANNEXES_SEND_MAIL_MODAL_REQUEST,
    sendMailData : sendMailData,
    attachments : attachments,
    userhash : userhash,
    modelhash : modelhash,
    datahash : datahash,
    dataUserIndex : dataUserIndex,
    formData : formData,
    newData : newData
});

// function that returns an object literal
export const postAnnexesSendMailModalSuccess = (data) => ({
    type: Types.POST_ANNEXES_SEND_MAIL_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postAnnexesSendMailModalFailure = () => ({
    type: Types.POST_ANNEXES_SEND_MAIL_MODAL_FAILURE,
});

// function that returns an object literal
export const postAnnexesSendMailModalError = () => ({
    type: Types.POST_ANNEXES_SEND_MAIL_MODAL_ERROR,
});


// function that returns an object literal
export const putEditNoteRequest = (annexehash, notehash, name, note) => ({
    type: Types.PUT_EDIT_NOTE_REQUEST,
    annexehash : annexehash,
    notehash : notehash,
    name : name,
    note : note,
});

// function that returns an object literal
export const putEditNoteSuccess = (data) => ({
    type: Types.PUT_EDIT_NOTE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putEditNoteFailure = () => ({
    type: Types.PUT_EDIT_NOTE_FAILURE,
});

// function that returns an object literal
export const putEditNoteError = () => ({
    type: Types.PUT_EDIT_NOTE_ERROR,
});

// function that returns an object literal
export const putEditNoteModalRequest = (annexehash, notehash, name, note) => ({
    type: Types.PUT_EDIT_NOTE_MODAL_REQUEST,
    annexehash : annexehash,
    notehash : notehash,
    name : name,
    note : note,
});

// function that returns an object literal
export const putEditNoteModalSuccess = (data) => ({
    type: Types.PUT_EDIT_NOTE_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putEditNoteModalFailure = () => ({
    type: Types.PUT_EDIT_NOTE_MODAL_FAILURE,
});

// function that returns an object literal
export const putEditNoteModalError = () => ({
    type: Types.PUT_EDIT_NOTE_MODAL_ERROR,
});

// function that returns an object literal
export const getAnnexeVersionRequest = (annexehash, version) => ({
    type: Types.GET_ANNEXE_VERSION_REQUEST,
    annexehash : annexehash,
    version : version,
});

// function that returns an object literal
export const getAnnexeVersionSuccess = (data) => ({
    type: Types.GET_ANNEXE_VERSION_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getAnnexeVersionFailure = () => ({
    type: Types.GET_ANNEXE_VERSION_FAILURE,
});

// function that returns an object literal
export const getAnnexeVersionError = () => ({
    type: Types.GET_ANNEXE_VERSION_ERROR,
});

// function that returns an object literal
export const getAnnexeVersionModalRequest = (annexehash, version) => ({
    type: Types.GET_ANNEXE_VERSION_MODAL_REQUEST,
    annexehash : annexehash,
    version : version,
});

// function that returns an object literal
export const getAnnexeVersionModalSuccess = (data) => ({
    type: Types.GET_ANNEXE_VERSION_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getAnnexeVersionModalFailure = () => ({
    type: Types.GET_ANNEXE_VERSION_MODAL_FAILURE,
});

// function that returns an object literal
export const getAnnexeVersionModalError = () => ({
    type: Types.GET_ANNEXE_VERSION_MODAL_ERROR,
});


// function that returns an object literal
export const getAnnexesVersionRequest = (annexehash) => ({
    type: Types.GET_ANNEXES_VERSION_REQUEST,
    annexehash : annexehash,
});

// function that returns an object literal
export const getAnnexesVersionSuccess = (data) => ({
    type: Types.GET_ANNEXES_VERSION_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getAnnexesVersionFailure = () => ({
    type: Types.GET_ANNEXES_VERSION_FAILURE,
});

// function that returns an object literal
export const getAnnexesVersionError = () => ({
    type: Types.GET_ANNEXES_VERSION_ERROR,
});

// function that returns an object literal
export const getAnnexesVersionModalRequest = (annexehash) => ({
    type: Types.GET_ANNEXES_VERSION_MODAL_REQUEST,
    annexehash : annexehash,
});

// function that returns an object literal
export const getAnnexesVersionModalSuccess = (data) => ({
    type: Types.GET_ANNEXES_VERSION_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getAnnexesVersionModalFailure = () => ({
    type: Types.GET_ANNEXES_VERSION_MODAL_FAILURE,
});

// function that returns an object literal
export const getAnnexesVersionModalError = () => ({
    type: Types.GET_ANNEXES_VERSION_MODAL_ERROR,
});


export const reduxGenerateAnnexeReset = () => ({
    type: Types.REDUX_GENERATE_ANNEXE_RESET,
});
