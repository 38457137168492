import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import {confirmAlert} from "react-confirm-alert";
import {Close, Search} from "@material-ui/icons";

import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        height:42,
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        padding: "5px 15px",
        width: '35%',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
        marginLeft: 10,
    },
    multTabMainContainer: {
        width : '100%',
        display: 'flex',
        minHeight: '42px',
        flexDirection: 'row',
        cursor: 'pointer',
    },
    multTableAdd: {
        width : '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    multTableItem: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: '#b8f6f8',
        color: colors.white,
        padding: "0 10px",
        gap: 5,
    },
    multTabSelected: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        minHeight: '45px',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid #b8f6f8',
        borderRadius: "6px",
        padding: "0 10px 1px",
        gap: 5,
        backgroundColor: colors.selectedAlpha,
        color: colors.white
    },
    multTableLabel: {
        // color: colors.white,
        // fontWeight: 'bold',
    },
    multTableItemValue: {
    // fontStyle: 'italic'
    },
    multTableDelButton: {
        // width : 18,
        // height : 18,
        // border:'2px solid',
        borderRadius: 50,
        borderColor: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        fontSize: 28,
        // fontWeight: 'bold',
    },
    multTableOutlinedButton: {
        // width : 25,
        height : 23,
        display: 'flex',
        border: '2px solid',
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        fontSize: '15px',
        borderRadius: '22px',
    },
    multTableItemSearchContainer: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        border: '1px solid #E3E3E3',
        borderRadius: "6px",
        padding: "5px 15px",
        marginTop: 10,
        gap: 5,
        alignItems: 'center',
    },
    singTableItemPresetOpen: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        width: '100%',
    },
    multTableItemSearchContainerOpen: {
        width: '100%',
        display: 'flex',
        borderRadius: "6px",
        // padding: "10px 15px",
        // marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    unselectedTabItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "0 0px 1px 10px",
        gap: 5,
        minWidth: '70px',
        minHeight: '45px',
        justifyContent: 'space-between'
    },
    multTableText:{
        flexGrow: 1,
        textAlign: 'right',
        padding: '0 15px 0 0',
        border: 'none',
    },
    endButtonContainer:{
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "3px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
        marginLeft: 'auto'
    },
    inputField: {
        width: '80px',
    },
    touchBox: {
        // border : '1px solid red',
        width: '20px',
        height: '30px',
        display: 'inline-block',
        flex: '1 1 auto'
    },
}));


const FormatMultipleSelectTableComponent = (props) => {
    // console.log('F23-6',props);
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);
    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value : []);
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    // const [open, setOpen] = useState(typeof props.expand === 'undefined' ? false : props.expand);
    const [open, setOpen] = useState(props.field.visualDefault ? props.field.visualDefault : false);
    const [preselOrignal , setPreselOriginal] = useState(typeof props.field.originalPresel !== 'undefined' ? props.field.originalPresel : []);
    const [value, setValue] = useState('');
    const [focus, setFocus] = useState('') // définit l'item cliqué

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[props.disabled]);

    const presels = props.field.originalPresel===undefined? [] : [...props.field.originalPresel]
    let allItems = [...presels]

    const isInSelected = (value) => {
        let isThere = false
        if (selected.length > 0) selected.map(select => {
            if(select.text === value) isThere = true
        })
        return isThere
    }

    const isInPresels = (item) => {
        return presels.includes(item)
    }

    const handleAddValue = (value) => {
        if(value.trim() !== ""){
            if (!allItems.includes(value)) allItems.push(value)
            if(!isInSelected(value)){
                props.field.options.presel.push(value);
                setFocus(value)
            }
            else setFocus(value)
            setNewValue('');
        }
    }

    const handleDeletePresel = (value, indexation) => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-sur'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        let tmp = selected.filter(item => item.text !== value.text);
                        setSelected(tmp);
                        props.field.options.presel = props.field.options.presel.filter(item => item !== value.text);
                        props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });
    }

    const handleDeleteValue = (object) => {
        if (isInSelected(object.text)){
            let tempSel = [...selected];
            let tmp = tempSel.filter(item => item.text !== object.text);
            setSelected(tmp);
            props.handleDeleteInputTable(object.text, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
    }

    const indexInSelected = (value) => {
        return selected.findIndex((item, index) => {
            return item.text.trim() === value.trim()
        })
    }

    const handleSelect = (item) => {
        if (isInSelected(item)) {
            setFocus("")
            let tempSel = [...selected]
            setSelected(tempSel.filter(label => label.text !== item))
            if(!isInPresels(item)) props.field.options.presel = props.field.options.presel.filter(label => label !== item);
            props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
        else {
            setFocus(item)
        }
    }

    const handleEditSelectValue = (event, item, indexation) => {
        if (event.target.value.trim !== ''){
            if(!isInSelected(focus)){
                setSelected([...selected, {"text" : focus, "value" : event.target.value}]);
            }
            else {
                selected[indexInSelected(focus)].value = event.target.value;
                setSelected(selected);
            }
            props.handleChangeInputTable({"text": focus, "value": event.target.value}, indexation, props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
        if (event.target.value.trim === ''){
            handleUnselect()
        }
    }

    const handleUnselect = () => {
        if (isInSelected(focus)){
            let tempSel = [...selected];
            let tmp = tempSel.filter(select => select.text !== focus);
            setSelected(tmp);
            props.handleDeleteInputTable(focus, props.field.options.presel.length - 1, props.field,props.index, props.field.options.multiselect, props.field.options.mandatory);
        }
    }

    return (
        <div className={classes.multTabMainContainer} onClick={() => {setOpen(!open)}}>
            {/** liste des selects **/
            !open && selected.length > 0 &&
            <div className={classes.multTableItemSearchContainerOpen} onClick={() => {setOpen(!open)}}>
                {selected.sort((a, b) => (a.text.toLowerCase()).localeCompare(b.text.toLowerCase()))
                .map((value, index) => {
                    return (
                        <div key={index} className={classes.multTabSelected}>
                            <span className={classes.multTableLabel} onClick={() => {setFocus(value.text); setOpen(true);}}>{value.text}: </span>
                            <span className={classes.multTableItemValue} onClick={() => {setFocus(value.text); setOpen(true);}}>{value.value}</span>
                            { !disabled &&
                                <span className="p3 cursor" onClick={(event) => {event.preventDefault(); event.stopPropagation(); setOpen(true); !isInPresels(value.text)? handleDeletePresel(value) : handleDeleteValue(value)}}>
                                    {isInPresels(value.text)?
                                    <span className={`${classes.multTableDelButton} material-icons`}>highlight_off</span>
                                    :
                                    <span className={`${classes.multTableOutlinedButton} material-icons`}>delete</span>}
                                </span>
                            }
                        </div>
                    )
                })}
            </div>
            }

            { /** add item field **/
            open && typeof props.field.options.presel !== 'undefined' &&
            <div className={classes.multTableItemSearchContainerOpen} >
                {/** presel filter **/
                open && props.field.value.length > 10?
                <div className={classes.singTableItemPresetOpen}>
                    <Input
                        id={`standard-full-width-search-${props.field.id}`}
                        label=""
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search className={classes.singTableSearchButton}/>
                            </InputAdornment>}
                        endAdornment={ value.length > 0 &&
                        <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                            <Close />
                        </InputAdornment>
                        }
                        className={classes.textField}
                        onChange={(event) => setValue(event.target.value)}
                        value={value}
                    />
                </div> : null}

                {!disabled && props.field.options.editable &&
                <div className={classes.input_add_item} onClick={(event) => {event.preventDefault(); event.stopPropagation();}}>
                    <Input
                        disabled={disabled}
                        autoComplete='off'
                        id={'new-select-input-' + props.field.id}
                        //label="Label"
                        style={{ margin: 0 }}
                        placeholder={t('common:multiple.add-item')}
                        //helpertext="Full width!"
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        disableUnderline={true}
                        className={classes.inputField}
                        onBlur={() => {handleAddValue(newValue.trim())}}
                        onChange={(event) => setNewValue(event.target.value)}
                        onKeyPress={(e) => { if (e.key === 'Enter')handleAddValue(newValue)}}
                        value={newValue}
                    />
                </div>}
                {props.field.format.includes('table') && props.field.format.includes('multi') && props.field.options.presel.filter(item => {
                    if (!value) return true
                    if (item.toLowerCase().includes(value.toLowerCase())) {
                        return true
                    }
                }) //make select appear first
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .sort((a, b) => {
                    let aN = isInSelected(a)? 1 : 0;
                    let bN = isInSelected(b)? 1 : 0;
                    // console.log('a: ', a, aN);
                    // console.log('b: ', b, bN);
                    // console.log('is ', a, "<",b,'? ',(aN - bN ));
                    return bN - aN
                })
                // .filter(item => {
                //     if(isInSelected(item)) return true
                // })
                .map((item, indexation) => {
                    let find = indexInSelected(item);
                    return (
                    <div key={'div' + props.field.id + '-' +indexation} 
                        className={(isInSelected(item)) ? classes.multTabSelected : classes.unselectedTabItem}
                        onClick={(event) => {event.preventDefault(); event.stopPropagation();
                            if(!disabled) {setFocus(item);}
                        }}>
                        <div className={classes.multTableLabel}>
                            {item}
                        </div>
                        {(isInSelected(item)) || item === focus ?
                            <span >:
                                <Input
                                    autoFocus={focus === item ? true : false}
                                    disabled={disabled}
                                    autoComplete='off'
                                    name={'field-' + item + '-' + indexation}
                                    onWheel={ event => event.currentTarget.blur() }
                                    value={typeof selected[find] !== "undefined" ? selected[find].value : ""}
                                    className={classes.inputField}
                                    style={{marginLeft: '5px'}}
                                    onKeyDown={(e)=> {if (e.key === "Backspace" || e.key === "Delete" || e.key === "Space"){
                                        setTimeout(()=>{
                                            if(e.target.value === '') handleUnselect()
                                        }, 200)
                                        if (e.key === "Enter"){setFocus(''); setOpen(false)}
                                    }}}
                                    onChange={(event, item, indexation) => handleEditSelectValue(event, item, indexation)}
                                />
                            </span>
                        : <div className={classes.touchBox} onClick={(event) => {event.preventDefault(); event.stopPropagation(); if (!disabled){handleSelect(item)}  }}/>}
                        {!disabled && ((preselOrignal.length > 0 || preselOrignal.length === 0) && !isInPresels(item)?
                        <span className="p3 cursor" onClick={(event) => {event.preventDefault(); event.stopPropagation(); handleDeletePresel(selected[find])}}>
                            <span className={`${classes.multTableOutlinedButton} material-icons`}>delete</span>
                        </span>
                        : isInSelected(item) ?
                        <span className="p3 cursor" onClick={(event) => {event.preventDefault(); event.stopPropagation(); handleDeleteValue(selected[find])}}>
                            <span className={`${classes.multTableDelButton} material-icons`}>highlight_off</span>
                        </span>
                        : null)}
                    </div>
                )})}
            </div>}

    {
        typeof props.field.options.presel !== 'undefined' &&
        <div className={classes.endButtonContainer} onClick={(event) => setOpen(!open)}>
                <span id="icon-delete-playlist" className={`material-icons md-small`}>
                    {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                </span>
        </div>
    }
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatMultipleSelectTableComponent);





